/* eslint-disable */
import * as React from 'react'
import { connect } from 'react-redux';
import PerfectScrollbar from "../thirdparty/perfect-scrollbar.js";

export class Segments extends React.Component<ISegments> {
	public dropList: number;
	public state: any;
	private refArr: any;
	private accordianRef: any;

	constructor(props: any) {
		super(props);
		this.dropList = 2;
		this.state = { cards: props.list };
		this.updateSelectedTraits = this.updateSelectedTraits.bind(this);
		this.refArr = [];
		this.accordianRef = [];
		this.toggleAccordian = this.toggleAccordian.bind(this);
		this.focusAccordian = this.focusAccordian.bind(this);
		this.focusText = this.focusText.bind(this);
		this.scrollRefComp = this.scrollRefComp.bind(this);
		this.expandAll = this.expandAll.bind(this);
	}
 

	expandAll(tabType: any, e: any) {

		for (let indx = 0; indx < this.accordianRef.length; indx++) {
			if (this.accordianRef[indx].catTabIndex === tabType) {
				const component = this.accordianRef[indx].reference;
				component.className = " accordion aTraits active ";
				var panel = component.nextElementSibling;
				if (panel.style.maxHeight) {
					panel.style.maxHeight = null;
					component.className = " accordion aTraits ";
				} else {
					panel.style.maxHeight = panel.scrollHeight + "px";
				}
			}
		}
	}

	public focusText(buttonIndex: any, ref: any) {
		let refComponent = { index: buttonIndex, reference: ref };
		this.refArr.push(refComponent);
	}

	public focusAccordian(title: any, tabIndex: any, ref: any) {
		let refComponent = { titleIndex: title, catTabIndex: tabIndex, reference: ref, matchString:title+tabIndex };
		const index = this.accordianRef.findIndex((obj: any) => obj.matchString == title+tabIndex);
		if (index < 0) {
			this.accordianRef.push(refComponent);
		}
	}

	getCardState(card: any) {
		const index = this.props.list.findIndex((obj: any) => obj.segmentId == card.segmentId);
		if (index >= 0) {
			card.isSelected = this.props.list[index].isSelected;
		}
		return card;
	}

	getFreshList(list: any) {
		const emptArr: any[] = [];
		let payload = [
			{
				title: "DEMOGRAPHICS",
				subcategorylist: [
					{
						subcategorytitle: "",
						listItems: []
					},

				],
				nonCatetories: [],
				cls: "demographicInactive",
				Tabtype: 0
			},
			{
				title: "INTERESTS",
				subcategorylist: [
					{
						subcategorytitle: "",
						listItems: [{}]
					}
				],
				nonCatetories: emptArr,
				cls: "interestsInactive",
				Tabtype: 1
			},
			{
				title: "BEHAVIOURS",
				subcategorylist: [
					{
						subcategorytitle: "",
						listItems: []
					}
				],
				nonCatetories: [],
				cls: "behavioursInactive",
				Tabtype: 2
			}
		];

		
		list.map((card: any, i: any) => {
			if (!card.isSelected) {
				if (card.subCategory !== null && card.subCategory !== undefined && card.subCategory !== "") {

					const index = payload[card.Tabtype].subcategorylist.findIndex((obj: any) => obj.subcategorytitle === card.subCategory);
					if (index >= 0) {
						payload[card.Tabtype].subcategorylist[index].listItems.push(card);
					}
					else {
						const emtpycItem = {
							subcategorytitle: card.subCategory,
							listItems: [{}]
						};
						emtpycItem.listItems.splice(0,1);
						emtpycItem.listItems.push(card);
						payload[card.Tabtype].subcategorylist.push(emtpycItem);
					}
				}
				else {
					payload[card.Tabtype].nonCatetories.push(card);
				}
			}
		});
		payload[0].subcategorylist.splice(0, 1);
		payload[1].subcategorylist.splice(0, 1);
		payload[2].subcategorylist.splice(0, 1);
		return payload;
	}

	scrollRefComp(ref: any) {
		if (ref) {
			new PerfectScrollbar(ref, {
				suppressScrollX: true,
				suppressScrollY: false
			});
			this.props.refTabComponent(ref, this.props.tabIndex)
		}
	}

	toggleAccordian(card: any, cardTabType:any, listLen:any, e:any) {
		const userIndx = this.accordianRef.findIndex((obj: any) => obj.matchString === card.subcategorytitle+cardTabType);
 		if (userIndx >= 0) {
			const component = this.accordianRef[userIndx].reference;
			var panel = component.nextElementSibling;
			component.className = " accordion aTraits active ";

			if(e.target.className == "accordion aTraits empCl" && panel.style.maxHeight) {
				e.target.nextElementSibling.style.maxHeight = (38 * listLen) + "px";
				component.className = e.target.className =" accordion aTraits active ";
				this.accordianRef[userIndx].reference = e.target;
			}
			else if (panel.style.maxHeight) {
				panel.style.maxHeight = null;
				component.className = e.target.className = " accordion aTraits ";
			} else {
				panel.style.maxHeight =  panel.scrollHeight+ "px";
				e.target.className =" accordion aTraits active ";
			}
		}
	}

	updateSelectedTraits(card: any, e: any) {
		const userIndx = this.refArr.findIndex((obj: any) => obj.index === card.segmentId);
		if (userIndx > 0) {
			const component = this.refArr[userIndx].reference;

			component.className = "selected  removeItem";
		}
		const contxt = this;
		setTimeout(function () {
			const pushingCard = { action: "ADD", card: card };
			const tabs = { card: pushingCard, listID: 1 };
			contxt.props.updateStateOnClick(tabs);
		}, 200)
	}

	render() {
		const listItems = this.getFreshList(this.props.list);
		let segmentCls = "col-xl-12 col-md-4 col-12 pl-xl-0 pr-xl-0 pr-md-0 pl-0-sm pr-0-sm spaceBottom";
		let segmentIntCls = "col-xl-12 col-md-4 col-12 pl-0 pr-0 spaceBottom";
		return (
			<div className="audienceTraitsTabContent" id="audienceTraitsTabContent" ref={this.scrollRefComp}>
				<div className="audienceTraits row-flex">
						{listItems.map((card: any, i: any) => {
							return (
								<div key={"pDiv"+i} className={i!==1?segmentCls:segmentIntCls}>
								<div key={i}>
									<h4>
										<span className={card.cls} key={"cls" + card.cls} />
										<span key={"title" + card.title}>{card.title}</span>
										<a onClick={this.expandAll.bind(this, i)}>Expand All</a>
									</h4>
									{card.subcategorylist.map((subCat: any, i: any) => {
										return (
											<div key={"span" + subCat.subcategorytitle}>
									<a className="accordion aTraits empCl" ref={this.focusAccordian.bind(this, subCat.subcategorytitle, card.Tabtype)} onClick={this.toggleAccordian.bind(this, subCat,card.Tabtype,subCat.listItems.length)}>{subCat.subcategorytitle}</a>
												<div className="panel">
													<div className="card-body">
														<ul>
															{subCat.listItems.map((subCatItem: any, i: any) => {
																return (<li ref={this.focusText.bind(this, subCatItem.segmentId)} key={"li" + subCatItem.segmentName}><span key={"sgName" + subCatItem.segmentName} onClick={this.updateSelectedTraits.bind(this, subCatItem)} >{subCatItem.segmentName}</span></li>)
															})}
														</ul>
													</div>
												</div>
											</div>)
									})}
									{card.nonCatetories.map((nonCat: any, i: any) => {
										return <a key={"link" + nonCat.segmentName} className="aTraits" ref={this.focusText.bind(this, nonCat.segmentId)} onClick={this.updateSelectedTraits.bind(this, nonCat)} >{nonCat.segmentName}</a>
									})}
								</div>
								</div>
							)
						})}

					</div>
 			</div>
		);
	}
}
function mapStateToProps(state: any) {
	return {

	};
}

export default connect(mapStateToProps, (dispatch) => {
	return {

	}

})(Segments);

interface ISegments extends React.FC<any> {
	id?: any;
	canDrop?: boolean
	isOver?: boolean
	list?: any;
	updateStateOnClick?: any;
	tabIndex?: any;
	optionalIist?: any;
	updateToggle: any;
	refTabComponent: any;
	startStopLoader?: any;
}


