import JSZip from "jszip";
import async from "async";
import EmbeddedImage from "../utils/EmbeddedImage";
import Slide from "./slide";

type PptNamespace = "contentType" | "rel" | "appProp" | "presentation";

class pptxfile {
  public content: Record<string, XMLDocument | Uint8Array | ArrayBuffer>;

  private resolvers: Record<PptNamespace, XPathNSResolver> = {
    contentType: {
      lookupNamespaceURI: (prefix) => {
        return "http://schemas.openxmlformats.org/package/2006/content-types";
      },
    },
    rel: {
      lookupNamespaceURI: (prefix) => {
        return "http://schemas.openxmlformats.org/package/2006/relationships";
      },
    },
    appProp: {
      lookupNamespaceURI: (prefix) => {
        if (prefix === "vt")
          return "http://schemas.openxmlformats.org/officeDocument/2006/docPropsVTypes";
        return "http://schemas.openxmlformats.org/officeDocument/2006/extended-properties";
      },
    },
    presentation: {
      lookupNamespaceURI: (prefix) => {
        switch (prefix) {
          case "a":
            return "http://schemas.openxmlformats.org/drawingml/2006/main";
          case "r":
            return "http://schemas.openxmlformats.org/officeDocument/2006/relationships";
          case "p":
            return "http://schemas.openxmlformats.org/presentationml/2006/main";
        }
        return "http://schemas.openxmlformats.org/officeDocument/2006/relationships";
      },
    },
  };

  private isXmlDocument(key: string): boolean {
    return (this.content[key] as XMLDocument).documentElement !== undefined;
  }

  getDocument(key: string): XMLDocument {
    if (this.isXmlDocument(key)) return this.content[key] as XMLDocument;
    // This is a utility function to keep Typescript happy, we shouldn't ever hit this:
    return new XMLDocument();
  }

  constructor() {
    this.content = {};
  }

  async load(data: ArrayBuffer): Promise<pptxfile> {
    const parser = new DOMParser();
    var content = this.content;
    await JSZip.loadAsync(data).then((zip) => {
      return async.each(zip.files, function (file: any, callback: any) {
        var key = file.name;
        if (file && typeof key == "string") {
          var ext = key.substr(key.lastIndexOf("."));
          if (ext === ".xml" || ext === ".rels") {
            file.async("string").then(function (xml: any) {
              const dom = parser.parseFromString(xml, "application/xml");
              content[key] = dom;
              callback();
            });
          } else {
            file.async("uint8array").then(function (data: any) {
              content[key] = data; // asText() will corrupt image files
              callback();
            });
          }
        }
      });
    });
    return this;
  }

  toJSON() {
    return this.content;
  }

  toBuffer(): Promise<Uint8Array> {
    const serializer = new XMLSerializer();
    var zip2 = new JSZip();
    var content = this.content;
    for (var key in content) {
      if (content.hasOwnProperty(key)) {
        var ext = key.substr(key.lastIndexOf("."));
        if (ext === ".xml" || ext === ".rels") {
          const xmlStr = serializer.serializeToString(
            content[key] as XMLDocument
          );
          zip2.file(key, xmlStr);
        } else {
          zip2.file(key, content[key] as Uint8Array);
        }
      }
    }
    zip2.file(
      "docProps/app.xml",
      '<?xml version="1.0" encoding="UTF-8" standalone="yes"?><Properties xmlns="http://schemas.openxmlformats.org/officeDocument/2006/extended-properties" xmlns:vt="http://schemas.openxmlformats.org/officeDocument/2006/docPropsVTypes"><TotalTime>0</TotalTime><Words>0</Words><Application>Microsoft Macintosh PowerPoint</Application><PresentationFormat>On-screen Show (4:3)</PresentationFormat><Paragraphs>0</Paragraphs><Slides>2</Slides><Notes>0</Notes><HiddenSlides>0</HiddenSlides><MMClips>0</MMClips><ScaleCrop>false</ScaleCrop><HeadingPairs><vt:vector size="4" baseType="variant"><vt:variant><vt:lpstr>Theme</vt:lpstr></vt:variant><vt:variant><vt:i4>1</vt:i4></vt:variant><vt:variant><vt:lpstr>Slide Titles</vt:lpstr></vt:variant><vt:variant><vt:i4>2</vt:i4></vt:variant></vt:vector></HeadingPairs><TitlesOfParts><vt:vector size="3" baseType="lpstr"><vt:lpstr>Office Theme</vt:lpstr><vt:lpstr>PowerPoint Presentation</vt:lpstr><vt:lpstr>PowerPoint Presentation</vt:lpstr></vt:vector></TitlesOfParts><Company>Proven, Inc.</Company><LinksUpToDate>false</LinksUpToDate><SharedDoc>false</SharedDoc><HyperlinksChanged>false</HyperlinksChanged><AppVersion>14.0000</AppVersion></Properties>'
    );
    return zip2.generateAsync({ type: "uint8array" });
  }

  public addTitlePageDetails(data: any): void {
    const slide = this.getDocument("ppt/slides/slide1.xml");
    const ioXpath =
      "/p:sld/p:cSld/p:spTree/p:sp/p:txBody/a:p/a:r/a:t[text()='IO# 3000XXXX']";
    const ioNumber = slide.evaluate(
      ioXpath,
      slide,
      this.resolvers["presentation"],
      XPathResult.FIRST_ORDERED_NODE_TYPE,
      null
    ).singleNodeValue;
    if (ioNumber) {
      ioNumber.textContent = data.id;
    }

    const nameXpath =
      "/p:sld/p:cSld/p:spTree/p:sp/p:txBody/a:p/a:r/a:t[text()='CAMPAIGN NAME']";
    const orderName = slide.evaluate(
      nameXpath,
      slide,
      this.resolvers["presentation"],
      XPathResult.FIRST_ORDERED_NODE_TYPE,
      null
    ).singleNodeValue;
    if (orderName) {
      orderName.textContent = data.name;
    }

    const advertiserXpath =
      "/p:sld/p:cSld/p:spTree/p:sp/p:txBody/a:p/a:r/a:t[text()='ADVERTISER NAME']";
    const advertiser = slide.evaluate(
      advertiserXpath,
      slide,
      this.resolvers["presentation"],
      XPathResult.FIRST_ORDERED_NODE_TYPE,
      null
    ).singleNodeValue;
    if (advertiser) {
      advertiser.textContent = data.advertiser.replace(/\s*\d+$/, "");
    }

    const startXpath =
      "/p:sld/p:cSld/p:spTree/p:sp/p:txBody/a:p/a:r/a:t[text()='JAN 1, 20XX – DEC 31, 20XX']";
    const campaignDates = slide.evaluate(
      startXpath,
      slide,
      this.resolvers["presentation"],
      XPathResult.FIRST_ORDERED_NODE_TYPE,
      null
    ).singleNodeValue;
    if (campaignDates) {
      campaignDates.textContent =
        data.orderStartDate + " - " + data.orderEndDate;
    }
  }

  addImageFile(image: EmbeddedImage, targetSlide: Slide): string {
    const result = this.getNextRId();
    const imageName = this.getNextImageName();

    var relsKey = "ppt/slides/_rels/" + targetSlide.name + ".xml.rels";
    const relsDoc = this.getDocument(relsKey);
    const relNode = relsDoc.evaluate(
      "/x:Relationships",
      relsDoc,
      this.resolvers["rel"],
      XPathResult.FIRST_ORDERED_NODE_TYPE,
      null
    ).singleNodeValue;
    if (relNode) {
      const relationship = relsDoc.createElementNS(
        "http://schemas.openxmlformats.org/package/2006/relationships",
        "Relationship"
      );
      relationship.setAttribute("Id", result);
      relationship.setAttribute(
        "Type",
        "http://schemas.openxmlformats.org/officeDocument/2006/relationships/image"
      );
      relationship.setAttribute("Target", "../media/" + imageName);
      relNode.appendChild(relationship);
    }
    this.content["ppt/media/" + imageName] = image.data;
    return result;
  }

  getSlideCount() {
    const slideCount = Object.keys(this.content).filter(function (key) {
      return key.substr(0, 16) === "ppt/slides/slide";
    }).length;
    return slideCount;
  }

  getSlideAtIndex(index: number): Slide | null {
    if (index >= this.getSlideCount()) {
      throw new Error("Slide index out of bounds");
    }

    const presentation = this.getDocument("ppt/presentation.xml");
    var evaluator = new XPathEvaluator();
    var resolver = evaluator.createNSResolver(presentation.documentElement);

    const rIdNode = presentation
      .evaluate(
        "/p:presentation/p:sldIdLst/p:sldId[position()=" +
          (index + 1).toString() +
          "]/@r:id",
        presentation,
        resolver,
        XPathResult.ANY_TYPE,
        null
      )
      .iterateNext();
    if (rIdNode == null) return null;
    const rId = rIdNode.nodeValue;

    const presRels = this.getDocument("ppt/_rels/presentation.xml.rels");
    if (presRels.firstChild) {
      const relationships: Array<Element> = Array.from(
        (presRels.firstChild as Element).children
      );
      const targets = relationships.filter(
        (item: Element) => item.getAttribute("Id") === rId
      );
      const target = targets[0].getAttribute("Target");

      if (target != null) {
        const name = target.substring(7, target.lastIndexOf(".xml"));
        return new Slide({
          content: this.getDocument("ppt/slides/" + name + ".xml"),
          presentation: this,
          name: name,
        });
      }
    }
    return null;
  }

  getNextSlideId(): number {
    var maxId = 0;
    const doc = this.getDocument("ppt/_rels/presentation.xml.rels");
    const targetNodes = doc.evaluate(
      "/x:Relationships/x:Relationship/@Target",
      doc,
      this.resolvers["rel"],
      XPathResult.ORDERED_NODE_ITERATOR_TYPE,
      null
    );
    var targetNode = targetNodes.iterateNext();
    while (targetNode) {
      var target = targetNode.nodeValue;
      if (target) {
        if (
          target.indexOf("slides/") === 0 &&
          target.lastIndexOf(".xml") === target.length - 4
        ) {
          const id = parseInt(target.replace(/\D/g, ""));
          if (id > maxId) maxId = id;
        }
      }
      targetNode = targetNodes.iterateNext();
    }
    return maxId + 1;
  }

  getNextRId(): string {
    var maxId = 0;
    const doc = this.getDocument("ppt/_rels/presentation.xml.rels");
    const idNodes = doc.evaluate(
      "/x:Relationships/x:Relationship/@Id",
      doc,
      this.resolvers["rel"],
      XPathResult.ORDERED_NODE_ITERATOR_TYPE,
      null
    );
    var idNode = idNodes.iterateNext();
    while (idNode) {
      if (idNode.nodeValue) {
        const id = parseInt(idNode.nodeValue.replace("rId", ""));
        if (id > maxId) maxId = id;
      }
      idNode = idNodes.iterateNext();
    }
    return "rId" + (maxId + 1);
  }

  getNextImageName(): string {
    var maxImageId = 0;
    for (var key in this.content) {
      if (this.content.hasOwnProperty(key) && key.indexOf("ppt/media/") === 0) {
        const extIdx = key.lastIndexOf(".png");
        if (extIdx > 0) {
          const id = parseInt(key.replace(/\D/g, ""));
          if (id > maxImageId) maxImageId = id;
        }
      }
    }
    return "image" + (maxImageId + 1) + ".png";
  }

  removeNodes(
    doc: XMLDocument,
    xpath: string,
    resolver: XPathNSResolver
  ): void {
    var foundSome = true;
    while (foundSome) {
      foundSome = false;
      const nodes = doc.evaluate(
        xpath,
        doc,
        resolver,
        XPathResult.ORDERED_NODE_ITERATOR_TYPE,
        null
      );
      var node = nodes.iterateNext();
      if (node != null && node.parentNode != null) {
        node.parentNode.removeChild(node);
        foundSome = true;
      }
    }
  }

  deleteSlide(index: number): void {
    const slide = this.getSlideAtIndex(index);
    if (slide == null) return;

    const slideKey = "ppt/slides/" + slide.name + ".xml";
    const relsKey = "ppt/slides/_rels/" + slide.name + ".xml.rels";

    const presentation = this.getDocument("ppt/presentation.xml");
    var evaluator = new XPathEvaluator();
    var resolver = evaluator.createNSResolver(presentation.documentElement);

    const sldId = presentation.evaluate(
      "/p:presentation/p:sldIdLst/p:sldId[position()=" +
        (index + 1).toString() +
        "]",
      presentation,
      resolver,
      XPathResult.FIRST_ORDERED_NODE_TYPE,
      null
    ).singleNodeValue;
    if (sldId && sldId.parentNode) {
      sldId.parentNode.removeChild(sldId);
    }
    delete this.content[slideKey];

    const contentDoc = this.getDocument("[Content_Types].xml");
    const contentResolver = this.resolvers["contentType"];
    const contentXpath = "/x:Types/x:Override[@PartName='/" + slideKey + "']";
    this.removeNodes(contentDoc, contentXpath, contentResolver);

    const presRels = this.getDocument("ppt/_rels/presentation.xml.rels");
    const relResolver = this.resolvers["rel"];
    const relXpath =
      "/x:Relationships/x:Relationship[@Target='" +
      slideKey.substring(4) +
      "']";
    this.removeNodes(presRels, relXpath, relResolver);

    const slideRels = this.getDocument(relsKey);
    const targetNodes = slideRels.evaluate(
      "/x:Relationships/x:Relationship/@Target",
      slideRels,
      relResolver,
      XPathResult.ORDERED_NODE_ITERATOR_TYPE,
      null
    );
    var targetNode = targetNodes.iterateNext();
    while (targetNode) {
      if (
        targetNode.nodeValue &&
        targetNode.nodeValue.indexOf("slideLayout") < 0
      ) {
        var assetCount = 0;
        for (var key in this.content) {
          if (
            this.content.hasOwnProperty(key) &&
            key.indexOf("ppt/slides/_rels/") === 0
          ) {
            assetCount += this.getDocument(key).evaluate(
              "count(/x:Relationships/x:Relationship[@Target='" +
                targetNode.nodeValue +
                "'])",
              this.getDocument(key),
              relResolver,
              XPathResult.NUMBER_TYPE,
              null
            ).numberValue;
          }
        }
        if (assetCount === 1) {
          delete this.content[targetNode.nodeValue.replace("../", "ppt/")];
        }
      }
      targetNode = targetNodes.iterateNext();
    }

    delete this.content[relsKey];

    const appDoc = this.getDocument("docProps/app.xml");
    const appResolver = this.resolvers["appProp"];
    const slideCountNode = appDoc.evaluate(
      "/x:Properties/x:Slides",
      appDoc,
      appResolver,
      XPathResult.FIRST_ORDERED_NODE_TYPE,
      null
    );
    if (slideCountNode && slideCountNode.singleNodeValue) {
      slideCountNode.singleNodeValue.textContent =
        this.getSlideCount().toString();
    }
  }

  addSlide(layoutName: string, index?: number): Slide {
    var slideName = "slide" + this.getNextSlideId();

    var layoutKey = "ppt/slideLayouts/" + layoutName + ".xml";
    var slideKey = "ppt/slides/" + slideName + ".xml";
    var relsKey = "ppt/slides/_rels/" + slideName + ".xml.rels";

    // create slide
    const slideContent = new DOMParser().parseFromString(
      new XMLSerializer()
        .serializeToString(this.getDocument(layoutKey))
        .replace(/p:sldLayout/g, "p:sld"),
      "application/xml"
    );
    slideContent.documentElement.removeAttribute("preserve");
    slideContent.documentElement.removeAttribute("type");
    slideContent.documentElement.removeAttribute("userDrawn");

    this.content[slideKey] = slideContent;
    var slide = new Slide({
      content: slideContent,
      presentation: this,
      name: slideName,
    });
    slide.clearShapes();

    // add to [Content Types].xml
    const contentDoc = this.getDocument("[Content_Types].xml");
    const override = contentDoc.createElementNS(
      "http://schemas.openxmlformats.org/package/2006/content-types",
      "Override"
    );
    override.setAttribute("PartName", "/ppt/slides/" + slideName + ".xml");
    override.setAttribute(
      "ContentType",
      "application/vnd.openxmlformats-officedocument.presentationml.slide+xml"
    );
    const typeNode = contentDoc.evaluate(
      "/x:Types",
      contentDoc,
      this.resolvers["contentType"],
      XPathResult.FIRST_ORDERED_NODE_TYPE,
      null
    );
    if (typeNode && typeNode.singleNodeValue) {
      typeNode.singleNodeValue.appendChild(override);
    }

    //add it rels to slidelayout
    this.content[relsKey] = new DOMParser().parseFromString(
      `
        <?xml version="1.0" encoding="UTF-8" standalone="yes"?>
            <Relationships xmlns="http://schemas.openxmlformats.org/package/2006/relationships">
                <Relationship Id="rId1" 
                    Type="http://schemas.openxmlformats.org/officeDocument/2006/relationships/slideLayout" 
                    Target="../slideLayouts/LAYOUTNAME.xml"/>
            </Relationships>        
        `
        .trim()
        .replace("LAYOUTNAME", layoutName),
      "application/xml"
    );

    // add it to ppt/_rels/presentation.xml.rels
    var rId = this.getNextRId();
    const relsDoc = this.getDocument("ppt/_rels/presentation.xml.rels");
    const relationship = contentDoc.createElementNS(
      "http://schemas.openxmlformats.org/package/2006/relationships",
      "Relationship"
    );
    relationship.setAttribute("Id", rId);
    relationship.setAttribute(
      "Type",
      "http://schemas.openxmlformats.org/officeDocument/2006/relationships/slide"
    );
    relationship.setAttribute("Target", "slides/" + slideName + ".xml");
    const relNode = relsDoc.evaluate(
      "/x:Relationships",
      relsDoc,
      this.resolvers["rel"],
      XPathResult.FIRST_ORDERED_NODE_TYPE,
      null
    );
    if (relNode && relNode.singleNodeValue) {
      relNode.singleNodeValue.appendChild(relationship);
    }

    // add it to ppt/presentation.xml
    const presDoc = this.getDocument("ppt/presentation.xml");
    const ids = presDoc.evaluate(
      "/p:presentation/p:sldIdLst/p:sldId/@id",
      presDoc,
      this.resolvers["presentation"],
      XPathResult.ORDERED_NODE_ITERATOR_TYPE,
      null
    );
    var maxId = 0;
    var id = ids.iterateNext();
    while (id) {
      if (id.nodeValue && parseInt(id.nodeValue) > maxId)
        maxId = parseInt(id.nodeValue);
      id = ids.iterateNext();
    }

    const slideNode = presDoc.createElementNS(
      "http://schemas.openxmlformats.org/presentationml/2006/main",
      "p:sldId"
    );
    slideNode.setAttribute("id", (maxId + 1).toString());
    slideNode.setAttribute("r:id", rId);
    const sibling = presDoc.evaluate(
      "/p:presentation/p:sldIdLst/p:sldId[" + index + "]",
      presDoc,
      this.resolvers["presentation"],
      XPathResult.FIRST_ORDERED_NODE_TYPE,
      null
    ).singleNodeValue;
    if (sibling) {
      (sibling as Element).after(slideNode);
    }

    // increment slidecount
    var sldCount = this.getSlideCount();
    const appDoc = this.getDocument("docProps/app.xml");
    const sldCountNode = appDoc.evaluate(
      "/x:Properties/x:Slides",
      appDoc,
      this.resolvers["appProp"],
      XPathResult.FIRST_ORDERED_NODE_TYPE,
      null
    ).singleNodeValue;
    if (sldCountNode) {
      sldCountNode.textContent = sldCount.toString();
    }
    return slide;
  }
}
export default pptxfile;
