import React from "react";
import { connect } from "react-redux";

import { getTomorrowsDate, getNewEndDate } from "src/Utility/CampaignUtil";

class DomoDataFrame extends React.Component<IDomoDataFrame, {}> {
  render() {
    let pFilter =
      "%5B%7B%22column%22%3A%22Order_ID%22%2C%22operand%22%3A%22EQUALS%22%2C%22values%22%3A%5BORDERID%5D%7D%2C%7B%22column%22%3A%22Event_Date%22%2C%22operand%22%3A%22BETWEEN%22%2C%22values%22%3A%5B%22STARTDATE%22%2C%22ENDDATE%22%5D%7D%5D";

    let url =
      `${this.props.coreConfigData?.reportingData?.PPT_EXPORT?.userProvidedValue}?pfilters=` +
      pFilter
        .replace("ORDERID", this.props.orderId)
        .replace("STARTDATE", this.props.usrSelectedDates.startDate.date)
        .replace("ENDDATE", this.props.usrSelectedDates.endDate.date);
    return (
      <div>
        <iframe title="export" src={url} width="850" height={250} />
      </div>
    );
  }
}

function mapStateToProps(state: any, props: any) {
  return {
    usrSelectedDates:
      state.CalendarControlState.hasOwnProperty("data") &&
      state.CalendarControlState.data
        ? Object.assign({}, state.CalendarControlState.data)
        : {
            startDate: {
              date: getTomorrowsDate(),
              campaignStartDate: getTomorrowsDate(),
            },
            endDate: {
              date: getNewEndDate(getTomorrowsDate()),
              campaignEndDate: getNewEndDate(getTomorrowsDate()),
            },
          },
    domoResponse:
      state.DomoQueryResponseState.hasOwnProperty("data") &&
      state.DomoQueryResponseState.data
        ? state.DomoQueryResponseState.data
        : {
            isValidOrderId: false,
            reportLabel: "",
            startDate: "",
            endDate: "",
          },
    progressBarStatus: state.ProgressCRMState.hasOwnProperty("data")
      ? state.ProgressCRMState.data
      : { loaded: 1, total: 1, text: "" },
    coreConfigData: state.AdminCoreConfigListState.hasOwnProperty("data")
      ? state.AdminCoreConfigListState.data
      : [],
  };
}

export default connect(mapStateToProps, (dispatch) => {
  return {};
})(DomoDataFrame);

interface IDomoDataFrame extends React.FC<any> {
  usrSelectedDates?: any;
  domoResponse?: any;
  orderId: string;
  match: any;
  history: any;
  coreConfigData: any;
}
