import Excel from "exceljs";
import ExcelSheet from "./ExcelSheet";

class GenericSheet extends ExcelSheet {
  protected columnCount: number;
  protected colPadd: number;
  protected hasVideo: boolean;
  protected config: any;
  constructor(
    workbook: Excel.Workbook,
    name: string,
    reportDates: any,
    orderInfo: Record<string, string | boolean>,
    logo: any,
    hasVideo: boolean,
    fileName: string
  ) {
    super(workbook, name, reportDates, orderInfo, logo);

    this.config = require("../../data/" + fileName);
    this.columnCount = this.config.columnCount;
    this.colPadd = 1;
    this.hasVideo = hasVideo;
    this.addSheetHeader();
    this.config.colWidths.forEach((row: any, index: number) => {
      this.sheet.getColumn(2 + index).width = row.width;
    });
  }

  public addSection(
    data: any,
    section: string,
    showTotal: boolean = true
  ): void {
    if (!data || data?.length <= 0) {
      return;
    }
    const colReportMapping = this.config;
    if (
      colReportMapping.hasOwnProperty("columnCountIfNoVideo") &&
      this.getColumnRuleCheck(data) <= 0
    ) {
      this.columnCount = colReportMapping.dynamicCols[section]?.stripCol
        ? colReportMapping.dynamicCols[section]?.stripCol
        : 6;
      this.hasVideo = false;
    } else {
      this.columnCount = this.config.columnCount;
      this.hasVideo = true;
    }
    this.sheet.addRow([]).commit();
    const heading = this.getSubColTitles(
      section,
      colReportMapping.dynamicCols,
      colReportMapping.sectionHeading
    );

    if (section === "Geofence" || section === "Keyword") {
      heading[5] = "Geo Conversions";
    }

    const sectionCols = colReportMapping.columns;
    const sectionTotal = colReportMapping.sectionTotal;
    const topHeading = this.addSectionHeading(
      this.hasVideo
        ? heading
        : colReportMapping.dynamicCols[section]?.stripCol
        ? heading.slice(0, colReportMapping.dynamicCols[section]?.stripCol)
        : heading.slice(0, colReportMapping.sliceIfVideo)
    );
    const topDataRow = topHeading.number + 1;

    topHeading.commit();
    data.forEach((row: any, index: number) => {
      const rowContent = this.getSubSectionRowContent(
        colReportMapping.dynamicCols[section].dataKey,
        sectionCols,
        row
      );

      if (section === "Geofence" || section === "Keyword") {
        rowContent[5] =
          row["sf_weighted_actions"] > 0 ? row["sf_weighted_actions"] : "";
      }

      var item = this.sheet.addRow(
        this.hasVideo
          ? rowContent
          : rowContent.slice(
              0,
              colReportMapping.dynamicCols[section]?.stripCol
                ? colReportMapping.dynamicCols[section]?.stripCol
                : colReportMapping.sliceIfVideo
            )
      );

      if (this.hasVideo || colReportMapping.dynamicCols[section]?.CTR) {
        const midCol = item.getCell(5);
        midCol.value = {
          formula: "IFERROR(D" + midCol.row + "/C" + midCol.row + ", 0)",
          date1904: false,
        };

        if (this.hasVideo || colReportMapping.dynamicCols[section]?.VCR) {
          var vcr = item.getCell(heading.length);
          vcr.value = {
            formula: "IFERROR(I" + vcr.row + "/G" + vcr.row + ", 0)",
            date1904: false,
          };
          vcr.style.numFmt = "0.0%";
        }
      }

      item.commit();

      for (var tri = 2; tri <= this.columnCount; tri++) {
        var totalCell = item.getCell(tri);
        totalCell.border = this.standardCellBorder;
        totalCell.alignment = { horizontal: tri === 2 ? "left" : "right" };
        if (tri >= 3 && sectionCols[tri - 3]?.numFmt) {
          item.getCell(tri).style.numFmt = sectionCols[tri - 3].numFmt;
        }
      }
      item.commit();
    });

    if (showTotal) {
      let totalRow;
      if (
        colReportMapping.hasOwnProperty("columnCountIfNoVideo") &&
        this.getColumnRuleCheck(data) <= 0
      ) {
        if (colReportMapping.dynamicCols[section]?.stripCol)
          totalRow = this.sheet.addRow(
            colReportMapping.defaultTotalRow.slice(
              0,
              colReportMapping.dynamicCols[section]?.stripCol
            )
          );
        else
          totalRow = this.sheet.addRow(
            colReportMapping.defaultTotalRow.slice(
              0,
              colReportMapping.sliceIfVideo
            )
          );
      } else {
        totalRow = this.sheet.addRow(colReportMapping.defaultTotalRow);
      }
      for (var tri = 2; tri <= this.columnCount; tri++) {
        var totalCell = totalRow.getCell(tri);
        totalCell.border = this.standardCellBorder;
        totalCell.font = { bold: true, color: { argb: "FF000000" } };
        totalCell.alignment = { horizontal: tri === 2 ? "left" : "right" };
        totalCell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFCCCCCC" },
          bgColor: { argb: "FFFFFFFF" },
        };
        if (sectionTotal[tri - 1]?.formula) {
          this.getFormula(
            totalRow.getCell(tri),
            sectionTotal[tri - 1],
            topDataRow,
            totalRow.number - 1
          );
          totalRow.getCell(tri).style.numFmt = sectionTotal[tri - 1]?.numFmt;
        }
      }
      totalRow.getCell(2).value = "Total";
      totalRow.commit();
    }
  }

  public addData(data: any, isCustom: boolean = false): void {
    if (!data || data?.length <= 0) {
      return;
    }
    const colReportMapping = this.config;
    this.sheet.addRow([]).commit();
    const cols = colReportMapping.sectionHeading;
    const sectionCols = colReportMapping.columns;
    const sectionTotal = colReportMapping.sectionTotal;
    const topHeading = this.addSectionHeading(cols);
    const topDataRow = topHeading.number + 1;
    topHeading.commit();

    data.forEach((row: any, index: number) => {
      const rowContent = this.getRowContent(sectionCols, row);
      var item = this.sheet.addRow(rowContent);
      var vcr = item.getCell(cols.length);
      if (!isCustom) {
        vcr.value = {
          formula: "IFERROR(J" + vcr.row + "/F" + vcr.row + ", 0)",
          date1904: false,
        };
        vcr.style.numFmt = "0.00%";
      }
      item.commit();
      for (var tri = 2; tri <= this.columnCount; tri++) {
        var totalCell = item.getCell(tri);
        totalCell.border = this.standardCellBorder;
        totalCell.alignment = { horizontal: tri === 2 ? "left" : "right" };
        if (sectionCols[tri - 1]?.formula) {
          this.getFormula(
            item.getCell(tri),
            sectionCols[tri - 1],
            this.safeInt(totalCell.row) - 1,
            this.safeInt(totalCell.row) - 1
          );
        } else if (sectionCols[tri - 1]?.dataType == 102) {
          if (this.safeInt(row[sectionCols[tri - 1]?.condition?.firstCol]) == 0)
            item.getCell(tri).value = 0;
          else
            item.getCell(tri).value =
              this.safeInt(row[sectionCols[tri - 1]?.condition?.secondCol]) /
              this.safeInt(row[sectionCols[tri - 1]?.condition?.firstCol]);
        }
        if (sectionCols[tri - 1]?.numFmt) {
          item.getCell(tri).style.numFmt = sectionCols[tri - 1].numFmt;
        }
      }
      item.commit();
    });
    const totalRow = this.sheet.addRow(colReportMapping.defaultTotalRow);
    for (var tri = 2; tri <= this.columnCount; tri++) {
      var totalCell = totalRow.getCell(tri);
      totalCell.border = this.standardCellBorder;
      totalCell.font = { bold: true, color: { argb: "FF000000" } };
      totalCell.alignment = { horizontal: tri === 2 ? "left" : "right" };
      totalCell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFCCCCCC" },
        bgColor: { argb: "FFFFFFFF" },
      };
      if (
        sectionTotal[tri - 1]?.formula &&
        sectionTotal[tri - 1].hasOwnProperty("formula")
      ) {
        this.getFormula(
          totalRow.getCell(tri),
          sectionTotal[tri - 1],
          topDataRow,
          totalRow.number - 1
        );
        totalRow.getCell(tri).style.numFmt = sectionTotal[tri - 1]?.numFmt;
      } else if (
        sectionTotal[tri - 1]?.datatype &&
        sectionTotal[tri - 1].datatype == 200
      ) {
        let sumTse = data
          .map((r: any) =>
            this.safeInt(r[sectionTotal[tri - 1]?.condition?.firstCol])
          )
          .reduce((a: any, b: any) => a + b, 0);
        let sumTs = data
          .map((r: any) =>
            this.safeInt(r[sectionTotal[tri - 1]?.condition?.secondCol])
          )
          .reduce((a: any, b: any) => a + b, 0);
        totalRow.getCell(tri).value = sumTse === 0 ? 0 : sumTs / sumTse;
        totalRow.getCell(tri).style.numFmt = sectionTotal[tri - 1]?.numFmt;
      }
    }
    totalRow.getCell(2).value = "Total";
    totalRow.commit();
  }

  public addSubSection(
    section: string,
    data: Record<string, string | boolean>[],
    isCustom: boolean = false,
    useTopLine: boolean = false
  ): void {
    if (!data || data?.length <= 0) {
      return;
    }
    const colReportMapping = this.config;
    const subSection = useTopLine
      ? colReportMapping.ToplineCols
      : colReportMapping.subSectionCols;
    const rowHeight = useTopLine ? 37 : 30;
    const showTotal = useTopLine && !subSection.showTotal ? false : true;

    this.sheet.addRow([]).commit();
    const heading = this.getSubColTitles(
      section,
      subSection.dynamicCols,
      subSection.commonHeadings
    );

    const topHeading = this.addSectionHeading(
      this.hasVideo ? heading : heading.slice(0, subSection.sliceIfVideo),
      rowHeight
    );
    const topDataRow = topHeading.number + 1;
    topHeading.commit();
    for (const row of data) {
      var rowData = this.getSubSectionRowContent(
        subSection.dynamicCols[section].dataKey,
        subSection.commonColumns,
        row
      );
      var item = this.sheet.addRow(
        this.hasVideo ? rowData : rowData.slice(0, subSection.sliceIfVideo)
      );

      for (var ri = 2; ri <= subSection.subColumnCount; ri++) {
        var cell = item.getCell(ri);
        cell.border = this.standardCellBorder;
        cell.alignment = { horizontal: ri === 2 ? "left" : "right" };

        if (
          (this.hasVideo && ri >= 4 && !isCustom) ||
          subSection.dynamicCols[section]?.CTR
        ) {
          let indx = ri - 2;
          if (ri >= subSection.subColumnCount) {
            indx -= 1;
          }
          item.getCell(ri).style.numFmt = subSection.commonColumns[indx].numFmt;
        } else {
          if (isCustom && ri >= 2 && ri < 4) {
            const colIndex = useTopLine ? 1 : 2;
            item.getCell(ri).style.numFmt =
              subSection.commonColumns[ri - colIndex]?.numFmt;
          }
        }
      }
      if (!isCustom) {
        item.getCell(8).style.numFmt = "#,##0";
      }

      if (isCustom) {
        for (var ri = 4; ri <= subSection.subColumnCount; ri++) {
          let calValue = 0;
          var cell = item.getCell(ri);
          let rIndex = ri - 3;
          if (
            subSection?.commonColumns[rIndex]?.dataType == 100 &&
            subSection?.commonColumns[rIndex]?.formula?.ops == "/"
          ) {
            cell.value = {
              formula:
                "IFERROR(" +
                subSection?.commonColumns[rIndex]?.formula?.startCol +
                cell.row +
                "/" +
                subSection?.commonColumns[rIndex]?.formula?.endCol +
                cell.row +
                ", 0)",
              date1904: false,
            };
          } else if (subSection?.commonColumns[rIndex]?.dataType == 102) {
            const parseMethod = subSection?.commonColumns[rIndex]?.isFloat
              ? this.safeFloat
              : this.safeInt;
            if (
              parseMethod(
                row[subSection?.commonColumns[rIndex]?.condition?.firstCol]
              ) == 0
            )
              calValue = 0;
            else
              calValue =
                parseMethod(
                  row[subSection?.commonColumns[rIndex]?.condition?.secondCol]
                ) /
                parseMethod(
                  row[subSection?.commonColumns[rIndex]?.condition?.firstCol]
                );

            cell.value = calValue;
          }
          cell.style.numFmt = subSection?.commonColumns[rIndex]?.numFmt;
        }
      }

      if (this.hasVideo && !isCustom) {
        var vcr = item.getCell(heading.length);
        const len = subSection?.commonColumns?.length - 1;
        vcr.value = {
          formula:
            "IFERROR(" +
            subSection?.commonColumns[len]?.formula?.startCol +
            vcr.row +
            "/" +
            subSection?.commonColumns[len]?.formula?.endCol +
            vcr.row +
            ", 0)",
          date1904: false,
        };
        vcr.style.numFmt = subSection?.commonColumns[len]?.numFmt;
      }
      //Custom condition - to be removed in future realignment
      if (useTopLine) {
        // RED-3941
        if (subSection?.commonColumns[0]?.dataType == 0) {
          item.getCell(3).alignment = { horizontal: "left" };
        }
        item.getCell(3).style.numFmt = "#,##0";
      }
      item.commit();
    }

    if (showTotal) {
      const totalTemplate = subSection?.subsectionDefaultTotal;
      let totalRow = this.sheet.addRow(
        this.hasVideo ? totalTemplate : totalTemplate.slice(0, 3)
      );

      for (var tri = 2; tri <= subSection?.subColumnCount; tri++) {
        var totalCell = totalRow.getCell(tri);
        totalCell.border = this.standardCellBorder;
        totalCell.font = { bold: true, color: { argb: "FF000000" } };
        totalCell.alignment = { horizontal: tri === 2 ? "left" : "right" };
        totalCell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFCCCCCC" },
          bgColor: { argb: "FFFFFFFF" },
        };
        if (!this.hasVideo && tri >= 4 && !isCustom) {
          continue;
        } else if (
          subSection?.sectionTotal[tri]?.formula &&
          subSection?.sectionTotal[tri]?.formula
        ) {
          this.getFormula(
            totalRow.getCell(tri + 1),
            subSection.sectionTotal[tri],
            topDataRow,
            totalRow.number - 1
          );
          totalRow.getCell(tri + 1).style.numFmt =
            subSection.sectionTotal[tri]?.numFmt;
        } else if (
          !subSection?.sectionTotal[tri]?.formula &&
          subSection?.sectionTotal[tri]?.datatype &&
          subSection.sectionTotal[tri].datatype == 200
        ) {
          const parseMethod = subSection?.sectionTotal[tri]?.isFloat
            ? this.safeFloat
            : this.safeInt;
          let sumTse = data
            .map((r) =>
              parseMethod(r[subSection.sectionTotal[tri]?.condition?.firstCol])
            )
            .reduce((a, b) => a + b, 0);
          let sumTs = data
            .map((r) =>
              parseMethod(r[subSection.sectionTotal[tri]?.condition?.secondCol])
            )
            .reduce((a, b) => a + b, 0);
          totalRow.getCell(tri + 1).value = sumTse === 0 ? 0 : sumTs / sumTse;
          totalRow.getCell(tri + 1).style.numFmt =
            subSection.sectionTotal[tri]?.numFmt;
        }
      }
      totalRow.commit();
    }
  }

  public addTabSection(
    section: string,
    data: Record<string, string | boolean>[],
    isCustom: boolean = false
  ): void {
    if (!data || data?.length <= 0) {
      return;
    }
    const colReportMapping = this.config;
    const tabSection = colReportMapping.tabSectionCols;
    this.sheet.addRow([]).commit();
    const heading = this.getSubColTitles(
      section,
      tabSection.dynamicCols,
      tabSection.commonHeadings
    );
    const topHeading = this.addSectionHeading(heading);
    const topDataRow = topHeading.number + 1;
    topHeading.commit();
    for (const row of data) {
      let rowData = this.getSubSectionRowContent(
        tabSection.dynamicCols[section].dataKey,
        tabSection.commonColumns,
        row,
        true
      );
      let item = this.sheet.addRow(rowData);
      item.getCell(3).style.numFmt = "#,##0";
      for (var ri = 2; ri <= tabSection.subColumnCount; ri++) {
        var cell = item.getCell(ri);
        cell.border = this.standardCellBorder;
        cell.alignment = { horizontal: ri === 2 ? "left" : "right" };
        cell.style.numFmt = tabSection?.subColumnCount[ri]?.numFmt;
      }
      item.commit();
    }
    const totalTemplate = ["", "Total", 0];
    const totalRow = this.sheet.addRow(totalTemplate);
    for (var tri = 2; tri <= tabSection.subColumnCount; tri++) {
      var totalCell = totalRow.getCell(tri);
      totalCell.border = this.standardCellBorder;
      totalCell.font = { bold: true, color: { argb: "FF000000" } };
      totalCell.alignment = { horizontal: tri === 2 ? "left" : "right" };
      totalCell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFCCCCCC" },
        bgColor: { argb: "FFFFFFFF" },
      };
    }
    totalRow.getCell(2).value = "Total";
    totalRow.getCell(3).value = {
      formula: "SUM(C" + topDataRow + ":C" + (totalRow.number - 1) + ")",
      date1904: false,
    };
    totalRow.getCell(3).style.numFmt = "#,##0";
    totalRow.commit();
  }

  public addCTVPlatformSection(
    section: string,
    data: Record<string, string | boolean>[],
    isCustom: boolean = false
  ): void {
    if (!data || data?.length <= 0) {
      return;
    }
    const colReportMapping = this.config;
    const subSection = colReportMapping.subSectionCols;
    this.sheet.addRow([]).commit();
    const heading = this.getSubColTitles(
      section,
      subSection.dynamicCols,
      subSection.commonHeadings
    );
    heading.splice(2, 0, "Platform");
    const topHeading = this.addSectionHeading(
      this.hasVideo ? heading : heading.slice(0, subSection.sliceIfVideo)
    );
    const topDataRow = topHeading.number + 1;
    topHeading.commit();
    const lineCount = this.safeInt(subSection.subColumnCount);
    for (const row of data) {
      var rowData = this.getSubSectionRowContent(
        subSection.dynamicCols[section].dataKey,
        subSection.commonColumns,
        row
      );
      rowData.splice(2, 0, row["platform"]);
      var item = this.sheet.addRow(
        this.hasVideo ? rowData : rowData.slice(0, subSection.sliceIfVideo)
      );
      for (var ri = 2; ri <= lineCount + 1; ri++) {
        var cell = item.getCell(ri);
        cell.border = this.standardCellBorder;
        cell.alignment = { horizontal: ri === 2 || ri == 3 ? "left" : "right" };
      }
      item.getCell(4).style.numFmt = "#,##0";
      item.getCell(5).style.numFmt = "#,##0";
      item.getCell(6).style.numFmt = "#,##0";
      item.getCell(7).style.numFmt = "#,##0";
      item.getCell(8).style.numFmt = "#,##0";
      item.getCell(9).style.numFmt = "#,##0";
      item.getCell(10).value = {
        formula: "IFERROR(I" + cell.row + "/E" + cell.row + ", 0)",
        date1904: false,
      };
      item.getCell(10).style.numFmt = "0.0%";
      item.commit();
    }

    const totalTemplate = JSON.parse(
      JSON.stringify(subSection?.subsectionDefaultTotal)
    );
    totalTemplate.splice(2, 0, "");
    const totalRow = this.sheet.addRow(
      this.hasVideo ? totalTemplate : totalTemplate.slice(0, 3)
    );
    for (var tri = 2; tri <= lineCount + 1; tri++) {
      var totalCell = totalRow.getCell(tri);
      totalCell.border = this.standardCellBorder;
      totalCell.font = { bold: true, color: { argb: "FF000000" } };
      totalCell.alignment = { horizontal: tri === 2 ? "left" : "right" };
      totalCell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFCCCCCC" },
        bgColor: { argb: "FFFFFFFF" },
      };
    }
    totalRow.getCell(4).value = {
      formula: "SUM(D" + topDataRow + ":D" + (totalRow.number - 1) + ")",
      date1904: false,
    };
    totalRow.getCell(4).style.numFmt = "#,##0";
    totalRow.getCell(5).value = {
      formula: "SUM(E" + topDataRow + ":E" + (totalRow.number - 1) + ")",
      date1904: false,
    };
    totalRow.getCell(5).style.numFmt = "#,##0";
    totalRow.getCell(6).value = {
      formula: "SUM(F" + topDataRow + ":F" + (totalRow.number - 1) + ")",
      date1904: false,
    };
    totalRow.getCell(6).style.numFmt = "#,##0";
    totalRow.getCell(7).value = {
      formula: "SUM(G" + topDataRow + ":G" + (totalRow.number - 1) + ")",
      date1904: false,
    };
    totalRow.getCell(7).style.numFmt = "#,##0";
    totalRow.getCell(8).value = {
      formula: "SUM(H" + topDataRow + ":H" + (totalRow.number - 1) + ")",
      date1904: false,
    };
    totalRow.getCell(8).style.numFmt = "#,##0";
    totalRow.getCell(9).value = {
      formula: "SUM(I" + topDataRow + ":I" + (totalRow.number - 1) + ")",
      date1904: false,
    };
    totalRow.getCell(9).style.numFmt = "#,##0";
    totalRow.getCell(10).value = {
      formula: "IFERROR(I" + totalRow.number + "/E" + totalRow.number + ", 0)",
      date1904: false,
    };
    totalRow.getCell(10).style.numFmt = "0.0%";
    totalRow.commit();
  }

  getRowContent(sectionCols: any, row: any) {
    const rowContent: any[] = [];
    sectionCols.forEach((element: any, index: number) => {
      switch (element.dataType) {
        case -1:
          rowContent.push(" ");
          break;
        case 0:
          {
            if (element?.subStr == 0) {
              rowContent.push(row[element.name]);
            } else {
              rowContent.push(
                String(row[element.name]).substr(0, element.subStr)
              );
            }
          }
          break;
        case 1:
          {
            const parseMethod = element?.isFloat
              ? this.safeFloat
              : this.safeInt;
            rowContent.push(parseMethod(row[element.name]));
          }
          break;
        case 100:
          {
            rowContent.push(0);
          }
          break;
        case 101:
          {
            if (row[element.name] == element?.rule?.colCompare)
              rowContent.push(String(element.rule.setVal));
            else rowContent.push(String(element.rule.resetVal));
          }
          break;
        case 102:
          {
            if (this.safeInt(row[element.condition?.firstCol]) == 0)
              rowContent.push(0);
            else
              rowContent.push(
                this.safeInt(row[element.condition?.secondCol]) /
                  this.safeInt(row[element.condition?.firstCol])
              );
          }
          break;
      }
    });
    return rowContent;
  }

  getSubSectionRowContent(
    sectionName: string,
    sectionCols: any,
    row: any,
    isTab: boolean = false
  ) {
    const rowContent: any[] = [""];
    if (!isTab) rowContent.push(row[sectionName]);
    sectionCols.forEach((element: any, index: number) => {
      switch (element.dataType) {
        case 0:
          {
            if (element?.subStr == 0) {
              rowContent.push(row[element.name]);
            } else {
              rowContent.push(
                String(row[element.name]).substr(0, element.subStr)
              );
            }
          }
          break;
        case 1:
          {
            const parseMethod = element?.isFloat
              ? this.safeFloat
              : this.safeInt;
            rowContent.push(parseMethod(row[element.name]));
          }
          break;
        case 100:
          {
            rowContent.push(0);
          }
          break;
        case 102:
          {
            if (this.safeInt(row[element.condition?.firstCol]) == 0)
              rowContent.push(0);
            else
              rowContent.push(
                this.safeInt(row[element.condition?.secondCol]) /
                  this.safeInt(row[element.condition?.firstCol])
              );
          }
          break;
      }
    });
    return rowContent;
  }

  getFormula(totalRow: any, sectionCols: any, topDataRow: any, toRow: any) {
    switch (sectionCols.formula.ops) {
      case "+":
        totalRow.value = {
          formula:
            "SUM(" +
            sectionCols.formula.startCol +
            topDataRow +
            ":" +
            sectionCols.formula.endCol +
            toRow +
            ")",
          date1904: false,
        };
        break;
      case "/":
        {
          const firstCol = `${sectionCols.formula.startCol}${toRow + 1}`;
          const secondCol = `${sectionCols.formula.endCol}${toRow + 1}`;
          const divRange = `${firstCol}/${secondCol}`;
          totalRow.value = { formula: `IFERROR($${divRange},0)` };
        }
        break;
      case "%":
        {
        }
        break;
    }
  }

  getSubColTitles(key: string, dynamicCols: any, commonTitles: any) {
    let headings = ["", dynamicCols[key].title];
    headings = headings.concat(commonTitles);
    return headings;
  }

  getColumnRuleCheck(data: any) {
    const newData = data.filter(
      (d: any) => this.safeInt(d["Video_Starts"]) > 0
    );
    return newData.length;
  }

  //RED-3961
}
export default GenericSheet;
