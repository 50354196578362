/* eslint-disable */
export function safeNumber(txt: string): number {
    const result = parseFloat(txt)
    return isNaN(result) ? 0 : result
}

export function numberWithCommas(x: number, decimals: number) {
    return x.toFixed(decimals).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function acronymRate(rate: string): string {
    switch(rate) {
        case 'Cost Per Day': return 'CPD'
        case 'Cost Per Unit': return 'CPU'
        case 'CPC': return rate
        case 'CPM': return rate
        case 'various': return rate  // TODO what's that about?
    }

    return rate
}

export function strToDate(date: string): Date {
    return new Date(
        parseInt(date.substr(0, 4)),
        (parseInt(date.substr(5, 2)) - 1),
        parseInt(date.substr(8, 2))
    )
}

export function zeroPadNumber(num: number): string {
    return (num > 9 ? '' : '0') + num.toString()
}

export function dateToMmmDd(date: Date): string {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    return months[date.getMonth()] + ' ' + zeroPadNumber(date.getDate())
}

export function dateToMmmDdYyyy(date: Date): string {
    return dateToMmmDd(date) + ' ' + date.getFullYear()
}

export function dateToYyyyMmDd(date: Date): string {
    return date.getFullYear().toString() + '-' + 
        zeroPadNumber(date.getMonth() + 1) + '-' +
        zeroPadNumber(date.getDate())
}