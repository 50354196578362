/* eslint-disable */
import * as React from "react";
import { connect } from "react-redux";

import {
  slickStateAction,
  submitUIConfigAction,
  setToggleStateAction,
} from "../../Actions";
import { NavBarConstants } from "../../ConstConfig";
import { fakeAudienceList } from "../../Utility/dummyAttribs";
import { FormattedNumber } from "react-intl";
import { ConstAction } from "../../ConstConfig/ConstAction";
import ConfirmDialog from "../../CommonComponent/ConfirmDialog";
import Logger from "../../rogersframework/Logger/Logger";
import {
  convertToUTCDateForYYYYMMDD,
  getAudienceCreationDate,
  getPaginationMatrices,
} from "../utils/dashboardValidation";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

import { UserOps } from "../../ConstConfig/UserOps";
import "../Audiences.css";
import { getFormattedViabilityMsg } from "../../AudienceBuilder/audienceBuilderUtil";
import MessageBox from "../../CommonComponent/MessageBox";

class Audiences extends React.Component<IAudiences, {}> {
  public state: any;
  audienceListTableOptions: any;
  audienceListTableOptionsSmall: any;
  searchText: any;
  tableProps: any;
  pageProps: any;
  pageObject: any;
  sortedColumn: any;
  static MAX_ROWSIZE = 25;
  pageWidth: any;
  refTable: any;
  constructor(props: any) {
    super(props);
    this.getActionControls = this.getActionControls.bind(this);
    this.createCampgain = this.createCampgain.bind(this);
    this.getColViewPerBreakpoint = this.getColViewPerBreakpoint.bind(this);
    this.showMethodology = this.showMethodology.bind(this);
    this.audienceListTableOptions = {
      hidePageListOnlyOnePage: true,
      hideSizePerPage: true,
      sizePerPage: Audiences.MAX_ROWSIZE, // which size per page you want to locate as default
      defaultSortName: "lastUpdated",
      defaultSortOrder: "desc",
      searchPosition: "left",
      paginationPanel: this.renderPaginationPanel,
      searchPanel: this.customSerachPanel,
      toolBar: this.createCustomToolBar,
    };

    this.audienceListTableOptionsSmall = {
      hidePageListOnlyOnePage: true,
      hideSizePerPage: true,
      sizePerPage: Audiences.MAX_ROWSIZE, // which size per page you want to locate as default
      searchPosition: "left",
      paginationPanel: this.renderPaginationPanel,
      searchPanel: this.customSerachPanel,
      toolBar: this.createCustomToolBar,
    };

    this.renderPaginationPanel = this.renderPaginationPanel.bind(this);
    this.searchText = null;
    this.refTable = null;
    this.searchInput = this.searchInput.bind(this);
    this.searchTextInTable = this.searchTextInTable.bind(this);
    this.onContentChange = this.onContentChange.bind(this);
    this.changePageTo = this.changePageTo.bind(this);
    this.pageObject = { currPage: 1, showMaxPage: -1, pageStartIndex: 1 };
    this.customSortStyle = this.customSortStyle.bind(this);
    this.showCreateAudiencePanel = this.showCreateAudiencePanel.bind(this);
    this.sortedColumn = "lastUpdated";
    this.getSortedStyle = this.getSortedStyle.bind(this);
    this.getDateSortedFormat = this.getDateSortedFormat.bind(this);
    this.formatNumber = this.formatNumber.bind(this);
    this.showPanel = this.showPanel.bind(this);
    this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this);
    this.pageWidth = window.innerWidth;
    this.loadAudienceSizerPage = this.loadAudienceSizerPage.bind(this);
    this.getAllColView = this.getAllColView.bind(this);
    this.pageProps = { totalPages: -2 };
  }
  createCampgain() {
    const dummyUserObj = {
      UserAction: "SlickPosition",
      selectedTab: NavBarConstants.AUDIENCESIZINGSLICK,
      slickIdx: NavBarConstants.AUDIENCESIZINGSLICK,
      source: "NEW",
    };
    this.props.handleSubmit(dummyUserObj);
    this.props.submitToggleSwitchAction({ switchState: false });
    this.props.history.push("/AudienceSizer");
  }
  /*
        Sorting icons
    */
  changeCaret(order: any, column: any) {
    if (order && order === "desc") {
      return <i className="descSort float-right" />;
    } else if (order && order === "asc") {
      return <i className="ascSort float-right" />;
    }
    return <i className="sortInactive float-right" />;
  }

  changePageTo(changePage: any, direction: any, pageNum: any, e: any) {
    if (direction === "prev") {
      this.pageObject.currPage -= 1;
    } else if (direction === "next") {
      this.pageObject.currPage += 1;
    } else if (direction === "first") {
      this.pageObject.currPage = 1;
    } else if (direction === "last") {
      this.pageObject.currPage = this.pageProps.totalPages;
    } else if (direction === "pageSwitch") {
      this.pageObject.currPage = pageNum;
    }
    if (this.pageObject.currPage >= this.pageProps.totalPages) {
      this.pageObject.currPage = this.pageProps.totalPages;
    }
    if (this.pageObject.currPage <= 1) {
      this.pageObject.currPage = 1;
    }
    changePage(this.pageObject.currPage);
  }

  createCustomToolBar = (props: any) => {
    return <div className="col-12">{props.components.searchPanel}</div>;
  };

  customSortStyle(order: any, dataField: any) {
    if ((order && order === "desc") || order === "asc") {
      this.sortedColumn = dataField;
      return "boldText";
    }
    return "";
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  UNSAFE_componentWillMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  formatNumber(cell: any, row: any, formatExtraData: any, index: any) {
    let style = formatExtraData === this.sortedColumn ? "boldText" : "";
    return (
      <span className={style}>
        <FormattedNumber
          value={cell.toString()}
          style="decimal"
          minimumFractionDigits={0}
          maximumFractionDigits={0}
        />
      </span>
    );
  }

  formatViability(cell: any, row: any, formatExtraData: any, index: any) {
    let style = formatExtraData === this.sortedColumn ? "boldText" : "";
    let formattedText = getFormattedViabilityMsg(cell, formatExtraData);

    return <span className={style}>{formattedText.msg}</span>;
  }
  getColViewPerBreakpoint(cell: any, row: any, enumObject: any) {
    return (
      <span>
        <span className="audienceFrstColWidth visible-md visible-sm visible-xs">
          {this.getAllColView(cell, row, enumObject)}
        </span>
        <span className="visible-lg">{cell.toString()}</span>
      </span>
    );
  }

  getSortedColClass(
    fieldValue: any,
    row: any,
    colIdx: any,
    rowIdx: any,
    fieldName: any,
    e: any
  ) {
    let colClsName = " ";
    let totalPages = Math.floor(
      this.props.audienceList.size / Audiences.MAX_ROWSIZE
    );

    if (fieldValue === this.sortedColumn) {
      colClsName = "rtLfBorder ";
    }

    if (
      fieldValue === this.sortedColumn &&
      totalPages <= 1 &&
      rowIdx === this.props.audienceList.size - 1
    ) {
      colClsName += " btmfBorder ";
    } else if (
      fieldValue === this.sortedColumn &&
      totalPages >= 1 &&
      rowIdx > 0 &&
      rowIdx % 24 === 0
    ) {
      colClsName += " btmfBorder ";
    } else if (
      fieldValue === this.sortedColumn &&
      rowIdx >= 0 &&
      totalPages >= 1 &&
      rowIdx ===
        this.props.audienceList.size - 25 * (this.pageObject.currPage - 1) - 1
    ) {
      colClsName += " btmfBorder ";
    }

    return colClsName;
  }
  getSortedColCornerClass(fieldName: any, e: any) {
    if (fieldName === this.sortedColumn) {
      return "rtTopLfBorder";
    }
    return "rtNoTopLfBorder";
  }

  getAllColView(cell: any, row: any, enumObject: any) {
    const date = getAudienceCreationDate(row.lastUpdated);
    return (
      <div className="listA">
        <h4>{cell}</h4>
        <div className="row-flex">
          <div className="col-md-4 pl-0 pr-0">
            <span>Updated on:</span>
            <span>{date}</span>
          </div>
          <div className="col-md-12 mt-4 pl-0 pr-0 smallBtnPanel">
            <a
              className="btn btnSmall-secondary"
              href="javascript:void(0);"
              role="button"
              onClick={this.handleSubmit.bind(this, row, ConstAction.EDIT)}
            >
              VIEW
            </a>
            <a
              className="btn btnSmallDarkGray"
              href="javascript:void(0);"
              onClick={this.handleSubmit.bind(this, row, ConstAction.DELETE)}
              data-toggle="modal"
              data-target="#deleteAudienceModal"
            >
              DELETE
            </a>
          </div>
        </div>
      </div>
    );
  }

  getSortedStyle(cell: any, row: any, formatExtraData: any, index: any) {
    let style = formatExtraData === this.sortedColumn ? "boldText" : "";
    const data = cell ? cell : "";
    return <span className={style}>{data.toString()}</span>;
  }

  getDateSortedFormat(cell: any, row: any, formatExtraData: any, index: any) {
    let style = formatExtraData === this.sortedColumn ? "boldText" : "";
    let respDate = convertToUTCDateForYYYYMMDD(cell.toString());
    return <span className={style}>{respDate}</span>;
  }

  /*
    Last column view/launch
    */
  getActionControls(cell: any, row: any, formatExtraData: any, rowIx: any) {
    return (
      <span>
        <a
          className="btn btnSmall-secondary mr-lg-2"
          href="javascript:void(0);"
          role="button"
          onClick={this.handleSubmit.bind(this, row, ConstAction.EDIT)}
        >
          VIEW
        </a>
        <a
          className="btn btnSmallDarkGray mr-lg-2"
          href="javascript:void(0);"
          role="button"
          onClick={this.handleSubmit.bind(this, row, ConstAction.DELETE)}
          data-toggle="modal"
          data-target="#deleteAudienceModal"
        >
          DELETE
        </a>
      </span>
    );
  }

  handleWindowSizeChange(e: any) {
    this.pageWidth = window.innerWidth;
  }

  handleSubmit(rowContent: any, action: any, evt: any) {
    if (action === ConstAction.DELETE) {
      Logger.getInstance().printDebugLogs(rowContent);
      rowContent["deleteAudience"] = true;
      this.props.handleDialogSubmitAction(true, rowContent);
    } else if (action === ConstAction.EDIT) {
      this.props.handleEditAudienceAction(true, rowContent, this.props.history);
    }
    Logger.getInstance().printDebugLogs("Handle");
  }

  /*
        Search Panel
    */
  customSerachPanel = (props: any) => {
    this.tableProps = props;
    const contxt = this;
    function search(e: any) {
      contxt.searchText = e.target.value;
      props.search(e.target.value);
    }

    return (
      <div className="row-flex ml-1 mr-1">
        <div className="col-xl-12 col-md-4 pl-0 pr-0 order-mb-first">
          <h3>Audience Templates</h3>
        </div>
        <div className="col-xl-4 col-md-8 col-sm-12 col-12 pl-0 pr-0 searchPanel order-mb-1">
          <input
            type="text"
            placeholder="Search your audiences"
            name="search"
            onChange={search}
          />
          <button className="" type="submit" onClick={this.searchTextInTable}>
            <i className="searchBtnInactive float-right" />
          </button>
        </div>
        <div className="col-xl-8 col-md-6 col-sm-4 col-5 pr-0 pl-0 spaceTop spaceBottom spacerBottom0 order-mb-0">
          <a
            className="btn btnPrimary float-xl-right float-md-right"
            href="javascript:void(0);"
            role="button"
            onClick={this.createCampgain}
          >
            Create New Audience
          </a>
        </div>
      </div>
    );
  };

  onContentChange(e: any) {
    this.searchText = e.target.value;
    this.tableProps.search(this.searchText);
  }

  loadAudienceSizerPage(row?: any) {
    this.props.handleEditAudienceAction(true, row, this.props.history);
  }

  searchTextInTable() {
    if (this.searchText && this.searchText.value != "")
      this.tableProps.search(this.searchText);
  }

  searchInput(comp: any) {
    this.searchText = comp;
  }

  showMethodology() {
    const dummyUserObj = {
      UserAction: "SlickPosition",
      selectedTab: NavBarConstants.TERMINOLOGY,
      slickIdx: NavBarConstants.TERMINOLOGYSLICK,
    };
    this.props.handleSubmit(dummyUserObj);
  }

  /*
    Pagination
    */
  showPanel() {
    if (this.searchText && this.searchText.trim().length > 0) {
      return true;
    }
    return false;
  }

  showCreateAudiencePanel() {
    return (
      <table className="table table-borderless">
        <tbody>
          <tr className="addRow">
            <td
              className="col-xl-6 col-lg-12 col-md-6 col-sm-12 col-12"
              onClick={this.createCampgain}
            >
              <a onClick={this.createCampgain}>
                <span className="addBtn float-left mr-2" />
                CREATE AUDIENCE
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  renderPaginationPanel = (props: any) => {
    this.pageProps = props;
    const pageList: { pageNum: number; classLabel: string }[] = [];
    let displayMaxPageList = 11;
    if (this.pageWidth <= 500) {
      displayMaxPageList = 3;
    }
    this.pageObject.showMaxPage =
      props.totalPages < displayMaxPageList
        ? props.totalPages
        : this.pageObject.showMaxPage > -1
        ? this.pageObject.showMaxPage
        : displayMaxPageList;

    this.pageObject.maxPageSize = props.totalPages;
    let i = 1;
    const contxt = this;
    function changePageTo(
      changePage: any,
      direction: any,
      pageNum: Number,
      e: any
    ) {
      if (direction === "prev") {
        contxt.pageObject.currPage -= 1;
      } else if (direction === "next") {
        contxt.pageObject.currPage += 1;
      } else if (direction === "first") {
        contxt.pageObject.currPage = 1;
      } else if (direction === "last") {
        contxt.pageObject.currPage = props.totalPages;
      } else if (direction === "pageSwitch") {
        contxt.pageObject.currPage = pageNum;
      }
      if (contxt.pageObject.currPage >= props.totalPages) {
        contxt.pageObject.currPage = props.totalPages;
      }

      if (contxt.pageObject.currPage <= 1) {
        contxt.pageObject.currPage = 1;
      }
      contxt.pageObject = getPaginationMatrices(
        contxt.pageObject,
        direction,
        displayMaxPageList
      );
      props.changePage(contxt.pageObject.currPage);
      return;
    }

    for (
      i = this.pageObject.pageStartIndex;
      i <= this.pageObject.showMaxPage;
      i++
    ) {
      pageList.push({
        pageNum: i,
        classLabel: this.pageObject.currPage === i ? "selected" : "",
      });
    }
    if (
      props &&
      props.components.pageList &&
      props.components.pageList.props.children.length > 1
    )
      return (
        <div className="col-12 customLFPad pl-15 pr-15">
          {this.showCreateAudiencePanel()}
          <div className="paginationTable spacerV72">
            <ul>
              <li
                onClick={changePageTo.bind(
                  this,
                  props.changePage,
                  UserOps.FIRST_AUD,
                  null
                )}
              >
                <span className="paginationFirst float-left" />
                First
              </li>
              <li
                onClick={changePageTo.bind(
                  this,
                  props.changePage,
                  UserOps.PREV_AUD,
                  null
                )}
              >
                <span className="paginationPrev float-left" />
              </li>
              {pageList.map((cell: any, i: any) => {
                return (
                  <li
                    key={"key" + i}
                    className={cell.classLabel + " pageNum "}
                    onClick={changePageTo.bind(
                      this,
                      props.changePage,
                      UserOps.PAGE_NUM,
                      cell.pageNum
                    )}
                  >
                    {cell.pageNum}
                  </li>
                );
              })}

              <li
                onClick={changePageTo.bind(
                  this,
                  props.changePage,
                  UserOps.NEXT_AUD,
                  null
                )}
              >
                <span className="paginationNext float-right" />
              </li>
              <li
                onClick={changePageTo.bind(
                  this,
                  props.changePage,
                  UserOps.LAST_AUD,
                  null
                )}
              >
                <span className="paginationLast float-right" />
                Last
              </li>
            </ul>
          </div>
          <div className="hSpacer20" />
          <div className="hSpacer20" />
        </div>
      );
    else if (props.totalPages <= 1) {
      return (
        <div className="col-12 customLFPad pl-15 pr-15">
          {this.showCreateAudiencePanel()}
        </div>
      );
    }
    return <span />;
  };

  render() {
    return (
      <main role="main" className="container-fluid">
        <div className="row fixed-header-top ml-0 mr-0">
          <div className="col-12">
            <div className="d-desk-block d-ipad-none d-mb-none table-responsive-xl tableRedui">
              <BootstrapTable
                data={this.props.audienceList.toArray()}
                version="4"
                striped={true}
                hover={true}
                condensed={false}
                options={this.audienceListTableOptions}
                pagination={true}
                trClassName="table table-borderless"
                search={true}
                bordered={false}
                multiColumnSearch={false}
              >
                <TableHeaderColumn
                  dataField="name"
                  dataSort={true}
                  isKey={true}
                  caretRender={this.changeCaret}
                  sortHeaderColumnClassName={this.customSortStyle}
                  className={this.getSortedColCornerClass.bind(this, "name")}
                  columnClassName={this.getSortedColClass.bind(this, "name")}
                  dataFormat={this.getSortedStyle}
                  formatExtraData="name"
                >
                  Audience Name
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataFormat={this.getDateSortedFormat}
                  formatExtraData="lastUpdated"
                  dataField="lastUpdated"
                  dataSort={true}
                  caretRender={this.changeCaret}
                  sortHeaderColumnClassName={this.customSortStyle}
                  className={this.getSortedColCornerClass.bind(
                    this,
                    "lastUpdated"
                  )}
                  columnClassName={this.getSortedColClass.bind(
                    this,
                    "lastUpdated"
                  )}
                  searchable={false}
                >
                  Last Update
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataFormat={this.getActionControls}
                  className="actionWd"
                  width="200"
                  columnClassName="actionWd"
                >
                  Actions
                </TableHeaderColumn>
              </BootstrapTable>
              <div className="hSpacer20" />
              <div className="hSpacer20" />
              <div className="hSpacer20" />
            </div>
            <div className="tableRedui d-desk-none d-ipad-block d-mb-block iPadLandscape-block">
              <BootstrapTable
                data={this.props.audienceList.toArray()}
                version="4"
                striped={true}
                hover={true}
                condensed={false}
                options={this.audienceListTableOptionsSmall}
                pagination={true}
                trClassName="table  table-borderless tableRedui"
                search={true}
                bordered={false}
                ref={(node) => (this.refTable = node)}
                tableHeaderClass={"tableColHeaderHid"}
              >
                <TableHeaderColumn
                  row={0}
                  rowSpan={0}
                  className="tableColHeaderHid"
                  dataField="name"
                  isKey={true}
                  dataFormat={this.getAllColView}
                />
                <TableHeaderColumn
                  dataField="people"
                  searchable={false}
                  hidden={true}
                />
                <TableHeaderColumn
                  dataField="tvSize"
                  searchable={false}
                  hidden={true}
                />
                <TableHeaderColumn
                  dataField="digitalSize"
                  dataSort={true}
                  searchable={false}
                  hidden={true}
                />
              </BootstrapTable>
              <div className="hSpacer20" />
              <div className="hSpacer20" />
              <div className="hSpacer20" />
              <div className="hSpacer20" />
            </div>
          </div>
        </div>
        <ConfirmDialog />
        <MessageBox handleUserAction={this.loadAudienceSizerPage} />
      </main>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    audienceList: state.AudiencesState.hasOwnProperty("AudienceList")
      ? state.AudiencesState.AudienceList
      : fakeAudienceList(),
  };
}

export default connect(mapStateToProps, (dispatch) => {
  return {
    handleSubmit: (dummyUserObj: any) => {
      dispatch(slickStateAction(dummyUserObj));
    },
    handleDialogSubmitAction: (show: boolean, rowContent: any) => {
      const payload = {
        Dialog: { isVisible: show, content: rowContent, initial: true },
      };
      dispatch(submitUIConfigAction(payload));
    },
    handleEditAudienceAction: (
      editAudience: boolean,
      rowContent: any,
      histry: any
    ) => {
      const payload = {
        Dialog: {
          editAudienceAction: true,
          content: rowContent,
          history: histry,
        },
      };
      dispatch(submitUIConfigAction(payload));
    },
    submitToggleSwitchAction: (payload: any) => {
      dispatch(setToggleStateAction(payload));
    },
    showMethdology(messageBoxObj: any) {
      dispatch(submitUIConfigAction(messageBoxObj));
    },
  };
})(Audiences);

interface IAudiences extends React.FC<any> {
  propsFromStore: any;
  handleSubmit: any;
  handleChange: any;
  loggingIn: any;
  audienceList: any;
  handlePass: any;
  handleDialogSubmitAction?: any;
  handleEditAudienceAction?: any;
  submitToggleSwitchAction?: any;
  showMethdology?: any;
  history: any;
}
