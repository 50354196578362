import { List } from 'immutable';
import { convertToDate } from '../../Utility/reportingValidation';

export class PacingModel {
    reportsList: any;
    rowId: any;
    constructor(list: any) {
        this.reportsList = [];
        this.rowId = -1;
    }

    public buildModel(list: any) {
        
        this.reportsList = [];
        let contxt = this;
        if(list.length>0)
        {
        list.map((item: any, i: any) => {
            let fObject = contxt.extractFileName(item);
            contxt.setReportingListItems(item, fObject);
          });
        }
    }

    public getReportingListPerRow(reportingRowID: any) {

    }

    public isReportingListEmpty() {
        if (this.reportsList.length <= 0) {
            return true;
        }
        return false;
    }

    public getReportingList() {
        return List(this.reportsList);
    }

    public fillAndResetReportList(payload: any) {
        this.buildModel(payload);
    }

    public setReportingItem(reptItem: any, fObject: any) {
        let proposalId = reptItem.proposalId;;
        this.rowId++;

         let reportEntryObj = {
            reportId:reptItem.reportId,
            rowId: this.rowId,
            advertiserName:reptItem.advertiserName,
            campaignStartDate:  convertToDate(reptItem.campaignStartDate, false) ,
            campaignEndDate:convertToDate(reptItem.campaignEndDate, false),
            trackableBookedImpressions:reptItem.trackableBookedImpressions?reptItem.trackableBookedImpressions:0,
            trackableActualImpressions:reptItem.trackableActualImpressions?reptItem.trackableActualImpressions:0,
            untrackableBookedImpressions:reptItem.untrackableBookedImpressions?reptItem.untrackableBookedImpressions:0,
            trackableBookedSpend:reptItem.trackableBookedSpend?("$"+reptItem.trackableBookedSpend.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")):"$0.00",
            trackableActualSpend:reptItem.trackableActualSpend?("$"+reptItem.trackableActualSpend.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")):"$0.00",
            coordinator:reptItem.coordinator,
            accountManager:reptItem.accountManager,
            totalDays:reptItem.totalDays?reptItem.totalDays:'0',
            daysLeft:reptItem.daysLeft?reptItem.daysLeft:'0',
            pacing:(reptItem.pacing > 0)?((reptItem.pacing).toFixed(2)+'%'): '0.00%',
           // pacing:(reptItem.pacing > 0)?(Math.round(reptItem.pacing)+'%'): '0.00%',
            fileName: fObject.fileName,
            proposalId: proposalId,
            isExcelAvailable: false,
            isPPTAvailable: false,
        };
        reportEntryObj.isExcelAvailable = fObject.excel;
        reportEntryObj.isPPTAvailable = fObject.ppt
        return reportEntryObj;
    }

    public setReportingListItems(reqObj: any, fObject: any) {
        let audienceItem = this.setReportingItem(reqObj, fObject);
        this.reportsList.push(audienceItem);
    }

    public extractFileName(row:any) {
        let fileObj = {fileName:"",excel:false,ppt:false, propId:""};
        let delemeterIndex = 0;
        if (row.hasOwnProperty("ppt_file_name") && row.ppt_file_name && row.ppt_file_name !== "") {
            delemeterIndex = row.ppt_file_name.indexOf("-");
            fileObj.fileName =  row.ppt_file_name.substring(delemeterIndex + 1, row.ppt_file_name.length-4);
            fileObj.propId = row.ppt_file_name.substring(0, delemeterIndex - 1);
            fileObj.ppt = true;
        }

        if (row.hasOwnProperty("excel_file_name") && row.excel_file_name && row.excel_file_name !=="") {
            delemeterIndex = row.excel_file_name.indexOf("-");
            fileObj.fileName =  row.excel_file_name.substring(delemeterIndex + 1, row.excel_file_name.length-4);
            fileObj.propId = row.excel_file_name.substring(0, delemeterIndex - 1);
            fileObj.excel = true;
        }
        return fileObj;
    }
}

export default PacingModel;