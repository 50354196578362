import axios from "axios";
import { getAuthContext } from "src/Login/MSAL/msalConfig";
import { AxiosClient } from "../ClientServices/AxiosClient";
import {
  getObjectValueOfKey,
  isApiDataNeeded,
  summaryTransformation,
  transformRaw,
  transformRows,
} from "../Store/helper";
import Excel from "exceljs";
import { saveAs } from "file-saver";
import SummarySheet from "src/Reporting/Component/SummarySheet";
import GAMData, { GAMSection } from "src/Reporting/Component/GAMData";
import * as KpiRollup from "../../Reporting/utils/KpiRollup";
import { updateProgressCRM } from "src/Actions";
import GenericSheet from "src/Reporting/Component/GenericSheet";
import ToplineSummary, {
  ToplineSummaryHead,
} from "src/Reporting/Component/ToplineSummary";
import EblastInsights from "src/Reporting/Component/EblastInsights";
import VideoSuiteInsights from "src/Reporting/Component/VideoSuiteInsights";

const Data = require("src/images/rsm_logo.png");

const reduiExcelReporting = (store: any) => (next: any) => (action: any) => {
  if (
    action &&
    action.hasOwnProperty("type") &&
    action.type === "DWONLOAD_EXCEL"
  ) {
    const clnt = new AxiosClient(store);
    const token = getAuthContext().idToken.rawIdToken;
    let userAction = action.type;
    switch (userAction) {
      case "DWONLOAD_EXCEL":
        {
          const postReqList: Promise<any>[] = [];
          const resultSet: any[] = [];
          const columnKeyMapping = require("../../data/GamColMapping.json");
          action.payload.data.forEach((element: any) => {
            if (isApiDataNeeded(action.payload.excelPayload, element)) {
              let criteria = [
                "display",
                "video",
                "summary",
                "takeover",
                "adsWiz",
                "redX",
                "twitter",
                "facebook",
                "conto",
                "vod",
                "ctvott",
                "newsLetter",
                "newsLetterX",
                "legacysummary",
                "videoSuite",
                "eBlast",
              ].includes(element.criteria)
                ? getCriteria(
                    element.criteria,
                    action.payload.dates,
                    element.queryId
                  )
                : "";

              const payload = {
                queryId: element.queryId,
                queryItems: {
                  ORDERID: action.payload.orderId,
                  ADDEDCRITERIA: criteria,
                },
              };
              const reqObjectSeg = {
                authToken: token,
                url: action.payload.url,
              };
              const postReq = clnt.post_reporting(
                "POST",
                reqObjectSeg,
                payload,
                true,
                element.display
              );
              postReqList.push(postReq);
              resultSet.push({
                key: element.queryId + element.criteria,
                orgKey: element.queryId,
                criteria: element.criteria,
                val: {},
                transform: element.isTransformNeeded,
              });
            }
          });
          axios
            .all(postReqList)
            .then(
              axios.spread((...responses) => {
                responses.forEach((element: any, index: any) => {
                  const isTransformNeeded = resultSet[index].transform;
                  const key = resultSet[index].key;
                  if (isTransformNeeded) {
                    const val = transformRows(element, columnKeyMapping[key]);
                    resultSet[index].val = val;
                  } else {
                    resultSet[index].val = element;
                  }
                });
                store.dispatch(
                  updateProgressCRM({
                    data: {
                      total: 0,
                      loaded: 9999,
                      text: "Exporting...",
                    },
                  })
                );
                saveExcel(
                  action.payload.dates,
                  action.payload.excelPayload,
                  action.payload.orderId,
                  resultSet
                );
                store.dispatch(
                  updateProgressCRM({
                    data: {
                      total: 0,
                      loaded: 10000,
                      text: "Downloaded",
                    },
                  })
                );
                localStorage.removeItem("orderId");
              })
            )
            .catch((errors) => {});
        }
        break;
    }
  }
  return next(action);
};

function saveExcel(
  reportDates: any,
  excelPayload: any,
  orderId: any,
  resultSet: any
) {
  const wb = new Excel.Workbook();
  let logo = null;
  fetch(Data)
    .then((response: Response) =>
      response.status === 200 || response.status === 0
        ? response.arrayBuffer()
        : Promise.reject()
    )
    .then((data: ArrayBuffer) => {
      logo = data;
      let image = null;
      if (logo) {
        const png = new DataView(new Uint8Array(logo).buffer);
        var width = png.getUint32(16);
        var height = png.getUint32(20);
        const logoId = logo
          ? wb.addImage({
              buffer: logo,
              extension: "png",
            })
          : -1;
        image = {
          id: logoId,
          width: width,
          height: height,
          size: logo.byteLength,
          format: "png",
          data: logo,
        };
      }

      let result = getObjectValueOfKey(resultSet, "toplinesummarysummary");
      if (result && result.hasOwnProperty("rows") && result.rows.length > 0) {
        const sheetName = "Topline Summary";
        const toplineSummarySheet = new ToplineSummary(
          wb,
          sheetName,
          true,
          reportDates,
          excelPayload,
          image
        );

        let val = transformRaw(result);
        toplineSummarySheet.addToplineSummary(val);

        let records = getObjectValueOfKey(resultSet, "ctvOttSummarysummary");
        val = transformRaw(records);
        toplineSummarySheet.addData(ToplineSummaryHead.CTVOTT, val);

        records = getObjectValueOfKey(resultSet, "vodSummarysummary");
        val = transformRaw(records);
        toplineSummarySheet.addData(ToplineSummaryHead.VOD, val);

        records = getObjectValueOfKey(resultSet, "audioSummarysummary");
        val = transformRaw(records);
        toplineSummarySheet.addData(ToplineSummaryHead.DigitalAudio, val);

        records = getObjectValueOfKey(resultSet, "redxSummarysummary");
        val = transformRaw(records);
        toplineSummarySheet.addData(ToplineSummaryHead.RedX, val);

        records = getObjectValueOfKey(resultSet, "sponsorshipSummarysummary");
        val = transformRaw(records);
        toplineSummarySheet.addSponsorshipTakeOver(val);

        records = getObjectValueOfKey(resultSet, "displayVideoSummarysummary");
        val = transformRaw(records);
        toplineSummarySheet.addDisplayVideo(val);

        records = getObjectValueOfKey(resultSet, "videoSuiteSummarysummary");
        val = transformRaw(records);
        toplineSummarySheet.addData(ToplineSummaryHead.VIDSUITE, val);

        records = getObjectValueOfKey(resultSet, "contoSummarysummary");
        val = transformRaw(records);
        toplineSummarySheet.addContoSummary(val);

        records = getObjectValueOfKey(
          resultSet,
          "socialReportingSummarysummary"
        );
        val = transformRaw(records);
        toplineSummarySheet.addSocialSummary(val);
      }
      if (excelPayload.includeLegacySummary) {
        let val = getObjectValueOfKey(resultSet, "Summarylegacysummary");
        if (val && val.hasOwnProperty("rows") && val.rows.length > 0) {
          const sheetName =
            "Line Item Summary" + (excelPayload.isBetaTester ? "" : "");
          const legacySummarySheet = new SummarySheet(
            wb,
            sheetName,
            reportDates,
            excelPayload,
            image
          );
          const compResultSet = summaryTransformation(val);
          legacySummarySheet.addData(compResultSet);
        }
      }
      if (excelPayload.hasGAMDisplay) {
        const records = getObjectValueOfKey(resultSet, "gamLineItemdisplay");
        if (records && records.length >= 1) {
          addSheetWithData(
            "RSM Display",
            "display",
            false,
            wb,
            reportDates,
            excelPayload,
            image,
            resultSet
          );
        }
      }

      if (excelPayload.hasGAMVideo) {
        const records = getObjectValueOfKey(resultSet, "gamCityProvincevideo");
        if (records && records.length >= 1) {
          addSheetWithData(
            "RSM Video",
            "video",
            true,
            wb,
            reportDates,
            excelPayload,
            image,
            resultSet
          );
        }
      }

      if (excelPayload.hasVideoSuite) {
        result = getObjectValueOfKey(resultSet, "toplinesummarysummary");
        if (result && result.hasOwnProperty("rows") && result.rows.length > 0) {
          const sheetName = "RSM Video Suite";
          const videoSuitSheet = new VideoSuiteInsights(
            wb,
            sheetName,
            true,
            reportDates,
            excelPayload,
            image
          );
          videoSuitSheet.addAllSectionsToSheet(resultSet);
        }
      }

      if (excelPayload.hasGAMTakeovers) {
        addSheetWithData(
          "RSM Takeover",
          "takeover",
          false,
          wb,
          reportDates,
          excelPayload,
          image,
          resultSet
        );
      }

      if (excelPayload.hasredX) {
        let val = getObjectValueOfKey(resultSet, "redXLineItemredX");
        if (val && val && val.length > 0) {
          const redExSheet = new GenericSheet(
            wb,
            "R.E.D. X",
            reportDates,
            excelPayload,
            image,
            true,
            "redXSheet.json"
          );
          redExSheet.addSection(val, "LineItem");

          val = getObjectValueOfKey(resultSet, "redXAdSizeredX");
          redExSheet.addSection(val, "AdSize");

          val = getObjectValueOfKey(resultSet, "redXDeviceredX");
          redExSheet.addSection(val, "Device");

          val = getObjectValueOfKey(resultSet, "redXDayPerformanceredX");

          redExSheet.addSection(KpiRollup.dayOfWeek(val, false), "DayOfWeek");
          redExSheet.addSection(KpiRollup.weekOf(val), "WeekOf");

          val = getObjectValueOfKey(resultSet, "redXKeywordredX");
          redExSheet.addSection(val, "Keyword", false);

          val = getObjectValueOfKey(resultSet, "redXGeofenceredX");
          redExSheet.addSection(val, "Geofence", false);

          val = getObjectValueOfKey(resultSet, "redXProvinceredX");
          //Don't show total
          redExSheet.addSection(val, "Region", false);

          val = getObjectValueOfKey(resultSet, "redXCityProvinceredX");
          redExSheet.addSection(val, "CityProvince", false);
        }
      }

      if (excelPayload.hasAdswizz) {
        let records = getObjectValueOfKey(
          resultSet,
          "adswizToplineReachadsWiz"
        );
        if (
          records &&
          records.hasOwnProperty("rows") &&
          records.rows.length > 0
        ) {
          const gamAdsWizSheet = new GenericSheet(
            wb,
            "Digital Audio",
            reportDates,
            excelPayload,
            image,
            true,
            "Adswizz.json"
          );

          let val = transformRaw(records);
          // don't show total
          gamAdsWizSheet.addSubSection("ToplineItem", val, true, true);

          records = getObjectValueOfKey(resultSet, "adswizzZoneadsWiz");
          val = transformRaw(records);
          gamAdsWizSheet.addData(val, false);

          records = getObjectValueOfKey(resultSet, "adswizzPublisheradsWiz");
          val = transformRaw(records);
          gamAdsWizSheet.addSubSection("Publisher", val, true);

          records = getObjectValueOfKey(resultSet, "adswizzPositionadsWiz");
          val = transformRaw(records);
          gamAdsWizSheet.addSubSection("Position", val, true);

          records = getObjectValueOfKey(resultSet, "adswizzDeviceadsWiz");
          val = transformRaw(records);
          gamAdsWizSheet.addSubSection("Device", val, true);

          records = getObjectValueOfKey(
            resultSet,
            "adswizzDayPerformanceadsWiz"
          );
          val = transformRaw(records);
          gamAdsWizSheet.addSubSection("WeekOf", KpiRollup.weekOf(val), true);

          records = getObjectValueOfKey(resultSet, "adswizzHouradsWiz");
          val = transformRaw(records);
          gamAdsWizSheet.addSubSection("HourOfDay", val, true);

          records = getObjectValueOfKey(resultSet, "adswizzCMAadsWiz");
          val = transformRaw(records);
          gamAdsWizSheet.addSubSection("CMA", val, true);
        }
      }

      if (excelPayload.hasConto) {
        let records = getObjectValueOfKey(resultSet, "contoZoneconto");
        if (
          records &&
          records.hasOwnProperty("rows") &&
          records.rows.length > 0
        ) {
          const contoSheet = new GenericSheet(
            wb,
            "Contobox",
            reportDates,
            excelPayload,
            image,
            true,
            "conto.json"
          );

          let val = transformRaw(records);
          contoSheet.addData(val, true);

          records = getObjectValueOfKey(resultSet, "contoAdTypeconto");
          val = transformRaw(records);
          contoSheet.addSubSection("AdType", val, true);

          records = getObjectValueOfKey(resultSet, "contoDeviceconto");
          val = transformRaw(records);
          contoSheet.addSubSection("Device", val, true);

          records = getObjectValueOfKey(resultSet, "contoTabconto");
          val = transformRaw(records);
          contoSheet.addTabSection("Tab", val, true);

          records = getObjectValueOfKey(resultSet, "contoClickconto");
          val = transformRaw(records);
          contoSheet.addTabSection("Clicks", val, true);

          records = getObjectValueOfKey(resultSet, "contotop20domainconto");
          val = transformRaw(records);
          // don't show total - RED-3920
          contoSheet.addSubSection("Site", val, true, true);

          records = getObjectValueOfKey(resultSet, "contoDayconto");
          val = transformRaw(records);
          contoSheet.addSubSection("WeekOf", KpiRollup.weekOf(val), true);

          records = getObjectValueOfKey(resultSet, "contoHourconto");
          val = transformRaw(records);
          contoSheet.addSubSection("Hour", val, true);
        }
      }

      if (excelPayload.hasFacebook) {
        let records = getObjectValueOfKey(
          resultSet,
          "facebookPerformancefacebook"
        );
        if (
          records &&
          records.hasOwnProperty("rows") &&
          records.rows.length > 0
        ) {
          const facebookSheet = new GenericSheet(
            wb,
            "Facebook",
            reportDates,
            excelPayload,
            image,
            true,
            "facebook.json"
          );

          let val = transformRaw(records);
          facebookSheet.addData(val, true);

          records = getObjectValueOfKey(resultSet, "facebookPlatformfacebook");
          val = transformRaw(records);
          facebookSheet.addSubSection("Platform", val, true);

          records = getObjectValueOfKey(resultSet, "facebookDevicefacebook");
          val = transformRaw(records);
          facebookSheet.addSubSection("Device", val, true);

          records = getObjectValueOfKey(resultSet, "facebookPositionfacebook");
          val = transformRaw(records);
          facebookSheet.addSubSection("Position", val, true);

          records = getObjectValueOfKey(resultSet, "facebookBrandfacebook");
          val = transformRaw(records);
          facebookSheet.addSubSection("Brand", val, true);
          records = getObjectValueOfKey(resultSet, "facebookDayfacebook");
          val = transformRaw(records);
          const uniqueVidViewDef = new Set(
            val.map((x: any) => x["fb_video_views_definition"])
          );
          const fbVidView = Array.from(uniqueVidViewDef)
            .map((vvd: any) =>
              val.filter((x: any) => x["fb_video_views_definition"] == vvd)
            )
            .map((x: any) => KpiRollup.weekOf(x));
          const flattedView = [].concat.apply([], fbVidView);
          facebookSheet.addSubSection("WeekOf", flattedView, true);
        }
      }

      if (excelPayload.hasTwitter) {
        let records = getObjectValueOfKey(
          resultSet,
          "twitterPerformancetwitter"
        );
        if (
          records &&
          records.hasOwnProperty("rows") &&
          records.rows.length > 0
        ) {
          const xiteSheet = new GenericSheet(
            wb,
            "Twitter",
            reportDates,
            excelPayload,
            image,
            true,
            "twitter.json"
          );
          let val = transformRaw(records);
          xiteSheet.addSubSection("AdGroup", val, true);

          records = getObjectValueOfKey(resultSet, "twitterPropertytwitter");
          val = transformRaw(records);
          xiteSheet.addSubSection("Property", val, true);

          val = getObjectValueOfKey(resultSet, "twitterDayPerformancetwitter");
          val = transformRaw(val);
          xiteSheet.addSubSection("WeekOf", KpiRollup.weekOf(val), true);
        }
      }

      if (excelPayload.hasVOD) {
        let records = getObjectValueOfKey(resultSet, "vodLineItemvod");
        if (
          records &&
          records.hasOwnProperty("rows") &&
          records.rows.length > 0
        ) {
          const vodSheet = new GenericSheet(
            wb,
            "VOD",
            reportDates,
            excelPayload,
            image,
            true,
            "vod.json"
          );

          let val = transformRaw(records);
          vodSheet.addData(val, true);

          records = getObjectValueOfKey(resultSet, "vodPlatformvod");
          val = transformRaw(records);
          vodSheet.addSubSection("Platform", val, true);

          records = getObjectValueOfKey(resultSet, "vodCreativevod");
          val = transformRaw(records);
          vodSheet.addSubSection("Creative", val, true);

          records = getObjectValueOfKey(resultSet, "vodProvidervod");
          val = transformRaw(records);
          vodSheet.addSubSection("Provider", val, true);

          records = getObjectValueOfKey(resultSet, "vodShowvod");
          val = transformRaw(records);
          vodSheet.addSubSection("Show", val, true);

          records = getObjectValueOfKey(resultSet, "vodDayvod");
          val = transformRaw(records);

          vodSheet.addSubSection("WeekOf", KpiRollup.weekOf(val, true), true);

          vodSheet.addSubSection("DayOfWeek", KpiRollup.dayOfWeek(val), true);

          records = getObjectValueOfKey(resultSet, "vodProvincevod");
          val = transformRaw(records);
          vodSheet.addSubSection("Province", val, true);

          records = getObjectValueOfKey(resultSet, "vodRegionvod");
          val = transformRaw(records);
          vodSheet.addSubSection("Region", val, true);

          records = getObjectValueOfKey(resultSet, "vodAddressablevod");
          val = transformRaw(records);
          vodSheet.addSubSection("Addressable", val, true);
        }
      }

      if (excelPayload.hasCTV) {
        let records = getObjectValueOfKey(resultSet, "ctvLineItemctvott");
        if (
          records &&
          records.hasOwnProperty("rows") &&
          records.rows.length > 0
        ) {
          const ctvOTTSheet = new GenericSheet(
            wb,
            "CTV OTT",
            reportDates,
            excelPayload,
            image,
            true,
            "ctvott.json"
          );

          let val = transformRaw(records);
          ctvOTTSheet.addData(val, true);

          records = getObjectValueOfKey(resultSet, "ctvHourctvott");
          val = transformRaw(records);
          ctvOTTSheet.addSubSection("HourOfDay", val, true);

          records = getObjectValueOfKey(resultSet, "ctvProviderctvott");
          val = transformRaw(records);
          ctvOTTSheet.addSubSection("Provider", val, true);

          records = getObjectValueOfKey(resultSet, "ctvPlatformctvott");
          val = transformRaw(records);
          ctvOTTSheet.addCTVPlatformSection("Platform", val, true);

          records = getObjectValueOfKey(resultSet, "ctvWeekOfctvott");
          val = transformRaw(records);
          ctvOTTSheet.addSubSection("WeekOf", val, true);

          records = getObjectValueOfKey(resultSet, "ctvDayOfWeekctvott");
          val = transformRaw(records);
          ctvOTTSheet.addSubSection(
            "DayOfWeek",
            KpiRollup.dayOfWeek(val),
            true
          );
        }
      }

      if (excelPayload.hasNewsLetter) {
        let records = getObjectValueOfKey(
          resultSet,
          "eNewLetterLineItemnewsLetter"
        );
        if (records) {
          const eNewsLetterSheet = new GenericSheet(
            wb,
            "Newsletter Display",
            reportDates,
            excelPayload,
            image,
            true,
            "newsLetterInsights.json"
          );
          let val = transformRaw(records);
          eNewsLetterSheet.addData(val, true);

          records = getObjectValueOfKey(
            resultSet,
            "eNewLetterCreativeSizenewsLetter"
          );
          eNewsLetterSheet.addSubSection("Creative_Size", records, true);

          records = getObjectValueOfKey(
            resultSet,
            "eNewLetterWeekOfnewsLetterX"
          );
          val = transformRaw(records);
          eNewsLetterSheet.addSubSection(
            "WeekOf",
            KpiRollup.weekOf(records),
            true
          );
        }
      }

      let eBlastrResult = getObjectValueOfKey(
        resultSet,
        "eBlast_Insightslegacysummary"
      );
      if (
        eBlastrResult &&
        eBlastrResult.hasOwnProperty("rows") &&
        eBlastrResult.rows.length > 0
      ) {
        const sheetName = "eBlast";
        const EblastInsightsSheet = new EblastInsights(
          wb,
          sheetName,
          true,
          reportDates,
          excelPayload,
          image
        );
        let val = transformRaw(eBlastrResult);
        EblastInsightsSheet.addEblastInsights(val);
      }

      const reportName = orderId + " - " + excelPayload.reportLabel + ".xlsx";
      wb.xlsx.writeBuffer().then((xlsxData) => {
        var blob = new Blob([xlsxData], {
          type: "text/plain;charset=utf-8",
        });
        saveAs(blob, reportName);
      });
    });
}

function getCriteria(criteria: string, reportDates: any, queryId: string) {
  let result = "";
  if (
    criteria === "display" &&
    (queryId === "gamProvince" || queryId === "gamCityProvince")
  )
    result = "AND gam_media_format not in ('Video','OTT')";
  else if (criteria === "display")
    result = "AND `Creative_Size` <> 'Video/Overlay'";

  if (
    criteria === "video" &&
    (queryId === "gamProvince" || queryId === "gamCityProvince")
  )
    result = "AND gam_media_format  in ('Video','OTT')";
  else if (criteria === "video")
    result = "AND `Creative_Size` = 'Video/Overlay'";

  if (criteria === "takeover") result = "AND `gam_is_takeover` = 'Y'";
  if (criteria === "newsLetter")
    result =
      "AND NOT (`Sales_Order_Line_Item_Name` like '%cancel%' and `Contracted_Impressions`< 1)";

  return result + dateCriteria(reportDates, criteria);
}

function dateCriteria(params: any, criteria: string = null) {
  if (criteria != null && (criteria == "twitter" || criteria == "facebook")) {
    const startDate = params.reportStartDate;
    const endDate = params.reportEndDate;
    const areDefaultDates =
      startDate === params.orderStartDate &&
      params.reportEndDate === params.orderEndDate;
    const socialStartDate = areDefaultDates ? null : "'" + startDate + "'";
    const socialEndDate = areDefaultDates ? null : "'" + endDate + "'";
    if (areDefaultDates) {
      return "";
    } else
      return (
        " AND (`Event_Date` >= " +
        socialStartDate +
        " AND `Event_Date` <= " +
        socialEndDate +
        ") "
      );
  }

  return (
    " AND (`Event_Date` >= '" +
    params.reportStartDate +
    "' AND `Event_Date` <= '" +
    params.reportEndDate +
    "') "
  );
}

function addSheetWithData(
  sheetTitle: string,
  criteria: string,
  isVideo: any,
  wb: any,
  reportDates: any,
  excelPayload: any,
  image: any,
  resultSet: any
) {
  const filteredResultSet = resultSet.filter(
    (item: any) => item.hasOwnProperty("criteria") && item.criteria == criteria
  );

  if (filteredResultSet.length > 0) {
    const gamDisplaySheet = new GAMData(
      wb,
      sheetTitle,
      isVideo,
      reportDates,
      excelPayload,
      image
    );

    const GAMSections = {
      gamLineItem: GAMSection.LineItem,
      gamCreative: GAMSection.Creative,
      gamAdSize: GAMSection.AdSize,
      gamDevice: GAMSection.Device,
      gamAdUnit: GAMSection.AdUnit,
      gamDayPerformance0: GAMSection.DayOfWeek,
      gamDayPerformance1: GAMSection.WeekOf,
      gamProvince: GAMSection.Province,
      gamCityProvince: GAMSection.CityProvince,
    };
    filteredResultSet.forEach((element: any) => {
      let val = element.val;
      if (element.orgKey === "gamDayPerformance") {
        gamDisplaySheet.addData(
          GAMSection.DayOfWeek,
          KpiRollup.dayOfWeek(val, false)
        );
        gamDisplaySheet.addData(GAMSection.WeekOf, KpiRollup.weekOf(val));
      } else {
        gamDisplaySheet.addData(GAMSections[element.orgKey], val);
      }
    });
  }
}

export default reduiExcelReporting;
