import settingsConsts from "src/ConstConfig/settingsConst";

export function getParsedConfiguration(valData: any) {
  let result = {
    Excel: {
      userSelected: settingsConsts.DEFAULT,
      userProvidedValue: "",
      reportRange: true,
    },
    PPT: {
      userSelected: settingsConsts.DEFAULT,
      userProvidedValue: "",
      reportRange: true,
    },
    PPT_EXPORT: {
      userSelected: settingsConsts.DEFAULT,
      userProvidedValue: "",
      reportRange: true,
    },
  };
  if (valData && valData.hasOwnProperty("configuration")) {
    valData.configData = valData.configuration;
  }

  if (
    valData &&
    valData.hasOwnProperty("configData") &&
    valData.configData.length > 0
  ) {
    valData.configData = JSON.parse(
      JSON.stringify(JSON.parse(valData.configData))
    );

    valData.configData.map((card: any, cardIndex: any) => {
      card.subcategorylist.map((subCat: any, subCatIndex: any) => {
        subCat.listItems.map((subCatItem: any, subCatListIndex: any) => {
          if (
            subCat.hasOwnProperty("subCatID") &&
            subCat.subCatID == settingsConsts.EXCL101 &&
            subCatItem.isSelected
          ) {
            result.Excel.userProvidedValue = subCatItem.adminProvidedVal;
            if (
              subCatItem.id == settingsConsts.USEDEF_EXCL ||
              subCatItem.id == settingsConsts.USEDYN_EXCL
            ) {
              result.Excel.userSelected = settingsConsts.DEFAULT;
            } else {
              result.Excel.reportRange = false;
              result.Excel.userSelected = settingsConsts.USEEXT_EXCL;
            }
          }

          if (
            subCat.hasOwnProperty("subCatID") &&
            subCat.subCatID == settingsConsts.PPT101 &&
            subCatItem.isSelected
          ) {
            result.PPT.userProvidedValue = subCatItem.adminProvidedVal;
            if (
              subCatItem.id == settingsConsts.USEDEF_PPT ||
              subCatItem.id == settingsConsts.USEDYN_PPT
            ) {
            } else {
              result.PPT.reportRange = false;
              result.PPT.userSelected = settingsConsts.USEEXT_PPT;
            }
          }

          if (
            subCat.hasOwnProperty("subCatID") &&
            subCat.subCatID == settingsConsts.PPT_EXPORT_101 &&
            subCatItem.isInput
          ) {
            result.PPT_EXPORT.userProvidedValue = subCatItem.adminProvidedVal;
          }
        });
      });
    });
  }
  return result;
}
