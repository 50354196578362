/* eslint-disable */
export function validateCredentials() {
    return true;
}

export function findDiffInDays(date1:any, date2:any){
        var one_day=1000*60*60*24;
      
        // Convert both dates to milliseconds
        var date1_ms = date1.getTime();
        var date2_ms = date2.getTime();
      
        // Calculate the difference in milliseconds
        var difference_ms = date2_ms - date1_ms;
          
        return Math.round(difference_ms/one_day); 
   
}

export function getYesterday() {
    const reqDate = new Date();
    // reqDate.setMonth(reqDate.getMonth() + 1);
    reqDate.setDate(reqDate.getDate() - 1);
    // .toString().padStart(2, "0")
    const respDate =   reqDate.getDate() + "-" + Number(reqDate.getMonth()+1) + "-" + reqDate.getFullYear();
    return respDate;
}
export function getYesterdayYYYYMMDD() {
    const reqDate = new Date();
    // reqDate.setMonth(reqDate.getMonth() + 1);
    reqDate.setDate(reqDate.getDate() - 1);
    // .toString().padStart(2, "0")
    return reqDate.getFullYear()+  "-" +  Number(reqDate.getMonth()+1) +"-" + reqDate.getDate() ;
}


export function getDate(date:any) {
    if (date && date !== "") {
        const dateStr = date.replace(/-/g, "\/").split("/",3);
        const crdate = new Date(dateStr[2],Number(dateStr[1]-1),dateStr[0]);
        const month = (crdate.getMonth()+1);
        const day = crdate.getDate();
        const respDate =  crdate.getFullYear() + "/" + month + "/" + day ;
        return respDate;
    }
    return "";
}
export function getDashboardDate(date:any) {
    if (date && date !== "") {
        const dateStr = date.replace(/-/g, "\/").split("/",3);
        const crdate = new Date(dateStr[2],Number(dateStr[1]-1),dateStr[0]);
        const month = (crdate.getMonth()+1);
        const day = crdate.getDate();
        // const respDate =  crdate.getFullYear() + "/" + month + "/" + day ;
        const respDate =  day + "-" + month + "-" + crdate.getFullYear() ;
        return respDate;
    }
    return "";
}

export function getLineChartItemDescription(itemName:any) {
    const lineChartDescObj = {
        "Odin": "Rogers wireless age, gender, and address",
        "Maestro": "Rogers cable age, gender, and address",
        "Adobe": "Digital audience definitions",
        "SN Now": "Subscriber activity",
        "Passive Lookup": "Wireless location data",
        "Omniture": "Website activity",
        "Set-top Boxes": "TV viewing stats",
        "NHL Live": "Subscriber activity",
        "Numeris": "Linear TV viewership",
        "PRIZM5": "Consumer Segmentation",
        "Household Spend": "Canadian behaviours",
        "Census Plus": "750+ Canadian census attributes",
        "Vividata": "Media consumption / exposure",
        "Social Values": "Psychographic insights",
        "Geo POI": "Landmarks and addresses",
         "DFP": "Digital ad delivery",
        "Freewheel": "Video ad delivery",
        "Intersec": "GeoTxt ad delivery",
        "S4M": "Linear TV schedules and reports",
        "Brightcove": "OTT Video delivery",
        "Cadent": "VOD ad delivery",
        "Simpli.fi": "Exchange ad delivery",
        "DBM": "Exchange ad delivery",
        "Cynch Indexing": "Audience-based TV preferences"
    }

    if(itemName && itemName !=="") {
        return lineChartDescObj[itemName];
    }
    return "";
}