import DomoLineItem from "../Component/DomoLineItem"
import DomoOrderRecord from "../Component/DomoOrderRecord"
import OrderLineTotals from "../Component/OrderLineTotals"
import SummaryPaginator from "./SummaryPaginator"

 

class DomoOrderRecordWithPagination implements DomoOrderRecord {
    private paginator: SummaryPaginator | null = null
    public footer: OrderLineTotals = new OrderLineTotals(null)
    public slideCount = 0

    // DomoOrderRecord members
    public id = ''
    public name = ''
    public advertiser = ''
    public orderStartDate = ''
    public orderEndDate = ''
    public reportStartDate = ''
    public reportEndDate = ''
    public lines: DomoLineItem[] = []

    

    constructor(record: DomoOrderRecord) {
        this.paginator = new SummaryPaginator(record.lines)

        this.id = record.id
        this.name = record.name
        this.advertiser = record.advertiser
        this.orderStartDate = record.orderStartDate
        this.orderEndDate = record.orderEndDate
        this.reportStartDate = record.reportStartDate
        this.reportEndDate = record.reportEndDate
        this.lines = record.lines
        this.slideCount = this.paginator.slideCount
        this.footer = new OrderLineTotals(record)
    }

    linesForSlide(index: number): DomoLineItem[] {
        return this.paginator ? this.paginator.linesForSlide(index) : []
    }

    linesForToplineSlide(index: number): DomoLineItem[] {
        return this.paginator ? this.paginator.linesForSlide(index) : []
    }
}
export default DomoOrderRecordWithPagination