import { getAuthContext } from "src/Login/MSAL/msalConfig";
import { AxiosClient } from "../ClientServices/AxiosClient";
import {
  reportResponseAction,
  submitUIConfigAction,
  displayReportListAction,
  submitSpinnerAction,
  reportingStatusAction,
  displayPacingReportListAction,
  sendDomoQueryReesponse,
  calendarChangeAction,
  userActions,
  showErrorBox,
  slickStateAction,
} from "../../Actions";
import { UserOps } from "../../ConstConfig/UserOps";
import { List } from "immutable";
import DomoCampaignOrder from "src/Reporting/Model/DomoCampaignOrder";
import { UserErrorFields } from "../../ConstConfig/UserExceptions";
import { ActionConstants, NavBarConstants } from "src/ConstConfig";
import { getEndDate } from "src/CommonComponent/Calendar/utils/CampaignUtil";
const reduiReportingMiddleWare =
  (store: any) => (next: any) => (action: any) => {
    if (
      action &&
      action.hasOwnProperty("type") &&
      (action.type === "DOWNLOAD_REPORT" ||
        action.type === "SEARCH_REPORT" ||
        action.type === "LISTALL_REPORT" ||
        action.type === "LISTALL_PACINGREPORT" ||
        action.type === "DOMOSTATS" ||
        action.type === "LISTALLCONFIG" ||
        action.type === "DOMOQUERYAPI")
    ) {
      const clnt = new AxiosClient(store);
      const token = getAuthContext().idToken.rawIdToken;
      let userAction = action.type;
      if (action.payload.data.type === "LISTALL_REPORT") {
        userAction = "LISTALL_REPORT";
      } else if (action.payload.data.type === "LISTALL_PACINGREPORT") {
        userAction = "LISTALL_PACINGREPORT";
      }

      switch (userAction) {
        case "SEARCH_REPORT":
          {
            const reqObjectSeg = {
              authToken: token,
              url: action.payload.data.url,
            };
            const payload = {
              queryId: "Summary",
              queryItems: {
                ORDERID: action.payload.data.proposalID,
                ADDEDCRITERIA: "",
              },
            };

            clnt.post("POST", reqObjectSeg, payload).then((returnVal: any) => {
              const data = {
                data: {
                  enableExcle: false,
                  enablePPT: false,
                  proposalExist: false,
                  isReportExist: false,
                },
              };
              if (returnVal.status > 400 && returnVal.status < 600) {
                data.data.isReportExist = true;
              } else {
                let domoStatus =
                  new DomoCampaignOrder().getCampaignReportStructure(
                    returnVal,
                    action.payload.data.proposalID
                  );
                if (domoStatus.isValidOrderId) {
                  data.data.proposalExist = true;
                  data.data.isReportExist = false;
                  data.data.enablePPT = true;
                  data.data.enableExcle = true;
                } else {
                  data.data.isReportExist = true;
                }
              }
              store.dispatch(reportResponseAction(data));
            });
          }
          break;
        case "DOWNLOAD_REPORT":
          {
            const reqObjectSeg = {
              authToken: token,
              url: action.payload.data.url,
            };
            let refreshRecentReports =
              action.payload.data.hasOwnProperty("refreshRecentReports") &&
              action.payload.data.refreshRecentReports
                ? action.payload.data.refreshRecentReports
                : false;
            const payload = {
              proposalId: action.payload.data.proposalID,
              reportFormat: action.payload.data.reportFormat,
            };
            if (
              action.payload.data.hasOwnProperty("fileId") &&
              action.payload.data.fileId !== -1
            ) {
              payload["fileId"] = action.payload.data.fileId;
            }
            if (
              action.payload.data.hasOwnProperty("dropDownContents") &&
              action.payload.data.dropDownContents
            ) {
              (payload["reportingPeriod"] =
                action.payload.data.dropDownContents.reportingPeriod),
                (payload["reportType"] =
                  action.payload.data.dropDownContents.reportType),
                (payload["fileId"] =
                  action.payload.data.dropDownContents.fileId);
            }
            clnt
              .postAndDownload("POST", reqObjectSeg, payload)
              .then((returnVal: any) => {
                if (returnVal.status >= 400 && returnVal.status < 600) {
                  const messageBoxObj = {
                    Dialog: {
                      MessageBox: {
                        isVisible: true,
                        UserMessage: " Unable to download the file",
                        saveFailed: false,
                        boxButtons: UserOps.OK,
                        messageHead: "Error!",
                        popupAuto: true,
                      },
                    },
                  };
                  store.dispatch(submitUIConfigAction(messageBoxObj));
                } else {
                  const contentDisposition =
                    returnVal.headers["content-disposition"];
                  let fileName =
                    "downloaded." + action.payload.data.reportFormat;
                  if (contentDisposition) {
                    const fileNameMatch =
                      contentDisposition.match(/filename="(.+)"/);
                    if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
                    fileName = decodeURIComponent(fileName);
                  }
                  const url = window.URL.createObjectURL(
                    new Blob([returnVal.data])
                  );
                  const link = document.createElement("a");
                  link.href = url;
                  if (fileName === "unknown") {
                    fileName = "downloaded." + action.payload.data.reportFormat;
                  }
                  link.setAttribute("download", fileName); //or any other extension
                  document.body.appendChild(link);
                  link.click();
                  if (refreshRecentReports) {
                    reqObjectSeg.url = action.payload.data.reportListUrl;
                    setTimeout(function () {
                      makeGetCall(clnt, reqObjectSeg).then((returnVal: any) => {
                        const data = {
                          data: { isReportExist: false, list: {} },
                        };
                        if (returnVal.status >= 400 && returnVal.status < 600) {
                          data.data.isReportExist = false;
                          data.data.list = List([]);
                        } else {
                          data.data.isReportExist = true;
                          data.data.list = returnVal;
                        }
                        store.dispatch(displayReportListAction(data));
                      });
                    }, 600);
                  }
                }
              });
          }
          break;
        case "LISTALL_REPORT":
          {
            const reqObjectSeg = {
              authToken: token,
              url: action.payload.data.url,
            };
            clnt
              .getResponse(reqObjectSeg.url, reqObjectSeg)
              .then((returnVal: any) => {
                const data = {
                  data: {
                    enableExcle: false,
                    enablePPT: false,
                    proposalExist: false,
                    isReportExist: false,
                    list: {},
                  },
                };
                // returnVal = require("../../data/reporting.json");
                if (returnVal.status >= 400 && returnVal.status < 600) {
                  data.data.isReportExist = true;
                  data.data.list = List([]);
                } else {
                  data.data.proposalExist = true;
                  data.data.isReportExist = false;
                  data.data.list = returnVal;
                }
                store.dispatch(displayReportListAction(data));
              });
          }
          break;
        case "GET_REPORTING_BANNER_CONFIG":
          {
            const reqObjectSeg = {
              authToken: token,
              url: action.payload.data.url,
            };
            let returnVal = makeGetCall(clnt, reqObjectSeg);
            if (returnVal.status >= 400 && returnVal.status < 600) {
              store.dispatch(
                reportingStatusAction({
                  data: {
                    currReportingStatus: {
                      description: "This is from middleware",
                      toggleSwitch: true,
                    },
                  },
                })
              );
            } else {
              store.dispatch(
                reportingStatusAction({
                  data: {
                    currReportingStatus: {
                      description: "This is from middleware",
                      toggleSwitch: false,
                    },
                  },
                })
              );
            }
          }
          break;
        case "LISTALL_PACINGREPORT":
          {
            const reqObjectSeg = {
              authToken: token,
              url: action.payload.data.url,
            };
            let spinnerState = { UIConfig: { isSpinnerActive: true } };
            store.dispatch(submitSpinnerAction(spinnerState));
            makeGetCall(clnt, reqObjectSeg).then((returnVal: any) => {
              const data = {
                data: {
                  enableExcle: false,
                  enablePPT: false,
                  proposalExist: false,
                  isReportExist: false,
                  list: {},
                },
              };
              // returnVal = require("../../data/pacingreport.json");
              if (returnVal.status >= 400 && returnVal.status < 600) {
                data.data.isReportExist = true;
                data.data.list = List([]);
              } else {
                data.data.proposalExist = true;
                data.data.isReportExist = false;
                data.data.list = returnVal;
              }
              store.dispatch(displayPacingReportListAction(data));
              let spinnerState = { UIConfig: { isSpinnerActive: false } };
              store.dispatch(submitSpinnerAction(spinnerState));
            });
          }
          break;
        case "DOMOSTATS":
          {
            const reqObjectSeg = {
              authToken: token,
              url: action.payload.data.url,
            };
            clnt
              .post("POST", reqObjectSeg, action.payload.data.payload)
              .then((returnVal: any) => {
                if (returnVal.status >= 400 && returnVal.status < 600) {
                } else {
                }
              });
          }
          break;
        case "DOMOQUERYAPI":
          {
            const reqObjectSeg = {
              authToken: token,
              url: action.payload.data.url,
            };
            let spinnerState = { UIConfig: { isSpinnerActive: true } };
            store.dispatch(submitSpinnerAction(spinnerState));
            clnt
              .post("POST", reqObjectSeg, action.payload.data.payload)
              .then((returnVal: any) => {
                if (returnVal.status >= 400 && returnVal.status < 600) {
                } else {
                }
                let spinnerState = { UIConfig: { isSpinnerActive: false } };
                store.dispatch(submitSpinnerAction(spinnerState));
                let domoStatus =
                  new DomoCampaignOrder().getCampaignReportStructure(
                    returnVal,
                    action.payload.data.payload.queryItems.ORDERID
                  );
                if (domoStatus.isValidOrderId) {
                  domoStatus["apiResponse"] = returnVal;
                  let startDate = domoStatus.startDate;
                  let endDate = domoStatus.endDate;
                  let campEndDate = getEndDate(endDate);
                  let usrSelectedDates = {
                    startDate: {
                      date: startDate,
                      campaignStartDate: startDate,
                    },
                    endDate: {
                      date: campEndDate,
                      campaignEndDate: campEndDate,
                      isDefault: false,
                    },
                  };
                  store.dispatch(calendarChangeAction(usrSelectedDates));
                  store.dispatch(sendDomoQueryReesponse({ data: domoStatus }));
                } else {
                  let respExceptionObj = {
                    [UserErrorFields.ERROR_CODE]: "Error - 404",
                    [UserErrorFields.ERROR_MESSAGE]: "Page Not Found",
                  };
                  store.dispatch(
                    userActions({
                      UserAction: ActionConstants.Login,
                      isLoginSuccessful: false,
                    })
                  );
                  store.dispatch(showErrorBox(respExceptionObj));
                  const dummyUserObj = {
                    UserAction: "SlickPosition",
                    selectedTab: "",
                    slickIdx: NavBarConstants.DASHBOARDSLICK,
                  };
                  store.dispatch(slickStateAction(dummyUserObj));
                }
              });
          }
          break;
      }
    }
    return next(action);
  };
function makeGetCall(clnt: any, reqObjectSeg: any) {
  return clnt.getResponse(reqObjectSeg.url, reqObjectSeg);
}

export default reduiReportingMiddleWare;
