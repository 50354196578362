/* eslint-disable */
import IClient from "./IClient";
import { stopLoader, updateProgressCRM } from "../../Actions";
import Logger from "../Logger/Logger";
import { clientUitls } from "./apiClientUtil";
import {
  adalConfig,
  msalApp,
  requiresInteraction,
} from "src/Login/ADAL/auth-util";
import { setAuthContextByAxios } from "src/Login/MSAL/msalConfig";
import { isTokenExpired } from "src/Utility/commonUtil";

const axios = require("axios");

const request = { scopes: [adalConfig.clientId] };

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (
      error?.response?.status === 401 &&
      error?.config?.url.includes("getLoggedInUserDetail")
    ) {
      return Promise.reject(error);
    }
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        return msalApp
          .acquireTokenSilent(request)
          .then((returnVal: any) => {
            const token = returnVal;
            setAuthContextByAxios(token);
            originalRequest.headers.Authorization =
              "Bearer " + token.idToken.rawIdToken;
            if (error?.config?.url.includes("upload")) {
            }
            setTimeout(function () {
              axios(originalRequest), 500;
            });
            return axios(originalRequest);
          })
          .catch<any>((error) => {
            // Call acquireTokenPopup (popup window) in case of acquireTokenSilent failure
            // due to consent or interaction required ONLY
            if (requiresInteraction(error.errorCode)) {
              return msalApp.acquireTokenRedirect(request);
            } else {
              console.error("Non-interactive error:", error.errorCode);
            }
          });
      } catch (e) {
        console.error("Something went wrong");
      }
    }
    return Promise.reject(error);
  }
);

export class AxiosClient implements IClient {
  clientStore: any;
  clientUtil: any;
  constructor(store: any) {
    this.clientStore = store;
    this.clientUtil = new clientUitls(store);
  }

  get<T>(url: string, data?: any, options?: any) {
    if (data.hasOwnProperty("authToken") && data.authToken !== "") {
      axios.defaults.headers.get["Authorization"] = "Bearer " + data.authToken;
    }
  }
  async getResponse(url: any, data: any) {
    if(url){
       /** check if current token is expired, then replace it after fetching the new one **/
    if (
      data.hasOwnProperty("authToken") &&
      data.authToken !== "" &&
      isTokenExpired(data.authToken)
    ) {
      await msalApp
        .acquireTokenSilent(request)
        .then(async (newTokenAcquired) => {
          const newToken = newTokenAcquired.idToken.rawIdToken;
          setAuthContextByAxios(newTokenAcquired);
          data.authToken = newToken;
        });
    }
    /** check if current token is expired, then replace it after fetching the new one **/

    axios.defaults.headers.common.Accept = "application/json";
    if (data.hasOwnProperty("authToken") && data.authToken !== "") {
      axios.defaults.headers.get["Authorization"] = "Bearer " + data.authToken;
    }

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + data.authToken,
      },
    };
    return (
      fetch(url, options)
        .then((response) => response.json())
        .then((res) => {
          return res;
        })
        .catch((error) => {
          if (error.response) {
            Logger.getInstance().printWarnLogs(error.response.status);
          } else if (error.request) {
            Logger.getInstance().printWarnLogs(error.request);
          } else {
            Logger.getInstance().printWarnLogs(
              "Error while invoking api",
              error.message
            );
          }
          const errorObject = {
            status: error.response.status,
            errorData: error.response.data,
          };
          this.clientUtil.checkAndReportError(errorObject);
          return errorObject;
        })
    );
    }   
  }

  async post(requestType: any, options: any, payload) {
    /** check if current token is expired, then replace it after fetching the new one **/
    if (
      options.hasOwnProperty("authToken") &&
      options.authToken !== "" &&
      isTokenExpired(options.authToken)
    ) {
      await msalApp
        .acquireTokenSilent(request)
        .then(async (newTokenAcquired) => {
          const newToken = newTokenAcquired.idToken.rawIdToken;
          setAuthContextByAxios(newTokenAcquired);
          options.authToken = newToken;
        });
    }
    /** check if current token is expired, then replace it after fetching the new one **/

    const contType = "Content-Type";
    axios.defaults.headers.common.Accept = "application/json";
    axios.defaults.headers.post[contType] = "application/json";
    if (options.hasOwnProperty("authToken")) {
      axios.defaults.headers.get["Authorization"] =
        "Bearer " + options.authToken;
    }
    const optionsData = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + options.authToken,
      },
      body: JSON.stringify(payload),
    };
    return (
      fetch(options.url, optionsData)
        .then((response) => response.json())
        .then((res) => {
          return JSON.parse(JSON.stringify(res));
        })
        .catch((error) => {
          if (error.response) {
            Logger.getInstance().printWarnLogs(
              "Status with failure",
              error?.response?.status
            );
          } else if (error.request) {
            Logger.getInstance().printWarnLogs(error.request);
          } else {
            Logger.getInstance().printWarnLogs("Error", error.message);
          }
          if (!error.hasOwnProperty("response")) {
            error = { response: { status: 500, data: "" } };
          }
          const errorObject = {
            status: error?.response?.status,
            errorData: error?.response?.data,
          };
          this.clientUtil.checkAndReportError(errorObject);
          return errorObject;
        })
    );
  }

  async put(requestType: any, options: any, payload: any) {
    /** check if current token is expired, then replace it after fetching the new one **/
    if (
      options.hasOwnProperty("authToken") &&
      options.authToken !== "" &&
      isTokenExpired(options.authToken)
    ) {
      await msalApp
        .acquireTokenSilent(request)
        .then(async (newTokenAcquired) => {
          const newToken = newTokenAcquired.idToken.rawIdToken;
          setAuthContextByAxios(newTokenAcquired);
          options.authToken = newToken;
        });
    }
    /** check if current token is expired, then replace it after fetching the new one **/

    const contType = "Content-Type";
    axios.defaults.headers.common.Accept = "application/json";
    axios.defaults.headers.post[contType] = "application/json";
    if (options.hasOwnProperty("authToken")) {
      axios.defaults.headers.get["Authorization"] =
        "Bearer " + options.authToken;
    }
    return axios({
      data: payload,
      method: "put",
      url: options.url,
    })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        if (error.response) {
          Logger.getInstance().printWarnLogs(
            "Status with failure",
            error.response.status
          );
        } else if (error.request) {
          Logger.getInstance().printWarnLogs(error.request);
        } else {
          Logger.getInstance().printWarnLogs("Error", error.message);
        }
        if (!error.hasOwnProperty("response")) {
          error = { response: { status: 500, data: "" } };
        }
        const errorObject = {
          status: error.response.status,
          errorData: error.response.data,
        };
        this.clientUtil.checkAndReportError(errorObject);
        return errorObject;
      });
  }

  async postAndDownload(requestType: any, options: any, payload: any) {
    /** check if current token is expired, then replace it after fetching the new one **/
    if (
      options.hasOwnProperty("authToken") &&
      options.authToken !== "" &&
      isTokenExpired(options.authToken)
    ) {
      await msalApp
        .acquireTokenSilent(request)
        .then(async (newTokenAcquired) => {
          const newToken = newTokenAcquired.idToken.rawIdToken;
          setAuthContextByAxios(newTokenAcquired);
          options.authToken = newToken;
        });
    }
    /** check if current token is expired, then replace it after fetching the new one **/

    const contType = "Content-Type";
    axios.defaults.headers.common.Accept = "application/json";
    axios.defaults.headers.post[contType] = "application/json";
    if (options.hasOwnProperty("authToken")) {
      axios.defaults.headers.get["Authorization"] =
        "Bearer " + options.authToken;
    }
    return axios({
      data: payload,
      method: "post",
      url: options.url,
      responseType: "arraybuffer",
    })
      .then((res) => {
        return {
          data: res.data,
          headers: res.headers,
        };
      })
      .catch((error) => {
        if (error.response) {
          Logger.getInstance().printWarnLogs(
            "Status with failure",
            error.response.status
          );
        } else if (error.request) {
          Logger.getInstance().printWarnLogs(error.request);
        } else {
          Logger.getInstance().printWarnLogs("Error", error.message);
        }
        if (!error.hasOwnProperty("response")) {
          error = { response: { status: 500, data: "" } };
        }
        const errorObject = {
          status: error.response.status,
          errorData: error.response.data,
        };
        this.clientUtil.checkAndReportError(errorObject);
        return errorObject;
      });
  }

  async delete(options: any, payload: any) {
    /** check if current token is expired, then replace it after fetching the new one **/
    if (
      options.hasOwnProperty("authToken") &&
      options.authToken !== "" &&
      isTokenExpired(options.authToken)
    ) {
      await msalApp
        .acquireTokenSilent(request)
        .then(async (newTokenAcquired) => {
          const newToken = newTokenAcquired.idToken.rawIdToken;
          setAuthContextByAxios(newTokenAcquired);
          options.authToken = newToken;
        });
    }
    /** check if current token is expired, then replace it after fetching the new one **/

    const contType = "Content-Type";
    axios.defaults.headers.common.Accept = "application/json";
    axios.defaults.headers.post[contType] = "application/json";
    if (options.hasOwnProperty("authToken")) {
      axios.defaults.headers.get["Authorization"] =
        "Bearer " + options.authToken;
    }
    const optionsData = {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + options.authToken,
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(payload),
    };
    return (
      fetch(options.url, optionsData)
        .then((response) => response.json())
        .then((res) => {
          this.clientStore.dispatch(stopLoader({}));
          return JSON.parse(JSON.stringify(res));
        })
        .catch((error) => {
          if (error.response) {
            Logger.getInstance().printWarnLogs(
              "Status with failure",
              error.response.status
            );
          } else if (error.request) {
            Logger.getInstance().printWarnLogs(error.request);
          } else {
            Logger.getInstance().printWarnLogs("Error", error.message);
          }
          return {
            status: error.response.status,
            errorData: error.response.data,
          };
        })
    );
  }

  async postFiles(options: any, payload: any) {
    /** check if current token is expired, then replace it after fetching the new one **/
    if (
      options.hasOwnProperty("authToken") &&
      options.authToken !== "" &&
      isTokenExpired(options.authToken)
    ) {
      await msalApp
        .acquireTokenSilent(request)
        .then(async (newTokenAcquired) => {
          const newToken = newTokenAcquired.idToken.rawIdToken;
          setAuthContextByAxios(newTokenAcquired);
          options.authToken = newToken;
        });
    }
    /** check if current token is expired, then replace it after fetching the new one **/

    const contType = "Content-Type";
    axios.defaults.headers.common.Accept = "application/json";
    axios.defaults.headers.post[contType] = "multipart/form-data";
    axios.defaults.maxContentLength = Infinity;
    if (options.hasOwnProperty("authToken")) {
      axios.defaults.headers.get["Authorization"] =
        "Bearer " + options.authToken;
    }
    if (!payload) {
    }
    return axios({
      data: payload,
      method: "post",
      url: options.url,
      onUploadProgress: (progressEvent: any) => {
        try {
          if ((progressEvent.loaded / progressEvent.total) * 100 <= 97) {
            this.clientStore.dispatch(
              updateProgressCRM({ data: progressEvent })
            );
          } else if ((progressEvent.loaded / progressEvent.total) * 100 >= 97) {
            const fakeProgEvent = {
              loaded: progressEvent.loaded,
              total: progressEvent.total,
            };
            fakeProgEvent.loaded =
              progressEvent.total - (progressEvent.total / 100) * 3;
            this.clientStore.dispatch(
              updateProgressCRM({ data: fakeProgEvent })
            );
          }
        } catch (e) {}
      },
    })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        if (error.response) {
          Logger.getInstance().printWarnLogs(
            "Status with failure",
            error?.response?.status
          );
        } else if (error.request) {
          Logger.getInstance().printWarnLogs(error?.request);
        } else {
          Logger.getInstance().printWarnLogs("Error", error.message);
        }
        if (error?.response && error?.response?.status)
          return {
            status: error.response.status,
            errorData: error.response.data,
          };
        else return { status: 500, errorData: "" };
      });
  }

  public async postFilesRequest(options: any, payload: any) {
    /** check if current token is expired, then replace it after fetching the new one **/
    if (
      options.hasOwnProperty("authToken") &&
      options.authToken !== "" &&
      isTokenExpired(options.authToken)
    ) {
      await msalApp
        .acquireTokenSilent(request)
        .then(async (newTokenAcquired) => {
          const newToken = newTokenAcquired.idToken.rawIdToken;
          setAuthContextByAxios(newTokenAcquired);
          options.authToken = newToken;
        });
    }
    /** check if current token is expired, then replace it after fetching the new one **/

    const contType = "Content-Type";
    axios.defaults.headers.common.Accept = "application/json";
    axios.defaults.headers.post[contType] = "multipart/form-data";
    axios.defaults.maxContentLength = Infinity;
    if (options.hasOwnProperty("authToken")) {
      axios.defaults.headers.get["Authorization"] =
        "Bearer " + options.authToken;
    }
    return axios({
      data: payload,
      method: "post",
      url: options.url,
      onUploadProgress: (progressEvent: any) => {
      },
    })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        if (error.response) {
          Logger.getInstance().printWarnLogs(
            "Status with failure",
            error.response.status
          );
        } else if (error.request) {
          Logger.getInstance().printWarnLogs(error.request);
        } else {
          Logger.getInstance().printWarnLogs("Error", error.message);
        }
        setTimeout(() => {
          return {
            status: error.response.status,
            errorData: error.response.data,
          };
        }, 10);
      });
  }
  public async post_reporting(
    requestType: any,
    options: any,
    payload: any,
    showProgress: any = false,
    text: any = ""
  ) {
    /** check if current token is expired, then replace it after fetching the new one **/
    if (
      options.hasOwnProperty("authToken") &&
      options.authToken !== "" &&
      isTokenExpired(options.authToken)
    ) {
      await msalApp
        .acquireTokenSilent(request)
        .then(async (newTokenAcquired) => {
          const newToken = newTokenAcquired.idToken.rawIdToken;
          setAuthContextByAxios(newTokenAcquired);
          options.authToken = newToken;
        });
    }
    /** check if current token is expired, then replace it after fetching the new one **/

    // const contType = "Content-Type";
    axios.defaults.headers.common.Accept = "application/json";
    if (options.hasOwnProperty("authToken")) {
      axios.defaults.headers.Authorization = "Bearer " + options.authToken;
    }
    return axios({
      data: payload,
      method: "post",
      url: options.url,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      onDownloadProgress: (progressEvent: any) => {
        if (showProgress) {
          this.clientStore.dispatch(
            updateProgressCRM({
              data: {
                total: progressEvent.total,
                loaded: progressEvent.loaded % 101,
                text: text,
              },
            })
          );
        }
      },
    })
      .then((res) => {
        return JSON.parse(JSON.stringify(res.data));
      })
      .catch((error) => {
        if (error.response) {
          Logger.getInstance().printWarnLogs(
            "Status with failure",
            error.response.status
          );
        } else if (error.request) {
          Logger.getInstance().printWarnLogs(error.request);
        } else {
          Logger.getInstance().printWarnLogs("Error", error.message);
        }
        if (!error.hasOwnProperty("response")) {
          error = { response: { status: 500, data: "" } };
        }
        const errorObject = {
          status: error.response.status,
          errorData: error.response.data,
        };
        this.clientUtil.checkAndReportError(errorObject);
        return errorObject;
      });
  }
}
export default AxiosClient;
