import Excel from 'exceljs'
import { getObjectValueOfKey, transformRaw } from 'src/rogersframework/Store/helper'
import ExcelSheet from './ExcelSheet'
import * as KpiRollup from "../../Reporting/utils/KpiRollup";

export enum VideoSuiteInsightsHead {
    Creative,
    DeviceCat,
    Site,
    WeekStd,
    WeekDay,
    WeekBeg,
    Province,
    LineItem
}
class VideoSuiteInsights extends ExcelSheet {

    protected columnCount: number
    protected hasVideo: boolean
    protected colPadd: number

    constructor(workbook: Excel.Workbook, name: string, hasVideo: boolean,
        reportDates: any,
        orderInfo: Record<string, string | boolean>, logo: any) {
        super(workbook, name, reportDates, orderInfo, logo)
        this.hasVideo = hasVideo;
        this.colPadd = 1;
        this.columnCount = 9;
        this.addSheetHeader()
        this.sheet.getColumn(2).width = 40.14
        this.sheet.getColumn(3).width = 15.71
        this.sheet.getColumn(4).width = 15.71
        this.sheet.getColumn(5).width = 15.71
        this.sheet.getColumn(6).width = 15.71
        this.sheet.getColumn(7).width = 15.71
        this.sheet.getColumn(8).width = 15.71
        this.sheet.getColumn(9).width = 15.71
    }


    private sectionDataKey(section: VideoSuiteInsightsHead): any {
        switch (section) {
            case VideoSuiteInsightsHead.LineItem: return { title: "Performance by Line Item", key: "Sales_Order_Line_Item_Name"};
            case VideoSuiteInsightsHead.Creative: return { title: "Performance by Creative", key: "Creative"};
            case VideoSuiteInsightsHead.DeviceCat: return { title: "Performance by Device Category", key: "Device_Name"};
            case VideoSuiteInsightsHead.Site: return { title: "Top Sites by Delivery", key: "Site"};
            case VideoSuiteInsightsHead.WeekDay: return { title: "Performance by Day of Week", key: "Day"};
            case VideoSuiteInsightsHead.WeekStd: return { title: "Performance by Week  (Standard)", key: "Week"};
            case VideoSuiteInsightsHead.WeekBeg: return { title: "Performance by Week  (Broadcast)", key: "Week"};
            case VideoSuiteInsightsHead.Province: return { title: "Performance by Province", key: "Province"};

            default: return { title: " ", key: ""};
        }
    }

    public addData(section: VideoSuiteInsightsHead, data: any): void {
        if (!data || data?.length <= 0) {
            return;
        }
        this.addSummaryLineHeading(this.sectionDataKey(section).heading)
        let heading = ['',
            this.sectionDataKey(section).title, "Delivered Impressions", "Clicks", "CTR", "Starts", "Midpoints", "Completes", "VCR"];
        const topHeading = this.addSectionHeading(heading)
        topHeading.commit()

        const topDataRow = topHeading.number + 1;
        const colCont = 9;
        for (const row of data) {
            var rowData = ['',                              //A
                row[this.sectionDataKey(section).key],      //B
                this.safeInt(row['Impressions']),          //C
                this.safeInt(row['Clicks']),                //D
                0,                                          //E
                this.safeInt(row['Video_Starts']),           //F
                this.safeInt(row['Video_Midpoints']),         //G
                this.safeInt(row['Video_Completes']),           //H
                0                                           //I
            ];
            var item = this.sheet.addRow(rowData)
            item.getCell(3).style.numFmt = '#,##0'
            item.getCell(4).style.numFmt = '#,##0'
            item.getCell(6).style.numFmt = '#,##0'
            item.getCell(7).style.numFmt = '#,##0'
            item.getCell(8).style.numFmt = '#,##0'
            item.getCell(9).style.numFmt = '#0%'


            var ctr = item.getCell(5)
            if (this.safeInt(row['Impressions']) > 0) {
                ctr.value = {
                    formula: 'IFERROR(D' + ctr.row + '/C' + ctr.row + ', 0)',
                    date1904: false
                }
            }
            ctr.style.numFmt = '#0.00%'
            var ctr = item.getCell(9)
            ctr.value = {
                formula: 'IFERROR(H' + ctr.row + '/F' + ctr.row + ', 0)',
                date1904: false
            }
            ctr.style.numFmt = '#0.0%'
            for (var ri = 2; ri <= colCont; ri++) {
                var cell = item.getCell(ri)
                cell.border = this.standardCellBorder
                cell.alignment = { horizontal: ri <= 2 ? 'left' : 'right' }
            }
            item.commit()
        }

        const totalTemplate = ['', 'Total', "", "", "", "", "", 0]
            const totalRow = this.sheet.addRow(totalTemplate)
            for (var tri = 2; tri <= colCont; tri++) {
                var totalCell = totalRow.getCell(tri)
                totalCell.border = this.standardCellBorder
                totalCell.font = { bold: true, color: { argb: 'FF000000' } }
                totalCell.alignment = { horizontal: tri === 2 ? 'left' : 'right' }
                totalCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFCCCCCC' }, bgColor: { argb: 'FFFFFFFF' } }
            }
            totalRow.getCell(2).value = 'Total'
            totalRow.getCell(3).value = { formula: 'SUM(C' + topDataRow + ':C' + (totalRow.number - 1) + ')', date1904: false }
            totalRow.getCell(3).style.numFmt = '#,##0'
            totalRow.getCell(4).value = { formula: 'SUM(D' + topDataRow + ':D' + (totalRow.number - 1) + ')', date1904: false }
            totalRow.getCell(4).style.numFmt = '#,##0'
            totalRow.getCell(5).value = { formula: 'IFERROR(D' + totalRow.number + '/C' + totalRow.number + ', 0)', date1904: false }
            totalRow.getCell(5).style.numFmt = '#0.00%'
            totalRow.getCell(6).value = { formula: 'SUM(F' + topDataRow + ':F' + (totalRow.number - 1) + ')', date1904: false }
            totalRow.getCell(6).style.numFmt = '#,##0'
            totalRow.getCell(7).value = { formula: 'SUM(G' + topDataRow + ':G' + (totalRow.number - 1) + ')', date1904: false }
            totalRow.getCell(7).style.numFmt = '#,##0'
            totalRow.getCell(8).value = { formula: 'SUM(H' + topDataRow + ':H' + (totalRow.number - 1) + ')', date1904: false }
            totalRow.getCell(8).style.numFmt = '#,##0'
            totalRow.getCell(9).value = { formula: 'IFERROR(H' + totalRow.number + '/F' + totalRow.number + ', 0)', date1904: false }
            totalRow.getCell(9).style.numFmt = '#0.0%'


            totalRow.commit()
        

    }

    public addProvinceData(section: VideoSuiteInsightsHead, data: any): void {
        if (!data || data?.length <= 0) {
            return;
        }
        this.addSummaryLineHeading(this.sectionDataKey(section).heading)
        let heading = ['',
            this.sectionDataKey(section).title, "Delivered Impressions", "Clicks", "CTR"];
        const topHeading = this.addSectionHeading(heading)
        const topDataRow = topHeading.number + 1;
        topHeading.commit()
        const colCont = 5;
        for (const row of data) {
            var rowData = ['',                              //A
                row[this.sectionDataKey(section).key],      //B
                this.safeInt(row['Impressions']),          //C
                this.safeInt(row['Clicks']),                //D
                0,                                          //E
               
            ];
            var item = this.sheet.addRow(rowData)
            item.getCell(3).style.numFmt = '#,##0'
            item.getCell(4).style.numFmt = '#,##0'
           
            var ctr = item.getCell(5)
            if (this.safeInt(row['Impressions']) > 0) {
                ctr.value = {
                    formula: 'IFERROR(D' + ctr.row + '/C' + ctr.row + ', 0)',
                    date1904: false
                }
            }
            ctr.style.numFmt = '#0.00%'
           
            for (var ri = 2; ri <= colCont; ri++) {
                var cell = item.getCell(ri)
                cell.border = this.standardCellBorder
                cell.alignment = { horizontal: ri <= 2 ? 'left' : 'right' }
            }
            item.commit()
        }

        const totalTemplate = ['', 'Total', "", "", ""]
        const totalRow = this.sheet.addRow(totalTemplate)
            for (var tri = 2; tri <= colCont; tri++) {
                var totalCell = totalRow.getCell(tri)
                totalCell.border = this.standardCellBorder
                totalCell.font = { bold: true, color: { argb: 'FF000000' } }
                totalCell.alignment = { horizontal: tri === 2 ? 'left' : 'right' }
                totalCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFCCCCCC' }, bgColor: { argb: 'FFFFFFFF' } }
            }
            totalRow.getCell(2).value = 'Total'
            totalRow.getCell(3).value = { formula: 'SUM(C' + topDataRow + ':C' + (totalRow.number - 1) + ')', date1904: false }
            totalRow.getCell(3).style.numFmt = '#,##0'
            totalRow.getCell(4).value = { formula: 'SUM(D' + topDataRow + ':D' + (totalRow.number - 1) + ')', date1904: false }
            totalRow.getCell(4).style.numFmt = '#,##0'
            totalRow.getCell(5).value = { formula: 'IFERROR(D' + totalRow.number + '/C' + totalRow.number + ', 0)', date1904: false }
            totalRow.getCell(5).style.numFmt = '#0.00%'
            
            totalRow.commit()
        

    }


    addSummaryLineHeading(heading: string) {
        const sectionHeading = this.sheet.addRow(['', heading])
       // sectionHeading.font = { bold: true, size: 20 }
        sectionHeading.height = 14.40;
        sectionHeading.commit()
    }

    addAllSectionsToSheet(resultSet: any ) {
        let records = getObjectValueOfKey(resultSet, "performanceByLineItemVideoSuitevideoSuite");
        let val = transformRaw(records);
        this.addData(VideoSuiteInsightsHead.LineItem, val);

        records = getObjectValueOfKey(resultSet, "performanceByCreativeQueryVideoSuitevideoSuite");
        val = transformRaw(records);
        this.addData(VideoSuiteInsightsHead.Creative, val);

        records = getObjectValueOfKey(resultSet, "perfByDeviceNameQueryVideoSuitevideoSuite");
        val = transformRaw(records);
        this.addData(VideoSuiteInsightsHead.DeviceCat, val);

        records = getObjectValueOfKey(resultSet, "performanceBySiteQueryVideoSuitevideoSuite");
        val = transformRaw(records);
        this.addData(VideoSuiteInsightsHead.Site, val);

        records = getObjectValueOfKey(resultSet, "performanceByDayQueryVideoSuitevideoSuite");
        val = transformRaw(records);

        let weekDay = KpiRollup.dayOfWeek(val,false)
        this.addData(VideoSuiteInsightsHead.WeekDay,weekDay );

        let weekStd = KpiRollup.weekOf(val)
        this.addData(VideoSuiteInsightsHead.WeekStd,weekStd );

        let weekOfRecords = KpiRollup.weekOf(val,true)
        this.addData(VideoSuiteInsightsHead.WeekBeg,weekOfRecords );

        records = getObjectValueOfKey(resultSet, "performanceByProvinceQueryVideoSuitevideoSuite");
        val = transformRaw(records);
        this.addProvinceData(VideoSuiteInsightsHead.Province, val)
    }
}
export default VideoSuiteInsights