/* eslint-disable */
import { isDataExist } from "../../Utility/commonUtil";
import { IGraphModel, IChartModel } from "./IGraphModel";
import { convertJsonToGraphModel } from "../utils/graphUtils";

export default class GraphModel implements IGraphModel {
  graphID: any;
  sourceName: any;
  chartSource: any;
  isGraphDataLoaded: any;
  constructor(jsonResp: any) {
    this.chartSource = <IChartModel>[];
    this.chartSource = convertJsonToGraphModel(jsonResp, this.chartSource);
    if (this.chartSource.length > 0) {
      this.isGraphDataLoaded = true;
    }
  }

  public isGraphDataExist() {
    return this.isGraphDataLoaded;
  }

  public setSourceName(name: any) {
    this.sourceName = name;
  }

  public getChartSource() {
    return this.chartSource;
  }

  public removeFromSource() {}

  public getChartSourceFromObj(name: any) {
    if (isDataExist(this.chartSource, name)) {
      return this.chartSource[name];
    }
    return [];
  }

  public getName(index: any) {
    if (isDataExist(this.chartSource[index], "dataSourceName")) {
      return this.chartSource[index].dataSourceName;
    }
    return null;
  }

  public getChartItem(index: any) {
    if (
      isDataExist(this.chartSource[index], "dataSourcePoints") &&
      this.chartSource[index].dataSourcePoints.length > 0
    ) {
      return this.chartSource[index];
    }
    return [];
  }

  public getChartLastLoaded(index: any) {
    if (isDataExist(this.chartSource[index], "lastUpdatedDuration")) {
      return this.chartSource[index].lastUpdatedDuration;
    }
    return [];
  }

  public getSourceLoaded(index: any) {
    if (isDataExist(this.chartSource[index], "message")) {
      return this.chartSource[index].message;
    }
    return [];
  }
}
