/* eslint-disable */
export enum CampaignEleConstants {
  STREETNAME_ERROR = "StreetName_Error",
  REFURL_ERRPR = "REFURL_Error",
  CAMPAIGNNAME = "CampaignName_Error",
  CAMPAIGNBUDGET = "CampaignBudget_Error",
  CAMPAIGNSTARTDATE = "CampaignStartDate_Error",
  CAMPAIGNENDDATE = "CampaignEndDate_Error",
  CAMPAIGNDAYOFWEEK = "CampaignDayOfWeek_Error",
  CAMPAIGNDAYOFPART = "CampaignDayOfPart_Error",
  CAMPAIGNPLATFORM = "CampaignPlatform_Error",
  CAMPAIGNADVERTISER = "CampaignAdvertiser_Error",
  CREATIVEFORMAT = "CreativeFormat_Error",

  USERFIRSTNAME = "UserFirstName_Error",
  USERLASTNAME = "UserLastName_Error",
  USEREMAIL = "UserEmail_Error",
  USERCOMPANY = "UserCompany_Error",
  USERROLE = "UserRole_Error",

  SEGMENTNAME = "SegmentName_Error",
  SEGMENTDESCR = "SegmentDescription_Error",
  LEGACYID = "LegacyID_Error",
  ADOBESEGMENTID = "AdobeSegmentId_Error",
  SUBCATEGORY = "SubCategoryId_Error",

  REPORTING_ID_ERROR = "ReportingId_Error",
  REPORTING_ID_FAILURE_ERROR = "ReportingId_NotFound_Error",
  CONTRACTID_ERROR = "CONTRACTID_ERROR",

  REPORTDATE_ERROR = "REPORTDATE_ERROR",
  // Added for admin module
  PHONEERROR = "PHONE_ERROR",



  AGENCYIDERROR = "segmentAgencyId_Error",
  UNIVERSESIZEERROR = "segmentUniverseSize_Error",
  AVERAGEHOURERROR = "segmentAverageHours_Error",
  SOURCEERROR = "segmentSource_Error",
  DATEERROR = "startDateEndDate_Error",

  reportingDescription = "ReportingDescription_Error",
  reportingChkBox = "ReportingStatusCheck_Error",

  QUERYSEGMENT = "QUERYSEGMENT",
  TVCAMPAIGNNAME = "TVCAMPAIGNNAME",
  TVCONTRACTIDS = "TVCONTRACTIDS",

  CLIENTKEY = "CLIENTKEY",
  CLIENTSECRET = "CLIENTSECRET",
  DOMOQUERYID = "DOMOQUERYID",
  DOMODATASETID = "DOMODATASETID",
  DOMOQUERY = "DOMOQUERY",
  DOMOQUERYTYPE = "DOMOQUERYTYPE"
}

export default CampaignEleConstants;