import Excel from "exceljs";
//import { wrap } from 'module'
import ExcelSheet from "./ExcelSheet";
export enum ToplineSummaryHead {
  CTVOTT,
  VOD,
  DigitalAudio,
  RedX,
  Province,
  VIDSUITE,
}
class ToplineSummary extends ExcelSheet {
  protected columnCount: number;
  protected hasVideo: boolean;
  protected colPadd: number;

  constructor(
    workbook: Excel.Workbook,
    name: string,
    hasVideo: boolean,
    reportDates: any,
    orderInfo: Record<string, string | boolean>,
    logo: any
  ) {
    super(workbook, name, reportDates, orderInfo, logo);
    this.hasVideo = hasVideo;
    this.colPadd = 0.9;
    this.columnCount = 15;
    this.addSheetHeader();
    this.sheet.getColumn(2).width = 41.25;
    this.sheet.getColumn(3).width = 13.69;
    this.sheet.getColumn(4).width = 16.58;
    this.sheet.getColumn(5).width = 16.58;
    this.sheet.getColumn(6).width = 16.58;
    this.sheet.getColumn(7).width = 16.58;
    this.sheet.getColumn(8).width = 16.58;
    this.sheet.getColumn(9).width = 16.58;
    this.sheet.getColumn(10).width = 16.58;
    this.sheet.getColumn(11).width = 16.58;
    this.sheet.getColumn(12).width = 16.71;
    this.sheet.getColumn(13).width = 16.71;
    this.sheet.getColumn(14).width = 16.71;
    this.sheet.getColumn(15).width = 19.22;
    this.sheet.getColumn(16).width = 16.14;
    this.sheet.getColumn(17).width = 16.14;
  }

  private sectionDataKey(section: ToplineSummaryHead): any {
    switch (section) {
      case ToplineSummaryHead.VIDSUITE:
        return { title: "VCR", key: "", heading: "Video Suite Summary" };
      case ToplineSummaryHead.CTVOTT:
        return { title: "VCR", key: "", heading: "CTV OTT Summary" };
      case ToplineSummaryHead.VOD:
        return { title: "VCR", key: "", heading: "Video on Demand Summary" };
      case ToplineSummaryHead.DigitalAudio:
        return { title: "LCR", key: "", heading: "Digital Audio Summary" };
      case ToplineSummaryHead.RedX:
        return { title: "LCR", key: "", heading: "R.E.D. X Summary" };
      default:
        return { title: " ", key: "", heading: "" };
    }
  }

  public addData(section: ToplineSummaryHead, data: any): void {
    if (!data || data?.length <= 0) {
      return;
    }
    this.addSummaryLineHeading(this.sectionDataKey(section).heading);
    let heading = [
      "",
      "Line Item",
      "Cost Type",
      "Start Date",
      "End Date",
      "Contr. Impr.",
      "Deliv. Impr.",
      "Delivery Index",
      "Contr. Spend",
      "Actual Spend",
      "Starts",
      "Compl.",
      this.sectionDataKey(section).title,
    ];

    if (section == ToplineSummaryHead.RedX) {
      heading = [
        "",
        "Line Item",
        "Cost Type",
        "Start Date",
        "End Date",
        "Contr. Impr.",
        "Deliv. Impr.",
        "Delivery Index",
        "Contr. Spend",
        "Actual Spend",
        "Clicks",
        "CTR",
        "Conversions",
      ];
    } else if (section == ToplineSummaryHead.VIDSUITE) {
      heading = [
        "",
        "Line Item",
        "Cost Type",
        "Start Date",
        "End Date",
        "Contr. Impr.",
        "Deliv. Impr.",
        "Delivery Index",
        "Contr. Spend",
        "Actual Spend",
        "Starts",
        "Compl.",
        this.sectionDataKey(section).title,
      ];
    }
    const topHeading = this.addSectionHeading(heading);
    const topDataRow = topHeading.number + 1;
    topHeading.commit();
    const colCont = 13;
    let numFormat = "$#,###0";
    for (const row of data) {
      let rowContents: any = [];
      if (section == ToplineSummaryHead.RedX) {
        rowContents = [
          this.safeInt(row["Clicks"]) > 0 ? this.safeInt(row["Clicks"]) : "", //K 11
          0, //L 12
          this.safeInt(row["Conversions"]) > 0
            ? this.safeInt(row["Conversions"])
            : "", ///M  13
        ];
      } else {
        rowContents = [
          this.safeInt(row["Vid. Starts"]) > 0
            ? this.safeInt(row["Vid. Starts"])
            : "", //K 11
          this.safeInt(row["Vid. Compl."]) > 0
            ? this.safeInt(row["Vid. Compl."])
            : "", //L 12
          0,
        ]; //M 13
      }
      var rowData = [
        "", //A 1
        row["Sales_Order_Line_Item_Name"], //B 2
        row["Cost_Type_CPE_Beast"], //C 3
        row["Sales_Order_Line_Item_Start_Date"], //D 4
        row["Sales_Order_Line_Item_End_Date"], //E 5
        this.safeInt(row["Contr. Impr."]), //F 6
        this.safeInt(row["Deliv. Impr."]), //G 7
        this.safeFloat(row["Deliv. Index"]), //H 8
        this.safeFloat(row["Contr. Spend"]), //I 9
        this.safeFloat(row["Actual Spend"]), //J 10
        ...rowContents,
      ];
      var item = this.sheet.addRow(rowData);

      item.getCell(6).style.numFmt = "#,##0";
      item.getCell(7).style.numFmt = "#,##0";
      item.getCell(8).style.numFmt = "#0%";
      item.getCell(11).style.numFmt = "#,##0";

      if (section == ToplineSummaryHead.VIDSUITE) {
        numFormat = "$#,###0.00";
      }
      item.getCell(9).style.numFmt = numFormat;
      item.getCell(10).style.numFmt = numFormat;
      if (section == ToplineSummaryHead.RedX) {
        item.getCell(13).style.numFmt = "#,##0";
        var ctr = item.getCell(12);
        ctr.value = "";
        if (
          this.safeInt(row["Clicks"]) / this.safeInt(row["Deliv. Impr."]) >
          0
        ) {
          ctr.value = {
            formula: "IFERROR(K" + ctr.row + "/G" + ctr.row + ", 0)",
            date1904: false,
          };
        }
        ctr.style.numFmt = "0.00%";
      } else {
        item.getCell(12).style.numFmt = "#,##0";
        var ctr = item.getCell(13);
        ctr.value = "";
        try {
          if (
            this.safeInt(row["Vid. Compl."]) /
              this.safeInt(row["Vid. Starts"]) >
            0
          ) {
            ctr.value = {
              formula: "IFERROR(L" + ctr.row + "/K" + ctr.row + ", 0)",
              date1904: false,
            };
            ctr.style.numFmt = "#0.0%";
          }
        } catch (err) {}
      }
      for (var ri = 2; ri <= colCont; ri++) {
        var cell = item.getCell(ri);
        cell.border = this.standardCellBorder;
        cell.alignment = { horizontal: ri <= 4 ? "left" : "right" };
      }
      item.commit();
    }

    const totalTemplate = [
      "",
      "Total",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
    ];
    if (section != ToplineSummaryHead.Province) {
      const totalRow = this.sheet.addRow(totalTemplate);
      for (var tri = 2; tri <= colCont; tri++) {
        var totalCell = totalRow.getCell(tri);
        totalCell.border = this.standardCellBorder;
        totalCell.font = { bold: true, color: { argb: "FF000000" } };
        totalCell.alignment = { horizontal: tri === 2 ? "left" : "right" };
        totalCell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFCCCCCC" },
          bgColor: { argb: "FFFFFFFF" },
        };
      }
      totalRow.getCell(2).value = "Total";
      totalRow.getCell(6).value = {
        formula: "SUM(F" + topDataRow + ":F" + (totalRow.number - 1) + ")",
        date1904: false,
      };
      totalRow.getCell(6).style.numFmt = "#,##0";
      totalRow.getCell(7).value = {
        formula: "SUM(G" + topDataRow + ":G" + (totalRow.number - 1) + ")",
        date1904: false,
      };
      totalRow.getCell(7).style.numFmt = "#,##0";
      // totalRow.getCell(8).value = { formula: 'IFERROR(G' + totalRow.number + '/F' + totalRow.number + ', 0)', date1904: false }
      // totalRow.getCell(8).style.numFmt = '#0%'

      totalRow.getCell(9).value = {
        formula: "SUM(I" + topDataRow + ":I" + (totalRow.number - 1) + ")",
        date1904: false,
      };
      totalRow.getCell(9).style.numFmt = numFormat;
      totalRow.getCell(10).value = {
        formula: "SUM(J" + topDataRow + ":J" + (totalRow.number - 1) + ")",
        date1904: false,
      };
      totalRow.getCell(10).style.numFmt = numFormat;

      totalRow.getCell(11).value = {
        formula: "SUM(K" + topDataRow + ":K" + (totalRow.number - 1) + ")",
        date1904: false,
      };
      totalRow.getCell(11).style.numFmt = "#,##0;-0;;@";

      if (section == ToplineSummaryHead.RedX) {
        //totalRow.getCell(7).value = "";
        //totalRow.getCell(11).value = "";
        totalRow.getCell(8).value = {
           formula: 'IFERROR(G' + totalRow.number + '/F' + totalRow.number + ', 0)', date1904: false }
        totalRow.getCell(8).style.numFmt = '#0%'
        totalRow.getCell(13).value = { 
           formula: 'SUM(M' + topDataRow + ':M' + (totalRow.number - 1) + ')', date1904: false }
        totalRow.getCell(13).style.numFmt = '#,##0;-0;;@'
        //totalRow.getCell(12).value = "";
        //totalRow.getCell(13).value = "";
      } else {
        totalRow.getCell(12).value = {
          formula: "SUM(L" + topDataRow + ":L" + (totalRow.number - 1) + ")",
          date1904: false,
        };
        totalRow.getCell(12).style.numFmt = "#,##0;-0;;@";
      }

      totalRow.commit();
    }
  }

  public addSponsorshipTakeOver(data: any): void {
    if (!data || data?.length <= 0) {
      return;
    }

    this.addSummaryLineHeading("Sponsorship/Takeover Summary");
    const heading = [
      "",
      "Line Item",
      "Cost Type",
      "Start Date",
      "End Date",
      "Contr. Days",
      "Deliv. Impr.",
      "Delivery Index",
      "Contr. Spend",
      "Actual Spend",
      "Clicks",
      "CTR",
      "Vid. Starts",
      "Vid. Compl.",
      "VCR",
    ];
    const topHeading = this.addSectionHeading(heading);
    const topDataRow = topHeading.number + 1;
    topHeading.commit();
    const colCont = 15;
    for (const row of data) {
      var rowData = [
        "",
        row["Sales_Order_Line_Item_Name"], //B 2
        row["Cost_Type_CPE_Beast"], //C 3
        row["Sales_Order_Line_Item_Start_Date"], //E 4
        row["Sales_Order_Line_Item_End_Date"], //E 5
        row["Contr Days"], //F 6
        this.safeInt(row["Deliv. Impr."]), //G 7
        this.safeFloat(row["Deliv Index"]), //H 8
        this.safeInt(row["Contr. Spend"]), //I 9
        this.safeInt(row["Actual Spend"]), //J 10
        this.safeInt(row["Clicks"]) > 0 ? this.safeInt(row["Clicks"]) : "", //K 11
        0, //L 12
        this.safeFloat(row["Vid. Starts"]) > 0
          ? this.safeFloat(row["Vid. Starts"])
          : "", //M  13
        this.safeFloat(row["Vid. Compl."]) > 0
          ? this.safeFloat(row["Vid. Compl."])
          : "", //N 14
        0, //O 15
      ];
      var item = this.sheet.addRow(rowData);

      item.getCell(7).style.numFmt = "#,##0";
      item.getCell(11).style.numFmt = "#,##0";
      item.getCell(12).style.numFmt = "#0.0%";
      item.getCell(13).style.numFmt = "#,##0";
      item.getCell(14).style.numFmt = "#,##0";
      item.getCell(9).style.numFmt = "$#,##0.00";
      item.getCell(8).style.numFmt = "#0.0%";
      item.getCell(10).style.numFmt = "$#,##0.00";
      var ctr = item.getCell(12);
      ctr.value = "";
      try {
        if (
          this.safeInt(row["Clicks"]) / this.safeInt(row["Deliv. Impr."]) >
          0
        ) {
          ctr.value = {
            formula: "IFERROR(K" + ctr.row + "/G" + ctr.row + ", 0)",
            date1904: false,
          };
          ctr.style.numFmt = "0.00%";
        }
      } catch (err) {}

      ctr = item.getCell(15);
      ctr.value = "";
      try {
        if (
          this.safeFloat(row["Vid. Compl."]) /
            this.safeFloat(row["Vid. Starts"]) >
          0
        ) {
          ctr.value = {
            formula: "IFERROR(N" + ctr.row + "/M" + ctr.row + ", 0)",
            date1904: false,
          };
          ctr.style.numFmt = "0.00%";
        }
      } catch (err) {}

      for (var ri = 2; ri <= colCont; ri++) {
        var cell = item.getCell(ri);
        cell.border = this.standardCellBorder;
        cell.alignment = { horizontal: ri <= 5 ? "left" : "right" };
      }

      item.commit();
    }

    const totalTemplate = ["", "Total", "", "", "", "", "", "", ""];

    const totalRow = this.sheet.addRow(totalTemplate);
    for (var tri = 2; tri <= colCont; tri++) {
      var totalCell = totalRow.getCell(tri);
      totalCell.border = this.standardCellBorder;
      totalCell.font = { bold: true, color: { argb: "FF000000" } };
      totalCell.alignment = { horizontal: tri === 2 ? "left" : "right" };
      totalCell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFCCCCCC" },
        bgColor: { argb: "FFFFFFFF" },
      };
    }
    totalRow.getCell(2).value = "Total";
    totalRow.getCell(7).value = {
      formula: "SUM(G" + topDataRow + ":G" + (totalRow.number - 1) + ")",
      date1904: false,
    };
    totalRow.getCell(7).style.numFmt = "#,##0";
    totalRow.getCell(11).value = {
      formula: "SUM(K" + topDataRow + ":K" + (totalRow.number - 1) + ")",
      date1904: false,
    };
    totalRow.getCell(11).style.numFmt = "#,##0;-0;;@";
    //totalRow.getCell(12).value = { formula: 'IFERROR(K' + totalRow.number + '/G' + totalRow.number + ', 0)', date1904: false }
    //totalRow.getCell(12).style.numFmt = '0.00%'

    totalRow.getCell(13).value = {
      formula: "SUM(M" + topDataRow + ":M" + (totalRow.number - 1) + ")",
      date1904: false,
    };
    totalRow.getCell(13).style.numFmt = "#,##0;-0;;@";
    totalRow.getCell(14).value = {
      formula: "SUM(N" + topDataRow + ":N" + (totalRow.number - 1) + ")",
      date1904: false,
    };
    totalRow.getCell(14).style.numFmt = "#,##0;-0;;@";
    totalRow.getCell(15).value = {
      formula: "IFERROR(N" + totalRow.number + "/M" + totalRow.number + ', "")',
      date1904: false,
    };
    totalRow.getCell(15).style.numFmt = "0.00%";
    totalRow.getCell(9).value = {
      formula: "SUM(I" + topDataRow + ":I" + (totalRow.number - 1) + ")",
      date1904: false,
    };
    totalRow.getCell(9).style.numFmt = "$#,##0.00";
    totalRow.getCell(10).value = {
      formula: "SUM(J" + topDataRow + ":J" + (totalRow.number - 1) + ")",
      date1904: false,
    };
    totalRow.getCell(10).style.numFmt = "$#,##0.00";

    totalRow.commit();
  }

  public addDisplayVideo(data: any): void {
    if (!data || data?.length <= 0) {
      return;
    }
    this.addSummaryLineHeading("Display & Video Brandsell Summary");
    const heading = [
      "",
      "Line Item",
      "Cost Type",
      "Start Date",
      "End Date",
      "Contr. Impr.",
      "Deliv. Impr.",
      "Delivery Index",
      "Contr. Spend",
      "Actual Spend",
      "Clicks",
      "CTR",
      "Vid. Starts",
      "Vid. Compl.",
      "VCR",
    ];
    const topHeading = this.addSectionHeading(heading);
    const topDataRow = topHeading.number + 1;
    topHeading.commit();
    const colCont = 15;
    for (const row of data) {
      var rowData = [
        "",
        row["Sales_Order_Line_Item_Name"], //B 2
        row["Cost_Type_CPE_Beast"], //C 3
        row["Sales_Order_Line_Item_Start_Date"], //D 4
        row["Sales_Order_Line_Item_End_Date"], //E 5
        this.safeInt(row["Contr. Impr."]), //F 6
        this.safeInt(row["Deliv. Impr."]), //G 7
        this.safeFloat(row["Deliv. Index"]), //H 8
        this.safeFloat(row["Contr. Spend"]), //I 9
        this.safeFloat(row["Actual Spend"]), //J 10
        this.safeInt(row["Clicks"]) > 0 ? this.safeInt(row["Clicks"]) : "", //K 11
        0, //L 12
        this.safeFloat(row["Vid. Starts"]) > 0
          ? this.safeFloat(row["Vid. Starts"])
          : "", //M 13
        this.safeFloat(row["Vid. Compl."]) > 0
          ? this.safeFloat(row["Vid. Compl."])
          : "", //N 14
        0, //O 15
      ];
      var item = this.sheet.addRow(rowData);

      item.getCell(6).style.numFmt = "#,##0";
      item.getCell(7).style.numFmt = "#,##0";
      item.getCell(8).style.numFmt = "#0.0%";
      item.getCell(11).style.numFmt = "#,##0";
      item.getCell(13).style.numFmt = "#,##0";
      item.getCell(14).style.numFmt = "#,##0";
      item.getCell(9).style.numFmt = "$#,##0.00";
      item.getCell(10).style.numFmt = "$#,##0.00";
      var ctr = item.getCell(12);
      ctr.value = "";
      try {
        if (
          this.safeInt(row["Clicks"]) / this.safeInt(row["Deliv. Impr."]) >
          0
        ) {
          ctr.value = {
            formula: "IFERROR(K" + ctr.row + "/G" + ctr.row + ", 0)",
            date1904: false,
          };
          ctr.style.numFmt = "0.00%";
        }
      } catch (err) {}

      ctr = item.getCell(15);
      ctr.value = "";
      try {
        if (
          this.safeFloat(row["Vid. Compl."]) /
            this.safeFloat(row["Vid. Starts"]) >
          0
        )
          ctr.value = {
            formula: "IFERROR(N" + ctr.row + "/M" + ctr.row + ", 0)",
            date1904: false,
          };
        ctr.style.numFmt = "0.00%";
      } catch (err) {}

      for (var ri = 2; ri <= colCont; ri++) {
        var cell = item.getCell(ri);
        cell.border = this.standardCellBorder;
        cell.alignment = { horizontal: ri <= 5 ? "left" : "right" };
      }
      item.commit();
    }

    const totalTemplate = ["", "Total", "", "", "", "", "", "", ""];

    const totalRow = this.sheet.addRow(totalTemplate);
    for (var tri = 2; tri <= colCont; tri++) {
      var totalCell = totalRow.getCell(tri);
      totalCell.border = this.standardCellBorder;
      totalCell.font = { bold: true, color: { argb: "FF000000" } };
      totalCell.alignment = { horizontal: tri === 2 ? "left" : "right" };
      totalCell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFCCCCCC" },
        bgColor: { argb: "FFFFFFFF" },
      };
    }
    totalRow.getCell(2).value = "Total";
    totalRow.getCell(6).value = {
      formula: "SUM(F" + topDataRow + ":F" + (totalRow.number - 1) + ")",
      date1904: false,
    };
    totalRow.getCell(6).style.numFmt = "#,##0";
    totalRow.getCell(7).value = {
      formula: "SUM(G" + topDataRow + ":G" + (totalRow.number - 1) + ")",
      date1904: false,
    };
    totalRow.getCell(7).style.numFmt = "#,##0";

    totalRow.getCell(11).value = {
      formula: "SUM(K" + topDataRow + ":K" + (totalRow.number - 1) + ")",
      date1904: false,
    };
    totalRow.getCell(11).style.numFmt = "#,##0;-0;;@";
    // totalRow.getCell(11).value = { formula: 'IFERROR(J' + totalRow.number + '/H' + totalRow.number + ', 0)', date1904: false }
    // totalRow.getCell(11).style.numFmt = '0.00%'
    totalRow.getCell(13).value = {
      formula: "SUM(M" + topDataRow + ":M" + (totalRow.number - 1) + ")",
      date1904: false,
    };
    totalRow.getCell(13).style.numFmt = "#,##0;-0;;@";
    totalRow.getCell(14).value = {
      formula: "SUM(N" + topDataRow + ":N" + (totalRow.number - 1) + ")",
      date1904: false,
    };
    totalRow.getCell(14).style.numFmt = "#,##0;-0;;@";
    // totalRow.getCell(14).value = { formula: 'IFERROR(M' + totalRow.number + '/L' + totalRow.number + ', 0)', date1904: false }
    // totalRow.getCell(14).style.numFmt = '0.00%'
    totalRow.getCell(9).value = {
      formula: "SUM(I" + topDataRow + ":I" + (totalRow.number - 1) + ")",
      date1904: false,
    };
    totalRow.getCell(9).style.numFmt = "$#,##0.00";
    totalRow.getCell(10).value = {
      formula: "SUM(J" + topDataRow + ":J" + (totalRow.number - 1) + ")",
      date1904: false,
    };
    totalRow.getCell(10).style.numFmt = "$#,##0.00";
    totalRow.commit();
  }

  public addContoSummary(data: any): void {
    if (!data || data?.length <= 0) {
      return;
    }
    this.addSummaryLineHeading("Contobox Summary");
    const heading = [
      "",
      "Line Item",
      "Cost Type",
      "Start Date",
      "End Date",
      "Contr.Units",
      "Deliv. Impr.",
      "Deliv. Engage.",
      "Contr. Spend",
      "Actual Spend",
      "Clicks",
      "CTR",
      "Vid. Starts",
      "Vid. Compl.",
      "VCR",
    ];
    const topHeading = this.addSectionHeading(heading);
    const topDataRow = topHeading.number + 1;
    topHeading.commit();
    const colCont = 15;
    for (const row of data) {
      var rowData = [
        "",
        row["Sales_Order_Line_Item_Name"], //B 2
        row["Cost_Type_CPE_Beast"], //C 3
        row["Sales_Order_Line_Item_Start_Date"], //D 4
        row["Sales_Order_Line_Item_End_Date"], //E 5
        this.safeInt(row["Contr. Units"]), //F 6
        this.safeFloat(row["Deliv. Impr."]), //G 7
        this.safeFloat(row["Deliv. Engage."]), //H 8
        this.safeFloat(row["Contr. Spend"]), //I 9
        this.safeFloat(row["Actual Spend"]), //J 10
        this.safeInt(row["Clicks"]) > 0 ? this.safeInt(row["Clicks"]) : "", //K 11
        0, //L 12
        this.safeFloat(row["Vid. Starts"]) > 0
          ? this.safeFloat(row["Vid. Starts"])
          : "", //M 13
        this.safeFloat(row["Vid. Compl."]) > 0
          ? this.safeFloat(row["Vid. Compl."])
          : "", //N 14
        0, //O 15
      ];
      var item = this.sheet.addRow(rowData);
      item.getCell(6).style.numFmt = "#,##0";
      item.getCell(7).style.numFmt = "#,##0";
      item.getCell(8).style.numFmt = "#,##0";
      item.getCell(11).style.numFmt = "#,##0";
      item.getCell(13).style.numFmt = "#,##0";
      item.getCell(14).style.numFmt = "#,##0";
      item.getCell(9).style.numFmt = "$#,###0";
      item.getCell(10).style.numFmt = "$#,###0";
      var ctr = item.getCell(12);
      ctr.value = "";
      try {
        if (
          this.safeInt(row["Clicks"]) / this.safeFloat(row["Deliv. Impr."]) >
          0
        ) {
          ctr.value = {
            formula: "IFERROR(K" + ctr.row + "/G" + ctr.row + ", 0)",
            date1904: false,
          };
          ctr.style.numFmt = "0.00%";
        }
      } catch (err) {}

      ctr = item.getCell(15);
      ctr.value = "";
      try {
        if (
          this.safeFloat(row["Vid. Compl."]) /
            this.safeFloat(row["Vid. Starts"]) >
          0
        ) {
          ctr.value = {
            formula: "IFERROR(N" + ctr.row + "/M" + ctr.row + ", 0)",
            date1904: false,
          };
          ctr.style.numFmt = "0.00%";
        }
      } catch (err) {}

      for (var ri = 2; ri <= colCont; ri++) {
        var cell = item.getCell(ri);
        cell.border = this.standardCellBorder;
        cell.alignment = { horizontal: ri <= 5 ? "left" : "right" };
      }

      item.commit();
    }

    const totalTemplate = ["", "Total", "", "", "", "", "", "", ""];

    const totalRow = this.sheet.addRow(totalTemplate);
    for (var tri = 2; tri <= colCont; tri++) {
      var totalCell = totalRow.getCell(tri);
      totalCell.border = this.standardCellBorder;
      totalCell.font = { bold: true, color: { argb: "FF000000" } };
      totalCell.alignment = { horizontal: tri === 2 ? "left" : "right" };
      totalCell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFCCCCCC" },
        bgColor: { argb: "FFFFFFFF" },
      };
    }
    totalRow.getCell(2).value = "Total";

    totalRow.getCell(7).value = {
      formula: "SUM(G" + topDataRow + ":G" + (totalRow.number - 1) + ")",
      date1904: false,
    };
    totalRow.getCell(7).style.numFmt = "#,##0";
    totalRow.getCell(8).value = {
      formula: "SUM(H" + topDataRow + ":H" + (totalRow.number - 1) + ")",
      date1904: false,
    };
    totalRow.getCell(8).style.numFmt = "#,##0";
    totalRow.getCell(11).value = {
      formula: "SUM(K" + topDataRow + ":K" + (totalRow.number - 1) + ")",
      date1904: false,
    };
    totalRow.getCell(11).style.numFmt = "#,##0;-0;;@";
    // totalRow.getCell(12).value = { formula: 'IFERROR(K' + totalRow.number + '/I' + totalRow.number + ', 0)', date1904: false }
    // totalRow.getCell(12).style.numFmt = '0.00%'
    totalRow.getCell(13).value = {
      formula: "SUM(M" + topDataRow + ":M" + (totalRow.number - 1) + ")",
      date1904: false,
    };
    totalRow.getCell(13).style.numFmt = "#,##;-0;;@";
    totalRow.getCell(14).value = {
      formula: "SUM(N" + topDataRow + ":N" + (totalRow.number - 1) + ")",
      date1904: false,
    };
    totalRow.getCell(14).style.numFmt = "#,##;-0;;@";
    // totalRow.getCell(15).value = { formula: 'IFERROR(N' + totalRow.number + '/M' + totalRow.number + ', 0)', date1904: false }
    // totalRow.getCell(15).style.numFmt = '0.00%'
    totalRow.getCell(9).value = {
      formula: "SUM(I" + topDataRow + ":I" + (totalRow.number - 1) + ")",
      date1904: false,
    };
    totalRow.getCell(9).style.numFmt = "$#,###0";
    totalRow.getCell(10).value = {
      formula: "SUM(J" + topDataRow + ":J" + (totalRow.number - 1) + ")",
      date1904: false,
    };
    totalRow.getCell(10).style.numFmt = "$#,###0";
    totalRow.commit();
  }

  public addSocialSummary(data: any): void {
    if (!data || data?.length <= 0) {
      return;
    }
    this.addSummaryLineHeading("Social Summary");
    const heading = [
      "",
      "Line Item",
      "Platform",
      "Start Date",
      "End Date",
      "Contr. Days",
      "Deliv. Impr.",
      "Delivery Index",
      "Contr. Spend",
      "Actual Spend",
      "Clicks",
      "CTR",
      "Vid. Starts",
      "Vid. Compl.",
      "VCR",
      "Engagements",
      "Engagement Rate",
    ];
    const topHeading = this.addSectionHeading(heading);
    const topDataRow = topHeading.number + 1;
    topHeading.commit();
    const colCont = 17;
    for (const row of data) {
      var rowData = [
        "",
        row["Sales_Order_Line_Item_Name"], //B 2
        row["Social_Platform_Beast"], //C 3
        row["Sales_Order_Line_Item_Start_Date"], //D 4
        row["Sales_Order_Line_Item_End_Date"], //E 5
        row["Contr Days"], //F 6
        this.safeFloat(row["Deliv. Impr."]), //G 7
        row["Deliv Index"], //H 8
        this.safeFloat(row["Contr. Spend"]), //I 9
        this.safeFloat(row["Actual Spend"]), //J 10,
        this.safeInt(row["Clicks"]) > 0 ? this.safeInt(row["Clicks"]) : "", //K 11
        0, //L 12
        this.safeFloat(row["Vid. Starts"]) > 0
          ? this.safeFloat(row["Vid. Starts"])
          : "", //M 13
        this.safeFloat(row["Vid. Compl."]) > 0
          ? this.safeFloat(row["Vid. Compl."])
          : "", //N 14
        0, //O 15
        this.safeFloat(row["Engagements"]) > 0
          ? this.safeFloat(row["Engagements"])
          : "", //P 16
        0, //Q 17
      ];
      var item = this.sheet.addRow(rowData);
      item.getCell(7).style.numFmt = "#,##0";
      item.getCell(16).style.numFmt = "#,##0";
      item.getCell(11).style.numFmt = "#,##0";
      item.getCell(13).style.numFmt = "#,##0";
      item.getCell(14).style.numFmt = "#,##0";
      item.getCell(15).style.numFmt = "#,##0";
      item.getCell(9).style.numFmt = "$#,###0";
      item.getCell(10).style.numFmt = "$#,###0";
      var ctr = item.getCell(17);
      ctr.value = "";
      try {
        if (
          this.safeFloat(row["Engagements"]) /
            this.safeFloat(row["Deliv. Impr."]) >
          0
        ) {
          ctr.value = {
            formula: "IFERROR(P" + ctr.row + "/G" + ctr.row + ", 0)",
            date1904: false,
          };
          ctr.style.numFmt = "0.0%";
        }
      } catch (err) {}

      ctr = item.getCell(12);
      ctr.value = "";
      try {
        if (
          this.safeInt(row["Clicks"]) / this.safeFloat(row["Deliv. Impr."]) >
          0
        )
          ctr.value = {
            formula: "IFERROR(K" + ctr.row + "/G" + ctr.row + ", 0)",
            date1904: false,
          };
        ctr.style.numFmt = "0.00%";
      } catch (err) {}

      ctr = item.getCell(15);
      ctr.value = "";
      try {
        if (
          this.safeFloat(row["Vid. Compl."]) /
            this.safeFloat(row["Vid. Starts"]) >
          0
        ) {
          ctr.value = {
            formula: "IFERROR(N" + ctr.row + "/M" + ctr.row + ", 0)",
            date1904: false,
          };
          ctr.style.numFmt = "0.00%";
        }
      } catch (err) {}

      for (var ri = 2; ri <= colCont; ri++) {
        var cell = item.getCell(ri);
        cell.border = this.standardCellBorder;
        cell.alignment = { horizontal: ri <= 5 ? "left" : "right" };
      }

      item.commit();
    }

    const totalTemplate = ["", "Total", "", "", "", "", "", "", ""];

    const totalRow = this.sheet.addRow(totalTemplate);
    for (var tri = 2; tri <= colCont; tri++) {
      var totalCell = totalRow.getCell(tri);
      totalCell.border = this.standardCellBorder;
      totalCell.font = { bold: true, color: { argb: "FF000000" } };
      totalCell.alignment = { horizontal: tri === 2 ? "left" : "right" };
      totalCell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFCCCCCC" },
        bgColor: { argb: "FFFFFFFF" },
      };
    }
    totalRow.getCell(2).value = "Total";

    totalRow.getCell(7).value = {
      formula: "SUM(G" + topDataRow + ":G" + (totalRow.number - 1) + ")",
      date1904: false,
    };
    totalRow.getCell(7).style.numFmt = "#,##0";
    totalRow.getCell(16).value = {
      formula: "SUM(P" + topDataRow + ":P" + (totalRow.number - 1) + ")",
      date1904: false,
    };
    totalRow.getCell(16).style.numFmt = "#,##0;-0;;@";
    totalRow.getCell(17).value = {
      formula: "IFERROR(P" + totalRow.number + "/H" + totalRow.number + ", 0)",
      date1904: false,
    };
    totalRow.getCell(17).style.numFmt = "0.00%;-0;;@";
    totalRow.getCell(11).value = {
      formula: "SUM(K" + topDataRow + ":K" + (totalRow.number - 1) + ")",
      date1904: false,
    };
    totalRow.getCell(11).style.numFmt = "#,##0;-0;;@";
    // totalRow.getCell(12).value = { formula: 'IFERROR(K' + totalRow.number + '/H' + totalRow.number + ', 0)', date1904: false }
    // totalRow.getCell(12).style.numFmt = '0.00%'
    totalRow.getCell(9).value = {
      formula: "SUM(I" + topDataRow + ":I" + (totalRow.number - 1) + ")",
      date1904: false,
    };
    totalRow.getCell(9).style.numFmt = "$#,##";
    totalRow.getCell(10).value = {
      formula: "SUM(J" + topDataRow + ":J" + (totalRow.number - 1) + ")",
      date1904: false,
    };
    totalRow.getCell(10).style.numFmt = "$#,##";
    // totalRow.getCell(15).value = { formula: 'IFERROR(N' + totalRow.number + '/M' + totalRow.number + ', 0)', date1904: false }
    // totalRow.getCell(15).style.numFmt = '0.00%'
    totalRow.getCell(13).value = {
      formula: "SUM(M" + topDataRow + ":M" + (totalRow.number - 1) + ")",
      date1904: false,
    };
    totalRow.getCell(13).style.numFmt = "#,###0;-0;;@";
    totalRow.getCell(14).value = {
      formula: "SUM(N" + topDataRow + ":N" + (totalRow.number - 1) + ")",
      date1904: false,
    };
    totalRow.getCell(14).style.numFmt = "#,###0;-0;;@";
    totalRow.commit();
  }

  public addToplineSummary(data: any): void {
    if (!data || data?.length <= 0) {
      return;
    }

    this.addSummaryLineHeading("Topline Summary");
    const heading = [
      "",
      "Tactic / Grouping",
      "Cost Type",
      "Contracted Quantity",
      "Deliv. Impr.",
      "Deliv. Index",
      "Clicks",
      "CTR",
      "Contr. Spend",
      "Actual Spend",
      "Starts",
      "Compl.",
      "Compl. Rate",
      "Engagements",
      "Engagement Rate",
    ];
    const topHeading = this.addSectionHeading(heading);
    const topDataRow = topHeading.number + 1;
    topHeading.commit();
    const colCont = 15;
    for (const row of data) {
      var rowData = [
        "",
        row["Topline Classification"], //B 2
        row["Cost_Type"], //C 3
        this.safeInt(row["Contracted Impressions"]), //D 4
        this.safeInt(row["Delivered Impressions"]), //E 5
        this.safeFloat(row["Delivery Index"]), //F 6
        this.safeInt(row["Clicks"]) > 0 ? this.safeInt(row["Clicks"]) : "", //G 7         //blank if 0
        "", //H 8                            //blank if 0
        this.safeFloat(row["Contracted Spend"]), //I 9
        this.safeFloat(row["Actual Spend"]), //J 10
        this.safeInt(row["Video Starts"]) > 0
          ? this.safeInt(row["Video Starts"])
          : "", //K  11
        this.safeInt(row["Video Completes"]) > 0
          ? this.safeInt(row["Video Completes"])
          : "", //L 12 //blank if 0
        "", //M 13                                  //blank if 0
        this.safeInt(row["Engagements"]) > 0
          ? this.safeInt(row["Engagements"])
          : "", // N 14 //blank if 0
        "", //O 15
      ];
      var item = this.sheet.addRow(rowData);
      item.getCell(4).style.numFmt = "#,##0";
      item.getCell(5).style.numFmt = "#,##0";
      item.getCell(6).style.numFmt = "#0%;-0;;@";
      item.getCell(9).style.numFmt = "$#,##0";
      item.getCell(10).style.numFmt = "$#,##0";
      item.getCell(11).style.numFmt = "#,##0";
      item.getCell(12).style.numFmt = "#,##0";
      item.getCell(7).style.numFmt = "#,##0";

      var ctr = item.getCell(8);
      ctr.value = "";
      try {
        if (
          this.safeInt(row["Clicks"]) /
            this.safeInt(row["Delivered Impressions"]) >
          0
        ) {
          ctr.value = {
            formula: "IFERROR(G" + ctr.row + "/E" + ctr.row + ", 0)",
            date1904: false,
          };
          ctr.style.numFmt = "0.00%";
        }
      } catch (err) {}

      ctr = item.getCell(13);
      ctr.value = "";
      try {
        if (
          this.safeInt(row["Video Completes"]) /
            this.safeInt(row["Video Starts"]) >
          0
        ) {
          ctr.value = {
            formula: "IFERROR(L" + ctr.row + "/K" + ctr.row + ", 0)",
            date1904: false,
          };
          ctr.style.numFmt = "0.0%";
        }
      } catch (err) {}

      ctr = item.getCell(15);
      ctr.value = "";
      try {
        if (
          this.safeInt(row["Engagements"]) /
            this.safeInt(row["Delivered Impressions"]) >
          0
        ) {
          ctr.value = {
            formula: "IFERROR(N" + ctr.row + "/E" + ctr.row + ", 0)",
            date1904: false,
          };
          ctr.style.numFmt = "0.00%";
        }
      } catch (err) {}
      for (var ri = 2; ri <= colCont; ri++) {
        var cell = item.getCell(ri);
        cell.border = this.standardCellBorder;
        cell.alignment = { horizontal: ri <= 3 ? "left" : "right" };
      }
      item.commit();
    }

    const totalTemplate = [
      "",
      "Total",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
    ];

    const totalRow = this.sheet.addRow(totalTemplate);
    for (var tri = 2; tri <= colCont; tri++) {
      var totalCell = totalRow.getCell(tri);
      totalCell.border = this.standardCellBorder;
      totalCell.font = { bold: true, color: { argb: "FF000000" } };
      totalCell.alignment = { horizontal: tri === 2 ? "left" : "right" };
      totalCell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFCCCCCC" },
        bgColor: { argb: "FFFFFFFF" },
      };
    }
    totalRow.getCell(2).value = "Total";
    totalRow.getCell(4).value = {
      formula: "SUM(D" + topDataRow + ":D" + (totalRow.number - 1) + ")",
      date1904: false,
    };
    totalRow.getCell(4).style.numFmt = "#,##0";
    totalRow.getCell(5).value = {
      formula: "SUM(E" + topDataRow + ":E" + (totalRow.number - 1) + ")",
      date1904: false,
    };
    totalRow.getCell(5).style.numFmt = "#,##0";
    totalRow.getCell(7).value = {
      formula: "SUM(G" + topDataRow + ":G" + (totalRow.number - 1) + ")",
      date1904: false,
    };
    totalRow.getCell(7).style.numFmt = "#,##0;-0;;@";
    totalRow.getCell(8).value = {
      formula: "IFERROR(G" + totalRow.number + "/E" + totalRow.number + ', "")',
      date1904: false,
    };
    totalRow.getCell(8).style.numFmt = "0.00%;-0;;@";
    totalRow.getCell(9).value = {
      formula: "SUM(I" + topDataRow + ":I" + (totalRow.number - 1) + ")",
      date1904: false,
    };
    totalRow.getCell(9).style.numFmt = "$#,###0";
    totalRow.getCell(10).value = {
      formula: "SUM(J" + topDataRow + ":J" + (totalRow.number - 1) + ")",
      date1904: false,
    };
    totalRow.getCell(10).style.numFmt = "$#,###0";
    totalRow.getCell(11).value = {
      formula: "SUM(K" + topDataRow + ":K" + (totalRow.number - 1) + ")",
      date1904: false,
    };
    totalRow.getCell(11).style.numFmt = "#,###0;-0;;@";
    totalRow.getCell(14).value = {
      formula: "SUM(N" + topDataRow + ":N" + (totalRow.number - 1) + ")",
      date1904: false,
    };
    totalRow.getCell(14).style.numFmt = "#,###0;-0;;@";
    totalRow.getCell(12).value = {
      formula: "SUM(L" + topDataRow + ":L" + (totalRow.number - 1) + ")",
      date1904: false,
    };
    totalRow.getCell(12).style.numFmt = "#,###0;-0;;@";

    totalRow.commit();
  }

  addSummaryLineHeading(heading: string) {
    const sectionHeading = this.sheet.addRow(["", heading]);
    sectionHeading.font = { bold: true, size: 20 };
    sectionHeading.height = 60;
    sectionHeading.commit();
  }
}
export default ToplineSummary;
