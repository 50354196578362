/* eslint-disable */
export function isDropZoneContainsList(DDItem: any) {
    let BreakException = { isListDataExist: false };
    try {
        DDItem.forEach(function (item: any) {
            if (item.isSelected) {
                BreakException.isListDataExist = true;
                throw BreakException;
            }
        });
    } catch (e) {
        if (e === BreakException) {
            return true;
        }
        else {
            return false;
        }
    }

    return false;
}

export function getTVExposureObject(payload: any) {
    const respObject = {
        width: "0%",
        msg: " "
    };

    if (payload <= 0) {
        respObject.width = "0%";
        respObject.msg = "";
    }
    else if (payload > 0 && payload < 480000) {
        respObject.width = "30%";
        respObject.msg = "POOR";
    }
    else if (payload >= 480000 && payload < 2400000) {
        respObject.width = "60%";
        respObject.msg = "GOOD";
    }
    else if (payload >= 2400000) {
        respObject.width = "80%";
        respObject.msg = "EXCELLENT";
    }

    return respObject;
}

export function getDigitalImpressionObject(payload: any) {
    const respObject = {
        width: "0%",
        msg: " "
    };

    if (payload <= 0) {
        respObject.width = "0%";
        respObject.msg = "";
    }
    else if (payload > 0 && payload < 1000000) {
        respObject.width = "30%";
        respObject.msg = "POOR";
    }
    else if (payload >= 1000000 && payload < 45000000) {
        respObject.width = "60%";
        respObject.msg = "GOOD";
    }
    else if (payload >= 45000000) {
        respObject.width = "80%";
        respObject.msg = "EXCELLENT";
    }

    return respObject;
}


export function getIndexOfCard(advArr: any, card: any) {
    return advArr.findIndex((obj: any) => obj.segmentName == card.segmentName);

}

export function isListContainsCorrectSelectedTraitsCount(DDItem: any) {
    let count = 0;
    try {
        DDItem.forEach(function (item: any) {
            if (item.isSelected) {
                count++;
            }
        });
    } catch (e) {
        return false;
    }
    return count;
}

export function getFormattedViabilityMsg(payload: any, selectedV: any) {

    if (selectedV === "tvSize") {
        return getTVExposureObject(payload);
    }
    else {
        return getDigitalImpressionObject(payload);
    }
}


export function getAgeChart() {
    return [
        {
            range: "18-24",
            percent: "0%"
        },
        {
            range: "25-34",
            percent: "78%"
        },
        {
            range: "35-44",
            percent: "33%"
        },
        {
            range: "45-54",
            percent: "42%"
        },
    ]
}

export function populateListItems(list: any) {
    const emptArr:any[] = [];
    let payload = [
        {
            title: "DEMOGRAPHICS",
            subcategorylist: [
                {
                    subcategorytitle: "",
                    listItems: emptArr
                },

            ],
            nonCatetories: emptArr,
            cls:"demographicInactive",
            Tabtype:0
        },
        {
            title: "INTERESTS",
            subcategorylist: [
                {
                    subcategorytitle: "",
                    listItems: emptArr
                }
            ],
            nonCatetories: emptArr,
            cls:"interestsInactive",
            Tabtype:1
        },
        {
            title: "BEHAVIOURS",
            subcategorylist: [
                {
                    subcategorytitle: "",
                    listItems:emptArr
                }
            ],
            nonCatetories: emptArr,
            cls:"behavioursInactive",
            Tabtype:2
        }
    ];
   
   
    list.map((card: any, i: any) => {
        if (!card.isSelected) {
             if(card.subCategory !==null && card.subCategory !== undefined && card.subCategory !== "") {
               
                const index =  payload[card.Tabtype].subcategorylist.findIndex((obj: any) => obj.subcategorytitle === card.subCategory);
                if (index >= 0) {
                    payload[card.Tabtype].subcategorylist[index].listItems.push(card);
                }
                else{
                    const emtpycItem = {
                        subcategorytitle: card.subCategory,
                        listItems:card
                    };
                     
                    payload[card.Tabtype].subcategorylist.push(emtpycItem);
                }
            }
            else{
                    payload[card.Tabtype].nonCatetories.push(card);
            }
            // payload[card.Tabtype]  
        }
    });

    return payload;

}

export function isCardAdded(payload:any,card:any) {

        const index =  payload[card.Tabtype].nonCatetories.findIndex((obj: any) => obj.segmentId === card.segmentId);
         if (index >= 0) {
            return index;
        }
        return -1;
 
   
}