import * as React from "react";
import { connect } from "react-redux";
import MessageBox from "../../CommonComponent/MessageBox";
import { KeyCode } from "../../ConstConfig/KeyCode";
import { UserOps } from "../../ConstConfig/UserOps";
import {
  searchReportAction,
  downloadReportAction,
  reportResponseAction,
  senDomoAccessLog,
} from "../../Actions";
import { Configs, ActionConstants } from "../../ConstConfig";
import {
  clearReportingErrorState,
  getRecentReportTable,
  getRecentReportDeviceTable,
  getPacingReportTable,
  getPacingReportDeviceTable,
  getRecentReportSortedColName,
} from "../utils/ReportingUtil";
import { List } from "immutable";
import CustomErrorAlertBanner from "../../CommonComponent/CustomErrorAlertBanner.";
import { ReportingModel } from "../Model/ReportingModel";
import { FormattedNumber } from "react-intl";
import RedUISpinner from "../../Panel/RedUISpinner";
import { isEmptyOrSpaces } from "../../Utility/CampaignUtil";
import ReportingRangeModel from "../Model/ReportingRangeModel";
import AlertBannerComponent from "../../CommonComponent/AlertBanner/AlertBannerComponent";
import ReportingTablePageComponent from "../../CommonComponent/ReportingTablePageComponent";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import GotoSlider from "../../GotoSlider";
import PacingModel from "../Model/PacingModel";
import { getRoleConfigs } from "../../Utility/roleBasedAttrib";
import settingsConsts from "../../ConstConfig/settingsConst";
import { showSearchPanel } from "src/rogersframework/Store/helper";

class ReportingFormComponent extends React.Component<
  IReportingFormComponent,
  {}
> {
  public state: any;
  private refArr: any;
  private errorStateCheck: any;
  private config: any;
  private proposalID: any;
  private static MAX_ROWSIZE = 25;
  private reportModel: any;
  private pacingModel: any;
  private reportRangeModel: any;

  constructor(props: any) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.errorStateCheck = clearReportingErrorState();
    this.downloadPPT = this.downloadPPT.bind(this);
    this.reportModel = new ReportingModel(null);
    this.pacingModel = new PacingModel(null);
    this.reportRangeModel = new ReportingRangeModel(null);
    this.downloadExcel = this.downloadExcel.bind(this);

    this.renderFormatPanel = this.renderFormatPanel.bind(this);
    this.onEnterPress = this.onEnterPress.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.getActionControls = this.getActionControls.bind(this);
    this.getAllColView = this.getAllColView.bind(this);
    this.getUserSelectedRange = this.getUserSelectedRange.bind(this);
    this.formatNumber = this.formatNumber.bind(this);
    this.expandComponent = this.expandComponent.bind(this);
    this.initTabSwitch = this.initTabSwitch.bind(this);
    this.config = new Configs();
    this.proposalID = null;
    this.refArr = [];
    this.state = { reportType: 0 };
  }

  componentDidMount() {
    this.props.handleDisableReportControls();
    if (
      this.props.UserAssignedRole.roles.indexOf(
        UserOps.REPORTINGRECENTREPORT
      ) >= 0
    )
      this.populateReportTable();

    if (this.props.UserAssignedRole.roles.indexOf(UserOps.REPORTINGPACING) >= 0)
      this.populatePacingReportTable();
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (this.props.allReportsList instanceof Object) {
      this.reportModel.buildModel(this.props.allReportsList);
    }
  }

  componentWillReceiveProps(prevProps: any, prevState: any) {
    if (this.props.allReportsList instanceof Object) {
      this.reportModel.buildModel(this.props.allReportsList);
    }
  }

  public getUserSelectedRange() {
    return this.reportRangeModel.getUserSelectedRange();
  }

  renderFormatPanel() {
    let linkPath =
      "https://rogers.domo.com/page/1405209425?pfilters=%5B%7B%22column%22:%22Order_ID%22,%22operand%22:%22EQUALS%22,%22values%22:%5B" +
      this.proposalID +
      "%5D%7D%5D";
    let conf = this.props.UserRole;
    let userSelOption = this.reportRangeModel.getUserSelectedRange();
    if (!userSelOption || !userSelOption.fileId) {
      userSelOption.fileId = this.proposalID;
    }
    if (
      this.props.reportResponse.hasOwnProperty("proposalExist") &&
      this.props.reportResponse.proposalExist
    ) {
      let configExclHrefLinks =
        this.props.coreConfigData.Excel.userSelected ==
        settingsConsts.USEEXT_EXCL
          ? this.props.coreConfigData.Excel.userProvidedValue
          : "javascript:void(0)";
      let configPPTHrefLinks =
        this.props.coreConfigData.PPT.userSelected == settingsConsts.USEEXT_PPT
          ? this.props.coreConfigData.PPT.userProvidedValue
          : "javascript:void(0)";
      if (configExclHrefLinks.indexOf("ORDERID") >= 0) {
        configExclHrefLinks = configExclHrefLinks.replace(
          "ORDERID",
          this.proposalID
        );
      }
      if (configPPTHrefLinks.indexOf("ORDERID") >= 0) {
        configPPTHrefLinks = configPPTHrefLinks.replace(
          "ORDERID",
          this.proposalID
        );
      }

      let exclLinkTarget =
        this.props.coreConfigData.Excel.userSelected ==
        settingsConsts.USEEXT_EXCL
          ? "_blank"
          : "";
      let pptLinkTarget =
        this.props.coreConfigData.PPT.userSelected == settingsConsts.USEEXT_PPT
          ? "_blank"
          : "";
      const isExtExcel =
        this.props.coreConfigData.Excel.userSelected ==
        settingsConsts.USEEXT_EXCL
          ? true
          : false;
      const isExtPPT =
        this.props.coreConfigData.PPT.userSelected == settingsConsts.USEEXT_PPT
          ? true
          : false;

      return (
        <div className="row-flex col-xl-3 col-md-6 col-sm-8 col-12">
          <div className="form-group leftPos">
            <label htmlFor="formDescription">FORMAT</label>
            <div className=" w-100 btn-group">
              {this.props.reportResponse.enableExcle && isExtExcel ? (
                <a
                  className={
                    conf.excelButton.clsName + " btn btnPrimary mr-lg-2"
                  }
                  href={configExclHrefLinks}
                  target={exclLinkTarget}
                  role="button"
                  onClick={this.downloadExcel}
                >
                  Excel
                </a>
              ) : (
                <a
                  className={
                    conf.excelButton.clsName + " btn btnPrimary mr-lg-2"
                  }
                  role="button"
                  href="javascript:void(0);"
                  onClick={this.initTabSwitch.bind(
                    this,
                    this.proposalID,
                    this.proposalID
                  )}
                >
                  Excel
                </a>
              )}
              {this.props.reportResponse.enablePPT && isExtPPT ? (
                <a
                  className={
                    conf.powerPoint.clsName + " btn btnPrimary mr-lg-2"
                  }
                  href={configPPTHrefLinks}
                  target={pptLinkTarget}
                  role="button"
                  onClick={this.downloadPPT}
                >
                  Powerpoint
                </a>
              ) : (
                <a
                  className={
                    conf.powerPoint.clsName + " btn btnPrimary mr-lg-2"
                  }
                  role="button"
                  href="javascript:void(0);"
                  onClick={this.initPowerPointTabSwitch.bind(
                    this,
                    this.proposalID
                  )}
                >
                  PowerPoint
                </a>
              )}
              <a
                className={conf.linkButton.clsName + " btn btnPrimary mr-lg-2"}
                role="button"
                href={linkPath}
                target="_blank"
                onClick={this.handleDomoClicks.bind(this, userSelOption.fileId)}
              >
                Domo
              </a>
            </div>
          </div>
          <a
            className=""
            href="javascript:void(0)"
            role="button"
            data-toggle="modal"
            data-target="#messageBoxGeneric"
            ref={(node) => this.focusText(node)}
          />
        </div>
      );
    }
    return null;
  }

  isExpandableRow(row: any) {
    return true;
  }

  expandComponent(row: any) {
    return (
      <div className="listA">
        <div className="row-flex">
          <div className="col-12 col-sm-12 col-md-12 pl-0 pr-0 spaceBottom10">
            <strong>Order Name</strong>
            <span> {row.fileName} </span>
          </div>
          <div className="col-md-4 pl-0 pr-0 ">
            <strong>Advertiser: </strong>
            <span> {row.advertiserName}</span>
          </div>
          <div className="col-md-4 pl-0 pr-0">
            <strong>Booked Impressions: </strong>
            <span>
              {this.formatNumber(
                row.trackableBookedImpressions,
                null,
                "decimal",
                null
              )}
            </span>
          </div>

          <div className="col-md-4 pl-0 pr-0">
            <strong>Actual Impressions: </strong>
            <span>
              {this.formatNumber(
                row.trackableActualImpressions,
                null,
                "decimal",
                null
              )}
            </span>
          </div>
          <div className="col-md-4 pl-0 pr-0">
            <strong />
            <span />
          </div>
          <div className="col-md-4 pl-0 pr-0">
            <strong>Coordinator: </strong>
            <span>{row.coordinator}</span>
          </div>
          <div className="col-md-4 pl-0 pr-0">
            <strong>Account Manager: </strong>
            <span>{row.accountManager}</span>
          </div>
        </div>
      </div>
    );
  }

  public caret() {}

  public onControlsChange(userInput: any, e: any) {
    switch (userInput) {
      case "REPORTID":
        {
          if (e.target.value && e.target.value.length > 0) {
            const re = /^[0-9\b]+$/;
            if (e.target.value && re.test(e.target.value)) {
              this.proposalID = e.target.value;
            } else {
              e.target.value = this.proposalID;
            }
            this.proposalID = e.target.value;

            this.errorStateCheck.proposalIDError.show = false;
            if (
              !this.props.reportResponse.isReportExist ||
              !this.props.reportResponse.proposalExist
            ) {
              this.props.handleDisableReportControls();
              this.reportRangeModel.resetReportingRangeModel();
            }
          }
          this.setState({ Config: this.state });
        }
        break;
      case "RECENTREPORT":
        {
          this.state = { reportType: 0 };
          this.setState({ reportType: 0 });
        }
        break;
      case "PACINGREPORT":
        {
          this.state = { reportType: 1 };
          this.setState({ reportType: 1 });
        }
        break;
      default:
        break;
    }
  }

  public onEnterPress(e: any) {
    let isEnterPressed = e.keyCode === KeyCode.ENTER ? true : false;
    if (isEnterPressed) {
      this.onControlsChange("REPORTID", e);
      e.preventDefault();
      this.handleSubmit(e);
    }
    return false;
  }

  public onKeyPress(e: any) {
    const re = /^[0-9\b]+$/;
    if (e.target.value && re.test(e.target.value)) {
      this.proposalID = e.target.value;
    } else {
      e.target.value = this.proposalID;
    }
  }

  public resetErrorMessageBlock(e: any) {
    this.errorStateCheck = clearReportingErrorState();
    let isAllAttribExist = true;

    if (isEmptyOrSpaces(e)) {
      this.errorStateCheck.proposalIDError.show = true;
      isAllAttribExist = false;
    }
    return isAllAttribExist;
  }

  public focusText(ref: any, grpButton?: any, itemName?: any) {
    this.refArr.push(ref);
  }

  public populateReportTable() {
    this.props.handleClicks({
      url: this.config.getReportListUrl(),
      type: UserOps.LISTALL_REPORT,
      reportConfigUrl: this.config.getReportingStatusConfigUrl(),
    });
  }

  public populatePacingReportTable() {
    this.props.handleClicks({
      url: this.config.getPacingReportListUrl(),
      type: UserOps.LISTALL_PACINGREPORT,
      reportConfigUrl: this.config.getReportingStatusConfigUrl(),
    });
  }

  public downloadExcel(e: any) {
    if (
      this.props.coreConfigData.Excel.userSelected == settingsConsts.USEEXT_EXCL
    ) {
      const payload = { fileId: this.proposalID, reportFormat: "xlsx" };
      this.props.handleDomoClicks({
        url: this.config.getDomoStatsUrl(),
        type: ActionConstants.DOMOSTATS,
        payload: payload,
      });

      return;
    }
  }
  customTitle(cell: any, row: any, rowIndex: any, colIndex: any) {
    return `${cell}`;
  }
  public downloadPPT(e: any) {
    if (
      this.props.coreConfigData.PPT.userSelected == settingsConsts.USEEXT_PPT
    ) {
      const payload = { fileId: this.proposalID, reportFormat: "pptx" };
      this.props.handleDomoClicks({
        url: this.config.getDomoStatsUrl(),
        type: ActionConstants.DOMOSTATS,
        payload: payload,
      });

      return;
    }
  }

  public download(
    type: any,
    proposalId: any,
    reportID: any,
    fileId: any,
    e: any
  ) {
    if (type == "xlsx" || type == "pptx") {
      const payload = { fileId: fileId, reportFormat: type };
      this.props.handleDomoClicks({
        url: this.config.getDomoStatsUrl(),
        type: ActionConstants.DOMOSTATS,
        payload: payload,
      });
      return;
    }
  }

  public submitWithReportingPeriod() {
    if (this.handleBtnState()) {
      let userSelected = this.reportRangeModel.getUserSelectedRange();
      this.props.handleClicks({
        url: this.config.getReportSearchUrl(),
        proposalID: this.proposalID,
        reportingPeriod: userSelected.dateValue,
        fileId: userSelected.fileId,
      });
    }
  }

  public handleBtnState() {
    const btnState = this.resetErrorMessageBlock(this.proposalID);
    this.setState({ Config: this.state });
    return btnState;
  }

  public handleSubmit(e: any) {
    this.props.handleDisableReportControls();
    this.reportRangeModel.resetReportingRangeModel();
    if (this.handleBtnState()) {
      this.props.handleClicks({
        url: this.config.getUserDomoQueryUrl(),
        proposalID: this.proposalID,
        reportingPeriod: "",
      });
    }
    if (e) {
      e.preventDefault();
    }
  }

  public initTabSwitch(orderId: any, fileId: any, e: any) {
    localStorage.setItem("orderId", orderId);

    this.props.history.push(`/Reporting/Digital/Excel/${orderId}`);
  }

  public initPowerPointTabSwitch(orderId: any, fileId: any, e: any) {
    localStorage.setItem("orderId", orderId);
    this.props.history.push(`/Reporting/Digital/PowerPoint/${orderId}`);
  }

  public handleDomoClicks(link: any, e: any) {
    const payload = { fileId: link, reportFormat: "domo" };
    this.props.handleDomoClicks({
      url: this.config.getDomoStatsUrl(),
      type: ActionConstants.DOMOSTATS,
      payload: payload,
    });
  }

  formatNumber(cell: any, row: any, formatExtraData: any, index: any) {
    let val = cell ? cell.toString() : "";
    const sortedColName = getRecentReportSortedColName();
    let clsName =
      formatExtraData === sortedColName ? " boldText wrapWords" : "wrapWords";
    if (formatExtraData === "pacing") {
      formatExtraData = "percent";
      val = val / 100;
    }
    if (formatExtraData === "CTR") {
      formatExtraData = "percent";
      val = val / 100;
      if (isNaN(val)) {
        val = 0;
      }
    }
    if (
      formatExtraData == "totalImpressions" ||
      formatExtraData == "bookedImpression" ||
      formatExtraData == "totalClicks"
    ) {
      formatExtraData = "decimal";
    }
    return (
      <span className={clsName}>
        <FormattedNumber
          value={val}
          style={formatExtraData}
          minimumFractionDigits={0}
          maximumFractionDigits={2}
        />
      </span>
    );
  }

  getActionControls(cell: any, row: any, formatExtraData: any, index: any) {
    let conf = this.props.UserRole;

    let clsDomoLink =
      formatExtraData == "recentReport"
        ? " pacingDomoLnk  pacingDomoLnkSM "
        : " pacingDomoLnk ";

    let linkPath =
      "https://rogers.domo.com/page/1405209425?pfilters=%5B%7B%22column%22:%22Order_ID%22,%22operand%22:%22EQUALS%22,%22values%22:%5B" +
      row.proposalId +
      "%5D%7D%5D";

    let configExclHrefLinks = "javascript:void(0)";
    let exclLinkTarget = "";
    configExclHrefLinks =
      this.props.coreConfigData.Excel.userSelected == settingsConsts.USEEXT_EXCL
        ? this.props.coreConfigData.Excel.userProvidedValue
        : "javascript:void(0)";
    exclLinkTarget =
      this.props.coreConfigData.Excel.userSelected == settingsConsts.USEEXT_EXCL
        ? "_blank"
        : "";

    let configPPTHrefLinks = "javascript:void(0)";
    let pptLinkTarget = "";
    configPPTHrefLinks =
      this.props.coreConfigData.PPT.userSelected == settingsConsts.USEEXT_PPT
        ? this.props.coreConfigData.PPT.userProvidedValue
        : "javascript:void(0)";
    pptLinkTarget =
      this.props.coreConfigData.PPT.userSelected == settingsConsts.USEEXT_PPT
        ? "_blank"
        : "";
    if (configExclHrefLinks.indexOf("ORDERID") >= 0) {
      configExclHrefLinks = configExclHrefLinks.replace(
        "ORDERID",
        row.proposalId
      );
    }
    if (configPPTHrefLinks.indexOf("ORDERID") >= 0) {
      configPPTHrefLinks = configPPTHrefLinks.replace(
        "ORDERID",
        row.proposalId
      );
    }

    const isExtExcel =
      this.props.coreConfigData.Excel.userSelected == settingsConsts.USEEXT_EXCL
        ? true
        : false;
    const isExtPPT =
      this.props.coreConfigData.PPT.userSelected == settingsConsts.USEEXT_PPT
        ? true
        : false;

    return (
      <span>
        {row.isExcelAvailable && isExtExcel ? (
          <a
            className={conf.excelButton.clsName + " btn btnSmall-secondary"}
            href={configExclHrefLinks}
            target={exclLinkTarget}
            role="button"
            onClick={this.download.bind(
              this,
              "xlsx",
              row.proposalId,
              row.proposalId,
              row.proposalId
            )}
          >
            Excel
          </a>
        ) : (
          <a
            className={conf.excelButton.clsName + " btn btnSmall-secondary "}
            role="button"
            href="javascript:void(0)"
            onClick={this.initTabSwitch.bind(
              this,
              row.proposalId,
              row.proposalId
            )}
          >
            Excel
          </a>
        )}

        {row.isPPTAvailable && isExtPPT ? (
          <a
            className={
              conf.powerPoint.clsName + clsDomoLink + " btn btnSmall-primary "
            }
            href={configPPTHrefLinks}
            target={pptLinkTarget}
            role="button"
            onClick={this.download.bind(
              this,
              "pptx",
              row.proposalId,
              row.proposalId,
              row.proposalId
            )}
          >
            Powerpoint
          </a>
        ) : (
          <a
            className={
              conf.powerPoint.clsName + clsDomoLink + " btn btnSmall-primary "
            }
            role="button"
            href="javascript:void(0)"
            onClick={this.initPowerPointTabSwitch.bind(
              this,
              row.proposalId,
              row.proposalId
            )}
          >
            PowerPoint
          </a>
        )}

        <a
          className={
            conf.linkButton.clsName + clsDomoLink + " btn btnSmall-primary"
          }
          role="button"
          href={linkPath}
          onClick={this.handleDomoClicks.bind(this, row.proposalId)}
          target="_blank"
        >
          Domo
        </a>
      </span>
    );
  }
  

  getAllColView(cell: any, row: any, formatExtraData: any, index: any) {
    let conf = this.props.UserRole;
    let clsDomoLink =
      formatExtraData == "recentReport" ? " pacingDomoLnk " : " pacingDomoLnk";

    let linkPath =
      "https://rogers.domo.com/page/1405209425?pfilters=%5B%7B%22column%22:%22Order_ID%22,%22operand%22:%22EQUALS%22,%22values%22:%5B" +
      row.proposalId +
      "%5D%7D%5D";
    let configExclHrefLinks =
      this.props.coreConfigData.Excel.userSelected == settingsConsts.USEEXT_EXCL
        ? this.props.coreConfigData.Excel.userProvidedValue
        : "javascript:void(0)";
    let configPPTHrefLinks =
      this.props.coreConfigData.PPT.userSelected == settingsConsts.USEEXT_PPT
        ? this.props.coreConfigData.PPT.userProvidedValue
        : "javascript:void(0)";
    if (configExclHrefLinks.indexOf("ORDERID") >= 0) {
      configExclHrefLinks = configExclHrefLinks.replace(
        "ORDERID",
        row.proposalId
      );
    }
    if (configPPTHrefLinks.indexOf("ORDERID") >= 0) {
      configPPTHrefLinks = configPPTHrefLinks.replace(
        "ORDERID",
        row.proposalId
      );
    }

    let exclLinkTarget =
      this.props.coreConfigData.Excel.userSelected == settingsConsts.USEEXT_EXCL
        ? "_blank"
        : "";
    let pptLinkTarget =
      this.props.coreConfigData.PPT.userSelected == settingsConsts.USEEXT_PPT
        ? "_blank"
        : "";
    const isExtExcel =
      this.props.coreConfigData.Excel.userSelected == settingsConsts.USEEXT_EXCL
        ? true
        : false;
    const isExtPPT =
      this.props.coreConfigData.PPT.userSelected == settingsConsts.USEEXT_PPT
        ? true
        : false;

    return (
      <div className="listA">
        <div className="row-flex">
          <div className="col-md-4 pl-0 pr-0">
            <span>IO </span>
            <span> {row.proposalId} </span>
          </div>
          <div className="col-md-8 pl-0 pr-0">
            <span>Name: </span>
            <span> {row.fileName}</span>
          </div>
          <div className="col-md-4 pl-0 pr-0">
            <span>Booked Impressions: </span>
            <span> {row.bookedImpression}</span>
          </div>
          <div className="col-md-4 pl-0 pr-0">
            <span>Delivered Impressions: </span>
            <span> {row.totalImpressions}</span>
          </div>
          <div className="col-md-4 pl-0 pr-0">
            <span>% Delivered: </span>
            <span> {row.pacing}</span>
          </div>
          <div className="col-md-4 pl-0 pr-0">
            <span>clicks: </span>
            <span> {row.totalClicks}</span>
          </div>
          <div className="col-md-4 pl-0 pr-0">
            <span>CTR: </span>
            <span> {row.CTR}</span>
          </div>
          <div className="col-md-12 mt-4 pl-0 pr-0 smallBtnPanel">
            {row.isExcelAvailable && isExtExcel ? (
              <a
                className=" btn btnSmall-secondary "
                href={configExclHrefLinks}
                target={exclLinkTarget}
                role="button"
                onClick={this.download.bind(
                  this,
                  "xlsx",
                  row.proposalId,
                  row.proposalId
                )}
              >
                Excel
              </a>
            ) : (
              <a
                className={
                  conf.excelButton.clsName + " btn btnSmall-secondary "
                }
                role="button"
                href="javascript:void(0);"
                onClick={this.initTabSwitch.bind(
                  this,
                  row.proposalId,
                  row.proposalId
                )}
              >
                Excel
              </a>
            )}

            {row.isPPTAvailable && isExtPPT ? (
              <a
                className={conf.powerPoint.clsName + " btn btnSmall-primary"}
                href={configPPTHrefLinks}
                target={pptLinkTarget}
                role="button"
                onClick={this.download.bind(
                  this,
                  "pptx",
                  row.proposalId,
                  row.proposalId
                )}
              >
                Powerpoint
              </a>
            ) : (
              <a
                className={
                  conf.powerPoint.clsName +
                  clsDomoLink +
                  " btn btnSmall-primary "
                }
                role="button"
                href="javascript:void(0);"
                onClick={this.initPowerPointTabSwitch.bind(
                  this,
                  row.proposalId
                )}
              >
                PowerPoint
              </a>
            )}
            <a
              className={
                conf.linkButton.clsName + clsDomoLink + " btn btnSmall-primary "
              }
              role="button"
              href={linkPath}
              onClick={this.handleDomoClicks.bind(this, row.proposalId)}
              target="_blank"
            >
              Domo
            </a>
          </div>
        </div>
      </div>
    );
  }

  public renderSearchScreen() {
    if (showSearchPanel(this.props.UserAssignedRole.roles))
      return (
        <div className="col-xl-3 col-md-6 col-sm-8 col-12">
          <div className="form-group leftPos proposalSearch">
            <label htmlFor="formName">PROPOSAL ID</label>
            <div className="row-flex">
              <input
                type="text"
                className="form-control col-9"
                id="formName"
                placeholder="Type proposal id here"
                onChange={this.onControlsChange.bind(this, "REPORTID")}
                onKeyDown={this.onEnterPress}
                onKeyPress={this.onKeyPress}
              />
              <a
                className="btn btnPrimary float-xl-right float-md-right col-3"
                href="javascript:void(0);"
                role="button"
                id="findReport"
                onClick={this.handleSubmit}
              >
                Find
              </a>
            </div>
            <CustomErrorAlertBanner
              errorMessageStruct={this.errorStateCheck.proposalIDError}
            />
            <CustomErrorAlertBanner
              errorMessageStruct={
                this.errorStateCheck.proposalID_NotExist_Error
              }
            />
          </div>
        </div>
      );
    else return null;
  }

  public render() {
    this.errorStateCheck.proposalID_NotExist_Error.show =
      this.props.reportResponse.isReportExist;
    if (this.props.UIDialogstats.isVisible) {
      let comp = this.refArr[0];
      if (comp) {
        comp.click();
      }
    }
    var settings = {
      autoplay: false,
      dots: false,
      dotsClass: "slick-listing slick-dots",
      infinite: false,
      slidesToScroll: 1,
      slidesToShow: 1,
      speed: 500,
    };
    this.reportModel.buildModel(this.props.allReportsList);
    this.pacingModel.buildModel(this.props.allPacingReportsList);
    this.reportRangeModel.buildModel(
      this.props.reportResponse.reportingPeriods
    );
    const tableInput = getRecentReportTable(
      this.reportModel.getReportingList(),
      this.formatNumber,
      this.getActionControls,
      ReportingFormComponent.MAX_ROWSIZE,
      this.customTitle
    );
    const tableInputSmall = getRecentReportDeviceTable(
      this.reportModel.getReportingList(),
      this.getAllColView,
      this.getActionControls
    );
    const tableInputPacingReports = getPacingReportTable(
      this.pacingModel.getReportingList(),
      this.formatNumber,
      this.getActionControls,
      ReportingFormComponent.MAX_ROWSIZE,
      this.isExpandableRow,
      this.expandComponent
    );
    const tableInputSmallPacingReports = getPacingReportDeviceTable(
      this.pacingModel.getReportingList(),
      this.getActionControls,
      this.isExpandableRow,
      this.expandComponent
    );
    const spinnerState = { UIConfig: { isSpinnerActive: true } };
    const recentReportVisibility =
      this.props.UserAssignedRole.roles.indexOf(UserOps.REPORTINGRECENTREPORT) <
        0 && this.reportModel.isReportingListEmpty()
        ? { cls: "d-none", show: false }
        : { cls: " ", show: true };
    const pacingReportVisibility =
      this.props.UserAssignedRole.roles.indexOf(UserOps.REPORTINGPACING) < 0 &&
      this.pacingModel.isReportingListEmpty()
        ? { cls: "d-none", show: false }
        : { cls: " ", show: true };
    return (
      <main role="main" className="container-fluid">
        <div className="row fixed-header-top ml-0 mr-0">
          <div className="col-12">
            <div className="float-left w-100 spacerB36 borderBottomGray">
              <div className="col-xl-7 col-md-12 col-sm-12 col-12 pl-0 pr-0 sm_label_center">
                <h3 className="float-left">Digital Reporting</h3>
              </div>
            </div>
          </div>
        </div>
        <AlertBannerComponent
          bannerData={this.props.currentReportingBannerStatus}
        />
        <div className="row-flex spaceTop">
          <div className="w-100">
            <form>
              {this.renderSearchScreen()}
              {this.renderFormatPanel()}
              <div className="form-group leftPos proposalSearch">
                <div
                  className="btn-group btn-group-toggle row-flex col-xl-4 col-md-6 col-sm-12"
                  data-toggle="buttons"
                >
                  <label
                    className={
                      recentReportVisibility.cls +
                      " btn btn-light col-xl-4 col-md-4 col-sm-3 active pl-0 sm_label_center"
                    }
                    onClick={this.onControlsChange.bind(this, "RECENTREPORT")}
                  >
                    <input type="radio" name="fomat" id="excel" />
                    Recent Reports
                  </label>
                  <label
                    className={
                      pacingReportVisibility.cls +
                      " btn btn-light col-xl-4 col-md-4 col-sm-3  pr-0 pl-0"
                    }
                    onClick={this.onControlsChange.bind(this, "PACINGREPORT")}
                  >
                    <input type="radio" name="format" id="powerpoint" />
                    Pacing Reports
                  </label>
                </div>

                <Slider
                  {...settings}
                  ref={(elem) =>
                    elem != null
                      ? GotoSlider(elem, this.state.reportType)
                      : null
                  }
                >
                  {recentReportVisibility.show && (
                    <li>
                      <ReportingTablePageComponent
                        tableDataInput={tableInput}
                        tableDataInputSmall={tableInputSmall}
                      />
                    </li>
                  )}
                  {pacingReportVisibility.show && (
                    <li>
                      <ReportingTablePageComponent
                        tableDataInput={tableInputPacingReports}
                        tableDataInputSmall={tableInputSmallPacingReports}
                      />
                    </li>
                  )}
                </Slider>
              </div>
            </form>
          </div>
        </div>
        <MessageBox />
        <RedUISpinner UIConfStats={spinnerState} />
      </main>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    reportResponse:
      state.ReportingControlState.hasOwnProperty("data") &&
      state.ReportingControlState.data
        ? state.ReportingControlState.data
        : {
            enableExcle: false,
            enablePPT: false,
            isReportExist: false,
            reportingPeriods: List([]),
          },
    UIDialogstats:
      state.configState.hasOwnProperty("Dialog") &&
      state.configState.Dialog.hasOwnProperty("MessageBox")
        ? state.configState.Dialog.MessageBox
        : {
            MessageBox: {
              isVisible: false,
              UserMessage: "",
              saveFailed: false,
            },
          },
    allReportsList:
      state.ReportingListState.hasOwnProperty("data") &&
      state.ReportingListState.data.list
        ? state.ReportingListState.data.list
        : List([]),
    currentReportingBannerStatus:
      state.ReportingConfigState.hasOwnProperty("data") &&
      state.ReportingConfigState.data.hasOwnProperty("currReportingStatus")
        ? state.ReportingConfigState.data.currReportingStatus
        : {
            id: 0,
            pageLable: "",
            message: "",
            selected: false,
            startTs: "",
            endTs: "",
            creationTs: "",
            updateTs: "",
          },
    allPacingReportsList:
      state.PacingReportingListState.hasOwnProperty("data") &&
      state.PacingReportingListState.data.list
        ? state.PacingReportingListState.data.list
        : List([]),
    UserRole:
      state.RoleBasedConfiguration.hasOwnProperty("userConfig") &&
      state.RoleBasedConfiguration.userConfig
        ? state.RoleBasedConfiguration.userConfig
        : getRoleConfigs(),
    coreConfigData: state.AdminCoreConfigListState.hasOwnProperty("data")
      ? state.AdminCoreConfigListState.data.reportingData
      : {
          Excel: {
            userSelected: settingsConsts.DEFAULT,
            userProvidedValue: "",
            reportRange: true,
          },
          PPT: {
            userSelected: settingsConsts.DEFAULT,
            userProvidedValue: "",
            reportRange: true,
          },
        },
    UserAssignedRole:
      state.AdminUserControlState.hasOwnProperty("UserProfile") &&
      state.AdminUserControlState.UserProfile
        ? state.AdminUserControlState.UserProfile
        : {
            email: "",
            company: null,
            userId: null,
            firstName: "",
            lastName: "",
            roles: ["User"],
          },
  };
}

export default connect(mapStateToProps, (dispatch) => {
  return {
    handleClicks(selectedItem: any) {
      const dummyUserObj = { type: UserOps.PULL_REPORT, data: selectedItem };
      dispatch(searchReportAction(dummyUserObj));
    },
    handleDownloadClicks(payload: any) {
      dispatch(downloadReportAction(payload));
    },
    handleDisableReportControls() {
      const data = {
        data: {
          enableExcle: false,
          enablePPT: false,
          proposalExist: false,
          isReportExist: false,
          reportingPeriods: List([]),
        },
      };
      dispatch(reportResponseAction(data));
    },
    handleDomoClicks(payload: any) {
      const dummyUserObj = { type: ActionConstants.DOMOSTATS, data: payload };
      dispatch(senDomoAccessLog(dummyUserObj));
    },
  };
})(ReportingFormComponent);

interface IReportingFormComponent extends React.FC<any> {
  errorMessage: any;
  handleClicks: any;
  reportResponse: any;
  currentReportingBannerStatus: any;
  handleDownloadClicks: any;
  handleReportClick: any;
  allReportsList: any;
  allPacingReportsList: any;
  handleDisableReportControls: any;
  UIDialogstats: any;
  handleDomoClicks?: any;
  UserRole: any;
  UserAssignedRole: any;
  senDomoAccessLog?: any;
  coreConfigData: any;
  history: any;
}
