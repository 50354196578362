/* eslint-disable */
import * as React from "react";
import { connect } from "react-redux";
import "../../CSS/perfect-scrollbar.css";
import LinOptForcasting from "./LinOptForcasting";

class LinOptForcastingLanding extends React.Component<ILinOptForcasting, {}> {
  public render() {
    
    return (this.props.traitsList) ? (
      <LinOptForcasting />
    ) : (
      <label htmlFor="formName">Loading traits...</label>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    traitsList: state.AdminUserTraitListControlState.hasOwnProperty(
      "TraitsList"
    )
      ? state.AdminUserTraitListControlState.TraitsList
      : null,
  };
}

export default connect(mapStateToProps, (dispatch) => {
  return {};
})(LinOptForcastingLanding);

interface ILinOptForcasting extends React.FC<any> {
  history: any;
  traitsList: any;
}
