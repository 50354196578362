import PageCategory from "./PageCategory";
import PageSection from "./PageSection";

export class SlideInfo {
    PageType: PageCategory
    Section: PageSection
    constructor(pageType: PageCategory, section: PageSection) {
        this.PageType = pageType
        this.Section = section
    }
}