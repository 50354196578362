export enum settingsConsts {

    DEFAULT="DEFAULT",
    USEEXTERNAL="USEEXTERNAL",
    USEDYNAMOC="USEEXTERNAL",
    USERVALS="USERVALS",

    EXCL101="EXCL101",
    USEDEF_EXCL="USEDEF_EXCL",
    USEEXT_EXCL="USEEXT_EXCL",
    USEDYN_EXCL="USEDYN_EXCL",

    PPT101="PPT101",
    USEDEF_PPT="USEDEF_PPT",
    USEEXT_PPT="USEEXT_PPT",
    USEDYN_PPT="USEDYN_PPT",
     PPT_EXPORT_101 = "PPT_EXPORT_101",
};
export default settingsConsts;