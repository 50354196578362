import React, { ChangeEvent } from 'react'
import PageSection from '../Model/PageSection'
// import Slides from '../Model/Slides'
// import './DeckSectionPicker.css'

// interface ISlidesSectionPicker extends React.FC<any> {
//     deck: Slides,
//     onUpdate: (oldSection: PageSection, newSection: PageSection) => void
// }
const SlidesSectionPicker: React.FC<any> = (props) => {


    const sectionMap: Record<string, PageSection> = {
        'Display/Video': PageSection.Display,
        'Video Insight':PageSection.VideoSuite,
        'R.E.D. X': PageSection.RedX,
        'Contobox': PageSection.Conto,
        'Digital Audio': PageSection.DigitalAudio,
        'Facebook / Instagram': PageSection.Facebook,
        'Twitter': PageSection.Twitter,
        'eBlast': PageSection.Eblast,
        'VOD': PageSection.Vod,
        'CTV': PageSection.Ctv,
        'Newsletter': PageSection.NewsLetter
       
    }

    function onSelectionChange(e: ChangeEvent<HTMLSelectElement>) {
        const oldSection = props.deck.section
        const newValue = e.currentTarget.options[e.currentTarget.selectedIndex].text
        props.deck.section = sectionMap[newValue]
        if (oldSection !== props.deck.section) {
            props.onUpdate(oldSection, props.deck.section)
        }
    }

    return (
        <select className='deck-section-picker' onChange={onSelectionChange} defaultValue={props.deck.section}>
            {Object.keys(sectionMap).map((item, i) =>
                <option key={i} value={sectionMap[item]}>{item}</option>
            )}
        </select>

    )

}
export default SlidesSectionPicker