import EmbeddedImage from './EmbeddedImage'

type SlideImage = EmbeddedImage & { 
    id: string,
    include: boolean,
    slideHeading: string,
    base64Thumbnail: string
}

export function convertEmbeddedToDeckImage(src: EmbeddedImage, thumbWidth: number): Promise<SlideImage> {
    return new Promise((resolve, reject) => {
        const image = document.createElement('img')

        image.onload = function(){
            const canvas = document.createElement('canvas')
            const ctx = canvas.getContext("2d")
            if (ctx) {
                ctx.imageSmoothingQuality = 'high'
                const width = thumbWidth
                const height = Math.floor(thumbWidth/src.width * src.height)
                canvas.width = width
                canvas.height = height
                ctx.drawImage(image, 0, 0, src.width, src.height, 0, 0, width, height)
                return resolve({
                    format: src.format,
                    width: src.width,
                    height: src.height,
                    data: src.data,
                    size: src.size,
                    slideHeading: '',
                    include: true,
                    id: '',
                    base64Thumbnail: canvas.toDataURL()
                })        
                
            }
            return reject()
        }
        image.src = "data:image/png;base64," + btoa(Array.from(new Uint8Array(src.data)).map(b => String.fromCharCode(b)).join(''))
    })
}

export default SlideImage