/* eslint-disable */
export function validateCredentials() {
    return true;
}

export function getAudienceCreationDate(date:any) {
   return date;
}
export function convertToUTCDateForYYYYMMDD(date: any) {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate()+1)
    const month = newDate.getMonth() + 1;
    const mnth = month.toString().length === 1 ? "0" + month : month;
    const day = newDate.getDate().toString().length === 1 ? "0" + newDate.getDate() : newDate.getDate();
    return newDate.getFullYear()+  "-" + mnth +"-" + day ;
}

export function convertToDate(date:any) {
    if (date && date !== "") {
        const crdate = new Date(date.replace(/-/g, "\/"));
        const month = crdate.getMonth()+1;
        const mnth = month <= 9 ?"0" + month : month;
        const day = crdate.getDate() <= 9 ? "0" + crdate.getDate() : crdate.getDate();
        const respDate =  crdate.getFullYear() + "-" + mnth + "-" + day;
        return respDate;
    }
    return "";
}

export function getPageNumber(direction:any,pageNum:any,pageObject:any,pageProps:any) {
     
    if(direction === "prev") {
         pageObject.currPage -= 1;
    }
    else if (direction === "next") {
        pageObject.currPage += 1;
    }
    else if (direction === "first") {
        pageObject.currPage = 1;
    }
    else if(direction === "last") {
        pageObject.currPage = pageProps.totalPages;
    }
    else if (direction === "pageSwitch") {
        pageObject.currPage = pageNum;
    }
    if(pageObject.currPage >= pageProps.totalPages) {
       pageObject.currPage = pageProps.totalPages;
    }
    if(pageObject.currPage <= 1) {
       pageObject.currPage = 1;
    }

    return pageObject;
}

export function getPaginationMatrices(pagination:any,direction:any,displayMaxPageList?:any) {
       if(direction === "prev") {
            return pagePrev(pagination,displayMaxPageList);
       }
       else if (direction === "next") {
            return pageNext(pagination,displayMaxPageList);
       }
       else if (direction === "first") {
            return pagFirst(pagination,displayMaxPageList);
       }
       else if(direction === "last") {
            return pageLast(pagination,displayMaxPageList);
       }
       else if (direction === "pageSwitch") {
            return pagination;
       }
 
}

export function pageNext(pagination:any,displayMaxPageList:any) {
    let pageShift = displayMaxPageList-1;
    if(pagination.showMaxPage <= displayMaxPageList && pagination.currPage < displayMaxPageList) {

    }
   else {
       pagination.showMaxPage += 1;
       if(pagination.showMaxPage >= pagination.maxPageSize) {
            pagination.showMaxPage =  pagination.maxPageSize;
       }
       pagination.pageStartIndex = pagination.showMaxPage  - pageShift;

   }
   return pagination;
}

export function pagePrev(pagination:any,displayMaxPageList:any) {
    let pageShift = displayMaxPageList-1;

    if(pagination.showMaxPage <= displayMaxPageList && pagination.currPage < displayMaxPageList) {
    }
   else {
       pagination.showMaxPage -= 1;
       
       pagination.pageStartIndex = pagination.showMaxPage  - pageShift;

   }
   return pagination;
}

export function pageLast(pagination:any,displayMaxPageList:any) {
    let pageShift = displayMaxPageList;

    if(pagination.maxPageSize <= displayMaxPageList) {
        pagination.showMaxPage =  pagination.maxPageSize;
    }
   else {
       pagination.showMaxPage =  pagination.maxPageSize;
       pagination.pageStartIndex = pagination.showMaxPage  - pageShift;
     
   }
   return pagination;
}

export function pagFirst(pagination:any,displayMaxPageList:any) {
    let pageShift = displayMaxPageList;

    if(pagination.maxPageSize <= displayMaxPageList) {
        pagination.showMaxPage =  pagination.maxPageSize;
    }
   else {
       pagination.showMaxPage =  pageShift;
       pagination.pageStartIndex = 1;
   }
   return pagination;
}

export function findSortTitle(sortArr:any,fieldName:any) {
    let index = sortArr.findIndex((obj: any) => obj.name === fieldName);
    if(index<=0) {
        index = 0;
    }
    return sortArr[index].title;
}