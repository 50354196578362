import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getDomoAPIResponse,
  sendDomoQueryReesponse,
  sendDownloadPPTDomoReports,
  senDomoAccessLog,
  slickStateAction,
  updateProgressCRM,
} from "src/Actions";
import { getTomorrowsDate, getNewEndDate } from "src/Utility/CampaignUtil";
import DomoCampaignOrder from "../Model/DomoCampaignOrder";
import Powerpoint2003File from "../utils/Powerpoint2003File";
import * as SectionGuesser from "../Model/SectionGuesser";
import async from "async";
import Slides from "../Model/Slides";
import EmbeddedImage from "../utils/EmbeddedImage";
import DefaultHeading from "../utils/DefaultHeading";
import { convertEmbeddedToDeckImage } from "../utils/SlideImage";
import SlideDetails from "./SlideDetails";
import PageSection from "../Model/PageSection";
import PageCategory from "../Model/PageCategory";
import { ActionConstants, Configs } from "src/ConstConfig";
import DomoDataFrame from "./DomoDataFrame";

const StepsPowerPointReporting: React.FC<IStepsPowerPointReporting> = (
  props
) => {
  const [slideList, setSlideList] = useState([]);
  const thumbWidth = 100;
  const configs: any = new Configs();

  useEffect(() => {
    window.addEventListener("dragover", onDragStart);
    window.addEventListener("dragenter", onDragStart);
    window.addEventListener("dragleave", onDragEnded);
    window.addEventListener("drop", handleDrop);
  }, []);

  const [advSlidesOptions, setadvSlidesOptions] = useState([
    {
      id: 1,
      displayName: "Include Agenda",
      isSelected: false,
      pageIDSection: -1,
      disabled: false,
      cls: "",
      categories: [PageCategory.Agenda],
    },
    {
      id: 2,
      displayName: "Include Insight Results Slides",
      isSelected: false,
      disabled: false,
      pageIDSection: -1,
      cls: "",
      categories: [
        PageCategory.ContoResults,
        PageCategory.DigitalAudioResults,
        PageCategory.DisplayResults,
        ,
        PageCategory.VideoInsightResults,
        PageCategory.EblastResults,
        PageCategory.FacebookResults,
        PageCategory.TwitterResults,
        PageCategory.VodResults,
        PageCategory.RedXResults,
        PageCategory.CTVResults,
        PageCategory.NewsLetterResults,
      ],
    },
    {
      id: 3,
      displayName: "Include Insight Commentary Slides",
      isSelected: false,
      disabled: false,
      pageIDSection: -1,
      cls: "",
      categories: [
        PageCategory.ContoCommentary,
        PageCategory.DigitalAudioCommentary,
        PageCategory.DisplayCommentary,
        PageCategory.EblastCommentary,
        PageCategory.FacebookCommentary,
        PageCategory.TwitterCommentary,
        PageCategory.VodCommentary,
        PageCategory.RedXCommentary,
        PageCategory.CTVCommentary,
        PageCategory.VideoInsightCommentary,
        PageCategory.NewsLetterCommentary,
      ],
    },
    {
      id: 4,
      displayName: "Include Layout Examples",
      isSelected: false,
      disabled: false,
      pageIDSection: PageSection.LayoutExample,
      cls: "",
      categories: [],
    },
    {
      id: 5,
      displayName: "Include Template Display/Video Section",
      isSelected: false,
      disabled: false,
      pageIDSection: PageSection.Display,
      cls: "",
      categories: [
        PageCategory.DisplayDictionary,
        PageCategory.VideoDictionary,
      ],
    },
    {
      id: 6,
      displayName: "Include Template Video Suite Section",
      isSelected: false,
      pageIDSection: PageSection.VideoSuite,
      disabled: false,
      cls: "",
      categories: [PageCategory.VideoInsightDictionary],
    },
    {
      id: 7,
      displayName: "Include Template R.E.D. X Section",
      isSelected: false,
      disabled: false,
      pageIDSection: PageSection.RedX,
      cls: "",
      categories: [PageCategory.SimplifiDictionary],
    },
    {
      id: 8,
      displayName: "Include Template Digital Audio Section",
      isSelected: false,
      pageIDSection: PageSection.DigitalAudio,
      disabled: false,
      cls: "",
      categories: [PageCategory.DigitalAudioDictionary],
    },
    {
      id: 9,
      displayName: "Include Template Contobox Section",
      isSelected: false,
      pageIDSection: PageSection.Conto,
      disabled: false,
      cls: "",
      categories: [],
    },
    {
      id: 10,
      displayName: "Include Template Facebook Section",
      isSelected: false,
      pageIDSection: PageSection.Facebook,
      disabled: false,
      cls: "",
      categories: [PageCategory.FacebookDictionary],
    },
    {
      id: 11,
      displayName: "Include Template Twitter Section",
      isSelected: false,
      pageIDSection: PageSection.Twitter,
      disabled: false,
      cls: "",
      categories: [PageCategory.TwitterDictionary],
    },
    {
      id: 12,
      displayName: "Include Template VOD Section",
      isSelected: false,
      pageIDSection: PageSection.Vod,
      disabled: false,
      cls: "",
      categories: [PageCategory.VodDictionary],
    },
    {
      id: 13,
      displayName: "Include Template CTV OTT Section",
      isSelected: false,
      pageIDSection: PageSection.Ctv,
      disabled: false,
      cls: "",
      categories: [PageCategory.CTVDictionary],
    },
    {
      id: 14,
      displayName: "Include Template NewsLetter Section",
      isSelected: false,
      pageIDSection: PageSection.NewsLetter,
      disabled: false,
      cls: "",
      categories: [PageCategory.NewsLetterDictionary],
    },
    {
      id: 15,
      displayName: "Include Template eBlasts Section",
      isSelected: false,
      pageIDSection: PageSection.Eblast,
      disabled: false,
      cls: "",
      categories: [PageCategory.EBlastDictionary],
    },
  ]);

  const [summaryOptions, setSummaryOptions] = useState([
    {
      id: 1,
      displayName: "Include CTR",
      isSelected: true,
    },
    {
      id: 2,
      displayName: "Include Conversions",
      isSelected: false,
    },
  ]);

  function onDragStart(event: any) {
    event.preventDefault();
  }

  function onDragEnded(event: any) {
    event.preventDefault();
  }

  function handleDrop(e: any) {
    e.preventDefault();
    e.stopPropagation();

    if (
      e &&
      e.dataTransfer &&
      e.dataTransfer.files &&
      e.dataTransfer.files.length > 0
    ) {
      const files = e.dataTransfer.files;
      let slideIndex = -1;
      for (var i = 0; i < files.length; i++) {
        if (!files[i].name) return;
        new Powerpoint2003File(files[i], (obj) => {
          const deck = new Slides();
          deck.filename = obj.filename;
          deck.section = SectionGuesser.guessSection(obj.filename);
          deck.images = [];
          slideIndex = advSlidesOptions.findIndex(
            (item) => item.pageIDSection == deck.section
          );
          if (slideIndex >= 0) {
            advSlidesOptions[slideIndex].isSelected = true;
            advSlidesOptions[slideIndex].disabled = true;
            advSlidesOptions[slideIndex].cls = "disabledBox";
            setadvSlidesOptions((advSlidesOptions) => [...advSlidesOptions]);
          }
          const allImages: Array<EmbeddedImage> = [];
          for (var x = 0; x < obj.imageCount(); x++) {
            if ((obj.getImage(x) as EmbeddedImage).size > 50000) {
              allImages.push(obj.getImage(x) as EmbeddedImage);
            }
          }
          async
            .forEachOf(allImages, (image, key, callback) => {
              convertEmbeddedToDeckImage(image, thumbWidth).then(
                (deckImage) => {
                  deckImage.id = String(key);
                  deckImage.slideHeading = DefaultHeading.forSection(
                    deck.section
                  );
                  deck.images.push(deckImage);
                  callback(null);
                }
              );
            })
            .then(() => {
              if (slideList.length <= 0)
                setSlideList((currentArray) => [...currentArray, deck]);
              else setSlideList([...slideList, deck]);
            });
        });
      }
    }
  }
  function initTabSwitch(e: any) {
    props.history.push("/Reporting/Digital/");
  }

  const onSummaryOptionChange = (slideIndex: any) => (e: any) => {
    if (slideIndex >= 0) {
      summaryOptions[slideIndex].isSelected = e.target.checked;
      setSummaryOptions((summaryOptions) => [...summaryOptions]);
    }
  };

  const onAdvOptionChange = (slideIndex: any) => (e: any) => {
    if (slideIndex >= 0) {
      advSlidesOptions[slideIndex].isSelected = e.target.checked;
      setadvSlidesOptions((advSlidesOptions) => [...advSlidesOptions]);
    }
  };

  function generatePPT(powerPointPayload: any) {
    const reportDates = {
      orderStartDate: powerPointPayload.startDate,
      orderEndDate: powerPointPayload.endDate,
      reportStartDate: props.usrSelectedDates.startDate.date,
      reportEndDate: props.usrSelectedDates.endDate.date,
    };
    const domoQueryObj = {
      dates: reportDates,
      powerPointPayload: powerPointPayload,
      orderId: props.orderId,
      url: "/CAMPAIGN_REPORT_TEMPLATE_v13.pptx",
      advSlidesOptions: advSlidesOptions,
      summaryOptions: summaryOptions,
      slideList: slideList,
    };
    props.triggerProgress();
    const payload = { fileId: props.orderId, reportFormat: "pptx" };
    props.handleDomoClicks({
      url: configs.getDomoStatsUrl(),
      type: ActionConstants.DOMOSTATS,
      payload: payload,
    });
    props.getPPT(domoQueryObj);
  }

  function generateReport() {
    generatePPT(props.domoResponse);
  }

  function renderSummaryTablePanel() {
    return summaryOptions.map((pltfrm: any, index: any) => {
      return (
        <span key={"span" + pltfrm.id + pltfrm.displayName}>
          <input
            type="checkbox"
            key={pltfrm.id + pltfrm.displayName}
            id={pltfrm.displayName}
            value={pltfrm.displayName}
            checked={pltfrm.isSelected}
            tabIndex={pltfrm.id}
            onChange={onSummaryOptionChange(index)}
          />{" "}
          <label htmlFor={pltfrm.displayName}>{pltfrm.displayName}</label>
        </span>
      );
    });
  }

  function renderAdvancePanel() {
    return advSlidesOptions.map((pltfrm: any, index: any) => {
      return (
        <span key={"span" + pltfrm.id + pltfrm.displayName}>
          <input
            type="checkbox"
            key={pltfrm.id + pltfrm.displayName}
            id={pltfrm.displayName}
            disabled={pltfrm.disabled}
            value={pltfrm.displayName}
            tabIndex={pltfrm.id}
            checked={pltfrm.isSelected}
            onChange={onAdvOptionChange(index)}
          />{" "}
          <label htmlFor={pltfrm.displayName} className={pltfrm.cls}>
            {pltfrm.displayName}
          </label>
        </span>
      );
    });
  }

  const styles = {
    border: "1px solid black",
    width: 600,
    color: "black",
    padding: 20,
  };

  if (props.domoResponse.isValidOrderId)
    return (
      <div className="w-100">
        <form>
          <div className="col-xl-12 col-md-6 col-sm-8 col-12">
            <div className="form-group leftPos proposalSearch">
              <h2> Step 1</h2>
              <label htmlFor="formName">Export your dashboards:</label>
              <DomoDataFrame orderId={props.orderId} />
            </div>
          </div>
          <div className="col-xl-12 col-md-6 col-sm-8 col-12">
            <div className="form-group leftPos proposalSearch">
              <h2> Step 2</h2>

              {slideList.length > 0 ? (
                <div>
                  <p>
                    <strong>Your files:</strong>
                  </p>
                  <div className="deck-container">
                    {slideList.map((deck, i) => (
                      <SlideDetails
                        key={i}
                        deck={deck}
                        thumbWidth={thumbWidth}
                        onUpdate={null}
                      />
                    ))}
                  </div>
                </div>
              ) : (
                <div style={styles}>Drag the files on to the page</div>
              )}
            </div>
          </div>
          <div className="col-xl-12 col-md-6 col-sm-8 col-12">
            <div className="form-group leftPos proposalSearch">
              <h2> Step 3</h2>
              <div className="spaceBottom">
                This will generate a pre-populated version of the{" "}
                <a
                  href="https://rogers.domo.com/kpis/details/249887178"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  RSM Campaign Template
                </a>
                .
                <br />
                You may need to install the fonts, which are available{" "}
                <a
                  href="https://rogers.domo.com/page/1405209425/kpis/details/1338775467"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </a>
                .
              </div>
              <div className="col-12">
                <div className="form-group leftPos">
                  <label>Summary Table Options:</label>

                  <div className="platforms">{renderSummaryTablePanel()}</div>
                </div>
              </div>
              <div className="col-12">
                <div className="form-group leftPos">
                  <label>Advanced Options:</label>

                  <div className="platforms">{renderAdvancePanel()}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 buttonPanel spaceBottom float-left">
            <div className="borderBottomGray spaceBottom48" />
            <a
              className="btn btnPrimary"
              href="javascript:void(0);"
              role="button"
              onClick={generateReport}
            >
              Generate
            </a>
            <a
              className=""
              href="javascript:void(0)"
              role="button"
              data-toggle="modal"
              data-target="#messageBoxGeneric"
            />
            <a
              className="btn btnPrimary float-right"
              href="javascript:void(0)"
              role="button"
              onClick={initTabSwitch}
            >
              Cancel
            </a>
          </div>
        </form>
      </div>
    );
  else return <span />;
};

function mapStateToProps(state: any, props: any) {
  return {
    usrSelectedDates:
      state.CalendarControlState.hasOwnProperty("data") &&
      state.CalendarControlState.data
        ? state.CalendarControlState.data
        : {
            startDate: {
              date: getTomorrowsDate(),
              campaignStartDate: getTomorrowsDate(),
            },
            endDate: {
              date: getNewEndDate(getTomorrowsDate()),
              campaignEndDate: getNewEndDate(getTomorrowsDate()),
            },
          },
    domoResponse:
      state.DomoQueryResponseState.hasOwnProperty("data") &&
      state.DomoQueryResponseState.data
        ? state.DomoQueryResponseState.data
        : {
            isValidOrderId: false,
            reportLabel: "",
            startDate: "",
            endDate: "",
          },
    progressBarStatus: state.ProgressCRMState.hasOwnProperty("data")
      ? state.ProgressCRMState.data
      : { loaded: 1, total: 1, text: "" },
  };
}

export default connect(mapStateToProps, (dispatch) => {
  return {
    handleSlick(payload: any) {
      dispatch(slickStateAction(payload));
    },
    loadDomoData(payload: any) {
      dispatch(getDomoAPIResponse(payload));
    },
    triggerProgress() {
      dispatch(
        updateProgressCRM({
          data: {
            total: 10,
            loaded: 10,
            text: "",
          },
        })
      );
    },
    resetScreenControls() {
      let domoStatus = new DomoCampaignOrder().getCampaignReportStructure(
        null,
        null
      );
      dispatch(sendDomoQueryReesponse({ data: domoStatus }));
      dispatch(
        updateProgressCRM({
          data: {
            total: 1,
            loaded: 0,
            text: "",
          },
        })
      );
    },
    getPPT(payload: any) {
      dispatch(sendDownloadPPTDomoReports(payload));
    },
    handleDomoClicks(payload: any) {
      const dummyUserObj = { type: ActionConstants.DOMOSTATS, data: payload };
      dispatch(senDomoAccessLog(dummyUserObj));
    },
  };
})(StepsPowerPointReporting);

interface IStepsPowerPointReporting extends React.FC<any> {
  editDomoConfigStat?: any;
  usrSelectedDates?: any;
  errorMessage?: any;
  handleSlick?: any;
  loadDomoData?: any;
  handleSave?: any;
  domoResponse?: any;
  resetScreenControls?: any;
  getPPT?: any;
  progressBarStatus?: any;
  orderId: string;
  triggerProgress?: any;
  match: any;
  handleDomoClicks: any;
  history: any;
}
