/* eslint-disable */
import * as React from "react";
import { connect } from "react-redux";
import "../bootstrap/css/bootstrap.css";
import {
  submitSegmentRequest,
  submitWizardAction,
  submiDragAction,
  submitUIConfigAction,
  setToggleStateAction,
  submitFlyoutConfigAction,
  submitAudiSpinnerAction,
  submitAltSpinnerAction,
} from "../Actions";
import "../CSS/perfect-scrollbar.css";
import { Demographics } from "./Models/DemographicsModel";
import {
  addItemAndGetPayload,
  changeFlagGetPayload,
  dummyAPIResponseList,
  isDropZoneEmpty,
  savePayload,
  unLinkandRemove,
} from "../Utility/AddItemToList";
import "./thirdparty/perfect-scrollbar.js";

import { isDropZoneContainsList } from "./audienceBuilderUtil";
import { UserOps } from "../ConstConfig/UserOps";
import { Segments } from "./DragDropWizard/Segments";
import { SelectedTraits } from "./DragDropWizard/SelectedTraits";
import SaveDialog from "../CommonComponent/SaveDialog";
import ConfirmDialog from "../CommonComponent/ConfirmDialog";
import { AdvanceModeModel } from "./Models/AdvanceModeModel";

class AudienceBuilder extends React.Component<IAudienceBuilder, {}> {
  public dropZoneList: any;
  private demographics: Demographics;
  private interestsSegment: Demographics;
  private behaviorSegment: Demographics;
  private advanceSegmentation: AdvanceModeModel;
  private currTabId: number;
  private routeObj: any;
  private isFlagStateUpdated: any;
  private DDItem: any;
  private flagState: any;
  private refArrOfPFS: any;
  private refArr: any;

  constructor(props: any) {
    super(props);

    this.currTabId = 0;
    this.demographics = new Demographics();
    this.interestsSegment = new Demographics();
    this.behaviorSegment = new Demographics();
    this.advanceSegmentation = new AdvanceModeModel();
    this.dropZoneList =
      this.props.wizardPageStats.WizardPage.PanelFirstZoneList;
    this.updateCurrentTab = this.updateCurrentTab.bind(this);
    this.updateSelectedBar = this.updateSelectedBar.bind(this);
    this.updateSelectedToggle = this.updateSelectedToggle.bind(this);
    this.saveAudienceContents = this.saveAudienceContents.bind(this);
    this.isSaveButtonDisabled = this.isSaveButtonDisabled.bind(this);
    this.showSaveSegmentsModal = this.showSaveSegmentsModal.bind(this);
    this.showSuccessFlyOut = this.showSuccessFlyOut.bind(this);
    this.setComponentReference = this.setComponentReference.bind(this);
    this.assignedUserRoles = this.assignedUserRoles.bind(this);
    this.enableDisableSave = this.enableDisableSave.bind(this);
    this.updateAdvOperator = this.updateAdvOperator.bind(this);

    this.refArrOfPFS = [];
    this.refArr = [];
    this.routeObj = {};
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  UNSAFE_componentWillMount() {
    this.routeObj = this.props.history;
    let reGeneratePayload = false;
    if (
      this.routeObj &&
      this.routeObj.hasOwnProperty("location") &&
      this.routeObj.location.pathname === "/AudienceSizer" &&
      this.routeObj.hasOwnProperty("action") &&
      this.routeObj.action === "POP"
    ) {
      reGeneratePayload = isDropZoneContainsList(
        this.props.wizardPageStats.WizardPage.PanelFirstZoneList
          .PanelFirstZoneList
      );
    }
    if (
      this.routeObj &&
      this.routeObj.hasOwnProperty("location") &&
      this.routeObj.location.pathname === "/AudienceSizer" &&
      this.routeObj.hasOwnProperty("action") &&
      this.routeObj.action === "PUSH"
    ) {
      reGeneratePayload = isDropZoneContainsList(
        this.props.wizardPageStats.WizardPage.PanelFirstZoneList
          .PanelFirstZoneList
      );
    }
    let isEditAudience =
      this.props.wizardPageStats.WizardPage.hasOwnProperty(
        "editAudienceZone"
      ) && this.props.wizardPageStats.WizardPage.editAudienceZone
        ? true
        : false;
    if (isEditAudience || reGeneratePayload) {
      this.demographics = new Demographics();
      this.interestsSegment = new Demographics();
      this.behaviorSegment = new Demographics();
      this.advanceSegmentation = new AdvanceModeModel();
      this.isFlagStateUpdated = false;
      const contxt = this;
      this.DDItem =
        this.props.wizardPageStats.WizardPage.PanelFirstZoneList.PanelFirstZoneList;
      this.flagState = this.props.wizardPageStats.WizardPage.toggleSwith;
      this.DDItem.forEach(function (item: any) {
        const individualItem = { card: { card: item, action: "ADD" } };
        if (item.isSelected) {
          addItemAndGetPayload(
            individualItem,
            item.Tabtype,
            contxt.demographics,
            contxt.interestsSegment,
            contxt.behaviorSegment,
            contxt.advanceSegmentation
          );
          if (isEditAudience) {
            contxt.flagState =
              item.segmentType === UserOps.OR
                ? 0
                : item.segmentType === UserOps.AND
                ? 1
                : 2;

            if (item.hasOwnProperty("operator") && item.operator) {
              contxt.flagState = 2;
            }
          }
          contxt.isFlagStateUpdated = true;
        }
      });
      const payload = changeFlagGetPayload(
        0,
        this.demographics,
        this.interestsSegment,
        this.behaviorSegment,
        contxt.flagState,
        this.advanceSegmentation
      );
      this.props.submitRequest(payload);
      if (this.isFlagStateUpdated) {
        this.props.submitToggleSwitchAction({ switchState: this.flagState });
        const wizardPagePrep = {
          WizardPage: {
            currentSelectedIndex: this.currTabId,
            PanelFirstZoneList:
              this.props.wizardPageStats.WizardPage.PanelFirstZoneList,
            toggleSwith: this.flagState,
            isDropZonContainsList: true,
          },
        };
        this.props.submitPageSwitchRequest(wizardPagePrep);
      }
    }
  }

  enableDisableSave(advMode: any) {}

  isSaveButtonDisabled() {
    let isBtnDisabled = true;
    let segIdOrderIdMap =
      this.advanceSegmentation.getSegmentationIdAndOrderIdMap();
    if (
      this.demographics.getSegmentPayloadForDemographics(segIdOrderIdMap)
        .length > 0 ||
      this.interestsSegment.getSegmentPayloadForDemographics(segIdOrderIdMap)
        .length > 0 ||
      this.behaviorSegment.getSegmentPayloadForDemographics(segIdOrderIdMap)
        .length > 0 ||
      this.advanceSegmentation.getSegmentPayloadForAdvanceMode().length > 0
    ) {
      isBtnDisabled = false;
    }

    return isBtnDisabled;
  }

  updateAdvOperator(card: any) {
    this.advanceSegmentation.setSegementInAdvanceMode(card);
  }

  updateSelectedToggle(flagState: any) {
    const DDItem = this.props.wizardPageStats.WizardPage.PanelFirstZoneList;
    const wizardPagePrep = {
      WizardPage: {
        currentSelectedIndex: this.currTabId,
        PanelFirstZoneList: DDItem,
        toggleSwith: flagState,
      },
    };
    this.props.submitPageSwitchRequest(wizardPagePrep);

    const payload = changeFlagGetPayload(
      this.props.wizardPageStats.WizardPage.currentSelectedIndex,
      this.demographics,
      this.interestsSegment,
      this.behaviorSegment,
      flagState,
      this.advanceSegmentation
    );
    this.props.submitRequest(payload);
  }

  public focusText(ref: any) {
    let refComponent = ref;
    this.refArr.push(refComponent);
  }

  showSuccessFlyOut() {
    if (this.props.flyOutMenuStats.isVisible) {
      const popoverCls = this.props.flyOutMenuStats.saveFailed
        ? " popoverCustom error "
        : "popoverCustom success";
      const popoverIconCls = this.props.flyOutMenuStats.saveFailed
        ? " errorIcon float-left"
        : " successIcon float-left";
      return (
        <div className={popoverCls} id="success">
          <div className="arrow_box float-right">
            <span>
              <span className={popoverIconCls} />
              <strong>{this.props.flyOutMenuStats.messageHead}</strong>
              {this.props.flyOutMenuStats.UserMessage}
            </span>
          </div>
        </div>
      );
    }
    const contxt = this;
    setTimeout(function () {
      const messageBoxObj = {
        FlyoutDialog: {
          MessageBox: {
            isVisible: false,
            UserMessage: "  ",
            saveFailed: false,
            boxButtons: UserOps.CLOSE,
            messageHead: "",
          },
        },
      };
      contxt.props.hideFlyoutMsg(messageBoxObj);
    }, 8000);
    return <div />;
  }

  public saveAudienceContents(title: any, isDuplicate: any, params: any) {
    const payload = savePayload(
      this.props.wizardPageStats.WizardPage.currentSelectedIndex,
      this.demographics,
      this.interestsSegment,
      this.behaviorSegment,
      this.props.wizardPageStats.WizardPage.toggleSwith,
      this.advanceSegmentation
    );
    const saveAudSeg = {
      saveSegmentName: title,
      segmentation: payload,
    };
    if (isDuplicate) {
      let comp = this.refArr[0];
      if (comp) {
        comp.click();
        const rowContent = {
          overwriteAudiece: true,
          AudienceName: title,
          saveAudSeg: saveAudSeg,
        };
        this.props.handleDuplicateNameSubmitAction(true, rowContent);
        return;
      }
    }
    this.props.submitSaveAudienceRequest(saveAudSeg);
  }

  public updateCurrentTab(requestPayload: any) {
    if (requestPayload.action === UserOps.DRAGPREVIEW) {
      this.props.submitDragPosition(requestPayload);
    } else if (requestPayload.card.action === UserOps.REORDER) {
      this.props.startStopLoader();

      this.advanceSegmentation.setReorderedSegementInAdvanceMode(
        requestPayload.card.card
      );
      const flagState = this.props.wizardPageStats.WizardPage.toggleSwith;
      const payload = changeFlagGetPayload(
        this.props.wizardPageStats.WizardPage.currentSelectedIndex,
        this.demographics,
        this.interestsSegment,
        this.behaviorSegment,
        flagState,
        this.advanceSegmentation
      );
      this.props.submitRequest(payload);
    } else {
      this.props.startStopLoader();
      addItemAndGetPayload(
        requestPayload,
        this.props.wizardPageStats.WizardPage.currentSelectedIndex,
        this.demographics,
        this.interestsSegment,
        this.behaviorSegment,
        this.advanceSegmentation,
        this.props.wizardPageStats.WizardPage.toggleSwith
      );

      const DDItem = this.props.wizardPageStats.WizardPage.PanelFirstZoneList;
      if (requestPayload.card.action === "REMOVE") {
        unLinkandRemove(
          DDItem.PanelFirstZoneList,
          requestPayload.card.card,
          requestPayload.card.allNodes.prev,
          requestPayload.card.allNodes.next
        );
      }
      const flagState = this.props.wizardPageStats.WizardPage.toggleSwith;

      const payload = changeFlagGetPayload(
        this.props.wizardPageStats.WizardPage.currentSelectedIndex,
        this.demographics,
        this.interestsSegment,
        this.behaviorSegment,
        flagState,
        this.advanceSegmentation
      );
      const index = DDItem.PanelFirstZoneList.findIndex(
        (obj: any) => obj.segmentId === requestPayload.card.card.segmentId
      );
      if (index >= 0) {
        DDItem.PanelFirstZoneList[index].isSelected =
          requestPayload.card.action === "ADD" ||
          requestPayload.card.action === "RELINK"
            ? true
            : false;
      }
      const dropZoneState = DDItem.PanelFirstZoneList.filter(isDropZoneEmpty);
      const disableSaveButton = dropZoneState.length > 0 ? false : true;
      const wizardPagePrep = {
        WizardPage: {
          currentSelectedIndex: this.currTabId,
          PanelFirstZoneList: DDItem,
          toggleSwith: flagState,
          isDropZonContainsList: !disableSaveButton,
        },
      };
      this.props.submitPageSwitchRequest(wizardPagePrep);
      this.props.submitRequest(payload);
    }
  }

  public setComponentReference(ref: any, tabIndex: any) {
    this.refArrOfPFS.push({ tabIndex: tabIndex, component: ref });
  }

  public updateSelectedBar(index: any, evt: any) {
    this.currTabId = index;
    const wizardPagePrep = {
      WizardPage: {
        currentSelectedIndex: this.currTabId,
        PanelFirstZoneList:
          this.props.wizardPageStats.WizardPage.PanelFirstZoneList,
        isDropZonContainsList:
          this.props.wizardPageStats.WizardPage.isDropZonContainsList,
        toggleSwith: this.props.wizardPageStats.WizardPage.toggleSwith,
      },
    };
    this.props.submitPageSwitchRequest(wizardPagePrep);
    let traitTab = 0;

    if (traitTab >= 0 && this.refArrOfPFS.length > 0) {
      const traitTabControl = this.refArrOfPFS[traitTab].component;
      if (traitTabControl) {
        traitTabControl.scrollTop = 0;
      }
    }
    return;
  }

  showSaveSegmentsModal() {
    this.props.handleDialogSubmitAction(true);
  }

  public assignedUserRoles() {
    if (
      this.props.UserRole &&
      this.props.UserRole.hasOwnProperty("roles") &&
      this.props.UserRole.roles
    ) {
      return this.props.UserRole.roles;
    }
    return [];
  }

  public render() {
    const showAudDefMsg =
      this.props.wizardPageStats.WizardPage.PanelFirstZoneList
        .isTraitDeletedInAudienc;
    return (
      <main role="main" className="container-fluid">
        <div className="row fixed-header-top ml-0 mr-0">
          <div className="col-12">
            <div className="float-left w-100 spacerB36  borderBottomGray">
              <h3 className="float-left">Audience Builder</h3>
              <div className="col-xl-5 col-md-12 col-sm-12 col-12 pl-0 pr-0 float-right">
                <button
                  type="button"
                  className="btn btnPrimary float-right"
                  data-container="body"
                  data-placement="left"
                  data-toggle="modal"
                  data-target="#SaveAudienceModal"
                  onClick={this.showSaveSegmentsModal}
                  disabled={this.isSaveButtonDisabled()}
                >
                  Save
                </button>
                {this.showSuccessFlyOut()}
              </div>
            </div>
            {showAudDefMsg && (
              <label className="float-left">
                This audience's definition has changed and must be updated
                before it can be used
              </label>
            )}
          </div>
        </div>

        <div className="row-flex spaceTop">
          <div className="col-xl-6 col-md-12 col-sm-12 col-12 spaceBottom spaceBottom20-md spaceBottom-mb">
            <div className="portlet">
              <div className="portletHead">
                <h3 className="portlet_headTitle">AUDIENCE TRAITS</h3>
              </div>
              <div className="portletBody spacerTop36">
                <div className="scrollableContainer">
                  <Segments
                    id={1}
                    optionalIist={
                      this.props.wizardPageStats.WizardPage.PanelFirstZoneList
                    }
                    list={
                      this.props.wizardPageStats.WizardPage.PanelFirstZoneList
                        .PanelFirstZoneList
                    }
                    tabIndex={
                      this.props.wizardPageStats.WizardPage.currentSelectedIndex
                    }
                    updateStateOnClick={this.updateCurrentTab}
                    updateToggle={this.updateSelectedToggle}
                    refTabComponent={this.setComponentReference}
                    startStopLoader={this.props.startStopLoader}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-md-12 col-sm-12 col-12 spaceBottom spaceBottom-mb">
            <div className="portlet">
              <div className="portletHead">
                <h3 className="portlet_headTitle">SELECTED TRAITS</h3>
              </div>
              <div className="portletBody spacerTop36">
                <SelectedTraits
                  id={2}
                  optionalIist={
                    this.props.wizardPageStats.WizardPage.PanelFirstZoneList
                  }
                  list={
                    this.props.wizardPageStats.WizardPage.PanelFirstZoneList
                      .PanelFirstZoneList
                  }
                  tabIndex={
                    this.props.wizardPageStats.WizardPage.currentSelectedIndex
                  }
                  updateStateOnClick={this.updateCurrentTab}
                  updateToggle={this.updateSelectedToggle}
                  toggleSwithState={
                    this.props.wizardPageStats.WizardPage.toggleSwith
                  }
                  assignedRoles={this.assignedUserRoles}
                  setAdvMode={this.enableDisableSave}
                  updateAdvOperator={this.updateAdvOperator}
                />
              </div>
            </div>
          </div>
        </div>
        <SaveDialog
          saveModalContents={this.saveAudienceContents}
          aName={this.props.wizardPageStats.WizardPage.audienceName}
        />
        <a
          className=""
          href="javascript:void(0)"
          role="button"
          data-toggle="modal"
          data-target="#deleteAudienceModal"
          ref={(node) => this.focusText(node)}
        />
        <ConfirmDialog />
      </main>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    errorMessage: state.showErrorBoxState,
    wizardPageStats: state.WizardPageStats.hasOwnProperty("data")
      ? state.WizardPageStats.data
      : {
          WizardPage: {
            currentSelectedIndex: 0,
            toggleSwith: 0, //false is 0
            PanelFirstZoneList: dummyAPIResponseList(),
            PanelSecondZoneList: {
              PanelFirstZoneList: [],
              isTraitDeletedInAudienc: false,
            },
            isDropZonContainsList: false,
            audienceName: "",
          },
        },
    flyOutMenuStats:
      state.FlyoutConfigState.hasOwnProperty("FlyoutDialog") &&
      state.FlyoutConfigState.FlyoutDialog.hasOwnProperty("MessageBox")
        ? state.FlyoutConfigState.FlyoutDialog.MessageBox
        : {
            MessageBox: {
              isVisible: false,
              UserMessage: "",
              saveFailed: false,
            },
          },
    UserRole:
      state.AdminUserControlState.hasOwnProperty("UserProfile") &&
      state.AdminUserControlState.UserProfile
        ? state.AdminUserControlState.UserProfile
        : {
            email: "",
            company: null,
            userId: null,
            firstName: "",
            lastName: "",
            roles: ["user"],
          },
  };
}

interface IAudienceBuilder extends React.FC<any> {
  propsFromStore: any;
  submitRequest: any;
  errorMessage: any;
  wizardPageStats?: any;
  submitPageSwitchRequest?: any;
  submitDragPosition?: any;
  startStopLoader?: any;
  submitSaveAudienceRequest?: any;
  submitToggleSwitchAction?: any;
  handleDialogSubmitAction: any;
  handleDuplicateNameSubmitAction: any;
  flyOutMenuStats: any;
  hideFlyoutMsg: any;
  history: any;
  UserRole?: any;
}

export default connect(mapStateToProps, (dispatch) => {
  return {
    submitRequest: (payload: any) => {
      payload.userAction = UserOps.DEMOGRAPHIC_API;
      dispatch(submitSegmentRequest(payload));
      let spinnerState = { UIConfig: { isSpinnerActive: true } };
      dispatch(submitAltSpinnerAction(spinnerState));
    },
    submitSaveAudienceRequest: (payload: any) => {
      payload.userAction = UserOps.SAVE_AUDIENCE;
      dispatch(submitSegmentRequest(payload));
    },
    submitPageSwitchRequest: (pageStub: any) => {
      dispatch(submitWizardAction(pageStub));
    },
    submitDragPosition: (pageload: any) => {
      dispatch(submiDragAction(pageload));
    },
    startStopLoader() {
      let spinnerState = { UIConfig: { isSpinnerActive: true } };
      dispatch(submitAudiSpinnerAction(spinnerState));
    },
    submitToggleSwitchAction: (payload: any) => {
      dispatch(setToggleStateAction(payload));
    },
    handleDialogSubmitAction: (show: boolean) => {
      const payload = { Dialog: { isVisible: show } };
      dispatch(submitUIConfigAction(payload));
    },
    hideFlyoutMsg(payload: any) {
      dispatch(submitFlyoutConfigAction(payload));
    },
    handleDuplicateNameSubmitAction: (show: boolean, rowContent: any) => {
      const payload = {
        Dialog: {
          isVisible: show,
          content: rowContent,
          Message:
            "Overwrite the existing audience named " +
            rowContent.AudienceName +
            "?",
        },
      };
      dispatch(submitUIConfigAction(payload));
    },
  };
})(AudienceBuilder);
