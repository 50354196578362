import { transformRaw } from "src/rogersframework/Store/helper";
import { anyOf } from "../utils/ReportingUtil";

export class DomoCampaignOrder {

     getCampaignLabel(queryResponse:any) {
         if (queryResponse?.rows && queryResponse?.rows?.length > 0 && queryResponse.rows[0]?.[0]) {
             return queryResponse.rows[0][0];
         }
         return "";
     }
    
     getCampaignReportStructure(queryResponse:any,orderId:any) {
        const result = transformRaw(queryResponse);

        const campaignReportStruct = {
             isValidOrderId:queryResponse?.rows && queryResponse?.rows?.length > 0 ? true: false,
             reportLabel:  queryResponse?.rows && queryResponse?.rows?.length > 0 && queryResponse.rows[0][0]?
                queryResponse.rows[0][0] : "",
             startDate:   queryResponse?.rows && queryResponse?.rows?.length > 0 && queryResponse.rows[0][0]?
             queryResponse.rows[0][1] : "",
             endDate:   queryResponse?.rows && queryResponse?.rows?.length > 0 && queryResponse.rows[0][0]?
             queryResponse.rows[0][2] : "",
             orderEndDate:"",
             Advertiser: queryResponse?.rows && queryResponse?.rows?.length && 
             queryResponse.rows[0][14] ?  queryResponse.rows[0][14] : "",
             'Order Name':queryResponse?.rows && queryResponse.rows?.length > 0 && queryResponse.rows[0][0]?
             queryResponse.rows[0][0] : "",
             'Order ID':orderId,
             
             response:result
        }
        
        //RED-3967
        //Extract the Report type and save it in Set
        const listOfReportType = new Set(result?.map(function(d:any) { return d["Report Type"]; }));
        const listOfToplineClassification = new Set(result?.map(function(d:any) { return d["Topline_Classifications_Beast"]; }));
        campaignReportStruct['hasGAMDisplay'] = anyOf(result, (row) => parseInt(row['SUM(`T1`.`Impressions`)'], 10) > 0) && (listOfReportType.has("RSM: Display/Video"));
        campaignReportStruct['hasGAMVideo'] = (listOfReportType.has("RSM: Display/Video")) && anyOf(result, (row) => parseInt(row['SUM(`T1`.`Video_Start`)'], 10) > 0);
        campaignReportStruct['hasGAMTakeovers'] = anyOf(result, row => row["Delivery Type"] === "Takeover"  && parseInt(row['SUM(`T1`.`Impressions`)'], 10) > 0) && listOfReportType.has("RSM: Display/Video"); 
        campaignReportStruct['hasAdswizz'] = listOfReportType.has("RSM: Digital Audio");
        campaignReportStruct['hasredX'] =  listOfReportType.has("RSM: R.E.D. X");
        campaignReportStruct['hasTwitter'] = listOfReportType.has("RSM: Social Reporting - Twitter");
        campaignReportStruct['hasFacebook'] = listOfReportType.has("RSM: Social Reporting - Facebook/Instagram");
        campaignReportStruct['hasConto'] = listOfReportType.has("RSM: Contobox");
        campaignReportStruct['hasVOD'] = listOfReportType.has("RSM: Video On Demand");
        campaignReportStruct['hasCTV'] = listOfReportType.has("RSM: CTV OTT");
        campaignReportStruct['hasNewsLetter'] = listOfReportType.has("RSM: Newsletter Ads");
        campaignReportStruct['hasVideoSuite'] = listOfReportType.has("RSM: Video Suite");
        campaignReportStruct['topLineClassifications'] = listOfToplineClassification;

        return campaignReportStruct;
    }

    safeInt(txt: any) {
        return parseInt(String(txt).length > 0 ? String(txt) : '0')
    }

    safeFloat(txt: any) {
        return parseFloat(String(txt).length > 0 ? String(txt) : '0')
    }
     
}

export default DomoCampaignOrder;