import PageCategory from "./PageCategory";
import PageSection from "./PageSection";

class SlidesContentTable {
    PageType: PageCategory;
    Section: PageSection;
    
    constructor(pageType: PageCategory, section: PageSection) {
        this.PageType = pageType
        this.Section = section
    }
}
export default SlidesContentTable;