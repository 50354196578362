import ToplineSummaryDomoLineItem from "../Component/ToplineSummaryDomoLineItem"
import ToplineDomoOrderRecord from "./ToplineDomoOrderRecord"
import ToplineSummaryPaginator from "./ToplineSummaryPaginator"
import ToplineTotals from "./ToplineTotals"

 

class ToplineDomoOrderRecordWithPagination implements ToplineDomoOrderRecord {
    private paginator: ToplineSummaryPaginator | null = null
    public footer: ToplineTotals = new ToplineTotals(null)
    public slideCount = 0

    // DomoOrderRecord members
    public id = ''
    public name = ''
    public advertiser = ''
    public orderStartDate = ''
    public orderEndDate = ''
    public reportStartDate = ''
    public reportEndDate = ''
    public lines: ToplineSummaryDomoLineItem[] = []

    

    constructor(record: ToplineDomoOrderRecord) {
        this.paginator = new ToplineSummaryPaginator(record.lines)

        this.id = record.id
        this.name = record.name
        this.advertiser = record.advertiser
        this.orderStartDate = record.orderStartDate
        this.orderEndDate = record.orderEndDate
        this.reportStartDate = record.reportStartDate
        this.reportEndDate = record.reportEndDate
        this.lines = record.lines
        this.slideCount = this.paginator.slideCount
        this.footer = new ToplineTotals(record)
    }

    linesForSlide(index: number): ToplineSummaryDomoLineItem[] {
        return this.paginator ? this.paginator.linesForSlide(index) : []
    }
}
export default ToplineDomoOrderRecordWithPagination