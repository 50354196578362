/* eslint-disable */
import * as React from "react";
import { connect } from "react-redux";

import GraphModel from "../Model/GraphModel";
import { getLineChartItemDescription } from "../utils/dashboardValidation";

import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
} from "chart.js";

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip
);
import { Line } from "react-chartjs-2";

class Graph extends React.Component<IGraph, {}> {
  public chartData: any;
  public chartOptions: any;
  constructor(props: any) {
    super(props);
    this.chartOptions = {
      responsive: true,

      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          display: false,
        },
        y: {
          display: false,
        },
      },
    };
    this.handleSubmit = this.handleSubmit.bind(this);

    this.generateGraphs = this.generateGraphs.bind(this);
  }

  public appendChart(item: any) {
    if (
      item.hasOwnProperty("dataSourcePoints") &&
      item["dataSourcePoints"].length === 1
    ) {
      const itempArr = item["dataSourcePoints"][0];
      item["dataSourcePoints"].push(itempArr);
      return item["dataSourcePoints"];
    }
    return item["dataSourcePoints"];
  }

  public generateGraphs() {
    let chartDd = (item: any) => ({
      labels: item["dataSourcePoints"],
      datasets: [
        {
          label: "",
          fillColor: "rgba(211,211,211,0.7)",
          data: this.appendChart(item),
          pointBorderWidth: 1,
          pointBorderColor: "#f4f4f4",
          pointBackgroundColor: "rgba(255,0,0,2)", // red
          pointStyle: "circle",
          fill: true,
          borderColor: "rgba(255,0,0,2)",
          backgroundColor: "#dcdcdd",
          borderWidth: 2,
          lineCap: "round",
          tension: 0.2,
          pointRadius: 2,
        },
      ],
    });

    let sourceInfo = function (src: any) {
      if (src != "error") {
        return getLineChartItemDescription(src);
      }
      return (
        <span>
          <i className="fa fa-exclamation-circle" /> Error on load
        </span>
      );
    };

    let firstParty = this.chartData.getChartSourceFromObj("FirstParty");
    let thirdParty = this.chartData.getChartSourceFromObj("ThirdParty");
    let delivery = this.chartData.getChartSourceFromObj("Delivery");

    let statusItems = (data: any) =>
      data.map((s: any, i: any) =>
        s.hasOwnProperty("dataSourcePoints") &&
        s.hasOwnProperty("dataSourceName") &&
        s.hasOwnProperty("message") ? (
          <div className="row-flex lineChart align-items-end" key={"f1" + i}>
            <div
              className="col-xl-6 col-md-12 col-12 order-xl-first order-md-last order-last pl-0 pr-0 pr-xl-4"
              key={"f2" + i}
            >
              <div className="content" key={"f3" + i}>
                <h4 key={"h4" + i}>{s.dataSourceName}</h4>
                <p key={"p1" + i}>{sourceInfo(s.dataSourceName)}</p>
              </div>
            </div>
            <div
              className="col-xl-6 col-md-12 col-12 pr-0 pl-0 pl-xl-4"
              key={"f4" + i}
            >
              <div className="content" key={"f5" + i}>
                <Line data={chartDd(s)} options={this.chartOptions} />
              </div>
            </div>
          </div>
        ) : null
      );
    return (
      <div className="row-flex">
        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 spaceTop spaceBottom spaceBottom-mb">
          <div className="portlet">
            <div className="portletHead">
              <h3 className="portlet_headTitle">FIRST PARTY</h3>
            </div>
            <div className="portletBody">{statusItems(firstParty)}</div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 spaceTop spaceBottom spaceBottom-mb">
          <div className="portlet">
            <div className="portletHead">
              <h3 className="portlet_headTitle">THIRD PARTY</h3>
            </div>
            <div className="portletBody">{statusItems(thirdParty)}</div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 spaceTop spaceBottom spaceBottom-mb">
          <div className="portlet">
            <div className="portletHead">
              <h3 className="portlet_headTitle">DELIVERY</h3>
            </div>
            <div className="portletBody">{statusItems(delivery)}</div>
          </div>
        </div>
      </div>
    );
  }

  public handleSubmit() {
    this.props.handleSubmit("", "");
  }

  public render() {
    this.chartData = new GraphModel(this.props.LineChartData);
    return <div>{this.generateGraphs()}</div>;
  }
}

function mapStateToProps(state: any) {
  return {
    errorMessage: state.showErrorBoxState.errorMessage,
    LineChartData:
      state.LineChartDataState.hasOwnProperty("ChartData") &&
      state.LineChartDataState.ChartData
        ? state.LineChartDataState.ChartData
        : {},
  };
}

export default connect(mapStateToProps, (dispatch) => {
  return {};
})(Graph);

interface IGraph extends React.FC<any> {
  handleSubmit?: any;
  errorMessage?: any;
  LineChartData?: any;
}
