/* eslint-disable */
export enum AdminOperationKeys {
    FNAME="FNAME",
    LNAME="LNAME",
    COMPANY= "COMPANY",
    ADGUEST= "ADGUEST",
    ROLES="ROLES",
    EMAIL="EMAIL",

    SEGMENTNAME="SEGMENTNAME",
    SEGMENTDESCR="SEGMENTDESCR",
    LEGACYID="LEGACYID",
    ADOBEID="ADOBEID",

    // Added for Admin keys
  
  PHONE = 'PHONE',

  DOMOCONSTRAINT='DOMOCONSTRAINT',

  TRIATID = 'TRIATID',
  STATUSMSG = 'STATUSMSG',
  DATASOURCE= 'DATASOURCE',
  SHOWINBUILDER = 'SHOWINBUILDER',
  EXPOSETOCYNCH = 'EXPOSETOCYNCH',
  REPORTBANNERTOGGLE="REPORTBANNERTOGGLE",



  ATTRIBNAME="ATTRIBNAME",
  CATNAME="CATNAME",
  SEGIDFORCATEGORY="SEGIDFORCATEGORY",
  DELETESEGIDFORCATEGORY="DELETESEGIDFORCATEGORY",
  DELETECATEGORY="DELETECATEGORY",
  ATTRIBUTEDESCIPTIPON="ATTRIBUTEDESCIPTIPON",

  TVCAMPAIGNNAME="TVCAMPAIGNNAME",
  TVCONTRACTIDS="TVCONTRACTIDS",

  RESTOREDEFAULT="RESTOREDEFAULT",

  DOMOAPICLIENTKEY="DOMOAPICLIENTKEY",
  DOMOAPICLIENTSECRET="DOMOAPICLIENTSECRET"


}

export default AdminOperationKeys;