enum PageSection
{
    Intro,
    Summary,
    Heading,
    LayoutExample,
    Display,
    RedX,
    Facebook,
    Twitter,
    DigitalAudio,
    Conto,
    Eblast,
    NewsLetter,
    VideoSuite,
    Vod,
    Conclusion,
    Appendix,
    Ctv,
    Unknown
}
export default PageSection    