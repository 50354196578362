/* eslint-disable */
import * as React from 'react';
import { connect } from 'react-redux';
// import { FormattedDate } from 'react-intl';

class AlertBannerComponent extends React.Component<IPieCharts, {}> {

    public state: any;
    constructor(props: any) {
        super(props);
    }

    createMarkup() { return { __html: this.props.bannerData.message }; };

    render() {
        if (this.props.bannerData.displayBanner)
            return (
                <div className="row-flxe ml-0 mr-0">
                    <div className="col-12">
                        <div className="alertBanner"  >  
                            <div dangerouslySetInnerHTML={this.createMarkup()} />
                        </div>
                    </div>
                </div>
            )
        else
            return null;

    }
}

function mapStateToProps(state: any) {
    return {
        UIConfStats: state.SpinnerState.hasOwnProperty("UIConfig") ? state.SpinnerState.UIConfig : { UIConfig: { isSpinnerActive: true } }
    };
}


export default connect(mapStateToProps, (dispatch) => {
    return {

    }

})(AlertBannerComponent);

interface IPieCharts extends React.FC<any> {
    UIConfStats: any;
    bannerData: any;
}
