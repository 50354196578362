/* eslint-disable */
import { UserOps } from "../ConstConfig/UserOps";
import { NavBarConstants } from "../ConstConfig";

export function isDataExist(obj: any, attrib: any) {
  let isValPresent = false;
  if (!obj) {
    return false;
  }
  if (obj && obj.hasOwnProperty(attrib) && obj[attrib] && obj[attrib] !== "") {
    isValPresent = true;
  }
  return isValPresent;
}

export function getUnique(array: any) {
  const uniqueArray = [];
  // Loop through array values
  for (let i = 0; i < array.length; i++) {
    if (uniqueArray.indexOf(array[i]) === -1) {
      uniqueArray.push(array[i]);
    }
  }
  return uniqueArray;
}

export function getMenuPerRole() {
  return {
    [UserOps.CRMUPLOADER]: [
      NavBarConstants.DASHBOARDSLICK,
      NavBarConstants.CRMSLICK,
    ],
    [UserOps.ADMIN]: [
      NavBarConstants.ADMINSLICK,
      NavBarConstants.DASHBOARDSLICK,
    ],
    [UserOps.AUDIENCEBUILDER]: [
      NavBarConstants.DASHBOARDSLICK,
      NavBarConstants.AUDIENCELISTSLICK,
    ],
    [UserOps.BETATESTER]: [NavBarConstants.DASHBOARDSLICK],
    [UserOps.REPORTINGDIGITAL]: [
      NavBarConstants.DASHBOARDSLICK,
      NavBarConstants.REPORTINGLISTSLICK,
    ],
    [UserOps.REPORTINGLINOPT]: [
      NavBarConstants.DASHBOARDSLICK,
      NavBarConstants.REPORTINGLISTSLICK,
    ],
    [UserOps.REPORTINGPACING]: [
      NavBarConstants.DASHBOARDSLICK,
      NavBarConstants.REPORTINGLISTSLICK,
    ],
    [UserOps.REPORTINGRECENTREPORT]: [
      NavBarConstants.DASHBOARDSLICK,
      NavBarConstants.REPORTINGLISTSLICK,
    ],
    [UserOps.REPORTINGEXCEL]: [
      NavBarConstants.DASHBOARDSLICK,
      NavBarConstants.REPORTINGLISTSLICK,
    ],
    [UserOps.REPORTINGPPT]: [
      NavBarConstants.DASHBOARDSLICK,
      NavBarConstants.REPORTINGLISTSLICK,
    ],
    [UserOps.REPORTINGDOMO]: [
      NavBarConstants.DASHBOARDSLICK,
      NavBarConstants.REPORTINGLISTSLICK,
    ],
    [UserOps.USER]: [NavBarConstants.DASHBOARDSLICK],
    [UserOps.REPORTINGUSER]: [NavBarConstants.DASHBOARDSLICK],
    [UserOps.DOMOUSER]: [NavBarConstants.DASHBOARDSLICK],
  };
}

export function getRequiredRoleAccess(UserRole: any) {
  const roleBasedMenu = getMenuPerRole();
  let menuPerRole = "";
  let assignedMenus = [];
  if (UserRole && UserRole.hasOwnProperty("roles") && UserRole.roles) {
    UserRole.roles.map((roleItem: any, i: any) => {
      if (roleItem) {
        const role = roleBasedMenu[roleItem];
        if (role) {
          menuPerRole += role.join(",") + ",";
        }
      }
    });
  }
  if (menuPerRole !== "") assignedMenus = getUnique(menuPerRole.split(","));

  return assignedMenus;
}

export function getRoleBasedRouteAccess(UserRole: any) {
  const roleBasedMenu = {
    [UserOps.ADMIN]: [
      "/",
      "/Terminology",
      "/AdminUsersList",
      "/AddUser",
      "/Admin",
      "/AdminAudienceSegment",
      "/AdminAudienceSegmentList",
      "/AudienceSizer",
      "/AdminAudienceTemplates",
      "/AdminCynchAttributeList",
      "/CynchAttributes",
      "/AdminCRMStatus",
      "/AdminReportingBannerStatus",
      "/SystemInformation",
      "/QueryAudienceSegmentIds",
      "/createQuerySegment",
      "/TVCampaignAggregation",
      "/TVCampaignAggregationOps",
      "/ConfigurationSettings",
      "/DomoConfigManagement",
      "/DomoQueryManagement",
      "/DomoQueryOps",
      "/TestPath"
    ],
    [UserOps.AUDIENCEBUILDER]: ["/", "/Terminology"],
    [UserOps.REPORTINGDOMO]: ["/Reporting", "/Terminology"],
    [UserOps.DOMOUSER]: ["/Reporting", "/Terminology"],
    [UserOps.REPORTINGUSER]: [
      "/",
      "/Reporting",
      "/Reporting/TV",
      "/Reporting/LinOpt",
      "/Reporting/Digital",
      "/Terminology",
      "/Reporting/Digital/Excel/:orderId",
      "/Reporting/Digital/PowerPoint/:orderId",
    ],
    [UserOps.REPORTINGDIGITAL]: [
      "/",
      "/Reporting",
      "/Reporting/Digital",
      "/Terminology",
      "/Reporting/Digital/Excel",
      "/Reporting/Digital/PowerPoint",
      "/Reporting/Digital/Excel/:orderId",
      "/Reporting/Digital/PowerPoint/:orderId",
    ],
    [UserOps.REPORTINGLINOPT]: [
      "/",
      "/Reporting",
      "/Reporting/LinOpt",
      "/Terminology",
    ],
    [UserOps.REPORTINGPACING]: [
      "/",
      "/Reporting",
      "/Reporting/Digital",
      "/Terminology",
      "/Reporting/Digital/Excel/:orderId",
      "/Reporting/Digital/PowerPoint/:orderId",
    ],
    [UserOps.REPORTINGRECENTREPORT]: [
      "/",
      "/Reporting",
      "/Reporting/Digital",
      "/Terminology",
      "/Reporting/Digital/Excel/:orderId",
      "/Reporting/Digital/PowerPoint/:orderId",
    ],
    [UserOps.REPORTINGEXCEL]: [
      "/",
      "/Reporting",
      "/Reporting/Digital",
      "/Terminology",
      "/Reporting/Digital/Excel/:orderId",
      "/Reporting/Digital/PowerPoint/:orderId",
    ],
    [UserOps.REPORTINGPPT]: [
      "/",
      "/Reporting",
      "/Reporting/Digital",
      "/Terminology",
      "/Reporting/Digital/Excel/:orderId",
      "/Reporting/Digital/PowerPoint/:orderId",
    ],
    [UserOps.BETATESTER]: ["/", "/Terminology"],
    [UserOps.CRMUPLOADER]: ["/CRMUploader", "/Terminology"],
    [UserOps.USER]: ["/", "/Terminology"],
  };
  let menuPerRole = "";
  let assignedMenus = [];
  if (UserRole && UserRole.hasOwnProperty("roles") && UserRole.roles) {
    UserRole.roles.map((roleItem: any, i: any) => {
      if (roleItem) {
        let role = roleBasedMenu[roleItem];
        if (role) menuPerRole += role.join(",") + ",";
      }
    });
  }
  if (menuPerRole !== "") assignedMenus = getUnique(menuPerRole.split(","));

  return assignedMenus;
}

export function decodeToken(token = null) {
  try {
    if (token?.split(".").length !== 3 || typeof token !== "string") {
      return null;
    }
    var payload = token?.split(".")[1];
    var padding = "=".repeat((4 - (payload?.length % 4)) % 4);
    var base64 = payload.replace("-", "+").replace("_", "/") + padding;
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    var decoded = JSON.parse(jsonPayload);
    return decoded;
  } catch (error) {
    return null;
  }
}

export function isTokenExpired(token) {
  const decodedToken = decodeToken(token);
  const tokenExpiry = decodedToken?.exp * 1000;
  const currentDate = new Date().getTime();
  const result = tokenExpiry < currentDate ? true : false;
  return result;
}
