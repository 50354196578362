/* eslint-disable */
import { getAllLinkedItems } from "../sortingUtil";
import { AudienceSizerConst } from "../../ConstConfig/AudienceSizerConst";

export class AdvanceModeModel {
    segmentId: number;
    SegmentSelectedList: any;
    segIdOrdrIdMap:any;
    constructor() {
        this.segmentId = 1234;
        this.SegmentSelectedList = [];
        this.segIdOrdrIdMap={};
    }

    public setAdvanceModeResponse(respObj: any) {

    }

    public getSegmentPayloadForAdvanceMode() {
        return this.SegmentSelectedList;
    }

    public getSegmentationIdAndOrderIdMap() {
        this.SegmentSelectedList.map((obj: any, i: any) => {
            this.segIdOrdrIdMap[obj.segmentId] = i + 1;
        })
        return this.segIdOrdrIdMap;
    }

    public getAdvanceModePayload() {
        let payload = JSON.parse(JSON.stringify(this.SegmentSelectedList));;
        const lenIndex = this.SegmentSelectedList.length;
        if (length > 0) {
            payload[lenIndex - 1].operator = "";
        }

        payload = getAllLinkedItems(payload);
        return { advanceModeSegmentation: payload }
    }

    public getAdvanceModeSavePayload() {
        let payload = JSON.parse(JSON.stringify(this.SegmentSelectedList));;
        payload = getAllLinkedItems(payload);
        const lenIndex = this.SegmentSelectedList.length;
        if (length > 0) {
            payload[lenIndex - 1].operator = "";
        }
        let orderId = "orderId";
        payload.map((obj: any, i: any) => {
            obj[orderId] = i + 1;
        })
        return { advanceModeSegmentation: payload }
    }

    private getSegmentCategory(tabType: any) {
        const catList = ["Demographics", "Interest", "Behaviour"];
        return catList[tabType];
    }

    public setSegementInAdvanceMode(reqObj: any) {
        let segmentId = reqObj.segmentId;
        let card = reqObj;
        if (reqObj.hasOwnProperty("card")) {
            segmentId = reqObj.card.segmentId;
            card = reqObj.card;
        }

        let realindx = this.SegmentSelectedList.findIndex((obj: any) => obj.segmentId === segmentId);
        if (reqObj.hasOwnProperty("linkCard") && reqObj.linkCard) {
            const indx = this.SegmentSelectedList.findIndex((obj: any) => obj.segmentId === reqObj.linkCard.segmentId);
            if (indx >= 0) {
                this.setAttributes(indx, reqObj.linkCard);
                realindx = this.SegmentSelectedList.findIndex((obj: any) => obj.segmentId === reqObj.card.segmentId);
            }
        }
        if (realindx >= 0) {
            this.setAttributes(realindx, card);
        }
        else {
            const advSegment = {
                operator: reqObj.operator ? reqObj.operator : "AND",
                segmentId: reqObj.segmentId,
                segmentName: reqObj.segmentName,
                segmentLabel: reqObj.segmentLabel,
                category: this.getSegmentCategory(reqObj.Tabtype),
                linkedSegmentId: reqObj.linkedSegmentId,
                linkDirection: reqObj.linkDirection,
            }

            this.SegmentSelectedList.push(advSegment);
         }
    }

    setAttributes(indx: any, reqObj: any) {
        this.SegmentSelectedList[indx].operator = reqObj.operator ? reqObj.operator : "AND";
        this.SegmentSelectedList[indx].segmentId = reqObj.segmentId;
        this.SegmentSelectedList[indx].segmentName = reqObj.segmentName;
        this.SegmentSelectedList[indx].segmentLabel = reqObj.segmentLabel;
        this.SegmentSelectedList[indx].category = this.getSegmentCategory(reqObj.Tabtype),
        this.SegmentSelectedList[indx].linkedSegmentId = reqObj.linkedSegmentId;
        this.SegmentSelectedList[indx].linkDirection = reqObj.linkDirection;
    }

    public setReorderedSegementInAdvanceMode(reqObjMap: any) {

        this.SegmentSelectedList = [];
         reqObjMap.map((reqObj: any, i: any) => {
            const advSegment = {
                operator: reqObj.operator ? reqObj.operator : "AND",
                segmentId: reqObj.segmentId,
                segmentName: reqObj.segmentName,
                segmentLabel: reqObj.segmentLabel,
                category: this.getSegmentCategory(reqObj.Tabtype),
                linkedSegmentId: reqObj.linkedSegmentId,
                linkDirection: reqObj.linkDirection
            }
            this.SegmentSelectedList.push(advSegment);
         });
    }

    public removeSegement(index: any) {
        this.SegmentSelectedList.splice(index, 1);
    }


    public unLinkAndRemove(requestPayload: any) {
        const list = this.getSegmentPayloadForAdvanceMode();

        let firstIndex = list.findIndex((obj: any) => obj.segmentId == requestPayload.card.card.segmentId);
        if (firstIndex >= 0) {
            this.removeSegement(firstIndex);
        }

        let nextIndex = list.findIndex((obj: any) => obj.segmentId == requestPayload.card.allNodes.prev.segmentId);
        if (nextIndex >= 0) {
            this.unLink(list, nextIndex, firstIndex, requestPayload.card.card.segmentId);
        }
        let finalIndex = list.findIndex((obj: any) => obj.segmentId == requestPayload.card.allNodes.next.segmentId);
        if (finalIndex >= 0) {
            this.unLink(list, finalIndex, firstIndex, requestPayload.card.card.segmentId);
        }
    }

    private unLink(list: any, index: any, nIndex: any, segmentId: any) {
        if (list[index].linkDirection === AudienceSizerConst.LINKDOWN || list[index].linkDirection === AudienceSizerConst.LINKUP) {
            list[index].linkDirection = AudienceSizerConst.LINKNONE;
            list[index].linkedSegmentId = [];
        }
        if (list[index].linkDirection === AudienceSizerConst.LINKBOTH) {
            if (index < nIndex) {
                list[index].linkDirection = AudienceSizerConst.LINKDOWN;
            }
            else {
                list[index].linkDirection = AudienceSizerConst.LINKUP;
            }
            let innerIndex = list[index].linkedSegmentId.findIndex((obj: any) => obj == segmentId);
            if (innerIndex >= 0) {
                list[index].linkedSegmentId.splice(innerIndex, 1);
            }
        }

    }
}



export default AdvanceModeModel;