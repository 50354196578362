/* eslint-disable */
import { AxiosClient } from "../ClientServices/AxiosClient";
import {
  submitSpinnerAction,
  broadcastCRMStat,
  submitUIConfigAction,
  updateProgressCRM,
  updateCRMStateMessage,
  requestCRMStat,
  updateCRMCanList,
} from "../../Actions";
import { List } from "immutable";
import { getAuthContext } from "src/Login/MSAL/msalConfig";
import axios from "axios";
import { UserOps } from "src/ConstConfig/UserOps";
import Logger from "../Logger/Logger";
import CRMCanModel from "src/Admin/Model/CRMCanModel";

const reduiCRMUploaderMiddleWare =
  (store: any) => (next: any) => (action: any) => {
    if (
      action &&
      action.hasOwnProperty("type") &&
      (action.type === "UPLOADCRM" ||
        action.type === "LISTALL_CRM" ||
        action.type === "CRMTRAITACTION")
    ) {
      const clnt = new AxiosClient(store);
      const token = getAuthContext().idToken.rawIdToken;
      let userAction = action.type;

      switch (userAction) {
        case "UPLOADCRM":
          {
            const reqObjectSeg = {
              authToken: token,
              url: action.payload.data.url,
            };
            let filePayload = action.payload.data.payload;

            if (action.payload.data.fileSize > 524288000) {
              const messageBoxObj = {
                Dialog: {
                  MessageBox: {
                    isVisible: true,
                    UserMessage: " This file is too large for processing",
                    saveFailed: false,
                    boxButtons: UserOps.OK,
                    messageHead: "Error!",
                    popupAuto: true,
                  },
                },
              };

              store.dispatch(submitUIConfigAction(messageBoxObj));
              return;
            }

            const contType = "Content-Type";
            axios.defaults.headers.common.Accept = "application/json";
            axios.defaults.headers.post[contType] = "multipart/form-data";
            axios.defaults.maxContentLength = Infinity;
            if (reqObjectSeg.hasOwnProperty("authToken")) {
              axios.defaults.headers.get["Authorization"] =
                "Bearer " + reqObjectSeg.authToken;
            }
            if (!filePayload) {
            }

            const optionsData = {
              method: "POST",
              headers: {
                Accept: "application/json",
                Authorization: "Bearer " + reqObjectSeg.authToken,
              },

              body: filePayload,
            };
            return fetch(reqObjectSeg.url, optionsData)
              .then((response) => response.json())
              .then((res) => {
                var count = 1;
                var counter = setInterval(timer, 30);
                function timer() {
                  count = count + Math.floor(Math.random() * 10);
                  if (count <= 100) {
                    store.dispatch(
                      updateProgressCRM({ data: { loaded: count, total: 100 } })
                    );
                  } else {
                    clearInterval(counter);
                    store.dispatch(
                      updateProgressCRM({ data: { loaded: 100, total: 100 } })
                    );
                  }
                }
                counter;

                if (
                  res.hasOwnProperty("status") &&
                  (res.status === false || res.status === null)
                ) {
                  store.dispatch(
                    updateCRMStateMessage({
                      data: {
                        msg: "File upload operation failed. Please try again later",
                      },
                    })
                  );
                } else if (
                  res.hasOwnProperty("status") &&
                  res.status === "Failed"
                ) {
                  store.dispatch(
                    updateCRMStateMessage({ data: { msg: res.message } })
                  );
                } else if (res.hasOwnProperty("status") && res.status === 500) {
                  store.dispatch(
                    updateCRMStateMessage({ data: { msg: res.error } })
                  );
                } else {
                  store.dispatch(
                    updateCRMStateMessage({
                      data: {
                        msg:
                          "Thank you, your file has been received and we're processing the following columns: " +
                          res.validHeaders.join(", "),
                      },
                    })
                  );
                  const dummyUserObj = {
                    type: UserOps.LISTALL_CRM,
                    data: {
                      url: action.payload.data.crmReportUrl,
                      type: UserOps.LISTALL_CRM,
                    },
                  };

                  store.dispatch(requestCRMStat(dummyUserObj));
                }
              })
              .catch((error) => {
                if (error.response) {
                  Logger.getInstance().printWarnLogs(
                    "Status with failure",
                    error?.response?.status
                  );
                } else if (error.request) {
                  Logger.getInstance().printWarnLogs(error?.request);
                } else {
                  Logger.getInstance().printWarnLogs("Error", error.message);
                }
                if (error?.response && error?.response?.status)
                  return {
                    status: error.response.status,
                    errorData: error.response.data,
                  };
                else return { status: 500, errorData: "" };
              });
          }
          break;

        case "LISTALL_CRM":
          {
            const reqObjectSeg = {
              authToken: token,
              url: action.payload.data.url,
            };
            let data = {};
            clnt
              .getResponse(reqObjectSeg.url, reqObjectSeg)
              .then((returnVal: any) => {
                if (returnVal.status >= 400 && returnVal.status < 600) {
                  data = List([]);
                } else {
                  if (returnVal instanceof Array) {
                    data = { CRMSList: List(returnVal) };
                    store.dispatch(broadcastCRMStat(data));
                  } else {
                  }
                }
                let spinnerState = { UIConfig: { isSpinnerActive: false } };

                store.dispatch(submitSpinnerAction(spinnerState));
              });
          }
          break;
        case "CRMTRAITACTION":
          {
            const url = action.payload.url;
            const reqObjectSeg = {
              authToken: token,
              url: url,
            };
            let canList = {};
            clnt.getResponse(url, reqObjectSeg).then((returnVal: any) => {
              if (returnVal.hasOwnProperty("status") && returnVal.status) {
                canList = {};
              } else {
                let crmModel = new CRMCanModel(returnVal);
                canList = { CRMCanList: crmModel.getcrmCanList() };
              }
              store.dispatch(updateCRMCanList(canList));
            });
          }
          break;
      }
    }
    return next(action);
  };

export default reduiCRMUploaderMiddleWare;
