import PageSection from './PageSection'

// TODO this should probably be externalized to a data file
export function guessSection(filename: string): PageSection {
    if (filename.indexOf('Display') >= 0) return PageSection.Display
    if (filename.indexOf('R.E.D. X') >= 0) return PageSection.RedX
    if (filename.indexOf('Contobox') >= 0) return PageSection.Conto
    if (filename.indexOf('Digital Audio') >= 0) return PageSection.DigitalAudio
    if (filename.indexOf('Twitter') >= 0) return PageSection.Twitter   
    if (filename.indexOf('Facebook_Instagram') >= 0) return PageSection.Facebook
    if (filename.indexOf('eBlasts') >= 0) return PageSection.Eblast
    if (filename.indexOf('Video On Demand') >= 0) return PageSection.Vod
    if (filename.indexOf('CTV OTT') >= 0) return PageSection.Ctv
    if (filename.indexOf('Newsletter') >= 0) return PageSection.NewsLetter
    if (filename.indexOf('Video Suite') >= 0) return PageSection.VideoSuite
    return PageSection.Display // guess the most common one
}
