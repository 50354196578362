/* eslint-disable */
import { CampaignEleConstants } from "../../ConstConfig/CampaignEleConstants";
import SortingModel from "../Model/SortingModel";

let recentReportSorted = new SortingModel('proposalId');
let pacingReportSorted = new SortingModel('proposalId');


export function getRecentReportSortedColName() {
    return recentReportSorted.getSortedColName();
}
export function getPacingReportSortedColName() {
    return pacingReportSorted.getSortedColName();
}


export function isEmptyOrSpaces(str:any){
    return str === null || str.match(/^ *$/) !== null;
}

export function clearReportingErrorState() {
    const errStateObj = {
        proposalIDError: { show: false, errFlyoutIdentifier: CampaignEleConstants.REPORTING_ID_ERROR , cssAttrib:"proposalSearch error"},
        proposalID_NotExist_Error: { show: false, errFlyoutIdentifier: CampaignEleConstants.REPORTING_ID_FAILURE_ERROR , cssAttrib:"proposalSearch error"},
    }
    return errStateObj;
}

export function clearLinOptsErrorState() {
    const errStateObj = {
        contractId: { show: false, errFlyoutIdentifier: CampaignEleConstants.CONTRACTID_ERROR , cssAttrib:"proposalSearch error"},
    }
    return errStateObj;
}

export function getRecentReportTable(list:any,formatNumber:any,actionMethod:any,maxRows:any,customTitle:any) {
    const tableInput = {
        tableContent: list,
        tableFields: [
            {
                field: "proposalId",
                dataSort: true,
                searchable: false,
                formatExtraData:"proposalId",
                displayName: "IO",
                isHidden: false,
                isKey: true,
                width:"60" 
            },
            {
                field: "fileName",
                dataSort: true,
                searchable: false,
                displayName: "Name",
                formatExtraData:"fileName",
                isHidden: false,
                isKey: false,
                clsName:"autoWrapCells",
                colClsName:"autoWrapCells",
                customTitle:customTitle,
                width:"390" 
            },
            {
                field: "bookedImpression",
                dataSort: false,
                formatExtraData: "bookedImpression",
                searchable: false,
                actionMethod: formatNumber,
                displayName: "Booked Imps",
                isHidden: false,
                isKey: false,
                width:"60" 
            },
            {
                field: "totalImpressions",
                dataSort: false,
                formatExtraData: "totalImpressions",
                searchable: false,
                tdStyle: {},
                displayName: "Delivered Imps",
                actionMethod: formatNumber,
                isKey: false,
                isHidden: false,
                width:"60" 
            },
            {
                field: "pacing",
                dataSort: false,
                formatExtraData: "pacing",
                searchable: false,
                displayName: "% Delivered",
                isKey: false,
                isHidden: false,
                actionMethod: formatNumber,
                width:"50" 
            },
            {
                field: "totalClicks",
                actionMethod: formatNumber,
                dataSort: false,
                formatExtraData: "totalClicks",
                searchable: false,
                displayName: "Clicks",
                isHidden: false,
                isKey: false,
                width:"50" 
            },
            {
                field: "CTR",
                actionMethod: formatNumber,
                dataSort: false,
                formatExtraData: "CTR",
                searchable: false,
                isHidden: false,
                displayName: "CTR",
                isKey: false,
                width:"40" 
            },
            {
                field: "",
                actionMethod: actionMethod,
                formatExtraData:"recentReport",
                isHidden: false,
                searchable: false,
                displayName: "Actions",
                hidden: false,
                isKey: false,
                sortingIcons:false,
                width:"150" 
            }
        ],
        pagination: false,
        showSearch:false,
        bordered: false,
        multiColumnSearch: false,
        showPagination: true,
        maxRowSize: maxRows,
        sortedCol: '',
        sortedOrder: '',
        isExpandableRow :false,
        sortModel:recentReportSorted,
        ExportCSV:false
    };
    tableInput["expandComponent"]= null;
    return tableInput;
}

export function getRecentReportDeviceTable(list:any,getAllColView:any,getActionControls:any) {
    const tableInputSmall = {
        tableContent: list,
        tableFields: [
            {
                field: "proposalId",
                dataSort: false,
                formatExtraData: "recentReport",
                searchable: false,
                actionMethod: getAllColView,
                displayName: "IO:",
                hidden: false,
                isKey: true
            }
        ],
        pagination: false,
        showSearch: false,
        dispNames: { "fileName": "Name : ", "bookedImpression": "Booked Impressions: ", "totalImpressions": "Delivered Impressions", "pacing": "% Delivered", "totalClicks": "Clicks", "CTR": "CTR" },
        actionMethod: getActionControls,
        bordered: false,
        multiColumnSearch: false,
        showPagination: true,
        maxRowSize: 25,
        sortedCol: '',
        sortedOrder: '',
        isExpandableRow :false,
        sortModel:recentReportSorted,
        ExportCSV:false
    }
    tableInputSmall["expandComponent"]= null;
    return tableInputSmall;
}

export function getPacingReportTable(list:any,formatNumber:any,actionMethod:any,maxRows:any,isExpandableRow:any,expandComponent:any) {
    const tableInput = {
        tableContent: list,
        searchTitle: "Search by order or people details",
        tableFields: [
            {
                field: "proposalId",
                dataSort: true,
                searchable: true,
                formatExtraData:"proposalId",
                displayName: "Proposal ID",
                isHidden: false,
                isKey: true,
                Export: true
            },
            {
                field: "advertiserName",
                dataSort: true,
                formatExtraData: "advertiserName",
                searchable: true,
                isHidden: true,
                displayName: "Advertiser",
                isKey: false,
                Export: true
            },
            {
                field: "campaignStartDate",
                dataSort: true,
                formatExtraData: "campaignStartDate",
                searchable: false,
                isHidden: false,
                displayName: "Start Date",
                isKey: false,
                isDate:true,
                Export: true
            },
            {
                field: "campaignEndDate",
                dataSort: true,
                formatExtraData: "campaignEndDate",
                searchable: false,
                isHidden: false,
                displayName: "End Date",
                isKey: false,
                isDate:true,
                Export: true
            },
            {
                field: "trackableBookedSpend",
                dataSort: true,
                formatExtraData: "trackableBookedSpend",
                searchable: false,
                isHidden: false,
                displayName: "Booked Spend",
                isKey: false,
                isCurrency:true
            },
            {
                field: "trackableActualSpend",
                dataSort: true,
                formatExtraData: "trackableActualSpend",
                searchable: false,
                isHidden: false,
                displayName: "Actual Spend",
                isKey: false,
                isCurrency:true,
                Export: true
            },
            {
                field: "pacing",
                dataSort: true,
                formatExtraData: "pacing",
                searchable: false,
                displayName: "pacing",
                isKey: false,
                isHidden: false,
                isPercent:true,
                Export: true
            },
            {
                field: "totalDays",
                dataSort: true,
                formatExtraData: "totalDays",
                searchable: false,
                displayName: "Total Days",
                isHidden: false,
                isKey: false,
                isNumber:true,
                Export: true
            },
            {
                field: "daysLeft",
                dataSort: true,
                formatExtraData: "daysLeft",
                searchable: false,
                isHidden: false,
                displayName: "Days Left",
                isKey: false,
                isNumber:false,
                defaultNumCell:true,
                Export: true
            },
            {
                field: "fileName",
                dataSort: true,
                formatExtraData: "fileName",
                searchable: true,
                isHidden: true,
                displayName: "Order Name",
                isKey: false,
                Export: true
            },
            {
                field: "trackableActualImpressions",
                dataSort: true,
                formatExtraData: "trackableActualImpressions",
                searchable: false,
                isHidden: true,
                displayName: "Actual Impressions",
                isKey: false,
                isNumber:true,
                Export: true
            },
           
            {
                field: "trackableBookedImpressions",
                dataSort: true,
                formatExtraData: "trackableBookedImpressions",
                searchable: false,
                isHidden: true,
                displayName: "Booked Impressions",
                isKey: false,
                isNumber:true,
                Export: true
            },
            {
                field: "untrackableBookedImpressions",
                dataSort: true,
                formatExtraData: "untrackableBookedImpressions",
                searchable: false,
                isHidden: true,
                displayName: "UnTracked Impressions",
                isKey: false,
                isNumber:true,
                Export: true
            },
            {
                field: "coordinator",
                dataSort: true,
                formatExtraData: "coordinator",
                searchable: true,
                isHidden: true,
                displayName: "Coordinator",
                isKey: false,
                Export: true
            },
            {
                field: "accountManager",
                dataSort: true,
                formatExtraData: "accountManager",
                searchable: true,
                isHidden: true,
                displayName: "Account Manager",
                isKey: false,
                Export: true
            },
            {
                field: "",
                actionMethod: actionMethod,
                formatExtraData:"",
                isHidden: false,
                searchable: false,
                displayName: "Actions",
                hidden: false,
                isKey: false,
                Export: false
            }
        ],
        pagination: true,
        showSearch:true,
        bordered: false,
        multiColumnSearch: true,
        showPagination: true,
        maxRowSize: maxRows,
        sortedCol: '',
        sortedOrder: '',
        isExpandableRow : isExpandableRow,
        expandComponent:expandComponent,
        sortModel:pacingReportSorted,
        ExportCSV:true
    };

    return tableInput;
}

export function getPacingReportDeviceTable(list:any,getActionControls:any,isExpandableRow:any,expandComponent:any) {
    const tableInputSmall = {
        tableContent: list,
        searchTitle: "Search by order or people details",
        tableFields: [
            {
                field: "rowId",
                dataSort: false,
                formatExtraData: "",
                searchable: false,
                displayName: "",
                hidden: false,
                isKey: true,
                Export: false
            },
             
        ],
        pagination: true,
        showSearch: true,
        dispNames: { "proposalId": 'Proposal ID: ',
        "campaignStartDate":"Start Date: ", "campaignEndDate": "End Date :", "trackableBookedSpend":"Booked Spend: ", "trackableActualSpend":"Actual Spend: ",
        "pacing":"Pacing: ","totalDays" : "Total Days: ", "daysLeft": "Days Left: "
        
        },
        actionMethod: getActionControls,
        bordered: false,
        multiColumnSearch: true,
        showPagination: true,
        maxRowSize: 25,
        sortedCol: '',
        sortedOrder: '',
        isExpandableRow : isExpandableRow,
        expandComponent:expandComponent,
        sortModel:pacingReportSorted,
        ExportCSV:true
    }
    return tableInputSmall;
}


export function getTwoDecimal(userVal:any) {
    return Number.parseFloat(userVal).toFixed(2);
}

//No spot


export function getExcel2Path(orderId:any) {
    let hostname = window && window.location && window.location.host;
   
    // hostname = "https://ehdpde101.dev.dmt.rogers.com";
    return "https://" + hostname + "/" + "Reporting/Digital/Excel/" + orderId;
}

export function isExist(queryResult: any, columnName: string, validResults:any) {
    const index = queryResult?.columns?.indexOf(columnName);
    if(index >=0)
    return (queryResult?.rows.filter((row:any) => validResults.includes(row[index])>=0)).length > 0 ? true : false;
    else return false;
}

export function isItemEqualsZero(queryResult: any, columnName: string) {

    const index = queryResult?.columns?.indexOf(columnName);
    if(index >=0)
    return (queryResult?.rows.filter((row:any) => parseInt(row[index],10) === 0)).length > 0 ? true : false;
    else return false;
}

export function isItemGTZero(queryResult: any, columnName: string) {

    const index = queryResult?.columns?.indexOf(columnName);
    if(index >=0)
    return (queryResult?.rows.filter((row:any) => parseInt(row[index],10) > 0)).length > 0 ? true : false;

    else return false;
}


type Criteria = (row: Record<string, string>) => boolean
export function anyOf (rows: Record<string, string>[], crit: Criteria) {
        return (rows.filter(row => crit(row))).length > 0 ? true : false
    }
