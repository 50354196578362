import * as React from 'react';
import { connect } from 'react-redux';
import { BootstrapTable, TableHeaderColumn, ExpandColumnOptions, ExpandColumnComponentProps } from 'react-bootstrap-table';
import {getTodaysDate} from "../../Utility/CampaignUtil";

class TableComponent extends React.Component<ITableComponent, {}> {
    private tableOption:any;
    private parentBtnGrp:any;
    constructor(props: any) {
        super(props);
        this.parentBtnGrp = null
        this.parentBtn = this.parentBtn.bind(this);
    }


    parentBtn(ref:any) {
        this.parentBtnGrp = ref;
    }

    componentDidMount() {
        if(this.parentBtnGrp) {
            const parent = this.parentBtnGrp.parentElement;
            parent.className = parent.className + " float-xl-right float-md-right pl-1 pr-3";
        }
    }

    createCustomExportCSVButton = (onClick:any) => {
        return (
            <a ref={(node) => this.parentBtn(node)}  className=" searchPanelExp btn btnPrimary " href="javascript:void(0);" role="button" onClick={ onClick }>Export CSV</a>
         );
      }
    expandColumnComponent = ({ isExpandableRow, isExpanded }: ExpandColumnComponentProps) => {
        const content = isExpandableRow
          ? (isExpanded ? '' : '')
          : '';
        const expClpClassName = isExpandableRow
          ? (isExpanded ? 'accordionInactive ' : 'accordion active accordinPadding')
          : '';
        return (
        
          <div className={expClpClassName}> {content} </div>
        );
      }

    public render() {
        let expandColumnOptions: ExpandColumnOptions = {
            expandColumnVisible: false,
            expandColumnBeforeSelectColumn: false,
            expandColumnComponent: null
           };
        if(this.props.tableInput.isExpandableRow) {
            expandColumnOptions  = {
                expandColumnVisible: true,
                expandColumnBeforeSelectColumn: false,
                expandColumnComponent: this.expandColumnComponent
               };
        }
        this.tableOption = {
            hidePageListOnlyOnePage: true,
            hideSizePerPage: true,
            sizePerPage: this.props.tableInput.tableOptions.sizePerPage,  // which size per page you want to locate as default
            defaultSortName: this.props.tableInput.tableOptions.defaultSortName,
            defaultSortOrder: this.props.tableInput.tableOptions.defaultSortOrder,
            searchPosition: 'left',
            paginationPanel: this.props.tableInput.tableOptions.renderPaginationPanel,
            searchPanel: this.props.tableInput.tableOptions.customSerachPanel,
            // toolBar: this.props.tableInput.tableOptions.toolBar
            exportCSVBtn:this.createCustomExportCSVButton
            
        }
        const fileName = "digital-pacing-"+ getTodaysDate()+".csv";
        return (
            <BootstrapTable 
            data={this.props.tableInput.tableContent.toArray()} 
            version='4' 
            striped={true} 
            hover={true} 
            condensed={false}
            options={this.tableOption}
            pagination={this.props.tableInput.pagination} 
            trClassName="table table-borderless" 
            search={this.props.tableInput.search} 
            bordered={this.props.tableInput.border} 
            multiColumnSearch={this.props.tableInput.multiColumnSearch}
            expandableRow={ this.props.tableInput.isExpandableRow }
            expandComponent={ this.props.tableInput.expandComponent }
            expandColumnOptions={expandColumnOptions}
            exportCSV={this.props.tableInput.ExportCSV}
            csvFileName={fileName}
            >
            {this.props.tableInput.tableContents.map((cell: any, i: any) => {
                return (
                <TableHeaderColumn 
                key={cell.dataField}
                dataField={cell.dataField} 
                dataSort={cell.dataSort} 
                caretRender={cell.changeCaret} 
                dataFormat={cell.dataFormatMethod} 
                formatExtraData={cell.formatExtraData} 
                sortHeaderColumnClassName={cell.customSortStyle}
                searchable={cell.searchable} 
                tdStyle={cell.tdStyle}
                hidden={cell.hidden}
                columnTitle={ cell.customTitle }
                className={cell.clsName}
                columnClassName={cell.colClsName}
                isKey={cell.isKey}
                export={cell.Export}
                width={cell.width}>
               {cell.Title}
                </TableHeaderColumn> )
            })}
            </BootstrapTable>
        );
    }
}

function mapStateToProps(state: any) {
    return {

    };
}

export default connect(mapStateToProps, (dispatch) => {
    return {

    }

})(TableComponent);

interface ITableComponent extends React.FC<any> {
    tableInput?:any;
    focusTable?:any;
    history?: any;
}
