/* eslint-disable */
export function getNextAvailableIndex(cards, indx, linkFoundInArr) {
    const linkedSegmentId = "linkedSegmentId";
    if(indx <=0 ){
         
        return 0;
    }
    if( (cards[indx].hasOwnProperty("linkedSegmentId") && cards[indx].linkedSegmentId &&  cards[indx][linkedSegmentId].length>0) 
    || (cards[indx].hasOwnProperty("linkClass") && cards[indx].linkClass ==" connector")) {
        return getNextAvailableIndex(cards, indx - 1, true);
    }
    else {
        if (linkFoundInArr) indx += 1;
        return indx;
    }
}


export function getLinkedIndexs(cards, currCard, indx,linkArr) {
    const linkedSegmentId = "linkedSegmentId";
    //
    if (cards[indx].hasOwnProperty("linkedSegmentId") && cards[indx].linkedSegmentId &&  cards[indx][linkedSegmentId].length>1) {
        linkArr += ","+cards[indx].linkedSegmentId;
        return getLinkedIndexs(cards, cards[indx],indx+1,linkArr);
    }
    else if(cards[indx].hasOwnProperty("linkedSegmentId") && cards[indx].linkedSegmentId &&  cards[indx][linkedSegmentId].length==1) {
        if(currCard.segmentId === cards[indx].linkedSegmentId[0]) {
            linkArr += ","+cards[indx].linkedSegmentId;
            return {lastIndex:indx,linkStr:linkArr};
        }
    }
    else {
        linkArr += ","+cards[indx].linkedSegmentId;
        return {lastIndex:indx,linkStr:linkArr};
    }
}

export function getAllLinkedItems(cards) {
    let tmpIndx = 0;
    let fIndex = 0;
    let segmentIdArr = "";
    let COMMA = "";
     for (; tmpIndx < cards.length; tmpIndx++) {
         if(tmpIndx === cards.length-1) {
             break;
         }
         if (cards[tmpIndx].hasOwnProperty("linkedSegmentId") && cards[tmpIndx].linkedSegmentId && cards[tmpIndx].linkedSegmentId.length > 0) {
             //
             if(segmentIdArr !=="") COMMA = ","
             if(cards[tmpIndx].linkedSegmentId.length === 1) {
               //
                if(cards[tmpIndx+1].linkedSegmentId.length===1 && cards[tmpIndx+1].linkedSegmentId[0]===cards[tmpIndx].segmentId) {
                    cards[tmpIndx+1].linkedSegmentId = [];
                    tmpIndx++;
                }
                else if(cards[tmpIndx+1].linkedSegmentId.length > 1 ) {
                    //
                    const obj = getLinkedIndexs(cards,cards[tmpIndx],tmpIndx+1,segmentIdArr);
                    if(obj) {
                        let allLinks = obj.linkStr.split(",");
                        allLinks = allLinks.filter(function (el) {return (el != "" );});
                        let x = (arr) => arr.filter((v,i) => arr.indexOf(v) === i)
                        allLinks = x(allLinks);
                         for(fIndex=0;fIndex<allLinks.length;fIndex++) {
                            let currCard =cards[tmpIndx].segmentId;
                     
                            if(allLinks[fIndex] == currCard) {
                                allLinks.splice(fIndex,1);
                            }
                        }
                        for(fIndex=tmpIndx;fIndex<=obj.lastIndex;fIndex++) {
                            
                            cards[fIndex].linkedSegmentId = [];
                        }
                        cards[tmpIndx].linkedSegmentId = allLinks;
                        tmpIndx=obj.lastIndex;
                    }
                    
                }
             }

        }
    }
   
    if(cards.length>0) {
        cards[cards.length-1].linkedSegmentId = [];
    }
  
    return cards;
   
}

export function removeDups(payload) {
    let x = (arr) => arr.filter((v,i) => arr.indexOf(v) === i)
    payload = x(payload);
}