/* eslint-disable */
import {ISegmentCore,ISegment,ISegmentListWithAndFilter } from "./IDemographicsModel";

export class Demographics implements ISegmentCore{
    segmentId: number;
    isAndFiltered:boolean;  // near future, if plan to break and auto select "AND" 
    isORFiltered:boolean;  // near future, if plan to break and auto select "OR" 
    PartOneSegement:ISegmentListWithAndFilter;
    // private PartTwoSegement:ISegmentListWithORFilter;
    constructor() {
        this.segmentId = 1234;
        this.PartOneSegement = {SegmentSelectedList:{segmentList:[]}, SegmentFilterList:{segmentList:[]}};
        this.isAndFiltered = false;
        this.isORFiltered = false;
    }

    public setDemographicsResponse(respObj:any){

    }

    public getSegmentPayloadForDemographics(segIdOrderIdMap:any) {
        let payload = JSON.parse(JSON.stringify(this.PartOneSegement.SegmentSelectedList.segmentList));
        let orderId="orderId";
        payload.map((obj:any,i:any) => {
            if(segIdOrderIdMap)
                obj[orderId] = segIdOrderIdMap[obj.segmentId];
        })
        return payload;
    }

    public getPayloadForDemographics(){
        return this.PartOneSegement.SegmentSelectedList;
    }
 
    public setSegementInAND(reqObj:any) {
        let segment = <ISegment>{};
        segment.segmentId = reqObj.segmentId;
        segment.segmentName = reqObj.segmentName;
        segment.segmentLabel = reqObj.segmentLabel;
        segment.segmentCategory = "Demographics";
        segment.linkDirection = reqObj.linkDirection;
        this.PartOneSegement.SegmentSelectedList.segmentList.push(segment);
    }

    public removeSegementInAND(index:any) {
        this.PartOneSegement.SegmentSelectedList.segmentList.splice(index,1);
    }

    public setSegementInOR() {

    }
}

export default Demographics;