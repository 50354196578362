import PageSection from './PageSection'
import SlideImage from '../utils//SlideImage'

class Slides {
  filename: string
  section: PageSection
  images: Array<SlideImage>

  constructor() {
    this.filename = ''
    this.section = PageSection.Unknown
    this.images = []
  }
}
export default Slides