import moment from "moment";

/* eslint-disable */
export function convertToUTCDateForYYYYMMDD(date: any) {
  if (date) {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + 1);
    const month = newDate.getMonth() + 1;
    const mnth = month.toString().length === 1 ? "0" + month : month;
    const day =
      newDate.getDate().toString().length === 1
        ? "0" + newDate.getDate()
        : newDate.getDate();
    return newDate.getFullYear() + "-" + mnth + "-" + day;
  }
  return "";
}
export function isValidDateRangeWithFlag(notification: any) {
  let resp = false;
  if (
    notification.hasOwnProperty("selected") &&
    (notification.selected === "Y" || notification.selected === true)
  ) {
    if (notification.hasOwnProperty("startTs") && notification.startTs) {
      const utcDate = convertToDate(notification.startTs, true);
      const utcEndDate = convertToDate(notification.endTs, true);
      const isToday = compareDates(new Date(), utcDate);
      const isEndDayToday = compareDates(new Date(), utcEndDate);
      if (isToday === 0 || isEndDayToday === 0) {
        resp = true;
      } else if (isToday < 0 && isEndDayToday >= 1) {
        resp = true;
      }
    }
  }
  return resp;
}

export function convertToDate(date: any, toUTC = false) {
  if (date && date !== "") {
    let crdate = new Date();
    if (typeof date == "string" && date.includes("-")) {
      crdate = new Date(date.replace(/-/g, "/"));
      crdate.setUTCHours(0, 0, 0, 0);
    } else {
      crdate = new Date(Number(date));
    }
    const month = crdate.getMonth() + 1;
    const mnth = month <= 9 ? "0" + month : month;
    if (toUTC) {
      crdate.setDate(crdate.getDate() + 1);
    }
    const day =
      crdate.getDate() <= 9 ? "0" + crdate.getDate() : crdate.getDate();
    const respDate = crdate.getFullYear() + "-" + mnth + "-" + day;
    return respDate;
  }
  return "";
}
function padTo2Digits(num: any) {
  return String(num).padStart(2, "0");
}
export function convertToDateTime(date: any, toUTC = false) {
  if (date && date !== "") {
    const crdate = new Date(date);
    crdate.setUTCHours(0, 0, 0, 0);
    const month = crdate.getMonth() + 1;
    const mnth = month <= 9 ? "0" + month : month;
    if (toUTC) {
      crdate.setDate(crdate.getDate() + 1);
    }
    const day =
      crdate.getDate() <= 9 ? "0" + crdate.getDate() : crdate.getDate();
    const respDate =
      crdate.getFullYear() +
      "-" +
      mnth +
      "-" +
      day +
      " " +
      padTo2Digits(crdate.getHours()) +
      ":" +
      padTo2Digits(crdate.getMinutes());
    return respDate;
  }
  return "";
}
export function formatDate(date) {
  return moment(date).format("MM/DD/YYYY hh:mm:ss");
}
export function compareDates(date1: any, date2: any) {
  if (date1 && date2 && date1 !== "" && date2 !== "") {
    date1 = new Date(date1);
    date1.setUTCHours(0, 0, 0, 0);
    date1.setDate(date1.getDate() + 1);
    date2 = new Date(date2);
    date2.setUTCHours(0, 0, 0, 0);
    date2.setDate(date2.getDate() + 1);
    var one_day = 1000 * 60 * 60 * 24;
    var date1_ms = date1.getTime();
    var date2_ms = date2.getTime();
    var difference_ms = date2_ms - date1_ms;
    return Math.round(difference_ms / one_day);
  }
  return false;
}
