/* eslint-disable */
export function getTomorrowsDate() {
    const reqDate = new Date();

    reqDate.setDate(reqDate.getDate() + 5);
    const month = reqDate.getMonth() + 1;
    const day = reqDate.getDate().toString().length === 1 ? "0" + reqDate.getDate() : reqDate.getDate();
    const mnth = month.toString().length === 1 ? "0" + month : month;
    return reqDate.getFullYear() + "-" + mnth + "-" + day;
}

export function getEndDate(campEndDate:any) {
    const reportEndDate = new Date(campEndDate);
    const reqDate = new Date();
    if (findDiffInDays(reqDate,reportEndDate)<=0) {
        return campEndDate;
    }
    
    reqDate.setHours(0,0,0,0);
    reqDate.setDate(reqDate.getDate()-1);
    const month = reqDate.getMonth() + 1;
    const day = reqDate.getDate().toString().length === 1 ? "0" + reqDate.getDate() : reqDate.getDate();
    const mnth = month.toString().length === 1 ? "0" + month : month;
    return reqDate.getFullYear() + "-" + mnth + "-" + day;
}

export function getAlertDate() {
    const reqDate = new Date();
    reqDate.setDate(reqDate.getDate());
    const month = reqDate.getMonth() + 1;
    const day = reqDate.getDate().toString().length === 1 ? "0" + reqDate.getDate() : reqDate.getDate();
    const mnth = month.toString().length === 1 ? "0" + month : month;
    return reqDate.getFullYear() + "-" + mnth + "-" + day;
}
export function getNewEndDate(date: any) {
    const reqDate = new Date(date);
    reqDate.setDate(reqDate.getDate());
    const month = reqDate.getMonth() + 1;
    const mnth = month.toString().length === 1 ? "0" + month : month;
    const day = reqDate.getDate().toString().length === 1 ? "0" + reqDate.getDate() : reqDate.getDate();
    return reqDate.getFullYear() + "-" + mnth + "-" + day;
}

export function getEightYrsEndDate(date: any) {
    let reqDate = new Date();
    if (date !==null) {
        reqDate = new Date(date);
    }
    reqDate.setDate(reqDate.getDate()+2920);
    const month = reqDate.getMonth() + 1;
    const mnth = month.toString().length === 1 ? "0" + month : month;
    const day = reqDate.getDate().toString().length === 1 ? "0" + reqDate.getDate() : reqDate.getDate();
    return reqDate.getFullYear() + "-" + mnth + "-" + day;
}


export function convertToUTCDate(date: any) {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate()+1)
    const month = newDate.getMonth() + 1;
    const mnth = month.toString().length === 1 ? "0" + month : month;
    const day = newDate.getDate().toString().length === 1 ? "0" + newDate.getDate() : newDate.getDate();
    return mnth+ "-" + day + "-" + newDate.getFullYear() ;
}

export function convertToUTCDateForYYYYMMDD(date: any) {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate()+1)
    const month = newDate.getMonth() + 1;
    const mnth = month.toString().length === 1 ? "0" + month : month;
    const day = newDate.getDate().toString().length === 1 ? "0" + newDate.getDate() : newDate.getDate();
    return newDate.getFullYear()+  "-" + mnth +"-" + day ;
}

export function getMaxCampaignDate() {
    const reqDate = new Date();
    reqDate.setDate(reqDate.getDate() + 2920);
    const month = reqDate.getMonth() + 1;
    const mnth = month.toString().length === 1 ? "0" + month : month;
    const day = reqDate.getDate().toString().length === 1 ? "0" + reqDate.getDate() : reqDate.getDate();
    return reqDate.getFullYear() + "-" + mnth + "-" + day;
}

export function getNewMaxCampaignDate(date: any) {
    if (date && date !== "") {
        const reqDate = new Date(date);
        reqDate.setDate(reqDate.getDate() + 2920);
        const month = reqDate.getMonth() + 1;
        const mnth = month.toString().length === 1 ? "0" + month : month;
        const day = reqDate.getDate().toString().length === 1 ? "0" + reqDate.getDate() : reqDate.getDate();
        return reqDate.getFullYear() + "-" + mnth + "-" + day;
    }
    return getMaxCampaignDate();
}

export function findDiffInDays(date1: any, date2: any) {
    
    if (date1 && date2 && date1 !== "" && date2 !== "") {
        date1 = new Date(date1);
        date1.setHours(0,0,0,0)
        date2 = new Date(date2);
        date2.setHours(0,0,0,0);
        var one_day = 1000 * 60 * 60 * 24;

        // Convert both dates to milliseconds
        var date1_ms = date1.getTime();
        var date2_ms = date2.getTime();

        // Calculate the difference in milliseconds
        var difference_ms = date2_ms - date1_ms;
        return Math.round(difference_ms / one_day);
    }
    return -1;

}

export function isPastDateSelected(date1: any, date2: any, diff: any) {
    if (date1 && date2 && date1 !== "" && date2 !== "") {
        date1 = new Date(date1);
        date2 = new Date(date2);
        if (date1 < new Date() || date2 < new Date() || date1 > date2) {
            return -1;
        }
    }
    return diff;

}
 
export function isEmptyOrSpaces(str:any){
    return str === null || str.match(/^ *$/) !== null;
}

export function getReportRangeDate(date1:any,date2:any) {
    if (date1 && date1 !== "" && date2 != "") {

        const reqDate1 = new Date(date1);
        reqDate1.setHours(0,0,0,0)
        reqDate1.setDate(reqDate1.getDate()+1)
        const reqDate2 = new Date(date2);
        reqDate2.setHours(0,0,0,0)
        reqDate2.setDate(reqDate2.getDate()+1)
        let result = getMonthName(reqDate1) + " " + reqDate1.getDate() + "-" + getMonthName(reqDate2) + " " + reqDate2.getDate() + " " + reqDate2.getFullYear();
        reqDate2.setMonth(reqDate2.getMonth()+1)
        reqDate1.setMonth(reqDate1.getMonth()+1)
        return result;
    }
    return null;
}

function getMonthName(dt:any){
const mlist = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec" ];
  return mlist[dt.getMonth()];
};

export function getMonthTODisplay(date:any) {
    const currDate = new Date();
    if (date.getFullYear() == currDate.getFullYear() && currDate.getMonth() < date.getMonth()) {
        return currDate.getMonth();
    }
    else{
        return date.getMonth();
    }
}



export function getPerfReportTItle(orderEndDate:any,reportEndDate:any,orderStartDate:any,reportStartDate:any) {
 
     if (findDiffInDays(orderEndDate,reportEndDate)==0) {
        if (findDiffInDays(orderStartDate,reportStartDate)==0) {
            return "Final Report"
        }
        else {
            return "Partial Report"
        }
    }
    else{
        return "Mid Report"
    }
    
}