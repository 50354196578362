/* eslint-disable */
import { createStore, applyMiddleware } from "redux";
import reducers from "../reducer/reduireducers";
import { AppConstants, Configs } from "../../ConstConfig";
import { AxiosClient } from "../ClientServices/AxiosClient";
import { getAuthContext } from "../../Login/MSAL/msalConfig";
import { UserOps } from "../../ConstConfig/UserOps";
import Logger from "../Logger/Logger";
import { getYesterday } from "../../Dashboard/utils/dashboardValidation";
import {
  getActionTypeForConfirmOperation,
  getUserSelectedOperaion,
  getTraitSelectedOperaion,
  getActionType,
  getReportingStateOperaion,
  isAudienceInvokedWithNew,
  isWizardPageStateExist,
  setAllFalse,
  isDashboardChangeAPI,
  isDashboardToBeInvoked,
  getDashboardPayload,
  isCampgainCreateInited,
} from "./helper";
import { StoreUitls } from "./StoreUtils";
import { AdminStoreUitls } from "./AdminStoreUtils.";
import reduiReportingMiddleWare from "../middleware/redui-reporting";
import reduiQuerySegmentMiddleWare from "../middleware/reduiQuerySegmentMiddleWare";
import reduiTVOrderCampaignMiddleware from "../middleware/reduiTVOrderCampaignMiddleware";
import reduiCoreConfigMiddleWare from "../middleware/reduiCoreConfigMiddleWare";
import reduiDomoConfigMiddleWare from "../middleware/reduiDomoConfigMiddleWare";
import reduiDomoQueryConfigMiddleware from "../middleware/reduiDomoQueryConfigMiddleware";
import reduiCRMUploaderMiddleWare from "../middleware/redui-crmuploader";
import reduiAudienceMiddleWare from "../middleware/redui-audiencemiddleware";
import reduiLinOptsMiddleWare from "../middleware/redui-linOpts";
import reduiExcelReporting from "../middleware/redui-ExcelReporting";
import reduiPowerPoint from "../middleware/redui-powerPoint";
const RedUIStore = (preloadedState: any) => {
  const authContext = getAuthContext();
  const urlConfig = new Configs();
  const configSet = process.env.REACT_APP_LOGIN_CONFIG;
  const store = createStore(
    reducers,
    applyMiddleware(
      reduiCRMUploaderMiddleWare,
      reduiReportingMiddleWare,
      reduiQuerySegmentMiddleWare,
      reduiTVOrderCampaignMiddleware,
      reduiCoreConfigMiddleWare,
      reduiDomoConfigMiddleWare,
      reduiDomoQueryConfigMiddleware,
      reduiAudienceMiddleWare,
      reduiLinOptsMiddleWare,
      reduiExcelReporting,
      reduiPowerPoint
    )
  );

  const clnt = new AxiosClient(store);

  const adminStoreUtil = new AdminStoreUitls(
    store,
    clnt,
    urlConfig,
    authContext
  );
  const storeUtil = new StoreUitls(store, clnt, urlConfig, adminStoreUtil);

  if (configSet !== "LOCAL") {
  } else {
    storeUtil.login(true);
  }

  store.subscribe(() => {
    let actionType;
    if (store.getState()) {
      actionType =
        store.getState().userAuth.hasOwnProperty("data") &&
        store.getState().userAuth.isLoginSuccessful !== ""
          ? store.getState().userAuth.data.isLoginSuccessful
          : "";
    }
    if (store.getState().WizardStateHandle.hasOwnProperty("data")) {
      const WizardStateHandleData = store.getState().WizardStateHandle;
      actionType =
        WizardStateHandleData.data.hasOwnProperty("userAction") &&
        WizardStateHandleData.data.userAction !== ""
          ? WizardStateHandleData.data.userAction
          : actionType;
    }
    if (store.getState().configState.hasOwnProperty("Dialog")) {
      const DialogData = store.getState().configState.Dialog;
      actionType = getActionTypeForConfirmOperation(DialogData, actionType);
    }
    if (isCampgainCreateInited(store.getState().CampaignState)) {
      actionType = UserOps.SEND_EMAIL;
    }
    if (isAudienceInvokedWithNew(store.getState().slickState)) {
      if (isWizardPageStateExist(store.getState().WizardPageStats)) {
        store.getState().WizardPageStats.data.WizardPage.editAudienceAction = 0;
      }
      if (
        store.getState().WizardPageStats &&
        store.getState().WizardPageStats.hasOwnProperty("data") &&
        store.getState().WizardPageStats.data.hasOwnProperty("WizardPage")
      ) {
        store.getState().WizardPageStats.data.WizardPage.toggleSwith = 0;
        store.getState().WizardPageStats.data.WizardPage.isDropZonContainsList =
          true;
      }

      setAllFalse(store.getState().WizardPageStats);
      Logger.getInstance().printWarnLogs("New Camp cli created");
      store.getState().slickState.data.source = "";
    }
    if (
      isDashboardChangeAPI(store.getState().DashboardState) ||
      isDashboardToBeInvoked(store.getState().slickState)
    ) {
      let dState = store.getState().DashboardState;
      if (dState.hasOwnProperty("count")) {
        dState = getDashboardPayload({
          date: store
            .getState()
            .DashboardPreflightDataState.hasOwnProperty("DatesAvailable")
            ? store.getState().DashboardPreflightDataState.DatesAvailable[0]
            : getYesterday(),
        });
      }
    }

    actionType = getActionType(store.getState().slickState, actionType);
    actionType = getUserSelectedOperaion(
      store.getState().AdminUserAddControlState,
      actionType
    );
    actionType = getTraitSelectedOperaion(
      store.getState().AdminUserTraitControlState,
      actionType
    );
    actionType = getTraitSelectedOperaion(
      store.getState().AdminUserTraitEditControlState,
      actionType
    );
    actionType = getTraitSelectedOperaion(
      store.getState().AdminCynchAttribOperationState,
      actionType
    );
    actionType = getReportingStateOperaion(
      store.getState().ReportingConfigState,
      actionType
    );

    switch (actionType) {
      case AppConstants.Inited:
        store.getState().userAuth.data.isLoginSuccessful = "";
        if (
          store.getState().userAuth.data.authContext.idToken.preferredName != ""
        ) {
          storeUtil.login(
            true,
            store.getState().userAuth.data.authContext.idToken.preferredName,
            store.getState().userAuth.data.authContext.idToken.rawIdToken
          );
        }
        break;
      case UserOps.DELETE_USER:
        {
          actionType = "";
          try {
            adminStoreUtil.deleteUser(
              store.getState().configState.Dialog.content.userId
            );
          } catch (e) {}
        }
        break;
      case UserOps.DELETE_TRAITS:
        {
          actionType = "";
          try {
            adminStoreUtil.deleteTrait(
              store.getState().configState.Dialog.content.segmentId
            );
          } catch (e) {}
        }
        break;
      case UserOps.ADD_USER:
        {
          actionType = "";
          try {
            adminStoreUtil.addUser(store.getState().AdminUserAddControlState);
          } catch (e) {}
        }
        break;
      case UserOps.EDIT_USER:
        {
          actionType = "";
          try {
            adminStoreUtil.updateUser(
              store.getState().AdminUserAddControlState
            );
          } catch (e) {}
        }
        break;
      case UserOps.ADD_TRAITS:
        {
          actionType = "";
          try {
            adminStoreUtil.addAudienceTrait(
              store.getState().AdminUserTraitControlState
            );
          } catch (e) {}
        }
        break;
      case UserOps.EDIT_TRAITS:
        {
          actionType = "";
          try {
            adminStoreUtil.updateAudienceTrait(
              store.getState().AdminUserTraitControlState
            );
          } catch (e) {}
        }
        break;
      case UserOps.TRAIT_MOVE_UP:
        {
          actionType = "";
          try {
            adminStoreUtil.sortAudienceTrait(
              store.getState().AdminUserTraitEditControlState,
              UserOps.TRAIT_MOVE_UP
            );
            store.getState().AdminUserTraitEditControlState.userAction = "";
          } catch (e) {}
        }
        break;
      case UserOps.TRAIT_MOVE_DOWN:
        {
          actionType = "";
          try {
            adminStoreUtil.sortAudienceTrait(
              store.getState().AdminUserTraitEditControlState,
              UserOps.TRAIT_MOVE_DOWN
            );
            store.getState().AdminUserTraitEditControlState.userAction = "";
          } catch (e) {}
        }
        break;
      case UserOps.GET_USERS:
        {
          actionType = "";
          store.getState().slickState.data.loadAPIData = false;
          store.getState().slickState.data.source = "";
          adminStoreUtil.loadAllUsers(true);
        }
        break;
      case UserOps.GET_TRAITS:
        {
          actionType = "";
          store.getState().slickState.data.loadAPIData = false;
          store.getState().slickState.data.source = "";
          adminStoreUtil.loadAllTrait(true);
        }
        break;
      case UserOps.GET_CRMList:
        {
          actionType = "";
          store.getState().slickState.data.loadAPIData = false;
          store.getState().slickState.data.source = "";
          adminStoreUtil.loadCRMStats(true);
        }
        break;
      case UserOps.REPORTING_STATUS:
        {
          actionType = "";
          store.getState().slickState.data.loadAPIData = false;
          store.getState().slickState.data.source = "";
        }
        break;
      case UserOps.SET_REPORTING_STATUS:
        {
          actionType = "";
          store.getState().ReportingConfigState.userAction = null;
          adminStoreUtil.saveReportingStatus(
            store.getState().ReportingConfigState
          );
        }
        break;

      case UserOps.DELETE_CYNCHATTRIB:
        {
          actionType = "";
          try {
            adminStoreUtil.deleteCynchAttribute(
              store.getState().configState.Dialog.content.cynchAttributeId
            );
          } catch (e) {}
        }
        break;

      case UserOps.DELETE_AUDIENCE_SEGMENT_QUERY:
        {
          actionType = "";
          try {
            adminStoreUtil.deleteSegmentQuery(
              store.getState().configState.Dialog.content.id
            );
          } catch (e) {}
        }
        break;

      case UserOps.ADD_ATTRIBUTE:
        {
          actionType = "";
          try {
            adminStoreUtil.addCynchAttributes(
              store.getState().AdminCynchAttribOperationState
            );
          } catch (e) {}
        }
        break;
      case UserOps.EDIT_ATTRIBUTE:
        {
          actionType = "";
          try {
            adminStoreUtil.addCynchAttributes(
              store.getState().AdminCynchAttribOperationState
            );
          } catch (e) {}
        }

        break;

      case UserOps.DELETE_AUDIENCE_SEGMENT:
        {
          actionType = "";
          storeUtil.deleteAudience(
            store.getState().configState.Dialog.content.id
          );
        }
        break;
      case UserOps.SAVE_AUDIENCE:
        {
          storeUtil.saveAudience(store.getState().WizardStateHandle.data);
          store.getState().WizardStateHandle.data.userAction = "";
          actionType = "";
        }
        break;
      case UserOps.OVERWRITE_AUDIENCE:
        {
          const DialogData = store.getState().configState.Dialog;
          if (
            DialogData.hasOwnProperty("content") &&
            DialogData.content &&
            DialogData.content.hasOwnProperty("overwriteAudiece") &&
            DialogData.content.overwriteAudiece
          ) {
            store.getState().configState.Dialog.content.overwriteAudiece =
              false;
          }
          storeUtil.overWriteAudience(
            store.getState().WizardStateHandle.data,
            true
          );
          store.getState().WizardStateHandle.data.userAction = "";
          actionType = "";
        }
        break;
      case UserOps.EDIT_AUDIENCE:
        {
          actionType = "";
          store.getState().configState.Dialog.editAudienceAction = false;
          storeUtil.FetchAudiences(
            store.getState().configState.Dialog.content.id,
            store.getState().configState.Dialog.history
          );
        }
        break;
      case UserOps.LOAD_AUDIENCE:
        {
          actionType = "";
          store.getState().slickState.data.loadAPIData = false;
          storeUtil.getSegments();
        }
        break;
      case UserOps.SEND_EMAIL:
        {
          actionType = "";
          storeUtil.sendEmail(
            store.getState().CampaignState.payload,
            store.getState().CampaignState.histry
          );
        }
        break;
      default:
        break;
    }
  });
  return store;
};

export default RedUIStore;
