import { List } from 'immutable';

export class ReportingRangeModel {
    reportsRange: any;
    userSelectedRange:any;
    rowId: any;
    constructor(list: any) {
        this.reportsRange = [];
        this.buildModel(list);
        this.rowId = -1;
        this.userSelectedRange = null;
    }

    public resetReportingRangeModel() {
        this.userSelectedRange = {displayDate : "" ,dateValue: "", reportType : "",fileId:""};
        this.reportsRange = [];
    }

    public buildModel(list: any) {
        this.reportsRange = [];
        if(list && list.toArray().length > 0) {
            list = list.toArray();
            list.map((cell: any, i: any) => {
                if(cell && cell.hasOwnProperty("displayDate") && cell.displayDate !="" && cell.hasOwnProperty("dateValue") && cell.dateValue) {
                    this.reportsRange.push(cell);
                }
           })
           if(list[0].hasOwnProperty("displayDate") && list[0].displayDate !="" && list[0].hasOwnProperty("dateValue") && list[0].dateValue && list[0].dateValue !="") {
                this.userSelectedRange = list[0];
           }
        }
    }
 
    public setUserSelectedRange(range:any) {
        this.userSelectedRange = range;
    }

    public getUserSelectedRange() {
        if(!this.userSelectedRange || (this.userSelectedRange.hasOwnProperty("dateValue") && this.userSelectedRange.dateValue==="")) {
            this.userSelectedRange = {displayDate : "" ,dateValue: "", reportType : "",fileId:""};
        }
        return this.userSelectedRange;
    }

    public isReportingRangeListEmpty() {
        if (this.reportsRange.length <= 0) {
            return true;
        }
        return false;
    }

    public getReportingRangeList() {
        return List(this.reportsRange);
    }

    public fillAndResetReportList(payload: any) {
        this.buildModel(payload);
    }
}

export default ReportingRangeModel;