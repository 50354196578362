import PageSection from '../Model/PageSection'

// TODO: these relationships are spread through the code...
class DefaultHeading {

    static forSection(section: PageSection): string {
        switch (section) {
            case PageSection.Conto:
                return 'CONTOBOX RESULTS'
            case PageSection.DigitalAudio:
                return 'DIGITAL AUDIO RESULTS'
            case PageSection.Display:
                return 'VIDEO/DISPLAY RESULTS'
            case PageSection.VideoSuite:
                 return 'VIDEO INSIGHT RESULTS'         
            case PageSection.Facebook:
                return 'FACEBOOK/INSTAGRAM RESULTS'
            case PageSection.RedX:
                return 'R.E.D. X RESULTS'
            case PageSection.Twitter:
                return 'TWITTER RESULTS'            
            case PageSection.Eblast:
                return 'EBLAST RESULTS'            
            case PageSection.Vod:
                return 'VIDEO ON DEMAND RESULTS'
            case PageSection.Ctv:
                return 'CTV/OTT RESULTS'
            case PageSection.NewsLetter:
                return 'NEWSLETTER RESULTS'   
     
        }
        return ''
    }
}
export default DefaultHeading
