/* eslint-disable */
import * as React from "react";
import { connect } from "react-redux";
import { requestCRMStat } from "../../Actions";
import "../../bootstrap/css/bootstrap.css";
import "../../CSS/redUI-styles.css";
import { Configs } from "../../ConstConfig";
import Graph from "./Graph";
import TableComponent from "src/CommonComponent/Table/TableComponent";
import { List } from "immutable";
import { formatBytes } from "src/CRMUploader/utils/CRMUploaderUtil";
import { FormattedNumber } from "react-intl";
import UserOps from "src/ConstConfig/UserOps";

class Dashboard extends React.Component<IDashboard, {}> {
  state: any;
  sortedColumn: any;
  tableProps: any;
  config: any;
  constructor(props: any) {
    super(props);
    this.state = {
      isCRMUploader:
        props.UserAssignedRole?.roles?.indexOf(UserOps.CRMUPLOADER) >= 0
          ? true
          : false,
    };
    this.uploadData = this.uploadData.bind(this);
    this.getDateSortedFormat = this.getDateSortedFormat.bind(this);
    this.getSortedStyle = this.getSortedStyle.bind(this);
    this.getAllColView = this.getAllColView.bind(this);
    this.config = new Configs();
    this.sortedColumn = "creationTs";
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.state.isCRMUploader) {
    }
  }

  public uploadData() {
    this.props.history.push("/CRMUploader");
  }


  getSortedStyle(cell: any, row: any, formatExtraData: any, index: any) {
    const style =
      formatExtraData === this.sortedColumn
        ? " boldText wrapWords"
        : "wrapWords";
    if (formatExtraData === "fileStatus") {
      const formattedFileStatus = {
        FILE_UPLOADED: { value: "Uploaded" },
        FILE_PROCESSING: { value: "Processing" },
        FILE_PROCESSED: { value: "Processed" },
        FILE_FAILED: { value: "Failed" },
      };
      if (cell) {
        cell = formattedFileStatus[cell].value;
      }
    }
    const val = cell ? cell.toString() : "";
    return <span className={style}>{val}</span>;
  }

  getFormattedFileSize(cell: any, row: any, formatExtraData: any, index: any) {
    const val = cell ? cell.toString() : "";
    return <span>{formatBytes(val)}</span>;
  }

  getFormattedNumber(cell: any, row: any, formatExtraData: any, index: any) {
    if (cell && cell !== "") {
      return (
        <span>
          <FormattedNumber
            value={cell.toString()}
            style="decimal"
            minimumFractionDigits={0}
            maximumFractionDigits={0}
          />
        </span>
      );
    }
    return null;
  }

  getDateSortedFormat(cell: any, row: any, formatExtraData: any, index: any) {
    const style = formatExtraData === this.sortedColumn ? " boldText " : "";
    const respDate = cell.toString();
    return <span className={style}>{respDate}</span>;
  }

  getAllColView(cell: any, row: any, enumObject: any) {
    function getStatus(cell: any) {
      const formattedFileStatus = {
        FILE_UPLOADED: { value: "Uploaded" },
        FILE_PROCESSING: { value: "Processing" },
        FILE_PROCESSED: { value: "Processed" },
        FILE_FAILED: { value: "Failed" },
      };
      if (cell) {
        cell = formattedFileStatus[cell].value;
      }
      return cell;
    }

    return (
      <div className="listA">
        <div className="row-flex">
          <div className="col-md-4 pl-0 pr-0">
            <span>Uploaded On: </span>
            <span className="wrapWords"> {row.creationTs}</span>
          </div>
          <div className="col-md-4 pl-0 pr-0">
            <span>File Name: </span>
            <span className="wrapWords"> {row.origFileName}</span>
          </div>
          <div className="col-md-4 pl-0 pr-0">
            <span>File Size: </span>
            <span> {formatBytes(row.fileSize)}</span>
          </div>
          <div className="col-md-4 pl-0 pr-0">
            <span>Hash Count: </span>
            <span> {row.hashCount}</span>
          </div>
          <div className="col-md-4 pl-0 pr-0">
            <span>Status: </span>
            <span> {getStatus(row.fileStatus)}</span>
          </div>
        </div>
      </div>
    );
  }

  public render() {
    const tableInput = {
      tableContent: this.props.crmItemList,
      tableContents: [
        {
          dataField: "logId",
          dataSort: true,
          dataFormatMethod: this.getSortedStyle,
          formatExtraData: "logId",
          Title: "ID",
          hidden: true,
          isKey: true,
        },

        {
          dataField: "creationTs",
          dataSort: false,
          dataFormatMethod: this.getSortedStyle,
          formatExtraData: "creationTs",
          tdStyle: { border: "1px solid #414042!" },
          Title: "Uploaded ON",
          hidden: false,
          isKey: false,
        },

        {
          dataField: "origFileName",
          dataSort: false,
          dataFormatMethod: this.getSortedStyle,
          formatExtraData: "origFileName",
          tdStyle: {},
          Title: "File Name",
          isKey: false,
        },
        {
          dataField: "fileSize",
          dataSort: false,
          dataFormatMethod: this.getFormattedFileSize,
          formatExtraData: "fileSize",
          tdStyle: {},
          Title: "File Size",
          isKey: false,
        },
        {
          dataField: "hashCount",
          dataSort: false,
          dataFormatMethod: this.getFormattedNumber,
          formatExtraData: "hashCount",
          tdStyle: {},
          Title: "Hash Count",
          isKey: false,
        },
        {
          dataField: "fileStatus",
          dataSort: false,
          dataFormatMethod: this.getSortedStyle,
          formatExtraData: "fileStatus",
          tdStyle: {},
          Title: "Status",
          isKey: false,
        },
      ],
      pagination: false,
      search: false,
      tableOptions: {
        hidePageListOnlyOnePage: true,
        hideSizePerPage: true,
        sizePerPage: 5, // which size per page you want to locate as default
      },
      bordered: false,
      multiColumnSearch: false,
    };
    const tableInputSmall = {
      tableContent: this.props.crmItemList,
      tableContents: [
        {
          dataField: "origFileName",
          dataSort: true,
          dataFormatMethod: this.getAllColView,
          formatExtraData: "origFileName",
          Title: " CRM Upload details",
          hidden: false,
          isKey: true,
        },
      ],
      pagination: false,
      search: false,
      tableOptions: {
        hidePageListOnlyOnePage: true,
        hideSizePerPage: true,
        sizePerPage: 5, // which size per page you want to locate as default
      },
      bordered: false,
      multiColumnSearch: false,
    };
    return (
      <main role="main" className="container-fluid">
        {!this.state.isCRMUploader && <div className="pt-16"></div>}
        {this.state.isCRMUploader && (
          <div className="col-12">
            <table
              className="pt-16 mb-4"
              style={{ width: "100%", marginTop: "70px" }}
            >
              <tr className="display-sm-grid">
                <td className="width-xl width-lg width-md width-sm">
                  <label htmlFor="formName">RECENT UPLOADS</label>
                </td>
                <td className="float-xl-right float-md-right float-mmd-right float-sm-none spacer-sm-top">
                  <a
                    className="btn btnPrimary "
                    href="javascript:void(0);"
                    role="button"
                    onClick={this.uploadData}
                  >
                    Upload Data
                  </a>
                </td>
              </tr>
            </table>
            <div className="d-desk-block d-ipad-none d-mb-none table-responsive-xl tableRedui">
              <TableComponent tableInput={tableInput} />
              <div className="hSpacer20" />
            </div>
            <div className="d-desk-none d-ipad-block d-mb-block iPadLandscape-block">
              <TableComponent tableInput={tableInputSmall} />
              <div className="hSpacer20" />
            </div>
          </div>
        )}
        <Graph />
      </main>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    crmItemList: state.CRMSListState.hasOwnProperty("CRMSList")
      ? state.CRMSListState.CRMSList.slice(0, 5)
      : List([]),
    UserAssignedRole:
      state.AdminUserControlState.hasOwnProperty("UserProfile") &&
      state.AdminUserControlState.UserProfile
        ? state.AdminUserControlState.UserProfile
        : {
            email: "",
            company: null,
            userId: null,
            firstName: "",
            lastName: "",
            roles: ["User"],
          },
  };
}

export default connect(mapStateToProps, (dispatch) => {
  return {
    initCRMList(selectedItem: any) {
      const dummyUserObj = { type: UserOps.LISTALL_CRM, data: selectedItem };
      dispatch(requestCRMStat(dummyUserObj));
    },
  };
})(Dashboard);

interface IDashboard extends React.FC<any> {
  propsFromStore: any;
  handleChange: any;
  loggingIn: any;
  handlePass: any;
  history: any;
  crmItemList?: any;
  initCRMList?: any;
}
