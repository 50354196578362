import { List } from 'immutable';

export class ReportingModel {
    reportsList: any;
    rowId: any;
    constructor(list: any) {
        this.reportsList = [];
        this.buildModel(list);
        this.rowId = -1;
    }

    public buildModel(list: any) {
        this.reportsList = [];
        let contxt = this;
        if (list instanceof Object)
            for (let key in list) {
                    if(list[key] && list[key]?.proposalId) {
                            let fObject = contxt.extractFileName(list[key]);
                            contxt.setReportingListItems(list[key], fObject);
                    }
                
            }
    }

    public getReportingListPerRow(reportingRowID: any) {

    }

    public isReportingListEmpty() {
        if (this.reportsList.length <= 0) {
            return true;
        }
        return false;
    }

    public getReportingList() {
        return List(this.reportsList);
    }

    public fillAndResetReportList(payload: any) {
        this.buildModel(payload);
    }

    public setReportingItem(reptItem: any, fObject: any) {
        let tImpression = Number(reptItem.totalImpressions);

        // let pacing = parseFloat(reptItem.pacing);
        let pacing = (reptItem.pacing> 0)?(reptItem.pacing.toFixed(2)+'%'): '0.00%';
        //let pacing = parseFloat(reptItem.pacing) * 100;
        let tClick = Number(reptItem.totalClicks);
        let pacingPer = (parseFloat(reptItem.pacing) / 100);
        if (pacingPer <= 0) {
            pacingPer = 1;
        }
        
        let bImpression =  Math.round(tImpression / pacingPer);
        let ctrs = tImpression > 0 ? (((tClick / tImpression)*100).toFixed(2)+'%') : '0.00%';
        
            // tClick = 1;
        
        let proposalId = "";
        this.rowId++;
        if (reptItem.proposalId && reptItem.proposalId !== "") {
            proposalId = reptItem.proposalId;
        }
        else {
            proposalId = fObject.propId;
        }
        
         let reportEntryObj = {
            rowId: this.rowId,
            fileId: reptItem.fileId,
            fileName: fObject.fileName,
            zipFileName: reptItem.zipFileName,
            fileCreateDate: reptItem.fileCreateDate,
            fileOwner: reptItem.fileOwner,
            ownerGroup: reptItem.ownerGroup,
            fileAvailableOnWl: reptItem.fileAvailableOnWl,
            pacing: pacing,
            totalImpressions: tImpression.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            totalClicks: tClick.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            totalConversions: reptItem.totalConversions,
            contractedAmount: reptItem.contractedAmount,
            actualAmount: reptItem.actualAmount,
            reportingPeriod: reptItem.reportingPeriod,
            proposalId: proposalId,
            reportType: reptItem.reportType,
            bookedImpression: bImpression.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            CTR: ctrs,
            isExcelAvailable: false,
            isPPTAvailable: false,
        };
        reportEntryObj.isExcelAvailable = fObject.excel;
        reportEntryObj.isPPTAvailable = fObject.ppt
        return reportEntryObj;
    }

    public setReportingListItems(reqObj: any, fObject: any) {
        let audienceItem = this.setReportingItem(reqObj, fObject);
        this.reportsList.push(audienceItem);
    }

    public extractFileName(row:any) {
        let fileObj = {fileName:"",excel:false,ppt:false, propId:""};
        let delemeterIndex = 0;
        if (row.hasOwnProperty("pptFileName") && row.pptFileName && row.pptFileName !== "") {
            delemeterIndex = -1;
            fileObj.fileName =  row.pptFileName.substring(delemeterIndex + 1, row.pptFileName.length-4);
            fileObj.propId = row.pptFileName.substring(0, delemeterIndex - 1);
            fileObj.ppt = true;
        }

        if (row.hasOwnProperty("excelFileName") && row.excelFileName && row.excelFileName !=="") {
            delemeterIndex = -1;
            fileObj.fileName =  row.excelFileName.substring(delemeterIndex + 1, row.excelFileName.length-4);
            fileObj.propId = row.excelFileName.substring(0, delemeterIndex - 1);
            fileObj.excel = true;
        }
        return fileObj;
    }
}

export default ReportingModel;