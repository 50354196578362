/* eslint-disable */
import { AxiosClient } from "../ClientServices/AxiosClient";
import { AudienceActionAdmin } from "src/Actions";
import { getAuthContext } from "src/Login/MSAL/msalConfig";
import AudiencesModel from "src/Admin/Model/AudiencesModel";

const reduiAudienceMiddleWare =
  (store: any) => (next: any) => (action: any) => {
    if (
      (action && action.hasOwnProperty("type")) ||
      action.type === "AUDIENCETRAITACTION"
    ) {
      const clnt = new AxiosClient(store);
      switch (action.type) {
        case "AUDIENCETRAITACTION":
          const reqObjectSeg = {
            authToken: getAuthContext().idToken.rawIdToken,
            url: action.payload.url,
          };
          clnt
            .getResponse(reqObjectSeg.url, reqObjectSeg)
            .then((returnVal: any) => {
              let audienceListAdmin = new AudiencesModel(returnVal);
              const respObj = {
                AudienceListAdmin: audienceListAdmin.getAudienceList(),
              };
              store.dispatch(AudienceActionAdmin(respObj));
            });
      }
    }
    return next(action);
  };

export default reduiAudienceMiddleWare;
