/* eslint-disable */
export class SortingModel {
    sortedColName: any;
  
    constructor(colName:any) {
      this.sortedColName = colName;
    }

    public setSortedColName(name:any) {
      
        this.sortedColName = name;
    }
    public getSortedColName() {
        return this.sortedColName;
    }
   
}

export default SortingModel;