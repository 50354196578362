import React, { Component, MouseEvent } from 'react'
import SlideImage from "../utils/SlideImage";
import '../../CSS/DeckImageDetail.css'


type Props = {
    image: SlideImage,
    onUpdate: (image: SlideImage) => void,
    thumbWidth: number
}

type State = {
    lightboxOpen: boolean
}

class DeckImageDetail extends Component<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {lightboxOpen: false}
        this.onCloseClick = this.onCloseClick.bind(this);
        this.toggleInclude = this.toggleInclude.bind(this);
        this.onImageClick = this.onImageClick.bind(this);
        this.onHeadingChange = this.onHeadingChange.bind(this);
    }

    toggleInclude(e: MouseEvent<HTMLAnchorElement>) {
        e.preventDefault()
        this.props.image.include = !this.props.image.include
        this.props.onUpdate(this.props.image)
    }

    onHeadingChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.props.image.slideHeading = e.target.value
    }


    onImageClick() {
        this.setState({lightboxOpen: true})
        // this.setState({lightboxOpen: false})
    }

    onCloseClick() {
        this.setState({lightboxOpen: false})
    }

    render() {
        return (
            <div className='deck-image-detail'>
                {this.state.lightboxOpen && 
                    <div className='modal' onClick={this.onCloseClick}>
                        <div className='modal-content'  style={{
                            backgroundImage: 'url(data:image/png;base64,' + btoa(Array.from(new Uint8Array(this.props.image.data)).map(b => String.fromCharCode(b)).join('')) + ')',
                            backgroundSize: 'contain'  
                            }} onClick={this.onCloseClick}  />
                               
                               
                                <div className="modal-footer float-right"> <button className='btn btnPrimary spacerL12' data-dismiss="modal"  onClick={this.onCloseClick}>Close</button></div>
                    </div>
                }
                <div style={{paddingTop: '16px'}}>
                    <div style={{float: 'left', width: this.props.thumbWidth + 'px'}}>
                        <img alt='deck slide' style={{cursor: 'zoom-in'}} onClick={this.onImageClick} src={this.props.image.base64Thumbnail} width={this.props.thumbWidth} />            
                    </div>
                    <div style={{paddingLeft: '16px', float: 'left'}}>
                        <div>
                            <a className={"btn mr-lg-2 " + (this.props.image.include ? 'btnSmall-primary' : 'btnSmallDarkGray')} onClick={this.toggleInclude} role="button" href="/">{this.props.image.include? 'Included' : 'Excluded'}</a>
                        </div>
                        <div>
                            <label>Slide heading: </label>
                            <input type='text' defaultValue={this.props.image.slideHeading} onChange={this.onHeadingChange} />
                        </div>
                    </div>
                    <div style={{clear: 'both'}}/>
                </div>
            </div>
        )
    }

}

export default DeckImageDetail