import * as Conversions from './Conversions'

type ToplineSummaryDomoLineItem = {
    tactics:string,
    costType:string,
    contractedQuantity:number,
    deliveredImpressions: number,
    ctr:number,
    deliverdIndex:number,
    clicks: number,
    starts:number,
    complition:number,
    complitionRate:number,
    engagement: number,
    engagementRate: number,
    contractedSpend: number,
    actualSpend: number,
    conversions:number,
    quantity:number
}

export function convertToplineSummaryDomoLineItem(data: Record<string, string>[]): ToplineSummaryDomoLineItem[] {
    const result: ToplineSummaryDomoLineItem[] = []

    data.forEach(line => {
        result.push({
            tactics: line['Topline Classification'],
            costType: line['Cost_Type'],
            contractedQuantity:Conversions.safeNumber(line['Contracted Impressions']),
            quantity:Conversions.safeNumber(line['Contracted Impressions']),
            deliverdIndex:Conversions.safeNumber(line['Delivery Index']),
            starts:Conversions.safeNumber(line['Video Starts']),
            complition:Conversions.safeNumber(line['Video Completes']),
            complitionRate:Conversions.safeNumber(line['Video Completes'])/Conversions.safeNumber(line['Video Starts']),
            engagement: Conversions.safeNumber(line['Engagements']),
            engagementRate:Conversions.safeNumber(line['Engagements'])/Conversions.safeNumber(line['Delivered Impressions']) ,
            deliveredImpressions: Conversions.safeNumber(line['Delivered Impressions']),
            clicks: Conversions.safeNumber(line['Clicks']),
            contractedSpend: Conversions.safeNumber(line['Contracted Spend']),
            actualSpend: Conversions.safeNumber(line['Actual Spend']),
            ctr:Conversions.safeNumber(line['Clicks'])/Conversions.safeNumber(line['Delivered Impressions']),
            conversions:0
        })
    })

    return result
}

export default ToplineSummaryDomoLineItem