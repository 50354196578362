import ToplineSummaryDomoLineItem from "../Component/ToplineSummaryDomoLineItem"

type SlideSlice = {
    top: number
    bottom: number
}

class ToplineSummaryPaginator {
    public slideCount: number = 0
    private indexes: SlideSlice[]
    private data: ToplineSummaryDomoLineItem[]

    constructor(data: ToplineSummaryDomoLineItem[]) {
        this.data = data
        this.slideCount = 1
        this.indexes = []

        var topLine = 0
        var pageNumber = 1
        var linesOnPage = 0
        var lineCursor = 0
        var maxLinesPerPage = 100      
        for (var record of data)
        {
            // var lineWeight = record.name.length > 50 ? 2 : 1
            // Need to change
           var lineWeight = record.tactics.length> 50 ? 2 : 1;

            if (linesOnPage  + lineWeight >= maxLinesPerPage)
            {
                this.indexes.push({top: topLine, bottom: lineCursor})
                topLine = lineCursor + 1
                linesOnPage = 0
                maxLinesPerPage = 100
                pageNumber = pageNumber + 1
            }
            else
            {
                linesOnPage = linesOnPage + lineWeight
                lineCursor = lineCursor + 1
            }
        }
        if (linesOnPage > 0)
        {
            this.indexes.push({top: topLine, bottom: data.length})
        }
        this.slideCount = 1
    }

    linesForSlide(index: number): ToplineSummaryDomoLineItem[] {
        return this.data.slice(this.indexes[index].top, this.indexes[index].bottom + 1)
    }
}
export default ToplineSummaryPaginator