import React, {  useState } from "react";
import { connect } from "react-redux";
import Slides from '../Model/Slides'
// import SlideImage from '../utils/SlideImage'
import PageSection from '../Model/PageSection'
import DefaultHeading from '../utils/DefaultHeading'
import SlidesSectionPicker from './SlidesSectionPicker'
import SlideImage from "../utils/SlideImage";
import DeckImageDetail from "./DeckImageDetail";

const SlideDetails: React.FC<ISlideDetails> = (props) => {

  const [isOpen,setIsOpen] = useState(false);
  const [imageCount,setSelectedImageCount] = useState(props.deck.images.length);

  function getSelectedImageCount() { 
    return props.deck.images.filter(i => i.include).length
  }

  const [selectedImageCount] = useState(getSelectedImageCount());

  function onImageUpdate(image: SlideImage) {
    let deck = props.deck
    let oldImages = deck.images.filter((i:any) => i.id === image.id)
    if (oldImages.length > 0)
    {
        oldImages[0].include = image.include
        oldImages[0].slideHeading = image.slideHeading
    }
    setSelectedImageCount(getSelectedImageCount())
    // props.onUpdate(deck)
}

function toggleImageView() {
    setIsOpen(!isOpen);
}

function onSectionChange(oldSection: PageSection, newSection: PageSection) {
    const oldDefault = DefaultHeading.forSection(oldSection)
    const newDefault = DefaultHeading.forSection(newSection)
    for (var image of props.deck.images) {
        if (image.slideHeading === oldDefault) {
            image.slideHeading = newDefault
        }
    }
    // props.onUpdate(props.deck)
}
    return (
      <div>
      <div className='deck-detail'>
          <p>{props.deck.filename}</p>
          <div>
              <label>Section: </label>
              <SlidesSectionPicker deck={props.deck} onUpdate={onSectionChange} />
              <span className='deck-detail-expander' onClick={toggleImageView}>{selectedImageCount} of {imageCount} images selected (click to {isOpen ? 'hide' : 'view'})</span>
          </div>
          {isOpen &&
          props.deck.images.map((image, i) =>
              <DeckImageDetail key={image.slideHeading + i.toString()} image={image} thumbWidth={props.thumbWidth} onUpdate={onImageUpdate} />
          )}
      </div>
  </div>
    );
};

function mapStateToProps(state: any, props: any) {
  return {
   };
}

export default connect(mapStateToProps, (dispatch) => {
  return {
  
  };
})(SlideDetails);

interface ISlideDetails extends React.FC<any> {
  deck: Slides,
  onUpdate: (deck: Slides) => void,
  thumbWidth: number
  history: any;
}
