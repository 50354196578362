enum PageCategory
{
    TitlePage,
    Agenda,
    SummaryTitle,
    ExecutiveSummary,
    HeroStats,
    CampaignSummaryData,
    CampaignSummaryDataSample,
    CampaignInsightsTitle,
    LayoutExampleTitle,
    LayoutExample,
    DisplayTitle,
    DisplayResults,
    DisplayCommentary,
    DisplayData,
    DisplayDataSample,
    VideoInsightTitle,
    VideoInsightResults,
    VideoInsightCommentary,
    VideoInsightData,
    VideoInsightDataSample,
    RedXTitle,
    RedXResults,
    RedXCommentary,
    RedXData,
    RedXDataSample,
    FacebookTitle,
    FacebookResults,
    FacebookCommentary,
    FacebookData,
    FacebookDataSample,
    TwitterTitle,
    TwitterResults,
    TwitterCommentary,
    TwitterData,
    TwitterDataSample,
    DigitalAudioTitle,
    DigitalAudioResults,
    DigitalAudioCommentary,
    DigitalAudioData,
    DigitalAudioDataSample,
    ContoTitle,
    ContoResults,
    ContoCommentary,
    ContoData,
    ContoDataSample,
    EblastTitle,
    EblastResults,
    EblastCommentary,
    EblastData,
    EblastDataSample,
    VodTitle,
    VodResults,
    VodCommentary,
    VodData,
    VodDataSample,
    CTVTitle,
    CTVResults,
    CTVCommentary,
    CTVData,
    CTVDataSample,
    NewsLetterTitle,
    NewsLetterResults,
    NewsLetterCommentary,
    NewsLetterData,
    NewsLetterDataSample,
    ConclusionTitle,
    ConclusionPage,
    ClosingLogo,
    AppendixTitle,
    AppendixPage,
    DisplayDictionary,
    VideoInsightDictionary,
    VideoDictionary,
    NewsLetterDictionary,
    SimplifiDictionary,
    DigitalAudioDictionary,
    FacebookDictionary,
    TwitterDictionary,
    VodDictionary,
    CTVDictionary,
    EBlastDictionary,
    Unknown
}
export default PageCategory    