import Excel from 'exceljs'
import ExcelSheet from './ExcelSheet'

class SummarySheet extends ExcelSheet {

    protected columnCount: number
    protected colPadd: number
    constructor(workbook: Excel.Workbook, name: string, 
            reportDates: any,
            orderInfo: Record<string, string | boolean>, logo: any) {
        super(workbook, name, reportDates, orderInfo, logo)
        this.columnCount = 12
        this.colPadd = 1;
        this.addSheetHeader()
        this.sheet.getColumn(2).width = 60.43
        this.sheet.getColumn(3).width = 14.71
        this.sheet.getColumn(4).width = 15.71
        this.sheet.getColumn(5).width = 20.43
        this.sheet.getColumn(6).width = 18.43
        this.sheet.getColumn(7).width = 23.71
        this.sheet.getColumn(8).width = 28.43
        this.sheet.getColumn(9).width = 20.71
        this.sheet.getColumn(10).width = 15.14
        this.sheet.getColumn(11).width = 25.71
        this.sheet.getColumn(12).width = 25.14
    }


    private generateDeliveryIndex(rowIndex: number,costType:any) {
      const firstColumn = 'G';
      const lastColumn = 'E';
    
      const firstCellReference = `${firstColumn}${rowIndex + 1}`;
      const lastCellReference = `${lastColumn}${rowIndex + 1}`;
    
      const sumRange = `${firstCellReference}/${lastCellReference}`;
      if(costType !== 'CPM') {
          return '-'
      }
      else
      return {
        formula: `IFERROR(${sumRange},0)`,
      };
    }
    private getContractedImpression(costType:any,contractedImpression:any) {
        if(costType !== 'CPM') {
            return '-'
        }
        else
        return contractedImpression;
      }

      private generateCTR( rowIndex: number,) {
        const firstColumn = 'I';
        const lastColumn = 'G';
      
        const firstCellReference = `${firstColumn}${rowIndex + 1}`;
        const lastCellReference = `${lastColumn}${rowIndex + 1}`;
      
        const sumRange = `${firstCellReference}/${lastCellReference}`;
        return {
          formula: `IFERROR(${sumRange},0)`,
        };
      }

      private generateSUM(HeaderRowsCount:number,columnLetter: string, totalDataRows: number) {
        const firstDataRow = HeaderRowsCount + 1;
        const lastDataRow = firstDataRow + totalDataRows - 1;
      
        const firstCellReference = `${columnLetter}${firstDataRow}`;
        const lastCellReference = `${columnLetter}${lastDataRow}`;
        const sumRange = `${firstCellReference}:${lastCellReference}`;
      
        return {
          formula: `SUM(${sumRange})`
        };
      }

      private generateAvg(firstDataRow:any,lastDataRow:any) {
        const sumRange = `${firstDataRow}/${lastDataRow}`;
        return {
          formula: `IFERROR($${sumRange},0)`
        };
      }

    public addData(data:  any): void {
        this.sheet.addRow([]).commit()
        const topHeading = this.addSectionHeading(['', 
                'Line Item Placement', 
                'Start Date',
                'End Date',
                'Contracted\r\nImpressions',
                'CPM / CPU',
                'Delivered\r\nImpressions',
                'Delivery Index',
                'Clicks',
                'CTR',
                'Contracted\r\nSpend',
                'Actual Spend'])
        const topDataRow = topHeading.number;
        topHeading.commit()
        const summaryColReportMapping = require("../../data/summaryReportMapping.json");
        this.sheet.columns = summaryColReportMapping;
        
        data.forEach((row:any, index:number) => {
            var item = this.sheet.addRow({
                ...row,
                "Contracted_Impressions":this.getContractedImpression(row['Cost_Type'],row['Contracted_Impressions']),
                'CTR':this.generateCTR(index + topDataRow),
                'DeliveryIndex':this.generateDeliveryIndex(index + topDataRow,row['Cost_Type']),
            }
            );
            item.getCell(this.columnCount+1).value = '';
            item.commit();
            for (var tri=2; tri<=this.columnCount; tri++)
            {
                var totalCell = item.getCell(tri)
                totalCell.border = this.standardCellBorder
            }
           
        });
        const totalRow = this.sheet.addRow(['', 'Total', '', '', this.generateSUM(topDataRow,"E",data.length), '', 
        this.generateSUM(topDataRow,"G",data.length), 
        '',
        this.generateSUM(topDataRow,"I",data.length), 
        this.generateAvg("I" + (topDataRow+data.length+1),"G" + (topDataRow+data.length+1)),
        this.generateSUM(topDataRow,"K",data.length), 
        this.generateSUM(topDataRow,"L",data.length), 
    ])      
        for (var tri=2; tri<=this.columnCount; tri++)
        {
            var totalCell = totalRow.getCell(tri)
            totalCell.border = this.standardCellBorder
            totalCell.font = { bold: true, color: {argb: 'FF000000'} }
            totalCell.alignment = {horizontal: tri === 2 ? 'left': 'right'}
            totalCell.fill = {type: 'pattern', pattern: 'solid', fgColor: {argb: 'FFCCCCCC'}, bgColor: {argb: 'FFFFFFFF'}} 
        }
        totalRow.getCell(5).style.numFmt = '#,##0'
        totalRow.getCell(7).style.numFmt = '#,##0'
        // totalRow.getCell(8).style.numFmt = '0.00%'
        totalRow.getCell(9).style.numFmt = '#,##0'
        totalRow.getCell(10).style.numFmt = '0.00%'
        totalRow.getCell(11).style.numFmt = '$#,##0.00'
        totalRow.getCell(12).style.numFmt = '$#,##0.00'
        totalRow.commit()     
    }
}
export default SummarySheet