import { saveAs } from "file-saver";
import { updateProgressCRM } from "src/Actions";
import { Configs } from "src/ConstConfig";
import { getAuthContext } from "src/Login/MSAL/msalConfig";
import { convertDomoOrderSummary } from "src/Reporting/Component/DomoLineItem";
import pptxfile from "src/Reporting/Component/pptxfile";
import SlideTOC from "src/Reporting/Component/SlideTOC";
import { convertToplineSummaryDomoLineItem } from "src/Reporting/Component/ToplineSummaryDomoLineItem";
import PageCategory from "src/Reporting/Model/PageCategory";
import PageSection from "src/Reporting/Model/PageSection";
import DomoOrderRecordWithPagination from "src/Reporting/utils/DomoOrderRecordWithPagination";
import ToplineDomoOrderRecordWithPagination from "src/Reporting/utils/ToplineDomoOrderRecordWithPagination";
import AxiosClient from "../ClientServices/AxiosClient";
import { transformRaw } from "../Store/helper";

const Data = require("src/images/rsm_logo_ppt.png");

const reduiPowerPoint = (store: any) => (next: any) => (action: any) => {
  const configs: any = new Configs();

  if (
    action &&
    action.hasOwnProperty("type") &&
    action.type === "DWONLOAD_DOMOPPT"
  ) {
    let userAction = action.type;
    switch (userAction) {
      case "DWONLOAD_DOMOPPT":
        {
          store.dispatch(
            updateProgressCRM({
              data: {
                total: 0,
                loaded: 9999,
                text: "Exporting...",
              },
            })
          );
          const clnt = new AxiosClient(store);
          const token = getAuthContext().idToken.rawIdToken;
          const payload = {
            queryId: "toplinesummary",
            queryItems: {
              ORDERID: action.payload.orderId,
              ADDEDCRITERIA:
                " AND (`Event_Date` >= '" +
                action.payload.dates.reportStartDate +
                "' AND `Event_Date` <= '" +
                action.payload.dates.reportEndDate +
                "') ",
            },
          };

          const reqObjectSeg = {
            authToken: token,
            url: configs.getUserDomoQueryUrl(),
          };
          clnt
            .post("POST", reqObjectSeg, payload)
            .then((toplineSummaryResponse: any) => {
              let toplineSummary = transformRaw(toplineSummaryResponse);
              downloadPPT(
                action.payload.dates,
                action.payload.powerPointPayload,
                action.payload.orderId,
                action.payload.advSlidesOptions,
                action.payload.slideList,
                action.payload.summaryOptions,
                toplineSummary
              );

              store.dispatch(
                updateProgressCRM({
                  data: {
                    total: 0,
                    loaded: 10000,
                    text: "Downloaded",
                  },
                })
              );
              localStorage.removeItem("orderId");
            });
        }
        break;
    }
  }
  return next(action);
};

function downloadPPT(
  reportDates: any,
  powerPointPaylod: any,
  orderId: any,
  advSlidesOptions: any,
  slideList: any,
  summaryOptions: any,
  toplineSummaryResponse: any
) {
  let logo: any = null;
  fetch(Data)
    .then((response: Response) =>
      response.status === 200 || response.status === 0
        ? response.arrayBuffer()
        : Promise.reject()
    )
    .then((data: ArrayBuffer) => {
      const png = new DataView(new Uint8Array(data).buffer);
      var width = png.getUint32(16);
      var height = png.getUint32(20);
      logo = {
        width: width,
        height: height,
        size: data.byteLength,
        format: "png",
        data: data,
      };
    });
  fetch("/CAMPAIGN_REPORT_TEMPLATE_v13.pptx")
    .then(function (response: Response) {
      if (response.status === 200 || response.status === 0) {
        return response.arrayBuffer();
      } else {
        throw new Error("Could not load template");
      }
    })
    .then((data: ArrayBuffer) => {
      var ppt = new pptxfile();
      return ppt.load(data);
    })
    .then((ppt) => {
      const toc = new SlideTOC(ppt);

      const summarySlide = {
        id: orderId,
        name: powerPointPaylod.reportLabel,
        advertiser: powerPointPaylod.Advertiser,
        orderStartDate: reportDates.orderStartDate,
        orderEndDate: reportDates.orderEndDate,
        reportStartDate: reportDates.reportStartDate,
        reportEndDate: reportDates.reportEndDate,
        lines: convertDomoOrderSummary(powerPointPaylod["response"]),
      };

      const toplineSummarySlide = {
        id: orderId,
        name: powerPointPaylod.reportLabel,
        advertiser: powerPointPaylod.Advertiser,
        orderStartDate: reportDates.orderStartDate,
        orderEndDate: reportDates.orderEndDate,
        reportStartDate: reportDates.reportStartDate,
        reportEndDate: reportDates.reportEndDate,
        lines: convertToplineSummaryDomoLineItem(toplineSummaryResponse),
      };
      //Response of topline summary
      let paginatedTopLineSummaryData =
        new ToplineDomoOrderRecordWithPagination(toplineSummarySlide);
      ppt.addTitlePageDetails(toplineSummarySlide);
      const toplinesummarySlidePage = toc.appendNewSlide(
        "slideLayout10",
        PageSection.Summary,
        dataCategoryFor(PageSection.Summary)
      );
      toplinesummarySlidePage.addHeading("CAMPAIGN TOPLINE SUMMARY");
      toplinesummarySlidePage.addToplineSummaryDetails(
        paginatedTopLineSummaryData!,
        logo,
        summaryOptions[0].isSelected,
        summaryOptions[1].isSelected
      );

      let paginatedData = new DomoOrderRecordWithPagination(summarySlide);
      ppt.addTitlePageDetails(summarySlide);
      const reportName =
        orderId + " - " + powerPointPaylod.reportLabel + ".pptx";
      for (var sumdex = 0; sumdex < paginatedData!.slideCount; sumdex++) {
        var extraSummarySlide = toc.appendNewSlide(
          "slideLayout10",
          PageSection.Summary,
          dataCategoryFor(PageSection.Summary)
        );
        extraSummarySlide.addHeading(
          "LINE ITEM SUMMARY (PAGE " +
            (sumdex + 1).toString() +
            " OF " +
            paginatedData!.slideCount.toString() +
            ")"
        );
        extraSummarySlide.addSummaryChart(
          paginatedData!.linesForSlide(sumdex),
          summaryOptions[0].isSelected,
          summaryOptions[1].isSelected,
          null,
          sumdex === paginatedData!.slideCount - 1
            ? paginatedData!.footer
            : null
        );
      }
      toc.deleteSampleDataSlides(PageSection.Summary);
      const processedSections: Array<PageSection> = [];
      for (var deck of slideList) {
        if (deck.section !== PageSection.Unknown) {
          processedSections.push(deck.section);
          for (var image of deck.images.filter((i: any) => i.include)) {
            const slide = toc.appendNewSlide(
              "slideLayout10",
              deck.section,
              dataCategoryFor(deck.section)
            );
            slide.addImage(image);
            slide.addHeading(image.slideHeading.toUpperCase());
          }
          toc.deleteSampleDataSlides(deck.section);
        }
      }
      advSlidesOptions.map(function (slides: any) {
        if (
          slides.pageIDSection != -1 &&
          processedSections.indexOf(slides.pageIDSection) < 0 &&
          !slides.isSelected
        ) {
          toc.deleteSection(slides.pageIDSection);
          if (slides.categories.length > 0) {
            slides.categories.map(function (category: any) {
              toc.deleteSlidesOfCategory(category);
            });
          }
        } else if (!slides.isSelected && slides.categories.length > 0) {
          slides.categories.map(function (category: any) {
            toc.deleteSlidesOfCategory(category);
          });
        }
      });
      savePowerPointFile(ppt.toBuffer(), reportName);
    });
}

function savePowerPointFile(data: Promise<Uint8Array>, fileName: string) {
  data.then((pptData: any) => {
    var blob = new Blob([pptData], { type: "text/plain;charset=utf-8" });
    saveAs(blob, fileName);
  });
}

function dataCategoryFor(section: PageSection): PageCategory {
  switch (section) {
    case PageSection.Conto:
      return PageCategory.ContoData;
    case PageSection.DigitalAudio:
      return PageCategory.DigitalAudioData;
    case PageSection.Display:
      return PageCategory.DisplayData;
    case PageSection.VideoSuite:
      return PageCategory.VideoInsightData;
    case PageSection.Facebook:
      return PageCategory.FacebookData;
    case PageSection.RedX:
      return PageCategory.RedXData;
    case PageSection.Twitter:
      return PageCategory.TwitterData;
    case PageSection.Summary:
      return PageCategory.CampaignSummaryData;
    case PageSection.Eblast:
      return PageCategory.EblastData;
    case PageSection.NewsLetter:
      return PageCategory.NewsLetterData;
    case PageSection.Vod:
      return PageCategory.VodData;
  }
  return PageCategory.Unknown;
}

export default reduiPowerPoint;
