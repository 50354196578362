/* eslint-disable */
import * as React from "react";
import { connect } from "react-redux";
import "../thirdparty/perfect-scrollbar.js";
import { UserOps } from "../../ConstConfig/UserOps";
import { getIndexOfCard } from "../audienceBuilderUtil";
import { getNextAvailableIndex } from "../sortingUtil.js";
import { AudienceSizerConst } from "../../ConstConfig/AudienceSizerConst";
const PerfectScrollbar = require("../thirdparty/perfect-scrollbar.js");
const update = require("react-addons-update");

export class SelectedTraits extends React.Component<ISelectedTraits> {
  public dropList: number;
  public state: any;
  constructor(props: any) {
    super(props);
    this.dropList = 2;
    this.state = { cards: [] };
    this.getTitle = this.getTitle.bind(this);
    this.scrollRefComp = this.scrollRefComp.bind(this);
    this.displayAdditionalOption = this.displayAdditionalOption.bind(this);
    this.displayLink = this.displayLink.bind(this);
    this.getLinkClass = this.getLinkClass.bind(this);
    this.showAdvMode = this.showAdvMode.bind(this);
    this.showSegmentsInOrder = this.showSegmentsInOrder.bind(this);
    this.moveSelectedTraitsDownword =
      this.moveSelectedTraitsDownword.bind(this);
    this.moveSelectedTraitsUpword = this.moveSelectedTraitsUpword.bind(this);
    this.removeCard = this.removeCard.bind(this);
    this.resetLinking = this.resetLinking.bind(this);
    this.swapElement = this.swapElement.bind(this);
    this.reOrder = this.reOrder.bind(this);
    this.showArrowComponent = this.showArrowComponent.bind(this);
  }

  UNSAFE_componentWillUpdate(nextProps: any, nextState: any) {
    if (this.props.toggleSwithState === 2) {
    }
    this.props.list.map((card: any, i: any) => {
      if (card.isSelected) {
        const index = getIndexOfCard(this.state.cards, card);
        if (index < 0) {
          this.state.cards.push(card);
        }
      }
    });
    if (this.state.cards.length >= 1) {
      this.state.cards[this.state.cards.length - 1].linkClass = "";
      this.props.list[this.state.cards.length - 1].linkClass = "";
    }
  }

  public getTitle() {
    if (this.props.toggleSwithState === 2) {
      this.props.setAdvMode(2);
      return "ADVANCED";
    } else if (this.props.toggleSwithState === 0) {
      this.props.setAdvMode(0);
      return "ANY";
    } else {
      this.props.setAdvMode(1);
      return "ALL ";
    }
  }

  handleSelectTraitChange(changedState: any, AdMode: any, e: any) {
    this.props.updateToggle(changedState);
  }

  scrollRefComp(ref: any) {
    if (ref)
      new PerfectScrollbar(ref, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
  }

  updateSelectedTraits(card: any, index: any, e: any) {
    let nodes = {};
    if (index >= 0) {
      nodes["prev"] = index - 1 < 0 ? [] : this.state.cards[index - 1];
      nodes["next"] =
        index + 1 >= this.state.cards.length ? [] : this.state.cards[index + 1];
      this.removeCard(index);
    }
    const pushingCard = { action: "REMOVE", card: card, allNodes: nodes };
    const tabs = { card: pushingCard, listID: 1 };
    this.props.updateStateOnClick(tabs);
  }

  displayAdditionalOption() {
    const index = this.props
      .assignedRoles()
      .findIndex((obj: any) => obj == UserOps.BETATESTER);
    if (index >= 0) {
      return (
        <a
          className="dropdown-item"
          href="javascript:void(0);"
          onClick={this.handleSelectTraitChange.bind(this, 2, true)}
        >
          <span>ADVANCED </span>
        </a>
      );
    }
    return null;
  }

  reOrder() {
    const pushingCard = { action: "REORDER", card: this.state.cards };
    const tabs = { card: pushingCard, listID: 1 };
    this.props.updateStateOnClick(tabs);
  }

  resetLinking(card: any, isLinked: boolean, index: any) {
    const propIndex = getIndexOfCard(this.state.cards, card);
    let linkedWithIdIndex = -1;
    if (propIndex >= 0) {
      const indx = getIndexOfCard(
        this.props.list,
        this.state.cards[propIndex + 1]
      );
      if (indx >= 0) {
        linkedWithIdIndex =
          this.props.list[indx].hasOwnProperty("linkedSegmentId") &&
          this.props.list[indx].linkedSegmentId.length
            ? this.props.list[indx].linkedSegmentId.findIndex(
                (obj: any) => obj === this.state.cards[propIndex].segmentId
              )
            : -1;
      }
      if (!isLinked) {
        this.props.list[index].linkedSegmentId = [];
        this.props.list[index].linkDirection = AudienceSizerConst.LINKNONE;
        if (linkedWithIdIndex >= 0) {
          this.props.list[indx].linkedSegmentId.splice(linkedWithIdIndex, 1);
          this.props.list[indx].linkDirection =
            this.props.list[indx].linkedSegmentId.length == 1
              ? AudienceSizerConst.LINKDOWN
              : AudienceSizerConst.LINKNONE;
        }
      } else {
        if (linkedWithIdIndex <= 0) {
          this.props.list[index].linkedSegmentId.push(
            this.props.list[indx].segmentId
          );
          this.props.list[index].linkDirection =
            this.props.list[index].linkedSegmentId.length == 2
              ? AudienceSizerConst.LINKBOTH
              : AudienceSizerConst.LINKDOWN;
          this.props.list[indx].linkedSegmentId.push(
            this.props.list[index].segmentId
          );
          this.props.list[indx].linkDirection =
            this.props.list[indx].linkedSegmentId.length == 2
              ? AudienceSizerConst.LINKBOTH
              : AudienceSizerConst.LINKUP;
        }
      }
      const pushingCard = {
        action: "RELINK",
        card: card,
        linkCard: this.props.list[indx],
      };
      const tabs = { card: pushingCard, listID: 1 };
      this.props.updateStateOnClick(tabs);
    }
  }

  updateLink(card: any, e: any) {
    const linkClass = "linkClass";
    const index = getIndexOfCard(this.props.list, card);
    if (index >= 0) {
      if (this.props.list[index][linkClass] === " connector") {
        this.props.list[index][linkClass] = " link";
        this.resetLinking(card, false, index);
      } else {
        this.props.list[index][linkClass] = " connector";
        this.resetLinking(card, true, index);
      }
    }
  }

  getLinkClass(card: any) {
    const linkDirection = "linkDirection";
    const index = getIndexOfCard(this.props.list, card);
    if (
      this.props.list[index][linkDirection] === AudienceSizerConst.LINKDOWN ||
      this.props.list[index][linkDirection] === AudienceSizerConst.LINKBOTH
    ) {
      return " connector";
    }
    return this.props.list[index].linkClass;
  }

  displayLink(card: any) {
    const index = getIndexOfCard(this.props.list, card);
    if (
      this.props.list[index].linkDirection === AudienceSizerConst.LINKNONE ||
      this.props.list[index].linkDirection === AudienceSizerConst.LINKUP
    ) {
      return <span>Link</span>;
    }
    return null;
  }

  handleDDClick(title: any, card: any, e: any) {
    const AdvFilterMode = "AdvanceFilterMode";
    const operator = "operator";
    card[AdvFilterMode] = title;
    const index = getIndexOfCard(this.props.list, card);
    if (index >= 0) {
      this.props.list[index][operator] = title;
      this.props.updateAdvOperator(this.props.list[index]);
    }
    this.props.updateToggle(2);
  }

  getStartIndex(card: any) {
    const index = getIndexOfCard(this.state.cards, card);
    if (index >= 1) {
      if (
        this.state.cards[index - 1].hasOwnProperty("linkedSegmentId") &&
        this.state.cards[index - 1].linkedSegmentId.length > 0
      ) {
        this.getStartIndex(this.state.cards[index - 1]);
      }
    } else {
      return index;
    }
  }

  swapElement(orgIndex: any, mvIndex: any) {
    const swap = this.state.cards[mvIndex];
    this.state.cards[mvIndex] = this.state.cards[orgIndex];
    this.state.cards.splice(orgIndex, 1);
    this.state.cards.splice(mvIndex + 1, 0, swap);
  }

  moveSelectedTraitsUpword(card: any, orgIndex: any, e: any) {
    let mvIndex = orgIndex;
    if (orgIndex >= 1) {
      let tmpIndx = orgIndex - 1;
      let newIndex = getNextAvailableIndex(this.state.cards, tmpIndx);
      mvIndex = newIndex;
      if (tmpIndx <= 0) {
        mvIndex = 0;
      }
    }

    if (mvIndex >= 0) {
      if (orgIndex === mvIndex) {
        orgIndex -= 1;
      }
      this.swapElement(orgIndex, mvIndex);
    }
    this.reOrder();
  }

  moveSelectedTraitsDownword(card: any, orgIndex: any, e: any) {
    const index = getIndexOfCard(this.state.cards, card);
    let mvIndex = index;
    let isValAssigned = false;
    if (index + 1 !== this.state.cards.length) {
      let tmpIndx = index + 1;
      for (; tmpIndx < this.state.cards.length; ++tmpIndx) {
        if (
          (this.state.cards[tmpIndx].hasOwnProperty("linkedSegmentId") &&
            this.state.cards[tmpIndx].linkedSegmentId.length > 0) ||
          (this.state.cards[tmpIndx].hasOwnProperty("linkClass") &&
            this.state.cards[tmpIndx].linkClass == " connector")
        ) {
          continue;
        } else {
          mvIndex = tmpIndx - 1;
          isValAssigned = true;
          break;
        }
      }
      if (!isValAssigned) {
        mvIndex = tmpIndx - 1;
        isValAssigned = true;
      }
      if (mvIndex >= this.state.cards.length) {
        mvIndex = this.state.cards.length - 1;
      }
    }
    if (orgIndex === mvIndex) {
      orgIndex += 1;
    }
    const swap = this.state.cards[orgIndex];
    this.state.cards.splice(orgIndex, 1);
    this.state.cards.splice(mvIndex, 0, swap);
    this.reOrder();
  }

  removeCard(index: any) {
    this.setState(
      update(this.state, {
        cards: {
          $splice: [[index, 1]],
        },
      })
    );
  }

  showSegmentsInOrder() {
    return this.state.cards.map((card: any, i: any) => {
      return (
        <span key={"span" + i}>
          <li key={"carditem" + i}>
            <div>
              <p>{card.segmentName}</p>
              <p>{card.segmentLabel}</p>
            </div>
            <div>
              {this.showArrowComponent(
                "moveAudienceUp",
                this.moveSelectedTraitsUpword,
                card,
                i
              )}
              <a
                className="deleteAudience"
                onClick={this.updateSelectedTraits.bind(this, card, i)}
              />
              {this.showArrowComponent(
                "moveAudienceDown",
                this.moveSelectedTraitsDownword,
                card,
                i
              )}
            </div>
          </li>
          {this.showAdvMode(card)}
        </span>
      );
    });
  }

  showArrowComponent(
    classId: any,
    callBackMethod: any,
    card: any,
    cardIndex: any
  ) {
    if (this.getTitle() === "ADVANCED") {
      if (classId === "moveAudienceUp" && cardIndex === 0) {
        return null;
      }
      if (
        classId === "moveAudienceDown" &&
        cardIndex === this.state.cards.length - 1
      ) {
        return null;
      }
      const nxtIndex = cardIndex >= 1 ? cardIndex - 1 : 0;
      const linkClass = "linkClass";
      if (
        this.state.cards[cardIndex][linkClass] !== " connector" &&
        this.state.cards[nxtIndex][linkClass] !== " connector"
      ) {
        return (
          <a
            className={classId}
            onClick={callBackMethod.bind(this, card, cardIndex)}
          />
        );
      }
    }
    return null;
  }

  showAdvMode(card: any) {
    if (
      this.props.toggleSwithState === 2 &&
      this.state.cards[this.state.cards.length - 1].segmentId !== card.segmentId
    ) {
      return (
        <div>
          <div className="dropdown customDropdown show float-left operator">
            <a
              className="dropdownToggle"
              href="javascript:void(0);"
              role="button"
              id="dropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {card["operator"] || "AND"}
              <i className="dropdownToggleIcon float-right" />
            </a>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
              <a className="dropdown-item" href="javascript:void(0);">
                <span>
                  {card["operator"] ? card["operator"] : "AND"}{" "}
                  <i className="dropdownToggleIcon float-right iconPosition" />
                </span>
              </a>
              <a
                className="dropdown-item"
                href="javascript:void(0);"
                onClick={this.handleDDClick.bind(this, "AND", card)}
              >
                <span>AND</span>
              </a>
              <a
                className="dropdown-item"
                href="javascript:void(0);"
                onClick={this.handleDDClick.bind(this, "AND NOT", card)}
              >
                <span>AND NOT</span>
              </a>
              <a
                className="dropdown-item"
                href="javascript:void(0);"
                onClick={this.handleDDClick.bind(this, "OR", card)}
              >
                <span>OR</span>
              </a>
            </div>
          </div>
          <a
            className={"float-right " + this.getLinkClass(card)}
            id="link"
            onClick={this.updateLink.bind(this, card)}
          >
            {this.displayLink(card)}
          </a>
        </div>
      );
    }
    return null;
  }

  render() {
    return (
      <div className="selectedTraits">
        <h4 className="border-bottom-0 float-left">MERGE MODE:</h4>
        <div className="dropdown customDropdown show float-left">
          <a
            className="dropdownToggle"
            href="javascript:void(0);"
            role="button"
            id="dropdownMenuLink"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span className="redTxt">{this.getTitle()}</span>
            {this.getTitle() === "ADVANCED" ? "" : " SELECTED TRAITS"}
            <i className="dropdownToggleIcon float-right" />
          </a>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <a className="dropdown-item" href="javascript:void(0);">
              <span>
                <label className="redTxt">{this.getTitle()}</label>
                {this.getTitle() === "ADVANCED" ? "" : " SELECTED TRAITS"}{" "}
                <i className="dropdownToggleIcon float-right iconPosition" />
              </span>
            </a>
            <a
              className="dropdown-item"
              href="javascript:void(0);"
              onClick={this.handleSelectTraitChange.bind(this, 0, false)}
            >
              <span>ANY SELECTED TRAITS</span>
            </a>
            <a
              className="dropdown-item"
              href="javascript:void(0);"
              onClick={this.handleSelectTraitChange.bind(this, 1, false)}
            >
              <span>ALL SELECTED TRAITS</span>
            </a>
            {this.displayAdditionalOption()}
          </div>
        </div>
        <div
          className="selectedTraitsContent float-left"
          ref={this.scrollRefComp}
        >
          <ul>{this.showSegmentsInOrder()}</ul>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state: any) {
  return {};
}

export default connect(mapStateToProps, (dispatch) => {
  return {};
})(SelectedTraits);

interface ISelectedTraits extends React.FC<any> {
  id?: any;
  canDrop?: boolean;
  isOver?: boolean;
  list?: any;
  updateStateOnClick?: any;
  tabIndex?: any;
  optionalIist?: any;
  updateToggle: any;
  UserRole?: any;
  toggleSwithState: any;
  assignedRoles?: any;
  setAdvMode: any;
  updateAdvOperator: any;
}
