import Excel from 'exceljs'
import ExcelSheet from './ExcelSheet'
export enum GAMSection {
    LineItem,
    Creative,
    AdSize,
    Device,
    AdUnit,
    DayOfWeek,
    WeekOf,
    Province,
    CityProvince
}
class GAMData extends ExcelSheet {

    protected columnCount: number
    protected hasVideo: boolean
    protected colPadd: number

    constructor(workbook: Excel.Workbook, name: string, hasVideo: boolean,
        reportDates: any,
        orderInfo: Record<string, string | boolean>, logo: any) {
        super(workbook, name, reportDates, orderInfo, logo)
        this.columnCount = 12
        // this.addSheetHeader()
        this.hasVideo = hasVideo;
        this.colPadd = 1;
        this.columnCount = this.hasVideo ? 9 : 5
        this.addSheetHeader()
        this.sheet.getColumn(2).width = 123.43
        this.sheet.getColumn(3).width = 22.29
        this.sheet.getColumn(4).width = 20.14
        this.sheet.getColumn(5).width = 20.14
        if (this.hasVideo) {
            this.sheet.getColumn(6).width = 20.14
            this.sheet.getColumn(7).width = 20.14
            this.sheet.getColumn(8).width = 20.14
            this.sheet.getColumn(9).width = 20.14
        }
    }

    private sectionLabel(section: GAMSection): string {
        switch (section) {
            
            case GAMSection.LineItem: return 'Performance by Line Item'
            case GAMSection.Creative: return 'Performance by Creative'
            case GAMSection.AdSize: return 'Performance by Ad Size'
            case GAMSection.Device: return 'Performance by Device Category'
            case GAMSection.AdUnit: return 'Top Sites by Delivery'
            case GAMSection.DayOfWeek: return 'Performance by Day of Week'
            case GAMSection.WeekOf: return 'Performance by Week '
            case GAMSection.Province: return 'Performance by Province'
            case GAMSection.CityProvince: return 'Top City & Province by Delivery'
        }
    }

    private sectionDataKey(section: GAMSection): string {
        switch (section) {
            
            case GAMSection.LineItem: return 'Line Item'
            case GAMSection.Creative: return 'Creative Name'
            case GAMSection.AdSize: return 'Creative Size'
            case GAMSection.Device: return 'Device Name'
            case GAMSection.AdUnit: return 'Ad Unit Name'
            case GAMSection.DayOfWeek: return 'Day'
            case GAMSection.WeekOf: return 'Week'
            case GAMSection.Province: return 'Region'
            case GAMSection.CityProvince: return 'CityProvince'
        }
    }

    public addData(section: GAMSection, data: any): void {
        if (!data || data?.length <= 0) {
            return;
        }
        const hasVideoData = this.getColumnRuleCheck(data);
        
       
        this.sheet.addRow([]).commit()
        const heading = ['',
            this.sectionLabel(section), "Delivered\r\nImpressions", 'Clicks', 'CTR',
            'Video Starts', 'Video Midpoints', 'Video Completes', 'VCR']
        const topHeading = this.addSectionHeading(this.hasVideo && hasVideoData ? heading : heading.slice(0, 5))
        const topDataRow = topHeading.number;
        topHeading.commit()
        const colCont = (this.hasVideo && hasVideoData) ? this.columnCount : 5;
        for (const row of data) {
            var rowData = ['',
                row[this.sectionDataKey(section)],
                this.safeInt(row['Impressions']),
                this.safeInt(row['Clicks']),
                0,
                this.safeInt(row['Video_Starts']),
                this.safeInt(row['Video_Midpoints']),
                this.safeInt(row['Video_Completes']),
                0
            ]
            var item = this.sheet.addRow(this.hasVideo && hasVideoData ? rowData : rowData.slice(0, 5))

            item.getCell(3).style.numFmt = '#,##0'
            item.getCell(4).style.numFmt = '#,##0'
            var ctr = item.getCell(5)
            ctr.value = {
                formula: 'IFERROR(D' + ctr.row + '/C' + ctr.row + ', 0)',
                date1904: false
            }
            ctr.style.numFmt = '0.00%'
            
            for (var ri = 2; ri <= colCont; ri++) {
                var cell = item.getCell(ri)
                cell.border = this.standardCellBorder
                cell.alignment = { horizontal: ri === 2 ? 'left' : 'right' }
            }
            if (this.hasVideo && hasVideoData) {
                item.getCell(6).style.numFmt = '#,##0'
                item.getCell(7).style.numFmt = '#,##0'
                item.getCell(8).style.numFmt = '#,##0'
                var vcr = item.getCell(9)
                vcr.value = {
                    formula: 'IFERROR(H' + ctr.row + '/F' + ctr.row + ', 0)',
                    date1904: false
                }
                vcr.style.numFmt = '0.00%'
            }
            item.commit()
        }

        const totalTemplate = ['', 'Total', 0, 0, 0, 0, 0, 0, 0]
        if (section != GAMSection.Province && section != GAMSection.CityProvince) {
            const totalRow = this.sheet.addRow(this.hasVideo && hasVideoData ? totalTemplate : totalTemplate.slice(0, 5))
            for (var tri = 2; tri <= colCont; tri++) {
                var totalCell = totalRow.getCell(tri)
                totalCell.border = this.standardCellBorder
                totalCell.font = { bold: true, color: { argb: 'FF000000' } }
                totalCell.alignment = { horizontal: tri === 2 ? 'left' : 'right' }
                totalCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFCCCCCC' }, bgColor: { argb: 'FFFFFFFF' } }
            }
            totalRow.getCell(2).value = 'Total'
            totalRow.getCell(3).value = { formula: 'SUM(C' + topDataRow + ':C' + (totalRow.number - 1) + ')', date1904: false }
            totalRow.getCell(3).style.numFmt = '#,##0'
            totalRow.getCell(4).value = { formula: 'SUM(D' + topDataRow + ':D' + (totalRow.number - 1) + ')', date1904: false }
            totalRow.getCell(4).style.numFmt = '#,##0'
            totalRow.getCell(5).value = { formula: 'IFERROR(D' + totalRow.number + '/C' + totalRow.number + ', 0)', date1904: false }
            totalRow.getCell(5).style.numFmt = '0.00%'

            if (hasVideoData && this.hasVideo) {
                totalRow.getCell(6).value = { formula: 'SUM(F' + topDataRow + ':F' + (totalRow.number - 1) + ')', date1904: false }
                totalRow.getCell(6).style.numFmt = '#,##0'
                totalRow.getCell(7).value = { formula: 'SUM(G' + topDataRow + ':G' + (totalRow.number - 1) + ')', date1904: false }
                totalRow.getCell(7).style.numFmt = '#,##0'
                totalRow.getCell(8).value = { formula: 'SUM(H' + topDataRow + ':H' + (totalRow.number - 1) + ')', date1904: false }
                totalRow.getCell(8).style.numFmt = '#,##0'
                totalRow.getCell(9).value = { formula: 'IFERROR(H' + totalRow.number + '/F' + totalRow.number + ', 0)', date1904: false }
                totalRow.getCell(9).style.numFmt = '0.00%'
            }

            totalRow.commit()
        }

    }

    getColumnRuleCheck(data: any) {
        const newData = data.filter((d: any) => this.safeInt(d['Video_Starts']) > 0);
        return newData.length;
    }
}
export default GAMData