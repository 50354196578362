/* eslint-disable */
import { Audiences } from "../Audiences/Model/AudiencesModel";
import { AudienceSizerConst } from "../ConstConfig/AudienceSizerConst";

export function addItemAndGetPayload(
  requestPayload: any,
  pagetIndex: any,
  demographics: any,
  interests: any,
  behaviours: any,
  advanceSegmentation?: any,
  flagState?: any
) {
  {
    if (requestPayload.card.action === "REMOVE") {
      advanceSegmentation.unLinkAndRemove(requestPayload);
    }
    if (requestPayload.card.action === "ADD") {
      advanceSegmentation.setSegementInAdvanceMode(requestPayload.card.card);
    } else if (requestPayload.card.action === "RELINK") {
      advanceSegmentation.setSegementInAdvanceMode(requestPayload.card);
    }

    if (requestPayload.card.card.Tabtype === 0) {
      if (
        requestPayload.card.action === "REMOVE" ||
        !requestPayload.card.card.isSelected
      ) {
        const list = demographics.getSegmentPayloadForDemographics();
        const index = list.findIndex(
          (obj: any) => obj.segmentId == requestPayload.card.card.segmentId
        );
        if (index >= 0) {
          demographics.removeSegementInAND(index);
        }
      }
      if (requestPayload.card.action === "ADD") {
        demographics.setSegementInAND(requestPayload.card.card);
      }
    }
    if (requestPayload.card.card.Tabtype === 1) {
      if (
        requestPayload.card.action === "REMOVE" ||
        !requestPayload.card.card.isSelected
      ) {
        const index = interests
          .getSegmentPayloadForDemographics()
          .findIndex(
            (obj: any) => obj.segmentId == requestPayload.card.card.segmentId
          );
        if (index >= 0) {
          interests.removeSegementInAND(index);
        }
      }
      if (requestPayload.card.action === "ADD") {
        interests.setSegementInAND(requestPayload.card.card);
      }
    }
    if (requestPayload.card.card.Tabtype === 2) {
      if (
        requestPayload.card.action === "REMOVE" ||
        !requestPayload.card.card.isSelected
      ) {
        const index = behaviours
          .getSegmentPayloadForDemographics()
          .findIndex(
            (obj: any) => obj.segmentId == requestPayload.card.card.segmentId
          );
        if (index >= 0) {
          behaviours.removeSegementInAND(index);
        }
      }
      if (requestPayload.card.action === "ADD") {
        behaviours.setSegementInAND(requestPayload.card.card);
      }
    }
  }
}

export function changeFlagGetPayload(
  pagetIndex: any,
  demographics: any,
  interests: any,
  behaviours: any,
  flagState: any,
  advModeSeg?: any
) {
  let segMap = [];
  let catSeg = {
    category: "Demographics",
    segmentsAnd: Array,
    segmentsOr: Array,
  };
  let catIntSeg = {
    category: "Interest",
    segmentsOr: Array,
    segmentsAnd: Array,
  };
  let catBehaviorSeg = {
    category: "Behaviours",
    segmentsOr: Array,
    segmentsAnd: Array,
  };
  let segIdOrderIdMap = advModeSeg.getSegmentationIdAndOrderIdMap();
  if (flagState === 1) {
    catSeg.segmentsAnd =
      demographics.getSegmentPayloadForDemographics(segIdOrderIdMap);
    catIntSeg.segmentsAnd =
      interests.getSegmentPayloadForDemographics(segIdOrderIdMap);
    catBehaviorSeg.segmentsAnd =
      behaviours.getSegmentPayloadForDemographics(segIdOrderIdMap);
  } else if (flagState === 2) {
    //Advance Mode
    let advSeg = advModeSeg.getAdvanceModePayload();
    return advSeg;
  } else {
    catSeg.segmentsOr =
      demographics.getSegmentPayloadForDemographics(segIdOrderIdMap);
    catIntSeg.segmentsOr =
      interests.getSegmentPayloadForDemographics(segIdOrderIdMap);
    catBehaviorSeg.segmentsOr =
      behaviours.getSegmentPayloadForDemographics(segIdOrderIdMap);
  }
  segMap.push(catSeg);
  segMap.push(catIntSeg);
  segMap.push(catBehaviorSeg);
  const resPayload = { segmentation: segMap };
  return resPayload;
}

export function savePayload(
  pagetIndex: any,
  demographics: any,
  interests: any,
  behaviours: any,
  flagState: any,
  advModeSeg?: any
) {
  let segMap = [];
  let catSeg = {
    category: "Demographics",
    segmentsAnd: Array,
    segmentsOr: Array,
  };
  let catIntSeg = {
    category: "Interest",
    segmentsOr: Array,
    segmentsAnd: Array,
  };
  let catBehaviorSeg = {
    category: "Behaviours",
    segmentsOr: Array,
    segmentsAnd: Array,
  };
  let segIdOrderIdMap = advModeSeg.getSegmentationIdAndOrderIdMap();
  if (flagState === 1) {
    catSeg.segmentsAnd =
      demographics.getSegmentPayloadForDemographics(segIdOrderIdMap);
    catIntSeg.segmentsAnd =
      interests.getSegmentPayloadForDemographics(segIdOrderIdMap);
    catBehaviorSeg.segmentsAnd =
      behaviours.getSegmentPayloadForDemographics(segIdOrderIdMap);
  } else if (flagState === 2) {
    //Advance Mode
    let advSeg = advModeSeg.getAdvanceModeSavePayload();
    return advSeg;
  } else {
    catSeg.segmentsOr =
      demographics.getSegmentPayloadForDemographics(segIdOrderIdMap);
    catIntSeg.segmentsOr =
      interests.getSegmentPayloadForDemographics(segIdOrderIdMap);
    catBehaviorSeg.segmentsOr =
      behaviours.getSegmentPayloadForDemographics(segIdOrderIdMap);
  }
  segMap.push(catSeg);
  segMap.push(catIntSeg);
  segMap.push(catBehaviorSeg);
  const resPayload = { segmentation: segMap };
  return resPayload;
}

export function dummyAPIResponseList() {
  const PanelFirstZoneList = {
    PanelFirstZoneList: [
      {
        segmentId: 1,
        segmentName: "Province: British Columbia",
        isSelected: false,
        segmentLabel: "From our Wireless data",
        Tabtype: 0,
      },
      {
        segmentId: 2,
        segmentName: "Female",
        isSelected: false,
        segmentLabel: "From our Wireless & Digital data",
        Tabtype: 0,
      },
      {
        segmentId: 3,
        segmentName: "Age 14-20",
        isSelected: false,
        segmentLabel: "From our Wireless data",
        Tabtype: 0,
      },
      {
        segmentId: 4,
        segmentName: "Age 58-60",
        isSelected: false,
        segmentLabel: "From our Wireless & Digital data",
        Tabtype: 0,
      },
      {
        segmentId: 5,
        segmentName: "Macleans",
        isSelected: false,
        segmentLabel: "From our Wireless data",
        Tabtype: 1,
      },
      {
        segmentId: 6,
        segmentName: "City TV",
        isSelected: false,
        segmentLabel: "From our Wireless & Digital data",
        Tabtype: 1,
      },
      {
        segmentId: 7,
        segmentName: "Chatelean ",
        isSelected: false,
        segmentLabel: "From our Wireless data",
        Tabtype: 1,
      },
      {
        segmentId: 8,
        segmentName: "City News",
        isSelected: false,
        segmentLabel: "From our Wireless & Digital data",
        Tabtype: 1,
      },
    ],
  };
  return PanelFirstZoneList;
}

export function parseList(segmentation: any, isAdvanceMode?: any) {
  const PanelFirstZoneList = {
    PanelFirstZoneList: [{}],
  };

  let grupedSegList: any[] = [];
  const TabType = "Tabtype";
  const flagSelected = "isSelected";
  const apiSelecteFlag = "selected";
  const linkedSegmentId = "linkedSegmentId";
  let tabIndex = 0;
  const apiResp = segmentation;
  for (var i = 0; i < apiResp.length; i++) {
    let obj = apiResp[i];
    const inlineItem = obj.segmentDetails;
    for (let key in inlineItem) {
      let segEntry = inlineItem[key];
      if (obj.category === "Demographics") {
        tabIndex = 0;
      } else if (obj.category === "Interests") {
        tabIndex = 1;
      } else if (obj.category === "Behaviours") {
        tabIndex = 2;
      }
      segEntry[TabType] = tabIndex;
      segEntry[flagSelected] = segEntry[apiSelecteFlag];
      if (!isAdvanceMode) {
        segEntry[linkedSegmentId] = [];
      }
      if (segEntry.orderId === 0) {
        segEntry.orderId = 9999;
      }
      grupedSegList.push(segEntry);
    }
  }
  grupedSegList = grupedSegList.sort(function (a, b) {
    return a.orderId - b.orderId;
  });
  let delSegId: any[] = [];
  let isTraitDeletedInAudienc = false;

  let segLinkArr = [" link", " link", " connector", " connector"];
  let linkClass = "linkClass";
  grupedSegList.map((segEntry: any, i: any) => {
    let isItDeleted = false;
    if (
      segEntry.isSelected === true &&
      segEntry.hasOwnProperty("recordStatus") &&
      segEntry.recordStatus == "D"
    ) {
      if (i >= 0) {
        if (i >= 1) {
          grupedSegList[i - 1].operator = segEntry.operator;
          grupedSegList[i - 1].linkDirection = AudienceSizerConst.LINKNONE;
        }
        isItDeleted = true;
        isTraitDeletedInAudienc = true;
        delSegId.push(segEntry.segmentId);
      }
    } else if (
      segEntry.isSelected === false &&
      segEntry.hasOwnProperty("recordStatus") &&
      segEntry.recordStatus === "D"
    ) {
      if (i >= 0) {
        delSegId.push(segEntry.segmentId);
      }
    }
    if (
      segEntry.hasOwnProperty("linkDirection") &&
      segEntry.linkDirection &&
      !isItDeleted
    ) {
      segEntry[linkClass] = segLinkArr[segEntry.linkDirection];
      segEntry[linkedSegmentId] = [];
      switch (segEntry.linkDirection) {
        case AudienceSizerConst.LINKUP:
          {
            segEntry[linkedSegmentId].push(grupedSegList[i - 1].segmentId);
          }
          break;
        case AudienceSizerConst.LINKBOTH:
          {
            segEntry[linkedSegmentId].push(grupedSegList[i + 1].segmentId);
            segEntry[linkedSegmentId].push(grupedSegList[i - 1].segmentId);
          }
          break;
        case AudienceSizerConst.LINKDOWN:
          {
            segEntry[linkedSegmentId].push(grupedSegList[i + 1].segmentId);
          }
          break;
        default:
          break;
      }
    } else {
      segEntry.linkedSegmentId = [];
    }
  });

  delSegId.map((segmentId: any, i: any) => {
    let index = grupedSegList.findIndex(
      (obj: any) => obj.segmentId === segmentId
    );
    if (index >= 0) {
      grupedSegList.splice(index, 1);
    }
  });

  PanelFirstZoneList.PanelFirstZoneList = grupedSegList;
  PanelFirstZoneList["isTraitDeletedInAudienc"] = isTraitDeletedInAudienc;
  return PanelFirstZoneList;
}

export function getIndex(list: any, itemIndex: any) {
  return list.findIndex((obj: any) => obj.segmentId === itemIndex);
}

export function getAudienceListFromResponse(responseData: any, uName: any) {
  const audienceList = new Audiences(responseData, uName);

  return audienceList.getAudienceList();
}

export function getPayloadForSaveAudience(
  uName: any,
  modelName: any,
  audienceList: any,
  sizingCountResp: any
) {
  const audiencePaylod = {
    audienceListCountsResponse: {
      segmentation: audienceList.segmentation,
      counts: sizingCountResp.counts,
    },
    userId: uName,
    templateName: modelName,
    templateId: "",
  };

  if (audienceList.hasOwnProperty("advanceModeSegmentation")) {
    let lable = "advanceModeSegmentation";
    audiencePaylod.audienceListCountsResponse[lable] =
      audienceList.advanceModeSegmentation;

    lable = "advanceMode";
    audiencePaylod[lable] = "Y";
  }

  return audiencePaylod;
}

export function isDropZoneEmpty(card: any) {
  return card.isSelected;
}

export function unLinkandRemove(list: any, card: any, prev: any, next: any) {
  let firstIndex = list.findIndex(
    (obj: any) => obj.segmentId == card.segmentId
  );
  if (firstIndex >= 0) {
    unLink(list, firstIndex, firstIndex, card.segmentId);
  }
  let nextIndex = list.findIndex((obj: any) => obj.segmentId == prev.segmentId);
  if (nextIndex >= 0) {
    unLink(list, nextIndex, firstIndex, card.segmentId);
  }
  let finalIndex = list.findIndex(
    (obj: any) => obj.segmentId == next.segmentId
  );
  if (finalIndex >= 0) {
    unLink(list, finalIndex, firstIndex, card.segmentId);
  }
}

export function unLink(list: any, index: any, nIndex: any, segmentId: any) {
  if (index === nIndex) {
    list[index].linkDirection = AudienceSizerConst.LINKNONE;
    list[index].linkedSegmentId = [];
  }
  if (
    list[index].linkDirection === AudienceSizerConst.LINKDOWN ||
    list[index].linkDirection === AudienceSizerConst.LINKUP
  ) {
    list[index].linkDirection = AudienceSizerConst.LINKNONE;
    list[index].linkedSegmentId = [];
  }
  if (list[index].linkDirection === AudienceSizerConst.LINKBOTH) {
    if (index < nIndex) {
      list[index].linkDirection = AudienceSizerConst.LINKDOWN;
    } else {
      list[index].linkDirection = AudienceSizerConst.LINKUP;
    }
    let innerIndex = list[index].linkedSegmentId.findIndex(
      (obj: any) => obj == segmentId
    );
    if (innerIndex >= 0) {
      list[index].linkedSegmentId.splice(innerIndex, 1);
    }
  }
}
