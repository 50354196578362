export function dayOfWeek(data: Record<string, string | boolean>[], mondayStarts: boolean = true): Record<string, string | boolean>[] {
    const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

    const tallies: Record<string, Record<string, string>> = {}
    for (const row of data) {
        var date = new Date(
            safeInt(row['Event Date'].toString().substr(0, 4)),
            safeInt(row['Event Date'].toString().substr(5, 2)) - 1,
            safeInt(row['Event Date'].toString().substr(8, 2)),
            0, 0, 0)
        
        var dow = dayNames[date.getDay()]

        if (dow in tallies) {
            tallies[dow]['Impressions'] = (safeInt(tallies[dow]['Impressions']) + safeInt(row['Impressions'].toString())).toString()
            if ('Clicks' in row) {
                tallies[dow]['Clicks'] = (safeInt(tallies[dow]['Clicks']) + safeInt(row['Clicks'].toString())).toString()
            }
            if ('sf_weighted_actions' in row) {
                tallies[dow]['sf_weighted_actions'] = (safeInt(tallies[dow]['sf_weighted_actions']) + safeInt(row['sf_weighted_actions'].toString())).toString()
            }
            if ('Video_Completes' in row) {
                if ('Video_Starts' in row) {
                    tallies[dow]['Video_Starts'] = (safeInt(tallies[dow]['Video_Starts']) + safeInt(row['Video_Starts'].toString())).toString()
                }
                tallies[dow]['Video_Midpoints'] = (safeInt(tallies[dow]['Video_Midpoints']) + safeInt(row['Video_Midpoints'].toString())).toString()
                tallies[dow]['Video_Completes'] = (safeInt(tallies[dow]['Video_Completes']) + safeInt(row['Video_Completes'].toString())).toString()
                if ('Video_First_Quartile' in row) {
                    tallies[dow]['Video_First_Quartile'] = (safeInt(tallies[dow]['Video_First_Quartile']) + safeInt(row['Video_First_Quartile'].toString())).toString()
                    tallies[dow]['Video_Third_Quartile'] = (safeInt(tallies[dow]['Video_Third_Quartile']) + safeInt(row['Video_Third_Quartile'].toString())).toString()
                }
            }
        }
        else {
            tallies[dow] = {}
            tallies[dow]['Impressions'] = row['Impressions'].toString()
            if ('Clicks' in row) {
                tallies[dow]['Clicks'] = row['Clicks'].toString()
            }
            else {
                tallies[dow]['Clicks'] = '0'
            }
            if ('sf_weighted_actions' in row) {
                tallies[dow]['sf_weighted_actions'] = row['sf_weighted_actions'].toString()
            }
            else {
                tallies[dow]['sf_weighted_actions'] = '0'
            }
            if ('Video_Completes' in row) {
                if ('Video_Starts' in row) {
                    tallies[dow]['Video_Starts'] = row['Video_Starts'].toString()
                }
                tallies[dow]['Video_Midpoints'] = row['Video_Midpoints'].toString()
                tallies[dow]['Video_Completes'] = row['Video_Completes'].toString()
                if ('Video_First_Quartile' in row) {
                    tallies[dow]['Video_First_Quartile'] = row['Video_First_Quartile'].toString()
                    tallies[dow]['Video_Third_Quartile'] = row['Video_Third_Quartile'].toString()
                }
            }
            else {
                tallies[dow]['Video_Starts'] = '0'
                tallies[dow]['Video_Midpoints'] = '0'
                tallies[dow]['Video_Completes'] = '0'
                tallies[dow]['Video_First_Quartile'] = '0'
                tallies[dow]['Video_Third_Quartile'] = '0'
            }
        }
    }
 
    var startMonday = mondayStarts ? dayNames.slice(1) : dayNames;
    if(mondayStarts) {
        startMonday.push(dayNames[0]);
    }
    var result: Record<string, string | boolean>[] = []
    for (const property of startMonday) {
        if (property in tallies)
            result.push({
                'Day': property,
                'Impressions': tallies[property]['Impressions'],
                'Clicks': tallies[property]['Clicks'],
                'sf_weighted_actions': tallies[property]['sf_weighted_actions'],
                'Video_Starts': tallies[property]['Video_Starts'],
                'Video_Midpoints': tallies[property]['Video_Midpoints'],
                'Video_Completes': tallies[property]['Video_Completes'],
                'Video_First_Quartile': tallies[property]['Video_First_Quartile'],
                'Video_Third_Quartile': tallies[property]['Video_Third_Quartile'],
            })
    }
    return result
}

export function weekOf(data: Record<string, string | boolean>[], startMonday: boolean = false): Record<string, string | boolean>[] {
    const tallies: Record<string, Record<string, string>> = {}
    for (const row of data) {
        var date = new Date(
            safeInt(row['Event Date'].toString().substr(0, 4)),
            safeInt(row['Event Date'].toString().substr(5, 2)) - 1,
            safeInt(row['Event Date'].toString().substr(8, 2)),
            0, 0, 0)

        var weekOf = new Date(date)
        weekOf.setDate(date.getDate() - date.getDay())
        if (startMonday) {
            if (date.getDay() !== 0) {
                weekOf.setDate(weekOf.getDate() + 1)
            }
            else {
                weekOf.setDate(weekOf.getDate() - 6)
            }
        }
        var weekKey = weekOf.getFullYear().toString() + '-' +
            (weekOf.getMonth() < 9 ? ('0' + (weekOf.getMonth() + 1)) : (weekOf.getMonth() + 1)) + '-' +
            (weekOf.getDate() < 10 ? ('0' + (weekOf.getDate())) : (weekOf.getDate()))

        if (weekKey in tallies) {
            if (tallies[weekKey]['Start_Date'] > row['Event Date'].toString())
                tallies[weekKey]['Start_Date'] = row['Event Date'].toString()
            if (tallies[weekKey]['End_Date'] < row['Event Date'].toString())
                tallies[weekKey]['End_Date'] = row['Event Date'].toString()
            tallies[weekKey]['Impressions'] = (safeInt(tallies[weekKey]['Impressions']) + safeInt(row['Impressions'].toString())).toString()
            if ('Clicks' in row) {
                tallies[weekKey]['Clicks'] = (safeInt(tallies[weekKey]['Clicks']) + safeInt(row['Clicks'].toString())).toString()
            }
            if ('sf_weighted_actions' in row) {
                tallies[weekKey]['sf_weighted_actions'] = (safeInt(tallies[weekKey]['sf_weighted_actions']) + safeInt(row['sf_weighted_actions'].toString())).toString()
            }

            if ('Video_Completes' in row) {
                if ('Video_Starts' in row) {
                    tallies[weekKey]['Video_Starts'] = (safeInt(tallies[weekKey]['Video_Starts']) + safeInt(row['Video_Starts'].toString())).toString()
                }
                tallies[weekKey]['Video_Midpoints'] = (safeInt(tallies[weekKey]['Video_Midpoints']) + safeInt(row['Video_Midpoints'].toString())).toString()
                tallies[weekKey]['Video_Completes'] = (safeInt(tallies[weekKey]['Video_Completes']) + safeInt(row['Video_Completes'].toString())).toString()
                if ('Video_First_Quartile' in row) {
                    tallies[weekKey]['Video_First_Quartile'] = (safeInt(tallies[weekKey]['Video_First_Quartile']) + safeInt(row['Video_First_Quartile'].toString())).toString()
                    tallies[weekKey]['Video_Third_Quartile'] = (safeInt(tallies[weekKey]['Video_Third_Quartile']) + safeInt(row['Video_Third_Quartile'].toString())).toString()
                }
            }
            if ('Engagements' in row && 'Time_Spent' in row) {
                tallies[weekKey]['Engagements'] = (safeInt(tallies[weekKey]['Engagements']) + safeInt(row['Engagements'].toString())).toString()
                tallies[weekKey]['Time_Spent'] = (safeInt(tallies[weekKey]['Time_Spent']) + safeInt(row['Time_Spent'].toString())).toString()
                tallies[weekKey]['Time_Spent_Engagements'] = (safeInt(tallies[weekKey]['Time_Spent_Engagements']) + safeInt(row['Time_Spent_Engagements'].toString())).toString()
            }
            if ('Interactions' in row) {
                tallies[weekKey]['Interactions'] = (safeInt(tallies[weekKey]['Interactions']) + safeInt(row['Interactions'].toString())).toString()
            }
            if ('tw_likes' in row && 'tw_follows' in row) {
                tallies[weekKey]['tw_likes'] = (safeInt(tallies[weekKey]['tw_likes']) + safeInt(row['tw_likes'].toString())).toString()
                tallies[weekKey]['tw_replies'] = (safeInt(tallies[weekKey]['tw_replies']) + safeInt(row['tw_replies'].toString())).toString()
                tallies[weekKey]['tw_media_engagements'] = (safeInt(tallies[weekKey]['tw_media_engagements']) + safeInt(row['tw_media_engagements'].toString())).toString()
                tallies[weekKey]['tw_tweet_engagement'] = (safeInt(tallies[weekKey]['tw_tweet_engagement']) + safeInt(row['tw_tweet_engagement'].toString())).toString()
                tallies[weekKey]['tw_video_views'] = (safeInt(tallies[weekKey]['tw_video_views']) + safeInt(row['tw_video_views'].toString())).toString()
                tallies[weekKey]['tw_link_clicks'] = (safeInt(tallies[weekKey]['tw_link_clicks']) + safeInt(row['tw_link_clicks'].toString())).toString()
                tallies[weekKey]['tw_retweets'] = (safeInt(tallies[weekKey]['tw_retweets']) + safeInt(row['tw_retweets'].toString())).toString()
                tallies[weekKey]['tw_follows'] = (safeInt(tallies[weekKey]['tw_follows']) + safeInt(row['tw_follows'].toString())).toString()
            }
            if ('fb_post_likes' in row && 'fb_video_p95_watched_actions' in row) {
                tallies[weekKey]['fb_post_likes'] = (safeInt(tallies[weekKey]['fb_post_likes']) + safeInt(row['fb_post_likes'].toString())).toString()
                tallies[weekKey]['fb_post_shares'] = (safeInt(tallies[weekKey]['fb_post_shares']) + safeInt(row['fb_post_shares'].toString())).toString()
                tallies[weekKey]['fb_post_engagements'] = (safeInt(tallies[weekKey]['fb_post_engagements']) + safeInt(row['fb_post_engagements'].toString())).toString()
                tallies[weekKey]['fb_post_comments'] = (safeInt(tallies[weekKey]['fb_post_comments']) + safeInt(row['fb_post_comments'].toString())).toString()
                tallies[weekKey]['fb_video_views'] = (safeInt(tallies[weekKey]['fb_video_views']) + safeInt(row['fb_video_views'].toString())).toString()
                tallies[weekKey]['fb_link_clicks'] = (safeInt(tallies[weekKey]['fb_link_clicks']) + safeInt(row['fb_link_clicks'].toString())).toString()
                tallies[weekKey]['fb_video_p95_watched_actions'] = (safeInt(tallies[weekKey]['fb_video_p95_watched_actions']) + safeInt(row['fb_video_p95_watched_actions'].toString())).toString()
            }
            if ('Reach' in row) {
                tallies[weekKey]['Reach'] = (safeInt(tallies[weekKey]['Reach']) + safeInt(row['Reach'].toString())).toString()
                if (row['Impressions'] !== '0') {
                    tallies[weekKey]['Days'] = (safeInt(tallies[weekKey]['Days']) + 1).toString()
                }
            }
        }
        else {
            tallies[weekKey] = {}
            tallies[weekKey]['Start_Date'] = row['Event Date'].toString()
            tallies[weekKey]['End_Date'] = row['Event Date'].toString()
            tallies[weekKey]['Impressions'] = row['Impressions'].toString()
            if('fb_video_views_definition' in row)
                tallies[weekKey]['fb_video_views_definition'] = row['fb_video_views_definition'].toString()
            else
            tallies[weekKey]['fb_video_views_definition'] = '';
            if ('Clicks' in row) {
                tallies[weekKey]['Clicks'] = row['Clicks'].toString()
            }
            else {
                tallies[weekKey]['Clicks'] = '0'
            }
            if ('sf_weighted_actions' in row) {
                tallies[weekKey]['sf_weighted_actions'] = row['sf_weighted_actions'].toString()
            }
            else {
                tallies[weekKey]['sf_weighted_actions'] = '0'
            }
            if ('Video_Completes' in row) {
                if ('Video_Starts' in row) {
                    tallies[weekKey]['Video_Starts'] = row['Video_Starts'].toString()
                }
                tallies[weekKey]['Video_Midpoints'] = row['Video_Midpoints'].toString()
                tallies[weekKey]['Video_Completes'] = row['Video_Completes'].toString()
                if ('Video_First_Quartile' in row) {
                    tallies[weekKey]['Video_First_Quartile'] = row['Video_First_Quartile'].toString()
                    tallies[weekKey]['Video_Third_Quartile'] = row['Video_Third_Quartile'].toString()
                }
            }
            else {
                tallies[weekKey]['Video_Starts'] = '0'
                tallies[weekKey]['Video_Midpoints'] = '0'
                tallies[weekKey]['Video_Completes'] = '0'
                tallies[weekKey]['Video_First_Quartile'] = '0'
                tallies[weekKey]['Video_Third_Quartile'] = '0'
            }
            if ('Engagements' in row && 'Time_Spent' in row) {
                tallies[weekKey]['Engagements'] = row['Engagements'].toString()
                tallies[weekKey]['Time_Spent'] = row['Time_Spent'].toString()
                tallies[weekKey]['Time_Spent_Engagements'] = row['Time_Spent_Engagements'].toString()
            }
            if ('Interactions' in row) {
                tallies[weekKey]['Interactions'] = row['Interactions'].toString()
            }
            else {
                tallies[weekKey]['Engagements'] = '0'
                tallies[weekKey]['Time_Spent'] = '0'
                tallies[weekKey]['Time_Spent_Engagements'] = '0'
            }
            if ('tw_likes' in row && 'tw_follows' in row) {
                tallies[weekKey]['tw_likes'] = row['tw_likes'].toString()
                tallies[weekKey]['tw_replies'] = row['tw_replies'].toString()
                tallies[weekKey]['tw_media_engagements'] = row['tw_media_engagements'].toString()
                tallies[weekKey]['tw_tweet_engagement'] = row['tw_tweet_engagement'].toString()
                tallies[weekKey]['tw_video_views'] = row['tw_video_views'].toString()
                tallies[weekKey]['tw_link_clicks'] = row['tw_link_clicks'].toString()
                tallies[weekKey]['tw_retweets'] = row['tw_retweets'].toString()
                tallies[weekKey]['tw_follows'] = row['tw_follows'].toString()
            }
            else {
                tallies[weekKey]['tw_likes'] = '0'
                tallies[weekKey]['tw_replies'] = '0'
                tallies[weekKey]['tw_media_engagements'] = '0'
                tallies[weekKey]['tw_tweet_engagement'] = '0'
                tallies[weekKey]['tw_video_views'] = '0'
                tallies[weekKey]['tw_link_clicks'] = '0'
                tallies[weekKey]['tw_retweets'] = '0'
                tallies[weekKey]['tw_follows'] = '0'

            }
            if ('fb_post_likes' in row && 'fb_video_p95_watched_actions' in row) {
                tallies[weekKey]['fb_post_likes'] = row['fb_post_likes'].toString()
                tallies[weekKey]['fb_post_shares'] = row['fb_post_shares'].toString()
                tallies[weekKey]['fb_post_engagements'] = row['fb_post_engagements'].toString()
                tallies[weekKey]['fb_post_comments'] = row['fb_post_comments'].toString()
                tallies[weekKey]['fb_video_views'] = row['fb_video_views'].toString()
                tallies[weekKey]['fb_link_clicks'] = row['fb_link_clicks'].toString()
                tallies[weekKey]['fb_video_p95_watched_actions'] = row['fb_video_p95_watched_actions'].toString()
            }
            else {
                tallies[weekKey]['fb_post_likes'] = '0'
                tallies[weekKey]['fb_post_shares'] = '0'
                tallies[weekKey]['fb_post_engagements'] = '0'
                tallies[weekKey]['fb_post_comments'] = '0'
                tallies[weekKey]['fb_video_views'] = '0'
                tallies[weekKey]['fb_link_clicks'] = '0'
                tallies[weekKey]['fb_video_p95_watched_actions'] = '0'
            }
            if ('Reach' in row) {
                tallies[weekKey]['Reach'] = row['Reach'].toString()
                if (row['Impressions'] !== '0') {
                    tallies[weekKey]['Days'] = '1'
                }
                else {
                    tallies[weekKey]['Days'] = '0'
                }
            }
            else {
                tallies[weekKey]['Reach'] = '0'
                tallies[weekKey]['Days'] = '0'
            }

        }
    }
    var result: Record<string, string | boolean>[] = []
    for (const property in tallies) {
        result.push({
            'Week': property,
            'Start_Date': tallies[property]['Start_Date'],
            'End_Date': tallies[property]['End_Date'],
            'Impressions': tallies[property]['Impressions'],
            'Clicks': tallies[property]['Clicks'],
            'sf_weighted_actions': tallies[property]['sf_weighted_actions'],
            'Video_Starts': tallies[property]['Video_Starts'],
            'Video_Midpoints': tallies[property]['Video_Midpoints'],
            'Video_Completes': tallies[property]['Video_Completes'],
            'Video_First_Quartile': tallies[property]['Video_First_Quartile'],
            'Video_Third_Quartile': tallies[property]['Video_Third_Quartile'],
            'Engagements': tallies[property]['Engagements'],
            'Interactions': tallies[property]['Interactions'],
            'Time_Spent': tallies[property]['Time_Spent'],
            'Time_Spent_Engagements': tallies[property]['Time_Spent_Engagements'],
            'tw_likes': tallies[property]['tw_likes'],
            'tw_replies': tallies[property]['tw_replies'],
            'tw_media_engagements': tallies[property]['tw_media_engagements'],
            'tw_tweet_engagement': tallies[property]['tw_tweet_engagement'],
            'tw_video_views': tallies[property]['tw_video_views'],
            'tw_link_clicks': tallies[property]['tw_link_clicks'],
            'tw_retweets': tallies[property]['tw_retweets'],
            'tw_follows': tallies[property]['tw_follows'],
            'fb_post_likes': tallies[property]['fb_post_likes'],
            'fb_post_shares': tallies[property]['fb_post_shares'],
            'fb_post_engagements': tallies[property]['fb_post_engagements'],
            'fb_post_comments': tallies[property]['fb_post_comments'],
            'fb_video_views_definition': tallies[property]['fb_video_views_definition'],
            'fb_video_views': tallies[property]['fb_video_views'],
            'fb_link_clicks': tallies[property]['fb_link_clicks'],
            'Average_Daily_Reach': tallies[property]['Days'] === '0' ? 'N/A' : (safeInt(tallies[property]['Reach']) / safeInt(tallies[property]['Days'])).toString(),
            'fb_video_p95_watched_actions': tallies[property]['fb_video_p95_watched_actions'],
        })
    }
    return result
}

function safeInt(txt: any) {
    return parseInt(String(txt).length > 0 ? String(txt) : '0')
}