import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getDomoAPIResponse,
  sendDomoQueryReesponse,
  sendDownloadDomoReports,
  slickStateAction,
  updateProgressCRM,
} from "src/Actions";
import CalendarControl from "src/CommonComponent/Calendar/CalendarControl";
// import { findDiffInDays } from "src/CommonComponent/Calendar/utils/CampaignUtil";
import CustomErrorAlertBanner from "src/CommonComponent/CustomErrorAlertBanner.";
import { Configs } from "src/ConstConfig";
import UserOps from "src/ConstConfig/UserOps";
import { getTomorrowsDate, getNewEndDate } from "src/Utility/CampaignUtil";
import DomoCampaignOrder from "../Model/DomoCampaignOrder";
import { clearAllErrorStateInReporting } from "../utils/dashboardValidation";
import StepsPowerPointReporting from "./StepsPowerPointReporting";
// const queryIds = require("../../data/domoQueryIds.json");

const PowerPointDomoReporting: React.FC<IPowerPointDomoReporting> = (props) => {
  const configs: any = new Configs();
  const [errorStateCheck] = useState(
    clearAllErrorStateInReporting()
  );
  const [accessType, setAccessType] = useState(0);

  useEffect(() => {
    const isRoleExist = props.UserAssignedRole?.roles?.indexOf(UserOps.REPORTINGPPT) >= 0 ? true : false;
    if(isRoleExist){
      setAccessType(2)
    props.resetScreenControls();
    const payload = {
      queryId: "PPTSummary",
      queryItems: { ORDERID: props.match?.params?.orderId, ADDEDCRITERIA: "" },
    };
    const domoQueryObj = {
      data: { url: configs.getUserDomoQueryUrl(), payload: payload },
      // data: { url: configs.getDomoQueryUrl(), payload: payload },
    };
    props.loadDomoData(domoQueryObj);
  }else{
      setAccessType(1);
    }
  }, []);

  const startDateCal = {
    Id: "startDate",
    NameId: "#startDateCal",
    name: "START DATE",
    identifier: "startDateCal",
  };
  const endDateCal = {
    Id: "endDate",
    NameId: "#endDateCal",
    name: "END DATE",
    identifier: "endDateCal",
  };


  const pWidth =
    (props.progressBarStatus.loaded / 100);
  const progress = {
    width: pWidth + "%",
  };
  if (props.domoResponse.isValidOrderId && accessType===2)
    return (
      <main role="main" className="container-fluid">
        <div className="row fixed-header-top ml-0 mr-0">
          <div className="col-12">
            <div className="float-left w-100 spacerB36  borderBottomGray">
              <div className="col-xl-7 col-md-12 col-sm-12 col-12 pl-0 pr-0">
                <h3 className="float-left">Reporting - PowerPoint</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="row-flex spaceTop">
        
          <div className="w-100">
            <form>
              <div className="col-xl-12 col-md-6 col-sm-8 col-12">
                <div className="form-group leftPos proposalSearch">
                  <label htmlFor="formName">
                    {props.domoResponse.reportLabel}
                  </label>
                </div>
              </div>

              <div className="col-xl-3 col-md-6 col-sm-12 col-12">
                <CalendarControl CalendarCtrl={startDateCal} />
              </div>
              <div className="col-xl-3 col-md-6 col-sm-12 col-12">
                <CalendarControl CalendarCtrl={endDateCal} />
              </div>
              <div className="col-12 spaceBottom">
              <CustomErrorAlertBanner
                    errorMessageStruct={errorStateCheck.reportDateError}
                  />
              </div>
              <StepsPowerPointReporting orderId={props.match?.params?.orderId} history={props.history}/>
              <div className="col-12 buttonPanel spaceBottom float-left">
              {props.progressBarStatus.total !== 1 && (
                <div className="graph-cont">
                  <span
                    className="bar-fill position-relative"
                    style={{ ...progress }}
                  >
                    <span className="txt-center">{props.progressBarStatus.text} {Math.trunc(pWidth)}%</span>
                  </span>
                </div>
              )}
                
              </div>
            
            </form>
          </div>
        </div>
      </main>
    );
    else return (!props.UIConfStats.isSpinnerActive && accessType===1 && <div className="row fixed-header-top ml-0 mr-0">
    <div className="col-12 spaceBottom blockCentered">
        <img src={require("../../svg/safety_icon.svg").default} />
        <h3 className="mb-4 mt-4">
            Access Denied
        </h3>
        <h4>You don't have access to this application</h4>
    </div>
    </div>);
};

function mapStateToProps(state: any, props: any) {
  return {
    UIConfStats: state.SpinnerState.hasOwnProperty("UIConfig") ? state.SpinnerState.UIConfig : { UIConfig: { isSpinnerActive: true } },
    usrSelectedDates:
      state.CalendarControlState.hasOwnProperty("data") &&
      state.CalendarControlState.data
        ? state.CalendarControlState.data
        : {
            startDate: {
              date: getTomorrowsDate(),
              campaignStartDate: getTomorrowsDate(),
            },
            endDate: {
              date: getNewEndDate(getTomorrowsDate()),
              campaignEndDate: getNewEndDate(getTomorrowsDate()),
            },
          },
    domoResponse:
      state.DomoQueryResponseState.hasOwnProperty("data") &&
      state.DomoQueryResponseState.data
        ? state.DomoQueryResponseState.data
        : {
            isValidOrderId: false,
            reportLabel: "",
            startDate: "",
            endDate: "",
          },
      progressBarStatus: state.ProgressCRMState.hasOwnProperty("data") ? state.ProgressCRMState.data : { loaded: 1, total: 1, text:"" },
      UserAssignedRole:
      state.AdminUserControlState.hasOwnProperty("UserProfile") &&
      state.AdminUserControlState.UserProfile
        ? state.AdminUserControlState.UserProfile
        : {
            email: "",
            company: null,
            userId: null,
            firstName: "",
            lastName: "",
            roles: ["User"],
          },
  };
}

export default connect(mapStateToProps, (dispatch) => {
  return {
    handleSlick(payload: any) {
      dispatch(slickStateAction(payload));
    },
    loadDomoData(payload: any) {
      dispatch(getDomoAPIResponse(payload));
    },
    triggerProgress() {
      dispatch(updateProgressCRM({ data: 
        {
            total:10,
            loaded:10,
            text:""
        }}));
    },
    resetScreenControls() {
      let domoStatus = new DomoCampaignOrder().getCampaignReportStructure(
        null,
        null
      );
      dispatch(sendDomoQueryReesponse({ data: domoStatus }));
      dispatch(updateProgressCRM({ data: 
        {
            total:1,
            loaded:0,
            text:""
        }}));
    },
    getExcel(payload: any) {
      dispatch(sendDownloadDomoReports(payload));
    },
  };
})(PowerPointDomoReporting);

interface IPowerPointDomoReporting extends React.FC<any> {
  editDomoConfigStat?: any;
  usrSelectedDates?: any;
  errorMessage?: any;
  handleSlick?: any;
  loadDomoData?: any;
  handleSave?: any;
  domoResponse?: any;
  resetScreenControls?: any;
  getExcel?: any;
  progressBarStatus?: any;
  triggerProgress?:any;
  match: any;
  history: any;
  UserAssignedRole?: any;
  UIConfStats?: any;
}
