/* eslint-disable */
import { createAction } from "redux-actions";
import ActionConstants from "./ConstConfig/ActionConstants";
export const setInitializing = createAction<void>("SET_INIT");
export const showErrorBox = createAction<any>("SHOW_ERROR_SCREEN");
export const incrementcounter = createAction<void>("INCREMENT");
export const userActions = createAction<any>(ActionConstants.UserLogin);
export const userCredentials = createAction<any>("USER_ENTRIES");
export const startLoader = createAction<any>("START_LOADER");
export const stopLoader = createAction<any>("STOP_LOADER");
export const slickStateAction = createAction<any>("CHANGE_SLICK");
export const CrmTraitAction = createAction<any>("CRMTRAITACTION");
export const AudienceTraitAction = createAction<any>("AUDIENCETRAITACTION");
export const configurationStateAction = createAction<any>(
  ActionConstants.ChangeConfig
);
export const addItemToDropZone = createAction<any>("DROP_INDDZONE");
export const submitSegmentRequest = createAction<any>("SEGMENT_REQ");
export const submitWizardAction = createAction<any>("WIZARDSTATS");
export const submiSizingChangeAction = createAction<any>("SIZING_RESPONSE");
export const submiDragAction = createAction<any>("DRAGITEM");
export const submitUIConfigAction = createAction<any>(ActionConstants.UIConfig);
export const submitSpinnerAction = createAction<any>(
  ActionConstants.SpinnerConfig
);
export const submitAdminSpinnerAction = createAction<any>(
  ActionConstants.AdminSpinnerConfig
);
export const submitAltSpinnerAction = createAction<any>(
  ActionConstants.AltSpinnerConfig
);
export const submitAudiSpinnerAction = createAction<any>(
  ActionConstants.AudienceSpinnerConfig
);
export const AudienceAction = createAction<any>(ActionConstants.Audiences);
export const AudienceActionAdmin = createAction<any>(
  ActionConstants.AdminAudiences
);
export const AudienceStatusAction = createAction<any>(
  ActionConstants.AudienceStatus
);
export const DashboardDateControlAction = createAction<any>(
  ActionConstants.DashboardDateControl
);
export const CampaignCtrlAction = createAction<any>(
  ActionConstants.CAMPAIGNCONTROL
);
export const sendEmailAction = createAction<any>(
  ActionConstants.CREATECAMPAIGN
);
export const setToggleStateAction = createAction<any>(
  ActionConstants.TOGGLE_BUTTON_STATE
);
export const sendPreflightDateAction = createAction<any>(
  ActionConstants.PREFLIGHT_GENDATA
);
export const sendLineChartAction = createAction<any>(ActionConstants.LINECHART);
export const calendarChangeAction = createAction<any>(
  ActionConstants.CALENDAR_STATE
);
export const adminCalendarChangeAction = createAction<any>(
  ActionConstants.ADMIN_CALENDAR_STATE
);
export const audienceTraitCalendarChangeAction = createAction<any>(
  ActionConstants.AUDIENCE_TRAIT_CALENDAR_STATE
);
export const sendAdminUserRoleAction = createAction<any>(
  ActionConstants.ADMIN_USER_FETCH_ROLE
);
export const sendAdminUserListAction = createAction<any>(
  ActionConstants.ADMIN_USER_LISTING
);
export const sendAdminUserAddAction = createAction<any>(
  ActionConstants.ADMIN_USER_ADD
);
export const sendAdminUserEditAction = createAction<any>(
  ActionConstants.ADMIN_USER_EDIT
);
export const sendAdminUserDeleteAction = createAction<any>(
  ActionConstants.ADMIN_USER_DELETE
);
export const sendAdminAudienceTraitListAction = createAction<any>(
  ActionConstants.ADMIN_TRAIT_LISTING
);
export const sendAdminAudienceTraitAddAction = createAction<any>(
  ActionConstants.ADMIN_TRAIT_ADD
);
export const sendAdminAudienceTraitEditAction = createAction<any>(
  ActionConstants.ADMIN_TRAIT_EDIT
);
export const sendAdminAudienceTraitDeleteAction = createAction<any>(
  ActionConstants.ADMIN_TRAIT_DELETE
);
export const sendAdminAudienceTraitPaginationAction = createAction<any>(
  ActionConstants.ADMIN_TRAIT_PAGINATION
);
export const searchReportAction = createAction<any>(
  ActionConstants.SEARCH_REPORT
);
export const reportResponseAction = createAction<any>(
  ActionConstants.REPORT_RESPONSE
);
export const downloadReportAction = createAction<any>(
  ActionConstants.REPORT_DOWNLOAD
);
export const displayReportListAction = createAction<any>(
  ActionConstants.REPORT_LISTRESPONSE
);
export const displayPacingReportListAction = createAction<any>(
  ActionConstants.PACINGREPORT_LISTRESPONSE
);
export const submitFlyoutConfigAction = createAction<any>(
  ActionConstants.FLYOUTCONFIG
);
export const uploadCMRFile = createAction<any>(ActionConstants.UPLOADCRM);
export const updateProgressCRM = createAction<any>(ActionConstants.PROGRESS);
export const updateCRMStateMessage = createAction<any>(ActionConstants.CRMMSG);
export const broadcastCRMStat = createAction<any>(ActionConstants.CRMLISTSTAT);
export const requestCRMStat = createAction<any>(ActionConstants.LISTALL_CRM);
export const getDomoAPIResponse = createAction<any>(
  ActionConstants.DOMOQUERYAPI
);
export const sendDomoQueryReesponse = createAction<any>(
  ActionConstants.DOMOQUERYAPIRESPONSE
);
export const sendDownloadDomoReports = createAction<any>(
  ActionConstants.DWONLOAD_EXCEL
);
export const sendDownloadPPTDomoReports = createAction<any>(
  ActionConstants.DWONLOAD_DOMOPPT
);
export const senDomoAccessLog = createAction<any>(ActionConstants.DOMOSTATS);
export const initiateForcastRequest = createAction<any>(
  ActionConstants.GETFORCAST
);
export const sendForcastResponse = createAction<any>(
  ActionConstants.RESPONSEFORCAST
);
export const reportingStatusAction = createAction<any>(
  ActionConstants.REPORTINGSTATUS
);
export const saveReportingStatusAction = createAction<any>(
  ActionConstants.SETREPORTINGSTATUS
);
export const updateCRMCanList = createAction<any>(ActionConstants.CRMCANLIST);
export const updateDashboardCharts = createAction<any>(
  ActionConstants.DASHBOARD_CHARTS
);
export const sendAdminCynchAttributeListAction = createAction<any>(
  ActionConstants.CYNCHATTRIBUTES
);
export const sendCynchCategoryAction = createAction<any>(
  ActionConstants.CYNCHATTRIUPDATE
);
export const sendCynchOperationAction = createAction<any>(
  ActionConstants.CYNCHOPERATION
);
export const sendEditCynchAttribute = createAction<any>(
  ActionConstants.CYNCHEDIT
);
export const sendParentStateUpdated = createAction<any>(
  ActionConstants.CYNCHCATPARENT
);
export const getAllQuerySegments = createAction<any>(
  ActionConstants.QUERYSEGMENTIDS
);
export const dispatchAllQuerySegments = createAction<any>(
  ActionConstants.QUERYSEGMENTIDSLIST
);
export const dispatchSaveQuerySegments = createAction<any>(
  ActionConstants.QUERYSEGMENTIDSAVE
);
export const editQuerySegmentsAction = createAction<any>(
  ActionConstants.QUERYSEGMENTIDEDIT
);
export const getAllTVOrders = createAction<any>(
  ActionConstants.LISTALLTVORDERS
);
export const dispatchAllTVOrders = createAction<any>(
  ActionConstants.DISPLAYALLTVORDERS
);
export const dispatchSaveTVOrders = createAction<any>(
  ActionConstants.SAVETVORDER
);
export const deleteTVOrders = createAction<any>(ActionConstants.DELETETVORDER);
export const editTVOrdersAction = createAction<any>(
  ActionConstants.EDITTVORDERS
);
export const getCoreConfiguration = createAction<any>(
  ActionConstants.LISTALLCONFIG
);
export const sendCoreConfiguration = createAction<any>(
  ActionConstants.LOADCONFIG
);
export const sendCoreConfigurationToReporting = createAction<any>(
  ActionConstants.LOADCONFIG
);
export const sendSaveCoreConfiguration = createAction<any>(
  ActionConstants.SAVECONFIG
);
export const getDomoConfiguration = createAction<any>(
  ActionConstants.LISTDOMOCONFIG
);
export const sendDomoConfiguration = createAction<any>(
  ActionConstants.LOADDOMOCONFIG
);
export const sendSaveDomoConfiguration = createAction<any>(
  ActionConstants.SAVEDOMOCONFIG
);
export const getAllDomoQueires = createAction<any>(
  ActionConstants.LISTDOMOQUERY
);
export const dispatchAllDomoQueries = createAction<any>(
  ActionConstants.DISPLAYALLDOMOQUERIES
);
export const saveDomoQuery = createAction<any>(ActionConstants.SAVEDOMOQUERY);

export const deleteDomoQuery = createAction<any>(
  ActionConstants.DELETEDOMOQUERY
);
export const editDomoQuery = createAction<any>(ActionConstants.EDITDOMOQUERY);
export const sendRoleBasedConfiguration = createAction<any>(
  ActionConstants.ROLECONFIG
);
export const dispatchTestUserQuerySegments = createAction<any>(
  ActionConstants.TESTUSERQUERY
);
export const testUserQuerySegmentsFailed = createAction<any>(
  ActionConstants.TESTUSERQUERYFAILED
);
export const saveUserDomoQuery = createAction<any>(
  ActionConstants.SAVEUSERDOMOQUERY
);
export const resetDomoTestData = createAction<any>(
  ActionConstants.RESETDOMOTESTDATA
);
