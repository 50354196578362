/* eslint-disable */
import { combineReducers } from "redux";
import { handleActions } from "redux-actions";
import ActionConstants from "../../ConstConfig/ActionConstants";

const rootReducer = combineReducers<any>({
  slickState: handleActions<any>(
    {
      ["CHANGE_SLICK"]: (state, { payload: data }) => {
        return { ...state, data };
      },
    },
    { count: 0 }
  ),
  showErrorBoxState: handleActions<any>(
    {
      ["SHOW_ERROR_SCREEN"]: (state, { payload: data }) => data,
    },
    { count: 0 }
  ),

  configState: handleActions<any>(
    {
      [ActionConstants.UIConfig]: (state, { payload: data }) => data,
    },
    { count: 0 }
  ),

  FlyoutConfigState: handleActions<any>(
    {
      [ActionConstants.FLYOUTCONFIG]: (state, { payload: data }) => data,
    },
    { count: 0 }
  ),

  SpinnerState: handleActions<any>(
    {
      [ActionConstants.SpinnerConfig]: (state, { payload: data }) => data,
    },
    { count: 0 }
  ),

  AdminSpinnerState: handleActions<any>(
    {
      [ActionConstants.AdminSpinnerConfig]: (state, { payload: data }) => data,
    },
    { count: 0 }
  ),

  userAuth: handleActions<any>(
    {
      ["USER_ENTRIES"]: (state, { payload: data }) => {
        return { ...state, data };
      },
      [ActionConstants.UserLogin]: (state, { payload: data }) => {
        return { ...state, data };
      },
    },
    { count: 0 }
  ),
  WizardStateHandle: handleActions<any>(
    {
      ["SEGMENT_REQ"]: (state, { payload: data }) => {
        return { ...state, data };
      },
    },
    { count: 0 }
  ),
  WizardPageStats: handleActions<any>(
    {
      ["WIZARDSTATS"]: (state, { payload: data }) => {
        return { ...state, data };
      },
    },
    { count: 0 }
  ),
  SizingCountStats: handleActions<any>(
    {
      ["SIZING_RESPONSE"]: (state, { payload: data }) => {
        return { ...state, data };
      },
    },
    { count: 0 }
  ),
  AudiencesState: handleActions<any>(
    {
      [ActionConstants.Audiences]: (state, { payload: data }) => data,
    },
    { count: 0 }
  ),
  AudiencesStateAdmin: handleActions<any>(
    {
      [ActionConstants.AdminAudiences]: (state, { payload: data }) => data,
    },
    { count: 0 }
  ),
  AudiencesStatusState: handleActions<any>(
    {
      [ActionConstants.AudienceStatus]: (state, { payload: data }) => data,
    },
    { count: 0 }
  ),
  DashboardState: handleActions<any>(
    {
      [ActionConstants.DashboardDateControl]: (state, { payload: data }) =>
        data,
    },
    { count: 0 }
  ),
  CampaignState: handleActions<any>(
    {
      [ActionConstants.CAMPAIGNCONTROL]: (state, { payload: data }) => data,
      [ActionConstants.CREATECAMPAIGN]: (state, { payload: data }) => data,
    },
    { count: 0 }
  ),
  toggleSwitchState: handleActions<any>(
    {
      [ActionConstants.TOGGLE_BUTTON_STATE]: (state, { payload: data }) => data,
    },
    { count: 0 }
  ),
  DashboardPreflightDataState: handleActions<any>(
    {
      [ActionConstants.PREFLIGHT_GENDATA]: (state, { payload: data }) => data,
    },
    { count: 0 }
  ),
  LineChartDataState: handleActions<any>(
    {
      [ActionConstants.LINECHART]: (state, { payload: data }) => data,
    },
    { count: 0 }
  ),
  CalendarControlState: handleActions<any>(
    {
      [ActionConstants.CALENDAR_STATE]: (state, { payload: data }) => {
        return { ...state, data };
      },
    },
    { count: 0 }
  ),
  AdminCalendarControlState: handleActions<any>(
    {
      [ActionConstants.ADMIN_CALENDAR_STATE]: (state, { payload: data }) => {
        return { ...state, data };
      },
    },
    { count: 0 }
  ),
  AudienceTraitCalendarControlState: handleActions<any>(
    {
      [ActionConstants.AUDIENCE_TRAIT_CALENDAR_STATE]: (
        state,
        { payload: data }
      ) => {
        return { ...state, data };
      },
    },
    { count: 0 }
  ),
  AdminUserControlState: handleActions<any>(
    {
      [ActionConstants.ADMIN_USER_FETCH_ROLE]: (state, { payload: data }) =>
        data,
    },
    { count: 0 }
  ),
  AdminUserEditControlState: handleActions<any>(
    {
      [ActionConstants.ADMIN_USER_EDIT]: (state, { payload: data }) => data,
    },
    { count: 0 }
  ),
  AdminUserDeleteControlState: handleActions<any>(
    {
      [ActionConstants.ADMIN_USER_DELETE]: (state, { payload: data }) => data,
    },
    { count: 0 }
  ),

  AdminUserAddControlState: handleActions<any>(
    {
      [ActionConstants.ADMIN_USER_ADD]: (state, { payload: data }) => data,
    },
    { count: 0 }
  ),
  AdminUserUserListControlState: handleActions<any>(
    {
      [ActionConstants.ADMIN_USER_LISTING]: (state, { payload: data }) => data,
    },
    { count: 0 }
  ),
  AdminUserTraitControlState: handleActions<any>(
    {
      [ActionConstants.ADMIN_TRAIT_ADD]: (state, { payload: data }) => data,
    },
    { count: 0 }
  ),
  AdminUserTraitListControlState: handleActions<any>(
    {
      [ActionConstants.ADMIN_TRAIT_LISTING]: (state, { payload: data }) => data,
    },
    { count: 0 }
  ),
  AdminUserTraitEditControlState: handleActions<any>(
    {
      [ActionConstants.ADMIN_TRAIT_EDIT]: (state, { payload: data }) => data,
    },
    { count: 0 }
  ),
  AdminUserTraitDeleteControlState: handleActions<any>(
    {
      [ActionConstants.ADMIN_TRAIT_DELETE]: (state, { payload: data }) => data,
    },
    { count: 0 }
  ),
  ReportingControlState: handleActions<any>(
    {
      [ActionConstants.REPORT_RESPONSE]: (state, { payload: data }) => data,
    },
    { count: 0 }
  ),
  ReportingListState: handleActions<any>(
    {
      [ActionConstants.REPORT_LISTRESPONSE]: (state, { payload: data }) => data,
    },
    { count: 0 }
  ),
  ReportingDownloadState: handleActions<any>(
    {
      [ActionConstants.REPORT_DOWNLOAD]: (state, { payload: data }) => data,
    },
    { count: 0 }
  ),
  AdminUserTraitListPaginationControlState: handleActions<any>(
    {
      [ActionConstants.ADMIN_TRAIT_PAGINATION]: (state, { payload: data }) =>
        data,
    },
    { count: 0 }
  ),
  UploadCRMState: handleActions<any>(
    {
      [ActionConstants.UPLOADCRM]: (state, { payload: data }) => data,
    },
    { count: 0 }
  ),
  ProgressCRMState: handleActions<any>(
    {
      [ActionConstants.PROGRESS]: (state, { payload: data }) => data,
    },
    { count: 0 }
  ),
  CRMSMessagetate: handleActions<any>(
    {
      [ActionConstants.CRMMSG]: (state, { payload: data }) => data,
    },
    { count: 0 }
  ),
  CRMSListState: handleActions<any>(
    {
      [ActionConstants.CRMLISTSTAT]: (state, { payload: data }) => data,
    },
    { count: 0 }
  ),
  RoleBasedConfiguration: handleActions<any>(
    {
      [ActionConstants.ROLECONFIG]: (state, { payload: data }) => data,
    },
    { count: 0 }
  ),

  CRMCanListState: handleActions<any>(
    {
      [ActionConstants.CRMCANLIST]: (state, { payload: data }) => data,
    },
    { count: 0 }
  ),

  DashbardChartStats: handleActions<any>(
    {
      [ActionConstants.DASHBOARD_CHARTS]: (state, { payload: data }) => data,
    },
    { count: 0 }
  ),
  AdminCynchAttribListControlState: handleActions<any>(
    {
      [ActionConstants.CYNCHATTRIBUTES]: (state, { payload: data }) => data,
    },
    { count: 0 }
  ),
  AdminCynchAttribCategoryState: handleActions<any>(
    {
      [ActionConstants.CYNCHATTRIUPDATE]: (state, { payload: data }) => data,
    },
    { count: 0 }
  ),
  AdminCynchAttribOperationState: handleActions<any>(
    {
      [ActionConstants.CYNCHOPERATION]: (state, { payload: data }) => data,
    },
    { count: 0 }
  ),
  AdminCynchAttribEditOperationState: handleActions<any>(
    {
      [ActionConstants.CYNCHEDIT]: (state, { payload: data }) => data,
    },
    { count: 0 }
  ),
  AdminCynchAttribParentUpdateState: handleActions<any>(
    {
      [ActionConstants.CYNCHCATPARENT]: (state, { payload: data }) => data,
    },
    { count: 0 }
  ),
  AdminQueryAudienceListState: handleActions<any>(
    {
      [ActionConstants.QUERYSEGMENTIDSLIST]: (state, { payload: data }) => data,
    },
    { count: 0 }
  ),
  AdminQueryAudienceSaveState: handleActions<any>(
    {
      [ActionConstants.QUERYSEGMENTIDSAVE]: (state, { payload: data }) => data,
    },
    { count: 0 }
  ),
  AdminQueryAudienceEditState: handleActions<any>(
    {
      [ActionConstants.QUERYSEGMENTIDEDIT]: (state, { payload: data }) => data,
    },
    { count: 0 }
  ),
  AdminTVOrdersListState: handleActions<any>(
    {
      [ActionConstants.DISPLAYALLTVORDERS]: (state, { payload: data }) => data,
    },
    { count: 0 }
  ),
  AdminTVOrdersSaveState: handleActions<any>(
    {
      [ActionConstants.SAVETVORDER]: (state, { payload: data }) => data,
    },
    { count: 0 }
  ),
  AdminTVOrdersEditState: handleActions<any>(
    {
      [ActionConstants.EDITTVORDERS]: (state, { payload: data }) => data,
    },
    { count: 0 }
  ),
  AdminTVOrdersDeleteState: handleActions<any>(
    {
      [ActionConstants.DELETETVORDER]: (state, { payload: data }) => data,
    },
    { count: 0 }
  ),
  AdminCoreConfigListState: handleActions<any>(
    {
      [ActionConstants.LOADCONFIG]: (state, { payload: data }) => data,
    },
    { count: 0 }
  ),
  AdminCoreConfigSaveState: handleActions<any>(
    {
      [ActionConstants.SAVECONFIG]: (state, { payload: data }) => data,
    },
    { count: 0 }
  ),

  AdminCoreConfigGetState: handleActions<any>(
    {
      [ActionConstants.LISTALLCONFIG]: (state, { payload: data }) => data,
    },
    { count: 0 }
  ),
  AdminDomoConfigListState: handleActions<any>(
    {
      [ActionConstants.LOADDOMOCONFIG]: (state, { payload: data }) => data,
    },
    { count: 0 }
  ),
  AdminDomoConfigSaveState: handleActions<any>(
    {
      [ActionConstants.SAVEDOMOCONFIG]: (state, { payload: data }) => data,
    },
    { count: 0 }
  ),

  AdminDomoConfigGetState: handleActions<any>(
    {
      [ActionConstants.LISTDOMOCONFIG]: (state, { payload: data }) => data,
    },
    { count: 0 }
  ),

  AdminDomoQueryListState: handleActions<any>(
    {
      [ActionConstants.DISPLAYALLDOMOQUERIES]: (state, { payload: data }) =>
        data,
    },
    { count: 0 }
  ),
  AdminDomoSaveState: handleActions<any>(
    {
      [ActionConstants.SAVEDOMOQUERY]: (state, { payload: data }) => data,
    },
    { count: 0 }
  ),
  AdminDomoQueryEditState: handleActions<any>(
    {
      [ActionConstants.EDITDOMOQUERY]: (state, { payload: data }) => data,
    },
    { count: 0 }
  ),
  AdminDomoQueryDeleteState: handleActions<any>(
    {
      [ActionConstants.DELETEDOMOQUERY]: (state, { payload: data }) => data,
    },
    { count: 0 }
  ),
  ReportingConfigState: handleActions<any>(
    {
      [ActionConstants.REPORTINGSTATUS]: (state, { payload: data }) => data,
      [ActionConstants.SETREPORTINGSTATUS]: (state, { payload: data }) => data,
    },
    { count: 0 }
  ),

  AdminUserDomoListState: handleActions<any>(
    {
      [ActionConstants.TESTUSERQUERY]: (state, { payload: data }) => data,
    },
    { count: 0 }
  ),

  UserTestQueryErrorMessage: handleActions<any>(
    {
      [ActionConstants.TESTUSERQUERYFAILED]: (
        state,
        { payload: userTestQueryErrorMessage }
      ) => {
        return { userTestQueryErrorMessage };
      },
    },
    { count: 0 }
  ),

  PacingReportingListState: handleActions<any>(
    {
      [ActionConstants.PACINGREPORT_LISTRESPONSE]: (state, { payload: data }) =>
        data,
    },
    { count: 0 }
  ),

  ReportingDomoAccessState: handleActions<any>(
    {
      [ActionConstants.DOMOSTATS]: (state, { payload: data }) => data,
    },
    { count: 0 }
  ),

  LinOptForcastState: handleActions<any>(
    {
      [ActionConstants.GETFORCAST]: (state, { payload: data }) => data,
    },
    { count: 0 }
  ),
  LinOptForcastResponseState: handleActions<any>(
    {
      [ActionConstants.RESPONSEFORCAST]: (state, { payload: data }) => data,
    },
    { count: 0 }
  ),
  DomoQueryState: handleActions<any>(
    {
      [ActionConstants.DOMOQUERYAPI]: (state, { payload: data }) => data,
    },
    { count: 0 }
  ),
  DomoQueryResponseState: handleActions<any>(
    {
      [ActionConstants.DOMOQUERYAPIRESPONSE]: (state, { payload: data }) =>
        data,
    },
    { count: 0 }
  ),
  DomoExcelReportState: handleActions<any>(
    {
      [ActionConstants.DWONLOAD_EXCEL]: (state, { payload: data }) => data,
    },
    { count: 0 }
  ),
  DomoPPTReportState: handleActions<any>(
    {
      [ActionConstants.DWONLOAD_DOMOPPT]: (state, { payload: data }) => data,
    },
    { count: 0 }
  ),
  SecondSpinnerState: handleActions<any>(
    {
      [ActionConstants.AltSpinnerConfig]: (state, { payload: data }) => data,
      [ActionConstants.AudienceSpinnerConfig]: (state, { payload: data }) =>
        data,
    },
    { count: 0 }
  ),
});

export default rootReducer;
