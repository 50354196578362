import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Configs } from "src/ConstConfig";
import {
  saveUserDomoQuery,
  submitUIConfigAction,
  resetDomoTestData,
  testUserQuerySegmentsFailed,
} from "../Actions";
import { List } from "immutable";
import Table from "react-bootstrap/Table";

const modalProps = require("./MessageboxStyles.json");

const DomoDialog: React.FC<IDialog> = (props) => {
  const [orderID, setOrderID] = useState<string>("");
  const [additialCriteria, setAdditialCriteria] = useState<string>("");

  function saveOrderID(e: any) {
    setOrderID(e.target.value);
  }

  function saveAdditainalCriteria(e: any) {
    setAdditialCriteria(e.target.value);
  }
  const config = new Configs();

  const confirmTestDomo = () => {
    let payload = {
      data: {
        url: config.getUserDomoQueryUrl(),
        payload: {
          queryId: props.queryId,
          queryItems: {
            ADDEDCRITERIA: additialCriteria,
            ORDERID: orderID,
          },
        },
      },
    };
    props.handleSubmit(payload);
  };

  const handleClose = () => {
    setOrderID("");
    setAdditialCriteria("");
    props.resetDomoTestData();
    props.resetErrorMessage();
  };

  const displayTable = () => {
    return (
      <Table striped={true} bordered={true} hover={true} size="sm">
        <thead>
          <tr>
            {props.DomoTestQueryDataList?.returnVal?.columns?.map(
              (column: any) => (
                <th>{column}</th>
              )
            )}
          </tr>
        </thead>
        <tbody>
          {props.DomoTestQueryDataList?.returnVal?.rows?.map((row: any) => (
            <tr>
              {row.map((r: any) => (
                <td>{r}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  useEffect(() => {}, [props.ErrorMessage]);

  return (
    <div
      className={modalProps.fadeClass + " domoModal"}
      id="testDomoModal"
      role="dialog"
      aria-labelledby="genericModalRedUI"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-lg container-fluid"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title domo-modal" id="genericModalRedUI">
              <span className="spacerR16">DOMO QUERY TESTING</span>
              <div className="domoTestWarning">
                <span className="spacerR16">
                  Note: the tester will run the query as it exists in the
                  database and will not reflect unsaved changes
                </span>
              </div>
              <div>
                <span style={{ color: "#DA291C" }}>
                  {props.ErrorMessage.userTestQueryErrorMessage}
                </span>
              </div>
              <div className="row-flex spaceTop">
                <div className="w-100">
                  <form className="template">
                    <div className="col-12">
                      <div className="form-group leftPos">
                        <label htmlFor="formOrderID">Order ID</label>
                        <input
                          type="text"
                          className="form-control"
                          id="formOrderID"
                          placeholder="Type order id here"
                          onChange={saveOrderID}
                          value={orderID}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group leftPos">
                        <label htmlFor="formDataSetID">
                          Additional criteria
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="formDataSetID"
                          placeholder="Type additional criteria here"
                          onChange={saveAdditainalCriteria}
                          value={additialCriteria}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </h5>
          </div>
          <div style={{ overflow: "auto", margin: 40 }}>
            {props.DomoTestQueryDataList?.returnVal?.rows?.length > 0
              ? displayTable()
              : null}
          </div>
          <div className="modal-footer alignCenter">
            <button
              type="button"
              className="btn btnPrimary spacerR12"
              onClick={confirmTestDomo}
            >
              Continue
            </button>
            <button
              type="button"
              id="btnModalClose"
              className="btn btnPrimary spacerL12"
              data-dismiss="modal"
              onClick={handleClose}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state: any, props: any) {
  return {
    errorMessage: state.showErrorBoxState.errorMessage,
    UIDialogstats: state.configState.hasOwnProperty("Dialog")
      ? state.configState.Dialog
      : { isVisible: false },
    DomoTestQueryDataList: state.AdminUserDomoListState.hasOwnProperty("data")
      ? state.AdminUserDomoListState.data
      : List([]),
    ErrorMessage: state.UserTestQueryErrorMessage.userTestQueryErrorMessage
      ? state.UserTestQueryErrorMessage.userTestQueryErrorMessage
      : "",
  };
}

export default connect(mapStateToProps, (dispatch) => {
  return {
    handleSubmit(payload: any) {
      dispatch(saveUserDomoQuery(payload));
    },
    handleDialogSubmitAction: (show: boolean) => {
      const payload = { Dialog: { isVisible: show } };
      dispatch(submitUIConfigAction(payload));
    },
    handleDomoDialogSubmitAction: (show: boolean, deleteSegment: any) => {
      const payload = {
        Dialog: {
          isVisible: false,
          confirmAction: show,
          content: deleteSegment,
        },
      };
      dispatch(submitUIConfigAction(payload));
    },
    resetDomoTestData: () => {
      dispatch(resetDomoTestData(1));
    },
    resetErrorMessage: () => {
      dispatch(
        testUserQuerySegmentsFailed({
          userTestQueryErrorMessage: "",
        })
      );
    },
  };
})(DomoDialog);

export interface IDialog extends React.FC<any> {
  handleSubmit?: any;
  UIDialogstats?: any;
  handleClose?: any;
  errorMessage?: any;
  handleDialogSubmitAction?: any;
  handleDomoDialogSubmitAction: any;
  handleUserAction?: any;
  queryId: String;
  DomoTestQueryDataList: any;
  resetDomoTestData: any;
  ErrorMessage: any;
  resetErrorMessage: any;
}
