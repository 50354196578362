import CampaignEleConstants from "src/ConstConfig/CampaignEleConstants";

export function validateCredentials() {
  return true;
}

export function getAudienceCreationDate(date: any) {
  return date;
}

export function convertToDate(date: any, toUTC = false, toMDY = false) {
  if (date && date !== "") {
    const crdate = new Date(date.replace(/-/g, "/"));
    const month = crdate.getMonth() + 1;
    const mnth = month <= 9 ? "0" + month : month;
    if (toUTC) {
      crdate.setDate(crdate.getDate() + 1);
    }
    const day =
      crdate.getDate() <= 9 ? "0" + crdate.getDate() : crdate.getDate();

    if (toMDY) {
      return mnth + "-" + day + "-" + crdate.getFullYear();
    }
    const respDate = crdate.getFullYear() + "-" + mnth + "-" + day;

    return respDate;
  }
  return "";
}

export function getMMDDYYYY(date: any) {}

export function getPageNumber(
  direction: any,
  pageNum: any,
  pageObject: any,
  pageProps: any
) {
  if (direction === "prev") {
    pageObject.currPage -= 1;
  } else if (direction === "next") {
    pageObject.currPage += 1;
  } else if (direction === "first") {
    pageObject.currPage = 1;
  } else if (direction === "last") {
    pageObject.currPage = pageProps.totalPages;
  } else if (direction === "pageSwitch") {
    pageObject.currPage = pageNum;
  }
  if (pageObject.currPage >= pageProps.totalPages) {
    pageObject.currPage = pageProps.totalPages;
  }
  if (pageObject.currPage <= 1) {
    pageObject.currPage = 1;
  }

  return pageObject;
}

export function getPaginationMatrices(pagination: any, direction: any) {
  if (direction === "prev") {
    return pagePrev(pagination);
  } else if (direction === "next") {
    return pageNext(pagination);
  } else if (direction === "first") {
    return pagFirst(pagination);
  } else if (direction === "last") {
    return pageLast(pagination);
  } else if (direction === "pageSwitch") {
    return pagination;
  }
}

export function pageNext(pagination: any) {
  const pageShift = 10;
  if (pagination.showMaxPage <= 11 && pagination.currPage < 11) {
  } else {
    pagination.showMaxPage += 1;
    if (pagination.showMaxPage >= pagination.maxPageSize) {
      pagination.showMaxPage = pagination.maxPageSize;
    }
    pagination.pageStartIndex = pagination.showMaxPage - pageShift;
  }
  return pagination;
}

export function pagePrev(pagination: any) {
  const pageShift = 10;

  if (pagination.showMaxPage <= 11 && pagination.currPage < 11) {
  } else {
    pagination.showMaxPage -= 1;

    pagination.pageStartIndex = pagination.showMaxPage - pageShift;
  }
  return pagination;
}

export function pageLast(pagination: any) {
  const pageShift = 11;

  if (pagination.maxPageSize <= 11) {
    pagination.showMaxPage = pagination.maxPageSize;
  } else {
    pagination.showMaxPage = pagination.maxPageSize;
    pagination.pageStartIndex = pagination.showMaxPage - pageShift;
  }
  return pagination;
}

export function pagFirst(pagination: any) {
  const pageShift = 11;

  if (pagination.maxPageSize <= 11) {
    pagination.showMaxPage = pagination.maxPageSize;
  } else {
    pagination.showMaxPage = pageShift;
    pagination.pageStartIndex = 1;
  }
  return pagination;
}

export function clearAllErrorStateInCynch() {
  const errStateObj = {
    AttributeNameError: {
      show: false,
      errFlyoutIdentifier: "ATTRIBUTENAME",
      cssAttrib: "  error ",
    },
    AttributeDescError: {
      show: false,
      errFlyoutIdentifier: "ATTRIBUTEDESC",
      cssAttrib: " error ",
    },
    AttributeCatError: {
      show: false,
      errFlyoutIdentifier: "ATTRIBUTECAT",
      cssAttrib: "error ",
    },
    AttributeDuplicateCatError: {
      show: false,
      errFlyoutIdentifier: "DUPATTRIBUTECAT",
      cssAttrib: "error ",
    },
  };
  return errStateObj;
}

export function isValidEmailAddress(email: any) {
  const emailFilter =
    /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,4})+$/;
  if (!emailFilter.test(email)) {
    return false;
  }
  return true;
}

export function formatBytes(bytes: any, decimals = 2) {
  if (!bytes || bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export function getTraitTypeText(traitType: any) {
  let selectedIndex = { indx: 0, title: "ADOBE" };
  switch (traitType.toUpperCase()) {
    case "S":
    case "CUSTOM":
      {
        selectedIndex = { indx: 1, title: "CUSTOM" };
      }
      break;
    case "D":
    case "ADOBE":
      {
        selectedIndex = { indx: 0, title: "ADOBE" };
      }
      break;
    case "P":
    case "PRIZM":
      {
        selectedIndex = { indx: 2, title: "PRIZM" };
      }
      break;
    case "TEMPLATE":
    case "T":
      {
        selectedIndex = { indx: 3, title: "TEMPLATE" };
      }
      break;
    case "CRM CANS":
    case "C":
      {
        selectedIndex = { indx: 4, title: "CRM CANS" };
      }
      break;
    case "CRM CANS":
    case "C":
      {
        selectedIndex = { indx: 4, title: "CRM CANS" };
      }
      break;
    case "TV EXPOSURE":
    case "F":
      {
        selectedIndex = { indx: 4, title: "TV EXPOSURE" };
      }
      break;
    default:
      selectedIndex = { indx: 0, title: "ADOBE" };
      break;
  }
  return selectedIndex;
}

export function getSubSource(
  prizmData: any,
  audienceData: any,
  index: any,
  iD: any,
  legacyId: any,
  crmCanSource: any
) {
  let subSource = legacyId ? legacyId : "";
  if (index == 2 && iD) {
    let idx = prizmData.findIndex((obj: any) => obj.id == iD);
    if (idx >= 0) {
      subSource = prizmData[idx].title;
    }
  }

  if (index == 3 && iD) {
    const audiSource = audienceData.toArray();
    let idx = audiSource.findIndex((obj: any) => obj.id == iD);
    if (idx >= 0) {
      subSource = audiSource[idx].title;
    }
  }

  if (index == 4 && iD) {
    const crmSource = crmCanSource.toArray();
    let idx = crmSource.findIndex((obj: any) => obj.id == iD);
    if (idx >= 0) {
      subSource = crmSource[idx].title;
    }
  }
  return subSource;
}

export function clearAllErrorStateInReporting() {
  const errStateObj = {
    reportDateError: {
      show: false,
      errFlyoutIdentifier: CampaignEleConstants.REPORTDATE_ERROR,
      cssAttrib: " error",
    },
  };
  return errStateObj;
}
