/* eslint-disable */
export const userListObj = [
  {
    id: 1,
    displayName: "Admin",
    isSelected: false,
  },
  {
    id: 2,
    displayName: "Beta tester",
    isSelected: false,
  },
  {
    id: 3,
    displayName: "CRM Uploader",
    isSelected: false,
  },
  {
    id: 4,
    displayName: "Reporting - Digital Portal",
    isSelected: true,
  },
  {
    id: 5,
    displayName: "Reporting - LinOpt",
    isSelected: true,
  },
  {
    id: 6,
    displayName: "Reporting - Digital Pacing",
    isSelected: true,
  },
  {
    id: 7,
    displayName: "Reporting - Digital Recent Reports",
    isSelected: true,
  },
  {
    id: 8,
    displayName: "Reporting - Excel Export",
    isSelected: true,
  },
  {
    id: 9,
    displayName: "Reporting - PowerPoint Export",
    isSelected: true,
  },
  {
    id: 10,
    displayName: "Reporting - Domo link",
    isSelected: true,
  },
];

export function switchFlag(arrObj: any, id: any) {
  return arrObj.findIndex((obj: any) => obj.id == id) >= 0 ? true : false;
}
