/* eslint-disable */
export enum ConstAction {
  EDIT = 1,
  DELETE = 2,
  MOVEUP = 4,
  MOVEDOWN = 5,
  RESTRICTMSG,
}

export default ConstAction;

