import SlidesContentTable from '../Model/SlidesContentTable'
import PageCategory from '../Model/PageCategory'
import PageSection from '../Model/PageSection'
import pptxfile from './pptxfile'
import { SlideInfo } from '../Model/SlideInfo'
import Slide from './slide'

class TocSlide {
    public PageType: PageCategory
    public Section: PageSection
    public SlideId: number

    constructor(pageType: PageCategory, section: PageSection, slideId: number) {
        this.PageType = pageType
        this.Section = section
        this.SlideId = slideId
    }
}

class SlideTOC {
    private pptContents: pptxfile
    private slideContents: Array<SlidesContentTable>

    constructor(presentation: pptxfile) {
        this.pptContents = presentation

        this.slideContents = this.allSlides.map(function (slides) {
            return new SlidesContentTable(slides.PageType, slides.Section);
        });

    }

    appendNewSlide(layout: string, section: PageSection, category: PageCategory): Slide
    {
        var index = this.lastDataSlideIndexOfCategory(category)
        var slide = this.pptContents.addSlide(layout, index + 1)
        this.slideContents.splice(index + 1, 0, new TocSlide(category, section, index))
        return slide
    }

    lastIndexOfCategory(section: PageSection, category: PageCategory): number
    {
        for (var i=this.slideContents.length-1; i>=0; i--)
        {
            if (this.slideContents[i].Section === section && this.slideContents[i].PageType === category) return i
        }
        return -1
    }

    deleteSection(section: PageSection): void {
        this.deleteSlidesOfCriteria((s) => s.Section === section)
    }

    deleteSlidesOfCategory(category: PageCategory): void {
        this.deleteSlidesOfCriteria((s) => s.PageType === category)
    }

    deleteSlidesOfCriteria(predicate: (s: SlidesContentTable) => boolean): void {
        var found = true
        while (found) {
            found = false;
            for (var i = 0; i < this.slideContents.length; i++) {               
                if (predicate(this.slideContents[i])) {
                    this.pptContents.deleteSlide(i)
                    this.slideContents.splice(i, 1)
                    found = true
                    break
                }
            }
        }
    }

    lastDataSlideIndexOfCategory(dataCategory: PageCategory): number
    {
        var validCategories = this.dataCategoriesFor(dataCategory)
        for (var i = this.slideContents.length - 1; i >= 0; i--)
        {
            if (validCategories.indexOf(this.slideContents[i].PageType) >= 0) return i
        }
        return -1
    }

    dataCategoriesFor(category: PageCategory): Array<PageCategory> 
        {        
            switch(category)
            {
                case PageCategory.ContoData:
                    return [ PageCategory.ContoData, PageCategory.ContoDataSample ]
                case PageCategory.DigitalAudioData:
                    return [ PageCategory.DigitalAudioData, PageCategory.DigitalAudioDataSample ]
                case PageCategory.DisplayData:
                    return [ PageCategory.DisplayData, PageCategory.DisplayDataSample ]
                case PageCategory.VideoInsightData:
                     return [ PageCategory.VideoInsightData, PageCategory.VideoInsightDataSample ]
                case PageCategory.FacebookData:
                    return [ PageCategory.FacebookData, PageCategory.FacebookDataSample ]
                case PageCategory.RedXData:
                    return [ PageCategory.RedXData, PageCategory.RedXDataSample ]
                case PageCategory.TwitterData:
                    return [ PageCategory.TwitterData, PageCategory.TwitterDataSample ]
                case PageCategory.EblastData:
                    return [ PageCategory.EblastData, PageCategory.EblastDataSample ]
                case PageCategory.NewsLetterData:
                    return [ PageCategory.NewsLetterData, PageCategory.NewsLetterDataSample ]
                case PageCategory.VodData:
                    return [ PageCategory.VodData, PageCategory.VodDataSample ]       
                case PageCategory.CampaignSummaryData:
                    return [ PageCategory.CampaignSummaryData, PageCategory.CampaignSummaryDataSample ]
            }
            return [];
        }

    deleteSampleDataSlides(section: PageSection): void
    {
        const sampleCategories = [
            PageCategory.CampaignSummaryDataSample,
            PageCategory.DisplayDataSample,
            PageCategory.VideoInsightDataSample,
            PageCategory.DigitalAudioDataSample,
            PageCategory.ContoDataSample,
            PageCategory.FacebookDataSample,
            PageCategory.RedXDataSample,
            PageCategory.TwitterDataSample,
            PageCategory.EblastDataSample,
            PageCategory.NewsLetterDataSample,
            PageCategory.VodDataSample
        ]
        this.deleteSlidesOfCriteria(s => s.Section === section && sampleCategories.indexOf(s.PageType) >= 0)
    }

    private allSlides: Array<SlideInfo> = [
        new SlideInfo(PageCategory.TitlePage, PageSection.Intro),
        new SlideInfo(PageCategory.Agenda, PageSection.Intro),
        new SlideInfo(PageCategory.SummaryTitle, PageSection.Summary),
        new SlideInfo(PageCategory.ExecutiveSummary, PageSection.Summary),
        new SlideInfo(PageCategory.HeroStats, PageSection.Summary),
        new SlideInfo(PageCategory.HeroStats, PageSection.Summary),
        new SlideInfo(PageCategory.CampaignSummaryDataSample, PageSection.Summary),
        new SlideInfo(PageCategory.CampaignInsightsTitle, PageSection.Heading),
        new SlideInfo(PageCategory.LayoutExampleTitle, PageSection.LayoutExample),
        new SlideInfo(PageCategory.LayoutExample, PageSection.LayoutExample),
        new SlideInfo(PageCategory.LayoutExample, PageSection.LayoutExample),
        new SlideInfo(PageCategory.LayoutExample, PageSection.LayoutExample),
        new SlideInfo(PageCategory.DisplayTitle, PageSection.Display),
        new SlideInfo(PageCategory.DisplayResults, PageSection.Display),
        new SlideInfo(PageCategory.DisplayCommentary, PageSection.Display),
        new SlideInfo(PageCategory.DisplayDataSample, PageSection.Display),
        new SlideInfo(PageCategory.DisplayDataSample, PageSection.Display),
        new SlideInfo(PageCategory.DisplayDataSample, PageSection.Display),
        new SlideInfo(PageCategory.DisplayDataSample, PageSection.Display),
        new SlideInfo(PageCategory.DisplayDataSample, PageSection.Display),
        new SlideInfo(PageCategory.DisplayDataSample, PageSection.Display),
        new SlideInfo(PageCategory.DisplayDataSample, PageSection.Display),
        new SlideInfo(PageCategory.DisplayDataSample, PageSection.Display),
        new SlideInfo(PageCategory.DisplayDataSample, PageSection.Display),
        //Video Insight
        new SlideInfo(PageCategory.VideoInsightTitle, PageSection.VideoSuite),
        new SlideInfo(PageCategory.VideoInsightResults, PageSection.VideoSuite),
        new SlideInfo(PageCategory.VideoInsightCommentary, PageSection.VideoSuite),
        new SlideInfo(PageCategory.VideoInsightDataSample, PageSection.VideoSuite),
        new SlideInfo(PageCategory.VideoInsightDataSample, PageSection.VideoSuite),
        new SlideInfo(PageCategory.VideoInsightDataSample, PageSection.VideoSuite),
        new SlideInfo(PageCategory.RedXTitle, PageSection.RedX),
        new SlideInfo(PageCategory.RedXResults, PageSection.RedX),
        new SlideInfo(PageCategory.RedXCommentary, PageSection.RedX),
        new SlideInfo(PageCategory.RedXDataSample, PageSection.RedX),
        new SlideInfo(PageCategory.RedXDataSample, PageSection.RedX),
        new SlideInfo(PageCategory.RedXDataSample, PageSection.RedX),
        new SlideInfo(PageCategory.RedXDataSample, PageSection.RedX),
        new SlideInfo(PageCategory.RedXDataSample, PageSection.RedX),
        new SlideInfo(PageCategory.RedXDataSample, PageSection.RedX),
        new SlideInfo(PageCategory.DigitalAudioTitle, PageSection.DigitalAudio),
        new SlideInfo(PageCategory.DigitalAudioResults, PageSection.DigitalAudio),
        new SlideInfo(PageCategory.DigitalAudioCommentary, PageSection.DigitalAudio),
        new SlideInfo(PageCategory.DigitalAudioDataSample, PageSection.DigitalAudio),
        new SlideInfo(PageCategory.DigitalAudioDataSample, PageSection.DigitalAudio),
        new SlideInfo(PageCategory.ContoTitle, PageSection.Conto),
        new SlideInfo(PageCategory.ContoResults, PageSection.Conto),
        new SlideInfo(PageCategory.ContoCommentary, PageSection.Conto),
        new SlideInfo(PageCategory.ContoDataSample, PageSection.Conto),
        new SlideInfo(PageCategory.ContoDataSample, PageSection.Conto),
        new SlideInfo(PageCategory.ContoDataSample, PageSection.Conto),
        new SlideInfo(PageCategory.ContoDataSample, PageSection.Conto),
        new SlideInfo(PageCategory.FacebookTitle, PageSection.Facebook),
        new SlideInfo(PageCategory.FacebookResults, PageSection.Facebook),
        new SlideInfo(PageCategory.FacebookCommentary, PageSection.Facebook),
        new SlideInfo(PageCategory.FacebookDataSample, PageSection.Facebook),
        new SlideInfo(PageCategory.FacebookDataSample, PageSection.Facebook),
        new SlideInfo(PageCategory.FacebookDataSample, PageSection.Facebook),
        new SlideInfo(PageCategory.FacebookDataSample, PageSection.Facebook),
        new SlideInfo(PageCategory.TwitterTitle, PageSection.Twitter),
        new SlideInfo(PageCategory.TwitterResults, PageSection.Twitter),
        new SlideInfo(PageCategory.TwitterCommentary, PageSection.Twitter),
        new SlideInfo(PageCategory.TwitterDataSample, PageSection.Twitter),
        new SlideInfo(PageCategory.TwitterDataSample, PageSection.Twitter),
        new SlideInfo(PageCategory.TwitterDataSample, PageSection.Twitter),
         // new SlideInfo(PageCategory.NewsLetterCommentary, PageSection.NewsLetter),
        new SlideInfo(PageCategory.VodTitle, PageSection.Vod),
        new SlideInfo(PageCategory.VodResults, PageSection.Vod),
        new SlideInfo(PageCategory.VodCommentary, PageSection.Vod),
        new SlideInfo(PageCategory.VodDataSample, PageSection.Vod),
        new SlideInfo(PageCategory.VodDataSample, PageSection.Vod),
        new SlideInfo(PageCategory.VodDataSample, PageSection.Vod),
        new SlideInfo(PageCategory.VodDataSample, PageSection.Vod),
        new SlideInfo(PageCategory.CTVTitle, PageSection.Ctv),
        new SlideInfo(PageCategory.CTVResults, PageSection.Ctv),
        new SlideInfo(PageCategory.CTVCommentary, PageSection.Ctv),
        new SlideInfo(PageCategory.CTVDataSample, PageSection.Ctv),
        new SlideInfo(PageCategory.CTVDataSample, PageSection.Ctv),
        new SlideInfo(PageCategory.CTVDataSample, PageSection.Ctv),
        new SlideInfo(PageCategory.CTVDataSample, PageSection.Ctv),
        new SlideInfo(PageCategory.NewsLetterTitle, PageSection.NewsLetter),
       // new SlideInfo(PageCategory.NewsLetterResults, PageSection.NewsLetter),
        new SlideInfo(PageCategory.NewsLetterDataSample, PageSection.NewsLetter),
        new SlideInfo(PageCategory.EblastTitle, PageSection.Eblast),
        new SlideInfo(PageCategory.EblastResults, PageSection.Eblast),
        new SlideInfo(PageCategory.EblastCommentary, PageSection.Eblast),
        new SlideInfo(PageCategory.EblastDataSample, PageSection.Eblast),
        new SlideInfo(PageCategory.ConclusionTitle, PageSection.Conclusion),
        new SlideInfo(PageCategory.ConclusionPage, PageSection.Conclusion),
        new SlideInfo(PageCategory.ClosingLogo, PageSection.Conclusion),
        new SlideInfo(PageCategory.AppendixTitle, PageSection.Appendix),
        new SlideInfo(PageCategory.DisplayDictionary, PageSection.Appendix),
        new SlideInfo(PageCategory.VideoDictionary, PageSection.Appendix),
        new SlideInfo(PageCategory.SimplifiDictionary, PageSection.Appendix),
        new SlideInfo(PageCategory.DigitalAudioDictionary, PageSection.Appendix),
        new SlideInfo(PageCategory.FacebookDictionary, PageSection.Appendix),
        new SlideInfo(PageCategory.TwitterDictionary, PageSection.Appendix),
        new SlideInfo(PageCategory.VodDictionary, PageSection.Appendix),
        new SlideInfo(PageCategory.CTVDictionary, PageSection.Appendix),
        new SlideInfo(PageCategory.EBlastDictionary, PageSection.Appendix),
    ]
}
export default SlideTOC