/* eslint-disable */
export const NavBarConstants = {
  HOME: "HOME",
  DASHBOARD: "DASHBOARD",
  AUDIENCELIST: "AUDIENCE",
  AUDIENCESIZING: "AUDIENCESIZING",
  LOGINTOGGLE: "LOGINLOGOUT",
  TERMINOLOGY: "TERMINOLOGY",
  FEEDBACK: "FEEDBACK",
  ADMIN: "ADMIN",
  ADMINAUDIENCESEGMENT: "ADMINAUDIENCESEGMENT",
  AUDIENCETRAIT: "AUDIENCETRAIT",
  REPORTINGLIST: "REPORTING",
  CRMUPLOADER: "CRMUPLOADER",
  LINOPTS: "LINOPTS",
  DIGITAL: "Reporting",
  CYNCHATTRIB: "CYNCHATTRIB",
  ADMINCRMSTATUS: "ADMINCRMSTATS",
  AUDIENCECYNCH: "AUDIENCECYNCH",
  DOMOAPI: "DOMOAPI",
  DOMOQUERY: "DOMOQUERY",
  HOMESLICK: 0,
  DASHBOARDSLICK: 1,
  AUDIENCELISTSLICK: 2,
  AUDIENCESIZINGSLICK: 3,
  EMAILCONFPAGESLICK: 6,
  INSIGHTSLICK: 7,
  TERMINOLOGYSLICK: 8,
  FEEDBACKSLICK: 9,
  ADMINSLICK: 10,
  ADMINADDUSER: 11,
  AUDIENCETRAITSLICK: 12,
  REPORTINGLISTSLICK: 13,
  CRMSLICK: 14,
  SUBMENU: 9997,
  NONMENU: 9998,
  ALL: 9999,
  ADMINCRMSTATUSSLICK: 15,
  ADMINREPORTINGSTATUS: 16,
  SYSINFO: 18,
  DOMOAPISLICK: 21,
  DOMOQUERYSLICK: 22,
  AUDIENCECYNCHSLICK: 19,
  CYNCHATTRIBSLICK: 20,
};

export default NavBarConstants;
