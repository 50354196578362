/* eslint-disable */
import { UserOps } from "../ConstConfig/UserOps";

export function getCRMRoleConfigs() {
  return {
    viewAudience: false,
    viewAudienceDisabled: " d-none ",
    createNewAudienceDisabled: " d-none ",
    createNewAudience: false,
  };
}

export function getRoleConfigs() {
  return {
    viewAudience: true,
    viewAudienceDisabled: "  ",
    createNewAudienceDisabled: "  ",
    createNewAudience: true,
    linkButton: " d-none ",
    excel2Button: " d-none ",
    excelButton: " d-none ",
    powerPoint2: " d-none ",
    powerPoint: " d-none ",
    pacingReport: " d-none ",
    LegacySummaryButton: " d-none",
  };
}
export function findMatchingRoles(arr1: any, arr2: any) {
  var ret = [];
  var i;

  for (i in arr1) {
    if (arr2.indexOf(arr1[i]) > -1) {
      ret.push(arr1[i]);
    }
  }
  return ret;
}
export function generateConfiguration(roles: any) {
  const properties = {
    viewAudience: {
      clsName: " d-none ",
      groupsAllowed: [UserOps.AUDIENCEBUILDER],
      clsEnabled: "",
    },
    createNewAudience: {
      clsName: " d-none ",
      groupsAllowed: [UserOps.AUDIENCEBUILDER],
      clsEnabled: "",
    },
    launchAudience: {
      clsName: false,
      groupsAllowed: [UserOps.AUDIENCEBUILDER],
      clsEnabled: true,
    },
    linkButton: {
      clsName: " d-none ",
      groupsAllowed: [UserOps.REPORTINGDOMO],
      clsEnabled: "",
    },
    excelButton: {
      clsName: " d-none ",
      groupsAllowed: [UserOps.REPORTINGEXCEL],
      clsEnabled: "",
    },
    powerPoint: {
      clsName: " d-none ",
      groupsAllowed: [UserOps.REPORTINGPPT],
      clsEnabled: "",
    },
    pacingReport: {
      clsName: " d-none ",
      groupsAllowed: [UserOps.REPORTINGPACING],
      clsEnabled: "",
    },
    LegacySummaryButton: {
      clsName: " d-none ",
      groupsAllowed: [UserOps.BETATESTER],
      clsEnabled: "",
    },
  };
  let newConfig = {};
  var key;

  for (key in properties) {
    if (properties.hasOwnProperty(key)) {
      if (findMatchingRoles(roles, properties[key].groupsAllowed).length >= 1) {
        newConfig[key] = { clsName: properties[key].clsEnabled };
      } else {
        newConfig[key] = { clsName: properties[key].clsName };
      }
    }
  }
  return newConfig;
}

export function getConfig(UserRole: any) {
  if (UserRole && UserRole.hasOwnProperty("roles") && UserRole.roles) {
    return generateConfiguration(UserRole.roles);
  }
  return getRoleConfigs();
}
