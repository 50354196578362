import ToplineDomoOrderRecord from "./ToplineDomoOrderRecord"

class ToplineTotals {
    public deliveredImpressions: number = 0
    public clicks: number = 0
    public contractedQuantity: number = 0
    public contractedSpend: number = 0
    public actualSpend: number = 0
    public ctr: number = 0
    public starts: number = 0
    public complition: number = 0
    public engagement:number = 0

    constructor(data: ToplineDomoOrderRecord | null) {
        if (data)
        {
            for (var line of data.lines)
            {
                this.deliveredImpressions += line.deliveredImpressions
                this.clicks += line.clicks
                this.contractedQuantity += line.contractedQuantity
                this.contractedSpend += line.contractedSpend
                this.engagement  += line.engagement
                this.starts += line.starts
                this.complition += line.complition
                this.actualSpend += line.actualSpend
            }
            this.ctr = this.deliveredImpressions === 0 ? 0 : (this.clicks / this.deliveredImpressions * 100.0)
        }
        else
        {
            this.deliveredImpressions = 0
            this.clicks = 0
            this.contractedQuantity = 0
            this.starts = 0
            this.engagement = 0
            this.complition = 0
            this.contractedSpend = 0
            this.actualSpend = 0
            this.ctr = 0
        }
    }
}
export default ToplineTotals