/* eslint-disable */
export class Configs {
  baseUrl: any;
  apiPath: any;
  port: number;
  prefix: string;
  loginUrl: any;
  logoutUrl: any;
  segmentGetUrl: any;
  segmentPostUrl: any;
  audienceListUrl: string;
  audienceEditListUrl: string;
  deleteAudienceListUrl: string;
  saveAudienceListUrl: string;
  overwriteAudienceListUrl: string;
  dashboardUrl: String;
  dashboardGeopMapUrl: String;
  dashboardPreflightAPIUrl: String;
  dashboardLineChartAPIUrl: String;
  emailUrl: String;
  userRoleUrl: String;
  adminUserUrl: String;
  audienceTraitUrl: String;
  audienceTraitSortUrl: String;
  reportSerachUrl: String;
  reportDownloadUrl: String;
  reportListUrl: String;
  cmrFileUploadUrl: String;
  crmListUrl: String;
  crmStatListUrl: string;
  reportingStatusSetUrl: string;
  reportingStatusUrl: string;
  crmCanUrl: string;
  reportsStats: string;
  cynchAttributeUrl: string;
  querySegmentUrl: String;
  saveQuerySegmentUrl: String;
  deleteDigitalReportSegments: String;
  tvCampaignOrderList: String;
  configurationUrl: String;
  domoConfigUrl: String;
  domoQueryUrl: String;
  forCastUrl: String;
  domoStatsUrl: String;
  pacingReportListUrl: String;
  domoQueryTestUrl: String;
  userDomoQueryUrl: String;

  constructor() {
    let hostname = window && window.location && window.location.host;
    // let hostname = "10.73.186.40"; // Used for Prod build
    let isLocalHost = false;
    if (hostname === "localhost:3000") {
      isLocalHost = true;
    }
    this.prefix = ":";
    this.port = 443;
    // this.port = 8098;// Used for Prod build
    this.baseUrl = "https://" + hostname + this.prefix + this.port + "/";
    if (isLocalHost) {
      this.baseUrl = "/";
    }

    //  This code can be open in prod build
    if (
      process.env.REACT_APP_API_ENDPOINT != undefined &&
      process.env.REACT_APP_API_ENDPOINT != ""
    ) {
      this.baseUrl = process.env.REACT_APP_API_ENDPOINT;
    }
    this.apiPath = "RED/";
    this.loginUrl = this.baseUrl + this.apiPath + "getAuthToken";
    this.logoutUrl = this.baseUrl + this.apiPath + "logout";
    this.segmentGetUrl =
      this.baseUrl + this.apiPath + "app/admin/getSegmentLabels";
    this.segmentPostUrl =
      this.baseUrl + this.apiPath + "app/admin/getAudienceListCounts";

    this.reportDownloadUrl =
      this.baseUrl + this.apiPath + "app/report/downloadFile";
    this.pacingReportListUrl =
      this.baseUrl + this.apiPath + "app/report/getPacingReports";
    this.audienceTraitSortUrl =
      this.baseUrl + this.apiPath + "app/admin/moveSegments";
    this.cmrFileUploadUrl = this.baseUrl + this.apiPath + "app/data/upload";
    this.crmListUrl = this.baseUrl + this.apiPath + "app/data/getCrmDetails";
    this.crmStatListUrl =
      this.baseUrl + this.apiPath + "app/data/getAdminCrmDetails";
    this.reportingStatusSetUrl =
      this.baseUrl + this.apiPath + "app/admin/notificationMessage";
    this.reportingStatusUrl =
      this.baseUrl + this.apiPath + "app/admin/getNotificationMessages";
    this.crmCanUrl =
      this.baseUrl + this.apiPath + "app/data/getCrmListForSegmentCreation";
    this.reportsStats = this.baseUrl + this.apiPath + "app/admin/reportsStats";
    this.cynchAttributeUrl =
      this.baseUrl + this.apiPath + "app/admin/cynchAttributes";
    this.querySegmentUrl =
      this.baseUrl + this.apiPath + "app/admin/getDigitalReportingSegments";
    this.saveQuerySegmentUrl =
      this.baseUrl + this.apiPath + "app/admin/digitalReportingSegment";
    this.deleteDigitalReportSegments =
      this.baseUrl + this.apiPath + "app/admin/removeDititalReportSegments";
    this.tvCampaignOrderList =
      this.baseUrl + this.apiPath + "app/admin/tvCampaignOrders";
    this.configurationUrl =
      this.baseUrl + this.apiPath + "app/admin/coreConfig";
    this.domoConfigUrl = this.baseUrl + this.apiPath + "app/admin/domoConfig";
    this.domoQueryUrl = this.baseUrl + this.apiPath + "app/admin/domoQueries";
    this.saveAudienceListUrl =
      this.baseUrl + this.apiPath + "app/admin/saveAudiences";
    this.overwriteAudienceListUrl =
      this.baseUrl + this.apiPath + "app/admin/updateAudience";
    this.audienceListUrl =
      this.baseUrl + this.apiPath + "app/admin/listAudience";
    this.audienceEditListUrl =
      this.baseUrl + this.apiPath + "app/admin/editAudience";
    this.deleteAudienceListUrl =
      this.baseUrl + this.apiPath + "app/admin/deleteAudience";
    this.dashboardUrl = this.baseUrl + this.apiPath + "app/getDashBoardDetails";
    this.dashboardGeopMapUrl = this.baseUrl + this.apiPath + "app/GeoMap";
    this.dashboardPreflightAPIUrl =
      this.baseUrl + this.apiPath + "app/dashBoardDates";
    this.emailUrl = this.baseUrl + this.apiPath + "app/sendMail";
    this.dashboardLineChartAPIUrl =
      this.baseUrl + this.apiPath + "app/lineCharts";
    this.userRoleUrl =
      this.baseUrl + this.apiPath + "app/getLoggedInUserDetail";
    this.adminUserUrl = this.baseUrl + this.apiPath + "app/admin/users";
    this.audienceTraitUrl = this.baseUrl + this.apiPath + "app/admin/segments";
    this.reportSerachUrl =
      this.baseUrl + this.apiPath + "app/report/checkFilesAvailability";
    this.reportDownloadUrl =
      this.baseUrl + this.apiPath + "app/report/downloadFile";
    this.reportListUrl = this.baseUrl + this.apiPath + "app/report/getReports";
    this.audienceTraitSortUrl =
      this.baseUrl + this.apiPath + "app/admin/moveSegments";
    this.domoStatsUrl =
      this.baseUrl + this.apiPath + "app/report/registerReportClick";
    this.forCastUrl = this.baseUrl + this.apiPath + "app/linOptIndex";
    this.domoQueryTestUrl =
      this.baseUrl + this.apiPath + "app/reports/domotest";
    this.userDomoQueryUrl = this.baseUrl + this.apiPath + "app/reports/domo";
  }
  getAudienceListUrl() {
    return this.audienceListUrl;
  }
  getEditAudienceListUrl() {
    return this.audienceEditListUrl;
  }
  getDeleteAudienceListUrl() {
    return this.deleteAudienceListUrl;
  }
  getSaveAudienceListUrl(overWrite?: any) {
    if (overWrite && overWrite === true) {
      return this.overwriteAudienceListUrl;
    }
    return this.saveAudienceListUrl;
  }

  getDashboardUrl() {
    return this.dashboardUrl;
  }
  getGeoMapUrl() {
    return this.dashboardGeopMapUrl;
  }
  getDashboardPreflightAPIUrl() {
    return this.dashboardPreflightAPIUrl;
  }

  getLineChartUrl() {
    return this.dashboardLineChartAPIUrl;
  }

  getCampaignEmailUrl() {
    return this.emailUrl;
  }
  getUserRoleUrl() {
    return this.userRoleUrl;
  }
  getListUsersUrl() {
    return this.adminUserUrl;
  }
  getDeleteUserUrl() {
    return this.adminUserUrl;
  }
  getAddUserUrl() {
    return this.adminUserUrl;
  }
  getListAudienceTraitUrl() {
    return this.audienceTraitUrl;
  }
  getEditAudienceTraitUrl() {
    return this.audienceTraitUrl;
  }
  getDeleteAudienceTraitUrl() {
    return this.audienceTraitUrl;
  }
  getSortAudienceTraitUrl() {
    return this.audienceTraitSortUrl;
  }

  getReportSearchUrl() {
    return this.reportSerachUrl;
  }
  getReportDownloadUrl() {
    return this.reportDownloadUrl;
  }
  getReportListUrl() {
    return this.reportListUrl;
  }

  getCRMListComponentUrl() {
    return this.crmListUrl;
  }
  getCRMFileUploadUrl() {
    return this.cmrFileUploadUrl;
  }
  public getCRMListUrl() {
    return this.crmStatListUrl;
  }
  public getForCastUrl() {
    return this.forCastUrl;
  }

  public getDomoStatsUrl() {
    return this.domoStatsUrl;
  }

  public getPacingReportListUrl() {
    return this.pacingReportListUrl;
  }

  getReportingStatusConfigSaveUrl() {
    return this.reportingStatusSetUrl;
  }
  getReportingStatusConfigUrl() {
    return this.reportingStatusUrl;
  }

  getCrmCanUrl() {
    return this.crmCanUrl;
  }

  getReportsStats() {
    return this.reportsStats;
  }

  getListCynchAttributeUrl() {
    return this.cynchAttributeUrl;
  }

  getQuerySegmentUrl() {
    return this.querySegmentUrl;
  }

  getSaveQuerySegmentUrl() {
    return this.saveQuerySegmentUrl;
  }

  getDeleteQuerySegmentUrl() {
    return this.deleteDigitalReportSegments;
  }

  getTVOrderCampaignList() {
    return this.tvCampaignOrderList;
  }
  getCoreConfiguration() {
    return this.configurationUrl;
  }
  getDomoAPIConfigUrl() {
    return this.domoConfigUrl;
  }

  getDomoQueryUrl() {
    return this.domoQueryUrl;
  }
  getUserDomoQueryUrl() {
    return this.userDomoQueryUrl;
  }

  getDomoQueryTestUrl() {
    return this.domoQueryTestUrl;
  }
}
export default Configs;
