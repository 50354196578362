/* eslint-disable */
export enum ActionConstants {
  Login = "Login",
  UserLogin = "USER_LOGIN",
  ChangeConfig = "CHANGE_CONFIG",
  UIConfig = "UI_CONFIG",
  FLYOUTCONFIG = "FLYOUTCONFIG",
  SpinnerConfig = "SPINNER",
  AdminSpinnerConfig = "ADMIN_SPINNER",
  Audiences = "AUDIENCES",
  AdminAudiences = "ADMINAUDIENCES",
  AudienceStatus = "AudienceStatus",
  DashboardDateControl = "DASHBOARD_DATECONT",
  CAMPAIGNCONTROL = "CAMPAIGN_CONT",
  CREATECAMPAIGN = "CREATE_CAMPAIGN",
  TOGGLE_BUTTON_STATE = "TOGGLE_BUTTON_STATE",
  INSIGHT_STATE = "INSIGHTS",
  DASHBOARD_DATE_STATE = "DASHBOARD_PREFETCH_DATE",
  PREFLIGHT_GENDATA = "PREFLIGHT_GENDATA",
  LINECHART = "LINECHART",
  CALENDAR_STATE = "CALENDAR_STATE",
  ADMIN_USER_LISTING = "ADMIN_USER_LISTING",
  ADMIN_USER_ADD = "ADMIN_USER_ADD",
  ADMIN_USER_DELETE = "ADMIN_USER_DELETE",
  ADMIN_USER_EDIT = "ADMIN_USER_EDIT",
  ADMIN_USER_FETCH_ROLE = "ADMIN_USER_FETCH_ROLE",
  ADMIN_TRAIT_LISTING = "ADMIN_TRAIT_LISTING",
  ADMIN_TRAIT_ADD = "ADMIN_TRAIT_ADD",
  ADMIN_TRAIT_DELETE = "ADMIN_TRAIT_DELETE",
  ADMIN_TRAIT_EDIT = "ADMIN_TRAIT_EDIT",
  ADMIN_TRAIT_FETCH_ROLE = "ADMIN_TRAIT_FETCH_ROLE",
  ADMIN_TRAIT_PAGINATION = "ADMIN_TRAIT_PAGINATION",
  ADMIN_CALENDAR_STATE = "ADMIN_CALENDAR_STATE",
  AUDIENCE_TRAIT_CALENDAR_STATE = "AUDIENCE_TRAIT_CALENDAR_STATE",
  SEARCH_REPORT = "SEARCH_REPORT",
  REPORT_RESPONSE = "REPORT_RESPONSE",
  REPORT_LISTRESPONSE = "REPORT_LISTRESPONSE",
  PACINGREPORT_LISTRESPONSE = "PACINGREPORT_LISTRESPONSE",
  REPORT_DOWNLOAD = "DOWNLOAD_REPORT",
  UPLOADCRM = "UPLOADCRM",
  PROGRESS = "PROGRESS",
  CRMMSG = "CRMMSG",
  CRMLISTSTAT = "CRMLISTSTAT",
  LISTALL_CRM = "LISTALL_CRM",
  REPORTINGSTATUS = "REPORTINGSTATUS",
  ROLECONFIG = "ROLECONFIG",
  DOMOSTATS = "DOMOSTATS",
  GETFORCAST = "GETFORCAST",
  RESPONSEFORCAST = "RESPONSEFORCAST",
  LISTALLCONFIG = "LISTALLCONFIG",
  LOADCONFIG = "LOADCONFIG",
  SAVECONFIG = "SAVECONFIG",
  DOMOQUERYAPI = "DOMOQUERYAPI",
  DOMOQUERYAPIRESPONSE = "DOMOQUERYAPIRESPONSE",
  DWONLOAD_EXCEL = "DWONLOAD_EXCEL",
  DWONLOAD_DOMOPPT = "DWONLOAD_DOMOPPT",
  AltSpinnerConfig = "ALTSPINNERCONFIG",
  AudienceSpinnerConfig = "AUDIENCESPINNERCONFIG",
  SETREPORTINGSTATUS = "SETREPORTINGSTATUS",
  CRMCANLIST = "CRMCANLIST",
  DASHBOARD_CHARTS = "DASHBOARD_CHARTS",
  CYNCHATTRIBUTES = "CYNCHATTRIBUTES",
  CYNCHATTRIUPDATE = "CYNCHATTRIUPDATE",
  CYNCHOPERATION = "CYNCHOPERATION",
  CYNCHEDIT = "CYNCHEDIT",
  CYNCHCATPARENT = "CYNCHCATPARENT",
  QUERYSEGMENTIDS = "QUERYSEGMENTIDS",
  QUERYSEGMENTIDSLIST = "QUERYSEGMENTIDSLIST",
  QUERYSEGMENTIDSAVE = "QUERYSEGMENTIDSAVE",
  QUERYSEGMENTIDEDIT = "QUERYSEGMENTIDEDIT",
  LISTALLTVORDERS = "LISTALLTVORDERS",
  SAVETVORDER = "SAVETVORDER",
  DELETETVORDER = "DELETETVORDER",
  DISPLAYALLTVORDERS = "DISPLAYALLTVORDERS",
  EDITTVORDERS = "EDITTVORDERS",
  LISTDOMOCONFIG = "LISTDOMOCONFIG",
  SAVEDOMOCONFIG = "SAVEDOMOCONFIG",
  LOADDOMOCONFIG = "LOADDOMOCONFIG",
  LISTDOMOQUERY = "LISTDOMOQUERY",
  DISPLAYALLDOMOQUERIES = "DISPLAYALLDOMOQUERIES",
  SAVEDOMOQUERY = "SAVEDOMOQUERY",
  SAVEUSERDOMOQUERY = "SAVEUSERDOMOQUERY",
  DELETEDOMOQUERY = "DELETEDOMOQUERY",
  EDITDOMOQUERY = "EDITDOMOQUERY",
  TESTUSERQUERY = "TESTUSERQUERY",
  TESTUSERQUERYFAILED = "TESTUSERQUERYFAILED",
  RESETDOMOTESTDATA = "RESETDOMOTESTDATA",
}

export default ActionConstants;
