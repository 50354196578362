/* eslint-disable */ 
export const AppConstants ={
    Uninitialized : 0,
    Loading :1,
    Ready :2,
    Clicked :3,
    Loaded :4,

    NotInited :999,
    Inited :1000

}

export default AppConstants;

