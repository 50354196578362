import Excel from "exceljs";
import ExcelSheet from "./ExcelSheet";
export enum EblastInsightsHead {
  E_Blast,
}
class EblastInsights extends ExcelSheet {
  protected columnCount: number;
  protected hasVideo: boolean;
  protected colPadd: number;

  constructor(
    workbook: Excel.Workbook,
    name: string,
    hasVideo: boolean,
    reportDates: any,
    orderInfo: Record<string, string | boolean>,
    logo: any
  ) {
    super(workbook, name, reportDates, orderInfo, logo);
    this.hasVideo = hasVideo;
    this.colPadd = 0.9;
    this.columnCount = 13;
    this.addSheetHeader();
    this.sheet.getColumn(2).width = 41.25;
    this.sheet.getColumn(3).width = 100.69;
    this.sheet.getColumn(4).width = 15.97;
    this.sheet.getColumn(5).width = 13;
    this.sheet.getColumn(6).width = 13;
    this.sheet.getColumn(7).width = 13;
    this.sheet.getColumn(8).width = 14.58;
    this.sheet.getColumn(9).width = 14.58;
    this.sheet.getColumn(10).width = 14.58;
    this.sheet.getColumn(11).width = 15;
    this.sheet.getColumn(12).width = 15;
    this.sheet.getColumn(13).width = 20.14;
  }

  public addEblastInsights(data: any): void {
    if (!data || data?.length <= 0) {
      return;
    }

    this.addEblastInsightsHeading("");
    const heading = [
      "",
      "Performance by Brand",
      "Subject",
      "Send Date",
      "Deliveries",
      "Opens",
      "Unique Opens",
      "Clicks",
      "Unique Clicks",
      "Open Rate",
      "Total Click Rate",
      "Unique Click Rate",
      "Click-to-Open Rate",
    ];
    const topHeading = this.addSectionHeading(heading);
    const topDataRow = topHeading.number;
    topHeading.commit();
    const colCont = 13;
    for (const row of data) {
      var rowData = [
        "",
        row["Brand"], //B 2
        row["Subject"], //C 3
        row["Send Date"], //D 4
        this.safeInt(row["Deliveries"]), //E 5
        this.safeFloat(row["Opens"]), //F 6

        this.safeFloat(row["Unique Opens"]), //G 7
        this.safeInt(row["Clicks"]), //H 8
        this.safeInt(row["Unique Clicks"]), //I  9
        this.safeFloat(row["Unique Opens"] / row["Deliveries"]), //J  10
        this.safeFloat(row["Clicks"] / row["Deliveries"]), //K  11
        this.safeFloat(row["Unique Clicks"] / row["Deliveries"]), //L  12
        this.safeFloat(row["Unique Clicks"] / row["Unique Opens"]), //M  13
      ];
      var item = this.sheet.addRow(rowData);
      item.getCell(4).style.numFmt = "#,##0";
      item.getCell(5).style.numFmt = "#,##0";
      item.getCell(6).style.numFmt = "#,##0";
      item.getCell(7).style.numFmt = "#,##0";
      item.getCell(8).style.numFmt = "#,##0";
      item.getCell(9).style.numFmt = "#,##0";
      item.getCell(10).style.numFmt = "#0.00%";
      item.getCell(11).style.numFmt = "#0.00%";
      item.getCell(12).style.numFmt = "#0.00%";
      item.getCell(13).style.numFmt = "#0.00%";

      for (var ri = 2; ri <= colCont; ri++) {
        var cell = item.getCell(ri);
        cell.border = this.standardCellBorder;
        cell.alignment = { horizontal: ri <= 3 ? "left" : "right" };
      }
      item.commit();
    }

    const totalTemplate = [
      "",
      "Total",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
    ];

    const totalRow = this.sheet.addRow(totalTemplate);
    for (var tri = 2; tri <= colCont; tri++) {
      var totalCell = totalRow.getCell(tri);
      totalCell.border = this.standardCellBorder;
      totalCell.font = { bold: true, color: { argb: "FF000000" } };
      totalCell.alignment = { horizontal: tri === 2 ? "left" : "right" };
      totalCell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFCCCCCC" },
        bgColor: { argb: "FFFFFFFF" },
      };
    }
    totalRow.getCell(2).value = " GRAND TOTAL";
    totalRow.getCell(5).value = {
      formula: "SUM(E" + topDataRow + ":E" + (totalRow.number - 1) + ")",
      date1904: false,
    };
    totalRow.getCell(5).style.numFmt = "###,###";

    totalRow.getCell(6).value = {
      formula: "SUM(F" + topDataRow + ":F" + (totalRow.number - 1) + ")",
      date1904: false,
    };
    totalRow.getCell(6).style.numFmt = "###,###";

    totalRow.getCell(7).value = {
      formula: "SUM(G" + topDataRow + ":G" + (totalRow.number - 1) + ")",
      date1904: false,
    };
    totalRow.getCell(7).style.numFmt = "###,###";

    totalRow.getCell(8).value = {
      formula: "SUM(H" + topDataRow + ":H" + (totalRow.number - 1) + ")",
      date1904: false,
    };
    totalRow.getCell(8).style.numFmt = "###,###";

    totalRow.getCell(9).value = {
      formula: "SUM(I" + topDataRow + ":I" + (totalRow.number - 1) + ")",
      date1904: false,
    };
    totalRow.getCell(9).style.numFmt = "###,###";

    totalRow.getCell(10).value = {
      formula:
        "SUM(G" +
        topDataRow +
        ":G" +
        (totalRow.number - 1) +
        ")/SUM(E" +
        topDataRow +
        ":E" +
        (totalRow.number - 1) +
        ")",
      date1904: false,
    };
    totalRow.getCell(10).style.numFmt = "#0.00%";

    totalRow.getCell(11).value = {
      formula:
        "SUM(H" +
        topDataRow +
        ":H" +
        (totalRow.number - 1) +
        ")/SUM(E" +
        topDataRow +
        ":E" +
        (totalRow.number - 1) +
        ")",
      date1904: false,
    };
    totalRow.getCell(11).style.numFmt = "#0.00%";

    totalRow.getCell(12).value = {
      formula:
        "SUM(I" +
        topDataRow +
        ":I" +
        (totalRow.number - 1) +
        ")/SUM(E" +
        topDataRow +
        ":E" +
        (totalRow.number - 1) +
        ")",
      date1904: false,
    };
    totalRow.getCell(12).style.numFmt = "#0.00%";

    totalRow.getCell(13).value = {
      formula:
        "SUM(I" +
        topDataRow +
        ":I" +
        (totalRow.number - 1) +
        ")/SUM(G" +
        topDataRow +
        ":G" +
        (totalRow.number - 1) +
        ")",
      date1904: false,
    };
    totalRow.getCell(13).style.numFmt = "#0.00%";

    totalRow.commit();
  }

  addEblastInsightsHeading(heading: string) {
    const sectionHeading = this.sheet.addRow(["", heading]);
   // sectionHeading.font = { bold: true, size: 20 };
    sectionHeading.height = 14.40;
    sectionHeading.commit();
  }
}
export default EblastInsights;
