/* eslint-disable */
import * as React from "react";
import { connect } from "react-redux";
import RedUICustomDropDown from "src/CommonComponent/RedUICustomDropDown";
import { List } from "immutable";
import { ActionConstants, Configs } from "src/ConstConfig";
import { initiateForcastRequest } from "src/Actions";
import CustomErrorAlertBanner from "src/CommonComponent/CustomErrorAlertBanner.";
import { clearLinOptsErrorState, getTwoDecimal } from "../utils/ReportingUtil";
import "../../CSS/perfect-scrollbar.css";
import AlertBannerComponent from "src/CommonComponent/AlertBanner/AlertBannerComponent";
import ReportingAdminTraitModel from "../Model/ReportingAdminTraitModel";

const update = require("react-addons-update");

class LinOptForcasting extends React.Component<ILinOptForcasting, {}> {
  public state: any;
  private config: any;
  constructor(props: any) {
    super(props);
    this.state = {
      contractIds: "",
      segment: this.props.traitsList.toArray()[0],
      errorStateCheck: clearLinOptsErrorState(),
    };
    this.getTraits = this.getTraits.bind(this);
    this.saveUserSelectedTrait = this.saveUserSelectedTrait.bind(this);
    this.getUserSelectedUserSelectedTrait =
      this.getUserSelectedUserSelectedTrait.bind(this);
    this.submitRequest = this.submitRequest.bind(this);
    this.setContractId = this.setContractId.bind(this);
    this.showError = this.showError.bind(this);
    this.config = new Configs();
  }

  componentDidMount() {
    const usersList = new ReportingAdminTraitModel(
      this.props.traitsList.toArray()
    );
    let filteredList = usersList
      .getReportingAudienceTraitList()
      .filter((item: any) => item.exposeToCynch == "Y");
    filteredList = filteredList.filter(
      (item: any) =>
        item.hasOwnProperty("showInLinOpts") &&
        item.showInLinOpts?.toUpperCase() == "Y"
    );
    this.setState({
      contractIds: "",
      segment: filteredList.toArray()[0],
      errorStateCheck: clearLinOptsErrorState(),
    });
  }

  componentWillUnmount(): void {
    this.props.forcastResponse.index = -1;
  }

  getUserSelectedUserSelectedTrait() {
    try {
      return this.state.segment.title;
    } catch (e) {
      return "";
    }
  }

  public saveUserSelectedTrait(trait: any) {
    this.setState(
      update(this.state, {
        segment: {
          $set: trait,
        },
      })
    );
  }

  public setContractId(e: any) {
    this.showError(false);
    this.setState(
      update(this.state, {
        contractIds: {
          $set: e.target.value,
        },
      })
    );
  }

  getTraits() {
    const usersList = new ReportingAdminTraitModel(
      this.props.traitsList.toArray()
    );
    let filteredList = usersList
      .getReportingAudienceTraitList()
      .filter((item: any) => item.exposeToCynch == "Y");
    filteredList = filteredList.filter(
      (item: any) =>
        item.hasOwnProperty("showInLinOpts") &&
        item.showInLinOpts?.toUpperCase() == "Y"
    );

    let subCat = {
      list: filteredList,
      isEditMode: "",
      toggle: "dropdown",
    };
    const autoSelectedTrait: any = filteredList.toArray()[0];
    return (
      <div className="form-group leftPos float-left col-6 pl-0 pr-0">
        <label htmlFor="Audiences">AUDIENCES</label>
        <div className="selectedTraits position-relative top15">
          <RedUICustomDropDown
            ddList={subCat}
            selectDDListItem={this.saveUserSelectedTrait}
            selectedItem={
              this.state.segment?.title
                ? this.state.segment.title
                : autoSelectedTrait?.title
            }
            uiClasses={"ddWidthAudi"}
          />
        </div>
      </div>
    );
  }

  showError(isShow: any) {
    this.state.errorStateCheck.contractId.show = isShow;
    this.setState(
      update(this.state, {
        errorStateCheck: {
          $set: this.state.errorStateCheck,
        },
      })
    );
  }

  submitRequest() {
    if (this.state.contractIds != "") {
      const payload = {
        forcastPayload: {
          segmentId: this.state.segment.segmentId,
          contractIds: this.state.contractIds,
        },
        url: this.config.getForCastUrl(),
      };
      this.props.handleClicks(payload);
      this.showError(false);
    } else {
      this.showError(true);
    }
  }

  public render() {
    return (
      <main role="main" className="container-fluid">
        <div className="row fixed-header-top ml-0 mr-0">
          <div className="col-12">
            <div className="float-left w-100 spacerB36 borderBottomGray">
              <div className="col-xl-7 col-md-12 col-sm-12 col-12 pl-0 pr-0">
                <h3 className="float-left">LinOpt Forecasting</h3>
              </div>
            </div>
          </div>
        </div>
        <AlertBannerComponent bannerData={this.props.currLinOptStatus} />
        <div className="row-flex spaceTop">
          <div className="w-100">
            <form>
              <div className="col-xl-3 col-md-6 col-sm-8 col-12">
                <div className="form-group leftPos proposalSearch">
                  <label htmlFor="formName">CONTRACT ID</label>
                  <div className="row-flex">
                    <input
                      type="text"
                      className="form-control col-9"
                      id="formName"
                      placeholder="Type contract id here"
                      onChange={this.setContractId}
                    />
                  </div>
                  <CustomErrorAlertBanner
                    errorMessageStruct={this.state.errorStateCheck.contractId}
                  />
                </div>
              </div>
              <div className="col-xl-4 col-md-6 col-sm-8 col-12 zindex1">
                {this.getTraits()}
              </div>
              <div className="col-12 buttonPanel spaceBottom float-left">
                <a
                  className="btn btnPrimary float-xl-left float-left col-xl-2 col-md-4 col-sm-4 mWidth50"
                  href="javascript:void(0);"
                  role="button"
                  id="findReport"
                  onClick={this.submitRequest}
                >
                  GET FORECAST
                </a>
              </div>

              {this.props.forcastResponse.index > -1 && (
                <div className="col-xl-12 col-md-6 col-sm-12 col-12">
                  <div className="form-group leftPos float-left">
                    <label>
                      Index : {getTwoDecimal(this.props.forcastResponse.index)}
                    </label>
                    {this.props.forcastResponse.warnings.length <= 0 ? (
                      <span />
                    ) : (
                      <div className="row-flex">
                        <label>Warnings : </label>
                        <ul>
                          {this.props.forcastResponse.warnings.map(
                            (item: any, i: any) => {
                              return <li key={i}>{item}</li>;
                            }
                          )}
                        </ul>
                      </div>
                    )}
                    {this.props.forcastResponse.warnings.length > 1 && (
                      <label>
                        For this campaign, the skipped portion represents
                        {getTwoDecimal(this.props.forcastResponse.totalPercent)}
                        % of the total campaign weight (by Ind. 2+ AMA)
                      </label>
                    )}
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </main>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    segment: state.segment ? state.segment : null,
    traitsList: state.AdminUserTraitListControlState.hasOwnProperty(
      "TraitsList"
    )
      ? state.AdminUserTraitListControlState.TraitsList
      : List([{ id: -1, title: "" }]),
    forcastResponse: state.LinOptForcastResponseState.data
      ? state.LinOptForcastResponseState.data
      : { index: -1, warnings: [], totalPercent: "" },
    currentTVgBannerStatus:
      state.ReportingConfigState.hasOwnProperty("data") &&
      state.ReportingConfigState.data.hasOwnProperty("currtTVStatus")
        ? state.ReportingConfigState.data.currtTVStatus
        : {
            id: 0,
            pageLable: "",
            message: "",
            selected: false,
            startTs: "",
            endTs: "",
            creationTs: "",
            updateTs: "",
          },
    currLinOptStatus:
      state.ReportingConfigState.hasOwnProperty("data") &&
      state.ReportingConfigState.data.hasOwnProperty("currLinOptStatus")
        ? state.ReportingConfigState.data.currLinOptStatus
        : {
            id: 0,
            pageLabel: "LinOpt",
            message: "",
            selected: false,
            startTs: "",
            endTs: "",
            creationTs: "",
            updateTs: "",
          },
  };
}

export default connect(mapStateToProps, (dispatch) => {
  return {
    handleClicks(payload: any) {
      const dummyUserObj = { type: ActionConstants.GETFORCAST, data: payload };
      dispatch(initiateForcastRequest(dummyUserObj));
    },
  };
})(LinOptForcasting);

interface ILinOptForcasting extends React.FC<any> {
  history?: any;
  forcastResponse?: any;
  traitsList?: any;
  currentTVgBannerStatus?: any;
  handleClicks?: any;
  currLinOptStatus?: any;
}
