/* eslint-disable */
import {
  AppConstants,
  ActionConstants,
  ErrorConstants,
  NavBarConstants,
} from "../../ConstConfig";
import {
  showErrorBox,
  userActions,
  slickStateAction,
  submitSpinnerAction,
  sendAdminUserRoleAction,
  sendRoleBasedConfiguration,
  sendAdminUserListAction,
  AudienceAction,
  AudienceActionAdmin,
  updateCRMCanList,
  getAllTVOrders,
  submitWizardAction,
  sendLineChartAction,
  submitFlyoutConfigAction,
  AudienceStatusAction,
  reportingStatusAction,
  sendCoreConfigurationToReporting,
  submitAdminSpinnerAction,
  adminCalendarChangeAction,
  broadcastCRMStat,
} from "../../Actions";
import { setAllFalse } from "./helper";

import {
  parseList,
  getPayloadForSaveAudience,
} from "../../Utility/AddItemToList";
import { Audiences } from "../../Audiences/Model/AudiencesModel";
import AuthCache from "../../Cache/AuthCache";
import { UserOps } from "../../ConstConfig/UserOps";
import { isAccessUnauthorized } from "./exceptionHandler";
import {
  UserErrorFields,
  UserExceptions,
} from "../../ConstConfig/UserExceptions";
import { getAuthContext } from "src/Login/MSAL/msalConfig";
import { getConfig } from "src/Utility/roleBasedAttrib";
import CRMCanModel from "src/Admin/Model/CRMCanModel";
import AudiencesModel from "src/Admin/Model/AudiencesModel";
import { getFakeSegmentResponse } from "../../Utility/dummyAttribs";
import AdminModel from "../../Audiences/Model/AdminModel";
import Logger from "../Logger/Logger";
import {
  convertToDate,
  isValidDateRangeWithFlag,
} from "src/Utility/reportingValidation";
import { isValExistInPath } from "./helper";
import { getParsedConfiguration } from "src/Reporting/utils/configutils";
import { List } from "immutable";

export class StoreUitls {
  clnt: any;
  store: any;
  urlConfig: any;
  adminUtil: any;
  audienceList: Audiences;
  token: any;

  constructor(pStore: any, client: any, urlConf: any, adminStoreUtil: any) {
    this.clnt = client;
    this.store = pStore;
    this.urlConfig = urlConf;
    this.adminUtil = adminStoreUtil;
  }

  getAuhObj() {
    const authToken = getAuthContext();
    const token = authToken;
    let user = "";
    let tok = "";
    let name = "";
    const configSet = process.env.REACT_APP_LOGIN_CONFIG;
    if (configSet === "LOCAL") {
      const stuffUser = require("../../data/dummyUser.json");
      user = stuffUser.userName;
      tok = stuffUser.token;
    } else {
      user = token.idToken.preferredName;
      tok = token.idToken.rawIdToken;
      name = token.idToken.name;
    }
    const uName = user;
    const authorizationResponse = {
      userName: uName,
      authToken: tok,
      userFullName: name,
    };
    return authorizationResponse;
  }

  initLogin() {
    this.getLineChartDetail();
  }

  getUserRole() {
    const authorizationResponse = this.getAuhObj();
    let authSuccess = false;
    const reqObject = {
      userName: "",
      url: "",
      password: "",
      serviceName: "",
      authToken: "",
    };
    reqObject.url = this.urlConfig.getUserRoleUrl();
    reqObject.authToken = authorizationResponse.authToken;

    this.clnt.getResponse(reqObject.url, reqObject).then((returnVal: any) => {
      let respExceptionObj = {};
      if (
        returnVal.hasOwnProperty("status") &&
        returnVal.status >= 400 &&
        returnVal.status < 600
      ) {
        respExceptionObj = isAccessUnauthorized(returnVal, respExceptionObj);
        if (returnVal.hasOwnProperty("status") && returnVal.status >= 500) {
          respExceptionObj[UserErrorFields.ERROR_CODE] =
            UserExceptions.UNKNOWN_EXCEPTION;
          respExceptionObj[UserErrorFields.ERROR_MESSAGE] =
            "Unknown error occured, please try after sometime.";
        }
        this.showErrorMessage(respExceptionObj);

        let spinnerState = { UIConfig: { isSpinnerActive: false } };
        this.store.dispatch(submitSpinnerAction(spinnerState));
      } else {
        let unAuthorized = false;
        if (returnVal && returnVal.hasOwnProperty("roles") && returnVal.roles) {
          const loggedInUserEmail = "loggedInUserEmail";
          returnVal[loggedInUserEmail] = this.getAuhObj().userName;
          if (returnVal.roles.length) {
            this.store.dispatch(
              sendAdminUserRoleAction({ UserProfile: returnVal })
            );
            const roleConfig = getConfig(returnVal);
            if (returnVal.roles.includes(UserOps.CRMUPLOADER)) {
              this.getCrmDetails();
            }
            if (returnVal.roles.includes(UserOps.AUDIENCEBUILDER)) {
              this.loadAllUsers(false);
            }
            if (returnVal.roles.includes(UserOps.ADMIN)) {
              this.fetchAdminUserList();
              this.getTVCampaignList();
              this.getSegments();
              this.adminUtil.loadAllCynchAttributes(false);
            }
            if (
              returnVal.roles.includes(UserOps.ADMIN) ||
              returnVal.roles.includes(UserOps.REPORTINGLINOPT) ||
              returnVal.roles.includes(UserOps.REPORTINGUSER) ||
              returnVal.roles.includes(UserOps.REPORTINGDIGITAL)
            ) {
              this.getCoreConfig();
              this.getBannerConfig();
            }
            if (returnVal.roles.includes(UserOps.BETATESTER)) {
              this.sendLoginSuccess();
            }
            if (
              returnVal.roles.includes(UserOps.REPORTINGLINOPT) ||
              returnVal.roles.includes(UserOps.REPORTINGUSER)
            ) {
              this.adminUtil.loadAllTrait(true);
            }
            this.store.dispatch(
              sendRoleBasedConfiguration({ userConfig: roleConfig })
            );
            this.initLogin();
            this.sendLoginSuccess();
            unAuthorized = false;
          } else {
            unAuthorized = true;
          }
        } else {
          unAuthorized = true;
        }
        if (unAuthorized) {
          respExceptionObj[UserErrorFields.ERROR_CODE] =
            UserExceptions.USER_UNAUTHORIZED;
          respExceptionObj[UserErrorFields.ERROR_MESSAGE] =
            "You do not have access to this application at this time";
          this.showErrorMessage(respExceptionObj);
          let spinnerState = { UIConfig: { isSpinnerActive: false } };
          this.store.dispatch(submitSpinnerAction(spinnerState));
        }
      }
    });
    return authSuccess;
  }

  login(useSSO: boolean, uName?: any, ssoToken?: any) {
    let spinnerState = { UIConfig: { isSpinnerActive: true } };
    this.store.dispatch(submitSpinnerAction(spinnerState));
    if (useSSO) {
      let reqObject = {
        userName: "",
        url: "",
        password: "",
        serviceName: "",
        authToken: "",
      };
      reqObject.url = this.urlConfig.segmentGetUrl;
      reqObject.authToken = ssoToken;
      this.getUserRole();
    } else {
      if (process.env.REACT_APP_LOGIN_CONFIG === "LOCAL") {
      } else {
        const username = this.store.getState().userAuth.data.userName;
        const pass = this.store.getState().userAuth.data.password;
        const LoginUrl = this.urlConfig.loginUrl;
        const service = "Login";
        const reqObject = {
          userName: username,
          url: LoginUrl,
          password: pass,
          serviceName: service,
          authToken: "",
        };
        let inputOptions = JSON.stringify({
          userName: username,
          password: pass,
        });
        this.clnt
          .post("POST", reqObject, inputOptions)
          .then((returnVal: any) => {
            if (returnVal && returnVal.authStatus === true) {
              if (
                this.store
                  .getState()
                  .configState.hasOwnProperty("LoginPageState")
              ) {
                if (
                  this.store.getState().configState.LoginPageState
                    .rememberMeFlag
                ) {
                  const authenticationCache = new AuthCache();
                  authenticationCache.saveAttribInCache("isRememberME", true);
                  authenticationCache.saveAttribInCache("userName", username);
                  authenticationCache.saveAttribInCache("password", pass);
                }
              }
              const token = this.getAuhObj().authToken;
              reqObject.url = this.urlConfig.segmentGetUrl;
              reqObject.authToken = token;
            } else {
              this.store.dispatch(
                userActions({
                  UserAction: ActionConstants.Login,
                  isLoginSuccessful: AppConstants.NotInited,
                })
              );
              const userObj = {
                UserAction: ActionConstants.Login,
                errorMessage: ErrorConstants.ERROR_401,
                isLoginSuccessful: "failed",
              };
              this.store.dispatch(showErrorBox(userObj));
            }
          });
      }
    }
  }

  getCrmDetails() {
    const reqObjectSeg = {
      authToken: this.getAuhObj().authToken,
      url: this.urlConfig.getCRMListComponentUrl(),
      serviceName: "Config",
    };
    let data = {};
    this.clnt
      .getResponse(reqObjectSeg.url, reqObjectSeg)
      .then((returnVal: any) => {
        if (returnVal.status >= 400 && returnVal.status < 600) {
          data = List([]);
        } else {
          if (returnVal instanceof Array) {
            data = { CRMSList: List(returnVal) };
            this.store.dispatch(broadcastCRMStat(data));
          }
        }
      });
  }

  getBannerConfig() {
    const segservice = "Config";
    const reqObjectSeg = {
      authToken: "",
      url: this.urlConfig.getReportingStatusConfigUrl(),
      serviceName: segservice,
    };

    const token = this.getAuhObj().authToken;
    reqObjectSeg.authToken = token;
    this.clnt
      .getResponse(reqObjectSeg.url, reqObjectSeg)
      .then((returnVal: any) => {
        const inValidReponse = {
          id: 0,
          pageLable: "",
          message: "",
          selected: false,
          startTs: "",
          endTs: "",
          creationTs: "",
          updateTs: "",
        };
        if (returnVal.status >= 400 && returnVal.status < 600) {
          this.store.dispatch(
            reportingStatusAction({
              data: { currReportingStatus: inValidReponse },
            })
          );
        } else {
          const index = returnVal.findIndex(
            (obj: any) => obj.pageLable == "Digital"
          );
          const LinOptIndex = returnVal.findIndex(
            (obj: any) => obj.pageLable == "LinOpt"
          );
          if (index >= 0 || LinOptIndex >= 0) {
            const resp = returnVal[index];
            const LinOptresp = returnVal[LinOptIndex];
            resp["displayBanner"] = isValidDateRangeWithFlag(resp);
            LinOptresp["displayBanner"] = isValidDateRangeWithFlag(LinOptresp);
            let startDate = convertToDate(resp.startTs, true);
            let endDate = convertToDate(resp.endTs, true);
            let linOptStartDate = convertToDate(LinOptresp.startTs, true);
            let linOptEndDate = convertToDate(LinOptresp.endTs, true);
            let usrSelectedDates = {
              Digital: {
                startDate: { date: startDate },
                endDate: { date: endDate },
              },
              LinOpt: {
                startDate: { date: linOptStartDate },
                endDate: { date: linOptEndDate },
              },
            };
            this.store.dispatch(adminCalendarChangeAction(usrSelectedDates));
            this.store.dispatch(
              reportingStatusAction({
                data: {
                  currReportingStatus: resp,
                  currLinOptStatus: LinOptresp,
                },
              })
            );
          } else {
            this.store.dispatch(
              reportingStatusAction({
                data: { currReportingStatus: inValidReponse },
              })
            );
          }
        }
      });
  }
  fetchAdminUserList() {
    const Url = this.urlConfig.getListUsersUrl();
    const segservice = "Login";
    const reqObjectSeg = { authToken: "", url: Url, serviceName: segservice };
    const token = getAuthContext().idToken.rawIdToken;
    reqObjectSeg.authToken = token;
    const spinnerState = { UIConfig: { isSpinnerActive: true } };
    this.store.dispatch(submitAdminSpinnerAction(spinnerState));
    this.clnt
      .getResponse(reqObjectSeg.url, reqObjectSeg)
      .then((returnVal: any) => {
        if (returnVal.hasOwnProperty("status") && returnVal.status) {
        } else {
          const usersList = new AdminModel(
            returnVal,
            getAuthContext().idToken.name
          );
          const spinnerState = { UIConfig: { isSpinnerActive: false } };
          this.store.dispatch(submitAdminSpinnerAction(spinnerState));
          const respObj = { UsersList: usersList.getAdminUserList() };
          this.adminUtil.loadAllDashboardStats(true, returnVal);
          this.store.dispatch(sendAdminUserListAction(respObj));
        }
      });
  }

  sendLoginSuccess(show: boolean = false) {
    const authorizationResponse = this.getAuhObj();
    const userObj = {
      UserAction: ActionConstants.Login,
      isLoginSuccessful: true,
      authResponse: authorizationResponse,
    };
    this.store.dispatch(userActions(userObj));
    if (isValExistInPath("LinOpt") || show) {
      let spinnerState = { UIConfig: { isSpinnerActive: false } };
      this.store.dispatch(submitAdminSpinnerAction(spinnerState));
    }
  }

  showErrorMessage(responseMsg: any) {
    this.store.dispatch(
      userActions({
        UserAction: ActionConstants.Login,
        isLoginSuccessful: false,
      })
    );
    this.store.dispatch(showErrorBox(responseMsg));
    const dummyUserObj = {
      UserAction: "SlickPosition",
      selectedTab: "",
      slickIdx: NavBarConstants.DASHBOARDSLICK,
    };
    this.store.dispatch(slickStateAction(dummyUserObj));
  }

  getAudienceList() {
    const Url = this.urlConfig.getAudienceListUrl();
    const reqObjectSeg = {
      authToken: getAuthContext().idToken.rawIdToken,
      url: Url,
    };
    this.clnt.getResponse(Url, reqObjectSeg).then((returnVal: any) => {
      let audienceListAdmin = new AudiencesModel(returnVal);
      const respObj = {
        AudienceListAdmin: audienceListAdmin.getAudienceList(),
      };

      this.store.dispatch(AudienceActionAdmin(respObj));
    });
  }

  getCRMCANList() {
    const Url = this.urlConfig.getCrmCanUrl();
    const reqObjectSeg = {
      authToken: getAuthContext().idToken.rawIdToken,
      url: Url,
    };
    let canList = {};
    this.clnt.getResponse(Url, reqObjectSeg).then((returnVal: any) => {
      if (returnVal.hasOwnProperty("status") && returnVal.status) {
        canList = {};
      } else {
        let crmModel = new CRMCanModel(returnVal);
        canList = { CRMCanList: crmModel.getcrmCanList() };
      }
      this.store.dispatch(updateCRMCanList(canList));
    });
  }

  getTVCampaignList() {
    const dummyUserObj = {
      data: { url: this.urlConfig.getTVOrderCampaignList() },
    };
    this.store.dispatch(getAllTVOrders(dummyUserObj));
  }

  getCoreConfig() {
    const segservice = "Config";
    const reqObjectSeg = {
      authToken: "",
      url: this.urlConfig.getCoreConfiguration(),
      serviceName: segservice,
    };
    const token = this.getAuhObj().authToken;
    reqObjectSeg.authToken = token;
    this.clnt
      .getResponse(reqObjectSeg.url, reqObjectSeg)
      .then((returnVal: any) => {
        // returnVal = require("../../data/coresettings.json");
        let resultSet = returnVal;
        returnVal.reportingData = getParsedConfiguration(returnVal);
        if (returnVal.status >= 400 && returnVal.status < 600) {
          this.store.dispatch(sendCoreConfigurationToReporting({ data: [] }));
        } else {
          this.store.dispatch(
            sendCoreConfigurationToReporting({ data: resultSet })
          );
        }
      });
  }
  saveAudience(audienceData: any, overWrite?: any) {
    const token = this.getAuhObj();
    const uName = token.userName;
    const sizingCountResp = this?.store
      .getState()
      .SizingCountStats.hasOwnProperty("data")
      ? this.store.getState().SizingCountStats.data
      : {
          counts: {
            Canadians: 0,
            TvSessions: 0,
            DigitalSessions: 0,
          },
        };
    const reqObjectSeg = {
      authToken: token.authToken,
      url: this.urlConfig.getSaveAudienceListUrl(overWrite),
      userName: uName,
    };
    const inputOptions = getPayloadForSaveAudience(
      uName,
      audienceData.saveSegmentName,
      audienceData.segmentation,
      sizingCountResp
    );
    this.clnt
      .post("POST", reqObjectSeg, inputOptions)
      .then((returnVal: any) => {
        const messageBoxObj = {
          FlyoutDialog: {
            MessageBox: {
              isVisible: true,
              UserMessage: " Your audience has been saved.",
              saveFailed: false,
              boxButtons: UserOps.CLOSE,
              messageHead: "Success!",
            },
          },
        };
        if (returnVal.status >= 400 && returnVal.status < 600) {
          messageBoxObj.FlyoutDialog.MessageBox.messageHead = "Error!";
          messageBoxObj.FlyoutDialog.MessageBox.UserMessage =
            "Unable to save audience, please try again";
          messageBoxObj.FlyoutDialog.MessageBox.saveFailed = true;
          this.store.dispatch(AudienceStatusAction({ overWrite: false }));
        } else if (returnVal.status === "Failed") {
          messageBoxObj.FlyoutDialog.MessageBox.isVisible = false;
          this.store.dispatch(AudienceStatusAction({ overWrite: true }));
        } else {
          this.store.dispatch(AudienceStatusAction({ overWrite: false }));
          reqObjectSeg.url = this.urlConfig.getAudienceListUrl();
          this.getAudienceListNew(token, 0, reqObjectSeg);
        }

        this.store.dispatch(submitFlyoutConfigAction(messageBoxObj));
      });
  }
  getAudienceListNew(
    authorizationResponse: any,
    slickIndex: number,
    reqObject: any,
    reloadLogin?: boolean
  ) {
    this.clnt.getResponse(reqObject.url, reqObject).then((returnVal: any) => {
      this.audienceList = new Audiences(
        returnVal,
        authorizationResponse.userName
      );
      const respObj = { AudienceList: this.audienceList.getAudienceList() };
      this.store.dispatch(AudienceAction(respObj));
    });
  }

  getSegments() {
    const authorizationResponse = this.getAuhObj();
    let authSuccess = false;
    const reqObject = {
      userName: "",
      url: "",
      password: "",
      serviceName: "",
      authToken: "",
    };
    reqObject.url = this.urlConfig.segmentGetUrl;
    reqObject.authToken = authorizationResponse.authToken;
    const dummyResponse = {
      WizardPage: {
        currentSelectedIndex: 0,
        toggleSwith: 0,
        PanelFirstZoneList: parseList(getFakeSegmentResponse()),
        PanelSecondZoneList: {
          PanelFirstZoneList: [{}],
        },
        isDropZonContainsList: true,
      },
    };
    this.clnt.getResponse(reqObject.url, reqObject).then((returnVal: any) => {
      if (
        returnVal.hasOwnProperty("status") &&
        returnVal.status >= 400 &&
        returnVal.status < 600
      ) {
        let spinnerState = { UIConfig: { isSpinnerActive: false } };
        this.store.dispatch(submitSpinnerAction(spinnerState));
        authSuccess = false;
      } else if (returnVal.hasOwnProperty("segmentation")) {
        let advMode =
          returnVal.hasOwnProperty("advanceMode") &&
          returnVal.advanceMode === "Y"
            ? true
            : false;

        dummyResponse.WizardPage.PanelFirstZoneList = parseList(
          returnVal.segmentation,
          advMode
        );
        this.store.dispatch(submitWizardAction(dummyResponse));
        authSuccess = true;
        this.fetchAudienceList();
      }
    });
    return authSuccess;
  }

  fetchAudienceList() {
    const reqObject = {
      userName: "",
      url: "",
      password: "",
      serviceName: "",
      authToken: "",
    };
    reqObject.url = this.urlConfig.getAudienceListUrl();
    const authorizationResponse = this.getAuhObj();
    reqObject.authToken = authorizationResponse.authToken;
    this.getAudienceListNew(authorizationResponse, 0, reqObject, true);
  }

  overWriteAudience(audienceData: any, overWrite?: any) {
    const token = this.getAuhObj();
    const uName = token.userName;
    const sizingCountResp = this.store
      .getState()
      .SizingCountStats.hasOwnProperty("data")
      ? this.store.getState().SizingCountStats.data
      : {
          counts: {
            Canadians: 0,
            TvSessions: 0,
            DigitalSessions: 0,
          },
        };
    const reqObjectSeg = {
      authToken: token.authToken,
      url: this.urlConfig.getSaveAudienceListUrl(overWrite),
      userName: uName,
    };

    const inputOptions = getPayloadForSaveAudience(
      uName,
      audienceData.saveSegmentName,
      audienceData.segmentation,
      sizingCountResp
    );
    this.clnt.put("Put", reqObjectSeg, inputOptions).then((returnVal: any) => {
      const messageBoxObj = {
        FlyoutDialog: {
          MessageBox: {
            isVisible: true,
            UserMessage: " Your audience has been saved.",
            saveFailed: false,
            boxButtons: UserOps.CLOSE,
            messageHead: "Success!",
          },
        },
      };
      if (returnVal.status >= 400 && returnVal.status < 600) {
        messageBoxObj.FlyoutDialog.MessageBox.messageHead = "Error!";
        messageBoxObj.FlyoutDialog.MessageBox.UserMessage =
          "Unable to save audience, please try again";
        messageBoxObj.FlyoutDialog.MessageBox.saveFailed = true;
        this.store.dispatch(AudienceStatusAction({ overWrite: false }));
      } else if (returnVal.status === "Failed") {
        messageBoxObj.FlyoutDialog.MessageBox.isVisible = false;
        this.store.dispatch(AudienceStatusAction({ overWrite: true }));
      } else {
        this.store.dispatch(AudienceStatusAction({ overWrite: false }));
        reqObjectSeg.url = this.urlConfig.getAudienceListUrl();
        this.getAudienceListNew(token, 0, reqObjectSeg);
      }

      this.store.dispatch(submitFlyoutConfigAction(messageBoxObj));
    });
  }

  getAudienceListForUser(
    authorizationResponse: any,
    slickIndex: number,
    reqObject: any,
    reloadLogin?: boolean
  ) {
    const inputOptions = { username: reqObject.userName };
    this.clnt.post("POST", reqObject, inputOptions).then((returnVal: any) => {
      this.audienceList = new Audiences(
        returnVal,
        authorizationResponse.userName
      );
      const respObj = { AudienceList: this.audienceList.getAudienceList() };
      this.store.dispatch(AudienceAction(respObj));
    });
  }

  deleteAudience(audienceId: any) {
    const token = this.getAuhObj();
    const reqObjectSeg = {
      authToken: token.authToken,
      url: this.urlConfig.getDeleteAudienceListUrl(),
    };
    const inputOptions = { audienceSizingId: audienceId };
    this.clnt.delete(reqObjectSeg, inputOptions).then((returnVal: any) => {
      if (
        returnVal.hasOwnProperty("status") &&
        returnVal.status === UserOps.SUCCESS
      ) {
        reqObjectSeg.url = this.urlConfig.getAudienceListUrl();
        this.fetchAudienceList();
      }
    });
    this.store.getState().configState.Dialog.confirmAction = false;
  }

  FetchAudiences(audienceId: any, histry: any) {
    const token = this.getAuhObj();
    const reqObjectSeg = {
      authToken: token.authToken,
      url: this.urlConfig.getEditAudienceListUrl(),
    };
    const inputOptions = { audienceSizingId: audienceId };
    this.clnt
      .post("POST", reqObjectSeg, inputOptions)
      .then((returnVal: any) => {
        const dummyResponse = {
          WizardPage: {
            currentSelectedIndex: 0,
            toggleSwith: 0,
            PanelFirstZoneList: parseList(getFakeSegmentResponse()),
            PanelSecondZoneList: {
              PanelFirstZoneList: [{}],
            },
            editAudienceZone: false,
            isDropZonContainsList: false,
            audienceName: "",
          },
        };
        setAllFalse(this.store.getState().WizardPageStats);
        if (returnVal.hasOwnProperty("segmentation")) {
          let advMode =
            returnVal.hasOwnProperty("advanceMode") &&
            returnVal.advanceMode === "Y"
              ? true
              : false;

          dummyResponse.WizardPage.PanelFirstZoneList = parseList(
            returnVal.segmentation,
            advMode
          );
          dummyResponse.WizardPage.editAudienceZone = true;
          dummyResponse.WizardPage.audienceName =
            this.audienceList.getAudienceName(audienceId);
          this.store.dispatch(submitWizardAction(dummyResponse));
        }
        if (
          returnVal.hasOwnProperty("status") &&
          returnVal.status >= 400 &&
          returnVal.status < 600
        ) {
        }
        histry.push("/AudienceSizer");
        this.addResizeEvent();
        const dummyUserObj = {
          UserAction: "SlickPosition",
          selectedTab: "",
          slickIdx: NavBarConstants.AUDIENCESIZINGSLICK,
        };
        this.store.dispatch(slickStateAction(dummyUserObj));
      });
    Logger.getInstance().printWarnLogs("Fetch complete");
  }

  public sendEmail(payload: any, histry: any) {
    const token = this.getAuhObj().authToken;
    const reqObjectSeg = {
      authToken: token,
      url: this.urlConfig.getCampaignEmailUrl(),
    };
    payload.mailToAddresses = this.getAuhObj().userName;
    payload.userName = this.getAuhObj().userFullName;
    payload.isFailure = false;
    this.clnt.post("POST", reqObjectSeg, payload).then((returnVal: any) => {
      if (returnVal.hasOwnProperty("status") && returnVal.status === "Failed") {
        payload.isFailure = true;
      }
      if (returnVal.hasOwnProperty("audienceDetails")) {
        payload.audienceName = returnVal.audienceDetails;
      }
      if (returnVal.hasOwnProperty("mergeMode")) {
        let mergeModeStr = returnVal.mergeMode.toUpperCase();
        mergeModeStr = mergeModeStr.replace(/\s/g, "");
        if (mergeModeStr === "OR") {
          payload.mergeMode = "Any of";
          payload.audienceName = payload.audienceName.split("OR");
        } else if (mergeModeStr === "AND") {
          payload.mergeMode = "Any of";
          payload.audienceName = payload.audienceName.split("AND");
        } else {
          payload.mergeMode = "Advance";
          payload.audienceName = [payload.audienceName];
        }
      }
      const dummyUserObj = {
        UserAction: "SlickPosition",
        selectedTab: "",
        slickIdx: NavBarConstants.EMAILCONFPAGESLICK,
      };
      this.store.dispatch(slickStateAction(dummyUserObj));
      histry.push("/CampaignConfirmation");
    });
    this.store.getState().CampaignState.userAction = "";
  }

  getLineChartDetail() {
    const token = this.getAuhObj().authToken;
    const reqObjectSeg = {
      authToken: token,
      url: this.urlConfig.getLineChartUrl(),
    };

    this.clnt
      .getResponse(reqObjectSeg.url, reqObjectSeg)
      .then((returnVal: any) => {
        // workaround
        //returnVal = require("../../data/linecharts.json");
        if (returnVal.hasOwnProperty("status") && returnVal.status) {
          if (returnVal.status > 400 && returnVal.status < 600) {
            // const dummyUserObj =  {ChartData:require('../../Dashboard/data/dataSource.json')};
            const dummyUserObj = { ChartData: {} };
            this.store.dispatch(sendLineChartAction(dummyUserObj));
          }
        } else {
          // const dummyUserObj =  {ChartData:require('../../Dashboard/data/dataSource.json')};
          const dummyUserObj = { ChartData: returnVal };
          this.store.dispatch(sendLineChartAction(dummyUserObj));
        }
        let spinnerState = { UIConfig: { isSpinnerActive: false } };
        this.store.dispatch(submitSpinnerAction(spinnerState));
      });
    this.store.getState().CampaignState.userAction = "";
  }

  loadAllUsers(showSpinner: any) {
    const Url = this.urlConfig.getListUsersUrl();
    const segservice = "Login";
    const reqObjectSeg = { authToken: "", url: Url, serviceName: segservice };
    const spinnerState = { UIConfig: { isSpinnerActive: true } };
    if (showSpinner) {
      this.store.dispatch(submitSpinnerAction(spinnerState));
    }

    const token = this.token;
    reqObjectSeg.authToken = token;

    this.clnt
      .getResponse(reqObjectSeg.url, reqObjectSeg)
      .then((returnVal: any) => {
        if (returnVal.hasOwnProperty("status") && returnVal.status) {
        } else {
          const usersList = new AdminModel(
            returnVal,
            getAuthContext().idToken.name
          );
          const respObj = { UsersList: usersList.getUserNames() };
          this.store.dispatch(sendAdminUserListAction(respObj));
        }
        const spinnerState = { UIConfig: { isSpinnerActive: false } };
        if (showSpinner) {
          this.store.dispatch(submitSpinnerAction(spinnerState));
        }
      });
  }

  addResizeEvent() {}
}
