import Excel from 'exceljs'
import { getPerfReportTItle, getReportRangeDate } from 'src/CommonComponent/Calendar/utils/CampaignUtil'


abstract class ExcelSheet {
    protected wb: Excel.Workbook
    protected sheet: Excel.Worksheet
    protected reportDates: any
    protected orderInfo: Record<string, string | boolean> // I hate this object so much
    protected logo: any;
    protected abstract columnCount: number
    protected abstract colPadd: number
    protected standardCellBorder: Partial<Excel.Borders> = {
        top: {style:'thin'},
        left: {style:'thin'},
        bottom: {style:'thin'},
        right: {style:'thin'}
    }
    constructor(
        workbook: Excel.Workbook, name: string, 
        reportDates: any,
        orderInfo: Record<string, string | boolean>,
        logo: any)
    {
        this.wb = workbook        
        const sheetProperties = {
            properties:{tabColor:{argb:'FFF00000'}},
            views: [{showGridLines: false, zoomScale: 70}]
        }
        this.sheet = this.wb.addWorksheet(name, sheetProperties)
        this.reportDates = reportDates
        this.orderInfo = orderInfo
        this.logo = logo
    }

    private addHeaderLine(txt: string): Excel.Row {
        const row = this.sheet.addRow(['', txt])
        row.font = { bold: true, size: 11 }
        return row
    }

    protected addSheetHeader() {
        this.sheet.getColumn(1).width = 1
        this.sheet.addRow([]).commit()
        this.sheet.addRow([]).commit()
        const summary = this.sheet.addRow(['', 'Campaign Summary'])
        summary.font = { bold: true, size: 20 }
        summary.height = 60
        summary.commit()
        const spacer = this.sheet.addRow([])
        spacer.height = 6.5
        for (var i=2; i<=this.columnCount; i++)
        {
            spacer.getCell(i).border = {
                top: {style: 'medium', color: {argb: 'FF000000'}}
            }
        }
        spacer.commit()
        var row4 = this.addHeaderLine('Advertiser: ' + this.orderInfo['Advertiser'])
        var dateHeader = row4.getCell(this.columnCount)
        dateHeader.value = 'Date Range:'
        dateHeader.font = { bold: true, size: 9 }
        dateHeader.alignment = { horizontal: 'right' }
        row4.commit()
        var row5 = this.addHeaderLine('Campaign Name: ' + this.orderInfo['Order Name'])
        var dateRange = row5.getCell(this.columnCount)
        dateRange.value = getReportRangeDate(this.reportDates.reportStartDate, this.reportDates.reportEndDate);
        dateRange.font = { bold: false, size: 11 }
        dateRange.alignment = { horizontal: 'right' }
        row5.commit()        
        this.addHeaderLine('IO #: ' + this.orderInfo['Order ID']).commit()
        this.addHeaderLine('Campaign Flight Date: ' +getReportRangeDate(this.reportDates.orderStartDate,this.reportDates.orderEndDate)).commit()
        this.addHeaderLine('Performance Report: ' + getPerfReportTItle(this.reportDates.orderEndDate,this.reportDates.reportEndDate,this.reportDates.orderStartDate,this.reportDates.reportStartDate)).commit()
        if (this.logo) this.sheet.addImage(this.logo.id, {
            tl: { col: this.columnCount + this.colPadd - 1.9, row: 1 - 0.5 },
            ext: { width: this.logo.width, height: this.logo.height },
            editAs: 'oneCell'
        });
    }

    protected addSectionHeading(items: Array<string>, rowHeight:number = 30): Excel.Row {
        const heading = this.sheet.addRow(items)
        heading.font = { bold: true, size: 10, color: {argb: 'FFFFFFFF'} }
        let rows = Math.max(...items.map(x => x.split('\r\n').length))
        // was 30 and modified to 35
        heading.height = Math.max(rowHeight, rows * 15)
        heading.alignment = {horizontal: 'center', vertical: 'middle', wrapText: true}

        for (var i=2; i<=items.length; i++)
        {
            heading.getCell(i).fill = {type: 'pattern', pattern: 'solid', fgColor: {argb: 'FFFC0000'}, bgColor: {argb: 'FFFFFFFF'}}            
            heading.getCell(i).border = {
                top: {style:'thin'},
                left: {style:'thin'},
                bottom: {style:'thin'},
                right: {style:'thin'}
            }
        }
        return heading
    }

    protected safeInt(txt: any) {
        return parseInt(String(txt).length > 0 ? String(txt) : '0')
    }

    protected safeFloat(txt: any) {
        return parseFloat(String(txt).length > 0 ? String(txt) : '0')
    }
    
}
export default ExcelSheet