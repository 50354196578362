import DomoOrderRecord from './DomoOrderRecord'
class OrderLineTotals {
    public deliveredImpressions: number = 0
    public clicks: number = 0
    public conversions: number = 0
    public contractedSpend: number = 0
    public actualSpend: number = 0
    public ctr: number = 0

    constructor(data: DomoOrderRecord | null) {
        if (data)
        {
            for (var line of data.lines)
            {
                this.deliveredImpressions += line.deliveredImpressions
                this.clicks += line.clicks
                this.conversions += line.conversions
                this.contractedSpend += line.contractedSpend
                this.actualSpend += line.actualSpend
            }
            this.ctr = this.deliveredImpressions === 0 ? 0 : (this.clicks / this.deliveredImpressions * 100.0)
        }
        else
        {
            this.deliveredImpressions = 0
            this.clicks = 0
            this.conversions = 0
            this.contractedSpend = 0
            this.actualSpend = 0
            this.ctr = 0
        }
    }
}
export default OrderLineTotals