import EmbeddedImage from "./EmbeddedImage";
var CFB = require("cfb");

type ImagePointer = {
  offset: number;
  size: number;
};

class Powerpoint2003File {
  private _imageData: DataView | null = null;
  private _images = new Array<ImagePointer>();
  public filename = "";

  constructor(fileData: File, onLoaded: (arg0: Powerpoint2003File) => void) {
    const reader = new FileReader();
    this.filename = fileData.name;
    reader.onload = () => {
      if (reader.result instanceof ArrayBuffer) {
        var cfb = CFB.read(new Uint8Array(reader.result), { type: "array" });
        var pictures = CFB.find(cfb, "Root Entry/Pictures");
        if (pictures) {
          this._imageData = new DataView(
            new Uint8Array(pictures.content).buffer
          );
          var pointer = 0;
          while (pointer < this._imageData.byteLength) {
            let verInstance = this._imageData.getUint16(pointer, true);
            let instance = verInstance >> 4;
            let recType = this._imageData.getUint16(pointer + 2, true);
            let recLen = this._imageData.getUint32(pointer + 4, true);
            if (recType === 0xf01e) {
              let headerOffset = instance === 0x6e0 ? 17 : 33;
              this._images.push({
                offset: pointer + 8 + headerOffset,
                size: recLen,
              });
            }
            pointer += 8 + recLen;
          }
        }
      }
      onLoaded(this);
    };
    reader.readAsArrayBuffer(fileData);
  }

  imageCount = () => {
    return this._images.length;
  };

  getImage = (index: number): EmbeddedImage | undefined => {
    if (this._imageData && index < this._images.length) {
      let imagePointer = this._images[index];
      return {
        format: "PNG",
        width: this._imageData.getUint32(imagePointer.offset + 16),
        height: this._imageData.getUint32(imagePointer.offset + 20),
        data: this._imageData.buffer.slice(
          imagePointer.offset,
          imagePointer.offset + imagePointer.size
        ),
        size: imagePointer.size,
      };
    }
    return undefined;
  };
}
export default Powerpoint2003File;
