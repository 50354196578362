/* eslint-disable */
import { isDataExist } from "../../Utility/commonUtil";
import { IChartModel } from "../Model/IGraphModel";

export function convertJsonToGraphModel(jsonObj: any, dataSource: any) {
  const firstParty = "FirstParty";
  const thridParty = "ThirdParty";
  const delivery = "Delivery";
  const dsource = "dataSource";
  if (isDataExist(jsonObj, "dataSource")) {
    let chartDSource = [{}];
    jsonObj.dataSource.forEach((element: any) => {
      const chartItem = <IChartModel>{};
      chartItem.dataSourceName = element.name;
      chartItem.lastUpdatedDuration = element.lastUpdated;
      chartItem.dataSourcePoints = element.dataPoint;
      chartItem.message = element.description;
      chartDSource.push(chartItem);
    });
    chartDSource.splice(0, 1);
    dataSource[dsource] = chartDSource;
  }
  if (isDataExist(jsonObj, "dataSourceDelivery")) {
    let chartDSource = [{}];
    jsonObj.dataSourceDelivery.forEach((element: any) => {
      const chartItem = <IChartModel>{};
      chartItem.dataSourceName = element.name;
      chartItem.lastUpdatedDuration = element.lastUpdated;
      chartItem.dataSourcePoints = element.dataPoint;
      chartItem.message = element.description;
      chartDSource.push(chartItem);
    });
    chartDSource.splice(0, 1);
    dataSource[delivery] = chartDSource;
  }
  if (isDataExist(jsonObj, "dataSourceThirdParty")) {
    let chartDSource = [{}];
    jsonObj.dataSourceThirdParty.forEach((element: any) => {
      const chartItem = <IChartModel>{};
      chartItem.dataSourceName = element.name;
      chartItem.lastUpdatedDuration = element.lastUpdated;
      chartItem.dataSourcePoints = element.dataPoint;
      chartItem.message = element.description;
      chartDSource.push(chartItem);
    });
    chartDSource.splice(0, 1);
    dataSource[thridParty] = chartDSource;
  }
  if (isDataExist(jsonObj, "dataSourceFirstParty")) {
    let chartDSource = [{}];
    jsonObj.dataSourceFirstParty.forEach((element: any) => {
      const chartItem = <IChartModel>{};
      chartItem.dataSourceName = element.name;
      chartItem.lastUpdatedDuration = element.lastUpdated;
      chartItem.dataSourcePoints = element.dataPoint;
      chartItem.message = element.description;
      chartDSource.push(chartItem);
    });
    chartDSource.splice(0, 1);
    dataSource[firstParty] = chartDSource;
  }
  return dataSource;
}
