/* eslint-disable */
import * as React from "react";
import { connect } from "react-redux";
import {
  slickStateAction,
  saveReportingStatusAction,
  reportingStatusAction,
  adminCalendarChangeAction,
} from "../../Actions";
import { UserOps } from "../../ConstConfig/UserOps";
import { AdminOperationKeys } from "../../ConstConfig/AdminOperationKeys";
import MessageBox from "../../CommonComponent/MessageBox";
import { NavBarConstants } from "../../ConstConfig";
import "../../CSS/perfect-scrollbar.css";
import {
  isEmptyOrSpaces,
  getTomorrowsDate,
  getNewEndDate,
} from "src/Utility/CampaignUtil";
import {
  clearAllErrorStateReportingTrait,
  convertToDate,
} from "../utils/dashboardValidation";
import AdminReportingDateControl from "src/CommonComponent/Calendar/AdminReportingDateControl";
import {
  convertToUTCDateForYYYYMMDD,
  findDiffInDays,
} from "src/CommonComponent/Calendar/utils/CampaignUtil";
import CustomDropDown from "src/CommonComponent/CustomDropDown";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; 
import CustomErrorAlertBanner from "src/CommonComponent/CustomErrorAlertBanner.";
import { compareDates } from "src/Utility/reportingValidation";

const update = require("react-addons-update");

class AdminReportingBannerStatus extends React.Component<
  IAdminReportingBannerStatus,
  {}
> {
  state: any;
  private refArr: any;
  private startDateCal: any;
  private endDateCal: any;

  constructor(props: any) {
    super(props);
    this.goBack = this.goBack.bind(this);
    this.loadAdminPage = this.loadAdminPage.bind(this);
    this.saveReportingConf = this.saveReportingConf.bind(this);
    this.showPageDropDown = this.showPageDropDown.bind(this);
    this.getUserSelectedPageLabel = this.getUserSelectedPageLabel.bind(this);
    this.savePageLabel = this.savePageLabel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.refArr = [];
    this.state = {
      selectedCat: 1,
      pageLabel: "Digital",
      content: {
        Digital: this.props.currentReportingStatus,
        LinOpt: this.props.currLinOptStatus,
      },
      errorStateCheck: {},
    };
    this.state.errorStateCheck = clearAllErrorStateReportingTrait();
    this.setUserText = this.setUserText.bind(this);
    this.startDateCal = {
      Id: "startDate",
      NameId: "#startDateCal",
      name: "ALERT START DATE",
      identifier: "startDateCal",
    };
    this.endDateCal = {
      Id: "endDate",
      NameId: "#endDateCal",
      name: "ALERT END DATE",
      identifier: "endDateCal",
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.currentReportingStatus?.id != 0 &&
      this.state.content["Digital"].id === 0
    ) {
      this.setState({
        content: {
          Digital: this.props.currentReportingStatus,
          LinOpt: this.props.currLinOptStatus,
        },
      });
    }
  }

  goBack() {
    this.props.history.push("/AdminLandingPage");
  }

  setUserText(userText: any) {
    let evnt = { target: { value: userText } };
    this.onControlsChange(AdminOperationKeys.STATUSMSG, evnt);
  }

  onControlsChange(userInput: any, e: any) {
    switch (userInput) {
      case AdminOperationKeys.STATUSMSG:
        {
          this.state.content[this.state.pageLabel].message = e.target.value;
        }
        break;
      case AdminOperationKeys.REPORTBANNERTOGGLE:
        {
          this.state.content[this.state.pageLabel].selected = e.target.checked;
        }
        break;
      default:
        break;
    }
    this.setState(
      update(this.state, {
        state: {
          $set: this.state,
        },
      })
    );
  }

  saveReportingConf() {
    this.state.errorStateCheck = clearAllErrorStateReportingTrait();
    const isInValidDateRange = findDiffInDays(
      this.props.usrSelectedDates[this.state.pageLabel].startDate.date,
      this.props.usrSelectedDates[this.state.pageLabel].endDate.date
    );
    if (
      !isEmptyOrSpaces(this.state.content[this.state.pageLabel].message) &&
      isInValidDateRange >= 0
    ) {
      let startTs = convertToUTCDateForYYYYMMDD(
        this.props.usrSelectedDates[this.state.pageLabel].startDate.date
      );
      let endTs = convertToUTCDateForYYYYMMDD(
        this.props.usrSelectedDates[this.state.pageLabel].endDate.date
      );

      const toggleState = this.state.content[this.state.pageLabel].selected
        ? "Y"
        : "N";
      const id = this.state.content[this.state.pageLabel].id
        ? this.state.content[this.state.pageLabel].id
        : "";
      const payload = {
        id: id,
        pageLabel: this.state.pageLabel,
        selected: toggleState,
        message: this.state.content[this.state.pageLabel].message,
        startTs: startTs,
        endTs: endTs,
      };
      this.props.handleUserEntryUpdateAction(
        payload,
        this.props.currentReportingStatus,
        this.props.currLinOptStatus
      );
      const comp = this.refArr[1];
      if (comp) {
        comp.click();
      }
      this.setState(
        update(this.state, {
          pageLabel: {
            $set: "",
          },
        })
      );
    } else {
      if (isEmptyOrSpaces(this.state.content[this.state.pageLabel].message)) {
        this.state.errorStateCheck.reportingDescription.show = true;
      }
      if (isInValidDateRange < 0) {
        this.state.errorStateCheck.reportDateError.show = true;
      }
    }
    this.setState(
      update(this.state, {
        state: {
          $set: this.state,
        },
      })
    );
  }

  loadAdminPage() {
    const dummyUserObj = {
      UserAction: "SlickPosition",
      selectedTab: NavBarConstants.ADMINSLICK,
      slickIdx: NavBarConstants.ADMINSLICK,
    };
    this.props.handleSubmit(dummyUserObj);
    this.props.history.push("/Admin");
  }

  handleChange(value: any) {
    this.setUserText(value);
  }

  focusText(ref: any) {
    const refComponent = ref;
    this.refArr.push(refComponent);
  }

  getUserSelectedPageLabel() {
    return this.state.pageLabel;
  }

  savePageLabel(subCatID: any) {
    this.state.pageLabel = subCatID.title;
    this.props.updateDates(
      this.state.content[this.state.pageLabel].startTs,
      this.state.content[this.state.pageLabel].endTs,
      subCatID.title,
      this.props.usrSelectedDates
    );
    this.setState(
      update(this.state, {
        state: {
          $set: this.state,
        },
      })
    );
  }

  showPageDropDown() {
    let subCat = {
      list: [{ title: "Digital" }, { title: "LinOpt" }],
      isEditMode: "",
      toggle: "dropdown",
    };
    return (
      <CustomDropDown
        ddList={subCat}
        selectDDListItem={this.savePageLabel}
        selectedItem={this.getUserSelectedPageLabel}
        uiClasses={"ddWidth"}
      />
    );
  }

  render() {
    return (
      <main role="main" className="container-fluid">
        <div className="row fixed-header-top ml-0 mr-0">
          <div className="col-12">
            <div className="float-left w-100 spacerB36  borderBottomGray">
              <div className="col-xl-7 col-md-12 col-sm-12 col-12 pl-0 pr-0">
                <h3 className="float-left">Reporting Status Page</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="row-flex spaceTop">
          <div className="w-100">
            <form>
              <div className="col-xl-6 col-md-6 col-sm-8 col-12 spaceBottom">
                <div className="form-group leftPos">
                  <label htmlFor="category">SELECT PAGE</label>
                  <div className="selectedTraits position-relative">
                    {this.showPageDropDown()}
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-md-6 col-sm-8 col-12">
                <div className="form-group leftPos">
                  <label htmlFor="formDescription">STATUS MESSAGE</label>
                  <ReactQuill
                    value={this.state.content[this.state.pageLabel]?.message}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="col-xl-3 col-md-6 col-sm-12 col-12">
                <AdminReportingDateControl CalendarCtrl={this.startDateCal} pageLabel={this.state.pageLabel} />
              </div>
              <div className="col-xl-3 col-md-6 col-sm-12 col-12">
                <AdminReportingDateControl CalendarCtrl={this.endDateCal} pageLabel={this.state.pageLabel} />
              </div>
              <div className="col-12 spaceBottom">
                <CustomErrorAlertBanner
                  errorMessageStruct={
                    this.state.errorStateCheck.reportDateError
                  }
                />
              </div>
              <div className="col-xl-6 col-md-6 col-sm-8 col-12">
                <div className="form-group leftPos">
                  <div className="reportStatus">
                    <span key={"span"}>
                      <input
                        type="checkbox"
                        id={"chkBox"}
                        checked={ this.state.content[this.state.pageLabel].selected == true ||
                          this.state.content[this.state.pageLabel].selected == "Y"}
                        onChange={this.onControlsChange.bind(
                          this,
                          AdminOperationKeys.REPORTBANNERTOGGLE
                        )}
                        ref={(node) => this.focusText(node)}
                      />
                      <label htmlFor={"chkBox"}>
                        Show status message on reporting page
                      </label>
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-12 buttonPanel spaceBottom float-left">
                <div className="borderBottomGray spaceBottom48" />
                <a
                  className="btn btnPrimary"
                  href="javascript:void(0);"
                  role="button"
                  onClick={this.saveReportingConf}
                >
                  Save
                </a>
                <a
                  className=""
                  href="javascript:void(0)"
                  role="button"
                  data-toggle="modal"
                  data-target="#messageBoxGeneric"
                  data-backdrop="static"
                  ref={(node) => this.focusText(node)}
                />
                <a
                  className="btn btnPrimary float-right"
                  href="javascript:void(0);"
                  role="button"
                  onClick={this.loadAdminPage}
                >
                  Cancel
                </a>
              </div>
            </form>
          </div>
        </div>
        <MessageBox handleUserAction={this.loadAdminPage} />
      </main>
    );
  }
}

function mapStateToProps(state: any, props: any) {
  return {
    currentReportingStatus:
      state.ReportingConfigState.hasOwnProperty("data") &&
      state.ReportingConfigState.data.hasOwnProperty("currReportingStatus")
        ? state.ReportingConfigState.data.currReportingStatus
        : {
            id: 0,
            pageLabel: "Digital",
            message: "",
            selected: false,
            startTs: "",
            endTs: "",
            creationTs: "",
            updateTs: "",
          },
    currLinOptStatus:
      state.ReportingConfigState.hasOwnProperty("data") &&
      state.ReportingConfigState.data.hasOwnProperty("currLinOptStatus")
        ? state.ReportingConfigState.data.currLinOptStatus
        : {
            id: 0,
            pageLabel: "LinOpt",
            message: "",
            selected: false,
            startTs: "",
            endTs: "",
            creationTs: "",
            updateTs: "",
          },
    errorMessage: props.errorMessage,
    usrSelectedDates:
      state.AdminCalendarControlState.hasOwnProperty("data") &&
      state.AdminCalendarControlState.data
        ? state.AdminCalendarControlState.data
        : {
            startDate: { date: getTomorrowsDate() },
            endDate: { date: getNewEndDate(getTomorrowsDate()) },
          },
  };
}

export default connect(mapStateToProps, (dispatch) => {
  return {
    handleUserEntryUpdateAction(payload: any, digital: any, linOpt: any) {
      let resp = false;
      if (
        payload.hasOwnProperty("selected") &&
        (payload.selected === "Y" || payload.selected === true)
      ) {
        if (payload.hasOwnProperty("startTs") && payload.startTs) {
          const utcDate = convertToDate(payload.startTs, false);
          const utcEndDate = convertToDate(payload.endTs, false);
          const isToday = compareDates(new Date(), utcDate);
          const isEndDayToday = compareDates(new Date(), utcEndDate);
          if (isToday === 0 || isEndDayToday === 0) {
            resp = true;
          } else if (isToday < 0 && isEndDayToday >= 1) {
            resp = true;
          }
        }
      }
      if (payload.pageLabel === "Digital") {
        payload["displayBanner"] = resp;
        payload.pageLable = payload.pageLabel;
        delete payload.pageLabel;
        payload.userAction = UserOps.SET_REPORTING_STATUS;
        dispatch(saveReportingStatusAction(payload));
        dispatch(
          reportingStatusAction({
            data: { currReportingStatus: payload, currLinOptStatus: linOpt },
          })
        );
      } else if (payload.pageLabel === "LinOpt") {
        payload["displayBanner"] = resp;
        payload.pageLable = payload.pageLabel;
        delete payload.pageLabel;
        payload.userAction = UserOps.SET_REPORTING_STATUS;
        dispatch(saveReportingStatusAction(payload));
        dispatch(
          reportingStatusAction({
            data: { currReportingStatus: digital, currLinOptStatus: payload },
          })
        );
      }
    },
    handleSubmit(payload: any) {
      dispatch(slickStateAction(payload));
    },
    updateDates(startTs: any, endTs: any, title: string, usrSelectedDates: any) {
      let startDate = convertToDate(startTs, false);
      let endDate = convertToDate(endTs, false);
      const usDates = {...usrSelectedDates};
      let userSelectedDates;
      if(title === "Digital") {
        userSelectedDates = {
          Digital: {
            startDate: { date: startDate },
            endDate: { date: endDate },
        },
          LinOpt: {
            startDate: { date: usDates.LinOpt.startDate.date },
            endDate: { date: usDates.LinOpt.endDate.date },
          }
          
        };

      } else {
        userSelectedDates = {
          Digital: {
            startDate: { date: usDates.Digital.startDate.date },
            endDate: { date: usDates.Digital.endDate.date },
        },
          LinOpt: {
            startDate: { date: startDate },
            endDate: { date: endDate },
          }
          
        };
        
      }

      
      dispatch(adminCalendarChangeAction(userSelectedDates));
    },
  };
})(AdminReportingBannerStatus);

interface IAdminReportingBannerStatus extends React.FC<any> {
  errorMessage: any;
  handleUserEntryUpdateAction?: any;
  currentReportingStatus: any;
  handleSubmit: any;
  usrSelectedDates: any;
  currLinOptStatus: any;
  updateDates: any;
  history: any;
}
