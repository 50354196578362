import pptxfile from "./pptxfile";
import EmbeddedImage from "../utils/EmbeddedImage";
import DomoOrderRecord from "./DomoOrderRecord";
import DomoLineItem from "./DomoLineItem";
import OrderLineTotals from "./OrderLineTotals";
import * as Conversions from "./Conversions";
import DomoOrderRecordWithPagination from "../utils/DomoOrderRecordWithPagination";
import ToplineDomoOrderRecordWithPagination from "../utils/ToplineDomoOrderRecordWithPagination";
import ToplineDomoOrderRecord from "../utils/ToplineDomoOrderRecord";
import ToplineSummaryDomoLineItem from "./ToplineSummaryDomoLineItem";
import ToplineTotals from "../utils/ToplineTotals";

interface ISlideArgs {
  content: XMLDocument;
  presentation: pptxfile;
  name: string;
}

class Slide {
  public content: XMLDocument;
  public presentation: pptxfile;
  public name: string;

  constructor(args: ISlideArgs) {
    this.content = args.content;
    this.presentation = args.presentation;
    this.name = args.name;
  }

  private resolver: XPathNSResolver = {
    lookupNamespaceURI: (prefix) => {
      switch (prefix) {
        case "a":
          return "http://schemas.openxmlformats.org/drawingml/2006/main";
        case "r":
          return "http://schemas.openxmlformats.org/officeDocument/2006/relationships";
        case "p":
          return "http://schemas.openxmlformats.org/presentationml/2006/main";
      }
      return "http://schemas.openxmlformats.org/officeDocument/2006/relationships";
    },
  };

  private randomId(): string {
    let foo = "1";
    for (var i = 0; i < 9; ++i) foo += Math.floor(Math.random() * 10);
    return foo;
  }

  private shapeTreeNode(): Node | null {
    return this.content.evaluate(
      "/p:sld/p:cSld/p:spTree",
      this.content,
      this.resolver,
      XPathResult.FIRST_ORDERED_NODE_TYPE,
      null
    ).singleNodeValue;
  }

  public clearShapes(): void {
    var done = false;
    while (!done) {
      const nodes = this.content.evaluate(
        "/p:sld/p:cSld/p:spTree/p:sp",
        this.content,
        this.resolver,
        XPathResult.ORDERED_NODE_ITERATOR_TYPE,
        null
      );
      const node = nodes.iterateNext();
      if (node && node.parentNode) {
        node.parentNode.removeChild(node);
      }
      done = node == null;
    }
  }

  public addSummaryDetails(
    data: DomoOrderRecordWithPagination,
    logo: EmbeddedImage | null,
    includeCtr: boolean,
    includeConversions: boolean
  ): void {
    const tree = this.shapeTreeNode();
    if (!tree) return;

    this.addSummaryChart(
      data.linesForSlide(0),
      includeCtr,
      includeConversions,
      tree,
      data.slideCount === 1 ? data.footer : null
    );
  }

  /////// Topline starts

  public addToplineSummaryDetails(
    data: ToplineDomoOrderRecordWithPagination,
    logo: EmbeddedImage | null,
    includeCtr: boolean,
    includeConversions: boolean
  ): void {
    const tree = this.shapeTreeNode();
    if (!tree) return;
    this.appendOrderHeaderNode(data, tree);
    tree.appendChild(this.createDeliveredImpressionsNode(data));
    tree.appendChild(this.createContractedImpressionsNode(data));
    tree.appendChild(this.createClicksNode(data));
    tree.appendChild(this.createActualSpendNode(data));
    tree.appendChild(this.createContractedSpendNode(data));
    this.addToplineSummaryChart(
      data.linesForSlide(0),
      includeCtr,
      includeConversions,
      tree,
      data.slideCount === 1 ? data.footer : null
    );
    if (logo) this.addLogo(logo, tree);
  }

  public addToplineSummaryChart(
    data: ToplineSummaryDomoLineItem[],
    includeCtr: boolean,
    includeConversions: boolean,
    parent: Node | null,
    footer: ToplineTotals | null
  ): void {
    const tree = parent == null ? this.shapeTreeNode() : parent;
    if (!tree) return;

    this.appendToplineTable(
      data,
      includeCtr,
      includeConversions,
      tree,
      parent == null,
      footer
    ); // ugly fullpage flag...
  }

  //////// Topline Ends

  public addSummaryChart(
    data: DomoLineItem[],
    includeCtr: boolean,
    includeConversions: boolean,
    parent: Node | null,
    footer: OrderLineTotals | null
  ): void {
    const tree = parent == null ? this.shapeTreeNode() : parent;
    if (!tree) return;

    this.appendOrderLineItemTable(
      data,
      includeCtr,
      includeConversions,
      tree,
      parent == null,
      footer
    ); // ugly fullpage flag...
  }

  appendOrderHeaderNode(
    data: DomoOrderRecord | ToplineDomoOrderRecord,
    parent: Node
  ) {
    const xml = `
      <p:sp xmlns:p="http://schemas.openxmlformats.org/presentationml/2006/main">
      <p:nvSpPr>
        <p:cNvPr id="5" name="TextBox 4">
          <a:extLst xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
            <a:ext uri="{FF2B5EF4-FFF2-40B4-BE49-F238E27FC236}">
              <a16:creationId xmlns:a16="http://schemas.microsoft.com/office/drawing/2014/main" id="{8B76C666-5AAA-46F3-B36F-252865A7DE67}" />
            </a:ext>
          </a:extLst>
        </p:cNvPr>
        <p:cNvSpPr txBox="1" />
        <p:nvPr />
      </p:nvSpPr>
      <p:spPr>
        <a:xfrm xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
          <a:off x="2473890" y="1752944" />
          <a:ext cx="4574610" cy="1310640" />
        </a:xfrm>
        <a:prstGeom prst="rect" xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
          <a:avLst />
        </a:prstGeom>
      </p:spPr>
      <p:txBody>
        <a:bodyPr vert="horz" wrap="square" lIns="91440" tIns="45720" rIns="91440" bIns="45720" rtlCol="0" anchor="t" anchorCtr="0" xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
          <a:noAutofit />
        </a:bodyPr>
        <a:lstStyle xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main" />
        <a:p xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
          <a:pPr defTabSz="1188000" />
          <a:r>
            <a:rPr lang="en-CA" sz="1100" dirty="0">
              <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
            </a:rPr>
            <a:t>CAMPAIGN NAME</a:t>
          </a:r>
        </a:p>
        <a:p xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
          <a:pPr defTabSz="1188000" />
          <a:endParaRPr lang="en-CA" sz="1100" dirty="0">
            <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
          </a:endParaRPr>
        </a:p>
        <a:p xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
          <a:pPr defTabSz="1188000" />
          <a:r>
            <a:rPr lang="en-CA" sz="1100" dirty="0">
              <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
            </a:rPr>
            <a:t>ADVERTISER</a:t>
          </a:r>
        </a:p>
        <a:p xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
          <a:pPr defTabSz="1188000" />
          <a:r>
            <a:rPr lang="en-CA" sz="1100" dirty="0">
              <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
            </a:rPr>
            <a:t>ORDERID</a:t>
          </a:r>
        </a:p>
        <a:p xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
          <a:pPr defTabSz="1188000" />
          <a:r>
            <a:rPr lang="en-CA" sz="1100" dirty="0">
              <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
            </a:rPr>
            <a:t>ORDERDATES</a:t>
          </a:r>
        </a:p>
        <a:p xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
          <a:pPr defTabSz="1188000" />
          <a:r>
            <a:rPr lang="en-CA" sz="1100" dirty="0">
              <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
            </a:rPr>
            <a:t>REPORTDATES</a:t>
          </a:r>
        </a:p>
      </p:txBody>
    </p:sp>      
        `.trim();
    const doc = new DOMParser().parseFromString(xml, "application/xml");
    const result = this.content.importNode(doc.documentElement, true) as Node;
    parent.appendChild(result);
    if (parent.ownerDocument) {
      // Now add our data - if we do it inline with the XML we risk ampersands etc
      // breaking the markup ASK ME HOW I KNOW THIS
      const nameXpath =
        "/p:sld/p:cSld/p:spTree/p:sp/p:txBody/a:p/a:r/a:t[text()='CAMPAIGN NAME']";
      const orderName = parent.ownerDocument.evaluate(
        nameXpath,
        result,
        this.resolver,
        XPathResult.FIRST_ORDERED_NODE_TYPE,
        null
      ).singleNodeValue;
      if (orderName) {
        orderName.textContent = data.name;
      }
      const advertiserXpath =
        "/p:sld/p:cSld/p:spTree/p:sp/p:txBody/a:p/a:r/a:t[text()='ADVERTISER']";
      const advertiser = parent.ownerDocument.evaluate(
        advertiserXpath,
        result,
        this.resolver,
        XPathResult.FIRST_ORDERED_NODE_TYPE,
        null
      ).singleNodeValue;
      if (advertiser) {
        advertiser.textContent = "Advertiser:\t" + data.advertiser;
      }
      const idXpath =
        "/p:sld/p:cSld/p:spTree/p:sp/p:txBody/a:p/a:r/a:t[text()='ORDERID']";
      const id = parent.ownerDocument.evaluate(
        idXpath,
        result,
        this.resolver,
        XPathResult.FIRST_ORDERED_NODE_TYPE,
        null
      ).singleNodeValue;
      if (id) {
        id.textContent = "IO #:\t" + data.id;
      }
      const orderDatesXpath =
        "/p:sld/p:cSld/p:spTree/p:sp/p:txBody/a:p/a:r/a:t[text()='ORDERDATES']";
      const orderDates = parent.ownerDocument.evaluate(
        orderDatesXpath,
        result,
        this.resolver,
        XPathResult.FIRST_ORDERED_NODE_TYPE,
        null
      ).singleNodeValue;
      if (orderDates) {
        orderDates.textContent =
          "Flight Dates:\t" + data.orderStartDate + " - " + data.orderEndDate;
      }
      const reportDatesXpath =
        "/p:sld/p:cSld/p:spTree/p:sp/p:txBody/a:p/a:r/a:t[text()='REPORTDATES']";
      const reportDates = parent.ownerDocument.evaluate(
        reportDatesXpath,
        result,
        this.resolver,
        XPathResult.FIRST_ORDERED_NODE_TYPE,
        null
      ).singleNodeValue;
      if (reportDates) {
        reportDates.textContent =
          "Reporting Dates:\t" +
          data.reportStartDate +
          " - " +
          data.reportEndDate;
      }
    }
  }

  createDeliveredImpressionsNode(data: ToplineDomoOrderRecord) {
    const total = data.lines
      .map((x) => x.deliveredImpressions)
      .reduce((accumulator, currentValue) => accumulator + currentValue);
    const xml =
      `
        <p:sp xmlns:p="http://schemas.openxmlformats.org/presentationml/2006/main">
        <p:nvSpPr>
          <p:cNvPr id="6" name="TextBox 5">
            <a:extLst xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
              <a:ext uri="{FF2B5EF4-FFF2-40B4-BE49-F238E27FC236}">
                <a16:creationId xmlns:a16="http://schemas.microsoft.com/office/drawing/2014/main" id="{47FA5B2A-3641-43FE-AAA9-1D895C2B7140}" />
              </a:ext>
            </a:extLst>
          </p:cNvPr>
          <p:cNvSpPr txBox="1" />
          <p:nvPr />
        </p:nvSpPr>
        <p:spPr>
          <a:xfrm xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
            <a:off x="6801326" y="1823563" />
            <a:ext cx="1752969" cy="440149" />
          </a:xfrm>
          <a:prstGeom prst="rect" xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
            <a:avLst />
          </a:prstGeom>
        </p:spPr>
        <p:txBody>
          <a:bodyPr vert="horz" wrap="square" lIns="91440" tIns="45720" rIns="91440" bIns="45720" rtlCol="0" anchor="t" anchorCtr="0" xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
            <a:noAutofit />
          </a:bodyPr>
          <a:lstStyle xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main" />
          <a:p xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
            <a:pPr algn="ctr">
              <a:lnSpc>
                <a:spcPct val="80000" />
              </a:lnSpc>
            </a:pPr>
            <a:r>
              <a:rPr lang="en-CA" sz="1050" dirty="0">
                <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
              </a:rPr>
              <a:t>Delivered Impressions</a:t>
            </a:r>
          </a:p>
          <a:p xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
            <a:pPr algn="ctr">
              <a:lnSpc>
                <a:spcPct val="80000" />
              </a:lnSpc>
            </a:pPr>
            <a:endParaRPr lang="en-CA" sz="1050" dirty="0">
              <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
            </a:endParaRPr>
          </a:p>
          <a:p xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
            <a:pPr algn="ctr">
              <a:lnSpc>
                <a:spcPct val="60000" />
              </a:lnSpc>
            </a:pPr>
            <a:r>
              <a:rPr lang="en-CA" sz="2400" b="1" dirty="0">
                <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
              </a:rPr>
              <a:t>` +
      Conversions.numberWithCommas(total, 0) +
      `</a:t>
            </a:r>
          </a:p>
        </p:txBody>
      </p:sp>        
        `.trim();
    const doc = new DOMParser().parseFromString(xml, "application/xml");
    return this.content.importNode(doc.documentElement, true);
  }

  createContractedImpressionsNode(data: ToplineDomoOrderRecord) {
    const total = data.lines
      .map((x) => x.quantity)
      .reduce((accumulator, currentValue) => accumulator + currentValue);
    const xml =
      `
        <p:sp xmlns:p="http://schemas.openxmlformats.org/presentationml/2006/main">
        <p:nvSpPr>
          <p:cNvPr id="11" name="TextBox 10">
            <a:extLst xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
              <a:ext uri="{FF2B5EF4-FFF2-40B4-BE49-F238E27FC236}">
                <a16:creationId xmlns:a16="http://schemas.microsoft.com/office/drawing/2014/main" id="{F7067AA8-01CB-451F-B848-F63480AD7197}" />
              </a:ext>
            </a:extLst>
          </p:cNvPr>
          <p:cNvSpPr txBox="1" />
          <p:nvPr />
        </p:nvSpPr>
        <p:spPr>
          <a:xfrm xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
            <a:off x="8502367" y="1826563" />
            <a:ext cx="1743606" cy="733024" />
          </a:xfrm>
          <a:prstGeom prst="rect" xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
            <a:avLst />
          </a:prstGeom>
        </p:spPr>
        <p:txBody>
          <a:bodyPr vert="horz" wrap="square" lIns="91440" tIns="45720" rIns="91440" bIns="45720" rtlCol="0" anchor="t" anchorCtr="0" xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
            <a:noAutofit />
          </a:bodyPr>
          <a:lstStyle xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main" />
          <a:p xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
            <a:pPr algn="ctr">
              <a:lnSpc>
                <a:spcPct val="60000" />
              </a:lnSpc>
            </a:pPr>
            <a:r>
              <a:rPr lang="en-CA" sz="1050" dirty="0">
                <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
              </a:rPr>
              <a:t>Contracted Impressions</a:t>
            </a:r>
          </a:p>
          <a:p xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
            <a:pPr algn="ctr">
              <a:lnSpc>
                <a:spcPct val="60000" />
              </a:lnSpc>
            </a:pPr>
            <a:endParaRPr lang="en-CA" sz="1050" dirty="0">
              <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
            </a:endParaRPr>
          </a:p>
          <a:p xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
            <a:pPr algn="ctr">
              <a:lnSpc>
                <a:spcPct val="60000" />
              </a:lnSpc>
            </a:pPr>
            <a:r>
              <a:rPr lang="en-CA" sz="2400" b="1" dirty="0">
                <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
              </a:rPr>
              <a:t>` +
      Conversions.numberWithCommas(total, 0) +
      `</a:t>
            </a:r>
            <a:br>
              <a:rPr lang="en-CA" sz="2400" b="1" dirty="0">
                <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
              </a:rPr>
            </a:br>
            <a:r>
              <a:rPr lang="en-CA" sz="1000" dirty="0">
                <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
              </a:rPr>
              <a:t>(CPM Basis)</a:t>
            </a:r>
          </a:p>
        </p:txBody>
      </p:sp>        
        `.trim();
    const doc = new DOMParser().parseFromString(xml, "application/xml");
    return this.content.importNode(doc.documentElement, true);
  }

  createClicksNode(data: ToplineDomoOrderRecord) {
    const total = data.lines
      .map((x) => x.clicks)
      .reduce((accumulator, currentValue) => accumulator + currentValue);
    const xml =
      `
        <p:sp xmlns:p="http://schemas.openxmlformats.org/presentationml/2006/main">
        <p:nvSpPr>
          <p:cNvPr id="12" name="TextBox 11">
            <a:extLst xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
              <a:ext uri="{FF2B5EF4-FFF2-40B4-BE49-F238E27FC236}">
                <a16:creationId xmlns:a16="http://schemas.microsoft.com/office/drawing/2014/main" id="{FBB36281-AB45-44B5-9EE9-DE083C173E38}" />
              </a:ext>
            </a:extLst>
          </p:cNvPr>
          <p:cNvSpPr txBox="1" />
          <p:nvPr />
        </p:nvSpPr>
        <p:spPr>
          <a:xfrm xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
            <a:off x="10150154" y="1826563" />
            <a:ext cx="1175071" cy="733024" />
          </a:xfrm>
          <a:prstGeom prst="rect" xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
            <a:avLst />
          </a:prstGeom>
        </p:spPr>
        <p:txBody>
          <a:bodyPr vert="horz" wrap="square" lIns="91440" tIns="45720" rIns="91440" bIns="45720" rtlCol="0" anchor="t" anchorCtr="0" xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
            <a:noAutofit />
          </a:bodyPr>
          <a:lstStyle xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main" />
          <a:p xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
            <a:pPr algn="ctr">
              <a:lnSpc>
                <a:spcPct val="60000" />
              </a:lnSpc>
            </a:pPr>
            <a:r>
              <a:rPr lang="en-CA" sz="1050" dirty="0">
                <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
              </a:rPr>
              <a:t>Clicks</a:t>
            </a:r>
          </a:p>
          <a:p xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
            <a:pPr algn="ctr">
              <a:lnSpc>
                <a:spcPct val="60000" />
              </a:lnSpc>
            </a:pPr>
            <a:endParaRPr lang="en-CA" sz="1050" dirty="0">
              <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
            </a:endParaRPr>
          </a:p>
          <a:p xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
            <a:pPr algn="ctr">
              <a:lnSpc>
                <a:spcPct val="60000" />
              </a:lnSpc>
            </a:pPr>
            <a:r>
              <a:rPr lang="en-CA" sz="2400" b="1" dirty="0">
                <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
              </a:rPr>
              <a:t>` +
      Conversions.numberWithCommas(total, 0) +
      `</a:t>
            </a:r>
          </a:p>
        </p:txBody>
      </p:sp>        
        `.trim();
    const doc = new DOMParser().parseFromString(xml, "application/xml");
    return this.content.importNode(doc.documentElement, true);
  }

  createActualSpendNode(data: ToplineDomoOrderRecord) {
    const total = data.lines
      .map((x) => x.actualSpend)
      .reduce((accumulator, currentValue) => accumulator + currentValue);
    const xml =
      `
        <p:sp xmlns:p="http://schemas.openxmlformats.org/presentationml/2006/main">
        <p:nvSpPr>
          <p:cNvPr id="13" name="TextBox 12">
            <a:extLst xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
              <a:ext uri="{FF2B5EF4-FFF2-40B4-BE49-F238E27FC236}">
                <a16:creationId xmlns:a16="http://schemas.microsoft.com/office/drawing/2014/main" id="{3A8235C5-932F-417D-BF7F-27E50E77E625}" />
              </a:ext>
            </a:extLst>
          </p:cNvPr>
          <p:cNvSpPr txBox="1" />
          <p:nvPr />
        </p:nvSpPr>
        <p:spPr>
          <a:xfrm xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
            <a:off x="6801326" y="2440926" />
            <a:ext cx="1743607" cy="440149" />
          </a:xfrm>
          <a:prstGeom prst="rect" xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
            <a:avLst />
          </a:prstGeom>
        </p:spPr>
        <p:txBody>
          <a:bodyPr vert="horz" wrap="square" lIns="91440" tIns="45720" rIns="91440" bIns="45720" rtlCol="0" anchor="t" anchorCtr="0" xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
            <a:noAutofit />
          </a:bodyPr>
          <a:lstStyle xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main" />
          <a:p xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
            <a:pPr algn="ctr">
              <a:lnSpc>
                <a:spcPct val="60000" />
              </a:lnSpc>
            </a:pPr>
            <a:r>
              <a:rPr lang="en-CA" sz="1050" dirty="0">
                <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
              </a:rPr>
              <a:t>Actual Spend</a:t>
            </a:r>
          </a:p>
          <a:p xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
            <a:pPr algn="ctr">
              <a:lnSpc>
                <a:spcPct val="60000" />
              </a:lnSpc>
            </a:pPr>
            <a:endParaRPr lang="en-CA" sz="1050" dirty="0">
              <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
            </a:endParaRPr>
          </a:p>
          <a:p xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
            <a:pPr algn="ctr">
              <a:lnSpc>
                <a:spcPct val="60000" />
              </a:lnSpc>
            </a:pPr>
            <a:r>
              <a:rPr lang="en-CA" sz="2400" b="1" dirty="0">
                <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
              </a:rPr>
              <a:t>$` +
      Conversions.numberWithCommas(total, 0) +
      `</a:t>
            </a:r>
          </a:p>
        </p:txBody>
      </p:sp>        
        `.trim();
    const doc = new DOMParser().parseFromString(xml, "application/xml");
    return this.content.importNode(doc.documentElement, true);
  }

  createContractedSpendNode(data: ToplineDomoOrderRecord) {
    const total = data.lines
      .map((x) => x.contractedSpend)
      .reduce((accumulator, currentValue) => accumulator + currentValue);
    const xml =
      `
        <p:sp xmlns:p="http://schemas.openxmlformats.org/presentationml/2006/main">
        <p:nvSpPr>
          <p:cNvPr id="14" name="TextBox 13">
            <a:extLst xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
              <a:ext uri="{FF2B5EF4-FFF2-40B4-BE49-F238E27FC236}">
                <a16:creationId xmlns:a16="http://schemas.microsoft.com/office/drawing/2014/main" id="{6BC83FBB-4BFC-4C0F-BC55-D62BE580ADFF}" />
              </a:ext>
            </a:extLst>
          </p:cNvPr>
          <p:cNvSpPr txBox="1" />
          <p:nvPr />
        </p:nvSpPr>
        <p:spPr>
          <a:xfrm xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
            <a:off x="8502367" y="2440926" />
            <a:ext cx="1743606" cy="440149" />
          </a:xfrm>
          <a:prstGeom prst="rect" xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
            <a:avLst />
          </a:prstGeom>
        </p:spPr>
        <p:txBody>
          <a:bodyPr vert="horz" wrap="square" lIns="91440" tIns="45720" rIns="91440" bIns="45720" rtlCol="0" anchor="t" anchorCtr="0" xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
            <a:noAutofit />
          </a:bodyPr>
          <a:lstStyle xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main" />
          <a:p xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
            <a:pPr algn="ctr">
              <a:lnSpc>
                <a:spcPct val="60000" />
              </a:lnSpc>
            </a:pPr>
            <a:r>
              <a:rPr lang="en-CA" sz="1050" dirty="0">
                <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
              </a:rPr>
              <a:t>Contracted Spend</a:t>
            </a:r>
          </a:p>
          <a:p xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
            <a:pPr algn="ctr">
              <a:lnSpc>
                <a:spcPct val="60000" />
              </a:lnSpc>
            </a:pPr>
            <a:endParaRPr lang="en-CA" sz="1050" dirty="0">
              <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
            </a:endParaRPr>
          </a:p>
          <a:p xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
            <a:pPr algn="ctr">
              <a:lnSpc>
                <a:spcPct val="60000" />
              </a:lnSpc>
            </a:pPr>
            <a:r>
              <a:rPr lang="en-CA" sz="2400" b="1" dirty="0">
                <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
              </a:rPr>
              <a:t>$` +
      Conversions.numberWithCommas(total, 0) +
      `</a:t>
            </a:r>
          </a:p>
        </p:txBody>
      </p:sp>        
        `.trim();
    const doc = new DOMParser().parseFromString(xml, "application/xml");
    return this.content.importNode(doc.documentElement, true);
  }

  createConversionsNode(data: ToplineDomoOrderRecord) {
    const total = data.lines
      .map((x) => x.conversions)
      .reduce((accumulator, currentValue) => accumulator + currentValue);
    const xml =
      `
        <p:sp xmlns:p="http://schemas.openxmlformats.org/presentationml/2006/main">
        <p:nvSpPr>
          <p:cNvPr id="15" name="TextBox 14">
            <a:extLst xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
              <a:ext uri="{FF2B5EF4-FFF2-40B4-BE49-F238E27FC236}">
                <a16:creationId xmlns:a16="http://schemas.microsoft.com/office/drawing/2014/main" id="{DB078727-D241-44D8-8BDC-32F5D1B9B6E2}" />
              </a:ext>
            </a:extLst>
          </p:cNvPr>
          <p:cNvSpPr txBox="1" />
          <p:nvPr />
        </p:nvSpPr>
        <p:spPr>
          <a:xfrm xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
            <a:off x="10159678" y="2440926" />
            <a:ext cx="1175071" cy="440149" />
          </a:xfrm>
          <a:prstGeom prst="rect" xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
            <a:avLst />
          </a:prstGeom>
        </p:spPr>
        <p:txBody>
          <a:bodyPr vert="horz" wrap="square" lIns="91440" tIns="45720" rIns="91440" bIns="45720" rtlCol="0" anchor="t" anchorCtr="0" xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
            <a:noAutofit />
          </a:bodyPr>
          <a:lstStyle xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main" />
          <a:p xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
            <a:pPr algn="ctr">
              <a:lnSpc>
                <a:spcPct val="60000" />
              </a:lnSpc>
            </a:pPr>
            <a:r>
              <a:rPr lang="en-CA" sz="1050" dirty="0">
                <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
              </a:rPr>
              <a:t>Conversions</a:t>
            </a:r>
          </a:p>
          <a:p xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
            <a:pPr algn="ctr">
              <a:lnSpc>
                <a:spcPct val="60000" />
              </a:lnSpc>
            </a:pPr>
            <a:endParaRPr lang="en-CA" sz="1050" dirty="0">
              <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
            </a:endParaRPr>
          </a:p>
          <a:p xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
            <a:pPr algn="ctr">
              <a:lnSpc>
                <a:spcPct val="60000" />
              </a:lnSpc>
            </a:pPr>
            <a:r>
              <a:rPr lang="en-CA" sz="2400" b="1" dirty="0">
                <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
              </a:rPr>
              <a:t>` +
      Conversions.numberWithCommas(total, 0) +
      `</a:t>
            </a:r>
          </a:p>
        </p:txBody>
      </p:sp>        
        `.trim();
    const doc = new DOMParser().parseFromString(xml, "application/xml");
    return this.content.importNode(doc.documentElement, true);
  }

  generateLineItemTemplateForToplineSumm(): Node {
    const xml = `
      <a:tr xmlns:p="http://schemas.openxmlformats.org/presentationml/2006/main" xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main" h="0">
      <a:tc>
        <a:txBody>
          <a:bodyPr />
          <a:lstStyle />
          <a:p>
            <a:r>
              <a:rPr lang="en-CA" sz="1050"  b="0" dirty="0">
                <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
              </a:rPr>
              <a:t>TACTICGROUPING</a:t>
            </a:r>
          </a:p>
        </a:txBody>
        <a:tcPr marL="7200" marR="8601" marT="7200" marB="0" anchor="ctr">
          <a:lnL w="9525" cap="flat" cmpd="sng" algn="ctr">
            <a:solidFill>
              <a:schemeClr val="bg2" />
            </a:solidFill>
            <a:prstDash val="solid" />
            <a:round />
            <a:headEnd type="none" w="med" len="med" />
            <a:tailEnd type="none" w="med" len="med" />
          </a:lnL>
          <a:lnR w="9525" cap="flat" cmpd="sng" algn="ctr">
            <a:solidFill>
              <a:schemeClr val="bg2" />
            </a:solidFill>
            <a:prstDash val="solid" />
            <a:round />
            <a:headEnd type="none" w="med" len="med" />
            <a:tailEnd type="none" w="med" len="med" />
          </a:lnR>
          <a:lnT w="9525" cap="flat" cmpd="sng" algn="ctr">
            <a:solidFill>
              <a:schemeClr val="bg2" />
            </a:solidFill>
            <a:prstDash val="solid" />
            <a:round />
            <a:headEnd type="none" w="med" len="med" />
            <a:tailEnd type="none" w="med" len="med" />
          </a:lnT>
          <a:lnB w="9525" cap="flat" cmpd="sng" algn="ctr">
            <a:solidFill>
              <a:schemeClr val="bg2" />
            </a:solidFill>
            <a:prstDash val="solid" />
            <a:round />
            <a:headEnd type="none" w="med" len="med" />
            <a:tailEnd type="none" w="med" len="med" />
          </a:lnB>
          <a:lnTlToBr w="12700" cmpd="sng">
            <a:noFill />
            <a:prstDash val="solid" />
          </a:lnTlToBr>
          <a:lnBlToTr w="12700" cmpd="sng">
            <a:noFill />
            <a:prstDash val="solid" />
          </a:lnBlToTr>
          <a:noFill />
        </a:tcPr>
      </a:tc>
      <a:tc>
        <a:txBody>
          <a:bodyPr />
          <a:lstStyle />
          <a:p>
            <a:r>
              <a:rPr lang="en-CA" sz="1050"  b="0" dirty="0">
                <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
              </a:rPr>
              <a:t>COSTTYPE</a:t>
            </a:r>
          </a:p>
        </a:txBody>
        <a:tcPr marL="7200" marR="8601" marT="7200" marB="0" anchor="ctr">
          <a:lnL w="9525" cap="flat" cmpd="sng" algn="ctr">
            <a:solidFill>
              <a:schemeClr val="bg2" />
            </a:solidFill>
            <a:prstDash val="solid" />
            <a:round />
            <a:headEnd type="none" w="med" len="med" />
            <a:tailEnd type="none" w="med" len="med" />
          </a:lnL>
          <a:lnR w="9525" cap="flat" cmpd="sng" algn="ctr">
            <a:solidFill>
              <a:schemeClr val="bg2" />
            </a:solidFill>
            <a:prstDash val="solid" />
            <a:round />
            <a:headEnd type="none" w="med" len="med" />
            <a:tailEnd type="none" w="med" len="med" />
          </a:lnR>
          <a:lnT w="9525" cap="flat" cmpd="sng" algn="ctr">
            <a:solidFill>
              <a:schemeClr val="bg2" />
            </a:solidFill>
            <a:prstDash val="solid" />
            <a:round />
            <a:headEnd type="none" w="med" len="med" />
            <a:tailEnd type="none" w="med" len="med" />
          </a:lnT>
          <a:lnB w="9525" cap="flat" cmpd="sng" algn="ctr">
            <a:solidFill>
              <a:schemeClr val="bg2" />
            </a:solidFill>
            <a:prstDash val="solid" />
            <a:round />
            <a:headEnd type="none" w="med" len="med" />
            <a:tailEnd type="none" w="med" len="med" />
          </a:lnB>
          <a:lnTlToBr w="12700" cmpd="sng">
            <a:noFill />
            <a:prstDash val="solid" />
          </a:lnTlToBr>
          <a:lnBlToTr w="12700" cmpd="sng">
            <a:noFill />
            <a:prstDash val="solid" />
          </a:lnBlToTr>
          <a:noFill />
        </a:tcPr>
      </a:tc>
      <a:tc>
        <a:txBody>
          <a:bodyPr />
          <a:lstStyle />
          <a:p>
          <a:pPr algn="r"/>
            <a:r>
              <a:rPr lang="en-CA" sz="1050" b="0" dirty="0">
                <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
              </a:rPr>
              <a:t>CONTRACTEDQUANTITY</a:t>
            </a:r>
          </a:p>
        </a:txBody>
        <a:tcPr marL="7200" marR="8601" marT="7200" marB="0" anchor="ctr">
          <a:lnL w="9525" cap="flat" cmpd="sng" algn="ctr">
            <a:solidFill>
              <a:schemeClr val="bg2" />
            </a:solidFill>
            <a:prstDash val="solid" />
            <a:round />
            <a:headEnd type="none" w="med" len="med" />
            <a:tailEnd type="none" w="med" len="med" />
          </a:lnL>
          <a:lnR w="9525" cap="flat" cmpd="sng" algn="ctr">
            <a:solidFill>
              <a:schemeClr val="bg2" />
            </a:solidFill>
            <a:prstDash val="solid" />
            <a:round />
            <a:headEnd type="none" w="med" len="med" />
            <a:tailEnd type="none" w="med" len="med" />
          </a:lnR>
          <a:lnT w="9525" cap="flat" cmpd="sng" algn="ctr">
            <a:solidFill>
              <a:schemeClr val="bg2" />
            </a:solidFill>
            <a:prstDash val="solid" />
            <a:round />
            <a:headEnd type="none" w="med" len="med" />
            <a:tailEnd type="none" w="med" len="med" />
          </a:lnT>
          <a:lnB w="9525" cap="flat" cmpd="sng" algn="ctr">
            <a:solidFill>
              <a:schemeClr val="bg2" />
            </a:solidFill>
            <a:prstDash val="solid" />
            <a:round />
            <a:headEnd type="none" w="med" len="med" />
            <a:tailEnd type="none" w="med" len="med" />
          </a:lnB>
          <a:lnTlToBr w="12700" cmpd="sng">
            <a:noFill />
            <a:prstDash val="solid" />
          </a:lnTlToBr>
          <a:lnBlToTr w="12700" cmpd="sng">
            <a:noFill />
            <a:prstDash val="solid" />
          </a:lnBlToTr>
          <a:noFill />
        </a:tcPr>
      </a:tc>
      <a:tc>
        <a:txBody>
          <a:bodyPr />
          <a:lstStyle />
          <a:p>
            <a:pPr algn="r"/>
            <a:r>
              <a:rPr lang="en-CA" sz="1050" b="0" dirty="0">
                <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
              </a:rPr>
              <a:t>DELIVEREDIMPRESSION</a:t>
            </a:r>
          </a:p>
        </a:txBody>
        <a:tcPr marL="7200" marR="8601" marT="7200" marB="0" anchor="ctr">
          <a:lnL w="9525" cap="flat" cmpd="sng" algn="ctr">
            <a:solidFill>
              <a:schemeClr val="bg2" />
            </a:solidFill>
            <a:prstDash val="solid" />
            <a:round />
            <a:headEnd type="none" w="med" len="med" />
            <a:tailEnd type="none" w="med" len="med" />
          </a:lnL>
          <a:lnR w="9525" cap="flat" cmpd="sng" algn="ctr">
            <a:solidFill>
              <a:schemeClr val="bg2" />
            </a:solidFill>
            <a:prstDash val="solid" />
            <a:round />
            <a:headEnd type="none" w="med" len="med" />
            <a:tailEnd type="none" w="med" len="med" />
          </a:lnR>
          <a:lnT w="9525" cap="flat" cmpd="sng" algn="ctr">
            <a:solidFill>
              <a:schemeClr val="bg2" />
            </a:solidFill>
            <a:prstDash val="solid" />
            <a:round />
            <a:headEnd type="none" w="med" len="med" />
            <a:tailEnd type="none" w="med" len="med" />
          </a:lnT>
          <a:lnB w="9525" cap="flat" cmpd="sng" algn="ctr">
            <a:solidFill>
              <a:schemeClr val="bg2" />
            </a:solidFill>
            <a:prstDash val="solid" />
            <a:round />
            <a:headEnd type="none" w="med" len="med" />
            <a:tailEnd type="none" w="med" len="med" />
          </a:lnB>
          <a:lnTlToBr w="12700" cmpd="sng">
            <a:noFill />
            <a:prstDash val="solid" />
          </a:lnTlToBr>
          <a:lnBlToTr w="12700" cmpd="sng">
            <a:noFill />
            <a:prstDash val="solid" />
          </a:lnBlToTr>
          <a:noFill />
        </a:tcPr>
      </a:tc>
      <a:tc>
        <a:txBody>
          <a:bodyPr />
          <a:lstStyle />
          <a:p>
            <a:pPr algn="ctr"/>
            <a:r>
              <a:rPr lang="en-CA" sz="1050" b="0" dirty="0">
                <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
              </a:rPr>
              <a:t>DELIVEREDINDEX</a:t>
            </a:r>
          </a:p>
        </a:txBody>
        <a:tcPr marL="7200" marR="8601" marT="7200" marB="0" anchor="ctr">
          <a:lnL w="9525" cap="flat" cmpd="sng" algn="ctr">
            <a:solidFill>
              <a:schemeClr val="bg2" />
            </a:solidFill>
            <a:prstDash val="solid" />
            <a:round />
            <a:headEnd type="none" w="med" len="med" />
            <a:tailEnd type="none" w="med" len="med" />
          </a:lnL>
          <a:lnR w="9525" cap="flat" cmpd="sng" algn="ctr">
            <a:solidFill>
              <a:schemeClr val="bg2" />
            </a:solidFill>
            <a:prstDash val="solid" />
            <a:round />
            <a:headEnd type="none" w="med" len="med" />
            <a:tailEnd type="none" w="med" len="med" />
          </a:lnR>
          <a:lnT w="9525" cap="flat" cmpd="sng" algn="ctr">
            <a:solidFill>
              <a:schemeClr val="bg2" />
            </a:solidFill>
            <a:prstDash val="solid" />
            <a:round />
            <a:headEnd type="none" w="med" len="med" />
            <a:tailEnd type="none" w="med" len="med" />
          </a:lnT>
          <a:lnB w="9525" cap="flat" cmpd="sng" algn="ctr">
            <a:solidFill>
              <a:schemeClr val="bg2" />
            </a:solidFill>
            <a:prstDash val="solid" />
            <a:round />
            <a:headEnd type="none" w="med" len="med" />
            <a:tailEnd type="none" w="med" len="med" />
          </a:lnB>
          <a:lnTlToBr w="12700" cmpd="sng">
            <a:noFill />
            <a:prstDash val="solid" />
          </a:lnTlToBr>
          <a:lnBlToTr w="12700" cmpd="sng">
            <a:noFill />
            <a:prstDash val="solid" />
          </a:lnBlToTr>
          <a:noFill />
        </a:tcPr>
      </a:tc>
      <a:tc>
        <a:txBody>
          <a:bodyPr />
          <a:lstStyle />
          <a:p>
            <a:pPr algn="r"/>
            <a:r>
              <a:rPr lang="en-CA" sz="1050" b="0" dirty="0">
                <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
              </a:rPr>
              <a:t>CLICKS</a:t>
            </a:r>
          </a:p>
        </a:txBody>
        <a:tcPr  marL="7200" marR="8601" marT="7200" marB="0" anchor="ctr">
          <a:lnL w="9525" cap="flat" cmpd="sng" algn="ctr">
            <a:solidFill>
              <a:schemeClr val="bg2" />
            </a:solidFill>
            <a:prstDash val="solid" />
            <a:round />
            <a:headEnd type="none" w="med" len="med" />
            <a:tailEnd type="none" w="med" len="med" />
          </a:lnL>
          <a:lnR w="9525" cap="flat" cmpd="sng" algn="ctr">
            <a:solidFill>
              <a:schemeClr val="bg2" />
            </a:solidFill>
            <a:prstDash val="solid" />
            <a:round />
            <a:headEnd type="none" w="med" len="med" />
            <a:tailEnd type="none" w="med" len="med" />
          </a:lnR>
          <a:lnT w="9525" cap="flat" cmpd="sng" algn="ctr">
            <a:solidFill>
              <a:schemeClr val="bg2" />
            </a:solidFill>
            <a:prstDash val="solid" />
            <a:round />
            <a:headEnd type="none" w="med" len="med" />
            <a:tailEnd type="none" w="med" len="med" />
          </a:lnT>
          <a:lnB w="9525" cap="flat" cmpd="sng" algn="ctr">
            <a:solidFill>
              <a:schemeClr val="bg2" />
            </a:solidFill>
            <a:prstDash val="solid" />
            <a:round />
            <a:headEnd type="none" w="med" len="med" />
            <a:tailEnd type="none" w="med" len="med" />
          </a:lnB>
          <a:lnTlToBr w="12700" cmpd="sng">
            <a:noFill />
            <a:prstDash val="solid" />
          </a:lnTlToBr>
          <a:lnBlToTr w="12700" cmpd="sng">
            <a:noFill />
            <a:prstDash val="solid" />
          </a:lnBlToTr>
          <a:noFill />
        </a:tcPr>
      </a:tc>
      <a:tc>
        <a:txBody>
          <a:bodyPr />
          <a:lstStyle />
          <a:p>
            <a:pPr algn="r"/>
            <a:r>
              <a:rPr lang="en-CA" sz="1050" b="0" dirty="0">
                <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
              </a:rPr>
              <a:t>CONTRATIO</a:t>
            </a:r>
          </a:p>
        </a:txBody>
        <a:tcPr marL="7200" marR="8601" marT="7200" marB="0" anchor="ctr">
          <a:lnL w="9525" cap="flat" cmpd="sng" algn="ctr">
            <a:solidFill>
              <a:schemeClr val="bg2" />
            </a:solidFill>
            <a:prstDash val="solid" />
            <a:round />
            <a:headEnd type="none" w="med" len="med" />
            <a:tailEnd type="none" w="med" len="med" />
          </a:lnL>
          <a:lnR w="9525" cap="flat" cmpd="sng" algn="ctr">
            <a:solidFill>
              <a:schemeClr val="bg2" />
            </a:solidFill>
            <a:prstDash val="solid" />
            <a:round />
            <a:headEnd type="none" w="med" len="med" />
            <a:tailEnd type="none" w="med" len="med" />
          </a:lnR>
          <a:lnT w="9525" cap="flat" cmpd="sng" algn="ctr">
            <a:solidFill>
              <a:schemeClr val="bg2" />
            </a:solidFill>
            <a:prstDash val="solid" />
            <a:round />
            <a:headEnd type="none" w="med" len="med" />
            <a:tailEnd type="none" w="med" len="med" />
          </a:lnT>
          <a:lnB w="9525" cap="flat" cmpd="sng" algn="ctr">
            <a:solidFill>
              <a:schemeClr val="bg2" />
            </a:solidFill>
            <a:prstDash val="solid" />
            <a:round />
            <a:headEnd type="none" w="med" len="med" />
            <a:tailEnd type="none" w="med" len="med" />
          </a:lnB>
          <a:lnTlToBr w="12700" cmpd="sng">
            <a:noFill />
            <a:prstDash val="solid" />
          </a:lnTlToBr>
          <a:lnBlToTr w="12700" cmpd="sng">
            <a:noFill />
            <a:prstDash val="solid" />
          </a:lnBlToTr>
          <a:noFill />
        </a:tcPr>
      </a:tc>
      <a:tc>
        <a:txBody>
          <a:bodyPr />
          <a:lstStyle />
          <a:p>
            <a:pPr algn="r"/>
            <a:r>
              <a:rPr lang="en-CA" sz="1050" b="0" dirty="0">
                <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
              </a:rPr>
              <a:t>STARTS</a:t>
            </a:r>
          </a:p>
        </a:txBody>
        <a:tcPr marL="7200" marR="8601" marT="7200" marB="0" anchor="ctr">
          <a:lnL w="9525" cap="flat" cmpd="sng" algn="ctr">
            <a:solidFill>
              <a:schemeClr val="bg2" />
            </a:solidFill>
            <a:prstDash val="solid" />
            <a:round />
            <a:headEnd type="none" w="med" len="med" />
            <a:tailEnd type="none" w="med" len="med" />
          </a:lnL>
          <a:lnR w="9525" cap="flat" cmpd="sng" algn="ctr">
            <a:solidFill>
              <a:schemeClr val="bg2" />
            </a:solidFill>
            <a:prstDash val="solid" />
            <a:round />
            <a:headEnd type="none" w="med" len="med" />
            <a:tailEnd type="none" w="med" len="med" />
          </a:lnR>
          <a:lnT w="9525" cap="flat" cmpd="sng" algn="ctr">
            <a:solidFill>
              <a:schemeClr val="bg2" />
            </a:solidFill>
            <a:prstDash val="solid" />
            <a:round />
            <a:headEnd type="none" w="med" len="med" />
            <a:tailEnd type="none" w="med" len="med" />
          </a:lnT>
          <a:lnB w="9525" cap="flat" cmpd="sng" algn="ctr">
            <a:solidFill>
              <a:schemeClr val="bg2" />
            </a:solidFill>
            <a:prstDash val="solid" />
            <a:round />
            <a:headEnd type="none" w="med" len="med" />
            <a:tailEnd type="none" w="med" len="med" />
          </a:lnB>
          <a:lnTlToBr w="12700" cmpd="sng">
            <a:noFill />
            <a:prstDash val="solid" />
          </a:lnTlToBr>
          <a:lnBlToTr w="12700" cmpd="sng">
            <a:noFill />
            <a:prstDash val="solid" />
          </a:lnBlToTr>
          <a:noFill />
        </a:tcPr>
      </a:tc>
      <a:tc>
        <a:txBody>
          <a:bodyPr />
          <a:lstStyle />
          <a:p>
            <a:pPr algn="r"/>
            <a:r>
              <a:rPr lang="en-CA"  sz="1050" b="0" dirty="0">
                <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
              </a:rPr>
              <a:t>COMPLITION</a:t>
            </a:r>
          </a:p>
        </a:txBody>
        <a:tcPr marL="7200" marR="8601" marT="7200" marB="0" anchor="ctr">
          <a:lnL w="9525" cap="flat" cmpd="sng" algn="ctr">
            <a:solidFill>
              <a:schemeClr val="bg2" />
            </a:solidFill>
            <a:prstDash val="solid" />
            <a:round />
            <a:headEnd type="none" w="med" len="med" />
            <a:tailEnd type="none" w="med" len="med" />
          </a:lnL>
          <a:lnR w="9525" cap="flat" cmpd="sng" algn="ctr">
            <a:solidFill>
              <a:schemeClr val="bg2" />
            </a:solidFill>
            <a:prstDash val="solid" />
            <a:round />
            <a:headEnd type="none" w="med" len="med" />
            <a:tailEnd type="none" w="med" len="med" />
          </a:lnR>
          <a:lnT w="9525" cap="flat" cmpd="sng" algn="ctr">
            <a:solidFill>
              <a:schemeClr val="bg2" />
            </a:solidFill>
            <a:prstDash val="solid" />
            <a:round />
            <a:headEnd type="none" w="med" len="med" />
            <a:tailEnd type="none" w="med" len="med" />
          </a:lnT>
          <a:lnB w="9525" cap="flat" cmpd="sng" algn="ctr">
            <a:solidFill>
              <a:schemeClr val="bg2" />
            </a:solidFill>
            <a:prstDash val="solid" />
            <a:round />
            <a:headEnd type="none" w="med" len="med" />
            <a:tailEnd type="none" w="med" len="med" />
          </a:lnB>
          <a:lnTlToBr w="12700" cmpd="sng">
            <a:noFill />
            <a:prstDash val="solid" />
          </a:lnTlToBr>
          <a:lnBlToTr w="12700" cmpd="sng">
            <a:noFill />
            <a:prstDash val="solid" />
          </a:lnBlToTr>
          <a:noFill />
        </a:tcPr>
      </a:tc>
      <a:tc>
        <a:txBody>
          <a:bodyPr />
          <a:lstStyle />
          <a:p>
              <a:rPr lang="en-CA" sz="1050" b="0" dirty="0">
                <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
              </a:rPr>
              <a:t>COMPLITIONRATE</a:t>
          </a:p>
        </a:txBody>
        <a:tcPr marL="7200" marR="8601" marT="7200" marB="0" anchor="ctr">
          <a:lnL w="9525" cap="flat" cmpd="sng" algn="ctr">
            <a:solidFill>
              <a:schemeClr val="bg2" />
            </a:solidFill>
            <a:prstDash val="solid" />
            <a:round />
            <a:headEnd type="none" w="med" len="med" />
            <a:tailEnd type="none" w="med" len="med" />
          </a:lnL>
          <a:lnR w="9525" cap="flat" cmpd="sng" algn="ctr">
            <a:solidFill>
              <a:schemeClr val="bg2" />
            </a:solidFill>
            <a:prstDash val="solid" />
            <a:round />
            <a:headEnd type="none" w="med" len="med" />
            <a:tailEnd type="none" w="med" len="med" />
          </a:lnR>
          <a:lnT w="9525" cap="flat" cmpd="sng" algn="ctr">
            <a:solidFill>
              <a:schemeClr val="bg2" />
            </a:solidFill>
            <a:prstDash val="solid" />
            <a:round />
            <a:headEnd type="none" w="med" len="med" />
            <a:tailEnd type="none" w="med" len="med" />
          </a:lnT>
          <a:lnB w="9525" cap="flat" cmpd="sng" algn="ctr">
            <a:solidFill>
              <a:schemeClr val="bg2" />
            </a:solidFill>
            <a:prstDash val="solid" />
            <a:round />
            <a:headEnd type="none" w="med" len="med" />
            <a:tailEnd type="none" w="med" len="med" />
          </a:lnB>
          <a:lnTlToBr w="12700" cmpd="sng">
            <a:noFill />
            <a:prstDash val="solid" />
          </a:lnTlToBr>
          <a:lnBlToTr w="12700" cmpd="sng">
            <a:noFill />
            <a:prstDash val="solid" />
          </a:lnBlToTr>
          <a:noFill />
        </a:tcPr>
      </a:tc>
      <a:tc>
        <a:txBody>
          <a:bodyPr />
          <a:lstStyle />
          <a:p>
            <a:pPr algn="r"/>
            <a:r>
              <a:rPr lang="en-CA" sz="1050" b="0" dirty="0">
                <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
              </a:rPr>
              <a:t>ENGAGEMENTS</a:t>
            </a:r>
          </a:p>
        </a:txBody>
        <a:tcPr marL="7200" marR="8601" marT="7200" marB="0" anchor="ctr">
          <a:lnL w="9525" cap="flat" cmpd="sng" algn="ctr">
            <a:solidFill>
              <a:schemeClr val="bg2" />
            </a:solidFill>
            <a:prstDash val="solid" />
            <a:round />
            <a:headEnd type="none" w="med" len="med" />
            <a:tailEnd type="none" w="med" len="med" />
          </a:lnL>
          <a:lnR w="9525" cap="flat" cmpd="sng" algn="ctr">
            <a:solidFill>
              <a:schemeClr val="bg2" />
            </a:solidFill>
            <a:prstDash val="solid" />
            <a:round />
            <a:headEnd type="none" w="med" len="med" />
            <a:tailEnd type="none" w="med" len="med" />
          </a:lnR>
          <a:lnT w="9525" cap="flat" cmpd="sng" algn="ctr">
            <a:solidFill>
              <a:schemeClr val="bg2" />
            </a:solidFill>
            <a:prstDash val="solid" />
            <a:round />
            <a:headEnd type="none" w="med" len="med" />
            <a:tailEnd type="none" w="med" len="med" />
          </a:lnT>
          <a:lnB w="9525" cap="flat" cmpd="sng" algn="ctr">
            <a:solidFill>
              <a:schemeClr val="bg2" />
            </a:solidFill>
            <a:prstDash val="solid" />
            <a:round />
            <a:headEnd type="none" w="med" len="med" />
            <a:tailEnd type="none" w="med" len="med" />
          </a:lnB>
          <a:lnTlToBr w="12700" cmpd="sng">
            <a:noFill />
            <a:prstDash val="solid" />
          </a:lnTlToBr>
          <a:lnBlToTr w="12700" cmpd="sng">
            <a:noFill />
            <a:prstDash val="solid" />
          </a:lnBlToTr>
          <a:noFill />
        </a:tcPr>
      </a:tc>
      <a:tc>
        <a:txBody>
          <a:bodyPr />
          <a:lstStyle />
          <a:p>
            <a:rPr lang="en-CA" sz="1050" b="0" dirty="0">
                <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
              </a:rPr>
            <a:t>ENGAGEMENTRATE</a:t>
          </a:p>
        </a:txBody>
        <a:tcPr marL="7200" marR="8601" marT="7200" marB="0" anchor="ctr">
          <a:lnL w="9525" cap="flat" cmpd="sng" algn="ctr">
            <a:solidFill>
              <a:schemeClr val="bg2" />
            </a:solidFill>
            <a:prstDash val="solid" />
            <a:round />
            <a:headEnd type="none" w="med" len="med" />
            <a:tailEnd type="none" w="med" len="med" />
          </a:lnL>
          <a:lnR w="9525" cap="flat" cmpd="sng" algn="ctr">
            <a:solidFill>
              <a:schemeClr val="bg2" />
            </a:solidFill>
            <a:prstDash val="solid" />
            <a:round />
            <a:headEnd type="none" w="med" len="med" />
            <a:tailEnd type="none" w="med" len="med" />
          </a:lnR>
          <a:lnT w="9525" cap="flat" cmpd="sng" algn="ctr">
            <a:solidFill>
              <a:schemeClr val="bg2" />
            </a:solidFill>
            <a:prstDash val="solid" />
            <a:round />
            <a:headEnd type="none" w="med" len="med" />
            <a:tailEnd type="none" w="med" len="med" />
          </a:lnT>
          <a:lnB w="9525" cap="flat" cmpd="sng" algn="ctr">
            <a:solidFill>
              <a:schemeClr val="bg2" />
            </a:solidFill>
            <a:prstDash val="solid" />
            <a:round />
            <a:headEnd type="none" w="med" len="med" />
            <a:tailEnd type="none" w="med" len="med" />
          </a:lnB>
          <a:lnTlToBr w="12700" cmpd="sng">
            <a:noFill />
            <a:prstDash val="solid" />
          </a:lnTlToBr>
          <a:lnBlToTr w="12700" cmpd="sng">
            <a:noFill />
            <a:prstDash val="solid" />
          </a:lnBlToTr>
          <a:noFill />
        </a:tcPr>
      </a:tc>
      <a:tc>
      <a:txBody>
        <a:bodyPr />
        <a:lstStyle />
        <a:p>
          <a:pPr algn="r"/>
          <a:r>
            <a:rPr lang="en-CA"  sz="1050" b="0" dirty="0">
              <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
            </a:rPr>
            <a:t>CONTRACTEDSPEND</a:t>
          </a:r>
        </a:p>
      </a:txBody>
      <a:tcPr marL="7200" marR="8601" marT="7200" marB="0" anchor="ctr">
        <a:lnL w="9525" cap="flat" cmpd="sng" algn="ctr">
          <a:solidFill>
            <a:schemeClr val="bg2" />
          </a:solidFill>
          <a:prstDash val="solid" />
          <a:round />
          <a:headEnd type="none" w="med" len="med" />
          <a:tailEnd type="none" w="med" len="med" />
        </a:lnL>
        <a:lnR w="9525" cap="flat" cmpd="sng" algn="ctr">
          <a:solidFill>
            <a:schemeClr val="bg2" />
          </a:solidFill>
          <a:prstDash val="solid" />
          <a:round />
          <a:headEnd type="none" w="med" len="med" />
          <a:tailEnd type="none" w="med" len="med" />
        </a:lnR>
        <a:lnT w="9525" cap="flat" cmpd="sng" algn="ctr">
          <a:solidFill>
            <a:schemeClr val="bg2" />
          </a:solidFill>
          <a:prstDash val="solid" />
          <a:round />
          <a:headEnd type="none" w="med" len="med" />
          <a:tailEnd type="none" w="med" len="med" />
        </a:lnT>
        <a:lnB w="9525" cap="flat" cmpd="sng" algn="ctr">
          <a:solidFill>
            <a:schemeClr val="bg2" />
          </a:solidFill>
          <a:prstDash val="solid" />
          <a:round />
          <a:headEnd type="none" w="med" len="med" />
          <a:tailEnd type="none" w="med" len="med" />
        </a:lnB>
        <a:lnTlToBr w="12700" cmpd="sng">
          <a:noFill />
          <a:prstDash val="solid" />
        </a:lnTlToBr>
        <a:lnBlToTr w="12700" cmpd="sng">
          <a:noFill />
          <a:prstDash val="solid" />
        </a:lnBlToTr>
        <a:noFill />
      </a:tcPr>
    </a:tc>
    <a:tc>
    <a:txBody>
      <a:bodyPr />
      <a:lstStyle />
      <a:p>
        <a:pPr algn="r"/>
        <a:r>
          <a:rPr lang="en-CA" sz="1050" b="0" dirty="0">
            <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
          </a:rPr>
          <a:t>ACTUALSPEND</a:t>
        </a:r>
      </a:p>
    </a:txBody>
    <a:tcPr marL="7200" marR="8601" marT="7200" marB="0" anchor="ctr">
      <a:lnL w="9525" cap="flat" cmpd="sng" algn="ctr">
        <a:solidFill>
          <a:schemeClr val="bg2" />
        </a:solidFill>
        <a:prstDash val="solid" />
        <a:round />
        <a:headEnd type="none" w="med" len="med" />
        <a:tailEnd type="none" w="med" len="med" />
      </a:lnL>
      <a:lnR w="9525" cap="flat" cmpd="sng" algn="ctr">
        <a:solidFill>
          <a:schemeClr val="bg2" />
        </a:solidFill>
        <a:prstDash val="solid" />
        <a:round />
        <a:headEnd type="none" w="med" len="med" />
        <a:tailEnd type="none" w="med" len="med" />
      </a:lnR>
      <a:lnT w="9525" cap="flat" cmpd="sng" algn="ctr">
        <a:solidFill>
          <a:schemeClr val="bg2" />
        </a:solidFill>
        <a:prstDash val="solid" />
        <a:round />
        <a:headEnd type="none" w="med" len="med" />
        <a:tailEnd type="none" w="med" len="med" />
      </a:lnT>
      <a:lnB w="9525" cap="flat" cmpd="sng" algn="ctr">
        <a:solidFill>
          <a:schemeClr val="bg2" />
        </a:solidFill>
        <a:prstDash val="solid" />
        <a:round />
        <a:headEnd type="none" w="med" len="med" />
        <a:tailEnd type="none" w="med" len="med" />
      </a:lnB>
      <a:lnTlToBr w="12700" cmpd="sng">
        <a:noFill />
        <a:prstDash val="solid" />
      </a:lnTlToBr>
      <a:lnBlToTr w="12700" cmpd="sng">
        <a:noFill />
        <a:prstDash val="solid" />
      </a:lnBlToTr>
      <a:noFill />
    </a:tcPr>
  </a:tc>
      <a:extLst>
        <a:ext uri="{0D108BD9-81ED-4DB2-BD59-A6C34878D82A}">
          <a16:rowId xmlns:a16="http://schemas.microsoft.com/office/drawing/2014/main" val="RANDOMID" />
        </a:ext>
      </a:extLst>
    </a:tr>
      `.trim();
    const doc = new DOMParser().parseFromString(
      xml.replace("RANDOMID", this.randomId()),
      "application/xml"
    );
    return this.content.importNode(doc.documentElement, true);
  }

  generateLineItemTemplate(): Node {
    const xml = `
        <a:tr xmlns:p="http://schemas.openxmlformats.org/presentationml/2006/main" xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main" h="0">
        <a:tc>
          <a:txBody>
            <a:bodyPr />
            <a:lstStyle />
            <a:p>
              <a:r>
                <a:rPr lang="en-CA" sz="800" dirty="0">
                  <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
                </a:rPr>
                <a:t>LINEITEMNAME</a:t>
              </a:r>
            </a:p>
          </a:txBody>
          <a:tcPr>
            <a:lnL w="9525" cap="flat" cmpd="sng" algn="ctr">
              <a:solidFill>
                <a:schemeClr val="bg2" />
              </a:solidFill>
              <a:prstDash val="solid" />
              <a:round />
              <a:headEnd type="none" w="med" len="med" />
              <a:tailEnd type="none" w="med" len="med" />
            </a:lnL>
            <a:lnR w="9525" cap="flat" cmpd="sng" algn="ctr">
              <a:solidFill>
                <a:schemeClr val="bg2" />
              </a:solidFill>
              <a:prstDash val="solid" />
              <a:round />
              <a:headEnd type="none" w="med" len="med" />
              <a:tailEnd type="none" w="med" len="med" />
            </a:lnR>
            <a:lnT w="9525" cap="flat" cmpd="sng" algn="ctr">
              <a:solidFill>
                <a:schemeClr val="bg2" />
              </a:solidFill>
              <a:prstDash val="solid" />
              <a:round />
              <a:headEnd type="none" w="med" len="med" />
              <a:tailEnd type="none" w="med" len="med" />
            </a:lnT>
            <a:lnB w="9525" cap="flat" cmpd="sng" algn="ctr">
              <a:solidFill>
                <a:schemeClr val="bg2" />
              </a:solidFill>
              <a:prstDash val="solid" />
              <a:round />
              <a:headEnd type="none" w="med" len="med" />
              <a:tailEnd type="none" w="med" len="med" />
            </a:lnB>
            <a:lnTlToBr w="12700" cmpd="sng">
              <a:noFill />
              <a:prstDash val="solid" />
            </a:lnTlToBr>
            <a:lnBlToTr w="12700" cmpd="sng">
              <a:noFill />
              <a:prstDash val="solid" />
            </a:lnBlToTr>
            <a:noFill />
          </a:tcPr>
        </a:tc>
        <a:tc>
          <a:txBody>
            <a:bodyPr />
            <a:lstStyle />
            <a:p>
              <a:r>
                <a:rPr lang="en-CA" sz="800" dirty="0">
                  <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
                </a:rPr>
                <a:t>LINEITEMSTARTDATE</a:t>
              </a:r>
            </a:p>
          </a:txBody>
          <a:tcPr>
            <a:lnL w="9525" cap="flat" cmpd="sng" algn="ctr">
              <a:solidFill>
                <a:schemeClr val="bg2" />
              </a:solidFill>
              <a:prstDash val="solid" />
              <a:round />
              <a:headEnd type="none" w="med" len="med" />
              <a:tailEnd type="none" w="med" len="med" />
            </a:lnL>
            <a:lnR w="9525" cap="flat" cmpd="sng" algn="ctr">
              <a:solidFill>
                <a:schemeClr val="bg2" />
              </a:solidFill>
              <a:prstDash val="solid" />
              <a:round />
              <a:headEnd type="none" w="med" len="med" />
              <a:tailEnd type="none" w="med" len="med" />
            </a:lnR>
            <a:lnT w="9525" cap="flat" cmpd="sng" algn="ctr">
              <a:solidFill>
                <a:schemeClr val="bg2" />
              </a:solidFill>
              <a:prstDash val="solid" />
              <a:round />
              <a:headEnd type="none" w="med" len="med" />
              <a:tailEnd type="none" w="med" len="med" />
            </a:lnT>
            <a:lnB w="9525" cap="flat" cmpd="sng" algn="ctr">
              <a:solidFill>
                <a:schemeClr val="bg2" />
              </a:solidFill>
              <a:prstDash val="solid" />
              <a:round />
              <a:headEnd type="none" w="med" len="med" />
              <a:tailEnd type="none" w="med" len="med" />
            </a:lnB>
            <a:lnTlToBr w="12700" cmpd="sng">
              <a:noFill />
              <a:prstDash val="solid" />
            </a:lnTlToBr>
            <a:lnBlToTr w="12700" cmpd="sng">
              <a:noFill />
              <a:prstDash val="solid" />
            </a:lnBlToTr>
            <a:noFill />
          </a:tcPr>
        </a:tc>
        <a:tc>
          <a:txBody>
            <a:bodyPr />
            <a:lstStyle />
            <a:p>
              <a:r>
                <a:rPr lang="en-CA" sz="800" dirty="0">
                  <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
                </a:rPr>
                <a:t>LINEITEMENDDATE</a:t>
              </a:r>
            </a:p>
          </a:txBody>
          <a:tcPr>
            <a:lnL w="9525" cap="flat" cmpd="sng" algn="ctr">
              <a:solidFill>
                <a:schemeClr val="bg2" />
              </a:solidFill>
              <a:prstDash val="solid" />
              <a:round />
              <a:headEnd type="none" w="med" len="med" />
              <a:tailEnd type="none" w="med" len="med" />
            </a:lnL>
            <a:lnR w="9525" cap="flat" cmpd="sng" algn="ctr">
              <a:solidFill>
                <a:schemeClr val="bg2" />
              </a:solidFill>
              <a:prstDash val="solid" />
              <a:round />
              <a:headEnd type="none" w="med" len="med" />
              <a:tailEnd type="none" w="med" len="med" />
            </a:lnR>
            <a:lnT w="9525" cap="flat" cmpd="sng" algn="ctr">
              <a:solidFill>
                <a:schemeClr val="bg2" />
              </a:solidFill>
              <a:prstDash val="solid" />
              <a:round />
              <a:headEnd type="none" w="med" len="med" />
              <a:tailEnd type="none" w="med" len="med" />
            </a:lnT>
            <a:lnB w="9525" cap="flat" cmpd="sng" algn="ctr">
              <a:solidFill>
                <a:schemeClr val="bg2" />
              </a:solidFill>
              <a:prstDash val="solid" />
              <a:round />
              <a:headEnd type="none" w="med" len="med" />
              <a:tailEnd type="none" w="med" len="med" />
            </a:lnB>
            <a:lnTlToBr w="12700" cmpd="sng">
              <a:noFill />
              <a:prstDash val="solid" />
            </a:lnTlToBr>
            <a:lnBlToTr w="12700" cmpd="sng">
              <a:noFill />
              <a:prstDash val="solid" />
            </a:lnBlToTr>
            <a:noFill />
          </a:tcPr>
        </a:tc>
        <a:tc>
          <a:txBody>
            <a:bodyPr />
            <a:lstStyle />
            <a:p>
              <a:pPr algn="r"/>
              <a:r>
                <a:rPr lang="en-CA" sz="800" dirty="0">
                  <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
                </a:rPr>
                <a:t>LINEITEMQUANTITY</a:t>
              </a:r>
            </a:p>
          </a:txBody>
          <a:tcPr>
            <a:lnL w="9525" cap="flat" cmpd="sng" algn="ctr">
              <a:solidFill>
                <a:schemeClr val="bg2" />
              </a:solidFill>
              <a:prstDash val="solid" />
              <a:round />
              <a:headEnd type="none" w="med" len="med" />
              <a:tailEnd type="none" w="med" len="med" />
            </a:lnL>
            <a:lnR w="9525" cap="flat" cmpd="sng" algn="ctr">
              <a:solidFill>
                <a:schemeClr val="bg2" />
              </a:solidFill>
              <a:prstDash val="solid" />
              <a:round />
              <a:headEnd type="none" w="med" len="med" />
              <a:tailEnd type="none" w="med" len="med" />
            </a:lnR>
            <a:lnT w="9525" cap="flat" cmpd="sng" algn="ctr">
              <a:solidFill>
                <a:schemeClr val="bg2" />
              </a:solidFill>
              <a:prstDash val="solid" />
              <a:round />
              <a:headEnd type="none" w="med" len="med" />
              <a:tailEnd type="none" w="med" len="med" />
            </a:lnT>
            <a:lnB w="9525" cap="flat" cmpd="sng" algn="ctr">
              <a:solidFill>
                <a:schemeClr val="bg2" />
              </a:solidFill>
              <a:prstDash val="solid" />
              <a:round />
              <a:headEnd type="none" w="med" len="med" />
              <a:tailEnd type="none" w="med" len="med" />
            </a:lnB>
            <a:lnTlToBr w="12700" cmpd="sng">
              <a:noFill />
              <a:prstDash val="solid" />
            </a:lnTlToBr>
            <a:lnBlToTr w="12700" cmpd="sng">
              <a:noFill />
              <a:prstDash val="solid" />
            </a:lnBlToTr>
            <a:noFill />
          </a:tcPr>
        </a:tc>
        <a:tc>
          <a:txBody>
            <a:bodyPr />
            <a:lstStyle />
            <a:p>
              <a:pPr algn="ctr"/>
              <a:r>
                <a:rPr lang="en-CA" sz="800" dirty="0">
                  <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
                </a:rPr>
                <a:t>LINEITEMRATETYPE</a:t>
              </a:r>
            </a:p>
          </a:txBody>
          <a:tcPr>
            <a:lnL w="9525" cap="flat" cmpd="sng" algn="ctr">
              <a:solidFill>
                <a:schemeClr val="bg2" />
              </a:solidFill>
              <a:prstDash val="solid" />
              <a:round />
              <a:headEnd type="none" w="med" len="med" />
              <a:tailEnd type="none" w="med" len="med" />
            </a:lnL>
            <a:lnR w="9525" cap="flat" cmpd="sng" algn="ctr">
              <a:solidFill>
                <a:schemeClr val="bg2" />
              </a:solidFill>
              <a:prstDash val="solid" />
              <a:round />
              <a:headEnd type="none" w="med" len="med" />
              <a:tailEnd type="none" w="med" len="med" />
            </a:lnR>
            <a:lnT w="9525" cap="flat" cmpd="sng" algn="ctr">
              <a:solidFill>
                <a:schemeClr val="bg2" />
              </a:solidFill>
              <a:prstDash val="solid" />
              <a:round />
              <a:headEnd type="none" w="med" len="med" />
              <a:tailEnd type="none" w="med" len="med" />
            </a:lnT>
            <a:lnB w="9525" cap="flat" cmpd="sng" algn="ctr">
              <a:solidFill>
                <a:schemeClr val="bg2" />
              </a:solidFill>
              <a:prstDash val="solid" />
              <a:round />
              <a:headEnd type="none" w="med" len="med" />
              <a:tailEnd type="none" w="med" len="med" />
            </a:lnB>
            <a:lnTlToBr w="12700" cmpd="sng">
              <a:noFill />
              <a:prstDash val="solid" />
            </a:lnTlToBr>
            <a:lnBlToTr w="12700" cmpd="sng">
              <a:noFill />
              <a:prstDash val="solid" />
            </a:lnBlToTr>
            <a:noFill />
          </a:tcPr>
        </a:tc>
        <a:tc>
          <a:txBody>
            <a:bodyPr />
            <a:lstStyle />
            <a:p>
              <a:pPr algn="r"/>
              <a:r>
                <a:rPr lang="en-CA" sz="800" dirty="0">
                  <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
                </a:rPr>
                <a:t>LINEITEMRATE</a:t>
              </a:r>
            </a:p>
          </a:txBody>
          <a:tcPr>
            <a:lnL w="9525" cap="flat" cmpd="sng" algn="ctr">
              <a:solidFill>
                <a:schemeClr val="bg2" />
              </a:solidFill>
              <a:prstDash val="solid" />
              <a:round />
              <a:headEnd type="none" w="med" len="med" />
              <a:tailEnd type="none" w="med" len="med" />
            </a:lnL>
            <a:lnR w="9525" cap="flat" cmpd="sng" algn="ctr">
              <a:solidFill>
                <a:schemeClr val="bg2" />
              </a:solidFill>
              <a:prstDash val="solid" />
              <a:round />
              <a:headEnd type="none" w="med" len="med" />
              <a:tailEnd type="none" w="med" len="med" />
            </a:lnR>
            <a:lnT w="9525" cap="flat" cmpd="sng" algn="ctr">
              <a:solidFill>
                <a:schemeClr val="bg2" />
              </a:solidFill>
              <a:prstDash val="solid" />
              <a:round />
              <a:headEnd type="none" w="med" len="med" />
              <a:tailEnd type="none" w="med" len="med" />
            </a:lnT>
            <a:lnB w="9525" cap="flat" cmpd="sng" algn="ctr">
              <a:solidFill>
                <a:schemeClr val="bg2" />
              </a:solidFill>
              <a:prstDash val="solid" />
              <a:round />
              <a:headEnd type="none" w="med" len="med" />
              <a:tailEnd type="none" w="med" len="med" />
            </a:lnB>
            <a:lnTlToBr w="12700" cmpd="sng">
              <a:noFill />
              <a:prstDash val="solid" />
            </a:lnTlToBr>
            <a:lnBlToTr w="12700" cmpd="sng">
              <a:noFill />
              <a:prstDash val="solid" />
            </a:lnBlToTr>
            <a:noFill />
          </a:tcPr>
        </a:tc>
        <a:tc>
          <a:txBody>
            <a:bodyPr />
            <a:lstStyle />
            <a:p>
              <a:pPr algn="r"/>
              <a:r>
                <a:rPr lang="en-CA" sz="800" dirty="0">
                  <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
                </a:rPr>
                <a:t>LINEITEMIMPRESSIONS</a:t>
              </a:r>
            </a:p>
          </a:txBody>
          <a:tcPr>
            <a:lnL w="9525" cap="flat" cmpd="sng" algn="ctr">
              <a:solidFill>
                <a:schemeClr val="bg2" />
              </a:solidFill>
              <a:prstDash val="solid" />
              <a:round />
              <a:headEnd type="none" w="med" len="med" />
              <a:tailEnd type="none" w="med" len="med" />
            </a:lnL>
            <a:lnR w="9525" cap="flat" cmpd="sng" algn="ctr">
              <a:solidFill>
                <a:schemeClr val="bg2" />
              </a:solidFill>
              <a:prstDash val="solid" />
              <a:round />
              <a:headEnd type="none" w="med" len="med" />
              <a:tailEnd type="none" w="med" len="med" />
            </a:lnR>
            <a:lnT w="9525" cap="flat" cmpd="sng" algn="ctr">
              <a:solidFill>
                <a:schemeClr val="bg2" />
              </a:solidFill>
              <a:prstDash val="solid" />
              <a:round />
              <a:headEnd type="none" w="med" len="med" />
              <a:tailEnd type="none" w="med" len="med" />
            </a:lnT>
            <a:lnB w="9525" cap="flat" cmpd="sng" algn="ctr">
              <a:solidFill>
                <a:schemeClr val="bg2" />
              </a:solidFill>
              <a:prstDash val="solid" />
              <a:round />
              <a:headEnd type="none" w="med" len="med" />
              <a:tailEnd type="none" w="med" len="med" />
            </a:lnB>
            <a:lnTlToBr w="12700" cmpd="sng">
              <a:noFill />
              <a:prstDash val="solid" />
            </a:lnTlToBr>
            <a:lnBlToTr w="12700" cmpd="sng">
              <a:noFill />
              <a:prstDash val="solid" />
            </a:lnBlToTr>
            <a:noFill />
          </a:tcPr>
        </a:tc>
        <a:tc>
          <a:txBody>
            <a:bodyPr />
            <a:lstStyle />
            <a:p>
              <a:pPr algn="r"/>
              <a:r>
                <a:rPr lang="en-CA" sz="800" dirty="0">
                  <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
                </a:rPr>
                <a:t>LINEITEMCLICKS</a:t>
              </a:r>
            </a:p>
          </a:txBody>
          <a:tcPr>
            <a:lnL w="9525" cap="flat" cmpd="sng" algn="ctr">
              <a:solidFill>
                <a:schemeClr val="bg2" />
              </a:solidFill>
              <a:prstDash val="solid" />
              <a:round />
              <a:headEnd type="none" w="med" len="med" />
              <a:tailEnd type="none" w="med" len="med" />
            </a:lnL>
            <a:lnR w="9525" cap="flat" cmpd="sng" algn="ctr">
              <a:solidFill>
                <a:schemeClr val="bg2" />
              </a:solidFill>
              <a:prstDash val="solid" />
              <a:round />
              <a:headEnd type="none" w="med" len="med" />
              <a:tailEnd type="none" w="med" len="med" />
            </a:lnR>
            <a:lnT w="9525" cap="flat" cmpd="sng" algn="ctr">
              <a:solidFill>
                <a:schemeClr val="bg2" />
              </a:solidFill>
              <a:prstDash val="solid" />
              <a:round />
              <a:headEnd type="none" w="med" len="med" />
              <a:tailEnd type="none" w="med" len="med" />
            </a:lnT>
            <a:lnB w="9525" cap="flat" cmpd="sng" algn="ctr">
              <a:solidFill>
                <a:schemeClr val="bg2" />
              </a:solidFill>
              <a:prstDash val="solid" />
              <a:round />
              <a:headEnd type="none" w="med" len="med" />
              <a:tailEnd type="none" w="med" len="med" />
            </a:lnB>
            <a:lnTlToBr w="12700" cmpd="sng">
              <a:noFill />
              <a:prstDash val="solid" />
            </a:lnTlToBr>
            <a:lnBlToTr w="12700" cmpd="sng">
              <a:noFill />
              <a:prstDash val="solid" />
            </a:lnBlToTr>
            <a:noFill />
          </a:tcPr>
        </a:tc>
        <a:tc>
          <a:txBody>
            <a:bodyPr />
            <a:lstStyle />
            <a:p>
              <a:pPr algn="r"/>
              <a:r>
                <a:rPr lang="en-CA" sz="800" dirty="0">
                  <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
                </a:rPr>
                <a:t>LINEITEMCTR</a:t>
              </a:r>
            </a:p>
          </a:txBody>
          <a:tcPr>
            <a:lnL w="9525" cap="flat" cmpd="sng" algn="ctr">
              <a:solidFill>
                <a:schemeClr val="bg2" />
              </a:solidFill>
              <a:prstDash val="solid" />
              <a:round />
              <a:headEnd type="none" w="med" len="med" />
              <a:tailEnd type="none" w="med" len="med" />
            </a:lnL>
            <a:lnR w="9525" cap="flat" cmpd="sng" algn="ctr">
              <a:solidFill>
                <a:schemeClr val="bg2" />
              </a:solidFill>
              <a:prstDash val="solid" />
              <a:round />
              <a:headEnd type="none" w="med" len="med" />
              <a:tailEnd type="none" w="med" len="med" />
            </a:lnR>
            <a:lnT w="9525" cap="flat" cmpd="sng" algn="ctr">
              <a:solidFill>
                <a:schemeClr val="bg2" />
              </a:solidFill>
              <a:prstDash val="solid" />
              <a:round />
              <a:headEnd type="none" w="med" len="med" />
              <a:tailEnd type="none" w="med" len="med" />
            </a:lnT>
            <a:lnB w="9525" cap="flat" cmpd="sng" algn="ctr">
              <a:solidFill>
                <a:schemeClr val="bg2" />
              </a:solidFill>
              <a:prstDash val="solid" />
              <a:round />
              <a:headEnd type="none" w="med" len="med" />
              <a:tailEnd type="none" w="med" len="med" />
            </a:lnB>
            <a:lnTlToBr w="12700" cmpd="sng">
              <a:noFill />
              <a:prstDash val="solid" />
            </a:lnTlToBr>
            <a:lnBlToTr w="12700" cmpd="sng">
              <a:noFill />
              <a:prstDash val="solid" />
            </a:lnBlToTr>
            <a:noFill />
          </a:tcPr>
        </a:tc>
        <a:tc>
          <a:txBody>
            <a:bodyPr />
            <a:lstStyle />
            <a:p>
              <a:pPr algn="r"/>
              <a:r>
                <a:rPr lang="en-CA" sz="800" dirty="0">
                  <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
                </a:rPr>
                <a:t>LINEITEMCONVERSIONS</a:t>
              </a:r>
            </a:p>
          </a:txBody>
          <a:tcPr>
            <a:lnL w="9525" cap="flat" cmpd="sng" algn="ctr">
              <a:solidFill>
                <a:schemeClr val="bg2" />
              </a:solidFill>
              <a:prstDash val="solid" />
              <a:round />
              <a:headEnd type="none" w="med" len="med" />
              <a:tailEnd type="none" w="med" len="med" />
            </a:lnL>
            <a:lnR w="9525" cap="flat" cmpd="sng" algn="ctr">
              <a:solidFill>
                <a:schemeClr val="bg2" />
              </a:solidFill>
              <a:prstDash val="solid" />
              <a:round />
              <a:headEnd type="none" w="med" len="med" />
              <a:tailEnd type="none" w="med" len="med" />
            </a:lnR>
            <a:lnT w="9525" cap="flat" cmpd="sng" algn="ctr">
              <a:solidFill>
                <a:schemeClr val="bg2" />
              </a:solidFill>
              <a:prstDash val="solid" />
              <a:round />
              <a:headEnd type="none" w="med" len="med" />
              <a:tailEnd type="none" w="med" len="med" />
            </a:lnT>
            <a:lnB w="9525" cap="flat" cmpd="sng" algn="ctr">
              <a:solidFill>
                <a:schemeClr val="bg2" />
              </a:solidFill>
              <a:prstDash val="solid" />
              <a:round />
              <a:headEnd type="none" w="med" len="med" />
              <a:tailEnd type="none" w="med" len="med" />
            </a:lnB>
            <a:lnTlToBr w="12700" cmpd="sng">
              <a:noFill />
              <a:prstDash val="solid" />
            </a:lnTlToBr>
            <a:lnBlToTr w="12700" cmpd="sng">
              <a:noFill />
              <a:prstDash val="solid" />
            </a:lnBlToTr>
            <a:noFill />
          </a:tcPr>
        </a:tc>
        <a:tc>
          <a:txBody>
            <a:bodyPr />
            <a:lstStyle />
            <a:p>
              <a:pPr algn="r"/>
              <a:r>
                <a:rPr lang="en-CA" sz="800" dirty="0">
                  <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
                </a:rPr>
                <a:t>LIINEITEMCONTRACTEDSPEND</a:t>
              </a:r>
            </a:p>
          </a:txBody>
          <a:tcPr>
            <a:lnL w="9525" cap="flat" cmpd="sng" algn="ctr">
              <a:solidFill>
                <a:schemeClr val="bg2" />
              </a:solidFill>
              <a:prstDash val="solid" />
              <a:round />
              <a:headEnd type="none" w="med" len="med" />
              <a:tailEnd type="none" w="med" len="med" />
            </a:lnL>
            <a:lnR w="9525" cap="flat" cmpd="sng" algn="ctr">
              <a:solidFill>
                <a:schemeClr val="bg2" />
              </a:solidFill>
              <a:prstDash val="solid" />
              <a:round />
              <a:headEnd type="none" w="med" len="med" />
              <a:tailEnd type="none" w="med" len="med" />
            </a:lnR>
            <a:lnT w="9525" cap="flat" cmpd="sng" algn="ctr">
              <a:solidFill>
                <a:schemeClr val="bg2" />
              </a:solidFill>
              <a:prstDash val="solid" />
              <a:round />
              <a:headEnd type="none" w="med" len="med" />
              <a:tailEnd type="none" w="med" len="med" />
            </a:lnT>
            <a:lnB w="9525" cap="flat" cmpd="sng" algn="ctr">
              <a:solidFill>
                <a:schemeClr val="bg2" />
              </a:solidFill>
              <a:prstDash val="solid" />
              <a:round />
              <a:headEnd type="none" w="med" len="med" />
              <a:tailEnd type="none" w="med" len="med" />
            </a:lnB>
            <a:lnTlToBr w="12700" cmpd="sng">
              <a:noFill />
              <a:prstDash val="solid" />
            </a:lnTlToBr>
            <a:lnBlToTr w="12700" cmpd="sng">
              <a:noFill />
              <a:prstDash val="solid" />
            </a:lnBlToTr>
            <a:noFill />
          </a:tcPr>
        </a:tc>
        <a:tc>
          <a:txBody>
            <a:bodyPr />
            <a:lstStyle />
            <a:p>
              <a:pPr algn="r"/>
              <a:r>
                <a:rPr lang="en-CA" sz="800" dirty="0">
                  <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
                </a:rPr>
                <a:t>LINEITEMACTUALSPEND</a:t>
              </a:r>
            </a:p>
          </a:txBody>
          <a:tcPr>
            <a:lnL w="9525" cap="flat" cmpd="sng" algn="ctr">
              <a:solidFill>
                <a:schemeClr val="bg2" />
              </a:solidFill>
              <a:prstDash val="solid" />
              <a:round />
              <a:headEnd type="none" w="med" len="med" />
              <a:tailEnd type="none" w="med" len="med" />
            </a:lnL>
            <a:lnR w="9525" cap="flat" cmpd="sng" algn="ctr">
              <a:solidFill>
                <a:schemeClr val="bg2" />
              </a:solidFill>
              <a:prstDash val="solid" />
              <a:round />
              <a:headEnd type="none" w="med" len="med" />
              <a:tailEnd type="none" w="med" len="med" />
            </a:lnR>
            <a:lnT w="9525" cap="flat" cmpd="sng" algn="ctr">
              <a:solidFill>
                <a:schemeClr val="bg2" />
              </a:solidFill>
              <a:prstDash val="solid" />
              <a:round />
              <a:headEnd type="none" w="med" len="med" />
              <a:tailEnd type="none" w="med" len="med" />
            </a:lnT>
            <a:lnB w="9525" cap="flat" cmpd="sng" algn="ctr">
              <a:solidFill>
                <a:schemeClr val="bg2" />
              </a:solidFill>
              <a:prstDash val="solid" />
              <a:round />
              <a:headEnd type="none" w="med" len="med" />
              <a:tailEnd type="none" w="med" len="med" />
            </a:lnB>
            <a:lnTlToBr w="12700" cmpd="sng">
              <a:noFill />
              <a:prstDash val="solid" />
            </a:lnTlToBr>
            <a:lnBlToTr w="12700" cmpd="sng">
              <a:noFill />
              <a:prstDash val="solid" />
            </a:lnBlToTr>
            <a:noFill />
          </a:tcPr>
        </a:tc>
        <a:extLst>
          <a:ext uri="{0D108BD9-81ED-4DB2-BD59-A6C34878D82A}">
            <a16:rowId xmlns:a16="http://schemas.microsoft.com/office/drawing/2014/main" val="RANDOMID" />
          </a:ext>
        </a:extLst>
      </a:tr>
        `.trim();
    const doc = new DOMParser().parseFromString(
      xml.replace("RANDOMID", this.randomId()),
      "application/xml"
    );
    return this.content.importNode(doc.documentElement, true);
  }

  replaceNodeText(
    doc: XMLDocument,
    node: Node,
    oldVal: string,
    newVal: string
  ) {
    const xpath =
      "/p:sld/p:cSld/p:spTree/p:graphicFrame/a:graphic/a:graphicData/a:tbl/a:tr/a:tc/a:txBody/a:p/a:r/a:t[text()='" +
      oldVal +
      "']";
    const target = doc.evaluate(
      xpath,
      node,
      this.resolver,
      XPathResult.FIRST_ORDERED_NODE_TYPE,
      null
    ).singleNodeValue;
    if (target) {
          target.textContent = newVal;
        
        }
  }

  replaceNodeTextBold(
    doc: XMLDocument,
    node: Node,
    oldVal: string,
    newVal: string
  ) {
    const xpath =
      "/p:sld/p:cSld/p:spTree/p:graphicFrame/a:graphic/a:graphicData/a:tbl/a:tr/a:tc/a:txBody/a:p/a:r/a:t[text()='" +
      oldVal +
      "']";
    const target = doc.evaluate(
      xpath,
      node,
      this.resolver,
      XPathResult.FIRST_ORDERED_NODE_TYPE,
      null
    ).singleNodeValue;
    if (target) {
        const p = target.parentNode!;
        while (p.childElementCount > 0){
            p.removeChild(p.firstChild!);
        }
        const arps = doc.createElementNS(
            "http://schemas.openxmlformats.org/drawingml/2006/main",
            "a:rPr"
        );
        arps.setAttribute("lang","en-CA");
        arps.setAttribute("sz","1050");
        arps.setAttribute("b","1");
        arps.setAttribute("dirty","0");

      const anc = doc.createElementNS(
            "http://schemas.openxmlformats.org/drawingml/2006/main",
            "a:latin"
        );

        anc.setAttribute("typeface","Ted Next Medium");
        anc.setAttribute("panose","020B0602030202020203");
        anc.setAttribute("pitchFamily","34");
        anc.setAttribute("charset","0");

        const ats = doc.createElementNS(
            "http://schemas.openxmlformats.org/drawingml/2006/main",
            "a:t"
        );
        arps.appendChild(anc);
        ats.textContent = newVal;

        p.appendChild(arps);
        p.appendChild(ats);
    }

  }

  clearNodeText(doc: XMLDocument, node: Node, oldVal: string) {
    const xpath =
      "/p:sld/p:cSld/p:spTree/p:graphicFrame/a:graphic/a:graphicData/a:tbl/a:tr/a:tc/a:txBody/a:p/a:r/a:t[text()='" +
      oldVal +
      "']";
    const target = doc.evaluate(
      xpath,
      node,
      this.resolver,
      XPathResult.FIRST_ORDERED_NODE_TYPE,
      null
    ).singleNodeValue;
    if (target) {
      const p = target.parentNode!.parentNode!;
      while (p.childElementCount > 0) {
        p.removeChild(p.firstChild!);
      }
      const endParaRPr = doc.createElementNS(
        "http://schemas.openxmlformats.org/drawingml/2006/main",
        "a:endParaRPr"
      );
      endParaRPr.setAttribute("sz", "800");
      p.appendChild(endParaRPr);
    }
  }

  removeRowColumn(doc: XMLDocument, node: Node, oneBasedIndex: number) {
    try {
      const xpath = "./a:tc[position()=" + oneBasedIndex.toString() + "]";
      const target = doc.evaluate(
        xpath,
        node,
        this.resolver,
        XPathResult.FIRST_ORDERED_NODE_TYPE,
        null
      ).singleNodeValue;
      if (target) {
        target.parentNode!.removeChild(target);
      }
    } catch (e) {
    }
  }

  appendSummaryFooter(
    footer: OrderLineTotals,
    includeCtr: boolean,
    includeConversions: boolean,
    doc: Document,
    parentNode: Node
  ) {
    const result = this.generateLineItemTemplate();
    parentNode.appendChild(result);
    this.replaceNodeText(doc, result, "LINEITEMNAME", "Totals:");
    this.clearNodeText(doc, result, "LINEITEMSTARTDATE");
    this.clearNodeText(doc, result, "LINEITEMENDDATE");
    this.clearNodeText(doc, result, "LINEITEMQUANTITY");
    this.clearNodeText(doc, result, "LINEITEMRATETYPE");
    this.clearNodeText(doc, result, "LINEITEMRATE");
    this.replaceNodeText(
      doc,
      result,
      "LINEITEMIMPRESSIONS",
      Conversions.numberWithCommas(footer.deliveredImpressions, 0)
    );
    this.replaceNodeText(
      doc,
      result,
      "LINEITEMCLICKS",
      Conversions.numberWithCommas(footer.clicks, 0)
    );
    if (includeCtr) {
      this.replaceNodeText(
        doc,
        result,
        "LINEITEMCTR",
        Conversions.numberWithCommas(footer.ctr, 2) + "%"
      );
    } else {
      this.removeRowColumn(doc, result, 9);
    }
    if (includeConversions) {
      this.replaceNodeText(
        doc,
        result,
        "LINEITEMCONVERSIONS",
        Conversions.numberWithCommas(footer.conversions, 0)
      );
    } else {
      this.removeRowColumn(doc, result, includeCtr ? 10 : 9);
    }
    this.replaceNodeText(
      doc,
      result,
      "LIINEITEMCONTRACTEDSPEND",
      "$" + Conversions.numberWithCommas(footer.contractedSpend, 2)
    );
    this.replaceNodeText(
      doc,
      result,
      "LINEITEMACTUALSPEND",
      "$" + Conversions.numberWithCommas(footer.actualSpend, 2)
    );

    // Colour changes!

    // Background fill
    const bgXpath =
      "/p:sld/p:cSld/p:spTree/p:graphicFrame/a:graphic/a:graphicData/a:tbl/a:tr[last()]/a:tc/a:tcPr/a:noFill";
    var bgDone = false;
    while (!bgDone) {
      const noFillNodes = doc.evaluate(
        bgXpath,
        result,
        this.resolver,
        XPathResult.ORDERED_NODE_ITERATOR_TYPE,
        null
      );
      var noFillNode = noFillNodes.iterateNext();
      bgDone = noFillNode == null;
      if (noFillNode) {
        var prNode = noFillNode.parentNode!;
        prNode.removeChild(noFillNode);
        var bgFill = doc.createElementNS(
          "http://schemas.openxmlformats.org/drawingml/2006/main",
          "a:solidFill"
        );
        var bgColor = doc.createElementNS(
          "http://schemas.openxmlformats.org/drawingml/2006/main",
          "a:srgbClr"
        );
        bgColor.setAttribute("val", "06BAE6");
        bgFill.appendChild(bgColor);
        prNode.appendChild(bgFill);
      }
    }

    // Foreground
    const fgXpath =
      "/p:sld/p:cSld/p:spTree/p:graphicFrame/a:graphic/a:graphicData/a:tbl/a:tr[last()]/a:tc/a:txBody/a:p/a:r/a:rPr";
    const runPropNodes = doc.evaluate(
      fgXpath,
      result,
      this.resolver,
      XPathResult.ORDERED_NODE_SNAPSHOT_TYPE,
      null
    );
    for (var i = 0; i < runPropNodes.snapshotLength; i++) {
      var node = runPropNodes.snapshotItem(i);
      if (node) {
        var fgFill = doc.createElementNS(
          "http://schemas.openxmlformats.org/drawingml/2006/main",
          "a:solidFill"
        );
        var fgColor = doc.createElementNS(
          "http://schemas.openxmlformats.org/drawingml/2006/main",
          "a:schemeClr"
        );
        fgColor.setAttribute("val", "bg1");
        fgFill.appendChild(fgColor);
        node.insertBefore(fgFill, node.childNodes.item(0));
      }
    }
  }

  //TOpline Summary
  appendToplineSummaryFooter(
    footer: ToplineTotals,
    includeCtr: boolean,
    includeConversions: boolean,
    doc: Document,
    parentNode: Node
  ) {
    const result = this.generateLineItemTemplateForToplineSumm();
    parentNode.appendChild(result);
    this.replaceNodeTextBold(doc, result, "TACTICGROUPING", "Totals:");
    this.clearNodeText(doc, result, "COSTTYPE");
    this.clearNodeText(doc, result, "DELIVEREDINDEX");
    this.clearNodeText(doc, result, "COMPLITIONRATE");
    this.clearNodeText(doc, result, "ENGAGEMENTRATE");
    this.replaceNodeTextBold(
      doc,
      result,
      "DELIVEREDIMPRESSION",
      footer.deliveredImpressions > 0
        ? Conversions.numberWithCommas(footer.deliveredImpressions, 0)
        : ""
    );
    this.replaceNodeTextBold(
      doc,
      result,
      "CLICKS",
      footer.clicks > 0 ? Conversions.numberWithCommas(footer.clicks, 0) : ""
    );
    if (includeCtr) {
      this.replaceNodeTextBold(
        doc,
        result,
        "CONTRATIO",
        footer.ctr > 0 ? Conversions.numberWithCommas(footer.ctr, 2) + "%" : ""
      );
    } else {
      this.removeRowColumn(doc, result, 7);
    }
    this.replaceNodeTextBold(
      doc,
      result,
      "CONTRACTEDQUANTITY",
      footer.contractedQuantity > 0
        ? Conversions.numberWithCommas(footer.contractedQuantity, 0)
        : ""
    );
    this.replaceNodeTextBold(
      doc,
      result,
      "ENGAGEMENTS",
      footer.engagement > 0
        ? Conversions.numberWithCommas(footer.engagement, 0)
        : ""
    );
    this.replaceNodeTextBold(
      doc,
      result,
      "COMPLITION",
      footer.complition > 0
        ? Conversions.numberWithCommas(footer.complition, 0)
        : ""
    );
    this.replaceNodeTextBold(
      doc,
      result,
      "STARTS",
      footer.starts > 0 ? Conversions.numberWithCommas(footer.starts, 0) : ""
    );
    this.replaceNodeTextBold(
      doc,
      result,
      "CONTRACTEDSPEND",
      footer.contractedSpend > 0
        ? "$" + Conversions.numberWithCommas(footer.contractedSpend, 0)
        : ""
    );
    this.replaceNodeTextBold(
      doc,
      result,
      "ACTUALSPEND",
      footer.actualSpend > 0
        ? "$" + Conversions.numberWithCommas(footer.actualSpend, 0)
        : ""
    );

    // Colour changes!

    // Background fill
    const bgXpath =
      "/p:sld/p:cSld/p:spTree/p:graphicFrame/a:graphic/a:graphicData/a:tbl/a:tr[last()]/a:tc/a:tcPr/a:noFill";
    var bgDone = false;
    while (!bgDone) {
      const noFillNodes = doc.evaluate(
        bgXpath,
        result,
        this.resolver,
        XPathResult.ORDERED_NODE_ITERATOR_TYPE,
        null
      );
      var noFillNode = noFillNodes.iterateNext();
      bgDone = noFillNode == null;
      if (noFillNode) {
        var prNode = noFillNode.parentNode!;
        prNode.removeChild(noFillNode);
        var bgFill = doc.createElementNS(
          "http://schemas.openxmlformats.org/drawingml/2006/main",
          "a:solidFill"
        );
        var bgColor = doc.createElementNS(
          "http://schemas.openxmlformats.org/drawingml/2006/main",
          "a:srgbClr"
        );
        bgColor.setAttribute("val", "06BAE6");
        bgFill.appendChild(bgColor);
        prNode.appendChild(bgFill);
      }
    }

    // Foreground
    const fgXpath =
      "/p:sld/p:cSld/p:spTree/p:graphicFrame/a:graphic/a:graphicData/a:tbl/a:tr[last()]/a:tc/a:txBody/a:p/a:r/a:rPr";
    const runPropNodes = doc.evaluate(
      fgXpath,
      result,
      this.resolver,
      XPathResult.ORDERED_NODE_SNAPSHOT_TYPE,
      null
    );
    for (var i = 0; i < runPropNodes.snapshotLength; i++) {
      var node = runPropNodes.snapshotItem(i);
      if (node) {
        var fgFill = doc.createElementNS(
          "http://schemas.openxmlformats.org/drawingml/2006/main",
          "a:solidFill"
        );
        var fgColor = doc.createElementNS(
          "http://schemas.openxmlformats.org/drawingml/2006/main",
          "a:schemeClr"
        );
        fgColor.setAttribute("val", "bg1");
        fgFill.appendChild(fgColor);
        node.insertBefore(fgFill, node.childNodes.item(0));
      }
    }
  }

  appendToplineTable(
    data: ToplineSummaryDomoLineItem[],
    includeCtr: boolean,
    includeConversions: boolean,
    parent: Node,
    fullPage: boolean,
    footer: ToplineTotals | null
  ) {
    includeConversions = true;
    const top = "3344380";
    const left = "671513";
    const width = "10735397";
    const height = "2007430";
    var nameGap = 0;
    if (includeCtr) nameGap += 606351;
    // if (includeConversions) nameGap += 665686
    const nameWidth = (1117249 + 606351 - nameGap).toString(); // includeCtr ? '3235011' : '3900697'  // CTR width = 665686

    const xml =
      `
      <p:graphicFrame xmlns:p="http://schemas.openxmlformats.org/presentationml/2006/main">
      <p:nvGraphicFramePr>
        <p:cNvPr id="3" name="Table 3">
          <a:extLst xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
            <a:ext uri="{FF2B5EF4-FFF2-40B4-BE49-F238E27FC236}">
              <a16:creationId xmlns:a16="http://schemas.microsoft.com/office/drawing/2014/main" id="{10C436C0-F348-4735-9B01-FBA24F76123A}" />
            </a:ext>
          </a:extLst>
        </p:cNvPr>
        <p:cNvGraphicFramePr>
          <a:graphicFrameLocks noGrp="1" xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main" />
        </p:cNvGraphicFramePr>
        <p:nvPr>
        </p:nvPr>
      </p:nvGraphicFramePr>
      <p:xfrm>
        <a:off x="` +
      left +
      `" y="` +
      top +
      `" xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main" />
        <a:ext cx="` +
      width +
      `" cy="` +
      height +
      `" xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main" />
      </p:xfrm>
      <a:graphic xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
        <a:graphicData uri="http://schemas.openxmlformats.org/drawingml/2006/table">
          <a:tbl>
            <a:tblPr firstRow="1" bandRow="1">
              <a:tableStyleId>{2D5ABB26-0587-4C30-8999-92F81FD0307C}</a:tableStyleId>
            </a:tblPr>
            <a:tblGrid>
              <a:gridCol w="` +
      nameWidth +
      `">
                <a:extLst>
                  <a:ext uri="{9D8B030D-6E8A-4147-A177-3AD203B41FA5}">
                    <a16:colId xmlns:a16="http://schemas.microsoft.com/office/drawing/2014/main" val="3617640476" />
                  </a:ext>
                </a:extLst>
              </a:gridCol>
              <a:gridCol w="831492">
                <a:extLst>
                  <a:ext uri="{9D8B030D-6E8A-4147-A177-3AD203B41FA5}">
                    <a16:colId xmlns:a16="http://schemas.microsoft.com/office/drawing/2014/main" val="2288565907" />
                  </a:ext>
                </a:extLst>
              </a:gridCol>
              <a:gridCol w="802879">
                <a:extLst>
                  <a:ext uri="{9D8B030D-6E8A-4147-A177-3AD203B41FA5}">
                    <a16:colId xmlns:a16="http://schemas.microsoft.com/office/drawing/2014/main" val="4160875108" />
                  </a:ext>
                </a:extLst>
              </a:gridCol>
              <a:gridCol w="838785">
                <a:extLst>
                  <a:ext uri="{9D8B030D-6E8A-4147-A177-3AD203B41FA5}">
                    <a16:colId xmlns:a16="http://schemas.microsoft.com/office/drawing/2014/main" val="9622618" />
                  </a:ext>
                </a:extLst>
              </a:gridCol>
              <a:gridCol w="737725">
                <a:extLst>
                  <a:ext uri="{9D8B030D-6E8A-4147-A177-3AD203B41FA5}">
                    <a16:colId xmlns:a16="http://schemas.microsoft.com/office/drawing/2014/main" val="3338735523" />
                  </a:ext>
                </a:extLst>
              </a:gridCol>
              <a:gridCol w="555822">
                <a:extLst>
                  <a:ext uri="{9D8B030D-6E8A-4147-A177-3AD203B41FA5}">
                    <a16:colId xmlns:a16="http://schemas.microsoft.com/office/drawing/2014/main" val="126046683" />
                  </a:ext>
                </a:extLst>
              </a:gridCol>
              <a:gridCol w="616351">
                <a:extLst>
                  <a:ext uri="{9D8B030D-6E8A-4147-A177-3AD203B41FA5}">
                    <a16:colId xmlns:a16="http://schemas.microsoft.com/office/drawing/2014/main" val="3599531724" />
                  </a:ext>
                </a:extLst>
              </a:gridCol>
              <a:gridCol w="666667">
                <a:extLst>
                  <a:ext uri="{9D8B030D-6E8A-4147-A177-3AD203B41FA5}">
                    <a16:colId xmlns:a16="http://schemas.microsoft.com/office/drawing/2014/main" val="174610657" />
                  </a:ext>
                </a:extLst>
              </a:gridCol>
              <a:gridCol w="686561">
                <a:extLst>
                  <a:ext uri="{9D8B030D-6E8A-4147-A177-3AD203B41FA5}">
                    <a16:colId xmlns:a16="http://schemas.microsoft.com/office/drawing/2014/main" val="1811971871" />
                  </a:ext>
                </a:extLst>
              </a:gridCol>
              <a:gridCol w="697197">
                <a:extLst>
                  <a:ext uri="{9D8B030D-6E8A-4147-A177-3AD203B41FA5}">
                    <a16:colId xmlns:a16="http://schemas.microsoft.com/office/drawing/2014/main" val="966806229" />
                  </a:ext>
                </a:extLst>
              </a:gridCol>
              <a:gridCol w="839583">
                <a:extLst>
                  <a:ext uri="{9D8B030D-6E8A-4147-A177-3AD203B41FA5}">
                    <a16:colId xmlns:a16="http://schemas.microsoft.com/office/drawing/2014/main" val="3552605965" />
                  </a:ext>
                </a:extLst>
              </a:gridCol>
              <a:gridCol w="827881">
                <a:extLst>
                  <a:ext uri="{9D8B030D-6E8A-4147-A177-3AD203B41FA5}">
                    <a16:colId xmlns:a16="http://schemas.microsoft.com/office/drawing/2014/main" val="757368644" />
                  </a:ext>
                </a:extLst>
              </a:gridCol>
              <a:gridCol w="899421">
              <a:extLst>
                <a:ext uri="{9D8B030D-6E8A-4147-A177-3AD203B41FA5}">
                  <a16:colId xmlns:a16="http://schemas.microsoft.com/office/drawing/2014/main" val="2683777492" />
                </a:ext>
              </a:extLst>
            </a:gridCol>
            <a:gridCol w="838784">
            <a:extLst>
              <a:ext uri="{9D8B030D-6E8A-4147-A177-3AD203B41FA5}">
                <a16:colId xmlns:a16="http://schemas.microsoft.com/office/drawing/2014/main" val="2420668212" />
              </a:ext>
            </a:extLst>
          </a:gridCol>
          
            </a:tblGrid>
            <a:tr h="311837">
              <a:tc>
                <a:txBody>
                  <a:bodyPr />
                  <a:lstStyle />
                  <a:p>
                    <a:r>
                      <a:rPr lang="en-CA" sz="1000" dirty="0">
                        <a:solidFill>
                          <a:schemeClr val="bg1" />
                        </a:solidFill>
                        <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
                      </a:rPr>
                      <a:t>Tactic / Grouping</a:t>
                    </a:r>
                  </a:p>
                </a:txBody>
                <a:tcPr marL="7200" marR="7200" marT="7200" marB="0" anchor="ctr">
                  <a:lnL w="9525" cap="flat" cmpd="sng" algn="ctr">
                    <a:solidFill>
                      <a:schemeClr val="bg2" />
                    </a:solidFill>
                    <a:prstDash val="solid" />
                    <a:round />
                    <a:headEnd type="none" w="med" len="med" />
                    <a:tailEnd type="none" w="med" len="med" />
                  </a:lnL>
                  <a:lnR w="9525" cap="flat" cmpd="sng" algn="ctr">
                    <a:solidFill>
                      <a:schemeClr val="bg2" />
                    </a:solidFill>
                    <a:prstDash val="solid" />
                    <a:round />
                    <a:headEnd type="none" w="med" len="med" />
                    <a:tailEnd type="none" w="med" len="med" />
                  </a:lnR>
                  <a:lnT w="9525" cap="flat" cmpd="sng" algn="ctr">
                    <a:solidFill>
                      <a:schemeClr val="bg2" />
                    </a:solidFill>
                    <a:prstDash val="solid" />
                    <a:round />
                    <a:headEnd type="none" w="med" len="med" />
                    <a:tailEnd type="none" w="med" len="med" />
                  </a:lnT>
                  <a:lnB w="9525" cap="flat" cmpd="sng" algn="ctr">
                    <a:solidFill>
                      <a:schemeClr val="bg2" />
                    </a:solidFill>
                    <a:prstDash val="solid" />
                    <a:round />
                    <a:headEnd type="none" w="med" len="med" />
                    <a:tailEnd type="none" w="med" len="med" />
                  </a:lnB>
                  <a:lnTlToBr w="12700" cmpd="sng">
                    <a:noFill />
                    <a:prstDash val="solid" />
                  </a:lnTlToBr>
                  <a:lnBlToTr w="12700" cmpd="sng">
                    <a:noFill />
                    <a:prstDash val="solid" />
                  </a:lnBlToTr>
                  <a:solidFill>
                    <a:srgbClr val="06BAE6" />
                  </a:solidFill>
                </a:tcPr>
              </a:tc>
              <a:tc>
                <a:txBody>
                  <a:bodyPr />
                  <a:lstStyle />
                  <a:p>
                    <a:pPr algn="ctr" />
                    <a:r>
                      <a:rPr lang="en-CA" sz="1000" dirty="0">
                        <a:solidFill>
                          <a:schemeClr val="bg1" />
                        </a:solidFill>
                        <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
                      </a:rPr>
                      <a:t>Cost Type</a:t>
                    </a:r>
                  </a:p>
                </a:txBody>
                <a:tcPr marL="12700" marR="12700" marT="7200" marB="0" anchor="ctr">
                  <a:lnL w="9525" cap="flat" cmpd="sng" algn="ctr">
                    <a:solidFill>
                      <a:schemeClr val="bg2" />
                    </a:solidFill>
                    <a:prstDash val="solid" />
                    <a:round />
                    <a:headEnd type="none" w="med" len="med" />
                    <a:tailEnd type="none" w="med" len="med" />
                  </a:lnL>
                  <a:lnR w="12700" cap="flat" cmpd="sng" algn="ctr">
                    <a:solidFill>
                      <a:schemeClr val="bg2" />
                    </a:solidFill>
                    <a:prstDash val="solid" />
                    <a:round />
                    <a:headEnd type="none" w="med" len="med" />
                    <a:tailEnd type="none" w="med" len="med" />
                  </a:lnR>
                  <a:lnT w="9525" cap="flat" cmpd="sng" algn="ctr">
                    <a:solidFill>
                      <a:schemeClr val="bg2" />
                    </a:solidFill>
                    <a:prstDash val="solid" />
                    <a:round />
                    <a:headEnd type="none" w="med" len="med" />
                    <a:tailEnd type="none" w="med" len="med" />
                  </a:lnT>
                  <a:lnB w="9525" cap="flat" cmpd="sng" algn="ctr">
                    <a:solidFill>
                      <a:schemeClr val="bg2" />
                    </a:solidFill>
                    <a:prstDash val="solid" />
                    <a:round />
                    <a:headEnd type="none" w="med" len="med" />
                    <a:tailEnd type="none" w="med" len="med" />
                  </a:lnB>
                  <a:lnTlToBr w="12700" cmpd="sng">
                    <a:noFill />
                    <a:prstDash val="solid" />
                  </a:lnTlToBr>
                  <a:lnBlToTr w="12700" cmpd="sng">
                    <a:noFill />
                    <a:prstDash val="solid" />
                  </a:lnBlToTr>
                  <a:solidFill>
                    <a:srgbClr val="06BAE6" />
                  </a:solidFill>
                </a:tcPr>
              </a:tc>
              <a:tc>
                <a:txBody>
                  <a:bodyPr />
                  <a:lstStyle />
                  <a:p>
                    <a:pPr algn="ctr" />
                    <a:r>
                      <a:rPr lang="en-CA" sz="1000" dirty="0">
                        <a:solidFill>
                          <a:schemeClr val="bg1" />
                        </a:solidFill>
                        <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
                      </a:rPr>
                      <a:t>Contr. Quantity</a:t>
                    </a:r>
                  </a:p>
                </a:txBody>
                <a:tcPr marL="7200" marR="7200" marT="7200" marB="0" anchor="ctr">
                  <a:lnL w="9525" cap="flat" cmpd="sng" algn="ctr">
                    <a:solidFill>
                      <a:schemeClr val="bg2" />
                    </a:solidFill>
                    <a:prstDash val="solid" />
                    <a:round />
                    <a:headEnd type="none" w="med" len="med" />
                    <a:tailEnd type="none" w="med" len="med" />
                  </a:lnL>
                  <a:lnR w="9525" cap="flat" cmpd="sng" algn="ctr">
                    <a:solidFill>
                      <a:schemeClr val="bg2" />
                    </a:solidFill>
                    <a:prstDash val="solid" />
                    <a:round />
                    <a:headEnd type="none" w="med" len="med" />
                    <a:tailEnd type="none" w="med" len="med" />
                  </a:lnR>
                  <a:lnT w="9525" cap="flat" cmpd="sng" algn="ctr">
                    <a:solidFill>
                      <a:schemeClr val="bg2" />
                    </a:solidFill>
                    <a:prstDash val="solid" />
                    <a:round />
                    <a:headEnd type="none" w="med" len="med" />
                    <a:tailEnd type="none" w="med" len="med" />
                  </a:lnT>
                  <a:lnB w="9525" cap="flat" cmpd="sng" algn="ctr">
                    <a:solidFill>
                      <a:schemeClr val="bg2" />
                    </a:solidFill>
                    <a:prstDash val="solid" />
                    <a:round />
                    <a:headEnd type="none" w="med" len="med" />
                    <a:tailEnd type="none" w="med" len="med" />
                  </a:lnB>
                  <a:lnTlToBr w="12700" cmpd="sng">
                    <a:noFill />
                    <a:prstDash val="solid" />
                  </a:lnTlToBr>
                  <a:lnBlToTr w="12700" cmpd="sng">
                    <a:noFill />
                    <a:prstDash val="solid" />
                  </a:lnBlToTr>
                  <a:solidFill>
                    <a:srgbClr val="06BAE6" />
                  </a:solidFill>
                </a:tcPr>
              </a:tc>
              <a:tc>
                <a:txBody>
                  <a:bodyPr />
                  <a:lstStyle />
                  <a:p>
                    <a:pPr algn="ctr" />
                    <a:r>
                      <a:rPr lang="en-CA" sz="1000" dirty="0">
                        <a:solidFill>
                          <a:schemeClr val="bg1" />
                        </a:solidFill>
                        <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
                      </a:rPr>
                      <a:t>Deliv. Impr.</a:t>
                    </a:r>
                  </a:p>
                </a:txBody>
                <a:tcPr marL="7200" marR="7200" marT="7200" marB="0" anchor="ctr">
                  <a:lnL w="9525" cap="flat" cmpd="sng" algn="ctr">
                    <a:solidFill>
                      <a:schemeClr val="bg2" />
                    </a:solidFill>
                    <a:prstDash val="solid" />
                    <a:round />
                    <a:headEnd type="none" w="med" len="med" />
                    <a:tailEnd type="none" w="med" len="med" />
                  </a:lnL>
                  <a:lnR w="9525" cap="flat" cmpd="sng" algn="ctr">
                    <a:solidFill>
                      <a:schemeClr val="bg2" />
                    </a:solidFill>
                    <a:prstDash val="solid" />
                    <a:round />
                    <a:headEnd type="none" w="med" len="med" />
                    <a:tailEnd type="none" w="med" len="med" />
                  </a:lnR>
                  <a:lnT w="9525" cap="flat" cmpd="sng" algn="ctr">
                    <a:solidFill>
                      <a:schemeClr val="bg2" />
                    </a:solidFill>
                    <a:prstDash val="solid" />
                    <a:round />
                    <a:headEnd type="none" w="med" len="med" />
                    <a:tailEnd type="none" w="med" len="med" />
                  </a:lnT>
                  <a:lnB w="9525" cap="flat" cmpd="sng" algn="ctr">
                    <a:solidFill>
                      <a:schemeClr val="bg2" />
                    </a:solidFill>
                    <a:prstDash val="solid" />
                    <a:round />
                    <a:headEnd type="none" w="med" len="med" />
                    <a:tailEnd type="none" w="med" len="med" />
                  </a:lnB>
                  <a:lnTlToBr w="12700" cmpd="sng">
                    <a:noFill />
                    <a:prstDash val="solid" />
                  </a:lnTlToBr>
                  <a:lnBlToTr w="12700" cmpd="sng">
                    <a:noFill />
                    <a:prstDash val="solid" />
                  </a:lnBlToTr>
                  <a:solidFill>
                    <a:srgbClr val="06BAE6" />
                  </a:solidFill>
                </a:tcPr>
              </a:tc>
              <a:tc>
                <a:txBody>
                  <a:bodyPr />
                  <a:lstStyle />
                  <a:p>
                    <a:pPr algn="ctr" />
                    <a:r>
                      <a:rPr lang="en-CA" sz="1000" dirty="0">
                        <a:solidFill>
                          <a:schemeClr val="bg1" />
                        </a:solidFill>
                        <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
                      </a:rPr>
                      <a:t>Deliv. Index</a:t>
                    </a:r>
                  </a:p>
                </a:txBody>
                <a:tcPr marL="7200" marR="7200" marT="7200" marB="0" anchor="ctr">
                  <a:lnL w="9525" cap="flat" cmpd="sng" algn="ctr">
                    <a:solidFill>
                      <a:schemeClr val="bg2" />
                    </a:solidFill>
                    <a:prstDash val="solid" />
                    <a:round />
                    <a:headEnd type="none" w="med" len="med" />
                    <a:tailEnd type="none" w="med" len="med" />
                  </a:lnL>
                  <a:lnR w="9525" cap="flat" cmpd="sng" algn="ctr">
                    <a:solidFill>
                      <a:schemeClr val="bg2" />
                    </a:solidFill>
                    <a:prstDash val="solid" />
                    <a:round />
                    <a:headEnd type="none" w="med" len="med" />
                    <a:tailEnd type="none" w="med" len="med" />
                  </a:lnR>
                  <a:lnT w="9525" cap="flat" cmpd="sng" algn="ctr">
                    <a:solidFill>
                      <a:schemeClr val="bg2" />
                    </a:solidFill>
                    <a:prstDash val="solid" />
                    <a:round />
                    <a:headEnd type="none" w="med" len="med" />
                    <a:tailEnd type="none" w="med" len="med" />
                  </a:lnT>
                  <a:lnB w="9525" cap="flat" cmpd="sng" algn="ctr">
                    <a:solidFill>
                      <a:schemeClr val="bg2" />
                    </a:solidFill>
                    <a:prstDash val="solid" />
                    <a:round />
                    <a:headEnd type="none" w="med" len="med" />
                    <a:tailEnd type="none" w="med" len="med" />
                  </a:lnB>
                  <a:lnTlToBr w="12700" cmpd="sng">
                    <a:noFill />
                    <a:prstDash val="solid" />
                  </a:lnTlToBr>
                  <a:lnBlToTr w="12700" cmpd="sng">
                    <a:noFill />
                    <a:prstDash val="solid" />
                  </a:lnBlToTr>
                  <a:solidFill>
                    <a:srgbClr val="06BAE6" />
                  </a:solidFill>
                </a:tcPr>
              </a:tc>
              <a:tc>
                <a:txBody>
                  <a:bodyPr />
                  <a:lstStyle />
                  <a:p>
                    <a:pPr algn="ctr" />
                    <a:r>
                      <a:rPr lang="en-CA" sz="1000" dirty="0">
                        <a:solidFill>
                          <a:schemeClr val="bg1" />
                        </a:solidFill>
                        <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
                      </a:rPr>
                      <a:t>Clicks</a:t>
                    </a:r>
                  </a:p>
                </a:txBody>
                <a:tcPr marL="7200" marR="7200" marT="7200" marB="0" anchor="ctr">
                  <a:lnL w="9525" cap="flat" cmpd="sng" algn="ctr">
                    <a:solidFill>
                      <a:schemeClr val="bg2" />
                    </a:solidFill>
                    <a:prstDash val="solid" />
                    <a:round />
                    <a:headEnd type="none" w="med" len="med" />
                    <a:tailEnd type="none" w="med" len="med" />
                  </a:lnL>
                  <a:lnR w="9525" cap="flat" cmpd="sng" algn="ctr">
                    <a:solidFill>
                      <a:schemeClr val="bg2" />
                    </a:solidFill>
                    <a:prstDash val="solid" />
                    <a:round />
                    <a:headEnd type="none" w="med" len="med" />
                    <a:tailEnd type="none" w="med" len="med" />
                  </a:lnR>
                  <a:lnT w="9525" cap="flat" cmpd="sng" algn="ctr">
                    <a:solidFill>
                      <a:schemeClr val="bg2" />
                    </a:solidFill>
                    <a:prstDash val="solid" />
                    <a:round />
                    <a:headEnd type="none" w="med" len="med" />
                    <a:tailEnd type="none" w="med" len="med" />
                  </a:lnT>
                  <a:lnB w="9525" cap="flat" cmpd="sng" algn="ctr">
                    <a:solidFill>
                      <a:schemeClr val="bg2" />
                    </a:solidFill>
                    <a:prstDash val="solid" />
                    <a:round />
                    <a:headEnd type="none" w="med" len="med" />
                    <a:tailEnd type="none" w="med" len="med" />
                  </a:lnB>
                  <a:lnTlToBr w="12700" cmpd="sng">
                    <a:noFill />
                    <a:prstDash val="solid" />
                  </a:lnTlToBr>
                  <a:lnBlToTr w="12700" cmpd="sng">
                    <a:noFill />
                    <a:prstDash val="solid" />
                  </a:lnBlToTr>
                  <a:solidFill>
                    <a:srgbClr val="06BAE6" />
                  </a:solidFill>
                </a:tcPr>
              </a:tc>
              <a:tc>
                <a:txBody>
                  <a:bodyPr />
                  <a:lstStyle />
                  <a:p>
                    <a:pPr algn="ctr" />
                    <a:r>
                      <a:rPr lang="en-CA" sz="1000" dirty="0">
                        <a:solidFill>
                          <a:schemeClr val="bg1" />
                        </a:solidFill>
                        <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
                      </a:rPr>
                      <a:t>CTR</a:t>
                    </a:r>
                  </a:p>
                </a:txBody>
                <a:tcPr marL="7200" marR="7200" marT="7200" marB="0" anchor="ctr">
                  <a:lnL w="9525" cap="flat" cmpd="sng" algn="ctr">
                    <a:solidFill>
                      <a:schemeClr val="bg2" />
                    </a:solidFill>
                    <a:prstDash val="solid" />
                    <a:round />
                    <a:headEnd type="none" w="med" len="med" />
                    <a:tailEnd type="none" w="med" len="med" />
                  </a:lnL>
                  <a:lnR w="9525" cap="flat" cmpd="sng" algn="ctr">
                    <a:solidFill>
                      <a:schemeClr val="bg2" />
                    </a:solidFill>
                    <a:prstDash val="solid" />
                    <a:round />
                    <a:headEnd type="none" w="med" len="med" />
                    <a:tailEnd type="none" w="med" len="med" />
                  </a:lnR>
                  <a:lnT w="9525" cap="flat" cmpd="sng" algn="ctr">
                    <a:solidFill>
                      <a:schemeClr val="bg2" />
                    </a:solidFill>
                    <a:prstDash val="solid" />
                    <a:round />
                    <a:headEnd type="none" w="med" len="med" />
                    <a:tailEnd type="none" w="med" len="med" />
                  </a:lnT>
                  <a:lnB w="9525" cap="flat" cmpd="sng" algn="ctr">
                    <a:solidFill>
                      <a:schemeClr val="bg2" />
                    </a:solidFill>
                    <a:prstDash val="solid" />
                    <a:round />
                    <a:headEnd type="none" w="med" len="med" />
                    <a:tailEnd type="none" w="med" len="med" />
                  </a:lnB>
                  <a:lnTlToBr w="12700" cmpd="sng">
                    <a:noFill />
                    <a:prstDash val="solid" />
                  </a:lnTlToBr>
                  <a:lnBlToTr w="12700" cmpd="sng">
                    <a:noFill />
                    <a:prstDash val="solid" />
                  </a:lnBlToTr>
                  <a:solidFill>
                    <a:srgbClr val="06BAE6" />
                  </a:solidFill>
                </a:tcPr>
              </a:tc>
              <a:tc>
                <a:txBody>
                  <a:bodyPr />
                  <a:lstStyle />
                  <a:p>
                    <a:pPr algn="ctr" />
                    <a:r>
                      <a:rPr lang="en-CA" sz="1000" dirty="0">
                        <a:solidFill>
                          <a:schemeClr val="bg1" />
                        </a:solidFill>
                        <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
                      </a:rPr>
                      <a:t>Starts</a:t>
                    </a:r>
                  </a:p>
                </a:txBody>
                <a:tcPr marL="7200" marR="7200" marT="7200" marB="0" anchor="ctr">
                  <a:lnL w="9525" cap="flat" cmpd="sng" algn="ctr">
                    <a:solidFill>
                      <a:schemeClr val="bg2" />
                    </a:solidFill>
                    <a:prstDash val="solid" />
                    <a:round />
                    <a:headEnd type="none" w="med" len="med" />
                    <a:tailEnd type="none" w="med" len="med" />
                  </a:lnL>
                  <a:lnR w="9525" cap="flat" cmpd="sng" algn="ctr">
                    <a:solidFill>
                      <a:schemeClr val="bg2" />
                    </a:solidFill>
                    <a:prstDash val="solid" />
                    <a:round />
                    <a:headEnd type="none" w="med" len="med" />
                    <a:tailEnd type="none" w="med" len="med" />
                  </a:lnR>
                  <a:lnT w="9525" cap="flat" cmpd="sng" algn="ctr">
                    <a:solidFill>
                      <a:schemeClr val="bg2" />
                    </a:solidFill>
                    <a:prstDash val="solid" />
                    <a:round />
                    <a:headEnd type="none" w="med" len="med" />
                    <a:tailEnd type="none" w="med" len="med" />
                  </a:lnT>
                  <a:lnB w="9525" cap="flat" cmpd="sng" algn="ctr">
                    <a:solidFill>
                      <a:schemeClr val="bg2" />
                    </a:solidFill>
                    <a:prstDash val="solid" />
                    <a:round />
                    <a:headEnd type="none" w="med" len="med" />
                    <a:tailEnd type="none" w="med" len="med" />
                  </a:lnB>
                  <a:lnTlToBr w="12700" cmpd="sng">
                    <a:noFill />
                    <a:prstDash val="solid" />
                  </a:lnTlToBr>
                  <a:lnBlToTr w="12700" cmpd="sng">
                    <a:noFill />
                    <a:prstDash val="solid" />
                  </a:lnBlToTr>
                  <a:solidFill>
                    <a:srgbClr val="06BAE6" />
                  </a:solidFill>
                </a:tcPr>
              </a:tc>
              <a:tc>
                <a:txBody>
                  <a:bodyPr />
                  <a:lstStyle />
                  <a:p>
                    <a:pPr algn="ctr" />
                    <a:r>
                      <a:rPr lang="en-CA" sz="1000" dirty="0">
                        <a:solidFill>
                          <a:schemeClr val="bg1" />
                        </a:solidFill>
                        <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
                      </a:rPr>
                      <a:t>Compl.</a:t>
                    </a:r>
                  </a:p>
                </a:txBody>
                <a:tcPr marL="7200" marR="7200" marT="7200" marB="0" anchor="ctr">
                  <a:lnL w="9525" cap="flat" cmpd="sng" algn="ctr">
                    <a:solidFill>
                      <a:schemeClr val="bg2" />
                    </a:solidFill>
                    <a:prstDash val="solid" />
                    <a:round />
                    <a:headEnd type="none" w="med" len="med" />
                    <a:tailEnd type="none" w="med" len="med" />
                  </a:lnL>
                  <a:lnR w="9525" cap="flat" cmpd="sng" algn="ctr">
                    <a:solidFill>
                      <a:schemeClr val="bg2" />
                    </a:solidFill>
                    <a:prstDash val="solid" />
                    <a:round />
                    <a:headEnd type="none" w="med" len="med" />
                    <a:tailEnd type="none" w="med" len="med" />
                  </a:lnR>
                  <a:lnT w="9525" cap="flat" cmpd="sng" algn="ctr">
                    <a:solidFill>
                      <a:schemeClr val="bg2" />
                    </a:solidFill>
                    <a:prstDash val="solid" />
                    <a:round />
                    <a:headEnd type="none" w="med" len="med" />
                    <a:tailEnd type="none" w="med" len="med" />
                  </a:lnT>
                  <a:lnB w="9525" cap="flat" cmpd="sng" algn="ctr">
                    <a:solidFill>
                      <a:schemeClr val="bg2" />
                    </a:solidFill>
                    <a:prstDash val="solid" />
                    <a:round />
                    <a:headEnd type="none" w="med" len="med" />
                    <a:tailEnd type="none" w="med" len="med" />
                  </a:lnB>
                  <a:lnTlToBr w="12700" cmpd="sng">
                    <a:noFill />
                    <a:prstDash val="solid" />
                  </a:lnTlToBr>
                  <a:lnBlToTr w="12700" cmpd="sng">
                    <a:noFill />
                    <a:prstDash val="solid" />
                  </a:lnBlToTr>
                  <a:solidFill>
                    <a:srgbClr val="06BAE6" />
                  </a:solidFill>
                </a:tcPr>
              </a:tc>
              <a:tc>
                <a:txBody>
                  <a:bodyPr />
                  <a:lstStyle />
                  <a:p>
                    <a:pPr algn="ctr" />
                    <a:r>
                      <a:rPr lang="en-CA" sz="1000" dirty="0">
                        <a:solidFill>
                          <a:schemeClr val="bg1" />
                        </a:solidFill>
                        <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
                      </a:rPr>
                      <a:t>Compl. Rate</a:t>
                    </a:r>
                  </a:p>
                </a:txBody>
                <a:tcPr marL="7200" marR="7200" marT="7200" marB="0" anchor="ctr">
                  <a:lnL w="9525" cap="flat" cmpd="sng" algn="ctr">
                    <a:solidFill>
                      <a:schemeClr val="bg2" />
                    </a:solidFill>
                    <a:prstDash val="solid" />
                    <a:round />
                    <a:headEnd type="none" w="med" len="med" />
                    <a:tailEnd type="none" w="med" len="med" />
                  </a:lnL>
                  <a:lnR w="9525" cap="flat" cmpd="sng" algn="ctr">
                    <a:solidFill>
                      <a:schemeClr val="bg2" />
                    </a:solidFill>
                    <a:prstDash val="solid" />
                    <a:round />
                    <a:headEnd type="none" w="med" len="med" />
                    <a:tailEnd type="none" w="med" len="med" />
                  </a:lnR>
                  <a:lnT w="9525" cap="flat" cmpd="sng" algn="ctr">
                    <a:solidFill>
                      <a:schemeClr val="bg2" />
                    </a:solidFill>
                    <a:prstDash val="solid" />
                    <a:round />
                    <a:headEnd type="none" w="med" len="med" />
                    <a:tailEnd type="none" w="med" len="med" />
                  </a:lnT>
                  <a:lnB w="9525" cap="flat" cmpd="sng" algn="ctr">
                    <a:solidFill>
                      <a:schemeClr val="bg2" />
                    </a:solidFill>
                    <a:prstDash val="solid" />
                    <a:round />
                    <a:headEnd type="none" w="med" len="med" />
                    <a:tailEnd type="none" w="med" len="med" />
                  </a:lnB>
                  <a:lnTlToBr w="12700" cmpd="sng">
                    <a:noFill />
                    <a:prstDash val="solid" />
                  </a:lnTlToBr>
                  <a:lnBlToTr w="12700" cmpd="sng">
                    <a:noFill />
                    <a:prstDash val="solid" />
                  </a:lnBlToTr>
                  <a:solidFill>
                    <a:srgbClr val="06BAE6" />
                  </a:solidFill>
                </a:tcPr>
              </a:tc>
              <a:tc>
                <a:txBody>
                  <a:bodyPr />
                  <a:lstStyle />
                  <a:p>
                    <a:pPr algn="ctr" />
                    <a:r>
                      <a:rPr lang="en-CA" sz="1000" dirty="0">
                        <a:solidFill>
                          <a:schemeClr val="bg1" />
                        </a:solidFill>
                        <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
                      </a:rPr>
                      <a:t>Engagements</a:t>
                    </a:r>
                  </a:p>
                </a:txBody>
                <a:tcPr marL="7200" marR="7200" marT="7200" marB="0" anchor="ctr">
                  <a:lnL w="9525" cap="flat" cmpd="sng" algn="ctr">
                    <a:solidFill>
                      <a:schemeClr val="bg2" />
                    </a:solidFill>
                    <a:prstDash val="solid" />
                    <a:round />
                    <a:headEnd type="none" w="med" len="med" />
                    <a:tailEnd type="none" w="med" len="med" />
                  </a:lnL>
                  <a:lnR w="9525" cap="flat" cmpd="sng" algn="ctr">
                    <a:solidFill>
                      <a:schemeClr val="bg2" />
                    </a:solidFill>
                    <a:prstDash val="solid" />
                    <a:round />
                    <a:headEnd type="none" w="med" len="med" />
                    <a:tailEnd type="none" w="med" len="med" />
                  </a:lnR>
                  <a:lnT w="9525" cap="flat" cmpd="sng" algn="ctr">
                    <a:solidFill>
                      <a:schemeClr val="bg2" />
                    </a:solidFill>
                    <a:prstDash val="solid" />
                    <a:round />
                    <a:headEnd type="none" w="med" len="med" />
                    <a:tailEnd type="none" w="med" len="med" />
                  </a:lnT>
                  <a:lnB w="9525" cap="flat" cmpd="sng" algn="ctr">
                    <a:solidFill>
                      <a:schemeClr val="bg2" />
                    </a:solidFill>
                    <a:prstDash val="solid" />
                    <a:round />
                    <a:headEnd type="none" w="med" len="med" />
                    <a:tailEnd type="none" w="med" len="med" />
                  </a:lnB>
                  <a:lnTlToBr w="12700" cmpd="sng">
                    <a:noFill />
                    <a:prstDash val="solid" />
                  </a:lnTlToBr>
                  <a:lnBlToTr w="12700" cmpd="sng">
                    <a:noFill />
                    <a:prstDash val="solid" />
                  </a:lnBlToTr>
                  <a:solidFill>
                    <a:srgbClr val="06BAE6" />
                  </a:solidFill>
                </a:tcPr>
              </a:tc>
              <a:tc>
                <a:txBody>
                  <a:bodyPr />
                  <a:lstStyle />
                  <a:p>
                    <a:pPr algn="ctr" />
                    <a:r>
                      <a:rPr lang="en-CA" sz="1000" dirty="0">
                        <a:solidFill>
                          <a:schemeClr val="bg1" />
                        </a:solidFill>
                        <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
                      </a:rPr>
                      <a:t>Eng. Rate</a:t>
                    </a:r>
                  </a:p>
                </a:txBody>
                <a:tcPr marL="7200" marR="7200" marT="7200" marB="0" anchor="ctr">
                  <a:lnL w="9525" cap="flat" cmpd="sng" algn="ctr">
                    <a:solidFill>
                      <a:schemeClr val="bg2" />
                    </a:solidFill>
                    <a:prstDash val="solid" />
                    <a:round />
                    <a:headEnd type="none" w="med" len="med" />
                    <a:tailEnd type="none" w="med" len="med" />
                  </a:lnL>
                  <a:lnR w="9525" cap="flat" cmpd="sng" algn="ctr">
                    <a:solidFill>
                      <a:schemeClr val="bg2" />
                    </a:solidFill>
                    <a:prstDash val="solid" />
                    <a:round />
                    <a:headEnd type="none" w="med" len="med" />
                    <a:tailEnd type="none" w="med" len="med" />
                  </a:lnR>
                  <a:lnT w="9525" cap="flat" cmpd="sng" algn="ctr">
                    <a:solidFill>
                      <a:schemeClr val="bg2" />
                    </a:solidFill>
                    <a:prstDash val="solid" />
                    <a:round />
                    <a:headEnd type="none" w="med" len="med" />
                    <a:tailEnd type="none" w="med" len="med" />
                  </a:lnT>
                  <a:lnB w="9525" cap="flat" cmpd="sng" algn="ctr">
                    <a:solidFill>
                      <a:schemeClr val="bg2" />
                    </a:solidFill>
                    <a:prstDash val="solid" />
                    <a:round />
                    <a:headEnd type="none" w="med" len="med" />
                    <a:tailEnd type="none" w="med" len="med" />
                  </a:lnB>
                  <a:lnTlToBr w="12700" cmpd="sng">
                    <a:noFill />
                    <a:prstDash val="solid" />
                  </a:lnTlToBr>
                  <a:lnBlToTr w="12700" cmpd="sng">
                    <a:noFill />
                    <a:prstDash val="solid" />
                  </a:lnBlToTr>
                  <a:solidFill>
                    <a:srgbClr val="06BAE6" />
                  </a:solidFill>
                </a:tcPr>
              </a:tc>
              <a:tc>
              <a:txBody>
                <a:bodyPr />
                <a:lstStyle />
                <a:p>
                  <a:pPr algn="ctr" />
                  <a:r>
                    <a:rPr lang="en-CA" sz="1000" dirty="0">
                      <a:solidFill>
                        <a:schemeClr val="bg1" />
                      </a:solidFill>
                      <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
                    </a:rPr>
                    <a:t>Contr. Spend</a:t>
                  </a:r>
                </a:p>
              </a:txBody>
              <a:tcPr marL="7200" marR="7200" marT="7200" marB="0" anchor="ctr">
                <a:lnL w="9525" cap="flat" cmpd="sng" algn="ctr">
                  <a:solidFill>
                    <a:schemeClr val="bg2" />
                  </a:solidFill>
                  <a:prstDash val="solid" />
                  <a:round />
                  <a:headEnd type="none" w="med" len="med" />
                  <a:tailEnd type="none" w="med" len="med" />
                </a:lnL>
                <a:lnR w="9525" cap="flat" cmpd="sng" algn="ctr">
                  <a:solidFill>
                    <a:schemeClr val="bg2" />
                  </a:solidFill>
                  <a:prstDash val="solid" />
                  <a:round />
                  <a:headEnd type="none" w="med" len="med" />
                  <a:tailEnd type="none" w="med" len="med" />
                </a:lnR>
                <a:lnT w="9525" cap="flat" cmpd="sng" algn="ctr">
                  <a:solidFill>
                    <a:schemeClr val="bg2" />
                  </a:solidFill>
                  <a:prstDash val="solid" />
                  <a:round />
                  <a:headEnd type="none" w="med" len="med" />
                  <a:tailEnd type="none" w="med" len="med" />
                </a:lnT>
                <a:lnB w="9525" cap="flat" cmpd="sng" algn="ctr">
                  <a:solidFill>
                    <a:schemeClr val="bg2" />
                  </a:solidFill>
                  <a:prstDash val="solid" />
                  <a:round />
                  <a:headEnd type="none" w="med" len="med" />
                  <a:tailEnd type="none" w="med" len="med" />
                </a:lnB>
                <a:lnTlToBr w="12700" cmpd="sng">
                  <a:noFill />
                  <a:prstDash val="solid" />
                </a:lnTlToBr>
                <a:lnBlToTr w="12700" cmpd="sng">
                  <a:noFill />
                  <a:prstDash val="solid" />
                </a:lnBlToTr>
                <a:solidFill>
                  <a:srgbClr val="06BAE6" />
                </a:solidFill>
              </a:tcPr>
            </a:tc>
            <a:tc>
            <a:txBody>
              <a:bodyPr />
              <a:lstStyle />
              <a:p>
                <a:pPr algn="ctr" />
                <a:r>
                  <a:rPr lang="en-CA" sz="1000" dirty="0">
                    <a:solidFill>
                      <a:schemeClr val="bg1" />
                    </a:solidFill>
                    <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
                  </a:rPr>
                  <a:t>Actual Spend</a:t>
                </a:r>
              </a:p>
            </a:txBody>
            <a:tcPr marL="7200" marR="7200" marT="7200" marB="0" anchor="ctr">
              <a:lnL w="9525" cap="flat" cmpd="sng" algn="ctr">
                <a:solidFill>
                  <a:schemeClr val="bg2" />
                </a:solidFill>
                <a:prstDash val="solid" />
                <a:round />
                <a:headEnd type="none" w="med" len="med" />
                <a:tailEnd type="none" w="med" len="med" />
              </a:lnL>
              <a:lnR w="9525" cap="flat" cmpd="sng" algn="ctr">
                <a:solidFill>
                  <a:schemeClr val="bg2" />
                </a:solidFill>
                <a:prstDash val="solid" />
                <a:round />
                <a:headEnd type="none" w="med" len="med" />
                <a:tailEnd type="none" w="med" len="med" />
              </a:lnR>
              <a:lnT w="9525" cap="flat" cmpd="sng" algn="ctr">
                <a:solidFill>
                  <a:schemeClr val="bg2" />
                </a:solidFill>
                <a:prstDash val="solid" />
                <a:round />
                <a:headEnd type="none" w="med" len="med" />
                <a:tailEnd type="none" w="med" len="med" />
              </a:lnT>
              <a:lnB w="9525" cap="flat" cmpd="sng" algn="ctr">
                <a:solidFill>
                  <a:schemeClr val="bg2" />
                </a:solidFill>
                <a:prstDash val="solid" />
                <a:round />
                <a:headEnd type="none" w="med" len="med" />
                <a:tailEnd type="none" w="med" len="med" />
              </a:lnB>
              <a:lnTlToBr w="12700" cmpd="sng">
                <a:noFill />
                <a:prstDash val="solid" />
              </a:lnTlToBr>
              <a:lnBlToTr w="12700" cmpd="sng">
                <a:noFill />
                <a:prstDash val="solid" />
              </a:lnBlToTr>
              <a:solidFill>
                <a:srgbClr val="06BAE6" />
              </a:solidFill>
            </a:tcPr>
          </a:tc>
              <a:extLst>
                <a:ext uri="{0D108BD9-81ED-4DB2-BD59-A6C34878D82A}">
                  <a16:rowId xmlns:a16="http://schemas.microsoft.com/office/drawing/2014/main" val="2015614021" />
                </a:ext>
              </a:extLst>
            </a:tr>
          </a:tbl>
        </a:graphicData>
      </a:graphic>
    </p:graphicFrame>        
      `.trim();
    const doc = new DOMParser().parseFromString(xml, "application/xml");
    const newNode = this.content.importNode(doc.documentElement, true);
    parent.appendChild(newNode);

    if (parent.ownerDocument) {
      if (!includeCtr) {
        const gridNodes = this.content.evaluate(
          "/p:sld/p:cSld/p:spTree/p:graphicFrame/a:graphic/a:graphicData/a:tbl/a:tblGrid/a:gridCol[position()=7]",
          this.content,
          this.resolver,
          XPathResult.ORDERED_NODE_ITERATOR_TYPE,
          null
        );
        const gridNode = gridNodes.iterateNext();
        if (gridNode && gridNode.parentNode) {
          gridNode.parentNode.removeChild(gridNode);
        }
        const colNodes = this.content.evaluate(
          "/p:sld/p:cSld/p:spTree/p:graphicFrame/a:graphic/a:graphicData/a:tbl/a:tr/a:tc[position()=7]",
          this.content,
          this.resolver,
          XPathResult.ORDERED_NODE_ITERATOR_TYPE,
          null
        );
        const colNode = colNodes.iterateNext();
        if (colNode && colNode.parentNode) {
          colNode.parentNode.removeChild(colNode);
        }
      }
      if (!includeConversions) {
        const colIdx = includeCtr ? "10" : "9";
        const gridNodes = this.content.evaluate(
          "/p:sld/p:cSld/p:spTree/p:graphicFrame/a:graphic/a:graphicData/a:tbl/a:tblGrid/a:gridCol[position()=" +
            colIdx +
            "]",
          this.content,
          this.resolver,
          XPathResult.ORDERED_NODE_ITERATOR_TYPE,
          null
        );
        const gridNode = gridNodes.iterateNext();
        if (gridNode && gridNode.parentNode) {
          gridNode.parentNode.removeChild(gridNode);
        }
        const colNodes = this.content.evaluate(
          "/p:sld/p:cSld/p:spTree/p:graphicFrame/a:graphic/a:graphicData/a:tbl/a:tr/a:tc[position()=" +
            colIdx +
            "]",
          this.content,
          this.resolver,
          XPathResult.ORDERED_NODE_ITERATOR_TYPE,
          null
        );
        const colNode = colNodes.iterateNext();
        if (colNode && colNode.parentNode) {
          colNode.parentNode.removeChild(colNode);
        }
      }
      const tblXpath =
        "/p:sld/p:cSld/p:spTree/p:graphicFrame/a:graphic/a:graphicData/a:tbl";
      const tableNode = parent.ownerDocument.evaluate(
        tblXpath,
        newNode,
        this.resolver,
        XPathResult.FIRST_ORDERED_NODE_TYPE,
        null
      ).singleNodeValue;
      if (tableNode != null) {
        data.forEach((line) => {
          var rowNode = this.generateLineItemTemplateForToplineSumm();
          tableNode.appendChild(rowNode);
          //change this TBD
          this.replaceNodeText(
            parent.ownerDocument!,
            rowNode,
            "TACTICGROUPING",
            line.tactics
          );
          this.replaceNodeText(
            parent.ownerDocument!,
            rowNode,
            "COSTTYPE",
            line.costType
          );
          this.replaceNodeText(
            parent.ownerDocument!,
            rowNode,
            "CONTRACTEDQUANTITY",
            line.contractedQuantity > 0
              ? Conversions.numberWithCommas(line.contractedQuantity, 0)
              : ""
          );
          this.replaceNodeText(
            parent.ownerDocument!,
            rowNode,
            "DELIVEREDIMPRESSION",
            line.deliveredImpressions > 0
              ? Conversions.numberWithCommas(line.deliveredImpressions, 0)
              : ""
          );
          this.replaceNodeText(
            parent.ownerDocument!,
            rowNode,
            "DELIVEREDINDEX",
            line.deliverdIndex > 0
              ? Conversions.numberWithCommas(
                  line.deliverdIndex * 100.0,
                  0
                ).toString() + "%"
              : ""
          );
          this.replaceNodeText(
            parent.ownerDocument!,
            rowNode,
            "CLICKS",
            line.clicks > 0 ? Conversions.numberWithCommas(line.clicks, 0) : ""
          );
          this.replaceNodeText(
            parent.ownerDocument!,
            rowNode,
            "CONTRATIO",
            line.deliveredImpressions === 0
              ? ""
              : Conversions.numberWithCommas(
                  (line.clicks / line.deliveredImpressions) * 100.0,
                  2
                ).toString() + "%"
          );
          this.replaceNodeText(
            parent.ownerDocument!,
            rowNode,
            "STARTS",
            line.starts > 0 ? Conversions.numberWithCommas(line.starts, 0) : ""
          );

          this.replaceNodeText(
            parent.ownerDocument!,
            rowNode,
            "COMPLITION",
            line.complition > 0
              ? Conversions.numberWithCommas(line.complition, 0)
              : ""
          );

          this.replaceNodeText(
            parent.ownerDocument!,
            rowNode,
            "COMPLITIONRATE",
            line.starts <= 0
              ? ""
              : Conversions.numberWithCommas(
                  (line.complition / line.starts) * 100.0,
                  2
                ).toString() + "%"
          );
          this.replaceNodeText(
            parent.ownerDocument!,
            rowNode,
            "ENGAGEMENTS",
            line.engagement > 0
              ? Conversions.numberWithCommas(line.engagement, 0)
              : ""
          );
          let engRate =
            line.deliveredImpressions > 0
              ? (line.engagement / line.deliveredImpressions) * 100
              : 0;
          this.replaceNodeText(
            parent.ownerDocument!,
            rowNode,
            "ENGAGEMENTRATE",
            line.deliveredImpressions <= 0 || engRate <= 0
              ? ""
              : Conversions.numberWithCommas(
                  (line.engagement / line.deliveredImpressions) * 100.0,
                  2
                ).toString() + "%"
          );
          this.replaceNodeText(
            parent.ownerDocument!,
            rowNode,
            "CONTRACTEDSPEND",
            line.contractedSpend > 0
              ? "$" + Conversions.numberWithCommas(line.contractedSpend, 0)
              : ""
          );
          this.replaceNodeText(
            parent.ownerDocument!,
            rowNode,
            "ACTUALSPEND",
            line.actualSpend > 0
              ? "$" + Conversions.numberWithCommas(line.actualSpend, 0)
              : ""
          );
        });
        if (footer) {
          this.appendToplineSummaryFooter(
            footer,
            includeCtr,
            includeConversions,
            parent.ownerDocument!,
            tableNode
          );
        }
      }
    }
  }

  appendOrderLineItemTable(
    data: DomoLineItem[],
    includeCtr: boolean,
    includeConversions: boolean,
    parent: Node,
    fullPage: boolean,
    footer: OrderLineTotals | null
  ) {
    const top = fullPage ? "1752944" : "3125747";
    const left = "771526";
    const width = "10420350";
    const height = fullPage ? "2310640" : "1310640";
    var nameGap = 0;
    if (includeCtr) nameGap += 665686;
    if (includeConversions) nameGap += 665686;
    const nameWidth = (4566383 - nameGap).toString(); // includeCtr ? '3235011' : '3900697'  // CTR width = 665686

    const xml =
      `
        <p:graphicFrame xmlns:p="http://schemas.openxmlformats.org/presentationml/2006/main">
        <p:nvGraphicFramePr>
          <p:cNvPr id="3" name="Table 3">
            <a:extLst xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
              <a:ext uri="{FF2B5EF4-FFF2-40B4-BE49-F238E27FC236}">
                <a16:creationId xmlns:a16="http://schemas.microsoft.com/office/drawing/2014/main" id="{10C436C0-F348-4735-9B01-FBA24F76123A}" />
              </a:ext>
            </a:extLst>
          </p:cNvPr>
          <p:cNvGraphicFramePr>
            <a:graphicFrameLocks noGrp="1" xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main" />
          </p:cNvGraphicFramePr>
          <p:nvPr>
          </p:nvPr>
        </p:nvGraphicFramePr>
        <p:xfrm>
          <a:off x="` +
      left +
      `" y="` +
      top +
      `" xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main" />
          <a:ext cx="` +
      width +
      `" cy="` +
      height +
      `" xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main" />
        </p:xfrm>
        <a:graphic xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
          <a:graphicData uri="http://schemas.openxmlformats.org/drawingml/2006/table">
            <a:tbl>
              <a:tblPr firstRow="1" bandRow="1">
                <a:tableStyleId>{2D5ABB26-0587-4C30-8999-92F81FD0307C}</a:tableStyleId>
              </a:tblPr>
              <a:tblGrid>
                <a:gridCol w="` +
      nameWidth +
      `">
                  <a:extLst>
                    <a:ext uri="{9D8B030D-6E8A-4147-A177-3AD203B41FA5}">
                      <a16:colId xmlns:a16="http://schemas.microsoft.com/office/drawing/2014/main" val="3878221376" />
                    </a:ext>
                  </a:extLst>
                </a:gridCol>
                <a:gridCol w="742567">
                  <a:extLst>
                    <a:ext uri="{9D8B030D-6E8A-4147-A177-3AD203B41FA5}">
                      <a16:colId xmlns:a16="http://schemas.microsoft.com/office/drawing/2014/main" val="820552669" />
                    </a:ext>
                  </a:extLst>
                </a:gridCol>
                <a:gridCol w="742567">
                  <a:extLst>
                    <a:ext uri="{9D8B030D-6E8A-4147-A177-3AD203B41FA5}">
                      <a16:colId xmlns:a16="http://schemas.microsoft.com/office/drawing/2014/main" val="3710878327" />
                    </a:ext>
                  </a:extLst>
                </a:gridCol>
                <a:gridCol w="673810">
                  <a:extLst>
                    <a:ext uri="{9D8B030D-6E8A-4147-A177-3AD203B41FA5}">
                      <a16:colId xmlns:a16="http://schemas.microsoft.com/office/drawing/2014/main" val="1588051974" />
                    </a:ext>
                  </a:extLst>
                </a:gridCol>
                <a:gridCol w="571926">
                  <a:extLst>
                    <a:ext uri="{9D8B030D-6E8A-4147-A177-3AD203B41FA5}">
                      <a16:colId xmlns:a16="http://schemas.microsoft.com/office/drawing/2014/main" val="3043513024" />
                    </a:ext>
                  </a:extLst>
                </a:gridCol>
                <a:gridCol w="684530">
                  <a:extLst>
                    <a:ext uri="{9D8B030D-6E8A-4147-A177-3AD203B41FA5}">
                      <a16:colId xmlns:a16="http://schemas.microsoft.com/office/drawing/2014/main" val="1857458189" />
                    </a:ext>
                  </a:extLst>
                </a:gridCol>
                <a:gridCol w="665686">
                  <a:extLst>
                    <a:ext uri="{9D8B030D-6E8A-4147-A177-3AD203B41FA5}">
                      <a16:colId xmlns:a16="http://schemas.microsoft.com/office/drawing/2014/main" val="2902855428" />
                    </a:ext>
                  </a:extLst>
                </a:gridCol>
                <a:gridCol w="528485">
                  <a:extLst>
                    <a:ext uri="{9D8B030D-6E8A-4147-A177-3AD203B41FA5}">
                      <a16:colId xmlns:a16="http://schemas.microsoft.com/office/drawing/2014/main" val="2884151954" />
                    </a:ext>
                  </a:extLst>
                </a:gridCol>
                <a:gridCol w="665686">
                  <a:extLst>
                    <a:ext uri="{9D8B030D-6E8A-4147-A177-3AD203B41FA5}">
                      <a16:colId xmlns:a16="http://schemas.microsoft.com/office/drawing/2014/main" val="1047418845" />
                    </a:ext>
                  </a:extLst>
                </a:gridCol>
                <a:gridCol w="470668">
                  <a:extLst>
                    <a:ext uri="{9D8B030D-6E8A-4147-A177-3AD203B41FA5}">
                      <a16:colId xmlns:a16="http://schemas.microsoft.com/office/drawing/2014/main" val="1047419945" />
                    </a:ext>
                  </a:extLst>
                </a:gridCol>
                <a:gridCol w="768820">
                  <a:extLst>
                    <a:ext uri="{9D8B030D-6E8A-4147-A177-3AD203B41FA5}">
                      <a16:colId xmlns:a16="http://schemas.microsoft.com/office/drawing/2014/main" val="190214256" />
                    </a:ext>
                  </a:extLst>
                </a:gridCol>
                <a:gridCol w="806325">
                  <a:extLst>
                    <a:ext uri="{9D8B030D-6E8A-4147-A177-3AD203B41FA5}">
                      <a16:colId xmlns:a16="http://schemas.microsoft.com/office/drawing/2014/main" val="1198832991" />
                    </a:ext>
                  </a:extLst>
                </a:gridCol>
              </a:tblGrid>
              <a:tr h="204890">
                <a:tc>
                  <a:txBody>
                    <a:bodyPr />
                    <a:lstStyle />
                    <a:p>
                      <a:r>
                        <a:rPr lang="en-CA" sz="800" dirty="0">
                          <a:solidFill>
                            <a:schemeClr val="bg1" />
                          </a:solidFill>
                          <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
                        </a:rPr>
                        <a:t>Line Item</a:t>
                      </a:r>
                    </a:p>
                  </a:txBody>
                  <a:tcPr>
                    <a:lnL w="9525" cap="flat" cmpd="sng" algn="ctr">
                      <a:solidFill>
                        <a:schemeClr val="bg2" />
                      </a:solidFill>
                      <a:prstDash val="solid" />
                      <a:round />
                      <a:headEnd type="none" w="med" len="med" />
                      <a:tailEnd type="none" w="med" len="med" />
                    </a:lnL>
                    <a:lnR w="9525" cap="flat" cmpd="sng" algn="ctr">
                      <a:solidFill>
                        <a:schemeClr val="bg2" />
                      </a:solidFill>
                      <a:prstDash val="solid" />
                      <a:round />
                      <a:headEnd type="none" w="med" len="med" />
                      <a:tailEnd type="none" w="med" len="med" />
                    </a:lnR>
                    <a:lnT w="9525" cap="flat" cmpd="sng" algn="ctr">
                      <a:solidFill>
                        <a:schemeClr val="bg2" />
                      </a:solidFill>
                      <a:prstDash val="solid" />
                      <a:round />
                      <a:headEnd type="none" w="med" len="med" />
                      <a:tailEnd type="none" w="med" len="med" />
                    </a:lnT>
                    <a:lnB w="9525" cap="flat" cmpd="sng" algn="ctr">
                      <a:solidFill>
                        <a:schemeClr val="bg2" />
                      </a:solidFill>
                      <a:prstDash val="solid" />
                      <a:round />
                      <a:headEnd type="none" w="med" len="med" />
                      <a:tailEnd type="none" w="med" len="med" />
                    </a:lnB>
                    <a:lnTlToBr w="12700" cmpd="sng">
                      <a:noFill />
                      <a:prstDash val="solid" />
                    </a:lnTlToBr>
                    <a:lnBlToTr w="12700" cmpd="sng">
                      <a:noFill />
                      <a:prstDash val="solid" />
                    </a:lnBlToTr>
                    <a:solidFill>
                      <a:srgbClr val="06BAE6" />
                    </a:solidFill>
                  </a:tcPr>
                </a:tc>
                <a:tc>
                  <a:txBody>
                    <a:bodyPr />
                    <a:lstStyle />
                    <a:p>
                      <a:pPr algn="ctr" />
                      <a:r>
                        <a:rPr lang="en-CA" sz="800" dirty="0">
                          <a:solidFill>
                            <a:schemeClr val="bg1" />
                          </a:solidFill>
                          <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
                        </a:rPr>
                        <a:t>Start Date</a:t>
                      </a:r>
                    </a:p>
                  </a:txBody>
                  <a:tcPr>
                    <a:lnL w="9525" cap="flat" cmpd="sng" algn="ctr">
                      <a:solidFill>
                        <a:schemeClr val="bg2" />
                      </a:solidFill>
                      <a:prstDash val="solid" />
                      <a:round />
                      <a:headEnd type="none" w="med" len="med" />
                      <a:tailEnd type="none" w="med" len="med" />
                    </a:lnL>
                    <a:lnR w="9525" cap="flat" cmpd="sng" algn="ctr">
                      <a:solidFill>
                        <a:schemeClr val="bg2" />
                      </a:solidFill>
                      <a:prstDash val="solid" />
                      <a:round />
                      <a:headEnd type="none" w="med" len="med" />
                      <a:tailEnd type="none" w="med" len="med" />
                    </a:lnR>
                    <a:lnT w="9525" cap="flat" cmpd="sng" algn="ctr">
                      <a:solidFill>
                        <a:schemeClr val="bg2" />
                      </a:solidFill>
                      <a:prstDash val="solid" />
                      <a:round />
                      <a:headEnd type="none" w="med" len="med" />
                      <a:tailEnd type="none" w="med" len="med" />
                    </a:lnT>
                    <a:lnB w="9525" cap="flat" cmpd="sng" algn="ctr">
                      <a:solidFill>
                        <a:schemeClr val="bg2" />
                      </a:solidFill>
                      <a:prstDash val="solid" />
                      <a:round />
                      <a:headEnd type="none" w="med" len="med" />
                      <a:tailEnd type="none" w="med" len="med" />
                    </a:lnB>
                    <a:lnTlToBr w="12700" cmpd="sng">
                      <a:noFill />
                      <a:prstDash val="solid" />
                    </a:lnTlToBr>
                    <a:lnBlToTr w="12700" cmpd="sng">
                      <a:noFill />
                      <a:prstDash val="solid" />
                    </a:lnBlToTr>
                    <a:solidFill>
                      <a:srgbClr val="06BAE6" />
                    </a:solidFill>
                  </a:tcPr>
                </a:tc>
                <a:tc>
                  <a:txBody>
                    <a:bodyPr />
                    <a:lstStyle />
                    <a:p>
                      <a:pPr algn="ctr" />
                      <a:r>
                        <a:rPr lang="en-CA" sz="800" dirty="0">
                          <a:solidFill>
                            <a:schemeClr val="bg1" />
                          </a:solidFill>
                          <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
                        </a:rPr>
                        <a:t>End Date</a:t>
                      </a:r>
                    </a:p>
                  </a:txBody>
                  <a:tcPr>
                    <a:lnL w="9525" cap="flat" cmpd="sng" algn="ctr">
                      <a:solidFill>
                        <a:schemeClr val="bg2" />
                      </a:solidFill>
                      <a:prstDash val="solid" />
                      <a:round />
                      <a:headEnd type="none" w="med" len="med" />
                      <a:tailEnd type="none" w="med" len="med" />
                    </a:lnL>
                    <a:lnR w="9525" cap="flat" cmpd="sng" algn="ctr">
                      <a:solidFill>
                        <a:schemeClr val="bg2" />
                      </a:solidFill>
                      <a:prstDash val="solid" />
                      <a:round />
                      <a:headEnd type="none" w="med" len="med" />
                      <a:tailEnd type="none" w="med" len="med" />
                    </a:lnR>
                    <a:lnT w="9525" cap="flat" cmpd="sng" algn="ctr">
                      <a:solidFill>
                        <a:schemeClr val="bg2" />
                      </a:solidFill>
                      <a:prstDash val="solid" />
                      <a:round />
                      <a:headEnd type="none" w="med" len="med" />
                      <a:tailEnd type="none" w="med" len="med" />
                    </a:lnT>
                    <a:lnB w="9525" cap="flat" cmpd="sng" algn="ctr">
                      <a:solidFill>
                        <a:schemeClr val="bg2" />
                      </a:solidFill>
                      <a:prstDash val="solid" />
                      <a:round />
                      <a:headEnd type="none" w="med" len="med" />
                      <a:tailEnd type="none" w="med" len="med" />
                    </a:lnB>
                    <a:lnTlToBr w="12700" cmpd="sng">
                      <a:noFill />
                      <a:prstDash val="solid" />
                    </a:lnTlToBr>
                    <a:lnBlToTr w="12700" cmpd="sng">
                      <a:noFill />
                      <a:prstDash val="solid" />
                    </a:lnBlToTr>
                    <a:solidFill>
                      <a:srgbClr val="06BAE6" />
                    </a:solidFill>
                  </a:tcPr>
                </a:tc>
                <a:tc>
                  <a:txBody>
                    <a:bodyPr />
                    <a:lstStyle />
                    <a:p>
                      <a:pPr algn="ctr" />
                      <a:r>
                        <a:rPr lang="en-CA" sz="800" dirty="0">
                          <a:solidFill>
                            <a:schemeClr val="bg1" />
                          </a:solidFill>
                          <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
                        </a:rPr>
                        <a:t>Quantity</a:t>
                      </a:r>
                    </a:p>
                  </a:txBody>
                  <a:tcPr>
                    <a:lnL w="9525" cap="flat" cmpd="sng" algn="ctr">
                      <a:solidFill>
                        <a:schemeClr val="bg2" />
                      </a:solidFill>
                      <a:prstDash val="solid" />
                      <a:round />
                      <a:headEnd type="none" w="med" len="med" />
                      <a:tailEnd type="none" w="med" len="med" />
                    </a:lnL>
                    <a:lnR w="9525" cap="flat" cmpd="sng" algn="ctr">
                      <a:solidFill>
                        <a:schemeClr val="bg2" />
                      </a:solidFill>
                      <a:prstDash val="solid" />
                      <a:round />
                      <a:headEnd type="none" w="med" len="med" />
                      <a:tailEnd type="none" w="med" len="med" />
                    </a:lnR>
                    <a:lnT w="9525" cap="flat" cmpd="sng" algn="ctr">
                      <a:solidFill>
                        <a:schemeClr val="bg2" />
                      </a:solidFill>
                      <a:prstDash val="solid" />
                      <a:round />
                      <a:headEnd type="none" w="med" len="med" />
                      <a:tailEnd type="none" w="med" len="med" />
                    </a:lnT>
                    <a:lnB w="9525" cap="flat" cmpd="sng" algn="ctr">
                      <a:solidFill>
                        <a:schemeClr val="bg2" />
                      </a:solidFill>
                      <a:prstDash val="solid" />
                      <a:round />
                      <a:headEnd type="none" w="med" len="med" />
                      <a:tailEnd type="none" w="med" len="med" />
                    </a:lnB>
                    <a:lnTlToBr w="12700" cmpd="sng">
                      <a:noFill />
                      <a:prstDash val="solid" />
                    </a:lnTlToBr>
                    <a:lnBlToTr w="12700" cmpd="sng">
                      <a:noFill />
                      <a:prstDash val="solid" />
                    </a:lnBlToTr>
                    <a:solidFill>
                      <a:srgbClr val="06BAE6" />
                    </a:solidFill>
                  </a:tcPr>
                </a:tc>
                <a:tc>
                  <a:txBody>
                    <a:bodyPr />
                    <a:lstStyle />
                    <a:p>
                      <a:pPr algn="ctr" />
                      <a:r>
                        <a:rPr lang="en-CA" sz="800" dirty="0">
                          <a:solidFill>
                            <a:schemeClr val="bg1" />
                          </a:solidFill>
                          <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
                        </a:rPr>
                        <a:t>Rate Type</a:t>
                      </a:r>
                    </a:p>
                  </a:txBody>
                  <a:tcPr>
                    <a:lnL w="9525" cap="flat" cmpd="sng" algn="ctr">
                      <a:solidFill>
                        <a:schemeClr val="bg2" />
                      </a:solidFill>
                      <a:prstDash val="solid" />
                      <a:round />
                      <a:headEnd type="none" w="med" len="med" />
                      <a:tailEnd type="none" w="med" len="med" />
                    </a:lnL>
                    <a:lnR w="9525" cap="flat" cmpd="sng" algn="ctr">
                      <a:solidFill>
                        <a:schemeClr val="bg2" />
                      </a:solidFill>
                      <a:prstDash val="solid" />
                      <a:round />
                      <a:headEnd type="none" w="med" len="med" />
                      <a:tailEnd type="none" w="med" len="med" />
                    </a:lnR>
                    <a:lnT w="9525" cap="flat" cmpd="sng" algn="ctr">
                      <a:solidFill>
                        <a:schemeClr val="bg2" />
                      </a:solidFill>
                      <a:prstDash val="solid" />
                      <a:round />
                      <a:headEnd type="none" w="med" len="med" />
                      <a:tailEnd type="none" w="med" len="med" />
                    </a:lnT>
                    <a:lnB w="9525" cap="flat" cmpd="sng" algn="ctr">
                      <a:solidFill>
                        <a:schemeClr val="bg2" />
                      </a:solidFill>
                      <a:prstDash val="solid" />
                      <a:round />
                      <a:headEnd type="none" w="med" len="med" />
                      <a:tailEnd type="none" w="med" len="med" />
                    </a:lnB>
                    <a:lnTlToBr w="12700" cmpd="sng">
                      <a:noFill />
                      <a:prstDash val="solid" />
                    </a:lnTlToBr>
                    <a:lnBlToTr w="12700" cmpd="sng">
                      <a:noFill />
                      <a:prstDash val="solid" />
                    </a:lnBlToTr>
                    <a:solidFill>
                      <a:srgbClr val="06BAE6" />
                    </a:solidFill>
                  </a:tcPr>
                </a:tc>
                <a:tc>
                  <a:txBody>
                    <a:bodyPr />
                    <a:lstStyle />
                    <a:p>
                      <a:pPr algn="ctr" />
                      <a:r>
                        <a:rPr lang="en-CA" sz="800" dirty="0">
                          <a:solidFill>
                            <a:schemeClr val="bg1" />
                          </a:solidFill>
                          <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
                        </a:rPr>
                        <a:t>Rate</a:t>
                      </a:r>
                    </a:p>
                  </a:txBody>
                  <a:tcPr>
                    <a:lnL w="9525" cap="flat" cmpd="sng" algn="ctr">
                      <a:solidFill>
                        <a:schemeClr val="bg2" />
                      </a:solidFill>
                      <a:prstDash val="solid" />
                      <a:round />
                      <a:headEnd type="none" w="med" len="med" />
                      <a:tailEnd type="none" w="med" len="med" />
                    </a:lnL>
                    <a:lnR w="9525" cap="flat" cmpd="sng" algn="ctr">
                      <a:solidFill>
                        <a:schemeClr val="bg2" />
                      </a:solidFill>
                      <a:prstDash val="solid" />
                      <a:round />
                      <a:headEnd type="none" w="med" len="med" />
                      <a:tailEnd type="none" w="med" len="med" />
                    </a:lnR>
                    <a:lnT w="9525" cap="flat" cmpd="sng" algn="ctr">
                      <a:solidFill>
                        <a:schemeClr val="bg2" />
                      </a:solidFill>
                      <a:prstDash val="solid" />
                      <a:round />
                      <a:headEnd type="none" w="med" len="med" />
                      <a:tailEnd type="none" w="med" len="med" />
                    </a:lnT>
                    <a:lnB w="9525" cap="flat" cmpd="sng" algn="ctr">
                      <a:solidFill>
                        <a:schemeClr val="bg2" />
                      </a:solidFill>
                      <a:prstDash val="solid" />
                      <a:round />
                      <a:headEnd type="none" w="med" len="med" />
                      <a:tailEnd type="none" w="med" len="med" />
                    </a:lnB>
                    <a:lnTlToBr w="12700" cmpd="sng">
                      <a:noFill />
                      <a:prstDash val="solid" />
                    </a:lnTlToBr>
                    <a:lnBlToTr w="12700" cmpd="sng">
                      <a:noFill />
                      <a:prstDash val="solid" />
                    </a:lnBlToTr>
                    <a:solidFill>
                      <a:srgbClr val="06BAE6" />
                    </a:solidFill>
                  </a:tcPr>
                </a:tc>
                <a:tc>
                  <a:txBody>
                    <a:bodyPr />
                    <a:lstStyle />
                    <a:p>
                      <a:pPr algn="ctr" />
                      <a:r>
                        <a:rPr lang="en-CA" sz="800" dirty="0">
                          <a:solidFill>
                            <a:schemeClr val="bg1" />
                          </a:solidFill>
                          <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
                        </a:rPr>
                        <a:t>Delivered Imp.</a:t>
                      </a:r>
                    </a:p>
                  </a:txBody>
                  <a:tcPr>
                    <a:lnL w="9525" cap="flat" cmpd="sng" algn="ctr">
                      <a:solidFill>
                        <a:schemeClr val="bg2" />
                      </a:solidFill>
                      <a:prstDash val="solid" />
                      <a:round />
                      <a:headEnd type="none" w="med" len="med" />
                      <a:tailEnd type="none" w="med" len="med" />
                    </a:lnL>
                    <a:lnR w="9525" cap="flat" cmpd="sng" algn="ctr">
                      <a:solidFill>
                        <a:schemeClr val="bg2" />
                      </a:solidFill>
                      <a:prstDash val="solid" />
                      <a:round />
                      <a:headEnd type="none" w="med" len="med" />
                      <a:tailEnd type="none" w="med" len="med" />
                    </a:lnR>
                    <a:lnT w="9525" cap="flat" cmpd="sng" algn="ctr">
                      <a:solidFill>
                        <a:schemeClr val="bg2" />
                      </a:solidFill>
                      <a:prstDash val="solid" />
                      <a:round />
                      <a:headEnd type="none" w="med" len="med" />
                      <a:tailEnd type="none" w="med" len="med" />
                    </a:lnT>
                    <a:lnB w="9525" cap="flat" cmpd="sng" algn="ctr">
                      <a:solidFill>
                        <a:schemeClr val="bg2" />
                      </a:solidFill>
                      <a:prstDash val="solid" />
                      <a:round />
                      <a:headEnd type="none" w="med" len="med" />
                      <a:tailEnd type="none" w="med" len="med" />
                    </a:lnB>
                    <a:lnTlToBr w="12700" cmpd="sng">
                      <a:noFill />
                      <a:prstDash val="solid" />
                    </a:lnTlToBr>
                    <a:lnBlToTr w="12700" cmpd="sng">
                      <a:noFill />
                      <a:prstDash val="solid" />
                    </a:lnBlToTr>
                    <a:solidFill>
                      <a:srgbClr val="06BAE6" />
                    </a:solidFill>
                  </a:tcPr>
                </a:tc>
                <a:tc>
                  <a:txBody>
                    <a:bodyPr />
                    <a:lstStyle />
                    <a:p>
                      <a:pPr algn="ctr" />
                      <a:r>
                        <a:rPr lang="en-CA" sz="800" dirty="0">
                          <a:solidFill>
                            <a:schemeClr val="bg1" />
                          </a:solidFill>
                          <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
                        </a:rPr>
                        <a:t>Clicks</a:t>
                      </a:r>
                    </a:p>
                  </a:txBody>
                  <a:tcPr>
                    <a:lnL w="9525" cap="flat" cmpd="sng" algn="ctr">
                      <a:solidFill>
                        <a:schemeClr val="bg2" />
                      </a:solidFill>
                      <a:prstDash val="solid" />
                      <a:round />
                      <a:headEnd type="none" w="med" len="med" />
                      <a:tailEnd type="none" w="med" len="med" />
                    </a:lnL>
                    <a:lnR w="9525" cap="flat" cmpd="sng" algn="ctr">
                      <a:solidFill>
                        <a:schemeClr val="bg2" />
                      </a:solidFill>
                      <a:prstDash val="solid" />
                      <a:round />
                      <a:headEnd type="none" w="med" len="med" />
                      <a:tailEnd type="none" w="med" len="med" />
                    </a:lnR>
                    <a:lnT w="9525" cap="flat" cmpd="sng" algn="ctr">
                      <a:solidFill>
                        <a:schemeClr val="bg2" />
                      </a:solidFill>
                      <a:prstDash val="solid" />
                      <a:round />
                      <a:headEnd type="none" w="med" len="med" />
                      <a:tailEnd type="none" w="med" len="med" />
                    </a:lnT>
                    <a:lnB w="9525" cap="flat" cmpd="sng" algn="ctr">
                      <a:solidFill>
                        <a:schemeClr val="bg2" />
                      </a:solidFill>
                      <a:prstDash val="solid" />
                      <a:round />
                      <a:headEnd type="none" w="med" len="med" />
                      <a:tailEnd type="none" w="med" len="med" />
                    </a:lnB>
                    <a:lnTlToBr w="12700" cmpd="sng">
                      <a:noFill />
                      <a:prstDash val="solid" />
                    </a:lnTlToBr>
                    <a:lnBlToTr w="12700" cmpd="sng">
                      <a:noFill />
                      <a:prstDash val="solid" />
                    </a:lnBlToTr>
                    <a:solidFill>
                      <a:srgbClr val="06BAE6" />
                    </a:solidFill>
                  </a:tcPr>
                </a:tc>
                <a:tc>
                  <a:txBody>
                    <a:bodyPr />
                    <a:lstStyle />
                    <a:p>
                      <a:pPr algn="ctr" />
                      <a:r>
                        <a:rPr lang="en-CA" sz="800" dirty="0">
                          <a:solidFill>
                            <a:schemeClr val="bg1" />
                          </a:solidFill>
                          <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
                        </a:rPr>
                        <a:t>CTR</a:t>
                      </a:r>
                    </a:p>
                  </a:txBody>
                  <a:tcPr>
                    <a:lnL w="9525" cap="flat" cmpd="sng" algn="ctr">
                      <a:solidFill>
                        <a:schemeClr val="bg2" />
                      </a:solidFill>
                      <a:prstDash val="solid" />
                      <a:round />
                      <a:headEnd type="none" w="med" len="med" />
                      <a:tailEnd type="none" w="med" len="med" />
                    </a:lnL>
                    <a:lnR w="9525" cap="flat" cmpd="sng" algn="ctr">
                      <a:solidFill>
                        <a:schemeClr val="bg2" />
                      </a:solidFill>
                      <a:prstDash val="solid" />
                      <a:round />
                      <a:headEnd type="none" w="med" len="med" />
                      <a:tailEnd type="none" w="med" len="med" />
                    </a:lnR>
                    <a:lnT w="9525" cap="flat" cmpd="sng" algn="ctr">
                      <a:solidFill>
                        <a:schemeClr val="bg2" />
                      </a:solidFill>
                      <a:prstDash val="solid" />
                      <a:round />
                      <a:headEnd type="none" w="med" len="med" />
                      <a:tailEnd type="none" w="med" len="med" />
                    </a:lnT>
                    <a:lnB w="9525" cap="flat" cmpd="sng" algn="ctr">
                      <a:solidFill>
                        <a:schemeClr val="bg2" />
                      </a:solidFill>
                      <a:prstDash val="solid" />
                      <a:round />
                      <a:headEnd type="none" w="med" len="med" />
                      <a:tailEnd type="none" w="med" len="med" />
                    </a:lnB>
                    <a:lnTlToBr w="12700" cmpd="sng">
                      <a:noFill />
                      <a:prstDash val="solid" />
                    </a:lnTlToBr>
                    <a:lnBlToTr w="12700" cmpd="sng">
                      <a:noFill />
                      <a:prstDash val="solid" />
                    </a:lnBlToTr>
                    <a:solidFill>
                      <a:srgbClr val="06BAE6" />
                    </a:solidFill>
                  </a:tcPr>
                </a:tc>
                <a:tc>
                  <a:txBody>
                    <a:bodyPr />
                    <a:lstStyle />
                    <a:p>
                      <a:pPr algn="ctr" />
                      <a:r>
                        <a:rPr lang="en-CA" sz="800" dirty="0">
                          <a:solidFill>
                            <a:schemeClr val="bg1" />
                          </a:solidFill>
                          <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
                        </a:rPr>
                        <a:t>Conv.</a:t>
                      </a:r>
                    </a:p>
                  </a:txBody>
                  <a:tcPr>
                    <a:lnL w="9525" cap="flat" cmpd="sng" algn="ctr">
                      <a:solidFill>
                        <a:schemeClr val="bg2" />
                      </a:solidFill>
                      <a:prstDash val="solid" />
                      <a:round />
                      <a:headEnd type="none" w="med" len="med" />
                      <a:tailEnd type="none" w="med" len="med" />
                    </a:lnL>
                    <a:lnR w="9525" cap="flat" cmpd="sng" algn="ctr">
                      <a:solidFill>
                        <a:schemeClr val="bg2" />
                      </a:solidFill>
                      <a:prstDash val="solid" />
                      <a:round />
                      <a:headEnd type="none" w="med" len="med" />
                      <a:tailEnd type="none" w="med" len="med" />
                    </a:lnR>
                    <a:lnT w="9525" cap="flat" cmpd="sng" algn="ctr">
                      <a:solidFill>
                        <a:schemeClr val="bg2" />
                      </a:solidFill>
                      <a:prstDash val="solid" />
                      <a:round />
                      <a:headEnd type="none" w="med" len="med" />
                      <a:tailEnd type="none" w="med" len="med" />
                    </a:lnT>
                    <a:lnB w="9525" cap="flat" cmpd="sng" algn="ctr">
                      <a:solidFill>
                        <a:schemeClr val="bg2" />
                      </a:solidFill>
                      <a:prstDash val="solid" />
                      <a:round />
                      <a:headEnd type="none" w="med" len="med" />
                      <a:tailEnd type="none" w="med" len="med" />
                    </a:lnB>
                    <a:lnTlToBr w="12700" cmpd="sng">
                      <a:noFill />
                      <a:prstDash val="solid" />
                    </a:lnTlToBr>
                    <a:lnBlToTr w="12700" cmpd="sng">
                      <a:noFill />
                      <a:prstDash val="solid" />
                    </a:lnBlToTr>
                    <a:solidFill>
                      <a:srgbClr val="06BAE6" />
                    </a:solidFill>
                  </a:tcPr>
                </a:tc>
                <a:tc>
                  <a:txBody>
                    <a:bodyPr />
                    <a:lstStyle />
                    <a:p>
                      <a:pPr algn="ctr" />
                      <a:r>
                        <a:rPr lang="en-CA" sz="800" dirty="0">
                          <a:solidFill>
                            <a:schemeClr val="bg1" />
                          </a:solidFill>
                          <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
                        </a:rPr>
                        <a:t>Contracted Spend</a:t>
                      </a:r>
                    </a:p>
                  </a:txBody>
                  <a:tcPr>
                    <a:lnL w="9525" cap="flat" cmpd="sng" algn="ctr">
                      <a:solidFill>
                        <a:schemeClr val="bg2" />
                      </a:solidFill>
                      <a:prstDash val="solid" />
                      <a:round />
                      <a:headEnd type="none" w="med" len="med" />
                      <a:tailEnd type="none" w="med" len="med" />
                    </a:lnL>
                    <a:lnR w="9525" cap="flat" cmpd="sng" algn="ctr">
                      <a:solidFill>
                        <a:schemeClr val="bg2" />
                      </a:solidFill>
                      <a:prstDash val="solid" />
                      <a:round />
                      <a:headEnd type="none" w="med" len="med" />
                      <a:tailEnd type="none" w="med" len="med" />
                    </a:lnR>
                    <a:lnT w="9525" cap="flat" cmpd="sng" algn="ctr">
                      <a:solidFill>
                        <a:schemeClr val="bg2" />
                      </a:solidFill>
                      <a:prstDash val="solid" />
                      <a:round />
                      <a:headEnd type="none" w="med" len="med" />
                      <a:tailEnd type="none" w="med" len="med" />
                    </a:lnT>
                    <a:lnB w="9525" cap="flat" cmpd="sng" algn="ctr">
                      <a:solidFill>
                        <a:schemeClr val="bg2" />
                      </a:solidFill>
                      <a:prstDash val="solid" />
                      <a:round />
                      <a:headEnd type="none" w="med" len="med" />
                      <a:tailEnd type="none" w="med" len="med" />
                    </a:lnB>
                    <a:lnTlToBr w="12700" cmpd="sng">
                      <a:noFill />
                      <a:prstDash val="solid" />
                    </a:lnTlToBr>
                    <a:lnBlToTr w="12700" cmpd="sng">
                      <a:noFill />
                      <a:prstDash val="solid" />
                    </a:lnBlToTr>
                    <a:solidFill>
                      <a:srgbClr val="06BAE6" />
                    </a:solidFill>
                  </a:tcPr>
                </a:tc>
                <a:tc>
                  <a:txBody>
                    <a:bodyPr />
                    <a:lstStyle />
                    <a:p>
                      <a:pPr algn="ctr" />
                      <a:r>
                        <a:rPr lang="en-CA" sz="800" dirty="0">
                          <a:solidFill>
                            <a:schemeClr val="bg1" />
                          </a:solidFill>
                          <a:latin typeface="Ted Next Medium" panose="020B0602030202020203" pitchFamily="34" charset="0" />
                        </a:rPr>
                        <a:t>Actual Spend</a:t>
                      </a:r>
                    </a:p>
                  </a:txBody>
                  <a:tcPr>
                    <a:lnL w="9525" cap="flat" cmpd="sng" algn="ctr">
                      <a:solidFill>
                        <a:schemeClr val="bg2" />
                      </a:solidFill>
                      <a:prstDash val="solid" />
                      <a:round />
                      <a:headEnd type="none" w="med" len="med" />
                      <a:tailEnd type="none" w="med" len="med" />
                    </a:lnL>
                    <a:lnR w="9525" cap="flat" cmpd="sng" algn="ctr">
                      <a:solidFill>
                        <a:schemeClr val="bg2" />
                      </a:solidFill>
                      <a:prstDash val="solid" />
                      <a:round />
                      <a:headEnd type="none" w="med" len="med" />
                      <a:tailEnd type="none" w="med" len="med" />
                    </a:lnR>
                    <a:lnT w="9525" cap="flat" cmpd="sng" algn="ctr">
                      <a:solidFill>
                        <a:schemeClr val="bg2" />
                      </a:solidFill>
                      <a:prstDash val="solid" />
                      <a:round />
                      <a:headEnd type="none" w="med" len="med" />
                      <a:tailEnd type="none" w="med" len="med" />
                    </a:lnT>
                    <a:lnB w="9525" cap="flat" cmpd="sng" algn="ctr">
                      <a:solidFill>
                        <a:schemeClr val="bg2" />
                      </a:solidFill>
                      <a:prstDash val="solid" />
                      <a:round />
                      <a:headEnd type="none" w="med" len="med" />
                      <a:tailEnd type="none" w="med" len="med" />
                    </a:lnB>
                    <a:lnTlToBr w="12700" cmpd="sng">
                      <a:noFill />
                      <a:prstDash val="solid" />
                    </a:lnTlToBr>
                    <a:lnBlToTr w="12700" cmpd="sng">
                      <a:noFill />
                      <a:prstDash val="solid" />
                    </a:lnBlToTr>
                    <a:solidFill>
                      <a:srgbClr val="06BAE6" />
                    </a:solidFill>
                  </a:tcPr>
                </a:tc>
                <a:extLst>
                  <a:ext uri="{0D108BD9-81ED-4DB2-BD59-A6C34878D82A}">
                    <a16:rowId xmlns:a16="http://schemas.microsoft.com/office/drawing/2014/main" val="2015614021" />
                  </a:ext>
                </a:extLst>
              </a:tr>
            </a:tbl>
          </a:graphicData>
        </a:graphic>
      </p:graphicFrame>        
        `.trim();
    const doc = new DOMParser().parseFromString(xml, "application/xml");
    const newNode = this.content.importNode(doc.documentElement, true);
    parent.appendChild(newNode);

    if (parent.ownerDocument) {
      if (!includeCtr) {
        const gridNodes = this.content.evaluate(
          "/p:sld/p:cSld/p:spTree/p:graphicFrame/a:graphic/a:graphicData/a:tbl/a:tblGrid/a:gridCol[position()=9]",
          this.content,
          this.resolver,
          XPathResult.ORDERED_NODE_ITERATOR_TYPE,
          null
        );
        const gridNode = gridNodes.iterateNext();
        if (gridNode && gridNode.parentNode) {
          gridNode.parentNode.removeChild(gridNode);
        }
        const colNodes = this.content.evaluate(
          "/p:sld/p:cSld/p:spTree/p:graphicFrame/a:graphic/a:graphicData/a:tbl/a:tr/a:tc[position()=9]",
          this.content,
          this.resolver,
          XPathResult.ORDERED_NODE_ITERATOR_TYPE,
          null
        );
        const colNode = colNodes.iterateNext();
        if (colNode && colNode.parentNode) {
          colNode.parentNode.removeChild(colNode);
        }
      }
      if (!includeConversions) {
        const colIdx = includeCtr ? "10" : "9";
        const gridNodes = this.content.evaluate(
          "/p:sld/p:cSld/p:spTree/p:graphicFrame/a:graphic/a:graphicData/a:tbl/a:tblGrid/a:gridCol[position()=" +
            colIdx +
            "]",
          this.content,
          this.resolver,
          XPathResult.ORDERED_NODE_ITERATOR_TYPE,
          null
        );
        const gridNode = gridNodes.iterateNext();
        if (gridNode && gridNode.parentNode) {
          gridNode.parentNode.removeChild(gridNode);
        }
        const colNodes = this.content.evaluate(
          "/p:sld/p:cSld/p:spTree/p:graphicFrame/a:graphic/a:graphicData/a:tbl/a:tr/a:tc[position()=" +
            colIdx +
            "]",
          this.content,
          this.resolver,
          XPathResult.ORDERED_NODE_ITERATOR_TYPE,
          null
        );
        const colNode = colNodes.iterateNext();
        if (colNode && colNode.parentNode) {
          colNode.parentNode.removeChild(colNode);
        }
      }
      const tblXpath =
        "/p:sld/p:cSld/p:spTree/p:graphicFrame/a:graphic/a:graphicData/a:tbl";
      const tableNode = parent.ownerDocument.evaluate(
        tblXpath,
        newNode,
        this.resolver,
        XPathResult.FIRST_ORDERED_NODE_TYPE,
        null
      ).singleNodeValue;
      if (tableNode != null) {
        data.forEach((line) => {
          var rowNode = this.generateLineItemTemplate();
          tableNode.appendChild(rowNode);
          this.replaceNodeText(
            parent.ownerDocument!,
            rowNode,
            "LINEITEMNAME",
            line.name
          );
          this.replaceNodeText(
            parent.ownerDocument!,
            rowNode,
            "LINEITEMSTARTDATE",
            line.startDate
          );
          this.replaceNodeText(
            parent.ownerDocument!,
            rowNode,
            "LINEITEMENDDATE",
            line.endDate
          );
          this.replaceNodeText(
            parent.ownerDocument!,
            rowNode,
            "LINEITEMQUANTITY",
            Conversions.numberWithCommas(line.quantity, 0)
          );
          this.replaceNodeText(
            parent.ownerDocument!,
            rowNode,
            "LINEITEMRATETYPE",
            Conversions.acronymRate(line.rateType)
          );
          this.replaceNodeText(
            parent.ownerDocument!,
            rowNode,
            "LINEITEMRATE",
            "$" + Conversions.numberWithCommas(line.rate, 2)
          );
          this.replaceNodeText(
            parent.ownerDocument!,
            rowNode,
            "LINEITEMIMPRESSIONS",
            Conversions.numberWithCommas(line.deliveredImpressions, 0)
          );
          this.replaceNodeText(
            parent.ownerDocument!,
            rowNode,
            "LINEITEMCLICKS",
            Conversions.numberWithCommas(line.clicks, 0)
          );
          if (includeCtr) {
            this.replaceNodeText(
              parent.ownerDocument!,
              rowNode,
              "LINEITEMCTR",
              (line.deliveredImpressions === 0
                ? 0
                : Conversions.numberWithCommas(
                    (line.clicks / line.deliveredImpressions) * 100.0,
                    2
                  )
              ).toString() + "%"
            );
          } else {
            this.removeRowColumn(doc, rowNode, 9);
          }
          if (includeConversions) {
            this.replaceNodeText(
              parent.ownerDocument!,
              rowNode,
              "LINEITEMCONVERSIONS",
              Conversions.numberWithCommas(line.conversions, 0)
            );
          } else {
            this.removeRowColumn(doc, rowNode, includeCtr ? 10 : 9);
          }
          this.replaceNodeText(
            parent.ownerDocument!,
            rowNode,
            "LIINEITEMCONTRACTEDSPEND",
            "$" + Conversions.numberWithCommas(line.contractedSpend, 2)
          );
          this.replaceNodeText(
            parent.ownerDocument!,
            rowNode,
            "LINEITEMACTUALSPEND",
            "$" + Conversions.numberWithCommas(line.actualSpend, 2)
          );
        });
        if (footer) {
          this.appendSummaryFooter(
            footer,
            includeCtr,
            includeConversions,
            parent.ownerDocument!,
            tableNode
          );
        }
      }
    }
  }

  addLogo(image: EmbeddedImage, parent: Node) {
    const imageRelId = this.presentation.addImageFile(image, this);
    const pictureId = (parent as Element).children.length - 1; // TODO see if we should walk looking for IDs

    const xml =
      `
        <p:pic xmlns:p="http://schemas.openxmlformats.org/presentationml/2006/main">
        <p:nvPicPr>
          <p:cNvPr id="` +
      pictureId +
      `" name="RSM Logo">
            <a:extLst xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
              <a:ext uri="{FF2B5EF4-FFF2-40B4-BE49-F238E27FC236}">
                <a16:creationId xmlns:a16="http://schemas.microsoft.com/office/drawing/2014/main" id="{40B62851-2581-4C20-A554-66B7EC623C2C}" />
              </a:ext>
              <a:ext uri="{C183D7F6-B498-43B3-948B-1728B52AA6E4}">
                <adec:decorative xmlns:adec="http://schemas.microsoft.com/office/drawing/2017/decorative" val="1" />
              </a:ext>
            </a:extLst>
          </p:cNvPr>
          <p:cNvPicPr>
            <a:picLocks noChangeAspect="1" xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main" />
          </p:cNvPicPr>
          <p:nvPr />
        </p:nvPicPr>
        <p:blipFill>
          <a:blip r:embed="` +
      imageRelId +
      `" xmlns:r="http://schemas.openxmlformats.org/officeDocument/2006/relationships" xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main" />
          <a:stretch xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
            <a:fillRect />
          </a:stretch>
        </p:blipFill>
        <p:spPr>
          <a:xfrm xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
            <a:off x="770995" y="1826563" />
            <a:ext cx="1743607" cy="1158340" />
          </a:xfrm>
          <a:prstGeom prst="rect" xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
            <a:avLst />
          </a:prstGeom>
        </p:spPr>
      </p:pic>
        `.trim();
    const doc = new DOMParser().parseFromString(xml, "application/xml");
    const newNode = this.content.importNode(doc.documentElement, true);
    parent.appendChild(newNode);
  }

  addImage(image: EmbeddedImage): void {
    const tree = this.shapeTreeNode();
    if (!tree) return;

    const pictureName = "My Shape";
    const pictureId = (tree as Element).children.length - 1; // TODO see if we should walk looking for IDs
    const offsetX = 634999;
    const offsetY = 1016000;

    var width = 9764364;
    var height = 5855913;
    if (image.width > image.height) {
      height = Math.round(
        (width * parseFloat(image.height.toString())) / image.width
      );
    } else {
      width = Math.round(
        (height * parseFloat(image.width.toString())) / image.height
      );
    }
    const imageRelId = this.presentation.addImageFile(image, this);

    const picXml =
      `
<p:grpSp xmlns:p="http://schemas.openxmlformats.org/presentationml/2006/main">
  <p:nvGrpSpPr>
    <p:cNvPr id="3" name="Group 2">
      <a:extLst xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
        <a:ext uri="{FF2B5EF4-FFF2-40B4-BE49-F238E27FC236}">
          <a16:creationId xmlns:a16="http://schemas.microsoft.com/office/drawing/2014/main" id="{9F66D0D3-156B-4E9E-A810-7973906CD35D}" />
        </a:ext>
      </a:extLst>
    </p:cNvPr>
    <p:cNvGrpSpPr />
    <p:nvPr />
  </p:nvGrpSpPr>
  <p:grpSpPr>
    <a:xfrm xmlns:p="http://schemas.openxmlformats.org/presentationml/2006/main" xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
      <a:off x="` +
      offsetX +
      `" y="` +
      offsetY +
      `" />
      <a:ext cx="` +
      width +
      `" cy="` +
      height +
      `" />
      <a:chOff x="` +
      offsetX +
      `" y="` +
      offsetY +
      `" />
      <a:chExt cx="` +
      width +
      `" cy="` +
      height +
      `" />
    </a:xfrm>
  </p:grpSpPr>
  <p:pic xmlns:p="http://schemas.openxmlformats.org/presentationml/2006/main">
    <p:nvPicPr>
      <p:cNvPr id="` +
      pictureId +
      `" name="` +
      pictureName +
      `">
        <a:extLst xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
          <a:ext uri="{FF2B5EF4-FFF2-40B4-BE49-F238E27FC236}">
            <a16:creationId xmlns:a16="http://schemas.microsoft.com/office/drawing/2014/main" id="{3D60E410-2F37-4D48-93AE-FECF40B103AB}" />
          </a:ext>
        </a:extLst>
      </p:cNvPr>
      <p:cNvPicPr>
        <a:picLocks xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main" noChangeAspect="1" noChangeArrowheads="1" />
      </p:cNvPicPr>
      <p:nvPr />
    </p:nvPicPr>
    <p:blipFill>
      <a:blip xmlns:r="http://schemas.openxmlformats.org/officeDocument/2006/relationships" xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main" r:embed="` +
      imageRelId +
      `">
        <a:extLst>
          <a:ext uri="{28A0092B-C50C-407E-A947-70E740481C1C}">
            <a14:useLocalDpi xmlns:a14="http://schemas.microsoft.com/office/drawing/2010/main" val="0" />
          </a:ext>
        </a:extLst>
      </a:blip>
      <a:srcRect xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main" />
      <a:stretch xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
        <a:fillRect />
      </a:stretch>
    </p:blipFill>
    <p:spPr bwMode="auto">
      <a:xfrm xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
        <a:off x="` +
      offsetX +
      `" y="` +
      offsetY +
      `" />
        <a:ext cx="` +
      width +
      `" cy="` +
      height +
      `" />
      </a:xfrm>
      <a:prstGeom xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main" prst="rect">
        <a:avLst />
      </a:prstGeom>
      <a:noFill xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main" />
      <a:extLst xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
        <a:ext uri="{909E8E84-426E-40DD-AFC4-6F175D3DCCD1}">
          <a14:hiddenFill xmlns:a14="http://schemas.microsoft.com/office/drawing/2010/main">
            <a:solidFill>
              <a:srgbClr val="FFFFFF" />
            </a:solidFill>
          </a14:hiddenFill>
        </a:ext>
      </a:extLst>
    </p:spPr>
  </p:pic>
</p:grpSp>        
        `.trim();
    const doc = new DOMParser().parseFromString(picXml, "application/xml");
    const newNode = this.content.importNode(doc.documentElement, true);
    tree.appendChild(newNode);
  }

  addHeading(heading: string): void {
    const headingXml =
      `
        <p:sp xmlns:p="http://schemas.openxmlformats.org/presentationml/2006/main">
  <p:nvSpPr>
    <p:cNvPr id="2" name="Text Placeholder 1">
      <a:extLst xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
        <a:ext uri="{FF2B5EF4-FFF2-40B4-BE49-F238E27FC236}">
          <a16:creationId xmlns:a16="http://schemas.microsoft.com/office/drawing/2014/main" id="{15D9CF81-8772-B748-9DB2-F0942365D1C0}" />
        </a:ext>
      </a:extLst>
    </p:cNvPr>
    <p:cNvSpPr>
      <a:spLocks noGrp="1" xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main" />
    </p:cNvSpPr>
    <p:nvPr>
      <p:ph type="body" sz="quarter" idx="10" />
    </p:nvPr>
  </p:nvSpPr>
  <p:spPr />
  <p:txBody>
    <a:bodyPr xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main" />
    <a:lstStyle xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main" />
    <a:p xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
      <a:r>
        <a:rPr lang="en-CA" dirty="0">
          <a:ea typeface="Calibre Medium" charset="0" />
          <a:cs typeface="Arial" panose="020B0604020202020204" pitchFamily="34" charset="0" />
        </a:rPr>
        <a:t>` +
      heading +
      `</a:t>
      </a:r>
      <a:endParaRPr lang="en-CA" dirty="0">
        <a:ea typeface="Calibre Medium" charset="0" />
        <a:cs typeface="Calibre Medium" charset="0" />
      </a:endParaRPr>
    </a:p>
    <a:p xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
      <a:endParaRPr lang="en-US" dirty="0" />
    </a:p>
  </p:txBody>
</p:sp>
        `.trim();

    const tree = this.shapeTreeNode();
    if (!tree) return;
    const doc = new DOMParser().parseFromString(headingXml, "application/xml");
    const newNode = this.content.importNode(doc.documentElement, true);
    tree.appendChild(newNode);
  }
}
export default Slide;
