/* eslint-disable */
import { connect } from "react-redux";
import * as React from "react";
import TitleBar from "./Panel/TitleBar";
import "./CSS/redUI-styles.css";
import "./CSS/common.scss";
import "./App.css";
import { ActionConstants, AppConstants } from "./ConstConfig";
import { userActions } from "./Actions";
import Logger from "./rogersframework/Logger/Logger";
import { LOGLEVEL } from "./ConstConfig/logLevelConst";
import PropTypes from "prop-types";
import { Route, Switch } from "react-router-dom";
import RedUISpinner from "./Panel/RedUISpinner";
import AuthProvider from "./Login/MSAL/AuthProvider";
import { getRoleBasedRouteAccess } from "./Utility/commonUtil";
import Terminology from "./Terminology/Component/Terminology";
import TestPath from "./TestPath";
import CRMUploaderFormComponent from "./CRMUploader/Component/CRMUploaderFormComponent";
import MessageBox from "./CommonComponent/MessageBox";
import BottomPanelBar from "./Panel/BottomPanelBar";
import { IntlProvider } from "react-intl";
import AdminLandingPage from "./Admin/AdminLandingPage";
import AccessError from "./Login/Component/AccessError";
import AdminCRMStatus from "./Admin/Component/AdminCRMStatus";
import AdminUserList from "./Admin/Component/AdminUserList";
import AdminUserOperation from "./Admin/Component/AdminUserOperation";
import AdminAudienceQuerySegs from "./Admin/Component/AdminAudienceQuerySegs";
import AdminAudienceQuerySegsOps from "./Admin/Component/AdminAudienceQuerySegsOps";
import AdminAudienceSegment from "./Admin/Component/AdminAudienceSegment";
import AdminAudienceSegmentOperation from "./Admin/Component/AdminAudienceSegmentOperation";
import AdminCynchAttributeList from "./Admin/Component/AdminCynchAttributeList";
import AdminInfoPage from "./Admin/Component/AdminInfoPage";
import AdminReportingBannerStatus from "./Admin/Component/AdminReportingBannerStatus";
import CoreSettingOps from "./Admin/Component/CoreSettingOps";
import CynchAttributes from "./Admin/Component/CynchAttributes";
import DomoConfigManagement from "./Admin/Component/DomoConfigManagement";
import DomoQueryManagement from "./Admin/Component/DomoQueryManagement";
import DomoQueryOps from "./Admin/Component/DomoQueryOps";
import TVCampaignAggList from "./Admin/Component/TVCampaignAggList";
import TVCampaignAggOps from "./Admin/Component/TVCampaignAggOps";
import Audiences from "./Audiences/Component/Audiences";
import AudienceBuilder from "./AudienceBuilder/AudienceBuilder";
import Dashboard from "./Dashboard/Component/Dashboard";
import LinOptForcastingLanding from "./Reporting/Component/LinOptForcastingLanding";
import ReportingFormComponent from "./Reporting/Component/ReportingFormComponent";
import ExcelDomoReporting from "./Reporting/Component/ExcelDomoReporting";
import PowerPointDomoReporting from "./Reporting/Component/PowerPointDomoReporting";
import "react-tooltip/dist/react-tooltip.css";

interface IApp extends React.FC<any> {
  propsFromStore?: any;
  isLoginSuccessful?: any;
  slickIndex?: any;
  UserRole?: any;
  handleSubmit?: any;
  onSignIn?: any;
  account?: any;
}
const flow = require("lodash/flow");

class App extends React.Component<IApp, {}> {
  static propTypes = {
    account: PropTypes.object,
    emailMessages: PropTypes.object,
    error: PropTypes.string,
    graphProfile: PropTypes.object,
    onSignIn: PropTypes.func.isRequired,
  };
  localizationMessages = {
    fr: require("./translations/fr.json"),
    en: require("./translations/en.json"),
  };
  language: any;
  allRoutes: any[];
  assignedRoutes: any[];

  constructor(props: any) {
    super(props);
    this.language = navigator.language.split(/[-_]/)[0]; // language without region code
    if (process.env.REACT_APP_LOGIN_CONFIG !== "PROD") {
      Logger.setLogLevel(LOGLEVEL.WARN);
    } else {
      Logger.setLogLevel(LOGLEVEL.ERROR);
    }
    this.allRoutes = [
      { path: "/AddUser", component: AdminUserOperation },
      { path: "/AdminUsersList", component: AdminUserList },
      { path: "/Admin", component: AdminLandingPage },
      {
        path: "/AdminAudienceSegment",
        component: AdminAudienceSegmentOperation,
      },
      {
        path: "/AdminReportingBannerStatus",
        component: AdminReportingBannerStatus,
      },
      { path: "/AdminAudienceSegmentList", component: AdminAudienceSegment },
      { path: "/AdminCynchAttributeList", component: AdminCynchAttributeList },
      { path: "/CynchAttributes", component: CynchAttributes },
      { path: "/AdminCRMStatus", component: AdminCRMStatus },
      { path: "/SystemInformation", component: AdminInfoPage },
      { path: "/createQuerySegment", component: AdminAudienceQuerySegsOps },
      { path: "/QueryAudienceSegmentIds", component: AdminAudienceQuerySegs },
      { path: "/TVCampaignAggregation", component: TVCampaignAggList },
      { path: "/TVCampaignAggregationOps", component: TVCampaignAggOps },
      { path: "/ConfigurationSettings", component: CoreSettingOps },
      { path: "/DomoConfigManagement", component: DomoConfigManagement },
      { path: "/DomoQueryManagement", component: DomoQueryManagement },
      { path: "/DomoQueryOps", component: DomoQueryOps },
      { path: "/CRMUploader", component: CRMUploaderFormComponent },
      { path: "/AdminAudienceTemplates", component: Audiences },
      { path: "/redui/Audiences", component: Audiences },
      { path: "/AudienceSizer", component: AudienceBuilder },
      { path: "/Reporting/LinOpt", component: LinOptForcastingLanding },
      {
        path: "/Reporting/Digital/PowerPoint/:orderId",
        component: PowerPointDomoReporting,
      },
      {
        path: "/Reporting/Digital/Excel/:orderId",
        component: ExcelDomoReporting,
      },
      { path: "/Reporting/Digital", component: ReportingFormComponent },
      {
        path: "/Reporting/Digital/PowerPoint",
        component: ReportingFormComponent,
      },
      { path: "/Reporting/Digital/Excel", component: ReportingFormComponent },
      { path: "/Terminology", component: Terminology },
      { path: "/TestPath", component: TestPath },
    ];
    this.getAllRoutes = this.getAllRoutes.bind(this);
    this.isRoleExistForRoute = this.isRoleExistForRoute.bind(this);
    this.assignedRoutes = [];
  }

  isRoleExistForRoute(routeItem: any) {
    if (routeItem && routeItem.path !== "") {
      const index = this.assignedRoutes.findIndex(
        (obj: any) => obj == routeItem.path
      );

      if (index >= 0) {
        return true;
      }
    }
    return false;
  }

  getAllRoutes() {
    localStorage.setItem("slickIndex", this.props.slickIndex);
    this.assignedRoutes = getRoleBasedRouteAccess(this.props.UserRole);
    return this.allRoutes.map((routeItem: any, i: any) => {
      if (this.isRoleExistForRoute(routeItem)) {
        return (
          <Route
            key={i}
            path={routeItem.path}
            component={routeItem.component}
            exact={true}
          />
        );
      } else {
        return (
          <Route
            key={i}
            path={routeItem.path}
            component={() => (
              <div className="row fixed-header-top ml-0 mr-0">
                <div className="col-12 spaceBottom blockCentered">
                  <img src={require("./svg/safety_icon.svg").default} />
                  <h3 className="mb-4 mt-4">Access Denied</h3>
                  <h4>You don't have access to this application</h4>
                </div>
              </div>
            )}
            exact={true}
          />
        );
      }
    });
  }

  render() {
    let spinnerState = { UIConfig: { isSpinnerActive: false } };
    if (!this.props.account) {
      spinnerState = { UIConfig: { isSpinnerActive: true } };
      this.props.onSignIn();
    } else {
      spinnerState = { UIConfig: { isSpinnerActive: false } };
    }

    return (
      <IntlProvider
        locale={this.language}
        messages={this.localizationMessages[this.language]}
      >
        <div className="redUI">
          <TitleBar />
          <RedUISpinner UIConfStats={spinnerState} />
          {this.props.isLoginSuccessful ? (
            <Switch>
              {this.getAllRoutes()}
              <Route path={"/"} component={Dashboard} />
            </Switch>
          ) : (
            <AccessError />
          )}
          <MessageBox />
          <BottomPanelBar />
        </div>
      </IntlProvider>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    isLoginSuccessful:
      state.userAuth.hasOwnProperty("data") &&
      state.userAuth.data.isLoginSuccessful === true
        ? state.userAuth.data.isLoginSuccessful
        : false,
    slickIndex:
      state.slickState.hasOwnProperty("data") && state.slickState.data.slickIdx
        ? state.slickState.data.slickIdx
        : 2,
    UserRole:
      state.AdminUserControlState.hasOwnProperty("UserProfile") &&
      state.AdminUserControlState.UserProfile
        ? state.AdminUserControlState.UserProfile
        : {
            email: "",
            company: null,
            userId: null,
            firstName: "",
            lastName: "",
            roles: ["User"],
          },
  };
}

export default flow(
  connect(mapStateToProps, (dispatch) => {
    return {
      handleSubmit: (token: any) => {
        const userObj = {
          UserAction: ActionConstants.Login,
          isLoginSuccessful: AppConstants.Inited,
          authContext: token,
        };
        dispatch(userActions(userObj));
      },
    };
  }),
  AuthProvider
)(App);
