import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getDomoAPIResponse,
  sendDomoQueryReesponse,
  sendDownloadDomoReports,
  senDomoAccessLog,
  slickStateAction,
  updateProgressCRM,
} from "src/Actions";
import CalendarControl from "src/CommonComponent/Calendar/CalendarControl";
import { findDiffInDays } from "src/CommonComponent/Calendar/utils/CampaignUtil";
import CustomErrorAlertBanner from "src/CommonComponent/CustomErrorAlertBanner.";
import { ActionConstants, Configs } from "src/ConstConfig";
import UserOps from "src/ConstConfig/UserOps";
import { getTomorrowsDate, getNewEndDate } from "src/Utility/CampaignUtil";
import { getRoleConfigs } from "src/Utility/roleBasedAttrib";
import DomoCampaignOrder from "../Model/DomoCampaignOrder";
import { clearAllErrorStateInReporting } from "../utils/dashboardValidation";
const queryIds = require("../../data/domoQueryIds.json");

const ExcelDomoReporting: React.FC<IExcelDomoReporting> = (props) => {
  const configs: any = new Configs();
  const [errorStateCheck, setErrorStateCheck] = useState(
    clearAllErrorStateInReporting()
  );

  const isBetaTester =
    props.UserAssignedRole?.roles?.indexOf(UserOps.BETATESTER) >= 0
      ? true
      : false;

  const [legacySummary, setLegacySummary] = useState(true);
  const [accessType, setAccessType] = useState(0);

  useEffect(() => {
    const isRoleExist =
      props.UserAssignedRole?.roles?.indexOf(UserOps.REPORTINGEXCEL) >= 0
        ? true
        : false;
    if (isRoleExist) {
      setAccessType(2);
      props.resetScreenControls();
      const payload = {
        queryId: "Summary",
        queryItems: {
          ORDERID: props.match?.params?.orderId,
          ADDEDCRITERIA: "",
        },
      };

      const domoQueryObj = {
        data: { url: configs.getUserDomoQueryUrl(), payload: payload },
      };
      props.loadDomoData(domoQueryObj);
    } else {
      setAccessType(1);
    }
  }, []);

  const startDateCal = {
    Id: "startDate",
    NameId: "#startDateCal",
    name: "START DATE",
    identifier: "startDateCal",
  };
  const endDateCal = {
    Id: "endDate",
    NameId: "#endDateCal",
    name: "END DATE",
    identifier: "endDateCal",
  };

  function initTabSwitch(e: any) {
    props.history.push("/Reporting/Digital/");
  }

  function generateExcel(excelPayload: any) {
    const orgErrState = clearAllErrorStateInReporting();

    if (
      findDiffInDays(
        props.usrSelectedDates.startDate.date,
        props.usrSelectedDates.endDate.date
      ) < 0
    ) {
      orgErrState.reportDateError.show = true;
      setErrorStateCheck({ ...orgErrState });
    } else {
      const payload = {
        fileId: props.match?.params?.orderId,
        reportFormat: "xlsx",
      };
      props.handleDomoClicks({
        url: configs.getDomoStatsUrl(),
        type: ActionConstants.DOMOSTATS,
        payload: payload,
      });

      orgErrState.reportDateError.show = false;
      setErrorStateCheck({ ...orgErrState });
      const reportDates = {
        orderStartDate: excelPayload.startDate,
        orderEndDate: excelPayload.endDate,
        reportStartDate: props.usrSelectedDates.startDate.date,
        reportEndDate: props.usrSelectedDates.endDate.date,
      };
      excelPayload = {
        ...excelPayload,
        includeLegacySummary: legacySummary,
        isBetaTester: isBetaTester,
      };
      const domoQueryObj = {
        data: queryIds,
        dates: reportDates,
        excelPayload: excelPayload,
        orderId: props.match.params.orderId,
        addedCriteria: "",
        url: configs.getUserDomoQueryUrl(),
      };
      props.triggerProgress();
      props.getExcel(domoQueryObj);
    }
  }

  const resetLegacySummary = () => {
    const newLegacySummaryVal = !legacySummary;
    setLegacySummary(newLegacySummaryVal);
  };

  function generateReport() {
    generateExcel(props.domoResponse);
  }

  const pWidth = props.progressBarStatus.loaded / 100;
  const progress = {
    width: pWidth + "%",
  };
  if (props.domoResponse.isValidOrderId && accessType === 2)
    return (
      <main role="main" className="container-fluid">
        <div className="row fixed-header-top ml-0 mr-0">
          <div className="col-12">
            <div className="float-left w-100 spacerB36  borderBottomGray">
              <div className="col-xl-7 col-md-12 col-sm-12 col-12 pl-0 pr-0">
                <h3 className="float-left">Reporting</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="row-flex spaceTop">
          <div className="w-100">
            <form>
              <div className="col-xl-12 col-md-6 col-sm-8 col-12">
                <div className="form-group leftPos proposalSearch">
                  <label htmlFor="formName">
                    {props.domoResponse.reportLabel}
                  </label>
                </div>
              </div>

              <div className="col-xl-3 col-md-6 col-sm-12 col-12">
                <CalendarControl CalendarCtrl={startDateCal} />
              </div>
              <div className="col-xl-3 col-md-6 col-sm-12 col-12">
                <CalendarControl CalendarCtrl={endDateCal} />
              </div>
              <div className={" platforms spaceBottom"}>
                <span>
                  <input
                    type="checkbox"
                    id="legacySummary"
                    checked={legacySummary}
                    onChange={resetLegacySummary}
                  />
                  <label htmlFor="legacySummary">
                    Include legacy campaign summary
                  </label>
                </span>
              </div>
              <div className="col-12 spaceBottom">
                <CustomErrorAlertBanner
                  errorMessageStruct={errorStateCheck.reportDateError}
                />
              </div>

              <div className="col-12 buttonPanel spaceBottom float-left">
                {props.progressBarStatus.total !== 1 && (
                  <div className="graph-cont">
                    <span
                      className="bar-fill position-relative"
                      style={{ ...progress }}
                    >
                      <span className="txt-center">
                        {props.progressBarStatus.text} {Math.trunc(pWidth)}%
                      </span>
                    </span>
                  </div>
                )}
                <div className="borderBottomGray spaceBottom48" />
                <a
                  className="btn btnPrimary"
                  href="javascript:void(0);"
                  role="button"
                  onClick={generateReport}
                >
                  Generate
                </a>
                <a
                  className=""
                  href="javascript:void(0)"
                  role="button"
                  data-toggle="modal"
                  data-target="#messageBoxGeneric"
                />
                <a
                  className="btn btnPrimary float-right"
                  href="javascript:void(0)"
                  role="button"
                  onClick={initTabSwitch}
                >
                  Cancel
                </a>
              </div>
            </form>
          </div>
        </div>
      </main>
    );
  else
    return (
      !props.UIConfStats.isSpinnerActive &&
      accessType === 1 && (
        <div className="row fixed-header-top ml-0 mr-0">
          <div className="col-12 spaceBottom blockCentered">
            <img src={require("../../svg/safety_icon.svg").default} />
            <h3 className="mb-4 mt-4">Access Denied</h3>
            <h4>You don't have access to this application</h4>
          </div>
        </div>
      )
    );
};

function mapStateToProps(state: any, props: any) {
  return {
    UIConfStats: state.SpinnerState.hasOwnProperty("UIConfig")
      ? state.SpinnerState.UIConfig
      : { UIConfig: { isSpinnerActive: true } },
    usrSelectedDates:
      state.CalendarControlState.hasOwnProperty("data") &&
      state.CalendarControlState.data
        ? state.CalendarControlState.data
        : {
            startDate: {
              date: getTomorrowsDate(),
              campaignStartDate: getTomorrowsDate(),
            },
            endDate: {
              date: getNewEndDate(getTomorrowsDate()),
              campaignEndDate: getNewEndDate(getTomorrowsDate()),
            },
          },
    domoResponse:
      state.DomoQueryResponseState.hasOwnProperty("data") &&
      state.DomoQueryResponseState.data
        ? state.DomoQueryResponseState.data
        : {
            isValidOrderId: false,
            reportLabel: "",
            startDate: "",
            endDate: "",
          },
    progressBarStatus: state.ProgressCRMState.hasOwnProperty("data")
      ? state.ProgressCRMState.data
      : { loaded: 1, total: 1, text: "" },
    UserRole:
      state.RoleBasedConfiguration.hasOwnProperty("userConfig") &&
      state.RoleBasedConfiguration.userConfig
        ? state.RoleBasedConfiguration.userConfig
        : getRoleConfigs(),
    UserAssignedRole:
      state.AdminUserControlState.hasOwnProperty("UserProfile") &&
      state.AdminUserControlState.UserProfile
        ? state.AdminUserControlState.UserProfile
        : {
            email: "",
            company: null,
            userId: null,
            firstName: "",
            lastName: "",
            roles: ["User"],
          },
  };
}

export default connect(mapStateToProps, (dispatch) => {
  return {
    handleSlick(payload: any) {
      dispatch(slickStateAction(payload));
    },
    loadDomoData(payload: any) {
      dispatch(getDomoAPIResponse(payload));
    },
    triggerProgress() {
      dispatch(
        updateProgressCRM({
          data: {
            total: 10,
            loaded: 10,
            text: "",
          },
        })
      );
    },
    resetScreenControls() {
      let domoStatus = new DomoCampaignOrder().getCampaignReportStructure(
        null,
        null
      );
      dispatch(sendDomoQueryReesponse({ data: domoStatus }));
      dispatch(
        updateProgressCRM({
          data: {
            total: 1,
            loaded: 0,
            text: "",
          },
        })
      );
    },
    getExcel(payload: any) {
      dispatch(sendDownloadDomoReports(payload));
    },
    handleDomoClicks(payload: any) {
      const dummyUserObj = { type: ActionConstants.DOMOSTATS, data: payload };
      dispatch(senDomoAccessLog(dummyUserObj));
    },
  };
})(ExcelDomoReporting);

interface IExcelDomoReporting extends React.FC<any> {
  editDomoConfigStat?: any;
  usrSelectedDates?: any;
  UserRole?: any;
  handleSlick?: any;
  loadDomoData?: any;
  handleSave?: any;
  domoResponse?: any;
  resetScreenControls?: any;
  getExcel?: any;
  progressBarStatus?: any;
  triggerProgress?: any;
  match: any;
  handleDomoClicks: any;
  UserAssignedRole: any;
  history: any;
  UIConfStats?: any;
}
