import { getAuthContext } from "src/Login/MSAL/msalConfig";
import { AxiosClient } from "../ClientServices/AxiosClient";
import {  sendForcastResponse } from "../../Actions";

const reduiLinOptsMiddleWare = (store: any) => (next: any) => (action: any) => {
  if (action && action.hasOwnProperty("type") && (action.type === "GETFORCAST") ) {
    const clnt = new AxiosClient(store);
    const token = getAuthContext().idToken.rawIdToken;
    let userAction = action.type;
    switch (userAction) {
      case "GETFORCAST": {
        const reqObjectSeg = { authToken: token, url: action.payload.data.url };
        const payload =   action.payload.data.forcastPayload;
        clnt.post("POST", reqObjectSeg, payload).then((returnVal: any) => {
          const data = { data: {}};
          
          if ((returnVal.status >= 400 && returnVal.status < 600)) {
            data.data = null;
          }
          else {
               data.data =  returnVal;
            }
          store.dispatch(sendForcastResponse(data));
        });
      }
        break;
    }
  }
  return next(action);
}
 
export default reduiLinOptsMiddleWare;