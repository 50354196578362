/* eslint-disable */
import * as Conversions from './Conversions'

type DomoLineItem = {
    name: string,
    startDate: string,
    endDate: string,
    quantity: number,
    rateType: string,
    rate: number,
    deliveredImpressions: number,
    clicks: number,
    conversions: number,
    contractedSpend: number,
    actualSpend: number
}

export function convertDomoOrderSummary(data: Record<string, string>[]): DomoLineItem[] {
    const result: DomoLineItem[] = []

    data.forEach(line => {
        result.push({
            name: line['Sales_Order_Line_Item_Name'],
            startDate: line['Sales_Order_Line_Item_Start_Date'],
            endDate: line['Sales_Order_Line_Item_End_Date'],
            quantity: Conversions.safeNumber(line['Contracted_Impressions']),
            rateType: line['Cost_Type'],
            rate: Conversions.safeNumber(line['Net_Unit_Cost']),
            deliveredImpressions: Conversions.safeNumber(line['SUM(`T1`.`Impressions`)']),
            clicks: Conversions.safeNumber(line['SUM(`T1`.`Clicks`)']),
            conversions: Conversions.safeNumber(line['SUM(`T1`.`Conversions`)']),
            contractedSpend: Conversions.safeNumber(line['Contracted_Amount_Net']),
            actualSpend: Conversions.safeNumber(line['SUM(`T1`.`Actual_Spend`)'])
        })
    })

    return result
}

export default DomoLineItem